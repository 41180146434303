<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<p-card *ngIf="!EstCharge()">
    <div class="fluid formgrid grid">
        <div class="flex flex-column gap-2 col-12 text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-card>

<div *ngIf="EstCharge()" class="grid">
    <div class="col-12">
        <div class="card">
            <div class="fluid formgrid grid">
                <div class="flex flex-column gap-2 my-4 col-12">
                    <label for="type">Type<span class="requis">*</span></label>
                    <div class="flex flex-wrap gap-3">
                        <div class="flex align-items-center">
                            <p-radioButton name="type" value="Q" [(ngModel)]="nonConformite.type" inputId="type1" />
                            <label for="type1" class="ml-2">
                                Qualité
                            </label>
                        </div>
    
                        <div class="flex align-items-center">
                            <p-radioButton name="type" value="S" [(ngModel)]="nonConformite.type" inputId="type2" />
                            <label for="type2" class="ml-2">
                                SST
                            </label>
                        </div>
    
                        <div class="flex align-items-center">
                            <p-radioButton name="type" value="E" [(ngModel)]="nonConformite.type" inputId="type3" />
                            <label for="type3" class="ml-2">
                                Environnement
                            </label>
                        </div>
                    </div>
                    <small class="p-error" *ngIf="submitted && !nonConformite.type">Le type est obligatoire.</small>
                </div>
            </div>
            <div class="fluid formgrid grid">
                <div class="flex flex-column gap-2 col-12 md:col-6 lg:col-3">
                    <label for="codeProjet">Code Projet<span class="requis">*</span></label>
                    <p-dropdown [options]="codeProjets" [(ngModel)]="nonConformite.codeProjet" optionLabel="code"
                        appendTo="body" [showClear]="true" filter="true" filterBy="code,description"
                        placeholder="Sélectionner un projet...">
                        <ng-template pTemplate="selectedItem">
                            <div *ngIf="nonConformite.codeProjet">
                                <div>{{nonConformite.codeProjet.code}} - {{nonConformite.codeProjet.nom}}</div>
                            </div>
                        </ng-template>
                        <ng-template let-codeProjet pTemplate="item">
                            <div>
                                <div>{{codeProjet.code}} - {{codeProjet.nom}}</div>
                            </div>
                        </ng-template>
                    </p-dropdown>
                    <small class="p-error" *ngIf="submitted && !nonConformite.codeProjet.code">Le projet est
                        obligatoire.</small>
                </div>
                <div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3">
                    <label for="dateEmission">Date d'émission<span class="requis">*</span></label>
                    <p-calendar appendTo="body" showIcon=true [(ngModel)]="nonConformite.dateEmission"
                        dateFormat="yy-mm-dd" [yearNavigator]="true" [monthNavigator]="true"
                        yearRange="2000:2040"></p-calendar>
                    <small class="p-error" *ngIf="submitted && !nonConformite.dateEmission">La date d'émission est
                        obligatoire.</small>
                </div>
                <div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3">
                    <label for="dateDetection">Date de détection<span class="requis">*</span></label>
                    <p-calendar appendTo="body" showIcon=true [(ngModel)]="nonConformite.dateDetection"
                        dateFormat="yy-mm-dd" [yearNavigator]="true" [monthNavigator]="true"
                        yearRange="2000:2040"></p-calendar>
                    <small class="p-error" *ngIf="submitted && !nonConformite.dateDetection">La date de détection est
                        obligatoire.</small>
                </div>
            </div>
            <h5>Identification des intervenants</h5><hr>
            <div class="fluid formgrid grid">
                <div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-4">
                    <label for="entrepreneur">Entrepreneur</label>
                    <input type="text" pInputText id="entrepreneur" [(ngModel)]="nonConformite.entrepreneur">
                </div>
                <div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-4">
                    <label for="sousTraitant">Sous-traitant</label>
                    <p-dropdown [options]="fournisseurs" [(ngModel)]="nonConformite.sousTraitant" optionLabel="nom"
                        appendTo="body" [showClear]="true" filter="true" filterBy="numero,nom"
                        placeholder="Sélectionner un sous-traitant...">
                    </p-dropdown>
                </div>
                <div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-4">
                    <label for="client">Client</label>
                    <input type="text" pInputText id="client"
                        [(ngModel)]="nonConformite.client">
                </div>                
            </div>

            <div class="fluid formgrid grid">
                <div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-4">
                    <label for="responsableQualite">Responsable Qualité</label>
                    <input type="text" pInputText id="responsableQualite"
                        [(ngModel)]="nonConformite.responsableQualite">
                </div>
                <div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-4">
                    <label for="representantSousTraitant">Représentant Sous-traitant</label>
                    <input type="text" pInputText id="representantSousTraitant"
                        [(ngModel)]="nonConformite.representantSousTraitant">
                </div>
                <div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-4">
                    <label for="representantClient">Représentant Client</label>
                    <input type="text" pInputText id="representantClient"
                        [(ngModel)]="nonConformite.representantClient">
                </div>
            </div>

            <h5>Description de la non-conformité</h5><hr>
            <div class="fluid formgrid grid">
                <div class="flex flex-column gap-2 my-2 col-12">
                    <label for="titre">Titre<span class="requis">*</span></label>
                    <input type="text" pInputText id="titre" [(ngModel)]="nonConformite.titre" required>
                    <small class="p-error" *ngIf="submitted && !nonConformite.titre">Le titre est obligatoire.</small>
                </div>
            </div>

            <div class="fluid formgrid grid">
                <div class="flex flex-column gap-2 my-2 col-12 md:col-4">
                    <label for="typeNonConformite">Type de non-conformité<span class="requis">*</span></label>
                    <div class="flex align-items-center">
                        <p-radioButton 
                            name="objet" 
                            value="Contrat" 
                            [(ngModel)]="nonConformite.objet" 
                            inputId="objet1" />
                        <label for="objet1" class="ml-2">
                            Contrat
                        </label>
                    </div>
                    
                    <div class="flex align-items-center">
                        <p-radioButton 
                            name="objet" 
                            value="Produit/Matériel" 
                            [(ngModel)]="nonConformite.objet" 
                            inputId="objet2" />
                        <label for="objet2" class="ml-2">
                            Produit/Matériel
                        </label>
                    </div>
                    
                    <div class="flex align-items-center">
                        <p-radioButton 
                            name="objet" 
                            value="Processus" 
                            [(ngModel)]="nonConformite.objet" 
                            inputId="objet3" />
                        <label for="objet3" class="ml-2">
                            Processus
                        </label>
                    </div>
                
                    <div class="flex align-items-center">
                        <p-radioButton 
                            name="objet" 
                            value="Sous-traitant" 
                            [(ngModel)]="nonConformite.objet" 
                            inputId="objet4" />
                        <label for="objet4" class="ml-2">
                            Sous-traitant
                        </label>
                    </div>

                    <div class="flex align-items-center">
                        <p-radioButton 
                            name="objet" 
                            value="Autre" 
                            [(ngModel)]="nonConformite.objet" 
                            inputId="objet5"
                            #objetAutre />
                        <label for="objet5" class="mx-2">
                            Autre: 
                        </label>
                        <input *ngIf="objetAutre.checked" type="text" pInputText id="objet5" [(ngModel)]="autreObjet">
                    </div>
                    <small class="p-error" *ngIf="submitted && !nonConformite.objet">Le type de non-conformité est obligatoire.</small>
                </div>
                <div class="flex flex-column gap-2 my-2 col-12 md:col-4">
                    <label for="responsable">Responsable<span class="requis">*</span></label>
                    <div class="flex align-items-center">
                        <p-radioButton 
                            name="responsable" 
                            value="Client" 
                            [(ngModel)]="nonConformite.responsable" 
                            inputId="responsable1" />
                        <label for="responsable1" class="ml-2">
                            Client
                        </label>
                    </div>
                    
                    <div class="flex align-items-center">
                        <p-radioButton 
                            name="responsable" 
                            value="Entrepreneur" 
                            [(ngModel)]="nonConformite.responsable" 
                            inputId="responsable2" />
                        <label for="responsable2" class="ml-2">
                            Entrepreneur
                        </label>
                    </div>
                    
                    <div class="flex align-items-center">
                        <p-radioButton 
                            name="responsable" 
                            value="Fournisseur" 
                            [(ngModel)]="nonConformite.responsable" 
                            inputId="responsable3" />
                        <label for="responsable3" class="ml-2">
                            Fournisseur
                        </label>
                    </div>
                
                    <div class="flex align-items-center">
                        <p-radioButton 
                            name="responsable" 
                            value="Sous-traitant" 
                            [(ngModel)]="nonConformite.responsable" 
                            inputId="responsable4" />
                        <label for="responsable4" class="ml-2">
                            Sous-traitant
                        </label>
                    </div>

                    <div class="flex align-items-center">
                        <p-radioButton 
                            name="responsable" 
                            value="Autre" 
                            [(ngModel)]="nonConformite.responsable" 
                            inputId="responsable5"
                            #responsableAutre />
                        <label for="responsable5" class="mx-2">
                            Autre: 
                        </label>

                        <input *ngIf="responsableAutre.checked" type="text" pInputText id="responsable5" [(ngModel)]="autreResponsable">
                    </div>
                    <small class="p-error" *ngIf="submitted && !nonConformite.responsable">Le responsable est obligatoire.</small>
                </div>
                <div class="flex flex-column gap-4 pl-2 my-2 col-12 md:col-4">
                    <label for="documents">Documents de référence</label>
                    <p-floatLabel>
                        <input id="noArticle" type="text" pInputText [(ngModel)]="nonConformite.noArticle"  />
                        <label for="noArticle">N° Article</label>
                    </p-floatLabel>
                    <p-floatLabel>
                        <input id="noPlan" type="text" pInputText [(ngModel)]="nonConformite.noPlan" />
                        <label for="noPlan">N° Plan</label>
                    </p-floatLabel>
                    <p-floatLabel>
                        <input id="noBonCommande" type="text" pInputText [(ngModel)]="nonConformite.noBonCommande" />
                        <label for="noBonCommande">N° Bon de commande</label>
                    </p-floatLabel>
                    <p-floatLabel>
                        <input id="noPiece" type="text" pInputText [(ngModel)]="nonConformite.noPiece" />
                        <label for="noPiece">N° Pièce</label>
                    </p-floatLabel>
                </div>
            </div>

            <div class="fluid formgrid grid">
                <div class="flex flex-column gap-2 my-2 col-12 md:col-6">
                    <label for="description">Description</label>
                    <textarea pInputTextarea id="description" [(ngModel)]="nonConformite.description" rows="5"
                        style="resize: vertical;"></textarea>
                </div>
                <div class="flex flex-column gap-2 my-2 col-12 md:col-6">
                    <label for="causesPossibles">Causes possibles</label>
                    <textarea pInputTextarea id="causesPossibles" [(ngModel)]="nonConformite.causesPossibles" rows="5"
                        style="resize: vertical;"></textarea>
                </div>
            </div>

            <div class="col-12 text-right">
                <p-button label="Créer" (click)="creerNonConformite()" icon="pi pi-save" iconPos="left"></p-button>
            </div>
        </div>
    </div>
</div>