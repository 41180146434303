<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-menu #menuContextuelItemCommande popup="popup" [model]="menuContextuelEntrees"></p-menu>

<p-card *ngIf="!EstCharge()">
	<div class="fluid formgrid grid">
		<div class="flex flex-column gap-2 my-2 col-12 text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-card>

<div *ngIf="EstCharge()" class="grid">
    <div class="col-12">

        <div class="card">
       
            <p-table #dt [value]="attributs" dataKey="attributId" tableStyleClass="tableNormale"
                 [(contextMenuSelection)]="selectionAttribut"
                 [rowHover]="true" [filterDelay]="0"
                [globalFilterFields]="['nom','description']">
                <ng-template pTemplate="caption">
                    <div class="flex align-content-center justify-content-between">
                        <div>
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text"
                                    (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Recherche..." />
                            </span>
                                <button pButton pRipple icon="pi pi-plus"
                            class="p-button-success mr-2 ml-4 mb-2 boutonGrille"
                            (click)="ajouter()"></button>
                        </div>
                        <h5 class="titreTableau m-0">Attributs</h5>
                        <div>
                            <p-multiSelect disabled="true" [style]="{minWidth: '200px'}" placeholder="Colonnes..."></p-multiSelect>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        
                        <th pSortableColumn="nom">
                            <div class="flex justify-content-between align-items-center">
                                Nom
                                <p-sortIcon field="nom"></p-sortIcon>
                                <p-columnFilter type="text" field="nom" display="menu" class="ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="description">
                            <div class="flex justify-content-between align-items-center">
                                Description
                                <p-sortIcon field="description"></p-sortIcon>
                                <p-columnFilter type="text" field="description" display="menu" class="ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="typeChamp">
                            <div class="flex justify-content-between align-items-center">
                                Type
                                <p-sortIcon field="typeChamp"></p-sortIcon>
                                <p-columnFilter type="text" field="typeChamp" display="menu" class="ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                       
                        <th style="min-width: 5rem; width:5rem;"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-attribut>
                    <tr [pContextMenuRow]="attribut">
                        <td>{{attribut.nom}}</td>
                        <td>{{attribut.description}}</td>
                        <td>{{attribut.typeChamp | typeChamp}}</td>
                        <td>
                            <button pButton pRipple icon="pi pi-bars" class="p-button-secondary mr-2 boutonGrille"
                            (click)="surMenuContextuelItem($event, attribut)"></button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="4">Aucun attribut.</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>


</div>


 <!-- ##########   DIALOGUE DÉTAIL ATTRIBUT   ########## -->

 <p-dialog [(visible)]="dialogueOuvert" [style]="{width: '85%'}" header="Détail de l'attribut"
 [modal]="true" styleClass="fluid formgrid grid">
 <ng-template pTemplate="content">
     <div class="fluid formgrid grid">
         <div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3">
             <label for="nom">Nom<span class="requis">*</span></label>
             <input #nomAttribut type="text" pInputText id="nom" [(ngModel)]="attribut.nom"
                 autocomplete="off" autofocus />
             <small class="p-error" *ngIf="submitted && !attribut.nom">Le nom est
                 obligatoire.</small>
         </div>
         <div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-9">
            <label for="description">Description</label>
            <input #nomAttribut type="text" pInputText id="description" [(ngModel)]="attribut.description" required
                autocomplete="off" />
        </div>
     </div>
     <div class="fluid formgrid grid">
        <div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3">
        <p-radioButton label="Texte" name="type" value="1"
        [(ngModel)]="attribut.typeChamp" class="mt-4"></p-radioButton>
        <br />
        <p-radioButton label="Numérique" name="type" value="2"
        [(ngModel)]="attribut.typeChamp" class="mt-2"></p-radioButton>
        <br />
        <p-radioButton label="Décimale" name="type" value="3" 
        [(ngModel)]="attribut.typeChamp" class="mt-2"></p-radioButton>
        <br />
        <p-radioButton label="Date" name="type" value="4"
        [(ngModel)]="attribut.typeChamp" class="mt-2"></p-radioButton>
        <br />
        <p-radioButton label="Vrai/Faux" name="type" value="5"
        [(ngModel)]="attribut.typeChamp" class="mt-2"></p-radioButton>
        </div>
    </div>
    <div class="fluid formgrid grid">
        <div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3">
        <p-checkbox label="Obligatoire" [binary]="true" [(ngModel)]="attribut.indObligatoire" class="mt-3"></p-checkbox>
    </div>
    </div>

 </ng-template>

 <ng-template pTemplate="footer">
     <button pButton pRipple label="Annuler" icon="pi pi-times" class="p-button-text"
         (click)="annuler()"></button>
     <button pButton pRipple label="Enregistrer" icon="pi pi-check" class="p-button-text"
         (click)="sauvegarder()"></button>
 </ng-template>
</p-dialog>