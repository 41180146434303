<p-card *ngIf="!EstCharge()">
	<div class="fluid formgrid grid">
		<div class="flex flex-column gap-2 col-12 text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-card>

<div class="stepsdemo-content" *ngIf="EstCharge()">
    <p-card>
        <ng-template pTemplate="title">
            Sélectionnez une liste d'achat.
        </ng-template>
        <ng-template pTemplate="content">
            <p-table [value]="modeles" selectionMode="single" [(selection)]="selectionListe" dataKey="modeleListeAchatId">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Nom</th>
                        <th>Description</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-liste>
                    <tr [pSelectableRow]="liste">
                        <td>{{liste.nom}}</td>
                        <td>{{liste.description}}</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="2">Aucune liste disponible.</td>
                    </tr>
                </ng-template>
            </p-table>
        </ng-template>
        <ng-template pTemplate="footer">
            <div class="grid grid-nogutter justify-content-end">
                <p-button [disabled]="!selectionListe" label="Suivant" (onClick)="nextPage()"  icon="pi pi-angle-right" iconPos="right"></p-button>
            </div>
        </ng-template>
        
    </p-card>
</div>