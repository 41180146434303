import { Component, OnInit, ViewChild, Input, ɵConsole, ElementRef } from "@angular/core"; import { Location } from "@angular/common";
import { Table } from 'primeng/table';
import { BreadcrumbService } from '../../app.breadcrumb.service';
import { MessageService, LazyLoadEvent, ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { ItemMin } from '../../entites/item-min';
import { ItemService } from '../../services/item-service';
import { Router, ActivatedRoute } from '@angular/router';
import { Statut } from 'src/app/entites/statut';
import { CategorieMin } from 'src/app/entites/categorie';
import { BaseComponent } from 'src/app/base/base-component';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { SousCategorieEntite } from 'src/app/entites/sousCategorie';
import { Emplacement } from 'src/app/entites/emplacement';
import { EmplacementService } from 'src/app/services/emplacement-service';
import { Item } from 'src/app/entites/item';
import { ProfilService } from 'src/app/services/profil-service';
import { AppMainComponent } from 'src/app/app.main.component';
import { CompagnieEntite } from 'src/app/entites/compagnie';
import { Requisition } from 'src/app/entites/requisition';
import { RequisitionService } from 'src/app/services/requisition-service';
import { RequisitionMin } from 'src/app/entites/requisition-min';
import { SelectionRetourComponent } from 'src/app/controles/selection-retour.component';
import { CreationRetourComponent } from 'src/app/controles/creation-retour.component';
import { EStatuts } from 'src/app/entites/enums/statuts';


@Component({
    templateUrl: './retour.component.html',
    providers: [MessageService, ConfirmationService, DialogService]
})
export class RetourComponent extends BaseComponent implements OnInit {

    items: ItemMin[];

    requisitions: RequisitionMin[];

    categories: string[];

    statuts: Statut[] = this.sdb.Statuts();

    @ViewChild('rechercheItem') inputRechercheItem;
    @ViewChild('dt') tableItems: Table;

    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute, 
		appMain: AppMainComponent, location: Location, 
        private breadcrumbService: BreadcrumbService,
        private emplacementService: EmplacementService,
        private requisitionService: RequisitionService,
        private ItemsService: ItemService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        public dialogService: DialogService,
        public routerService: Router) {
        super(sdb, profilService, route, routerService, appMain);
        this.breadcrumbService.setItems([
            { label: 'Retours' }
        ]);
    }

    ngOnInit() {

        this.verifierAccesPage('retours');

        this.colonnesDisponibles = [
            { champ: 'categorie', entete: 'Catégorie', afficheParDefaut: true },
            { champ: 'sousCategorie', entete: 'Sous-catégorie', afficheParDefaut: true },
            { champ: 'marque', entete: 'Marque', afficheParDefaut: false },
            { champ: 'modele', entete: 'Modèle', afficheParDefaut: false },
            { champ: 'emplacement', entete: 'Emplacement', afficheParDefaut: true },
            { champ: 'compagnie', entete: 'Compagnie', afficheParDefaut: false }
        ];
        this.colonnesSelectionnees = this.colonnesDisponibles.filter(x => x.afficheParDefaut);

        var listeCategories = this.sdb.Categories();
        this.categories = [];
        listeCategories.forEach(x => {
            this.categories.push(x.nom);
        });


        var p1 = this.ItemsService.ObtenirItems().then(x => {
            this.items = x.valeur.filter(x => x.categorie.estUnique);
        });

        var p2 = this.requisitionService.ObtenirRetoursActifs().then(x => {
            this.requisitions = x.valeur;
        });

        Promise.all([p1, p2]).then(() => {
            this.CompleterChargement();
            setTimeout(() => { this.inputRechercheItem.nativeElement.focus(); }, 100);
        });
    }
    
    surEntreeRecherche() {
        if (this.tableItems.filteredValue != undefined &&
            this.tableItems.filteredValue.length == 1) {
            this.Retourner(this.tableItems.filteredValue[0].itemId);
        }
    }

    Retourner(item: ItemMin) {

        var retours: RequisitionMin[] = [];

        if (item.emplacement) {
            retours = this.requisitions.filter(x => x.de.emplacementId == item.emplacement.emplacementId)
        } else {
            //TODO
            return;
        }

        if (retours.length > 0) {
            const ref = this.dialogService.open(SelectionRetourComponent, {
                data: {
                    retours: retours
                },
                header: 'Sélectionner le retour',
                width: '85%'
            }).onClose.subscribe((res: RequisitionMin) => {
                if (res) {
                    if (res.requisitionId == 0) {
                        const ref = this.dialogService.open(CreationRetourComponent, {
                            data: {
                                emplacementId: item.emplacement.emplacementId
                            },
                            header: 'Créer un nouveau retour',
                            width: '85%'
                        }).onClose.subscribe((resCreationRetour: Requisition) => {
                            if (resCreationRetour) {
                                this.requisitions.push(this.requisitionService.RequisitionVersRequisitionMin(resCreationRetour));
                               this.CreerItemRetour(item, this.requisitionService.RequisitionVersRequisitionMin(resCreationRetour))
                            }
                        });
                    } else {
                        this.CreerItemRetour(item, res);
                    }
                }
            });
        } else {
            const ref = this.dialogService.open(CreationRetourComponent, {
                data: {
                    emplacementId: item.emplacement.emplacementId
                },
                header: 'Créer un nouveau retour',
                width: '85%'
            }).onClose.subscribe((resCreationRetour: Requisition) => {
                if (resCreationRetour) {
                    var reqMin = this.requisitionService.RequisitionVersRequisitionMin(resCreationRetour);
                    this.requisitions.push(this.requisitionService.RequisitionVersRequisitionMin(resCreationRetour));
                   this.CreerItemRetour(item, this.requisitionService.RequisitionVersRequisitionMin(resCreationRetour))
                }
            });
        }
    }

    CreerItemRetour(item: ItemMin, retour: RequisitionMin) {

        this.Bloquer();

        this.requisitionService.CreerItemRequisition({
            itemsRequisition: [{
                itemRequisitionId: 0,
                requisitionId: retour.requisitionId,
                item: item,
                statut: this.sdb.Statut(EStatuts.Actif),
                employe: this.profilService.ProfilCourant(),
                quantite: 1,
                expediteur: null,
                demandeur: null
            }]
        })

        item.emplacement = this.sdb.Emplacement(10000);

        this.Debloquer();

        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Retour effectué.' });
    }

}