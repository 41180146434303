<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<p-card *ngIf="!EstCharge()">
	<div class="fluid formgrid grid">
		<div class="flex flex-column gap-2 my-2 col-12 text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-card>

<div *ngIf="EstCharge()" class="grid">
	<div class="col-12">
		<div class="card">
			<h5>Soumission - {{soumission.fournisseur.nom}}
				<span [class]="'statut ' + soumission.statut.nom.toLowerCase().replace(' ','')">
					{{soumission.statut.nom}}
				</span>
			</h5>

			<p-menubar autoDisplay="false" [model]="menu">
				<p-button *ngIf="modeConsultation() && estModifiable" (click)="btnModifier_click()" icon="pi pi-pencil"
					styleClass="p-button-primary"></p-button>
				<p-button *ngIf="modeAjoutModification()" (onClick)="enregistrerModifications()" label
					icon="pi pi-check" styleClass="p-button-success mr-2">
				</p-button>
				<p-button *ngIf="modeAjoutModification()" (onClick)="annulerModification($event)" icon="pi pi-times"
					styleClass="p-button-secondary"></p-button>
			</p-menubar>
		</div>
	</div>
</div>

<div *ngIf="EstCharge()" class="grid">
	<div class="col-12">
		<div class="card">
			<div class="fluid formgrid grid">
				<div class="flex flex-column gap-2 my-2 col-12 lg:col-6">
					<label for="lastname">Fournisseur</label>
					<input *ngIf="modeConsultation()" pInputText value="{{soumission.fournisseur.nom}} ({{soumission.fournisseur.numero}})" readonly="true" />
					<p-dropdown *ngIf="!modeConsultation()" [options]="fournisseurs" [(ngModel)]="soumission.fournisseur" optionLabel="nom"
						[showClear]="true" filter="true" filterBy="numero,nom"
						placeholder="Sélectionner un fournisseur...">
						<ng-template pTemplate="selectedItem">
							<div *ngIf="soumission.fournisseur">
								<div>{{soumission.fournisseur.nom}} ({{soumission.fournisseur.numero}})</div>
							</div>
						</ng-template>
						<ng-template let-fournisseur pTemplate="item">
							<div>
								<div>{{fournisseur.nom}} ({{fournisseur.numero}})</div>
							</div>
						</ng-template>
					</p-dropdown>
					<small class="p-error" *ngIf="submitted && !soumission.fournisseur">Le fournisseur est
						obligatoire.</small>
				</div>
			</div>
			<div class="fluid formgrid grid">
				<div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3">
					<label for="dateDebut">Date de début d'effectivité</label>
					<input *ngIf="modeConsultation()" pInputText value="{{soumission.dateDebut | date:'yyyy-MM-dd'}}" readonly="true" />
					<p-calendar *ngIf="!modeConsultation()" appendTo="body" showIcon=true [(ngModel)]="soumission.dateDebut" [readonly]="modeConsultation()" dateFormat="yy-mm-dd" [yearNavigator]="true" [monthNavigator]="true" yearRange="2000:2040"></p-calendar>
				</div>
				<div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3">
					<label for="dateFin">Date de fin d'effectivité</label>
					<input *ngIf="modeConsultation()" pInputText value="{{soumission.dateFin | date:'yyyy-MM-dd'}}" readonly="true" />
					<p-calendar *ngIf="!modeConsultation()" appendTo="body" showIcon=true [(ngModel)]="soumission.dateFin" [readonly]="modeConsultation()" dateFormat="yy-mm-dd" [yearNavigator]="true" [monthNavigator]="true" yearRange="2000:2040"></p-calendar>
				</div>
				<div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3">
					<label for="dateDebutSaisie">Date de début de disponibilité</label>
					<input *ngIf="modeConsultation()" pInputText value="{{soumission.dateDebutSaisie | date:'yyyy-MM-dd'}}" readonly="true" />
					<p-calendar *ngIf="!modeConsultation()" appendTo="body" showIcon=true [(ngModel)]="soumission.dateDebutSaisie" [readonly]="modeConsultation()" dateFormat="yy-mm-dd" [yearNavigator]="true" [monthNavigator]="true" yearRange="2000:2040"></p-calendar>
				</div>
				<div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3">
					<label for="dateFinSaisie">Date de fin de disponibilité</label>
					<input *ngIf="modeConsultation()" pInputText value="{{soumission.dateFinSaisie | date:'yyyy-MM-dd'}}" readonly="true" />
					<p-calendar *ngIf="!modeConsultation()" appendTo="body" showIcon=true [(ngModel)]="soumission.dateFinSaisie" [readonly]="modeConsultation()" dateFormat="yy-mm-dd" [yearNavigator]="true" [monthNavigator]="true" yearRange="2000:2040"></p-calendar>
				</div>
			</div>

			<p-menu #menuContextuelItem popup="popup" [model]="contenuMenuContextuelItem"></p-menu>

			<p-table #dtItems [value]="soumission.itemsSoumission" dataKey="itemSoumissionId" [(selection)]="selectionMultipleItem"
			(onRowSelect)="surChangementSelection()" (onRowUnselect)="surChangementSelection()"
                        (onHeaderCheckboxToggle)="surChangementSelection()"
				tableStyleClass="tableNormale" autoLayout="true" 
				[rowHover]="true" [filterDelay]="0"
				[globalFilterFields]="['item.numero','numeroFournisseur','item.description']">
				<ng-template pTemplate="caption">
					<div class="flex align-content-center justify-content-between">
						<div>
							<span class="p-input-icon-left">
								<i class="pi pi-search"></i>
								<input pInputText type="text"
									(input)="dtItems.filterGlobal($event.target.value, 'contains')"
									placeholder="Recherche..." />
							</span>
							<button *ngIf="estModifiable" pButton pRipple icon="pi pi-plus"
								class="boutonGrille p-button-success p-button-sm mb-2 ml-4"
								(click)="surAjoutItem()"></button>
								<button *ngIf="estModifiable" pButton pRipple icon="pi pi-list"
								class="boutonGrille p-button-success p-button-sm mb-2 ml-4"
								(click)="surAjoutItems()"></button>

						</div>
						<h5 class="titreTableau m-0">Items</h5>
						<div>
							<p-multiSelect disabled="true" [style]="{minWidth: '200px'}" placeholder="Colonnes...">
							</p-multiSelect>
						</div>
					</div>
				</ng-template>
				<ng-template pTemplate="header">
					<tr>
						<th style="min-width: 3rem">
							<p-tableHeaderCheckbox></p-tableHeaderCheckbox>
						</th>
						<th pSortableColumn="item.categorie.nom">
							<div class="flex justify-content-between align-items-center">
								Catégorie
								<p-sortIcon field="item.categorie.nom"></p-sortIcon>
								<p-columnFilter type="text" field="item.categorie.nom" display="menu" class="ml-auto">
								</p-columnFilter>
							</div>
						</th>
						<th pSortableColumn="item.numero">
							<div class="flex justify-content-between align-items-center">
								Numéro
								<p-sortIcon field="item.numero"></p-sortIcon>
								<p-columnFilter type="text" field="item.numero" display="menu" class="ml-auto">
								</p-columnFilter>
							</div>
						</th>
						<th pSortableColumn="item.description">
							<div class="flex justify-content-between align-items-center">
								Description
								<p-sortIcon field="item.description"></p-sortIcon>
								<p-columnFilter type="text" field="item.description" display="menu" class="ml-auto">
								</p-columnFilter>
							</div>
						</th>
						<th pSortableColumn="item.unite.nom">
							<div class="flex justify-content-between align-items-center">
								Unité
								<p-sortIcon field="item.unite.nom"></p-sortIcon>
								<p-columnFilter type="text" field="item.unite.nom" display="menu" class="ml-auto">
								</p-columnFilter>
							</div>
						</th>
						<th pSortableColumn="numeroFournisseur">
							<div class="flex justify-content-between align-items-center">
								Numéro fournisseur
								<p-sortIcon field="numeroFournisseur"></p-sortIcon>
								<p-columnFilter type="text" field="numeroFournisseur" display="menu" class="ml-auto">
								</p-columnFilter>
							</div>
						</th>
						<th pSortableColumn="prix">
							<div class="flex justify-content-between align-items-center">
								Prix
								<p-sortIcon field="prix"></p-sortIcon>
								<p-columnFilter type="text" field="prix" display="menu" class="ml-auto">
								</p-columnFilter>
							</div>
						</th>
						<th style="min-width: 5rem"></th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-item>

					<tr>
						<td>
							<p-tableCheckbox [value]="item"></p-tableCheckbox>
						</td>
						<td>{{item.item.categorie.nom}}</td>
						<td>{{item.item.numero}}</td>
						<td>{{item.item.description}}</td>
						<td>{{item.item.unite?.nom}}</td>
						<td>{{item.numeroFournisseur}}</td>
						<td>{{item.prix | currency}}</td>
						
						<td>
							<button pButton pRipple icon="pi pi-bars" class="p-button-secondary mr-2 boutonGrille"
								(click)="surMenuContextuelItem($event, item)"></button>
						</td>
					</tr>
				</ng-template>
				<ng-template pTemplate="emptymessage">
					<tr>
						<td colspan="6">Aucun item.</td>
					</tr>
				</ng-template>
			</p-table>
		</div>
	</div>


</div>