import { Component, ViewChild, ElementRef, ɵConsole } from "@angular/core"; import { Location } from "@angular/common";
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService, MenuItem } from 'primeng/api';
import { Table } from 'primeng/table/table';
import { ActivatedRoute, Router } from '@angular/router';
import { EtapeBonTravailEntite } from 'src/app/entites/mecanique/etape-bon-travail';
import { BaseComponent } from 'src/app/base/base-component';
import { ItemMin } from 'src/app/entites/item-min';
import { ProfilService } from 'src/app/services/profil-service';
import { AppMainComponent } from 'src/app/app.main.component';
import { ItemService } from 'src/app/services/item-service';
import { RequisitionService } from 'src/app/services/requisition-service';
import { FournisseurService } from 'src/app/services/fournisseur-service';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { EntretienPreventifEntite } from 'src/app/entites/mecanique/entretien-preventif';
import { EntretienCorrectifEntite } from 'src/app/entites/mecanique/entretien-correctif';
import { ListeVerificationEntite } from 'src/app/entites/mecanique/liste-verification';
import { ListeVerificationService } from 'src/app/services/liste-verification-service';
import { CategorieEntretienEntite } from 'src/app/entites/mecanique/categorie-entretien';
import { ETypeBonTravail } from 'src/app/entites/enums/type-bon-travail';
import { ETypeEtapeBonTravail } from 'src/app/entites/enums/type-etape-bon-travail';
import { BonTravailService } from 'src/app/services/bon-travail-service';
import { RequeteAjouterEtapesBonTravail } from 'src/app/services/requetes/requete-ajouter-etapes-bon-travail';
import { EntretienCorrectifService } from 'src/app/services/entretien-correctif-service';
import { EntretienPreventifService } from 'src/app/services/entretien-preventif-service';
import { HeureService } from 'src/app/services/heure-service';
import { AjustementHeureEntite } from 'src/app/entites/ajustement-heure';
import { KmService } from 'src/app/services/km-service';
import { AjustementKmEntite } from 'src/app/entites/ajustement-km';

export interface ParametresAjouterAjustement {
    itemId: number;
}


@Component({
    selector: 'app-ajouter-ajustement-km',
    templateUrl: './ajouter-ajustement-km.component.html'
})
export class AjouterAjustementKmComponent extends BaseComponent {

    donnees: ParametresAjouterAjustement;

    ajustement: AjustementKmEntite;

    submitted: boolean;

    constructor(
        profilService: ProfilService,
        route: ActivatedRoute,
        routerService: Router,
        appMain: AppMainComponent, location: Location, 
        public itemService: ItemService,
        public entretienPreventifService: EntretienPreventifService,
        public entretienCorrectifService: EntretienCorrectifService,
        public fournisseurService: FournisseurService,
        public listeVerificationService: ListeVerificationService,
        public bonTravailService: BonTravailService,
        public kmService: KmService,
        public messageService: MessageService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public sdb: DonneesBaseService) {
        super(sdb, profilService, route, routerService, appMain);
    }



    ngOnInit() {

        this.donnees = this.config.data;

        this.ajustement = {
            itemId: this.donnees.itemId,
            date: new Date(),
            employe: this.profilService.ProfilCourant()
        }

        this.CompleterChargement();

    }

    annuler() {
        this.ref.close(null);
    }

    confirmer() {

        this.submitted = true;
        if (!this.valider()) {
            this.messageService.add({ key: 'ajustement', severity: 'error', summary: 'Erreurs', detail: 'Veuillez corriger les erreurs de saisie.' });
            return;
        }

        this.Bloquer();

        this.kmService.AjouterAjustement(this.ajustement).then(x => {

            this.Debloquer();

            if (x.estUnSucces) {

                this.ref.close(x.valeur);

            } else {

                x.messages.forEach(x => {
                    this.messageService.add({ key: 'ajustement', severity: 'error', summary: 'Erreur', detail: x.description, sticky: true });
                });

            }

        });      

    }

    valider() {

        return this.ajustement.date && this.ajustement.nbKmsAvant && (this.ajustement.nbKmsApres || this.ajustement.nbKmsApres == 0);
        
    }
}