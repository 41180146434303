<div class="card">
<div class="grid">
    <div class="col-6">
            <p-inputNumber #champQuantite id="quantity" [(ngModel)]="quantite" (keyup.enter)="surEntree()" [min]="min" [max]="max"></p-inputNumber>
            <small class="p-error" *ngIf="submitted && !quantite">La quantité est obligatoire.</small>
            <br>

    </div>
</div>
<div class="grid">
    <div class="col-12 text-right ">
        <p-button label="Envoyer" class="" icon="pi pi-save" iconPos="left" (onClick)="Confirmer()"></p-button>     
        <p-button label="Annuler" class="p-button-text ml-2" icon="pi pi-ban" iconPos="left" (onClick)="Annuler()"></p-button>
    </div>
</div>
</div>