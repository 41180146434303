import { Component, OnInit, ViewChild, Input, ɵConsole } from "@angular/core"; import { Location } from "@angular/common";
import { Table } from 'primeng/table';
import { BreadcrumbService } from '../../app.breadcrumb.service';

import { RequisitionMin } from '../../entites/requisition-min';
import { MenuItem, TreeNode } from 'primeng/api';
import { MessageService, LazyLoadEvent, ConfirmationService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { EnvoiRequisitionComponent } from '../../controles/envoi-requisition.component';
import { ItemMin } from '../../entites/item-min';
import { ItemService } from '../../services/item-service';
import { Router, ActivatedRoute } from '@angular/router';

import { ToolbarModule } from 'primeng/toolbar';

import { MenubarModule } from 'primeng/menubar';
import { BaseComponent } from '../../base/base-component';
import { Item } from '../../entites/item';
import { Inventaire } from '../../entites/inventaire';
import { NoteEntite } from '../../entites/note';
import { Requisition } from '../../entites/requisition';
import { RequisitionService } from '../../services/requisition-service';
import { Emplacement } from '../../entites/emplacement';
import { EmplacementService } from '../../services/emplacement-service';
import { AchatService } from '../../services/achat-service';
import { BonCommandeEntite } from '../../entites/bon-commande';
import { FournisseurService } from 'src/app/services/fournisseur-service';
import { Fournisseur } from 'src/app/entites/fournisseur';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { ProfilService } from 'src/app/services/profil-service';
import { AppMainComponent } from 'src/app/app.main.component';
import { LocationEntite } from 'src/app/entites/location';
import { TypeLocationEntite } from 'src/app/entites/type-location';
import { EStatuts } from 'src/app/entites/enums/statuts';
import { ConfirmerDebutLocationComponent, ResultatConfirmerDebutLocation } from './controles/confirmer-debut-location.component';
import { LocationService } from 'src/app/services/location-service';
import { ConfirmerFinLocationComponent } from './controles/confirmer-fin-location.component';
import { InfoBonLocationComponent, ResultatInfoBonLocation } from './controles/info-bon-location.component';
import { UniteEntite } from 'src/app/entites/unite';
import { CoutLocationEntite } from 'src/app/entites/cout-location';
import { ETypesLocation } from 'src/app/entites/enums/type-location';
import { ContextMenu } from 'primeng/contextmenu/public_api';
import { DocumentEntite } from 'src/app/entites/document';
import { DocumentService } from 'src/app/services/document-service';
import { DetailDocumentComponent } from '../documents/controles/detail-document.component';
import { environment } from 'src/environments/environment';
import { RequeteObtenirDocuments } from 'src/app/services/requetes/requete-obtenir-documents';
import { ContexteService } from 'src/app/services/contexte-service';
import { SelectionBdcComponent } from 'src/app/controles/selection-bdc.component';
import { ResultatSelectionItemSimple, SelectionItemSimpleComponent } from "src/app/controles/selection-item-simple.component";

@Component({
    templateUrl: './detail-location.component.html',
    styleUrls: ['./detail-location.component.scss'],
    providers: [MessageService, ConfirmationService, DialogService]
})
export class DetailLocationComponent extends BaseComponent implements OnInit {

    locationId: number;
    location: LocationEntite = {};

    fournisseurs: Fournisseur[];
    typesLocation: TypeLocationEntite[];
    emplacements: Emplacement[];
    unites: UniteEntite[];

    menu: MenuItem[];

    submitted: boolean = false;

    // DOCUMENTS
    chargementCompleteDocuments: boolean = false;
    documentsComplet: DocumentEntite[];
    contenuMenuContextuelDocuments: MenuItem[];
    @ViewChild('menuContextuelDocuments') menuContextuelDocuments: ContextMenu;

    @ViewChild('tabView') tabView;

    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute,
        appMain: AppMainComponent, location: Location, 
        private breadcrumbService: BreadcrumbService,
        private achatService: AchatService,
        private documentService: DocumentService,
        private locationService: LocationService,
        private fournisseurService: FournisseurService,
        private contexteService: ContexteService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        public dialogService: DialogService,
        public routerService: Router) {

        super(sdb, profilService, route, routerService, appMain);

        this.breadcrumbService.setItems([
            { label: 'Location', routerLink: '/locations' },
            { label: 'Détails' }
        ]);

        this.route.params.subscribe(params => this.locationId = params.id);
    }

    ngOnInit() {

        this.verifierAccesPage('location_detail');

        if (this.locationId == 0) {


            this.Ajouter();

            this.location.dateDebut = new Date();

            this.CompleterChargement();

        } else {


            this.Consulter();

            this.locationService.Obtenir(this.locationId).then(res => {
                this.location = res.valeur;

                if (this.location.dateDebut) {
                    this.location.dateDebut = new Date(this.location.dateDebut);
                }

                if (this.location.dateFin) {
                    this.location.dateFin = new Date(this.location.dateFin);
                }

                this.initialiserMenu();

                this.CompleterChargement();
            });

        }

        this.typesLocation = [
            { typeLocationId: 1, nom: "Date fixe" },
            { typeLocationId: 2, nom: "Variable" },
            { typeLocationId: 3, nom: "Journée" },
            { typeLocationId: 4, nom: "Semaine" },
            { typeLocationId: 5, nom: "Mois" },
            { typeLocationId: 6, nom: "Année" }
        ];

        this.emplacements = this.sdb.Emplacements();

        this.unites = this.sdb.Unites();

        this.fournisseurService.ObtenirFournisseurs().then(x => {
            this.fournisseurs = x.valeur;
        });

    }

    initialiserMenu() {

        this.estModifiable = this.location.statut.statutId != EStatuts.Complete && this.location.statut.statutId != EStatuts.Archive;

        this.menu = [
            {
                label: 'Retour',
                icon: 'pi pi-fw pi-angle-double-left',
                disabled: this.modeModification(),
                command: () => this.routerService.navigateByUrl('locations')
            },
            {
                label: 'Imprimer',
                icon: 'pi pi-fw pi-print',
                disabled: true

            },
            {
                label: 'Actions',
                icon: 'pi pi-fw pi-bars',
                disabled: this.modeModification(),
                items: [
                    {
                        label: 'Confirmer le début de location',
                        icon: 'pi pi-fw pi-calendar',
                        visible: this.estAutorise('location_action_confimer_debut') && this.location.statut.statutId == EStatuts.EnAttente,
                        command: () => this.confirmerDebut()
                    },
                    {
                        label: 'Fin de location',
                        icon: 'pi pi-fw pi-minus-circle',
                        visible: this.estAutorise('location_action_confimer_fin') && this.location.statut.statutId == EStatuts.Actif,
                        command: () => this.finLocation()
                    },
                    { separator: this.estAutorise('location_action_archiver') || this.estAutorise('location_supprimer') },
                    {
                        label: 'Archiver',
                        icon: 'pi pi-fw pi-times',
                        visible: this.estAutorise('location_action_archiver')
                    },
                    { separator: true },
                    {
                        label: 'Supprimer',
                        icon: 'pi pi-fw pi-times',
                        visible: this.estAutorise('location_supprimer'),
                        command: () => this.supprimer()
                    },
                ]
            },
        ];


    }

    surChangementOnglet(event) {

        if (this.tabView.tabs[event.index]._header.startsWith('Documents') && !this.chargementCompleteDocuments) {
            this.chargerDocuments();
        }

        this.initialiserMenu();
    }

    surBoutonModifier() {


        this.location.fournisseur = this.fournisseurs.find(x => x.fournisseurId == this.location.fournisseur.fournisseurId);
        this.location.emplacement = this.emplacements.find(x => x.emplacementId == this.location.emplacement.emplacementId);
        this.location.typeLocation = this.typesLocation.find(x => x.typeLocationId == this.location.typeLocation.typeLocationId);

        this.location.couts.forEach(c => {
            c.unite = this.unites.find(x => x.uniteId == c.unite?.uniteId);
        });

        this.Modifier();
    }

    surBoutonAnnulerModification() {
        this.DemarrerChargement();

        this.locationService.Obtenir(this.locationId).then(res => {
            this.location = res.valeur;

            if (this.location.dateDebut) {
                this.location.dateDebut = new Date(this.location.dateDebut);
            }
            if (this.location.dateFin) {
                this.location.dateFin = new Date(this.location.dateFin);
            }

            this.initialiserMenu();

            this.CompleterChargement();
        });

        this.Consulter();
    }

    enregistrerModifications() {
        this.submitted = true;
        this.messageService.clear();

        if (this.valider()) {
            this.Bloquer();
            this.locationService.Modifier(this.location).then(x => {
                this.Debloquer();
                if (x.estUnSucces) {
                    this.Consulter();
                    this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Location modifiée.' });
                } else {
                    x.messages.forEach(x => {
                        this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description, sticky: true });
                    });

                }

            });


        }
    }

    supprimer() {
        this.confirmationService.confirm({
            target: event.target,
            message: 'Voulez-vous vraiment supprimer la location sélectionnée ? Le bon de commande associé sera également supprimé.',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.Bloquer();
                this.locationService.Supprimer(this.location.locationId).then(res => {
                    this.Debloquer();
                    if (res.estUnSucces) {

                        this.routerService.navigateByUrl('locations');

                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                });

            }
        });
    }


    surFermetureTypeLocation() {
        switch (this.location.typeLocation.typeLocationId) {
            case ETypesLocation.Variable:
                this.location.couts = [
                    {
                        quantite: 1,
                        unite: this.sdb.UniteParCode('JRS')
                    },
                    {
                        quantite: 1,
                        unite: this.sdb.UniteParCode('SEM')
                    },
                    {
                        quantite: 1,
                        unite: this.sdb.UniteParCode('MOIS')
                    }
                ]
                break;
            case ETypesLocation.Jour:
                this.location.couts = [
                    {
                        quantite: 1,
                        unite: this.sdb.UniteParCode('JRS')
                    },
                ]
                break;
            case ETypesLocation.Semaine:
                this.location.couts = [
                    {
                        quantite: 1,
                        unite: this.sdb.UniteParCode('SEM')
                    },
                ]
                break;
            case ETypesLocation.Mois:
                this.location.couts = [
                    {
                        quantite: 1,
                        unite: this.sdb.UniteParCode('MOIS')
                    },
                ]
                break;
            case ETypesLocation.Annee:
                this.location.couts = [
                    {
                        quantite: 1,
                        unite: this.sdb.UniteParCode('AN')
                    },
                ]
                break;

            default:
                this.location.couts = [{}]
                break;
        }
    }

    copierDescription(cout: CoutLocationEntite) {
        cout.description = this.location.description;
    }

    supprimerCout(cout: CoutLocationEntite) {
        this.location.couts.splice(this.location.couts.indexOf(cout), 1);
    }

    ajouterCout() {
        this.location.couts.push({});
    }

    creerLocation() {



        this.submitted = true;
        this.messageService.clear();

        if (this.valider()) {

            this.Bloquer();

            this.locationService.Ajouter(this.location).then(res => {
                this.Debloquer();
                if (res.estUnSucces) {
                    this.routerService.navigateByUrl('/', { skipLocationChange: true }).then(() =>
                        this.routerService.navigate(['location/' + res.valeur.locationId]));
                } else {
                    res.messages.forEach(x => {
                        this.messageService.add({ key: 'archivage', severity: 'error', summary: 'Erreur', detail: x.description, sticky: true });
                    });
                }
            });


            /*  this.DemarrerChargement();
             
             this.achatService.CreerBonCommande({
                 fournisseur: this.selectionFournisseur,
                 dateAchat: this.dateDebut,
                 employe: this.profilService.ProfilCourant()
             }).then(x => {
                 if (x.estUnSucces) {
                     this.routerService.navigateByUrl('achat/' + x.valeur);
                 } else {
                     x.messages.forEach(x => {
                         this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description, sticky: true });
                     });
 
                     this.CompleterChargement();
                 }
             }); */
        } else {

        }
    }

    valider(): boolean {


        if (!this.location.fournisseur
            || !this.location.typeLocation
            || !this.location.dateDebut
            || !this.location.dateFin
            || !this.location.emplacement
            || !this.location.description) {
            return false;
        }

        return true;
    }

    confirmerDebut() {
        const ref = this.dialogService.open(ConfirmerDebutLocationComponent, {
            data: {
                location: this.location
            },
            header: 'Confirmer le début de la location',
            width: '50%'
        }).onClose.subscribe((res: ResultatConfirmerDebutLocation) => {
            if (res) {
                this.location = res.location;
                this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Début de la location confirmé.' });
                this.initialiserMenu();
            }
        });
    }

    creerBdc() {

        this.Bloquer();

        this.achatService.ObtenirBonCommandeLocationOuvertFournisseur(this.location.fournisseur.fournisseurId, this.profilService.ProfilCourant().employeId).then(x => {

            this.Debloquer();

            if (x.valeur && x.valeur.length > 1) {
                this.confirmationService.confirm({
                    target: event.target,
                    message: 'Il y a déjà plusieurs bons de commande de location ouvert pour ce fournisseur. Voulez-vous ajouter cette location à un de ces bons de commande?',
                    header: 'Confirmation',
                    icon: 'pi pi-exclamation-triangle',
                    accept: () => {

                        const ref = this.dialogService.open(SelectionBdcComponent, {
                            data: {
                                bdcs: x.valeur
                            },
                            header: 'Sélectionner un bon de commande',
                            width: '90%'
                        }).onClose.subscribe((res: BonCommandeEntite) => {
                            if (res) {
                                this.locationService.AjouterItemBonLocation({
                                    employe: this.profilService.ProfilCourant(),
                                    locationId: this.location.locationId,
                                    bonCommandeId: res.bonCommandeId
                                }).then(resBDL => {
        
                                    this.Debloquer();
        
                                    if (resBDL) {
                                        this.location.bonCommande = {
                                            bonCommandeId: resBDL.valeur.bonCommandeId,
                                            numero: resBDL.valeur.numero,
                                            dateAchat: resBDL.valeur.dateAchat,
                                            statut: resBDL.valeur.statut
                                        };
                                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'La location a été ajoutée au bon de commande.' });
                                    } else {
                                        resBDL.messages.forEach(x => {
                                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                                        });
                                    }
        
                                    this.initialiserMenu();
                                });
        
                                return;
                            }
                
                        });

                    },
                    reject: () => {

                        this.confirmerCreationBdc();

                    }
                });
            } else if (x.valeur && x.valeur.length == 1) {
                this.confirmationService.confirm({
                    target: event.target,
                    message: 'Il y a déjà un bon de commande de location ouvert pour ce fournisseur. Voulez-vous ajouter cette location au bon de commande déjà ouvert?',
                    header: 'Confirmation',
                    icon: 'pi pi-exclamation-triangle',
                    accept: () => {

                        this.locationService.AjouterItemBonLocation({
                            employe: this.profilService.ProfilCourant(),
                            locationId: this.location.locationId,
                            bonCommandeId: x.valeur[0].bonCommandeId
                        }).then(resBDL => {

                            this.Debloquer();

                            if (resBDL) {
                                this.location.bonCommande = {
                                    bonCommandeId: resBDL.valeur.bonCommandeId,
                                    numero: resBDL.valeur.numero,
                                    dateAchat: resBDL.valeur.dateAchat,
                                    statut: resBDL.valeur.statut
                                };
                                this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'La location a été ajoutée au bon de commande.' });
                            } else {
                                resBDL.messages.forEach(x => {
                                    this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                                });
                            }

                            this.initialiserMenu();
                        });

                        return;

                    },
                    reject: () => {

                        this.confirmerCreationBdc();

                    }
                });
            } else  {

                this.confirmerCreationBdc();
            }


        })
    }

    confirmerCreationBdc() {
        const ref = this.dialogService.open(InfoBonLocationComponent, {
            data: {
                selectionCodeProjet: this.location.emplacement?.codeProjet?.codeProjetId
            },
            header: 'Compléter l\'information du bon de location',
            width: '50%'
        }).onClose.subscribe((res: ResultatInfoBonLocation) => {
            if (res) {

                this.Bloquer();

                this.locationService.CreerBonLocation({
                    employe: this.profilService.ProfilCourant(),
                    locationId: this.location.locationId,
                    codeProjetId: res.selectionCodeProjet,
                    codeActiviteId: res.selectionCodeActivite,
                    frequence: res.selectionFrequence
                }).then(resBDL => {

                    this.Debloquer();

                    if (resBDL) {
                        this.location.bonCommande = {
                            bonCommandeId: resBDL.valeur.bonCommandeId,
                            numero: resBDL.valeur.numero,
                            dateAchat: resBDL.valeur.dateAchat,
                            statut: resBDL.valeur.statut
                        };
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Le bon de commande a été créé.' });
                    } else {
                        resBDL.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }

                    this.initialiserMenu();
                });
            }
        });
    }


    voirBdc() {
        this.routerService.navigateByUrl('achat/' + this.location.bonCommande.bonCommandeId);
    }

    finLocation() {

        const ref = this.dialogService.open(ConfirmerFinLocationComponent, {
            data: {
                location: this.location
            },
            header: 'Confirmer la fin de la location',
            width: '50%'
        }).onClose.subscribe((res: ResultatConfirmerDebutLocation) => {
            if (res) {
                this.location = res.location;
                this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Fin de la location confirmée.' });
                this.initialiserMenu();
            }
        });
    }

    /* creerLocation() {

        

        this.submitted = true;
        this.messageService.clear();

        if (this.valider()) {

            this.DemarrerChargement();
            
            this.achatService.CreerBonCommande({
                fournisseur: this.selectionFournisseur,
                dateAchat: this.dateDebut,
                employe: this.profilService.ProfilCourant()
            }).then(x => {
                if (x.estUnSucces) {
                    this.routerService.navigateByUrl('achat/' + x.valeur);
                } else {
                    x.messages.forEach(x => {
                        this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description, sticky: true });
                    });

                    this.CompleterChargement();
                }
            });
        } else {

        }
    }

    valider(): boolean {


        if (!this.selectionFournisseur || !this.dateDebut) {
            return false;
        }
            
        return true;
    } */




    /* DOCUMENTS */

    chargerDocuments() {
        const requete: RequeteObtenirDocuments = {
            id: this.location.locationId
        }
        this.documentService.ObtenirListeLocation(requete).then(res => {
            if (res.estUnSucces) {

                res.valeur.documents.forEach(doc => {
                    doc.apercu = 'data:image/jpeg;base64,' + doc.apercu;
                });


                this.documentsComplet = res.valeur.documents;

                this.chargementCompleteDocuments = true;
            }
        });
    }

    ajouterDocument() {

        this.contexteService.Vider();
        this.contexteService.Ajouter("locationId", this.location.locationId.toString());
        this.contexteService.Ajouter("numero", this.location.locationId.toString());

        this.routerService.navigateByUrl('document/nouveau');
    }

    afficherImage(doc: DocumentEntite) {
        window.open(`${environment.apiDocumentUrl}vl/${doc.cheminFichier}/${doc.nomFichier}`, "_blank");
    }

    telechargerDocument(doc: DocumentEntite) {
        window.open(`${environment.apiDocumentUrl}tl/${doc.cheminFichier}/${doc.nomFichier}`, "_blank");
    }

    surMenuContextuelDocuments(event, doc: DocumentEntite) {
        //this.selectionDocument = doc;

        this.contenuMenuContextuelDocuments = [
            {
                label: 'Modifier',
                icon: 'pi pi-fw pi-pencil',
                command: () => this.modifierDocument(doc)
            },
            {
                label: 'Supprimer',
                icon: 'pi pi-fw pi-trash',
                command: () => this.supprimerDocument(doc)
            }
        ];

        this.menuContextuelDocuments.toggle(event);
    }

    modifierDocument(doc: DocumentEntite) {
        const ref = this.dialogService.open(DetailDocumentComponent, {
            data: {
                document: doc
            },
            header: 'Modifier un document',
            width: '60%'
        }).onClose.subscribe((res: DocumentEntite) => {
            if (res) {
                doc.date = res.date;
                doc.nom = res.nom;
                doc.description = doc.description;
                this.messageService.add({ severity: 'success', summary: 'Succès', detail: `Document modifié.` });
            }

        });
    }

    supprimerDocument(doc: DocumentEntite) {
        this.confirmationService.confirm({
            target: event.target,
            message: 'Voulez-vous vraiment supprimer le document sélectionné ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.documentService.SupprimerDocument(doc.documentId).then(res => {
                    if (res.estUnSucces) {
                        this.documentsComplet = this.documentsComplet.filter(val => val.documentId != doc.documentId);
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Document supprimé.' });
                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                });

            }
        });
    }

    selectionnerItem() {
        const ref = this.dialogService.open(SelectionItemSimpleComponent, {
            data: {
                choixCategorieDisponible: false,
                categorieIds: [2]
            },
            header: 'Sélectionner l\'item',
            width: '60%'
        }).onClose.subscribe((res: ResultatSelectionItemSimple) => {
            if (res) {
                this.location.item = res.item;
            }
        });
    }

    retirerItem() {
        this.location.item = null;
    }
}
