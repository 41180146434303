<p-toast key="detailEntretien"></p-toast>

<p-blockUI [blocked]="blocked">
    <div class="fluid formgrid grid">
        <div class="flex flex-column gap-2 col-12 text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-blockUI>


<p-card *ngIf="!EstCharge()">
    <div class="fluid formgrid grid">
        <div class="flex flex-column gap-2 col-12 text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-card>

<div #contenu *ngIf="EstCharge()">

    <div class="fluid formgrid grid">
       
        <div class="flex flex-column gap-2 col-12">
            <label for="lastname">Code de catégorie</label>
            <p-dropdown [options]="categoriesEntretien" [(ngModel)]="selectionCategorieEntretien"
                optionLabel="description" [showClear]="true" filter="true" filterBy="code,description" appendTo="body"
                placeholder="Sélectionner une catégorie...">
                <ng-template pTemplate="selectedItem">
                    <div *ngIf="selectionCategorieEntretien">
                        <div>{{selectionCategorieEntretien.code}} - {{selectionCategorieEntretien.description}}
                        </div>
                    </div>
                </ng-template>
                <ng-template let-categorie pTemplate="item">
                    <div>
                        <div>{{categorie.code}} - {{categorie.description}}</div>
                    </div>
                </ng-template>
            </p-dropdown>

            <small class="p-error" *ngIf="submitted && donnees.obligatoire && !selectionCategorieEntretien">La catégorie est
                obligatoire.</small>
        </div>
       
    </div>

  


</div>


<div class="text-right mt-3">

    <button pButton pRipple label="Suivant" icon="pi pi-save" class="p-button-primary"
        (click)="suivant()"></button>
    <button pButton pRipple label="Annuler" icon="pi pi-ban" class="p-button-text" (click)="annuler()"></button>

</div>