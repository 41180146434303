import { Pipe, PipeTransform } from "@angular/core"; import { Location } from "@angular/common";
import { MenuItem } from 'primeng/api';

@Pipe({
  name: 'breadcrumb'
})
export class BreadcrumbPipe implements PipeTransform {

  transform(value: MenuItem[]): string {
    return value.map(item => item.label).join(' / ').toUpperCase().toString()
  }

}
