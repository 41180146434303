<div *ngIf="!loading" class="flex align-items-center"[pTooltip]="tooltipContent" [tooltipOptions]="tooltipOptions" tooltipPosition="bottom" showDelay="1000" hideDelay="300" >
    <p-avatar 
      [image]="null" 
      [label]="employe.prenom.charAt(0) + employe.nom.charAt(0)" 
      shape="circle" 
      size="normal" 
      [style]="{'background-color': backgroundColor()}"
      class="mr-2">
    </p-avatar>
    <div class="flex flex-column">
      <span class="font-bold">{{ employe | nomEmploye }}</span>
    <span class="text-sm text-500 cursor-pointer" (click)="onEmailClick(employe.courriel)">{{ employe.courriel }}</span>
  </div>
</div>


<ng-template #tooltipContent>
    <p-menu [model]="actions" />
</ng-template>


<div *ngIf="loading" class="flex align-items-center">
  <p-skeleton width="30px" height="30px" borderRadius="50%" styleClass="mr-2"></p-skeleton>
  <div class="flex flex-column gap-1">
    <p-skeleton width="50px"></p-skeleton>
    <p-skeleton width="80px"></p-skeleton>
  </div>
</div>