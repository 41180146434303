<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-confirmPopup key="popup"></p-confirmPopup>
<p-menu #menuContextuelItemCommande popup="popup" [model]="menuContextuelEntrees"></p-menu>

<p-card *ngIf="!EstCharge()">
	<div class="fluid formgrid grid">
		<div class="flex flex-column gap-2 my-2 col-12 text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-card>

<div *ngIf="EstCharge()" class="grid">
	<div class="col-12">
		<div class="card">
			<h5>
				Commande - {{departement.nom}}
				<!-- <i class="pi pi-heart" [ngStyle]="{'color': (estFavori) ? '#dd0000' : '#000000'}" (click)="ajouterFavori()"></i> -->
				<app-bouton-favori [titrePage]="titrePage"></app-bouton-favori>

			</h5>

			<p-menubar autoDisplay="false" [model]="menu"></p-menubar>

		</div>
	</div>
</div>


<div *ngIf="EstCharge()" class="grid">
	<div class="col-12">
		<div class="card">
			
					<div class="fluid formgrid grid">

					</div>

					<p-table #dt [value]="itemsCommande" [columns]="selectedColumns" dataKey="itemCommandeId" *ngIf="visible" tableStyleClass="tableNormale"
					[(selection)]="selectionMultipleItemCommande"  (onRowSelect)="surChangementSelection()" (onRowUnselect)="surChangementSelection()"
					(onHeaderCheckboxToggle)="surChangementSelection()"
						autoLayout="true" styleClass="p-datatable-sm p-datatable-striped"
						[rowHover]="true"  [filterDelay]="0"
						[globalFilterFields]="['item.numero','item.description','demandeur']">
						<ng-template pTemplate="caption">
							<div class="flex align-content-center justify-content-between">
								<div>
									<span class="p-input-icon-left">
										<i class="pi pi-search"></i>
										<input pInputText type="text"
											(input)="dt.filterGlobal($event.target.value, 'contains')"
											placeholder="Recherche..." />
									</span>
									
									<button *ngIf="estAutorise('commande_ajout_item')" pButton pRipple icon="pi pi-plus"
											class="p-button-success mr-2 ml-4 mb-2 boutonGrille"
											(click)="surAjoutItem()"></button>

								</div>
								<h5 class="titreTableau m-0">Items</h5>
								<div>
									<button pButton pRipple icon="pi pi-filter"
											class="p-button-secondary mr-2 ml-4 mb-2 boutonGrille"
											(click)="afficherPanneauFiltres()"></button>
									<p-multiSelect [options]="colonnesDisponibles" [(ngModel)]="colonnesSelectionnees" optionLabel="entete"
										selectedItemsLabel="{0} colonnes sélectionnés" [style]="{minWidth: '200px'}"
										placeholder="Colonnes..."></p-multiSelect>
								</div>
							</div>
						</ng-template>
						<ng-template pTemplate="header" let-columns>
							<tr>

								<th style="min-width: 3rem">
									<p-tableHeaderCheckbox></p-tableHeaderCheckbox>
								</th>
								<th pSortableColumn="quantite">
									<div class="flex justify-content-between align-items-center">
										Qte
										<p-sortIcon field="quantiteDemande"></p-sortIcon>
										<p-columnFilter type="text" field="quantiteDemande" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
							
								<th pSortableColumn="statut.nom">
									<div class="flex justify-content-between align-items-center">
										Statut
										<p-sortIcon field="statut.nom"></p-sortIcon>
										<p-columnFilter field="statut" matchMode="in" display="menu" [showMatchModes]="false"
										[showOperator]="false" [showAddButton]="false">
										<ng-template pTemplate="filter" let-value let-filter="filterCallback">
											<p-multiSelect [ngModel]="value" [options]="statuts" placeholder="Any"
												(onChange)="filter($event.value)" optionLabel="name">
												<ng-template let-option pTemplate="item">
													<div class="multiselect-representative-option">
	
														<span class="ml-1">{{option.nom}}</span>
													</div>
												</ng-template>
											</p-multiSelect>
										</ng-template>
									</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="item.numero">
									<div class="flex justify-content-between align-items-center">
										Numéro
										<p-sortIcon field="item.numero"></p-sortIcon>
										<p-columnFilter type="text" field="item.numero" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="item.description">
									<div class="flex justify-content-between align-items-center">
										Description
										<p-sortIcon field="item.description"></p-sortIcon>
										<p-columnFilter type="text" field="item.description" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th *ngIf="EstColonneSelectionnee('inventaire')" pSortableColumn="item.quantite">
									<div class="flex justify-content-between align-items-center">
										Inv.
										<p-sortIcon field="item.quantite"></p-sortIcon>
										<p-columnFilter type="number" field="item.quantite" display="menu" class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th *ngIf="EstColonneSelectionnee('demandeur')" pSortableColumn="demandeur">
									<div class="flex justify-content-between align-items-center">
										Demandeur
										<p-sortIcon field="demandeur"></p-sortIcon>
										<p-columnFilter type="text" field="demandeur" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th *ngIf="EstColonneSelectionnee('dateDemande')" pSortableColumn="dateDemande">
									<div class="flex justify-content-between align-items-center">
										Date de demande
										<p-sortIcon field="dateDemande"></p-sortIcon>
										<p-columnFilter type="date" field="dateDemande" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th *ngIf="EstColonneSelectionnee('dateRequis')" pSortableColumn="dateRequis">
									<div class="flex justify-content-between align-items-center">
										Date requise
										<p-sortIcon field="dateRequis"></p-sortIcon>
										<p-columnFilter type="date" field="dateRequis" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th *ngIf="EstColonneSelectionnee('expediteur')" pSortableColumn="expediteur">
									<div class="flex justify-content-between align-items-center">
										Expéditeur
										<p-sortIcon field="expediteur"></p-sortIcon>
										<p-columnFilter type="text" field="expediteur" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th *ngIf="EstColonneSelectionnee('camion')" pSortableColumn="camion">
									<div class="flex justify-content-between align-items-center">
										Camion
										<p-sortIcon field="camion"></p-sortIcon>
										<p-columnFilter type="text" field="camion" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th *ngIf="EstColonneSelectionnee('remorque')" pSortableColumn="remorque">
									<div class="flex justify-content-between align-items-center">
										Remorque
										<p-sortIcon field="remorque"></p-sortIcon>
										<p-columnFilter type="text" field="remorque" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th *ngIf="EstColonneSelectionnee('chauffeur')" pSortableColumn="chauffeur">
									<div class="flex justify-content-between align-items-center">
										Chauffeur
										<p-sortIcon field="chauffeur"></p-sortIcon>
										<p-columnFilter type="text" field="chauffeur" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th *ngIf="EstColonneSelectionnee('departement')" pSortableColumn="departement">
									<div class="flex justify-content-between align-items-center">
										Département
										<p-sortIcon field="departement"></p-sortIcon>
										<p-columnFilter type="text" field="departement" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								
							
								<th style="min-width: 10rem; width: 10rem;"></th>
							</tr>
						</ng-template>
						<ng-template pTemplate="body" let-item>
							
							<tr [pContextMenuRow]="item">
								<td>
									<p-tableCheckbox [value]="item"></p-tableCheckbox>
								</td>
								<td>{{item.quantite}} / {{item.quantiteDemande}}</td>
								<td><span [class]="'statut ' + item.statut.nom.replace(' ','').toLowerCase()">
									{{item.statut.nom}}
								</span>
								</td>
							
								<td>{{item.item.numero}}</td>
								<td>{{item.item.description}}</td>
								<td *ngIf="EstColonneSelectionnee('inventaire')">
									<span *ngIf="item.item.categorie.estSuivi" [ngClass]="item.item.quantite && item.item.quantite > 0 ? item.item.quantite >= item.quantite ? 'inventaire disponible' : 'inventaire insuffisant' : 'inventaire nondisponible'">
										{{item.item.quantite}}
									</span>
								</td>
								<td *ngIf="EstColonneSelectionnee('demandeur')">{{item.demandeur | nomEmploye}}</td>
								<td *ngIf="EstColonneSelectionnee('dateDemande')">{{item.dateDemande | date:'yyyy-MM-dd'}}</td>
								<td *ngIf="EstColonneSelectionnee('dateRequis')">{{item.dateRequis | date:'yyyy-MM-dd'}}</td>
								<td *ngIf="EstColonneSelectionnee('expediteur')">{{item.employe | nomEmploye}}</td>
								<td *ngIf="EstColonneSelectionnee('camion')">{{item.camion}}</td>
								<td *ngIf="EstColonneSelectionnee('remorque')">{{item.remorque}}</td>
								<td *ngIf="EstColonneSelectionnee('chauffeur')">{{item.chauffeur}}</td>
								<td *ngIf="EstColonneSelectionnee('departement')">{{item.departement?.nom}}</td>
								<td>
									<button *ngIf="estAutorise('commande_supprimer_item')" pButton pRipple icon="pi pi-trash" class="p-button-danger mr-2 boutonGrille"
									(click)="supprimerItems([item])"></button>
									<button pButton pRipple icon="pi pi-bars" class="p-button-secondary mr-2 boutonGrille"
									(click)="surMenuContextuelItem($event, item)"></button>
								</td>
							</tr>
						</ng-template>
						<ng-template pTemplate="emptymessage">
							<tr>
								<td colspan="8">Aucun item.</td>
							</tr>
						</ng-template>
					</p-table>

		</div>
	</div>


</div>

<div *ngIf="EstCharge()" class="grid">
	<div class="col-12">
		<div class="card">
			<p-menubar autoDisplay="false" [model]="menu"></p-menubar>

		</div>
	</div>
</div>


<p-sidebar [(visible)]="panneauFiltreAffiche" position="right">
    <h5>Filtres</h5>
</p-sidebar>