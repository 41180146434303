<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-menu #menuContextuelNonConformite popup="popup" [model]="menuContextuelNonConformite"></p-menu>

<p-card *ngIf="!EstCharge()">
    <div class="fluid formgrid grid">
        <div class="flex flex-column gap-2 my-2 col-12 text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-card>

<div *ngIf="EstCharge()" class="grid">
    <div class="col-12">
        <div class="card">
            <p-table #dt [value]="nonConformites" [rows]="100" [paginator]="true" [columns]="colonnesSelectionnees" [loading]="!EstCharge()"
                autoLayout="true" [rowsPerPageOptions]="[10,25,50,100,500]"
                [globalFilterFields]="['titre', 'codeProjet', 'statut', 'dateEmission', 'dateDetection', 'objet', 'employe', 'approbation']"
                [(selection)]="selectedNonConformites" [rowHover]="true" dataKey="id"
                currentPageReportTemplate="Affichage de {first} à {last} sur {totalRecords} entrées"
                [showCurrentPageReport]="true">
                <ng-template pTemplate="caption">
                    <div class="flex align-items-center justify-content-between">
                        <div>
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                            <button pButton pRipple icon="pi pi-plus"
                                class="p-button-success mr-2 ml-4 mb-2 boutonGrille"
                                (click)="newNonConformite()"></button>
                            <button pButton class="p-button-outlined boutonGrille mr-2 ml-4 mb-2"
                                icon="pi pi-filter-slash" (click)="clearFilters(dt)"></button>
                        </div>
                        <h5 class="titreTableau m-0">Non-conformités</h5>
                        <div>
                            <p-multiSelect [options]="colonnesDisponibles" [(ngModel)]="selectedColumns"
                                optionLabel="entete" selectedItemsLabel="{0} colonnes sélectionnés"
                                (onChange)="surChangementColonnes($event)" [style]="{minWidth: '200px'}"
                                placeholder="Colonnes..."></p-multiSelect>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="numero">
                            <div class="flex justify-content-between align-items-center">
                                Numéro
                                <p-sortIcon field="numero"></p-sortIcon>
                                <p-columnFilter type="text" field="numero" display="menu" class="ml-auto" />
                            </div>
                        </th>
                        <th pSortableColumn="titre">
                            <div class="flex justify-content-between align-items-center">
                                Titre
                                <p-sortIcon field="titre"></p-sortIcon>
                                <p-columnFilter type="text" field="titre" display="menu" class="ml-auto" />
                            </div>
                        </th>
                        <th pSortableColumn="codeProjet">
                            <div class="flex justify-content-between align-items-center">
                                Projet
                                <p-sortIcon field="codeProjet"></p-sortIcon>
                                <p-columnFilter type="text" field="codeProjet" display="menu" class="ml-auto" />
                            </div>
                        </th>
                        <th pSortableColumn="statut">
                            <div class="flex justify-content-between align-items-center">
                                Statut
                                <p-sortIcon field="statut"></p-sortIcon>
                                <p-columnFilter field="statut" matchMode="in" display="menu" [showMatchModes]="false"
                                    [showOperator]="false" [showAddButton]="false">
                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                        <p-multiSelect [ngModel]="value" [options]="statuts" placeholder="Any"
                                            (onChange)="filter($event.value)" optionLabel="name">
                                            <ng-template let-option pTemplate="item">
                                                <div class="multiselect-representative-option">
                                                    <span class="ml-1">{{option.nom}}</span>
                                                </div>
                                            </ng-template>
                                        </p-multiSelect>
                                    </ng-template>
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="dateEmission">
                            <div class="flex justify-content-between align-items-center">
                                Date d'émission
                                <p-sortIcon field="dateEmission"></p-sortIcon>
                                <p-columnFilter type="date" field="dateEmission" dateFormat="dd/mm/yyyy" display="menu" class="ml-auto" />
                            </div>
                        </th>
                        <th pSortableColumn="dateDetection">
                            <div class="flex justify-content-between align-items-center">
                                Date de détection
                                <p-sortIcon field="dateDetection"></p-sortIcon>
                                <p-columnFilter type="date" field="dateDetection" dateFormat="dd/mm/yyyy" display="menu" class="ml-auto" />
                            </div>
                        </th>
                        <th pSortableColumn="objet">
                            <div class="flex justify-content-between align-items-center">
                                Type
                                <p-sortIcon field="objet"></p-sortIcon>
                                <p-columnFilter type="text" field="objet" display="menu" class="ml-auto" />
                            </div>
                        </th>
                        <th pSortableColumn="objet">
                            <div class="flex justify-content-between align-items-center">
                                Employé
                                <p-sortIcon field="objet"></p-sortIcon>
                                <p-columnFilter type="text" field="objet" display="menu" class="ml-auto" />
                            </div>
                        </th>
                        <th pSortableColumn="approbation">
                            <div class="flex justify-content-between align-items-center">
                                Approbation
                                <p-sortIcon field="approbation"></p-sortIcon>
                                <p-columnFilter type="text" field="approbation" display="menu" class="ml-auto" />
                            </div>
                        </th>
                        <th></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-nonConformite>
                    <tr>
                        <td>
                            <a [routerLink]="'/assurancequalite/nonconformite/' + nonConformite.idNonConformite">{{nonConformite.numero}}</a>
                        </td>
                        <td>{{nonConformite.titre}}</td>
                        <td>{{nonConformite.codeProjet.code}}</td>
                        <td><span [class]="'statut ' + nonConformite.statut.nom.toLowerCase()">
                                {{nonConformite.statut.nom}}
                            </span>
                        </td>
                        <td>{{nonConformite.dateEmission | date: 'dd/MM/yyyy'}}</td>
                        <td>{{nonConformite.dateDetection | date: 'dd/MM/yyyy'}}</td>
                        <td>{{nonConformite.objet}}</td>
                        <td>{{nonConformite.employe | nomEmploye}}</td>
                        <td>{{nonConformite.approbation == null ? 'Non traité' : nonConformite.approbation}}</td>
                        <td class="celluleTableau">
                            <div class="flex">
                                <button pButton pRipple icon="pi pi-eye" class="p-button-success mr-2 boutonGrille"
                                (click)="openNonConformite(nonConformite.idNonConformite)"></button>
                                <button pButton pRipple icon="pi pi-bars"class="p-button-secondary mr-2 boutonGrille"
                                (click)="openHamburgerMenu(nonConformite)"></button>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>