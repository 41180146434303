
<div class="stepsdemo-content">
    <p-card>
        <ng-template pTemplate="title">
            Notes.
        </ng-template>
        <ng-template pTemplate="content">
            <div class="fluid formgrid grid">
                <div class="flex flex-column gap-2 col-12">
                    <textarea [rows]="10" pInputTextarea [(ngModel)]="note" ></textarea>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="footer">
            <div class="grid grid-nogutter justify-content-end">
                <p-button label="Précédent" (onClick)="previousPage()"  icon="pi pi-angle-left" iconPos="left" class="mr-2"></p-button>
                <p-button label="Suivant" (onClick)="nextPage()"  icon="pi pi-angle-right" iconPos="right"></p-button>
            </div>
        </ng-template>
         
    </p-card>
</div>