import {Component, OnInit, HostListener} from "@angular/core"; import { Location } from "@angular/common";
import {PrimeNGConfig} from 'primeng/api';
import { AuthService } from '@auth0/auth0-angular';
import { interval, Subscription } from 'rxjs';
import { DonneesBaseService } from './services/donnees-base-service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    menuMode = 'static';

    colorScheme = 'light';

    menuTheme = 'layout-sidebar-darkgray';

    inputStyle = 'outlined';

    ripple: boolean;


   

    constructor(
        public sdb: DonneesBaseService,
        private primengConfig: PrimeNGConfig, 
        public auth: AuthService) {

            
    
    }

   
    ngOnInit() {
        this.primengConfig.ripple = true;
    }

    ngOnDestroy() {
        /* this.subscription.unsubscribe(); */
      }
}
