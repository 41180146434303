<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<div class="grid">
    <div class="col-12">

        <div class="card">

            <p-menu #menuContextuelItem popup="popup" [model]="contenuMenuContextuelItem"></p-menu>
 
            <p-table #dt [value]="boiteOutils" dataKey="boiteOutilId" tableStyleClass="tableNormale" autoLayout="true"
                 [rowHover]="true"  [filterDelay]="0"
                [globalFilterFields]="['nom','description']">
                
                <ng-template pTemplate="caption">
                    <div class="flex align-content-center justify-content-between">
                        <div>
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input #champRecherche pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                (keyup.enter)="surEntreeRecherche()"
                                    placeholder="Recherche..." />
                            </span>
                            <button *ngIf="estAutorise('admin_boites_outils')" pButton pRipple icon="pi pi-plus"
                            class="p-button-success mr-2 ml-4 mb-2 boutonGrille"
                            (click)="surAjoutBoiteOutil()"></button>
                        </div>
                        <h5 class="titreTableau m-0">Boites d'outils</h5>
                        <div>
                            <p-multiSelect disabled="true" [style]="{minWidth: '200px'}" placeholder="Colonnes..."></p-multiSelect>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        
                        <th pSortableColumn="nom">
                            <div class="flex justify-content-between align-items-center">
                                Nom
                                <p-sortIcon field="nom"></p-sortIcon>
                                <p-columnFilter type="text" field="nom" display="menu" class="ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="description">
                            <div class="flex justify-content-between align-items-center">
                                Description
                                <p-sortIcon field="description"></p-sortIcon>
                                <p-columnFilter type="text" field="description" display="menu" class="ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>

                        <th style="min-width: 7rem; width: 7rem;"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-boiteOutil>
                    <tr [pContextMenuRow]="liste">
                        <td>{{boiteOutil.nom}}</td>
                        <td>{{boiteOutil.description}}</td>
                        <td>
                            <div class="flex">
                                <button pButton pRipple icon="pi pi-eye" class="p-button-success mr-2 boutonGrille"
                                (click)="OuvrirDetail(boiteOutil.boiteOutilId)"></button>
                                <button *ngIf="estAutorise('admin_boites_outils')" pButton pRipple icon="pi pi-bars"
                                    class="p-button-secondary mr-2 boutonGrille"
                                    (click)="surMenuContextuelItem($event, boiteOutil)"></button>
                            </div>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="3">Aucune boite d'outil.</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>


</div>

<p-dialog [(visible)]="dialogueNouvelBoiteOutil" [style]="{width: '85%'}" header="Nouvelle boite d'outils" [modal]="true"
    styleClass="fluid formgrid grid">
    <ng-template pTemplate="content">

        <p-toast key="nouvelBoiteOutil"></p-toast>

        <div class="formgrid grid">
            
            <div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-4">
                <label for="nom">Nom<span class="requis">*</span></label>
                <input type="text" pInputText id="nom" [(ngModel)]="nouvelBoiteOutil.nom" autocomplete="off" />
                <small class="p-error" *ngIf="submitted && !nouvelBoiteOutil.nom">Le nom est obligatoire.</small>
            </div>
            <div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-8">
                <label for="code">Description</label>
                <input type="text" pInputText id="description" [(ngModel)]="nouvelBoiteOutil.description" autocomplete="off" />
            </div>
            
        </div>

    </ng-template>

    <ng-template pTemplate="footer">
        <button pButton pRipple label="Annuler" icon="pi pi-times" class="p-button-text"
            (click)="annulerNouvelBoiteOutil()"></button>
        <button pButton pRipple label="Créer" icon="pi pi-check" class="p-button-text"
            (click)="creerNouvelBoiteOutil()"></button>
    </ng-template>
</p-dialog>