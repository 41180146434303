import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { ReponseBase } from './reponse-base';
import { ReponseObtenirCommande } from './reponses/reponse-obtenir-commande';
import { ItemService } from './item-service';
import { BaseService } from './base-service';
import { lastValueFrom, Observable, tap } from 'rxjs';
import { MessageService } from 'primeng/api';
import { ConversationEntite } from '../entites/conversation';
import { MessageEntite } from '../entites/message';
import { ETypeLienDocument } from '../entites/enums/type-lien-document';

@Injectable({ providedIn: 'root' })
export class ConversationService extends BaseService {

  private obtenirConversationUrl = environment.apiBaseUrl + 'conversation/{id}';
  private obtenirPlusieursMessagesUrl = environment.apiBaseUrl + 'conversation/messages';
  private ajouterMessageUrl = environment.apiBaseUrl + 'conversation/{id}/message';
  private messageUrl = environment.apiBaseUrl + 'conversation/message/{id}';
  constructor(
    public http: HttpClient,
    public router: Router,
    public messageService: MessageService) {
    super(http, router, messageService);
  }

  public ObtenirConversation(id: number): Observable<HttpResponse<ConversationEntite>> {
    return this.http.get<ConversationEntite>(this.obtenirConversationUrl.replace('{id}', id ? id.toString() : '0'), {headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response'}); 
  }

  public ObtenirPlusieursMessages(ids: number[]): Observable<HttpResponse<MessageEntite[]>> {
    let params = new HttpParams();
    ids.forEach(id => params = params.append('ids', id.toString()));
    return this.http.get<MessageEntite[]>(this.obtenirPlusieursMessagesUrl, {headers: new HttpHeaders({ 'Content-Type': 'application/json' }), params: params, observe: 'response'}); 
  }

  public AjouterMessage(id: number, message: MessageEntite): Observable<HttpResponse<MessageEntite>> {
    return this.http.post<MessageEntite>(this.ajouterMessageUrl.replace('{id}', id.toString()), message, {headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response'}); 
  }

  public ModifierMessage(id: number, message: MessageEntite): Observable<HttpResponse<MessageEntite>> {
    return this.http.put<MessageEntite>(this.messageUrl.replace('{id}', id.toString()), message, {headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response'}); 
  }

  public SupprimerMessage(id: number): Observable<HttpResponse<MessageEntite>> {
    return this.http.delete<MessageEntite>(this.messageUrl.replace('{id}', id.toString()), {headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response'}); 
  }
}
