import { Component, OnInit, ViewChild } from "@angular/core"; import { Location } from "@angular/common";
import { Table } from 'primeng/table';
import { BreadcrumbService } from '../../app.breadcrumb.service';

import { RequisitionMin } from '../../entites/requisition-min';
import { MenuItem, TreeNode } from 'primeng/api';
import { MessageService, LazyLoadEvent, ConfirmationService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

import { DialogService } from 'primeng/dynamicdialog';
import { EnvoiRequisitionComponent } from '../../controles/envoi-requisition.component';
import { ItemMin } from '../../entites/item-min';
import { ItemService } from '../../services/item-service';
import { Router, ActivatedRoute } from '@angular/router';

import { ToolbarModule } from 'primeng/toolbar';

import { MenubarModule } from 'primeng/menubar';
import { BaseComponent } from '../../base/base-component';
import { Item } from '../../entites/item';
import { Inventaire } from '../../entites/inventaire';
import { NoteEntite } from '../../entites/note';
import { Fournisseur } from 'src/app/entites/fournisseur';
import { FournisseurService } from 'src/app/services/fournisseur-service';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { ValeurAttribut } from 'src/app/entites/valeur-attribut';
import { CategorieMin } from 'src/app/entites/categorie';
import { SousCategorieEntite } from 'src/app/entites/sousCategorie';
import { Emplacement } from 'src/app/entites/emplacement';
import { EmplacementService } from 'src/app/services/emplacement-service';
import { EStatuts } from 'src/app/entites/enums/statuts';
import { ProfilService } from 'src/app/services/profil-service';
import { AppMainComponent } from 'src/app/app.main.component';
import { ContextMenu } from 'primeng/contextmenu/public_api';
import { RapportService } from 'src/app/services/rapport-service';
import { SelectionEmployeComponent } from 'src/app/controles/selection-employe.component';
import { EmployeEntite } from 'src/app/entites/employe';
import { RechercheAdresseComponent } from 'src/app/controles/recherche-adresse.component';
import { AdresseEntite } from 'src/app/entites/adresse';
import { AttributEntite } from 'src/app/entites/attribut';
import { CodeProjetEntite } from 'src/app/entites/code-projet';

@Component({
    templateUrl: './detail-emplacement.component.html',
    styleUrls: ['./detail-emplacement.component.scss'],
    providers: [MessageService, ConfirmationService, DialogService]
})
export class DetailEmplacementComponent extends BaseComponent implements OnInit {

    emplacementId: number;
    emplacement: Emplacement;

    codesProjet: CodeProjetEntite[];
    
    detailItemId: number;
    afficherDetailItem: boolean = false;

    menu: MenuItem[];

    attributs: AttributEntite[];

    filtreCategories: string[];
    filtreSousCategories: string[];

    chargementCompleteInventaire: boolean = false;
    inventaire: ItemMin[];
    itemInventaire: ItemMin;
    contenuMenuContextuelInventaire: MenuItem[];
    @ViewChild('menuContextuelInventaire') menuContextuelInventaire: ContextMenu;


    chargementCompleteResponsables: boolean = false;
    responsables: EmployeEntite[];


    chargementCompleteChargesProjet: boolean = false;
    chargesProjet: EmployeEntite[];

    chargementCompleteHistorique: boolean = false;
    selectionHistorique: RequisitionMin;
    menuContexteHistorique: MenuItem[];

    dialogueAjustementInventaire = false;


    @ViewChild('dtInventaire') tableInventaire: Table;

    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute,
        public appMain: AppMainComponent, location: Location, 
        private breadcrumbService: BreadcrumbService,
        private ItemsService: ItemService,
        private emplacementService: EmplacementService,
        public rapportService: RapportService,
        private messageService: MessageService,
        private fournisseurService: FournisseurService,
        private confirmationService: ConfirmationService,
        public dialogService: DialogService,
        public routerService: Router) {

        super(sdb, profilService, route, routerService, appMain);

        this.breadcrumbService.setItems([
            { label: 'Emplacements', routerLink: '/pilotage/emplacements' },
            { label: 'Détails' }
        ]);

        this.route.params.subscribe(params => this.emplacementId = params.id);
    }

    ngOnInit() {

        this.verifierAccesPage('emplacement_detail');

        this.attributs = this.sdb.Attributs();

        this.codesProjet = this.sdb.CodesProjet();

        this.colonnesDisponibles = [
            { base: true, champ: 'categorie', entete: 'Catégorie', afficheParDefaut: true },
            { base: true, champ: 'sousCategorie', entete: 'Sous-catégorie', afficheParDefaut: true },
            { base: true, champ: 'marque', entete: 'Marque', afficheParDefaut: true },
            { base: true, champ: 'modele', entete: 'Modèle', afficheParDefaut: true },
            { base: true, champ: 'noCtrl', entete: 'No Ctrl', afficheParDefaut: false },
            { base: true, champ: 'compagnie', entete: 'Compagnie', afficheParDefaut: false }
        ];
        this.attributs.forEach(att => {
            this.colonnesDisponibles.push({
                base: false,
                champ: 'att' + att.attributId,
                entete: att.nom,
                afficheParDefaut: false
            })
        });
        this.colonnesSelectionnees = this.colonnesDisponibles.filter(x => x.afficheParDefaut);

        this.filtreCategories = [];
        this.sdb.Categories().forEach(x => {
            this.filtreCategories.push(x.nom);
        });

        this.filtreSousCategories = [];
        this.sdb.SousCategories().forEach(x => {
            this.filtreSousCategories.push(x.nom);
        });

        this.emplacementService.ObtenirDetail(this.emplacementId).then(res => {

            this.emplacement = res.valeur;

            if (!this.emplacement.adresse) {
                this.emplacement.adresse = {};
            }

            if (this.emplacement.codeProjet) {
                this.emplacement.codeProjet = this.codesProjet.find(x => x.codeProjetId == this.emplacement.codeProjet.codeProjetId);
            }
             

            this.CompleterChargement();

            this.initialiserMenu();

        });




        this.menuContexteHistorique = [
            {
                label: 'Voir la réquisition',
                icon: 'pi pi-fw pi-check-square',
                command: () => this.routerService.navigateByUrl('requisition/' + this.selectionHistorique.requisitionId)
            }
        ];


    }

    initialiserMenu() {
        this.menu = [
            {
                label: 'Retour', icon: 'pi pi-fw pi-angle-double-left', command: () => this.routerService.navigateByUrl('pilotage/emplacements')
            },
            {
                label: 'Actions',
                icon: 'pi pi-fw pi-bars',
                visible: this.estAutorise('emplacement_action_archiver'),
                items: [
                    {
                        label: 'Archiver',
                        icon: 'pi pi-fw pi-times',
                        visible: this.estAutorise('emplacement_action_archiver'),
                        disabled: this.emplacement.statut.statutId == EStatuts.Archive,
                        command: () => this.archiver()
                    }
                ]
            },
        ];
    }

    obtenirValeurAttribut(item: Item, attributId: string): string {
     
        var attId = Number(attributId.replace('att', ''));
        var att = item.valeursAttributs.find(x => x.attribut.attributId == attId);
        if (att) {
            return att.valeur;
        } else {
            return '';
        }
        
    }

    surChangementOnglet(event) {
        if (event.index == 1 && !this.chargementCompleteInventaire) {
            this.emplacementService.ObtenirInventaire(this.emplacementId).then(x => {
                console.log(x);
                this.inventaire = x.valeur;
                this.chargementCompleteInventaire = true;
            });
        }
        if (event.index == 2 && !this.chargementCompleteResponsables) {
            this.emplacementService.ObtenirResponsables(this.emplacementId).then(x => {
                console.log(x);
                this.responsables = x.valeur;
                this.chargementCompleteResponsables = true;
            });
        }
        if (event.index == 3 && !this.chargementCompleteChargesProjet) {
            this.emplacementService.ObtenirChargesProjet(this.emplacementId).then(x => {
                console.log(x);
                this.chargesProjet = x.valeur;
                this.chargementCompleteChargesProjet = true;
            });
        }
        /* if (event.index == 2 && !this.chargementCompleteHistorique) {
            this.ItemsService.ObtenirHistorique(this.itemId).then(x => {
                this.item.historique = x.valeur;

                this.chargementCompleteHistorique = true;
            });
        } */
    }

    btnModifier_click() {
        this.Modifier();
    }

    annulerModification() {
        this.DemarrerChargement();

        this.emplacementService.ObtenirDetail(this.emplacementId).then(res => {

            this.emplacement = res.valeur;
            this.CompleterChargement();
            this.Consulter();

        });


    }

    submitted = false;

    enregistrerModifications() {
        this.submitted = true;
        this.messageService.clear();

        if (this.validerModificationItem()) {
            this.DemarrerChargement();
            this.emplacementService.ModifierEmplacement(this.emplacement).then(x => {
                this.CompleterChargement();
                if (x.estUnSucces) {
                    this.Consulter();
                    this.sdb.ReinitialiserEmplacements();
                    this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Emplacement modifié.' });
                } else {
                    x.messages.forEach(x => {
                        this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description, sticky: true });
                    });

                }

            });


        }
    }

    validerModificationItem(): boolean {

        if (!this.emplacement.code || !this.emplacement.nom) {
            return false;
        }

        return true;
    }

    rechercherAdresse() {
        const ref = this.dialogService.open(RechercheAdresseComponent, {
            header: 'Recherche une adresse',
            width: '50%'
        }).onClose.subscribe((r: AdresseEntite) => {
            if (r) {
                this.emplacement.adresse = r;
            }
        });
    }

    copierDescription() {
        this.emplacement.adresse.nom = this.emplacement.nom;
    }

    surAjoutEmplacement() {
        // TODO
    }

    OuvrirDetailItem(itemId: number) {
        this.routerService.navigateByUrl(`item/${itemId}`)
        this.messageService.add({ severity: 'info', summary: 'Item Selected', detail: itemId.toString() });
    }

    OuvrirDetailItemNouvelOnglet(itemId: number) {
        this.appMain.detailItemId = itemId;
        this.appMain.afficherDetailItem = true;
    }

    archiver() {
        this.routerService.navigateByUrl(`pilotage/emplacement/archiver/${this.emplacement.emplacementId}`)
        /* this.confirmationService.confirm({
           message: `Voulez-vous vraiment archiver l'emplacement ${this.emplacement.nom} ?`,
           accept: () => {
               //TODO
               this.emplacement.statut = this.sdb.Statut(EStatuts.Archive);
               this.initialiserMenu();
               this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Emplacement archivé' });
           }
       }); */
    }



    ajustementInventaire() {
        /* this.confirmationService.confirm({
            message: `Voulez-vous vraiment envoyer l'item ${} en?`,
            accept: () => {
                this.messageService.add({ severity: 'success', summary: 'Bon de commande transféré', detail: 'Nouveau numéro du bon de commande: 15240' });
                this.bdc.numero = '15240';
                this.bdc.statut = 'Complété';
                this.menu[2].disabled = false;
                this.menu[3].items[0].disabled = true;
            }
        }); */
        this.dialogueAjustementInventaire = true;
    }

    ajusterInventaire() {
        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Inventaire ajusté.' });
        this.dialogueAjustementInventaire = false;
    }



    /* TAB: INVENTAIRE */

    surMenuContextuelInventaire(event, inventaire: ItemMin) {
        this.itemInventaire = inventaire;

        this.contenuMenuContextuelInventaire = [
            { label: 'Voir l\'item', icon: 'pi pi-fw pi-eye', command: () => this.routerService.navigateByUrl('item/' + this.itemInventaire.itemId) }
        ];

        this.menuContextuelInventaire.toggle(event);

    }



    exportExcel() {

        var t = this.tableInventaire.value;

        if (this.tableInventaire.filteredValue) {
            t = this.tableInventaire.filteredValue;
        }

       


        var lignes: any[] = t.map(x => ({
            Numéro: x.numero,
            Description: x.description,
            Statut: x.statut.nom
        }));

        for (let index = 0; index < lignes.length; index++) {
            if (this.colonnesSelectionnees.filter(x => x.champ == 'categorie').length == 1) {
                if (t[index].categorie && t[index].categorie.nom) {
                    lignes[index].Categorie = t[index].categorie?.nom;
                } else {
                    lignes[index].Categorie = '';
                }
                lignes[index].Categorie = t[index].categorie?.nom;
            }
            if (this.colonnesSelectionnees.filter(x => x.champ == 'sousCategorie').length == 1) {
                if (t[index].sousCategorie && t[index].sousCategorie.nom) {
                    lignes[index].SousCategorie = t[index].sousCategorie?.nom;
                } else {
                    lignes[index].SousCategorie = '';
                }
            }
            if (this.colonnesSelectionnees.filter(x => x.champ == 'marque').length == 1) {
                if (t[index].marque) {
                    lignes[index].Marque = t[index].marque;
                } else {
                    lignes[index].Marque = '';
                }
            }
            if (this.colonnesSelectionnees.filter(x => x.champ == 'modele').length == 1) {
                if (t[index].modele) {
                    lignes[index].Modele = t[index].modele;
                } else {
                    lignes[index].Modele = '';
                }
            }
            if (t[index].quantite) {
                lignes[index].Quantite = t[index].quantite;
            } else {
                lignes[index].Quantite = '';
            }
            if (this.colonnesSelectionnees.filter(x => x.champ == 'noCtrl').length == 1) {
                if (t[index].noCtrl) {
                    lignes[index].NoCtrl = t[index].noCtrl;
                } else {
                    lignes[index].NoCtrl = '';
                }
            }
            if (this.colonnesSelectionnees.filter(x => x.champ == 'emplacement').length == 1) {
                if (t[index].emplacement && t[index].emplacement.nom) {
                    lignes[index].Emplacement = t[index].emplacement?.nom;
                } else {
                    lignes[index].Emplacement = '';
                }
            }
            if (this.colonnesSelectionnees.filter(x => x.champ == 'compagnie').length == 1) {
                if (t[index].compagnie && t[index].compagnie.nom) {
                    lignes[index].Compagnie = t[index].compagnie?.nom;
                } else {
                    lignes[index].Compagnie = '';
                }
            }

            this.attributs.forEach(att => {

                if (this.colonnesSelectionnees.filter(x => x.champ == 'att' + att.attributId).length == 1) {

                    var a = t[index].valeursAttributs.find(x => x.attribut.attributId == att.attributId);

                    if (a) {
                        lignes[index][att.nom] = a.valeur;
                    } else {
                        lignes[index][att.nom] = '';
                    }
                }
            });

        }

        this.messageService.add({ severity: 'info', summary: 'Demande de rapport', detail: `Votre demande de rapport a été envoyée.` });

        this.rapportService.ProduireRapportExcel({
            contenu: JSON.stringify(lignes)
        }).then(x => {
            if (x) {
                this.messageService.add({ severity: 'success', summary: 'Succès', detail: `Votre rapport a été généré.` });
            } else {
                this.messageService.add({ severity: 'error', summary: 'Erreur', detail: `Une erreur est survenue pendant la génération de votre rapport.` });
            }
        });

    }


    /* TAB: RESPONSABLES */

    ajouterResponsable() {
        const ref = this.dialogService.open(SelectionEmployeComponent, {
            header: 'Ajout d\'un responsable',
            width: '60%'
        }).onClose.subscribe((resSelectionEmploye: EmployeEntite) => {
            if (resSelectionEmploye) {

                this.Bloquer();

                this.emplacementService.AjouterResponsable(this.emplacementId, resSelectionEmploye.employeId).then(resAjoutResponsable => {

                    this.Debloquer();

                    if (resAjoutResponsable.estUnSucces) {
                        this.responsables.push(resSelectionEmploye);
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Responsable ajouté.' });
                    } else {
                        resAjoutResponsable.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                });
            }
        });
    }

    supprimerResponsable(employe: EmployeEntite) {
        this.confirmationService.confirm({
            message: 'Voulez-vous vraiment supprimer le responsable sélectionné ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.emplacementService.SupprimerResponsable(this.emplacementId, employe.employeId).then(res => {
                    if (res.estUnSucces) {
                        this.responsables = this.responsables.filter(val => val.employeId !== employe.employeId);
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Responsable supprimé' });
                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                });
            }
        });
    }

    /* TAB: CHARGÉS DE PROJET */

    ajouterChargeProjet() {
        const ref = this.dialogService.open(SelectionEmployeComponent, {
            header: 'Ajout d\'un chargé de projet',
            width: '60%'
        }).onClose.subscribe((resSelectionEmploye: EmployeEntite) => {
            if (resSelectionEmploye) {

                this.Bloquer();

                this.emplacementService.AjouterChargeProjet(this.emplacementId, resSelectionEmploye.employeId).then(resAjoutResponsable => {

                    this.Debloquer();

                    if (resAjoutResponsable.estUnSucces) {
                        this.chargesProjet.push(resSelectionEmploye);
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Chargé de projet ajouté.' });
                    } else {
                        resAjoutResponsable.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                });
            }
        });
    }

    supprimerChargeProjet(employe: EmployeEntite) {
        this.confirmationService.confirm({
            message: 'Voulez-vous vraiment supprimer le chargé de projet sélectionné ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.emplacementService.SupprimerChargeProjet(this.emplacementId, employe.employeId).then(res => {
                    if (res.estUnSucces) {
                        this.chargesProjet = this.chargesProjet.filter(val => val.employeId !== employe.employeId);
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Chargé de projet supprimé' });
                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                });
            }
        });
    }
}
