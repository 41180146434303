<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-confirmPopup key="itemsnc"></p-confirmPopup>
<p-menu #menuContextuelAjout popup="popup" [model]="contenuMenuContextuelAjout"></p-menu>
<p-menu #menuContextuelItem popup="popup" [model]="contenuMenuContextuelItem"></p-menu>


<p-card *ngIf="!EstCharge()">
    <div class="fluid formgrid grid">
        <div class="flex flex-column gap-2 my-2 col-12 text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-card>

<div *ngIf="EstCharge()" class="grid">
    <div class="col-12">

        <div class="card">
            <p-table #dt [value]="items" [columns]="selectedColumns" dataKey="itemId" autoLayout="true"
                tableStyleClass="tableNormale"  [rowHover]="true"
                [rows]="100" [paginator]="activerPagination" [rowsPerPageOptions]="[10,25,50,100,500]" [filterDelay]="0"
                [globalFilterFields]="['numero','description','statut','categorie','sousCategorie','marque','modele','noSerie','noPlaque','produitsFournisseurChaine','ancienNumero','noTag','emplacement.nom']">
                <ng-template pTemplate="caption">
                    <div class="flex align-items-center justify-content-between">
                        <div>
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input #rechercheItem pInputText type="text"
                                    (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    (keyup.enter)="surEntreeRecherche()" placeholder="Recherche..." />
                            </span>
                            <button *ngIf="estAutorise('item_creer')" pButton pRipple icon="pi pi-plus"
                                class="p-button-success mr-2 ml-4 mb-2 boutonGrille"
                                (click)="surBoutonAjout($event)"></button>

                            <button pButton class="p-button-outlined boutonGrille mr-2 ml-4 mb-2"
                                icon="pi pi-filter-slash" (click)="clearFilters(dt)"></button>
                        </div>
                        <div>
                            <h5 *ngIf="statutId == 901" class="titreTableau m-0">Unités</h5>
                            <h5 *ngIf="statutId != 901"class="titreTableau m-0">Items</h5>
                            <!--  <button pButton pRipple icon="pi pi-exclamation-triangle"
                                *ngIf="contientItemsIncomplets"
                                class="p-button-rounded p-button-warning p-button-outlined p-button-sm mt-2 ml-4"
                                (click)="ItemsNonComplets($event)"></button> -->
                        </div>

                        <div>
                            <button pButton pRipple icon="pi pi-file-pdf"
                                class="p-button-secondary mr-2 ml-4 mb-2 boutonGrille"
                                (click)="exportExcel()"></button>
                            <p-multiSelect [options]="colonnesDisponibles" [(ngModel)]="selectedColumns"
                                optionLabel="entete" (onChange)="surChangementColonnes($event)"
                                selectedItemsLabel="{0} colonnes sélectionnés" [style]="{minWidth: '200px'}"
                                placeholder="Colonnes..."></p-multiSelect>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                    <tr>

                        <th pSortableColumn="numero">
                            <div class="flex justify-content-between align-items-center">
                                Numéro
                                <p-sortIcon field="numero"></p-sortIcon>
                                <p-columnFilter type="text" field="numero" display="menu" class="ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="description">
                            <div class="flex justify-content-between align-items-center">
                                Description
                                <p-sortIcon field="description"></p-sortIcon>
                                <p-columnFilter type="text" field="description" display="menu" class="ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th>
                            <div class="flex justify-content-between align-items-center">
                                Statut
                                <p-columnFilter field="statut" matchMode="in" display="menu" [showMatchModes]="false"
                                    [showOperator]="false" [showAddButton]="false">
                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                        <p-multiSelect [ngModel]="value" [options]="statuts" placeholder="Any"
                                            (onChange)="filter($event.value)" optionLabel="name">
                                            <ng-template let-option pTemplate="item">
                                                <div class="multiselect-representative-option">

                                                    <span class="ml-1">{{option.nom}}</span>
                                                </div>
                                            </ng-template>
                                        </p-multiSelect>
                                    </ng-template>
                                </p-columnFilter>
                            </div>
                        </th>
                        <th *ngIf="EstColonneSelectionnee('categorie')" pSortableColumn="categorie.nom">
                            <div class="flex justify-content-between align-items-center">
                                Catégorie
                                <p-sortIcon field="categorie.nom"></p-sortIcon>
                                <p-columnFilter field="categorie.nom" matchMode="in" display="menu"
                                    [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                        <p-multiSelect [ngModel]="value" [options]="filtreCategories" placeholder="Any"
                                            (onChange)="filter($event.value)" optionLabel="name" filterBy="">
                                            <ng-template let-option pTemplate="item">
                                                <div class="multiselect-representative-option">
                                                    <span class="ml-1">{{option}}</span>
                                                </div>
                                            </ng-template>
                                        </p-multiSelect>
                                    </ng-template>
                                </p-columnFilter>
                            </div>
                        </th>
                        <th *ngIf="EstColonneSelectionnee('sousCategorie')" pSortableColumn="sousCategorie.nom">
                            <div class="flex justify-content-between align-items-center">
                                Sous-catégorie
                                <p-sortIcon field="sousCategorie.nom"></p-sortIcon>
                                <p-columnFilter field="sousCategorie.nom" matchMode="in" display="menu"
                                    [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                        <p-multiSelect [ngModel]="value" [options]="filtreSousCategories"
                                            placeholder="Any" (onChange)="filter($event.value)" optionLabel="name">
                                            <ng-template let-option pTemplate="item">
                                                <div class="multiselect-representative-option">
                                                    <span class="ml-1">{{option}}</span>
                                                </div>
                                            </ng-template>
                                        </p-multiSelect>
                                    </ng-template>
                                </p-columnFilter>
                            </div>
                        </th>
                        <th *ngIf="EstColonneSelectionnee('marque')" pSortableColumn="marque">
                            <div class="flex justify-content-between align-items-center">
                                Marque
                                <p-sortIcon field="marque"></p-sortIcon>
                                <p-columnFilter type="text" field="marque" display="menu" class="ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th *ngIf="EstColonneSelectionnee('modele')" pSortableColumn="modele">
                            <div class="flex justify-content-between align-items-center">
                                Modèle
                                <p-sortIcon field="modele"></p-sortIcon>
                                <p-columnFilter type="text" field="modele" display="menu" class="ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th *ngIf="EstColonneSelectionnee('noProduit')">
                            <div class="flex justify-content-between align-items-center">
                                No pièce fournisseur
                            </div>
                        </th>
                        <th *ngIf="EstColonneSelectionnee('noCtrl')" pSortableColumn="noCtrl">
                            <div class="flex justify-content-between align-items-center">
                                No Ctrl
                                <p-sortIcon field="noCtrl"></p-sortIcon>
                                <p-columnFilter type="text" field="noCtrl" display="menu" class="ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th *ngIf="EstColonneSelectionnee('emplacement')" pSortableColumn="emplacement.nom">
                            <div class="flex justify-content-between align-items-center">
                                Emplacement
                                <p-sortIcon field="emplacement"></p-sortIcon>
                                <p-columnFilter field="emplacement.emplacementId" matchMode="in" display="menu"
                                    [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                        <p-multiSelect [(ngModel)]="selectionEmplacements" [options]="emplacements"
                                            [showToggleAll]="true" optionLabel="nom"
                                            (onChange)="filter(ObtenirIdEmplacements($event.value))"
                                            [showClear]="true">
                                        </p-multiSelect>
                                    </ng-template>
                                </p-columnFilter>
                            </div>
                        </th>
                        <th *ngIf="EstColonneSelectionnee('compagnie')" pSortableColumn="compagnie.nom">
                            <div class="flex justify-content-between align-items-center">
                                Compagnie
                                <p-sortIcon field="compagnie.nom"></p-sortIcon>
                                <p-columnFilter field="compagnie.nom" matchMode="in" display="menu"
                                    [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                        <p-multiSelect [ngModel]="value" [options]="filtreCompagnies" placeholder="Any"
                                            (onChange)="filter($event.value)" optionLabel="name">
                                            <ng-template let-option pTemplate="item">
                                                <div class="multiselect-representative-option">
                                                    <span class="ml-1">{{option}}</span>
                                                </div>
                                            </ng-template>
                                        </p-multiSelect>
                                    </ng-template>
                                </p-columnFilter>
                            </div>
                        </th>
                        <ng-container *ngFor="let col of colonnesSelectionnees">
                            <th *ngIf="!col.base">
                                <div class="flex justify-content-between align-items-center">
                                    {{col.entete}}
                                </div>
                            </th>
                        </ng-container>
                        <th style="min-width: 10rem; width: 10rem;"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item let-columns="columns">
                    <tr [pContextMenuRow]="item">
                        <td>
                            <a *ngIf="estAutorise('item_detail')" [routerLink]="'/item/' + item.itemId">{{item.numero}}</a>
                            <span *ngIf="!estAutorise('item_detail')">{{item.numero}}</span>

                        </td>
                        <td>{{item.description}}</td>
                        <td><span [class]="'statut ' + item.statut.nom.toLowerCase()">
                                {{item.statut.nom}}
                            </span>
                        </td>
                        <td *ngIf="EstColonneSelectionnee('categorie')">
                            {{item.categorie.nom}}
                        </td>
                        <td *ngIf="EstColonneSelectionnee('sousCategorie')">
                            {{item.sousCategorie?.nom}}
                        </td>
                        <td *ngIf="EstColonneSelectionnee('marque')">
                            {{item.marque}}
                        </td>
                        <td *ngIf="EstColonneSelectionnee('modele')">
                            {{item.modele}}
                        </td>
                        <td *ngIf="EstColonneSelectionnee('noProduit')" style="white-space: pre-line;">
                            {{item.produitsFournisseurChaine}}
                        </td>
                        <td *ngIf="EstColonneSelectionnee('noCtrl')"> 
                            {{item.noCtrl}}
                        </td>
                        <td *ngIf="EstColonneSelectionnee('emplacement')">
                            {{item.emplacement | nomEmplacement}}
                        </td>
                        <td *ngIf="EstColonneSelectionnee('compagnie')">
                            {{item.compagnie?.nom}}
                        </td>
                        <ng-container *ngFor="let col of colonnesSelectionnees">
                            <td *ngIf="!col.base">
                                {{obtenirValeurAttribut(item, col.champ)}}
                            </td>
                        </ng-container>
                        <td class="celluleTableau">
                            <div class="flex">
                                <button *ngIf="estAutorise('item_detail')" pButton pRipple icon="pi pi-eye"
                                    class="p-button-success mr-2 boutonGrille"
                                    (click)="OuvrirDetailItem(item.itemId)"></button>
                                <button *ngIf="estAutorise('item_detail')" pButton pRipple icon="pi pi-external-link"
                                    class="p-button-secondary mr-2 boutonGrille"
                                    (click)="OuvrirDetailItemNouvelOnglet(item.itemId)"></button>
                                <button *ngIf="estAutorise('item_creer')" pButton pRipple icon="pi pi-bars"
                                    class="p-button-secondary mr-2 boutonGrille"
                                    (click)="surMenuContextuelItem($event, item)"></button>
                            </div>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="8">Aucun item.</td>
                    </tr>
                </ng-template>
            </p-table>

            <!--  <p-table [columns]="ccc" [value]="items" styleClass="p-datatable-responsive-demo">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th *ngFor="let col of columns">
                            {{col.header}}
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                    <tr>
                        <td *ngFor="let col of columns">
                            <span class="p-column-title">{{col.header}}</span>
                            {{rowData[col.flex flex-column gap-2 my-2]}}
                        </td>
                    </tr>
                </ng-template>
            </p-table> -->

        </div>
    </div>


</div>



<p-dialog [(visible)]="dialogueNouvelItem" [style]="{width: '85%'}" header="Nouvel item" [modal]="true"
    styleClass="fluid formgrid grid">
    <ng-template pTemplate="content">

        <p-toast key="nouvelItem"></p-toast>

        <div class="formgrid grid">
            <div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-4">
                <label for="inventoryStatus">Catégorie<span class="requis">*</span></label>
                <p-dropdown [(ngModel)]="nouvelItem.categorie" inputId="categorie" [options]="listeCategories"
                    (onChange)="surSelectionCategorie()" optionLabel="nom" placeholder="Sélectionnez..."
                    appendTo="body">
                    <ng-template let-option pTemplate="item">
                        <span>{{option.nom}}</span>
                    </ng-template>
                </p-dropdown>
                <small class="p-error" *ngIf="submitted && !nouvelItem.categorie">La catégorie est obligatoire.</small>
            </div>
            <div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-4">
                <label for="inventoryStatus">Sous-catégorie</label>
                <p-dropdown [options]="listeSousCategories" [(ngModel)]="nouvelItem.sousCategorie" optionLabel="nom"
                    [disabled]="!nouvelItem.categorie" (onChange)="surChangementSousCategorie($event.value)"
                    (onHide)="surFermetureSousCategorie($event)" [showClear]="true" appendTo="body" filter="true"
                    filterBy="nom" placeholder="Sélectionnez une sous-catégorie...">
                    <ng-template pTemplate="selectedItem">
                        <div *ngIf="nouvelItem.sousCategorie">
                            <div>{{nouvelItem.sousCategorie.nom}}</div>
                        </div>
                    </ng-template>
                    <ng-template let-sousCategorie pTemplate="item">
                        <div>
                            <div>{{sousCategorie.nom}}</div>
                        </div>
                    </ng-template>
                </p-dropdown>
            </div>
            <div *ngIf="nouvelItem.categorie && nouvelItem.categorie.estUnique" class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-4">
                <label for="compagnie">Compagnie<span class="requis">*</span></label>
                <p-dropdown [options]="listeCompagnies" [(ngModel)]="nouvelItem.compagnie" optionLabel="nom"
                    [showClear]="true" appendTo="body" filter="true" filterBy="nom"
                    placeholder="Sélectionnez une compagnie...">
                    <ng-template pTemplate="selectedItem">
                        <div *ngIf="nouvelItem.compagnie">
                            <div>{{nouvelItem.compagnie.nom}}</div>
                        </div>
                    </ng-template>
                    <ng-template let-compagnie pTemplate="item">
                        <div>
                            <div>{{compagnie.nom}}</div>
                        </div>
                    </ng-template>
                </p-dropdown>
                <small class="p-error" *ngIf="submitted && !nouvelItem.compagnie">La compagnies est obligatoire.</small>
            </div>
        </div>
        <div class="formgrid grid" *ngIf="nouvelItem.categorie">
            <div class="flex flex-column gap-2 my-2 col-10 md:col-4 lg:col-3" *ngIf="nouvelItem.categorie.estUnique">
                <label for="noSIHC">No SIHC<span class="requis">*</span></label>
                <input #champNumero type="text" pInputText id="noSIHC" [(ngModel)]="nouvelItem.numero"
                    autocomplete="off" autofocus />
                <small class="p-error" *ngIf="submitted && !nouvelItem.numero">Le numéro SIHC est
                    obligatoire.</small>
            </div>
            <div class="flex flex-column gap-2 my-2 col-2 md:col-2 lg:col-1" *ngIf="nouvelItem.categorie.estUnique">
                <button pButton pRipple icon="pi pi-search" class="p-button-rounded p-button-primary mr-2 mt-3"
                    (click)="obtenirProchainNumero()"></button>
            </div>
            <div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-8">
                <label for="description">Description<span class="requis">*</span></label>
                <!--  <input #descriptionNouvelItem type="text" pInputText id="description"
                    [(ngModel)]="nouvelItem.description" autocomplete="off" /> -->
                <p-autoComplete #champDescription appendTo="body" [(ngModel)]="nouvelItem.description"
                    [suggestions]="suggestionDescription" (completeMethod)="rechercherDescritpion($event)">
                </p-autoComplete>
                <small class="p-error" *ngIf="submitted && !nouvelItem.description">La description
                    est
                    obligatoire.</small>
            </div> 
        </div>
        <div class="formgrid grid" *ngIf="nouvelItem.categorie">
            <div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-4">
                <label for="marque">Marque</label>
                <!-- <input type="text" pInputText id="marque" [(ngModel)]="nouvelItem.marque" autocomplete="off"
                    autofocus /> -->
                <p-autoComplete appendTo="body" [(ngModel)]="nouvelItem.marque" [suggestions]="suggestionMarque"
                    (completeMethod)="rechercherMarque($event)"></p-autoComplete>
            </div>
            <div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-4">
                <label for="modele">Modèle</label>
                <input type="text" pInputText id="modele" [(ngModel)]="nouvelItem.modele" autocomplete="off"
                    autofocus />
            </div>
            <div *ngIf="nouvelItem.categorie.estFacturable" class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-4">
                <label for="noCtrl">Numéro CTRL</label>
                <input type="text" pInputText id="noCtrl" [(ngModel)]="nouvelItem.noCtrl" autocomplete="off"
                    autofocus />
            </div>

        </div>
        <div class="formgrid grid" *ngIf="nouvelItem.categorie">
            <div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-4" *ngIf="nouvelItem.categorie.estUnique">

                <label for="lastname">Emplacement de départ<span class="requis">*</span></label>
                <p-dropdown [options]="emplacements" [(ngModel)]="nouvelItem.emplacement" optionLabel="nom"
                    appendTo="body" [showClear]="true" filter="true" filterBy="code,nom"
                    placeholder="Sélectionner un emplacement...">
                    <ng-template pTemplate="selectedItem">
                        <div *ngIf="nouvelItem.emplacement">
                            <div>{{nouvelItem.emplacement | nomEmplacement}}</div>
                        </div>
                    </ng-template>
                    <ng-template let-emplacement pTemplate="item">
                        <div>
                            <div>{{emplacement | nomEmplacement}}</div>
                        </div>
                    </ng-template>
                </p-dropdown>
                <small class="p-error" *ngIf="submitted && !nouvelItem.emplacement">L'emplacement de départ
                    est
                    obligatoire.</small>
            </div>
            <div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-4">

                <label for="lastname">Localisation entrepot</label>
                <p-dropdown [options]="listeLocalisations" [(ngModel)]="nouvelItem.localisation" optionLabel="nom"
                    appendTo="body" [showClear]="true" filter="true" filterBy="code,nom"
                    placeholder="Sélectionner une localisation...">
                    <ng-template pTemplate="selectedItem">
                        <div *ngIf="nouvelItem.localisation">
                            <div>{{nouvelItem.localisation.nom}}</div>
                        </div>
                    </ng-template>
                    <ng-template let-loc pTemplate="item">
                        <div>
                            <div>{{loc.nom}}</div>
                        </div>
                    </ng-template>
                </p-dropdown>
            </div>
            <div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-4" *ngIf="nouvelItem.categorie.estFacturable">

                <label for="lastname">Type de facturation</label>
                <p-dropdown [options]="listeTypesFacturation" [(ngModel)]="nouvelItem.typeFacturation" optionLabel="nom"
                    appendTo="body" [showClear]="true" filter="true" filterBy="code,nom"
                    placeholder="Sélectionner un type de facturation...">
                    <ng-template pTemplate="selectedItem">
                        <div *ngIf="nouvelItem.typeFacturation">
                            <div>{{nouvelItem.typeFacturation.nom}}</div>
                        </div>
                    </ng-template>
                    <ng-template let-typeFacturation pTemplate="item">
                        <div>
                            <div>{{typeFacturation.nom}}</div>
                        </div>
                    </ng-template>
                </p-dropdown>
            </div>
        </div>

    </ng-template>

    <ng-template pTemplate="footer">

        <button pButton pRipple label="Créer" icon="pi pi-save" class="p-button-primary"
            (click)="creerNouvelItem()"></button>
        <button pButton pRipple label="Annuler" icon="pi pi-ban" class="p-button-text"
            (click)="annulerNouvelItem()"></button>
    </ng-template>
</p-dialog>
<p-sidebar [(visible)]="afficherDetailItem" [style]="{width:'95%'}" position="left">
    <app-detail-item [itemId]="detailItemId" *ngIf="afficherDetailItem"></app-detail-item>
</p-sidebar>