import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from "@angular/core"; import { Location } from "@angular/common";

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { ReponseBase } from './reponse-base';
import { RequisitionMin } from '../entites/requisition-min';
import { Requisition } from '../entites/requisition';
import { ReponseCreerItemRequisition } from './reponses/reponse-creer-item-requisition';
import { RequeteCreerItemRequisition } from './requetes/requete-creer-item-requisition';
import { ReponseObtenirRequisition } from './reponses/reponse-obtenir-requisition';
import { RequeteRetourExpress } from './requetes/requete-retour-express';
import { RequeteTransfererItemCommande } from './requetes/requete-transferer-item-commande';
import { ReponseTransfererItemCommande } from './reponses/reponse-transferer-item-commande';
import { ItemCommandeEntite } from '../entites/item-commande';
import { ReponseRetournerItemCommande } from './reponses/reponse-retourner-item-commande';
import { RequeteRetournerItemCommande } from './requetes/requete-retourner-item-commande';
import { RequeteCreerRequisitionsSelonTransporteurs } from './requetes/requete-creer-requisitions-selon-transporteurs';
import { ItemService } from './item-service';
import { RequeteAjouterBoiteOutil } from './requetes/requete-ajout-boite-outil';

@Injectable({ providedIn: 'root' })
export class RequisitionService {

  private obtenirListeUrl = environment.apiBaseUrl + 'requisition';
  private obtenirDetailUrl = environment.apiBaseUrl + 'requisition/';
  private obtenirResumeUrl = environment.apiBaseUrl + 'requisition/resume';
  private obtenirCommandelUrl = environment.apiBaseUrl + 'requisition/commande/{emplacementId}';
  private modifierUrl = environment.apiBaseUrl + 'requisition/modifier';
  private creerRequisitionUrl = environment.apiBaseUrl + 'requisition';
  private creerItemRequisitionUrl = environment.apiBaseUrl + 'requisition/item';
  private supprimerRequisitionUrl = environment.apiBaseUrl + 'requisition/';
  private retourExpressUrl = environment.apiBaseUrl + 'requisition/retourExpress';
  private transfertCommandeUrl = environment.apiBaseUrl + 'requisition/transfertCommande';
  private retourCommandeUrl = environment.apiBaseUrl + 'requisition/retourCommande';
  private supprimerItemsUrl = environment.apiBaseUrl + 'requisition/supprimerItems';
  private creerSelonTranporteursUrl = environment.apiBaseUrl + 'requisition/creerSelonTransporteurs';
  private obtenirRetoursActifsUrl = environment.apiBaseUrl + 'requisition/retoursactifs';
  private ajouterBoiteOutilUrl = environment.apiBaseUrl + 'requisition/boiteoutil';

  public httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(
    public itemService: ItemService,
    private http: HttpClient,
    public router: Router) { }


  public ObtenirRequisitions(): Promise<ReponseBase<RequisitionMin[]>> {
    var p = this.http.get<ReponseBase<RequisitionMin[]>>(this.obtenirListeUrl)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public ObtenirRequisition(RequisitionId: number): Promise<ReponseBase<ReponseObtenirRequisition>> {

    var p = this.http.get<ReponseBase<ReponseObtenirRequisition>>(this.obtenirDetailUrl + RequisitionId)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public ObtenirResumeRequisitions(ids: number[]): Promise<ReponseBase<RequisitionMin[]>> {
    var p = this.http.post<ReponseBase<RequisitionMin[]>>(this.obtenirResumeUrl, JSON.stringify(ids), this.httpOptions)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public ObtenirCommande(emplacementId: number): Promise<ReponseBase<ItemCommandeEntite[]>> {

    var p = this.http.get<ReponseBase<ItemCommandeEntite[]>>(this.obtenirCommandelUrl.replace('{emplacementId}', emplacementId.toString()))
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public CreerRequisition(requete: Requisition): Promise<ReponseBase<Requisition>> {
    var p = this.http.post<ReponseBase<Requisition>>(this.creerRequisitionUrl, JSON.stringify(requete), this.httpOptions).toPromise();

    p.catch(() => this.router.navigateByUrl('error'));

    p.then(() => this.itemService.ReinitialiserItems());

    return p;
  }

  public ModifierRequisition(requisition: Requisition): Promise<ReponseBase<Requisition>> {
    var p = this.http.post<ReponseBase<Requisition>>(this.modifierUrl, JSON.stringify(requisition), this.httpOptions).toPromise();

    p.catch(() => this.router.navigateByUrl('error'));

    p.then(() => this.itemService.ReinitialiserItems());

    return p;
  }


  public CreerItemRequisition(requete: RequeteCreerItemRequisition): Promise<ReponseBase<ReponseCreerItemRequisition>> {
    var p = this.http.post<ReponseBase<ReponseCreerItemRequisition>>(this.creerItemRequisitionUrl, JSON.stringify(requete), this.httpOptions).toPromise();

    p.catch(() => this.router.navigateByUrl('error'));

    p.then(() => this.itemService.ReinitialiserItems());

    return p;
  }

  public SupprimerRequisition(requisitionId: number): Promise<ReponseBase<boolean>> {
    var p = this.http.delete<ReponseBase<boolean>>(this.supprimerRequisitionUrl + requisitionId).toPromise();

    p.catch(() => this.router.navigateByUrl('error'));

    p.then(() => this.itemService.ReinitialiserItems());

    return p;
  }

  public RetourExpress(requete: RequeteRetourExpress): Promise<ReponseBase<number>> {
    var p = this.http.post<ReponseBase<number>>(this.retourExpressUrl, JSON.stringify(requete), this.httpOptions).toPromise();

    p.catch(() => this.router.navigateByUrl('error'));

    p.then(() => this.itemService.ReinitialiserItems());

    return p;
  }

  public TransfertCommande(requete: RequeteTransfererItemCommande): Promise<ReponseBase<ReponseTransfererItemCommande>> {
    var p = this.http.post<ReponseBase<ReponseTransfererItemCommande>>(this.transfertCommandeUrl, JSON.stringify(requete), this.httpOptions).toPromise();

    p.catch(() => this.router.navigateByUrl('error'));

    p.then(() => this.itemService.ReinitialiserItems());

    return p;
  }

  public RetournerCommande(requete: RequeteRetournerItemCommande): Promise<ReponseBase<ReponseRetournerItemCommande>> {
    var p = this.http.post<ReponseBase<ReponseRetournerItemCommande>>(this.retourCommandeUrl, JSON.stringify(requete), this.httpOptions).toPromise();

    p.catch(() => this.router.navigateByUrl('error'));

    p.then(() => this.itemService.ReinitialiserItems());

    return p;
  }

  public SupprimerItemsRequisition(ids: number[]): Promise<ReponseBase<number[]>> {
    var p = this.http.post<ReponseBase<number[]>>(this.supprimerItemsUrl, JSON.stringify(ids), this.httpOptions ).toPromise();

    p.catch(() => this.router.navigateByUrl('error'));

    p.then(() => this.itemService.ReinitialiserItems());

    return p;
  }

  public CreerSelonTransporteurs(requete: RequeteCreerRequisitionsSelonTransporteurs): Promise<ReponseBase<number[]>> {
    var p = this.http.post<ReponseBase<number[]>>(this.creerSelonTranporteursUrl, JSON.stringify(requete), this.httpOptions ).toPromise();

    p.catch(() => this.router.navigateByUrl('error'));

    p.then(() => this.itemService.ReinitialiserItems());

    return p;
  }

  public ObtenirRetoursActifs(): Promise<ReponseBase<RequisitionMin[]>> {
    var p = this.http.get<ReponseBase<RequisitionMin[]>>(this.obtenirRetoursActifsUrl)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public AjouterBoiteOutil(requete: RequeteAjouterBoiteOutil): Promise<ReponseBase<ReponseCreerItemRequisition>> {
    var p = this.http.post<ReponseBase<ReponseCreerItemRequisition>>(this.ajouterBoiteOutilUrl, JSON.stringify(requete), this.httpOptions).toPromise();

    p.catch(() => this.router.navigateByUrl('error'));

    p.then(() => this.itemService.ReinitialiserItems());

    return p;
  }

  public RequisitionVersRequisitionMin(req: Requisition): RequisitionMin {
    const r: RequisitionMin = {
      requisitionId: req.requisitionId,
      type: req.type,
      date: req.date,
      de: req.de,
      a: req.a,
      numero: req.numero,
      camion: req.camion,
      remorque: req.remorque,
      chauffeur: req.chauffeur,
      demandeur: req.demandeur
    };

    return r;
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);

      this.log(`${operation} failed: ${error.message}`);

      this.router.navigateByUrl('erreurtechnique');

      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(message);
  }
}
