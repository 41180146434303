import { Component, OnInit, ViewChild } from "@angular/core"; import { Location } from "@angular/common";
import { Table } from 'primeng/table';
import { BreadcrumbService } from '../../app.breadcrumb.service';

import { RequisitionMin } from '../../entites/requisition-min';
import { MenuItem, TreeNode } from 'primeng/api';
import { MessageService, LazyLoadEvent, ConfirmationService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

import { DialogService } from 'primeng/dynamicdialog';
import { EnvoiRequisitionComponent } from '../../controles/envoi-requisition.component';
import { ItemMin } from '../../entites/item-min';
import { ItemService } from '../../services/item-service';
import { Router, ActivatedRoute } from '@angular/router';

import { ToolbarModule } from 'primeng/toolbar';

import { MenubarModule } from 'primeng/menubar';
import { BaseComponent } from '../../base/base-component';
import { Item } from '../../entites/item';
import { Inventaire } from '../../entites/inventaire';
import { NoteEntite } from '../../entites/note';
import { Fournisseur } from 'src/app/entites/fournisseur';
import { FournisseurService } from 'src/app/services/fournisseur-service';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { ValeurAttribut } from 'src/app/entites/valeur-attribut';
import { CategorieMin, CategorieEntite } from 'src/app/entites/categorie';
import { SousCategorieEntite } from 'src/app/entites/sousCategorie';
import { Emplacement } from 'src/app/entites/emplacement';
import { EmplacementService } from 'src/app/services/emplacement-service';
import { EStatuts } from 'src/app/entites/enums/statuts';
import { ProfilService } from 'src/app/services/profil-service';
import { AppMainComponent } from 'src/app/app.main.component';
import { ContextMenu } from 'primeng/contextmenu/public_api';
import { InfoUtilisationEquipement } from 'src/app/services/reponses/reponse-rapport-utilisation-equipements';
import { RapportService } from 'src/app/services/rapport-service';
import { CleValeur } from 'src/app/entites/cleValeur';
import { InfoEntreeSortie } from 'src/app/services/reponses/reponse-rapport-entrees-sorties';
import { AttributEntite } from 'src/app/entites/attribut';

@Component({
    templateUrl: './rapport-entree-sortie.component.html',
    providers: [MessageService, ConfirmationService, DialogService]
})
export class RapportEntreeSortieComponent extends BaseComponent implements OnInit {

    detailItemId: number;
    afficherDetailItem: boolean = false;
    
    dateDebut: Date;
    dateFin: Date;

    categories: CategorieEntite[];
    selectionCategorie: CategorieEntite[];
    filtreEmplacement: string[];

    emplacements: Emplacement[];
    selectionEmplacement: Emplacement;

    typesMouvement: CleValeur[];
    selectionTypeMouvement: CleValeur;

    attributs: AttributEntite[];

    chargementCompleteResultats: boolean = false;

    resulats: InfoEntreeSortie[] = [];

    tabIndex: number = 0;

    contenuMenuContextuel: MenuItem[];
    @ViewChild('menuContextuel') menuContextuel: ContextMenu;


    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute,
        appMain: AppMainComponent, location: Location, 
        private breadcrumbService: BreadcrumbService,
        private rapportService: RapportService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        public dialogService: DialogService,
        public routerService: Router) {

        super(sdb, profilService, route, routerService, appMain);

        this.breadcrumbService.setItems([
            { label: 'Rapport d\'entrées / sorties' }
        ]);
    }

    ngOnInit() {

        this.verifierAccesPage('rapport_entrees_sorties');

        this.typesMouvement = [
            { "cle": "T", "valeur": "Tous" },
            { "cle": "E", "valeur": "Entrées" },
            { "cle": "S", "valeur": "Sorties" }
        ];
        this.selectionTypeMouvement = this.typesMouvement[0];
        this.categories = this.sdb.Categories();

        this.attributs = this.sdb.Attributs();

        this.emplacements = this.sdb.Emplacements();
        this.filtreEmplacement = [];
        this.emplacements.forEach(x => {
            this.filtreEmplacement.push(x.nom);
        });

        this.colonnesDisponibles = [
            { base: true, champ: 'date', entete: 'Date', afficheParDefaut: true },
            { base: true, champ: 'categorie', entete: 'Catégorie', afficheParDefaut: true },
            { base: true, champ: 'numero', entete: 'Numéro', afficheParDefaut: true },
            { base: true, champ: 'description', entete: 'Description', afficheParDefaut: true },
            { base: true, champ: 'de', entete: 'De', afficheParDefaut: true },
            { base: true, champ: 'a', entete: 'À', afficheParDefaut: true },
            { base: true, champ: 'noRequisition', entete: 'No Réquisition', afficheParDefaut: true },
            { base: true, champ: 'quantite', entete: 'Quantité', afficheParDefaut: true },
            { base: true, champ: 'marque', entete: 'Marque', afficheParDefaut: false },
            { base: true, champ: 'modele', entete: 'Modèle', afficheParDefaut: false },
            { base: true, champ: 'noCtrl', entete: 'NoCtrl', afficheParDefaut: false },
            { base: true, champ: 'emplacement', entete: 'Emplacement', afficheParDefaut: false },
            { base: true, champ: 'compagnie', entete: 'Compagnie', afficheParDefaut: false }
        ];

        this.attributs.forEach(att => {
            this.colonnesDisponibles.push({
                base: false,
                champ: 'att' + att.attributId,
                entete: att.nom,
                afficheParDefaut: false
            })
        });

        this.colonnesSelectionnees = this.colonnesDisponibles.filter(x => x.afficheParDefaut);

        this.rapportService.ObtenirDatesRapportUtilisationEquipements().then(res => {
            this.dateDebut = new Date(res.valeur.dateDebut);
            this.dateFin = new Date(res.valeur.dateFin);

            //TODO : TEMP
            //this.selectionEmplacement = this.emplacements.find(x => x.emplacementId == 320);
            this.selectionCategorie = this.categories;

            this.CompleterChargement();
        });

    }

    submitted = false;

    generer() {

        this.submitted = true;
        this.messageService.clear();

        if (this.valider()) {

            this.Bloquer();
            this.rapportService.ObtenirRapportEntreesSorties({
                typeMouvement: this.selectionTypeMouvement.cle,
                categorieIds: this.selectionCategorie.map(x => x.categorieId),
                emplacementId: this.selectionEmplacement?.emplacementId,
                dateDebut: this.dateDebut,
                dateFin: this.dateFin
            }).then(res => {
                this.Debloquer();

                if (res.estUnSucces) {
                    this.resulats = res.valeur.infoEntreeSortie;
                    this.tabIndex = 1;
                    console.log(this.resulats);
                } else {
                    res.messages.forEach(x => {
                        this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                    });
                }

            });
        }
    }

    valider() {
        return this.selectionCategorie &&
            this.selectionCategorie.length > 0 &&
            this.selectionEmplacement &&
            this.dateDebut &&
            this.dateFin
    }


    surMenuContextuel(event, info: InfoEntreeSortie) {

        this.contenuMenuContextuel = [
            {
                label: 'Voir l\'item',
                icon: 'pi pi-fw pi-eye',
                visible: this.estAutorise('item_detail'),
                command: () => this.routerService.navigateByUrl('item/' + info.item.itemId)
            },
            {
                label: 'Voir la réquisition',
                icon: 'pi pi-fw pi-eye',
                visible: this.estAutorise('requisition_detail'),
                command: () => this.routerService.navigateByUrl('requisition/' + info.requisition.requisitionId)
            }
        ];

        this.menuContextuel.toggle(event);

    }

    OuvrirDetailItemNouvelOnglet(itemId: number) {
        this.detailItemId = itemId;
        this.afficherDetailItem = true;
    }

    obtenirValeurAttribut(item: Item, attributId: string): string {
        var attId = Number(attributId.replace('att',''));
        var att =  item.valeursAttributs.find(x => x.attribut.attributId == attId);
        if (att) {
            return att.valeur;
        } else {
            return '';
        }
    }
}
