import { Pipe, PipeTransform } from "@angular/core"; import { Location } from "@angular/common";
import { EmployeEntite } from '../entites/employe';
import { Emplacement } from '../entites/emplacement';

@Pipe({
  name: 'typeChamp'
})
export class TypeChampPipe implements PipeTransform {

  transform(value: number): string {
    switch(value) { 
      case 1: { return 'Texte'; } 
      case 2: { return 'Numérique'; } 
      case 3: { return 'Décimale'; } 
      case 4: { return 'Date'; } 
      case 5: { return 'Vrai/Faux'; } 
      default: { return ''; } 
   }
  }

}
