import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { MessageService, ConfirmationService } from 'primeng/api';
import { OptionEtapeBonTravail } from 'src/app/entites/mecanique/option-etape-bon-travail';
import { SimpleChanges } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'option-etape-bon-travail',
  templateUrl: './option-etape-bon-travail.component.html',
  providers: [MessageService, ConfirmationService]
})
export class OptionEtapeBonTravailComponent implements OnInit, OnChanges {
  @Input({ required: true }) options: OptionEtapeBonTravail[] = [];
  @Input({ required: true }) optionSelectionnee: string;
  @Input() disabled: boolean = false;
  @Input() readonly: boolean = false;
  @Input() modifySelection: boolean = false;

  @Output() optionSelectionneeChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() optionsChange: EventEmitter<OptionEtapeBonTravail[]> = new EventEmitter<OptionEtapeBonTravail[]>();
  @Output() onChange: EventEmitter<OptionEtapeBonTravail> = new EventEmitter<OptionEtapeBonTravail>();

  get _optionSelectionnee(): string {
    return this.optionSelectionnee;
  }

  set _optionSelectionnee(value: string) {
    this.optionSelectionnee = value;
    this.optionSelectionneeChange.emit(value);
  }

  modification: boolean = false;
  index: number;

  constructor(
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    console.log(this.modifySelection);
    if (this.options == null || this.options.length == 0) {
      this.options = [];
      this.addOption();
      this.options[0].id = this.optionSelectionnee;
      this.optionsChange.emit(this.options);
    } 
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.options.length == 0)
      return;
    
    var description = this.options[this.options.length - 1].description;

    if (changes['readonly'] && !changes['readonly'].currentValue && (description != '' && description != undefined)) {
      this.addOption();

    } else if (changes['readonly'] && changes['readonly'].currentValue && (description == '' || description == undefined)) {
      this.options = this.options.splice(this.options.length - 1, 1);
    }
  }

  addOption() {
    this.options.push({
      id: this.uuid(),
      optionEtapeBonTravailId: 0,
      description: '',
      etapeBonTravailId: 0,
      montantPieces: null,
      nbHeures: 0,
      indAFaire: false
    });
    this.optionsChange.emit(this.options);
  }

  removeOption(option: OptionEtapeBonTravail) {
    this.confirmationService.confirm({
      message: 'Voulez-vous supprimer cette option ?',
      accept: () => {
        if (option.id == this._optionSelectionnee) {
          this._optionSelectionnee = this.options.filter(x => x.id != option.id)[0].id;
        }
        this.options = this.options.filter(o => o.id != option.id);
        this.optionsChange.emit(this.options);
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Option supprimée' });
      }
    });
  }

  surChangementDescription( index: number) {
    if (index == this.options.length - 1) {
      this.addOption();
    }
  }

  changementOption(option: OptionEtapeBonTravail) {
    this.onChange.emit(option);
  }

  uuid(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
}