import { Component, OnInit, ViewChild, Input } from "@angular/core"; import { Location } from "@angular/common";
import { Table } from 'primeng/table';
import { BreadcrumbService } from '../../app.breadcrumb.service';

import { RequisitionService } from '../../services/requisition-service';
import { RequisitionMin } from '../../entites/requisition-min';
import { MenuItem } from 'primeng/api/menuitem';
import { MessageService, LazyLoadEvent, ConfirmationService } from 'primeng/api';
import {ToastModule} from 'primeng/toast';

import {DialogService} from 'primeng/dynamicdialog';
import { EnvoiRequisitionComponent } from '../../controles/envoi-requisition.component';
import { Router, ActivatedRoute } from '@angular/router';
import { BonCommandeEntite } from '../../entites/bon-commande';
import { ListeAchatService } from '../../services/liste-achat-service';
import { AchatService } from '../../services/achat-service';
import { BaseComponent } from 'src/app/base/base-component';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { ProfilService } from 'src/app/services/profil-service';
import { ContextMenu } from 'primeng/contextmenu/public_api';
import { EStatuts } from 'src/app/entites/enums/statuts';
import { ModeleListeAchatEntite } from 'src/app/entites/modele-liste-achat';
import { ModeleListeAchatService } from 'src/app/services/modele-liste-achat-service';
import { AppMainComponent } from 'src/app/app.main.component';

@Component({
    templateUrl: './modeles-liste-achat.component.html',
    styleUrls: ['./modeles-liste-achat.component.scss'],
    styles: [`
        @media screen and (max-width: 960px) {
            :host ::ng-deep .p-datatable.p-datatable-customers.rowexpand-table .p-datatable-tbody > tr > td:nth-child(6) {
                display: flex;
            }
        }

    `],
    providers: [MessageService, ConfirmationService, DialogService],
    host: {
        '(document:keydown)': 'handleKeyboardEvents($event)'
    }
})
export class ModelesListeAchatComponent extends BaseComponent implements OnInit {

    modeles: ModeleListeAchatEntite[];
    selectionModele: ModeleListeAchatEntite;

    menuContextuel: MenuItem[];

    @ViewChild('dt') table: Table;
    @ViewChild('menuContextuelItemCommande') menuContextuelItemCommande: ContextMenu;
    
    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute, 
		appMain: AppMainComponent, location: Location, 
        private breadcrumbService: BreadcrumbService,
        private modeleListeAchatService: ModeleListeAchatService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        public dialogService: DialogService,
        public routerService: Router) {

            super(sdb, profilService, route, routerService, appMain);
            
        this.breadcrumbService.setItems([
            { label: 'Modèles de liste d\'achat' }
        ]);
    }

    ngOnInit() {

           this.modeleListeAchatService.ObtenirModeles().then(x => {
               this.modeles = x.valeur;
               this.CompleterChargement();
           });

       
    }

    handleKeyboardEvents(event: KeyboardEvent) {
        // if (event.key == '+') {
        //     this.AjouterModele();
        // }
    }

    menuContextuelEntrees: MenuItem[];
    surMenuContextuelItem(event, modele: ModeleListeAchatEntite) {
        this.selectionModele = modele;

        this.menuContextuelEntrees = [
            /* { 
                label: 'Voir le fournisseur', 
                icon: 'pi pi-fw pi-eye', 
                command: () => this.routerService.navigateByUrl('fournisseur/' + bonCommande.fournisseur.fournisseurId) 
            }, */
            {separator: true},
            { 
                label: 'Supprimer', 
                icon: 'pi pi-fw pi-times', 
                command: () => this.supprimerModele(modele)
            }
        ];

        this.menuContextuelItemCommande.toggle(event);
    }

    supprimerModele(modele: ModeleListeAchatEntite) {
        this.confirmationService.confirm({
            target: event.target,
            message: 'Voulez-vous vraiment supprimer le modèle sélectionné ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.modeleListeAchatService.SupprimerModele(modele.modeleListeAchatId).then(res => {
                    if (res.estUnSucces) {
                        this.modeles = this.modeles.filter(val => val.modeleListeAchatId != modele.modeleListeAchatId);
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Modèle supprimé.' });
                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                });

            }
        });
    }

    AjouterModele(){
        this.routerService.navigateByUrl("pilotage/modeleListeAchat/nouveau")
    }

    OuvrirDetailModele(modeleID: number) {
        this.routerService.navigateByUrl(`pilotage/modeleListeAchat/${modeleID}`)
    }
}
