import { Component, OnInit, ViewChild, Input } from "@angular/core"; import { Location } from "@angular/common";
import { Table } from 'primeng/table';
import { BreadcrumbService } from '../../app.breadcrumb.service';

import { RequisitionMin } from '../../entites/requisition-min';
import { MenuItem, TreeNode } from 'primeng/api';
import { MessageService, LazyLoadEvent, ConfirmationService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { EnvoiRequisitionComponent } from '../../controles/envoi-requisition.component';
import { ItemMin } from '../../entites/item-min';
import { ItemService } from '../../services/item-service';
import { Router, ActivatedRoute } from '@angular/router';

import { ToolbarModule } from 'primeng/toolbar';

import { MenubarModule } from 'primeng/menubar';
import { BaseComponent } from '../../base/base-component';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { ProfilService } from 'src/app/services/profil-service';
import { AppMainComponent } from 'src/app/app.main.component';
import { DocumentService } from 'src/app/services/document-service';
import { DocumentEntite } from 'src/app/entites/document';
import { RequeteAjouterDocuments } from 'src/app/services/requetes/requete-ajouter-documents';
import { ETypeLienDocument } from 'src/app/entites/enums/type-lien-document';
import { ContexteService } from 'src/app/services/contexte-service';
import { FileUpload } from 'primeng/fileupload/fileupload';
import { TypeDocumentEntite } from 'src/app/entites/type-document';
import { EStatuts } from 'src/app/entites/enums/statuts';

@Component({
    templateUrl: './ajout-document.component.html',
    styleUrls: ['./ajout-document.component.scss'],
    providers: [MessageService, ConfirmationService, DialogService]
})
export class AjoutDocumentComponent extends BaseComponent implements OnInit {

    modeDocument: ETypeLienDocument;
    idLien: number;

    menu: MenuItem[];

    dateDocument: Date;

    typesDocument: TypeDocumentEntite[];
    selectionTypeDocument: TypeDocumentEntite;

    submitted: boolean = false;

    @ViewChild('fu') fileUploadControl: FileUpload;

    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute,
        appMain: AppMainComponent, location: Location, 
        private breadcrumbService: BreadcrumbService,
        private documentService: DocumentService,
        private contexteService: ContexteService,
        private messageService: MessageService,
        public dialogService: DialogService,
        public routerService: Router) {

        super(sdb, profilService, route, routerService, appMain);

        this.breadcrumbService.setItems([
            { label: 'Ajout de documents' }
        ]);
    }

    ngOnInit() {

        if (this.contexteService.ValiderPresence(["itemId", "dossier", "numero"])) {

            this.modeDocument = ETypeLienDocument.Item;
            this.idLien = Number(this.contexteService.Obtenir("itemId"));

            this.typesDocument = this.sdb.TypesDocumentsParTypeLien(ETypeLienDocument.Item);

            this.selectionTypeDocument = this.typesDocument.find(x => x.dossier == this.contexteService.Obtenir("dossier"));

        } else if (this.contexteService.ValiderPresence(["requisitionId", "numero"])) {

            this.modeDocument = ETypeLienDocument.Requisition;
            this.idLien = Number(this.contexteService.Obtenir("requisitionId"));

        } else if (this.contexteService.ValiderPresence(["locationId", "numero"])) {

            this.modeDocument = ETypeLienDocument.Location;
            this.idLien = Number(this.contexteService.Obtenir("locationId"));

        } else if(this.contexteService.ValiderPresence(["bonTravailId", "numero"])) {

            this.modeDocument = ETypeLienDocument.BonTravail;
            this.idLien = Number(this.contexteService.Obtenir("bonTravailId"));

        } else if(this.contexteService.ValiderPresence(["nonConformiteId", "dossier", "numero"])) {

            this.modeDocument = ETypeLienDocument.NonConformite;
            this.idLien = Number(this.contexteService.Obtenir("nonConformiteId"));

            this.typesDocument = this.sdb.TypesDocumentsParTypeLien(ETypeLienDocument.NonConformite);

            this.selectionTypeDocument = this.typesDocument.find(x => x.dossier == this.contexteService.Obtenir("dossier"));

        } else {
            this.routerService.navigateByUrl('error');
        }

        this.dateDocument = new Date();

        this.initialiserMenu();

        this.CompleterChargement();
    }

    initialiserMenu() {
        this.menu = [
            {
                label: 'Retour', icon: 'pi pi-fw pi-angle-double-left', command: () => this.retour()
            }
        ];
    }

    retour() {

        if (this.contexteService.ValiderPresence(["itemId"])) {
            this.contexteService.Ajouter("tab", "3");
            const itemId = Number(this.contexteService.Obtenir("itemId"));
            this.routerService.navigateByUrl('item/' + itemId);
        } else if (this.contexteService.ValiderPresence(["requisitionId"])) {
            this.contexteService.Ajouter("tab", "2");
            const requisitionId = Number(this.contexteService.Obtenir("requisitionId"));
            this.routerService.navigateByUrl('requisition/' + requisitionId);
        } else if (this.contexteService.ValiderPresence(["locationId"])) {
            this.contexteService.Ajouter("tab", "1");
            const locationId = Number(this.contexteService.Obtenir("locationId"));
            this.routerService.navigateByUrl('location/' + locationId);
        } else if (this.contexteService.ValiderPresence(["bonTravailId"])) {
            this.contexteService.Ajouter("tab", "1");
            const bonTravailId = Number(this.contexteService.Obtenir("bonTravailId"));
            this.routerService.navigateByUrl('/mecanique/bontravail/' + bonTravailId);
        } else if (this.contexteService.ValiderPresence(["nonConformiteId"])) {
            this.contexteService.Ajouter("tab", "4");
            const nonConformiteId = Number(this.contexteService.Obtenir("nonConformiteId"));
            this.routerService.navigateByUrl('/assurancequalite/nonconformite/' + nonConformiteId);
        } else{
            this.routerService.navigateByUrl('error');
        }
    }

    onBasicUpload(event) {
    }

    onSelect(event) {
        for(let file of event.files) {
            if(file.name.length > 100) {
                this.messageService.add({severity: 'error', summary: 'Erreur', detail: 'Le nom du fichier ne doit pas dépasser 100 caractères incluant l\'extension.'});
                // Supprime le fichier de la file d'attente de téléchargement
                const index = this.fileUploadControl.files.indexOf(file);
                this.fileUploadControl.remove(file, index);
            }
        }
    }

    async onUpload(event) {

        this.submitted = true;

        if (!this.valider()) {
            return;
        }

        this.Bloquer();

        if (await this.documentService.AjouterDocuments(this.modeDocument, this.idLien, this.dateDocument, this.fileUploadControl.files, this.selectionTypeDocument)) {
            this.Debloquer();

            this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Les documents ont été ajoutés.' });
            this.fileUploadControl.clear();
        } else {
            this.Debloquer();
            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: 'Une erreur est survenue lors de l\'ajout des documents.' });
        }
    }



    valider(): boolean {


        if (!this.dateDocument || !this.selectionTypeDocument) {
            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: 'Vous devez remplir tous les champs.' });
            return false;
        }

        if (this.modeDocument == ETypeLienDocument.Item && !this.selectionTypeDocument) {
            return false;
        }

        return true;
    }
}
