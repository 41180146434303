<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-menu #menuContextuelItemCommande popup="popup" [model]="menuContextuelEntrees"></p-menu>

<p-card *ngIf="!EstCharge()">
	<div class="fluid formgrid grid">
		<div class="flex flex-column gap-2 my-2 col-12 text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-card>

<div class="grid" *ngIf="EstCharge()">
    <div class="col-12">

        <div class="card">
            <p-table #dt [value]="modeles" dataKey="modeleListeAchatId" autoLayout="true" tableStyleClass="tableNormale"
                styleClass="p-datatable-sm p-datatable-striped" [(contextMenuSelection)]="selectionModele"
                [rowHover]="true" [rows]="25" [paginator]="true" [filterDelay]="0"
                [globalFilterFields]="['nom','description']">
                <ng-template pTemplate="caption">
                    <div class="flex align-content-center justify-content-between">
                        <div>
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text"
                                    (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Recherche..." />
                            </span>
                                <button pButton pRipple icon="pi pi-plus"
                                class="p-button-success mr-2 ml-4 mb-2 boutonGrille"
                                (click)="AjouterModele()"></button>
                        </div>
                        <h5 class="titreTableau m-0">Modeles de liste d'achat</h5>
                        <div>
                            <p-multiSelect disabled="true" [style]="{minWidth: '200px'}" placeholder="Colonnes..."></p-multiSelect>
                        </div>
                    </div>
                </ng-template>
               
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="nom">
                            <div class="flex justify-content-between align-items-center">
                                Nom
                                <p-sortIcon field="nom"></p-sortIcon>
                                <p-columnFilter type="text" field="nom" display="menu" class="ml-auto">
                                </p-columnFilter>
                            </div>
                            
                        </th>
                        <th pSortableColumn="nom">
                            <div class="flex justify-content-between align-items-center">
                                Description
                                <p-sortIcon field="description"></p-sortIcon>
                                <p-columnFilter type="text" field="description" display="menu" class="ml-auto">
                                </p-columnFilter>
                            </div>
                            
                        </th>
                        <th style="min-width: 7rem; width: 7rem;"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-modele>
                    <tr>
                        <td>{{modele.nom}}</td>
                        <td>{{modele.description}}</td>
                       
                        <td>
                            <button pButton pRipple icon="pi pi-eye"
                                class="p-button-rounded p-button-success mr-2"
                                (click)="OuvrirDetailModele(modele.modeleListeAchatId)"></button>
                                <button pButton pRipple icon="pi pi-bars"
                                class="p-button-rounded p-button-secondary"
                                (click)="surMenuContextuelItem($event, modele)"></button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="2">Aucun modèle.</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>


</div>