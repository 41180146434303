import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Output, EventEmitter } from "@angular/core"; import { Location } from "@angular/common";

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { ItemMin } from '../entites/item-min';
import { Router } from '@angular/router';
import { ReponseBase } from './reponse-base';
import { Item } from '../entites/item';
import { BonCommandeEntite } from '../entites/bon-commande';
import { Statut } from '../entites/statut';
import { CategorieMin, CategorieEntite } from '../entites/categorie';
import { SousCategorieEntite } from '../entites/sousCategorie';
import { Emplacement } from '../entites/emplacement';
import { AttributEntite } from '../entites/attribut';
import { EmployeEntite } from '../entites/employe';
import { EmplacementService } from './emplacement-service';
import { DonneesBaseService } from './donnees-base-service';
import { FavoriEntite } from '../entites/favori';
import { HistoriqueEntite } from '../entites/historique';
import { RapportEntite } from '../entites/rapport';
import { ProfilEntite } from '../entites/profil';
import { CleValeur } from '../entites/cleValeur';

@Injectable({ providedIn: 'root' })
export class ContexteService {

  private _contexte: CleValeur[] = [];


  constructor(
    public sdb: DonneesBaseService,
    private http: HttpClient,
    public router: Router) { }

  public Ajouter(cle: string, valeur: string) {

    var existant = this._contexte.find(x => x.cle == cle);

    if (existant) {
      existant.valeur = valeur;
    } else {

      this._contexte.push({
        cle: cle,
        valeur: valeur
      });
    }
  }

  public Supprimer(cle: string) {
    this._contexte = this._contexte.filter(x => x.cle != cle);
  }

  public Vider() {
    this._contexte = [];
  }

  public ValiderPresence(cles: string[]): boolean {
    console.log('validation contexte');
    var valide: boolean = true;
    cles.forEach(cle => {
      console.log(cle);
      if (!this._contexte.find(x => x.cle == cle)) {
        console.log('contexte absent');
        valide = false;
      }
    });
    console.log('contexte ok');
    return valide;
  }

  public Obtenir(cle: string): string {
    return this._contexte.find(x => x.cle == cle).valeur;
  }

}
