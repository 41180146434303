<p-toast key="creationPiece"></p-toast>

<p-blockUI [blocked]="blocked">
    <div class="fluid formgrid grid">
        <div class="flex flex-column gap-2 col-12 text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-blockUI>


<p-card *ngIf="!EstCharge()">
    <div class="fluid formgrid grid">
        <div class="flex flex-column gap-2 col-12 text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-card>

<div #contenu *ngIf="EstCharge()">
	<div class="fluid formgrid grid" *ngIf="nouvelItem.categorie">
		<div class="flex flex-column gap-2 col-12 md:col-6 p-lg-8">
			<label for="description">Description<span class="requis">*</span></label>
				<p-autoComplete #champDescription appendTo="body" [(ngModel)]="nouvelItem.description" [suggestions]="suggestionDescription" (completeMethod)="rechercherDescritpion($event)"></p-autoComplete>
			<small class="p-error" *ngIf="submitted && !nouvelItem.description">La description
				est
				obligatoire.</small>
		</div>
	</div>
	<div class="fluid formgrid grid" *ngIf="nouvelItem.categorie">
		<div class="flex flex-column gap-2 col-12 md:col-6 p-lg-4">
			<label for="marque">Marque</label>
			<p-autoComplete appendTo="body" [(ngModel)]="nouvelItem.marque" [suggestions]="suggestionMarque" (completeMethod)="rechercherMarque($event)"></p-autoComplete>
		</div>
		<div class="flex flex-column gap-2 col-12 md:col-6 p-lg-4">
			<label for="modele">Modèle</label>
			<input type="text" pInputText id="modele" [(ngModel)]="nouvelItem.modele" autocomplete="off"
				autofocus />
		</div>

	</div>
	<div class="fluid formgrid grid" *ngIf="nouvelItem.categorie">

		<div class="flex flex-column gap-2 col-12 md:col-6 p-lg-4">

			<label for="lastname">Localisation entrepot</label>
			<p-dropdown [options]="listeLocalisations" [(ngModel)]="nouvelItem.localisation" optionLabel="nom"
				appendTo="body" [showClear]="true" filter="true" filterBy="code,nom"
				placeholder="Sélectionner une localisation...">
				<ng-template pTemplate="selectedItem">
					<div *ngIf="nouvelItem.localisation">
						<div>{{nouvelItem.localisation.nom}}</div>
					</div>
				</ng-template>
				<ng-template let-loc pTemplate="item">
					<div>
						<div>{{loc.nom}}</div>
					</div>
				</ng-template>
			</p-dropdown>
		</div>

	</div>

	<p-divider align="left">
		<div class="inline-flex align-items-center">
			<b>Fournisseur</b>
		</div>
	</p-divider>

	<div class="fluid formgrid grid" *ngIf="nouvelItem.categorie">
		<div class="flex flex-column gap-2 col-12 p-lg-6">
			<label for="lastname">Fournisseur</label>
			<p-dropdown [options]="listeFournisseurs" [(ngModel)]="prixFournisseur.fournisseur" optionLabel="nom"
				[showClear]="true" filter="true" filterBy="numero,nom"
				placeholder="Sélectionner un fournisseur...">
				<ng-template pTemplate="selectedItem">
					<div *ngIf="prixFournisseur.fournisseur">
						<div>{{prixFournisseur.fournisseur.nom}} ({{prixFournisseur.fournisseur.numero}})</div>
					</div>
				</ng-template>
				<ng-template let-fournisseur pTemplate="item">
					<div>
						<div>{{fournisseur.nom}} ({{fournisseur.numero}})</div>
					</div>
				</ng-template>
			</p-dropdown>
		</div>
		<div class="flex flex-column gap-2 col-12 md:col-6 lg:col-3" *ngIf="prixFournisseur.fournisseur != null">
            <label for="noFournisseur">No pièce fournisseur</label>
            <input #champNoFournisseur type="text" pInputText id="noFournisseur" [(ngModel)]="prixFournisseur.numeroFournisseur"
                autocomplete="off" />
        </div>
        <div class="flex flex-column gap-2 col-12 md:col-6 lg:col-3" *ngIf="prixFournisseur.fournisseur != null">
            <label for="prix">Prix</label>
            <p-inputNumber #champPrix [(ngModel)]="prixFournisseur.prix" mode="currency" currency="USD" locale="en-US" [minFractionDigits]="2" [maxFractionDigits]="4"></p-inputNumber>
        </div>
	</div> 

</div>


<div class="text-right mt-3">
	<button pButton pRipple label="Créer" icon="pi pi-save" class="p-button-primary"
	(click)="enregistrer()"></button>
<button pButton pRipple label="Annuler" icon="pi pi-ban" class="p-button-text"
	(click)="annuler()"></button>
	

</div>