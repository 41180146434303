import { Component, OnInit, ViewChild, Input } from "@angular/core"; import { Location } from "@angular/common";
import { Table } from 'primeng/table';
import { BreadcrumbService } from '../../app.breadcrumb.service';

import { RequisitionService } from '../../services/requisition-service';
import { RequisitionMin } from '../../entites/requisition-min';
import { MenuItem } from 'primeng/api/menuitem';
import { MessageService, LazyLoadEvent, ConfirmationService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

import { DialogService } from 'primeng/dynamicdialog';
import { EnvoiRequisitionComponent } from '../../controles/envoi-requisition.component';
import { Router, ActivatedRoute } from '@angular/router';
import { ListeAchatEntite } from '../../entites/liste-achat';
import { ListeAchatService } from '../../services/liste-achat-service';
import { FournisseurService } from '../../services/fournisseur-service';
import { Fournisseur } from '../../entites/fournisseur';
import { BaseComponent } from 'src/app/base/base-component';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { AppMainComponent } from 'src/app/app.main.component';
import { ProfilService } from 'src/app/services/profil-service';
import { ItemListeAchatEntite } from 'src/app/entites/item-liste-achat';
import { SelectionFournisseurComponent, ParametresSelectionFournisseur } from 'src/app/controles/selection-fournisseur.component';
import { ConsulterFournisseursComponent } from 'src/app/controles/consulter-fournisseurs.component';
import { PrixFournisseurEntite } from 'src/app/entites/prix-fournisseur';
import { ConfirmationCreationBonCommandeComponent } from 'src/app/controles/confirmation-creation-bon-commande.component';

@Component({
    templateUrl: './detail-approbation-liste-achat.component.html',
    styles: [`
        @media screen and (max-width: 960px) {
            :host ::ng-deep .p-datatable.p-datatable-customers.rowexpand-table .p-datatable-tbody > tr > td:nth-child(6) {
                display: flex;
            }
        }

    `],
    providers: [MessageService, ConfirmationService, DialogService]
})
export class DetailApprobationListeAchatComponent extends BaseComponent implements OnInit {

    listeId: number;
    liste: ListeAchatEntite;

    //menuContextuel: MenuItem[];

    menu: MenuItem[];

    // DIALOGUE APPROBATION
    dialogueApprobation: boolean = false;
    dateAchat: Date;
    dialogueApprobationSubmitted: boolean = false;

    @ViewChild('dt') table: Table;

    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute, 
		appMain: AppMainComponent, location: Location, 
        private breadcrumbService: BreadcrumbService,
        private listeAchatService: ListeAchatService,
        private fournisseurService: FournisseurService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        public dialogService: DialogService,
        public routerService: Router) {
            super(sdb, profilService, route, routerService, appMain);
        this.breadcrumbService.setItems([
            { label: 'Approbation de la liste d\'achat' }
        ]);
        this.route.params.subscribe(params => this.listeId = params.id);
    }

    ngOnInit() {

        this.verifierAccesPage('approuver_liste_achat');

        this.listeAchatService.ObtenirListe(this.listeId).then(x => {
            this.liste = x.valeur;
            this.CompleterChargement();
        });


        this.menu = [
            {
                label: 'Retour', icon: 'pi pi-fw pi-angle-double-left', command: () => this.routerService.navigateByUrl('approbationlisteachat')
            },
            {
                label: 'Approuver', icon: 'pi pi-fw pi-check', command: () => this.approuver()
            },
            {
                label: 'Rejeter', icon: 'pi pi-fw pi-times', command: () => this.rejeter()
            }
        ];


        /* this.menuContextuel = [
            {
                label: 'Retour', icon: 'pi pi-fw pi-angle-double-left', command: () => this.routerService.navigateByUrl('approbationListesAchat')
            },
            {
                label: 'Approuver', icon: 'pi pi-fw pi-check'
            },
            {
                label: 'Rejeter', icon: 'pi pi-fw pi-times'
            }
        ]; */
    }

    selectionnerFournisseur(item: ItemListeAchatEntite) {
        const ref = this.dialogService.open(SelectionFournisseurComponent, {
            data: {
                fournisseur: item.fournisseur,
                numeroFournisseur: item.numeroFournisseur,
                prix: item.prix
            },
            header: 'Sélection d\'un fournisseur',
            width: '50%'
        }).onClose.subscribe((res: ParametresSelectionFournisseur) => {
            if (res) {
                item.fournisseur = res.fournisseur;
                item.numeroFournisseur = res.numeroFournisseur;
                item.prix = res.prix;
            }

        });
    }

    consulterFournisseurs(item: ItemListeAchatEntite) {
        const ref = this.dialogService.open(ConsulterFournisseursComponent, {
            data: {
                itemId: item.item.itemId,
                afficherSelection: true,
                afficherCommander: false
            },
            header: 'Consulter les fournisseurs',
            width: '50%'
        }).onClose.subscribe((res: PrixFournisseurEntite) => {
            if (res) {
                item.fournisseur = res.fournisseur;
                //item.numeroFournisseur = res.numeroFournisseur;  // TODO
                item.prix = res.prix;
            }

        });
    }

    approuver() {

        if (this.liste.itemsListeAchat.filter(x => !x.fournisseur).length > 0) {

            this.confirmationService.confirm({
                target: event.target,
                message: 'Tous les items doivent avoir un fournisseur pour approuver la liste d\'achat.',
                header: 'Erreur',
                icon: 'pi pi-exclamation-triangle',
                rejectVisible: false,
                acceptLabel: 'OK'
            });

            //this.messageService.add({ severity: 'error', summary: 'Erreurs', detail: 'Tous les items doivent avoir un fournisseur pour approuver la liste d\'achat.' });
            return;
        }
        
        this.dateAchat = new Date();
        this.dialogueApprobationSubmitted = false;
        this.dialogueApprobation = true;

    }

    annulerApprobation() {
        
        
        this.dialogueApprobationSubmitted = false;
        this.dialogueApprobation = false;

    }

    confirmerApprobation() {

        this.dialogueApprobationSubmitted = true;

        if (!this.validerDialogueApprobation()) {
            
            this.messageService.add({ key: 'dialogueApprobation', severity: 'error', summary: 'Erreurs', detail: 'Veuillez corriger les erreurs de saisie.' });
            return;
        }


        this.listeAchatService.ApprouverListe({
            liste: this.liste,
            dateAchat: this.dateAchat,
            employe: this.profilService.ProfilCourant()
        }).then(res => {
            this.dialogueApprobation = false;
            if (res.estUnSucces) {

                const ref = this.dialogService.open(ConfirmationCreationBonCommandeComponent, {
                    data: {
                        bonsCommande: res.valeur
                    },
                    header: 'Bons de commande créés',
                    width: '50%'
                }).onClose.subscribe((r) => {
                    if (!r) {
                        this.routerService.navigateByUrl('approbationlisteachat');
                    }
        
                });

                
            } else {
                res.messages.forEach(x => {
                    this.messageService.add({ key: 'selectionItem', severity: 'error', summary: 'Erreur', detail: x.description });
                });
            }
        });
    }

    validerDialogueApprobation() {
        return this.dateAchat;
    }

    rejeter() {
        this.confirmationService.confirm({
            target: event.target,
            message: 'Voulez-vous vraiment rejeter la liste d\'achat ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.listeAchatService.RejeterListe(this.liste.listeAchatId).then(res => {
                    if (res.estUnSucces) {
                        this.routerService.navigateByUrl('approbationlisteachat');
                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                });

            }
        });
    }

    /* ConsulterBdc() {
        this.routerService.navigateByUrl('/achats');
    }

    SurOuvertureLigne(event) {

        this.detailLoaded = false;

        this.fournisseurService.ObtenirAutreFournisseurs(1).then(x => {
            this.liste.itemsListeAchat[0].autresfournisseurs = x.valeur;
            this.liste.itemsListeAchat[1].autresfournisseurs = x.valeur;

            setTimeout(() => { this.detailLoaded = true; }, 1000);
        });
    }

    SelectionnerNouveauFournisseur() {
        this.liste.itemsListeAchat[2].fournisseur = {
            "fournisseurId": 1,
            "numero": "12370",
            "nom": "Macpek inc.",
            "contacts": [],
            "achats": []
        };
        this.liste.itemsListeAchat[2].prix = 9.99;
    } */

}
