import { Component, OnInit, ViewChild, Input } from "@angular/core"; import { Location } from "@angular/common";
import { Table } from 'primeng/table';
import { MenuItem } from 'primeng/api/menuitem';
import { MessageService, ConfirmationService } from 'primeng/api';
import {DialogService} from 'primeng/dynamicdialog';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseComponent } from 'src/app/base/base-component';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { ProfilService } from 'src/app/services/profil-service';
import { ContextMenu } from 'primeng/contextmenu/public_api';
import { EStatuts } from 'src/app/entites/enums/statuts';
import { AppMainComponent } from 'src/app/app.main.component';
import { Statut } from 'src/app/entites/statut';
import { BonTravailEntite } from 'src/app/entites/mecanique/bon-travail';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { AchatService } from 'src/app/services/achat-service';
import { BonTravailService } from 'src/app/services/bon-travail-service';
import { EntretienPreventifEntite } from 'src/app/entites/mecanique/entretien-preventif';
import { Item } from 'src/app/entites/item';
import { HistoriqueEntretienComponent } from 'src/app/controles/mecanique/historique-entretien.component';
import { DepartementEntite } from 'src/app/entites/departement';
import { Emplacement } from 'src/app/entites/emplacement';
import { ItemService } from 'src/app/services/item-service';
import { EStatutsSpeciaux } from 'src/app/entites/enums/statuts-speciaux';
import { ItemMin } from 'src/app/entites/item-min';
import { AssignerEntretiensComponent } from '../bonsTravail/controles/assigner-entretiens.component';
import { EntretienPreventifService } from 'src/app/services/entretien-preventif-service';
import { DepartementMecaniqueEntite } from 'src/app/entites/mecanique/departement-mecanique';

@Component({
    templateUrl: './planification.component.html',
    styleUrls: ['./planification.component.scss'],
    styles: [`
        @media screen and (max-width: 960px) {
            :host ::ng-deep .p-datatable.p-datatable-customers.rowexpand-table .p-datatable-tbody > tr > td:nth-child(6) {
                display: flex;
            }
        }

    `],
    providers: [MessageService, ConfirmationService, DialogService]
})
export class PlanificationComponent extends BaseComponent implements OnInit {

    entretiensComplet: EntretienPreventifEntite[];
    entretiens: EntretienPreventifEntite[];

    selectionMultipleEntretiens: EntretienPreventifEntite[] = [];

    statuts: Statut[] = this.sdb.Statuts();

    departements: DepartementMecaniqueEntite[];
    departement: DepartementMecaniqueEntite;
    emplacements: Emplacement[];
    emplacement: Emplacement;
    unites: ItemMin[];
    unite: ItemMin;
    

    contenuMenuContextuelEntretien: MenuItem[];

    menu: MenuItem[];

    @ViewChild('dt') table: Table;
    @ViewChild('menuContextuelEntretien') menuContextuelEntretien: ContextMenu;
    
    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute, 
		appMain: AppMainComponent, location: Location, 
        private breadcrumbService: BreadcrumbService,
        private bonTravailService: BonTravailService,
        private itemService: ItemService,
        private entretienPreventifService: EntretienPreventifService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        public dialogService: DialogService,
        public routerService: Router) {

            super(sdb, profilService, route, routerService, appMain);
            
        this.breadcrumbService.setItems([
            { label: 'Planification' }
        ]);
    }

    ngOnInit() {

        this.verifierAccesPage('temp_mec');

        this.colonnesDisponibles = [
            /* { champ: 'projet', entete: 'Projet', afficheParDefaut: true },
            { champ: 'nbItems', entete: 'Nb items', afficheParDefaut: false },
            { champ: 'coutTotal', entete: 'Coût total', afficheParDefaut: false },
            { champ: 'employe', entete: 'Employé', afficheParDefaut: true },
            { champ: 'dateEnvoi', entete: 'Date d\'envoi', afficheParDefaut: true },
            { champ: 'envoyeA', entete: 'Envoyé à', afficheParDefaut: true } */
        ];
        this.colonnesSelectionnees = this.colonnesDisponibles.filter(x => x.afficheParDefaut);

        this.departements = this.sdb.DepartementsMecanique();
        this.emplacements = this.sdb.Emplacements();

        this.statuts = [
            this.sdb.Statut(EStatuts.AFaire),
            this.sdb.Statut(EStatuts.EnCours),
            this.sdb.Statut(EStatuts.EnRetard),
            this.sdb.Statut(EStatuts.OK)
        ]

        var p1 = new Promise<number>((resolve, reject) => {
            this.entretienPreventifService.Obtenir().then(x => {
                this.entretiensComplet = x.valeur;
                this.entretiens = x.valeur;
                resolve(null);
            });
        });

        var p2 = new Promise<number>((resolve, reject) => {
            this.itemService.ObtenirItemsParStatut(EStatutsSpeciaux.UnitesMecanique).then(x => {
                this.unites = x.valeur;
                resolve(null);
            });
        });

        Promise.all([p1,p2]).then(x => {

            this.initialiserMenu();

            this.CompleterChargement();
        });
       
    }

    initialiserMenu() {

        this.menu = [
            // {
            //     label: 'Imprimer', icon: 'pi pi-fw pi-print'
            // },
            {
                visible: this.estAutorise('temp_mec'),
                disabled: this.selectionMultipleEntretiens.length == 0,
                label: 'Assigner', 
                icon: 'pi pi-fw pi-user', 
                command: () => this.assigner(this.selectionMultipleEntretiens)
            },
            /* {
                label: 'Actions multiples', icon: 'pi pi-fw pi-bars',
                visible: this.estAutorise('temp_mec'),
                disabled: this.selectionMultipleEntretiens.length == 0,
                items: [
                    {
                        label: 'Assigner', icon: 'pi pi-fw pi-user', command: () => this.assigner(this.selectionMultipleEntretiens)
                    },
                ]
            }, */
        ];
    }

    surChangementSelectionEntretiens() {
        this.initialiserMenu();
    }

    assigner(entretiens: EntretienPreventifEntite[]) {

        if (entretiens.filter(x => x.statut.statutId == EStatuts.EnCours).length > 0) {
            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: 'Vous ne pouvez pas assignger un entretien qui est déjà en cours dans un bon de travail.' });
            return;
        }

        if ([...new Set(entretiens.map(x => x.item.itemId))].length > 1) {
            
            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: 'Vous ne pouvez pas assigner des entretiens associés à des unités différentes.' });
            return;
        }

        const ref = this.dialogService.open(AssignerEntretiensComponent, {
            data: {
                itemId: entretiens[0].item.itemId,
                entretiensPreventifs: entretiens,
                employeId: this.profilService.ProfilCourant().employeId,
                contreMaitreId: entretiens[0].item.departementMecanique?.contreMaitre?.employeId
            },
            header: `Assigner des entretiens`,
            width: '75%'
        }).onClose.subscribe((bt: BonTravailEntite) => {

            if (bt) {
                 entretiens.forEach(ent => {
                ent.bonTravail = bt;
                ent.statut = this.sdb.Statut(EStatuts.EnCours);
            });
          
            this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Bon de travail créé.' });
            this.selectionMultipleEntretiens = [];
            }
            

        });

    }

    surMenuContextuelEntretien(event, entretien: EntretienPreventifEntite) {

        this.contenuMenuContextuelEntretien = [
            { 
                label: 'Voir le bon de travail', 
                icon: 'pi pi-fw pi-eye', 
                visible: this.estAutorise('temp_mec') && entretien.bonTravail != null && entretien.bonTravail != undefined,
                command: () => this.routerService.navigateByUrl('mecanique/bontravail/' + entretien.bonTravail.bonTravailId) 
            },
            { 
                label: 'Voir l\'unité', 
                icon: 'pi pi-fw pi-eye', 
                visible: this.estAutorise('temp_mec'),
                command: () => this.routerService.navigateByUrl('item/' + entretien.item.itemId) 
            },
            {
                label: 'Voir l\'historique', icon: 'pi pi-fw pi-calendar',
                visible: this.estAutorise('temp_mec'), // TODO : && est entretien préventif
                command: _ => this.consulterHistoriqueEntretienPreventif(entretien.item, entretien)
            },
            {separator: true},
            { 
                label: 'Assigner', 
                icon: 'pi pi-fw pi-user', 
                visible: this.estAutorise('temp_mec'),
                command: () => this.assigner([entretien])
            }
        ];

        this.menuContextuelEntretien.toggle(event);
    }

    consulterHistoriqueEntretienPreventif(item: Item, entretien: EntretienPreventifEntite) {
        const ref = this.dialogService.open(HistoriqueEntretienComponent, {
            data: {
                itemId: item.itemId,
                entretienPreventifId: entretien.entretienPreventifId
            },
            header: `Historique d'entretien - ${item.numero} - ${item.description} - ${entretien.description}`,
            width: '75%'
        }).onClose.subscribe(() => {


        });
    }

    filtrer() {
        this.Bloquer();
           this.entretiens = this.entretiensComplet;
           if (this.unite) {
               this.entretiens = this.entretiens.filter(x => x.item.itemId == this.unite.itemId);
           }
           if (this.emplacement) {
            this.entretiens = this.entretiens.filter(x => x.item.emplacement.emplacementId == this.emplacement.emplacementId);
        }
        if (this.departement) {
            this.entretiens = this.entretiens.filter(x => x.item.departementMecanique && x.item.departementMecanique.departementMecaniqueId == this.departement.departementMecaniqueId);
        }
        this.Debloquer();
    }

    retirerFiltres() {
        this.entretiens = this.entretiensComplet;
        this.unite = null;
        this.departement = null;
        this.emplacement = null;
    }

}
