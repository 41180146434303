import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { BaseComponent } from '../base/base-component';
import { DonneesBaseService } from '../services/donnees-base-service';
import { ProfilService } from '../services/profil-service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppMainComponent } from '../app.main.component';
import { ConversationEntite } from '../entites/conversation';
import { ConversationService } from '../services/conversation-service';
import { MessageEntite } from '../entites/message';
import { EStatuts } from '../entites/enums/statuts';
import { Editor } from 'primeng/editor';
import Quill from 'quill';
import Toolbar from 'quill/modules/toolbar';
import { FileUpload } from 'primeng/fileupload';
import { DocumentService } from '../services/document-service';
import { ETypeLienDocument } from '../entites/enums/type-lien-document';
import { Menu } from 'primeng/menu';
import { MenuItem } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { ETypeFichier } from '../entites/enums/type-fichier';
import { DocumentEntite } from '../entites/document';
import { environment } from 'src/environments/environment';
import { QuillEditorComponent } from 'ngx-quill';
@Component({
  selector: 'app-fil-conversation',
  templateUrl: './fil-conversation.component.html',
  styleUrls: ['./fil-conversation.component.scss'],
  providers: [ConversationService, DocumentService]
})
export class FilConversationComponent extends BaseComponent implements OnInit {
  @Input({required: true}) conversationId: number;
  conversation: ConversationEntite;
  messages: MessageEntite[];
  newMessage: MessageEntite;
  messageToEdit: MessageEntite;
  isEditing: boolean = false;
  items: MenuItem[];
  status: string = '';

  @ViewChild('editor') editor: QuillEditorComponent;
  @ViewChild('fileUpload') _fileUpload: FileUpload;
  @ViewChild('fileUploadMessage') _fileUploadMessage: FileUpload;
  @ViewChild('menu') _menu: Menu;

  constructor(
    sdb: DonneesBaseService,
    profilService: ProfilService,
    route: ActivatedRoute,
    routerService: Router,
    appMain: AppMainComponent,
    private conversationService: ConversationService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private documentService: DocumentService
  ) {
    super(sdb, profilService, route, routerService, appMain);
  }

  ngOnInit() {

    this.ResetMessage();
    this.conversationService.ObtenirConversation(this.conversationId).subscribe({
      next: (response) => {
        this.CompleterChargement();
        this.conversation = response.body;
        this.messages = this.conversation.messages;
  
        this.editor.onFocus.subscribe(() => this.onFocus());
        this.editor.onBlur.subscribe(() => this.onBlur());
        this.onBlur();
    },
    error: (error) => {
      this.conversationService.errorHandler(error, 'la conversation');
      switch (error.status) {
        case 400:
          this.status = 'La conversation n\'existe pas.';
          break;
        case 403:
          this.status = 'Vous n\'êtes pas autorisé à accéder à cette conversation.';
          break;
        case 404:
          this.status = 'La conversation n\'existe pas.';
          break;
        default:
          this.status = 'Une erreur est survenue lors de la récupération de la conversation.';
      }
      this.CompleterChargement();
    }
    }); 
  }

  sendMessage() {

    if (this.newMessage.texte == '<p></p>') {
      this.messageService.add({ severity: 'warn', summary: 'Attention', detail: 'Vous ne pouvez pas envoyer un message vide' });
      return;
    }

    this.conversationService.AjouterMessage(this.conversation.conversationId, this.newMessage).subscribe({
      next: (response) => {
        this.conversationService.responseHandler(response);
        this.messages.push(response.body);
        this.ResetMessage();
        this.onBlur();
        
        this.documentService.AjouterDocuments(ETypeLienDocument.Message, response.body.messageId, new Date(), this._fileUpload.files).then((responseFiles) => {
          if (!responseFiles.body) {
            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: 'Erreur lors de l\'envoi des fichiers: aucun fichier n\'a été ajouté au nouveau message' });
            return;
          }
          else if (responseFiles.body.length != this._fileUpload.files.length) {
            this.messageService.add({ severity: 'warn', summary: 'Attention', detail: 'Erreur lors de l\'envoi des fichiers: certains fichiers n\'ont pas pu être ajoutés au nouveau message' });
          }

          this._fileUpload.clear();
          this.messages[this.messages.length - 1].documents = responseFiles.body;
        });
      },
      error: (error) => {
        this.conversationService.errorHandler(error, 'le message');
      }
    });
  }


  ResetMessage() {
    this.newMessage = {
      messageId: 0,
      texte: '<p></p>',
      date: new Date(),
      employe: this.profilService.ProfilCourant(),
      statut: null,
      documents: [],
      isEditing: false
    };
  }

  editMessage(message: MessageEntite) {
    var editingMessage = this.messages.find(m => m.isEditing);
    if (editingMessage) {
      this.cancelEditMessage(editingMessage);
    }

    this.messageToEdit = JSON.parse(JSON.stringify(message));
    message.isEditing = true;
  }

  confirmEditMessage() {
    this.conversationService.ModifierMessage(this.messageToEdit.messageId, this.messageToEdit).subscribe({
      next: (response) => {
        var message = this.messages.find(m => m.messageId == this.messageToEdit.messageId);
        message.isEditing = false;
        message.texte = this.messageToEdit.texte;
        this.conversationService.responseHandler(response);

        this.documentService.AjouterDocuments(ETypeLienDocument.Message, this.messageToEdit.messageId, new Date(), this._fileUploadMessage.files).then((responseFiles) => {
          if (!responseFiles.body)
            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: 'Erreur lors de l\'envoi des fichiers: aucun fichier n\'a été ajouté au nouveau message' });
          else if (responseFiles.body.length != this._fileUploadMessage.files.length) {
            this.messageService.add({ severity: 'warn', summary: 'Attention', detail: 'Erreur lors de l\'envoi des fichiers: certains fichiers n\'ont pas pu être ajoutés au nouveau message' });
          }
        });
      },
      error: (error) => {
        this.conversationService.errorHandler(error, 'le message');
      }
    });
  }

  cancelEditMessage(message: MessageEntite) {
    message.isEditing = false;
    message.texte = this.messageToEdit.texte;
    this.messageToEdit = null;
  }

  deleteMessage(message: MessageEntite) {
    this.confirmationService.confirm({
      key: 'confirmDialog',
      message: 'Voulez-vous vraiment supprimer ce message ?',
      accept: () => {
        this.conversationService.SupprimerMessage(message.messageId).subscribe({
          next: (response) => {
            this.messageService.add({ severity: 'info', summary: 'Suppression', detail: 'Le message a été supprimé' });
            this.messages = this.messages.filter(m => m.messageId != message.messageId);
          },
          error: (error) => {
            this.conversationService.errorHandler(error, 'le message');
          }
        });
      }
    });
  }

  obtenirApercu(doc: DocumentEntite[], message: MessageEntite) {
    var ids = doc.filter(x => !x.apercu && x.typeFichier == ETypeFichier.Image).map(x => x.documentId);

    if (ids.length == 0) 
        return;

    this.documentService.ObtenirApercus(ETypeLienDocument.Message, ids).then(res => {
        if (res.body) {
            Object.keys(res.body).forEach(x => {
                message.documents.find(y => y.documentId == parseInt(x)).apercu = 'data:image/jpeg;base64,' + res.body[x];
            });
        }
    });
  }

  downloadDocument(document: DocumentEntite) {
    window.open(`${environment.apiDocumentUrl}tm/${document.cheminFichier}/${document.nomFichier}`, '_blank');
  }

  isOwner(message: MessageEntite) {
    return this.profilService.ProfilCourant().employeId == message.employe?.employeId;
  }

  onShowMenu(message: MessageEntite, event: Event) {
    this.items = [
      { label: 'Modifier', icon: 'pi pi-pencil', command: () => this.editMessage(message) },
      { label: 'Supprimer', icon: 'pi pi-trash', command: () => this.deleteMessage(message) }
    ];
    this._menu.toggle(event);
  }

  loadMessages($event: LazyLoadEvent) {
    var ids = this.messages.slice($event.first, $event.first + $event.rows).map(m => m.messageId);
    this.conversationService.ObtenirPlusieursMessages(ids).subscribe((response) => {
      this.messages = this.messages.map(m => {
        var message = response.body.find(m2 => m2.messageId == m.messageId);
        if (message)
          this.obtenirApercu(message.documents, message);
          m = message;
        return m;
      });
    });
  }

  onFocus() {
    this.isEditing = true;

    const toolbar = this.editor.quillEditor.getModule('toolbar') as Toolbar;
    toolbar.container.style.display = 'block';
    toolbar.container.style.borderTop = "3px solid var(--primary-color)";
    toolbar.container.style.borderLeft = "3px solid var(--primary-color)";
    toolbar.container.style.borderRight = "3px solid var(--primary-color)";
    toolbar.container.style.borderTopLeftRadius = "var(--border-radius)";
    toolbar.container.style.borderTopRightRadius = "var(--border-radius)";
    this.editor.quillEditor.container.style.borderTopLeftRadius = "0px";
    this.editor.quillEditor.container.style.borderTopRightRadius = "0px";
    this.editor.quillEditor.container.style.border = "3px solid var(--primary-color)"
    this.editor.quillEditor.container.style.borderTop = "none";
  }

  onBlur() {
    if (this.newMessage.texte == '<p></p>') {
      this.isEditing = false;
      (this.editor.quillEditor.getModule('toolbar') as Toolbar).container.style.display = 'none';
      this.editor.quillEditor.container.style.border = "1px solid var(--text-color)"
      this.editor.quillEditor.container.style.borderRadius = "var(--border-radius)"
    }
  }
}