import { Component, OnInit } from "@angular/core"; import { Location } from "@angular/common";
import { BreadcrumbService } from '../../app.breadcrumb.service';
import { MenuItem } from 'primeng/api/menuitem';
import { MessageService, ConfirmationService } from 'primeng/api';
import {ToastModule} from 'primeng/toast';

import {DialogService} from 'primeng/dynamicdialog';
import { Router, ActivatedRoute } from '@angular/router';
import { ListeAchatService } from '../../services/liste-achat-service';
import { ListeAchatEntite } from '../../entites/liste-achat';
import { ItemListeAchatEntite } from '../../entites/item-liste-achat';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { ProfilService } from 'src/app/services/profil-service';
import { AppMainComponent } from 'src/app/app.main.component';
import { BaseComponent } from 'src/app/base/base-component';


@Component({
    templateUrl: './saisir-liste-achat-e3.component.html',
    styleUrls: ['./saisir-liste-achat-e3.component.scss'],
    providers: [MessageService, ConfirmationService, DialogService]
})
export class SaisirListeAchatEtape3Component extends BaseComponent implements OnInit {

    note:string;

    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute, 
		appMain: AppMainComponent, location: Location, 
        private breadcrumbService: BreadcrumbService,
        private listeAchatService: ListeAchatService,
        private messageService: MessageService,
        public dialogService: DialogService,
        public routerService: Router) {
            super(sdb, profilService, route, routerService, appMain);
        this.breadcrumbService.setItems([
            { label: 'Saisir une liste d\'achat', routerLink: '/listeachat/etape1' },
            { label: 'Étape 2', routerLink: '/listeachat/etape2' },
            { label: 'Étape 3' },
        ]);
    }

    ngOnInit() {

        this.verifierAccesPage('liste_achat');

        if (!this.listeAchatService.modeleListeAchatId) {
            this.routerService.navigateByUrl('listeachat/etape1');
            return;
        }

        this.note = this.listeAchatService.listeAchat.note;
    }

    nextPage() {

        this.listeAchatService.listeAchat.note = this.note;
        this.routerService.navigate(['listeachat/etape4']);

    }

    previousPage() {

        this.listeAchatService.listeAchat.note = this.note;
        this.routerService.navigate(['listeachat/etape2']);

    }

}
