import { Component, ViewChild, ElementRef, ɵConsole } from "@angular/core"; import { Location } from "@angular/common";
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { CleValeur, CleValeurNumerique } from '../entites/cleValeur';
import { EntreeCommande } from '../entites/entree-commande';
import { ItemMin } from '../entites/item-min';
import { Statut } from '../entites/statut';
import { DonneesBaseService } from '../services/donnees-base-service';
import { BaseComponent } from '../base/base-component';
import { ItemService } from '../services/item-service';
import { BlockUIModule } from 'primeng/blockui';
import { FournisseurService } from '../services/fournisseur-service';
import { Fournisseur } from '../entites/fournisseur';
import { EmployeEntite } from '../entites/employe';
import { ItemCommandeEntite } from '../entites/item-commande';
import { MessageService } from 'primeng/api';
import { ProfilService } from '../services/profil-service';
import { ActivatedRoute, Router } from '@angular/router';
import { AchatService } from '../services/achat-service';
import { ContactEntite } from '../entites/contact';
import { ContactService } from '../services/contact-service';
import { RapportService } from '../services/rapport-service';
import { AppMainComponent } from '../app.main.component';
import { RequisitionMin } from '../entites/requisition-min';
import { RequisitionService } from '../services/requisition-service';

export interface ParametresSelectionRetour {
    retours: RequisitionMin[];
}


@Component({
    templateUrl: './selection-retour.component.html'
})
export class SelectionRetourComponent extends BaseComponent {

    donnees: ParametresSelectionRetour;

    retours: RequisitionMin[];
    selectionRetour: RequisitionMin;

    submitted: boolean;

    @ViewChild('boutonConfirmer') boutonConfirmer;

    constructor(
        profilService: ProfilService,
        route: ActivatedRoute, 
        routerService: Router,
		appMain: AppMainComponent, location: Location, 
        public contactService: ContactService,
        public achatService: AchatService,
        public rapportService: RapportService,
        public fournisseurService: FournisseurService,
        private requisitionService: RequisitionService,
        public messageService: MessageService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public sdb: DonneesBaseService) {
        super(sdb, profilService, route, routerService, appMain);
    }

    ngOnInit() {

        this.donnees = this.config.data;


        this.retours = this.donnees.retours;

        if (this.retours.length == 1) {
            this.selectionRetour = this.retours[0];
        }

        this.CompleterChargement();

        setTimeout(() => { this.boutonConfirmer.nativeElement.focus() }, 25);

        /* this.requisitionService.ObtenirRetoursActifs().then(res => {
            if (res.estUnSucces) {

                this.retours = res.valeur;

                if (this.retours.length == 1) {
                    this.selectionRetour = [...this.retours];
                }

                this.CompleterChargement();

                setTimeout(() => { this.boutonConfirmer.nativeElement.focus() }, 25);
            }
        }) */

    }

    annuler() {
        this.ref.close(null);
    }

    envoyer() {

        this.submitted = true;
        if (!this.valider()) {
            this.messageService.add({ key: 'selectionRetour', severity: 'error', summary: 'Erreurs', detail: 'Veuillez corriger les erreurs de saisie.' });
            return;
        }

        this.ref.close(this.selectionRetour);
    }

    nouveau() {
        this.ref.close({
            requisitionId: 0
        });
    }

    valider() {
        return this.selectionRetour;
    }
}