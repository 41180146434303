import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MessageService } from 'primeng/api';
import { ActionCorrectiveEntite } from '../../entites/action-corrective';
import { NonConformiteService } from '../../services/non-conformite-service';
import { BaseComponent } from '../../base/base-component';
import { DonneesBaseService } from '../../services/donnees-base-service';
import { ProfilService } from '../../services/profil-service';
import { Router } from '@angular/router';
import { AppMainComponent } from 'src/app/app.main.component';
import { MenuItem } from 'primeng/api';
import { TabViewChangeEvent } from 'primeng/tabview';
import { ConfirmationService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { DetailNonConformiteComponent } from './detail-non-conformite.component';
import { DialogService } from 'primeng/dynamicdialog';
import { NonConformiteSimpleEntite } from '../../entites/non-conformite-simple';
import { Editor, Toolbar } from 'ngx-editor';
import { ETypeLienDocument } from '../../entites/enums/type-lien-document';
import { NonConformiteEntite } from '../../entites/non-conformite';
@Component({
  selector: 'app-detail-action-corrective',
  templateUrl: './detail-action-corrective.component.html',
  providers: [MessageService, ConfirmationService, DialogService]
})
export class DetailActionCorrectiveComponent extends BaseComponent implements OnInit {
  actionCorrective: ActionCorrectiveEntite;
  actionCorrectiveId: number;
  nonConformites: NonConformiteSimpleEntite[];
  nonConformite: NonConformiteEntite;
  tabIndex: number = 0;
  menu: MenuItem[];
  suivis = [
    { isChantier: true, date: '10/01/2024', image: 'product/laptop.png' },
    { isChantier: false, date: '10/01/2024', image: 'product/laptop.png' },
  ];

  constructor(
    private messageService: MessageService,
    private nonConformiteService: NonConformiteService,
    public sdb: DonneesBaseService,
    public profilService: ProfilService,
    public route: ActivatedRoute,
    public routerService: Router,
    public appMain: AppMainComponent,
    public location: Location,
    public breadcrumbService: BreadcrumbService,
    public dialogService: DialogService
  ) {
    super(sdb, profilService, route, routerService, appMain);

    this.breadcrumbService.setItems([
      { label: 'Action corrective', routerLink: ['/assurancequalite/actioncorrective'] },
      { label: 'Détail' }
    ]);
  }

  ngOnInit() {
    this.route.params.subscribe(async (params) => {
      this.actionCorrectiveId = params['id'];
      this.loadActionCorrectiveDetails().then(() => {
        this.initialiserMenu();
        this.CompleterChargement();
      });
    });
  }
  
  initialiserMenu() {
    this.menu = [
      { label: 'Retour', icon: 'pi pi-angle-double-left', command: () => this.location.back() },
      { label: 'Voir la non-conformité', icon: 'pi pi-eye', command: () => this.voirNonConformite() },
      {
        label: 'Actions', icon: 'pi pi-fw pi-bars',
        items: [
          {
            label: 'Archiver', icon: 'pi pi-fw pi-folder-open',
            visible: /*this.estAutorise('nonconformite_action_archiver') &&*/ this.nonConformite.employe.employeId != this.profilService.ProfilCourant().employeId,
            command: () => this.archiver()
          }
        ]
      },
    ];
  }

  async loadActionCorrectiveDetails() {
    var promises = [];

    promises.push(new Promise((resolve, reject) => {
      this.nonConformiteService.Obtenir().then(
        (data) => {
          this.nonConformites = data.valeur;
          resolve(void 0);
        },
        (error) => {
          this.messageService.add({ severity: 'error', summary: 'Erreur', detail: 'Erreur lors du chargement des non-conformités' });
          reject();
        }
      );
    }));

    promises.push(new Promise((resolve, reject) => {
      this.nonConformiteService.ObtenirActionCorrective(this.actionCorrectiveId).then(
        async (data) => {
          this.actionCorrective = data.valeur;
          await this.nonConformiteService.ObtenirDetail(this.actionCorrective.nonConformite.idNonConformite).then(
            (data) => {
              this.nonConformite = data.valeur;
              resolve(void 0);
            },
            (error) => {
              this.messageService.add({ severity: 'error', summary: 'Erreur', detail: 'Erreur lors du chargement de l\'action corrective' });
              reject();
            });
          resolve(void 0);
        },
        (error) => {
          this.messageService.add({ severity: 'error', summary: 'Erreur', detail: 'Erreur lors du chargement de l\'action corrective' });
          reject();
        });
    }));

    await Promise.all(promises);

    return;
  }

  archiver() {
    this.nonConformiteService.ArchiverActionCorrective(this.actionCorrective.actionCorrectiveId).then(
      (data) => {
        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Action corrective archivée avec succès' });
        this.location.back();
      },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Erreur', detail: 'Erreur lors de l\'archivage de l\'action corrective' });
      }
    );
  }

  surChangementOnglet(event: TabViewChangeEvent) {
    this.tabIndex = event.index;
  }


  surModification(event: any) {
    console.log(event);
  }

  voirNonConformite() {
    this.routerService.navigate(['/assurancequalite/nonconformite', this.actionCorrective.nonConformite.idNonConformite]);
  }

  enregistrerModifications() {
    this.nonConformiteService.ModifierActionCorrective(this.actionCorrective).then(
      (data) => {
        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Action corrective modifiée avec succès' });
        this.Consulter();
      },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Erreur', detail: 'Erreur lors de la modification de l\'action corrective' });
      }
    );
  }

  get ETypeLienDocument() {
    return ETypeLienDocument;
  }
}