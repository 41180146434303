<p-toast key="assignerEntretien"></p-toast>

<p-blockUI [blocked]="blocked">
	<div class="fluid formgrid grid">
		<div class="flex flex-column gap-2 col-12 text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-blockUI>


<p-card *ngIf="!EstCharge()">
	<div class="fluid formgrid grid">
		<div class="flex flex-column gap-2 col-12 text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-card>

<div #contenu *ngIf="EstCharge()">

	<div class="fluid formgrid grid mb-2" *ngIf="donnees.alerteEtapesNonCompletes">
		<div class="flex flex-column gap-2 col-12">
			<p-message severity="warn" text="Certaines étapes ne sont pas complétés." styleClass="mr-2"></p-message>
		</div>
	</div>

	<div class="fluid formgrid grid">

		<div class="flex flex-column gap-2 col-12 md:col-6 lg:col-3">
			<label for="date">Date de début <span style="color:red">*</span></label>
			<p-calendar appendTo="body" showIcon=true [(ngModel)]="bonTravail.dateTravail" dateFormat="yy-mm-dd"
				[yearNavigator]="true" [monthNavigator]="true" yearRange="2000:2040"></p-calendar>
			<small class="p-error" *ngIf="submitted && !bonTravail.dateTravail">La date de début est
				obligatoire.</small>

		</div>
		<div class="flex flex-column gap-2 col-12 md:col-6 lg:col-3">
			<label for="date">Date de fin <span style="color:red">*</span></label>
			<p-calendar appendTo="body" showIcon=true [(ngModel)]="bonTravail.dateFin" dateFormat="yy-mm-dd"
				[yearNavigator]="true" [monthNavigator]="true" yearRange="2000:2040"></p-calendar>
			<small class="p-error" *ngIf="submitted && !bonTravail.dateFin">La date de fin est
				obligatoire.</small>
		</div>
		<div class="flex flex-column gap-2 col-12 md:col-6 lg:col-3">
			<label for="date">Heures au compteur
				<span *ngIf="bonTravail.item.indSuiviHeures" style="color:red">*</span>
			</label>
			<input #inputHeures type="number" pInputText id="heures" [(ngModel)]="bonTravail.heuresCompteur"
				autocomplete="off" />
		</div>
		<div class="flex flex-column gap-2 col-12 md:col-6 lg:col-3">
			<label for="date">KMs au compteur
				<span *ngIf="bonTravail.item.indSuiviKms" style="color:red">*</span>
			</label>
			<input #inputHeures type="number" pInputText id="heures" [(ngModel)]="bonTravail.kmCompteur"
				autocomplete="off" />
		</div>

	</div>

	<div class="fluid formgrid grid mt-2" *ngIf="donnees.alerteEtapesNonCompletes">
		<div class="flex flex-column gap-2 col-12">
			<p-checkbox label="Créer un nouveau bon de travail avec les étapes non complétées" [binary]="true"
				[(ngModel)]="indCreerPartiel"></p-checkbox>
		</div>
	</div>

	<p-divider *ngIf="bonTravail.indSuiviCouts">
		Suivi des coûts
	</p-divider>

	<div *ngIf="bonTravail.indSuiviCouts" class="fluid formgrid grid">
		<div class="flex flex-column gap-2 col-12">
			<label for="date">Plage de date pour le calcul des coûts</label>
			<div class="flex gap-2">
				<div class="flex flex-column gap-1">
					<p-calendar class="max-w-full" [(ngModel)]="dateDebut" [showWeek]="true" [showIcon]="true"
						appendTo="body" />
					<small class="p-error" *ngIf="submittedItems && !dateDebut">La date de début est
						obligatoire.</small>
				</div>
				<div class="flex flex-column gap-1">
					<p-calendar class="max-w-full" [(ngModel)]="dateFin" [showWeek]="true" [showIcon]="true"
						appendTo="body" />
					<small class="p-error" *ngIf="submittedItems && !dateFin">La date de fin est obligatoire.</small>
				</div>
				<p-button label="Rechercher" icon="pi pi-search" (click)="rechercher()"></p-button>
			</div>
			<small>Cliquez pour rechercher tout les items de bon de commande dans la plage de date.</small>
		</div>
		<p-messages *ngIf="piecesSansCout.length > 0" severity="warn" [styleClass]="'mb-2 mt-2 w-full'">
			<ng-template pTemplate>
				<div class="flex align-items-center gap-2">
					<p class="m-0">Le pièces suivantes n'ont pas de coût associé:</p>
					<ul class="mr-2">
						<li *ngFor="let piece of piecesSansCout">{{piece.item.numero}} - {{piece.item.description}}</li>
					</ul>
					<p-button type="button" label="Ajouter un bon de commande" [outlined]="true" icon="pi pi-plus" (click)="goToAddBonCommande()"></p-button>
				</div>
 			</ng-template>
		</p-messages>
		<div class="col-12 mt-2">
			<p-table [value]="items" [loading]="loading" [showLoader]="false"
				[scrollable]="true" scrollHeight="300px" dataKey="itemBonCommandeId"
				rowGroupMode="subheader" groupRowsBy="item.numero">
				<ng-template pTemplate="header">
					<tr>
						<th>Numéro Bon Commande</th>
						<th>Quantité</th>
						<th>Prix</th>
						<th>Total</th>
						<th>Quantité à utiliser</th>
					</tr>
				</ng-template>
				<ng-template pTemplate="groupheader" let-item let-index="rowIndex">
					<tr>
						<td colspan="5">
							<div class="flex justify-content-between w-full">
								<strong>Item: {{item.item?.numero}} - {{item.item?.description}}</strong>
								<strong>Quantité requise: {{getQuantiteRequise(item.item.itemId)}}</strong>
							</div>
						</td>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-item let-index="rowIndex">
					<tr>
						<td>{{item.bonCommande?.numero}}</td>
						<td>{{item.quantite}}</td>
						<td>{{item.prix | currency:'CAD':'symbol-narrow':'1.2-2'}}</td>
						<td>{{item.quantite * item.prix | currency:'CAD':'symbol-narrow':'1.2-2'}}</td>
						<p-inputNumber 
							[(ngModel)]="item.quantiteUtilisee" 
							[showButtons]="true" 
							buttonLayout="horizontal" 
							inputId="horizontal" 
							spinnerMode="horizontal" 
							[step]="1"
							[min]="0"
							[max]="item.quantite"
							decrementButtonClass="p-button-danger"
							incrementButtonClass="p-button-success"
							incrementButtonIcon="pi pi-plus" 
							decrementButtonIcon="pi pi-minus" 
							[style]="{'width': '40px'}"/>
					</tr>
				</ng-template>
				<ng-template pTemplate="emptymessage">
					<tr>
						<td colspan="5">Aucun item trouvé</td>
					</tr>
				</ng-template>
				<ng-template pTemplate="loadingbody">
					<tr>
						<td colspan="5" class="text-center">
							<p-progressSpinner></p-progressSpinner>
						</td>
					</tr>
				</ng-template>
			</p-table>
		</div>
	</div>

	<div class="text-right mt-3">
		<button pButton pRipple label="Approuver" icon="pi pi-save" class="p-button-primary"
			(click)="approuver()"></button>
		<button pButton pRipple label="Annuler" icon="pi pi-ban" class="p-button-text" (click)="annuler()"></button>
	</div>
</div>