import { Component, ViewChild, Input } from "@angular/core"; import { Location } from "@angular/common";
import { DonneesBaseService } from '../services/donnees-base-service';
import { BaseComponent } from '../base/base-component';
import { ProfilService } from '../services/profil-service';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AppMainComponent } from '../app.main.component';
import { ItemService } from '../services/item-service';

@Component({
    selector: 'app-navigation-directe',
    templateUrl: './navigation-directe.component.html',
})
export class NavigationDirecteComponent extends BaseComponent {

    @Input() type: number;
    
    numeroNavigationDirecte: string;

    @ViewChild('panelNavigationDirecte') panelNavigationDirecte;
    @ViewChild('champNavigationDirecte') champNavigationDirecte;

    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute, 
        routerService: Router,
        appMain: AppMainComponent, location: Location, 
        private messageService: MessageService,
        private itemService: ItemService) {
        super(sdb, profilService, route, routerService, appMain);
    }

    ngOnInit() {
    }



    navigationDirecte(event) {
        this.numeroNavigationDirecte = '';
        this.panelNavigationDirecte.toggle(event);
        setTimeout(() => { 
            this.champNavigationDirecte.nativeElement.focus();
         }, 100);
    }

    confirmerNavigationDirecte() {

        this.panelNavigationDirecte.hide();
        
        this.Bloquer();

        if (this.type == 1) {
            this.itemService.VerifierExiste(this.numeroNavigationDirecte).then(res => {
                
                if (res.estUnSucces) {
                    if (res.valeur) {
                        this.routerService.navigateByUrl('/', {skipLocationChange: true}).then(()=>
                            this.routerService.navigate(['item/' + res.valeur.toString()]));
    
                    } else {
                        this.messageService.add({ severity: 'error', summary: 'Erreur', detail: `L'item numéro '${this.numeroNavigationDirecte}' n'existe pas.` });
                    }
                } else {
                    this.messageService.add({ severity: 'error', summary: 'Erreur', detail: `L'item numéro '${this.numeroNavigationDirecte}' n'existe pas.` });
                }
            });
        }
        
        this.Debloquer();
        
    }
}