import { Component, OnInit, ViewChild, Input, ElementRef, HostListener } from "@angular/core"; import { Location } from "@angular/common";
import { MenuItem } from 'primeng/api';
import { MessageService, ConfirmationService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Router, ActivatedRoute } from '@angular/router';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { ProfilService } from 'src/app/services/profil-service';
import { SelectionItemComponent, ETypeSelectionItem, ResultatSelectionItem } from 'src/app/controles/selection-item.component';
import { ContextMenu } from 'primeng/contextmenu/public_api';
import { EStatuts } from 'src/app/entites/enums/statuts';
import { RapportService } from 'src/app/services/rapport-service';
import { DatePipe } from '@angular/common';
import { AppMainComponent } from 'src/app/app.main.component';
import { Fournisseur } from 'src/app/entites/fournisseur';
import { CodeProjetEntite } from 'src/app/entites/code-projet';
import { FournisseurService } from 'src/app/services/fournisseur-service';
import { BaseComponent } from 'src/app/base/base-component';
import { BonTravailEntite } from 'src/app/entites/mecanique/bon-travail';
import { Emplacement } from 'src/app/entites/emplacement';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { AchatService } from 'src/app/services/achat-service';
import { ItemService } from 'src/app/services/item-service';
import { BonTravailService } from 'src/app/services/bon-travail-service';
import { RaisonBonTravailEntite } from 'src/app/entites/mecanique/raison-bon-travail';
import { environment } from 'src/environments/environment';
import { EtapeBonTravailEntite } from 'src/app/entites/mecanique/etape-bon-travail';
import { SaisieNoteMecaniqueComponent, ParametresSaisieNoteMecanique } from 'src/app/controles/mecanique/saisie-note-mecanique.component';
import { CleValeur, CleValeurNumerique } from 'src/app/entites/cleValeur';
import { SelectionEtapesComponent, ResultatSelectionEtapes } from './controles/selection-etapes.component';
import { PieceBonTravailEntite } from 'src/app/entites/mecanique/piece-bon-travail';
import { SelectionPieceComponent } from 'src/app/controles/mecanique/selection-piece.component';
import { EntretienPreventifEntite } from 'src/app/entites/mecanique/entretien-preventif';
import { HistoriqueEntretienComponent } from 'src/app/controles/mecanique/historique-entretien.component';
import { ItemMin } from 'src/app/entites/item-min';
import { AjustementInventaireComponent, ResultatAjustementInventaire } from 'src/app/controles/ajustement-inventaire.component';
import { ConsulterFournisseursComponent } from 'src/app/controles/consulter-fournisseurs.component';
import { PrixFournisseurEntite } from 'src/app/entites/prix-fournisseur';
import { SaisieQuantiteComponent } from 'src/app/controles/saisie-quantite.component';
import { NoteEntite } from 'src/app/entites/note';
import { ETypeEtapeBonTravail } from 'src/app/entites/enums/type-etape-bon-travail';
import { EmployeEntite } from 'src/app/entites/employe';
import { NoteService } from 'src/app/services/note-service';
import { DetailEntretienCorrectifComponent } from 'src/app/controles/mecanique/detail-entretien-correctif.component';
import { EntretienCorrectifEntite } from 'src/app/entites/mecanique/entretien-correctif';
import { SaisieNoteComponent } from 'src/app/controles/saisie-note.component';
import { ApprouverBonTravailComponent } from './controles/approuver-bon-travail.component';
import { PieceUniteEntite } from 'src/app/entites/piece-unite';
import { HistoriquePosePieceComponent } from 'src/app/controles/mecanique/historique-pose-piece.component';
import { ETypeBonTravail } from 'src/app/entites/enums/type-bon-travail';
import { SelectionEmployesComponent } from 'src/app/controles/selection-employes.component';
import { EnvoyerApprobationBonTravailComponent, RetourEnvoiApprobationBonTravail } from './controles/envoyer-approbation-bon-travail.component';
import { DocumentEntite } from "../../../entites/document";
import { RequeteObtenirDocuments } from "../../../services/requetes/requete-obtenir-documents";
import { DocumentService } from "../../../services/document-service";
import { ContexteService } from "../../../services/contexte-service";
import { OptionEtapeBonTravail } from "src/app/entites/mecanique/option-etape-bon-travail";
import { TableRowReorderEvent } from "primeng/table";
import { Table } from "primeng/table";
import { ETypeLienDocument } from "src/app/entites/enums/type-lien-document";
import { RequeteApprobationSoumission } from "src/app/services/requetes/requete-approbation-soumission";
import { ComponentCanDeactivate } from "src/app/services/pending-changes.guard";
import { lastValueFrom, Observable, Subject } from "rxjs";
import { ConfirmDialog } from "primeng/confirmdialog";

enum CloseEvent {
    SaveAndQuit,
    Quit,
    Cancel
}

@Component({
    selector: 'app-detail-bdt',
    templateUrl: './detail-bon-travail.component.html',
    providers: [MessageService, ConfirmationService, DialogService, DatePipe],
    host: {
        '(document:keydown)': 'handleKeyboardEvents($event)'
    }
})
export class DetailBonTravailComponent extends BaseComponent implements OnInit, ComponentCanDeactivate  {

    @Input('bdtId') inputBdcId: number;

    modeApercu: boolean = false;
    modeApprobation: boolean = false;

    idsEtapeBtConversionManuelle: number[] = [];
    commentaire: string;

    bonTravailId: number;
    bonTravail: BonTravailEntite;
    selectionMultipleEtapes: EtapeBonTravailEntite[] = [];
    selectionMultiplePieces: PieceBonTravailEntite[] = [];
    lignesOuvertes: any = {};

    bonTravailTransferts: BonTravailEntite[] = [];
    selectionBonTravailTransfert: BonTravailEntite;

    pieces: PieceBonTravailEntite[] = [];

    notes: NoteEntite[];

    typesBonTravail: CleValeurNumerique[];
    selectionTypeBonTravail: CleValeurNumerique;

    emplacements: Emplacement[];
    fournisseurs: Fournisseur[];
    raisons: RaisonBonTravailEntite[];
    employes: EmployeEntite[];

    documents: DocumentEntite[];

    contenuMenuContextuelEtape: MenuItem[];
    contenuMenuContextuelPiece: MenuItem[];
    splitBtnItems: MenuItem[];
    saveAndQuitItems: MenuItem[];

    onClosePromise: Subject<boolean> = new Subject<boolean>();

    contenuMenuContextuelNote: MenuItem[];
    @ViewChild('menuContextuelNote') menuContextuelNote: ContextMenu;

    menu: MenuItem[];

    events: any[] = [
        { key: 'dateCreation', label: 'Date de création' },
        { key: 'dateTravail', label: 'Date de travail' },
        { key: 'dateFin', label: 'Date de fin' },
        { key: 'dateFermeture', label: 'Date de fermeture' },
    ];

    @ViewChild('descriptionItem') champDescription: ElementRef;
    @ViewChild('menuContextuelEtape') menuContextuelEtape: ContextMenu;
    @ViewChild('menuContextuelPiece') menuContextuelPiece: ContextMenu;
    @ViewChild('dtTravail') dtTravail: Table;
    @ViewChild('confirmDialog') confirmDialog: ConfirmationService;
    @ViewChild('confirmChanges') confirmChanges: ConfirmDialog;
    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute,
        appMain: AppMainComponent, 
        private location: Location,
        private breadcrumbService: BreadcrumbService,
        private bonTravailService: BonTravailService,
        private ItemsService: ItemService,
        private documentService: DocumentService,
        private contexteService: ContexteService,
        private fournisseurService: FournisseurService,
        private rapportService: RapportService,
        private noteService: NoteService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        public dialogService: DialogService,
        public routerService: Router,
        public datepipe: DatePipe) {

        super(sdb, profilService, route, routerService, appMain);

        this.breadcrumbService.setItems([
            { label: 'Bons de travail', routerLink: '/mecanique/bonstravail' },
            { label: 'Détails' }
        ]);

        this.route.params.subscribe(params => this.bonTravailId = params.id);
    }

    handleKeyboardEvents(event: KeyboardEvent) {
        // if (event.key == '+' && !this.estFenetreAjoutEtapeActive) {
        //     this.surAjoutEtape();
        // }
    }

    ngOnInit() {

        this.splitBtnItems = [
            { label: 'Transformer sans approbation', icon: 'pi pi-send', command: () => this.transfererEnBonTravail() }
        ];

        this.saveAndQuitItems = [
            { label: 'Quitter sans sauvegarder', icon: 'pi pi-times', command: () => this.onClose(CloseEvent.Quit) }
        ];

        if (this.inputBdcId) {
            this.bonTravailId = this.inputBdcId;
            this.modeApercu = true;

            console.log('Mode apercu');
            console.log(this.bonTravailId);
        }

        this.verifierAccesPageAuMoisUnAcces(['bonstravail', 'temp_mec']);

        var mode = this.route.snapshot.queryParamMap.get('mode');
        if (mode && mode == 'M') {
            this.Modifier();
        } else if (mode && mode == 'A') {
            this.modeApprobation = true;
        } else {
            this.Consulter();
        }

        this.raisons = this.sdb.RaisonsBonTravail();

        this.employes = this.sdb.EmployesMecanique();

        this.emplacements = this.sdb.Emplacements();

        this.typesBonTravail = [
            { "cle": 1, "valeur": "Interne" },
            { "cle": 2, "valeur": "Externe" }
        ];

        var p1 = new Promise<number>((resolve, reject) => {

            this.bonTravailService.ObtenirDetail(this.bonTravailId).then(x => {

                console.log(x.valeur);

                this.bonTravail = x.valeur;
                this.bonTravail.etapes.forEach(et => this.pieces.push(...et.pieces));

                //TODO : TEMP
                //this.selectionTypeBonTravail = this.typesBonTravail[0];

                if (this.bonTravail.type == ETypeBonTravail.Externe) {
                    this.selectionTypeBonTravail = this.typesBonTravail[1];
                } else {
                    this.selectionTypeBonTravail = this.typesBonTravail[0];
                }

                //TODO : Essayer de rendre l'obtention des notes asynchrones
                this.ItemsService.ObtenirNotesItem(this.bonTravail.item.itemId).then(x => {
                    this.notes = x.valeur;
                    resolve(null);
                });
            });
        });

        var p3 = new Promise<number>((resolve, reject) => {
            this.fournisseurService.ObtenirFournisseurs().then(x => {
                this.fournisseurs = x.valeur;
                resolve(null);
            });
        });

        Promise.all([p1, p3]).then(x => {

            this.initialiserBonTravail();
            this.initialiserMenu();
            this.CompleterChargement(`Bon de travail #${this.bonTravail.numero} (${this.bonTravail.item.numero})`);
            this.expandRows();
        });

        this.colonnesDisponibles = [
            /* { champ: 'categorie', entete: 'Catégorie', afficheParDefaut: false },
            { champ: 'sousCategorie', entete: 'Sous-catégorie', afficheParDefaut: false },
            { champ: 'marque', entete: 'Marque', afficheParDefaut: false },
            { champ: 'modele', entete: 'Modèle', afficheParDefaut: false },
            { champ: 'noSerie', entete: 'No série', afficheParDefaut: false },
            { champ: 'noPlaque', entete: 'No plaque', afficheParDefaut: false },
            { champ: 'poids', entete: 'Poids', afficheParDefaut: false } */
        ];

        this.colonnesSelectionnees = this.colonnesDisponibles.filter(x => x.afficheParDefaut);
    }

    @HostListener('window:beforeunload')
    public canDeactivate(): Promise<boolean> {
        if (this.modeConsultation()) return Promise.resolve(true);

        this.onClosePromise = new Subject<boolean>();

        this.confirmationService.confirm({
            key: 'save-changes'
        });

        return lastValueFrom(this.onClosePromise.asObservable());
    }


    async onClose(event: CloseEvent) {
        if (event == CloseEvent.SaveAndQuit) {
            const x = await this.enregistrerModifications();
            if (x) {
                this.location.back();
            } else {
                this.onClosePromise.next(false);
                this.onClosePromise.complete();
                this.confirmChanges.reject();
            }
        } else if (event == CloseEvent.Quit) {
            this.onClosePromise.next(true);
            this.onClosePromise.complete();
        } else if (event == CloseEvent.Cancel) {
            this.onClosePromise.next(false);
            this.onClosePromise.complete();
            this.confirmChanges.reject();
        }
    }

    chargerDocuments() {
        const requete: RequeteObtenirDocuments = {
            id: this.bonTravail.bonTravailId
        }
        this.documentService.ObtenirListeBonTravail(requete).then(res => {
            if (res.estUnSucces) {
                res.valeur.documents.forEach(doc => {
                    doc.apercu = 'data:image/jpeg;base64,' + doc.apercu;
                });


                this.documents = res.valeur.documents;
            }
        });
    }

    initialiserMenu() {

        //TODO: Compléter accès
        this.estModifiable = this.bonTravail.statut.statutId != EStatuts.Complete;

        if (this.bonTravail.indSoumission) {
            this.menu = [
                {
                    label: 'Retour', icon: 'pi pi-fw pi-angle-double-left', command: () => this.routerService.navigateByUrl('mecanique/bonstravail')
                },
                {
                    label: 'Imprimer', icon: 'pi pi-fw pi-print',
                    disabled: !this.modeConsultation(),
                    items: [
                        {
                            label: '1 page', icon: 'pi pi-fw pi-print',
                            command: () => this.imprimer(false)
                        },
                        {
                            label: '2 pages', icon: 'pi pi-fw pi-print',
                            command: () => this.imprimer(true)
                        },
                    ]
                },
                {
                    label: 'Envoyer', icon: 'pi pi-fw pi-send',
                    visible: this.estAutorise('temp_mec') || this.estAutorise('bontravail_envoyer_courriel'),
                    disabled: !this.modeConsultation(),
                    items: [
                        {
                            label: '1 page', icon: 'pi pi-fw pi-print',
                            command: () => this.envoyer(false)
                        },
                        {
                            label: '2 pages', icon: 'pi pi-fw pi-print',
                            command: () => this.envoyer(true)
                        },
                    ]
                },
                {
                    label: 'Voir le bon de travail', icon: 'pi pi-fw pi-eye',
                    visible: this.bonTravail.bonTravailLie != null,
                    command: () => this.routerService.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                        this.routerService.navigate(['mecanique/bontravail/' + this.bonTravail.bonTravailLie.bonTravailId]);
                    })
                },
                {
                    label: 'Actions', icon: 'pi pi-fw pi-bars',
                    disabled: !this.modeConsultation(),
                    items: [
                        {
                            label: 'Transférer en bon de travail', icon: 'pi pi-fw pi-arrow-right',
                            disabled: !this.estAutorise('temp_mec') || !this.estAutorise('bontravail_approuver'),
                            visible: this.bonTravail.statut.statutId != EStatuts.Complete,
                            command: () => this.OuvrirPopupApprobation()
                        },
                        { separator: this.bonTravail.statut.statutId != EStatuts.Complete, },
                        {
                            label: 'Supprimer la soumission', icon: 'pi pi-fw pi-times',
                            styleClass: 'menuSuppression',
                            command: () => this.supprimerbonTravail()
                        }
                    ]
                }
            ];
        } else {
            this.menu = [
                {
                    label: 'Retour', icon: 'pi pi-fw pi-angle-double-left', command: () => this.routerService.navigateByUrl('mecanique/bonstravail')
                },
                {
                    label: 'Imprimer', icon: 'pi pi-fw pi-print',
                    disabled: !this.modeConsultation(),
                    items: [
                        {
                            label: '1 page', icon: 'pi pi-fw pi-print',
                            command: () => this.imprimer(false)
                        },
                        {
                            label: '2 pages', icon: 'pi pi-fw pi-print',
                            command: () => this.imprimer(true)
                        },
                    ]
                },
                {
                    label: 'Envoyer', icon: 'pi pi-fw pi-send',
                    visible: this.estAutorise('temp_mec') || this.estAutorise('bontravail_envoyer_courriel'),
                    disabled: !this.modeConsultation(),
                    items: [
                        {
                            label: '1 page', icon: 'pi pi-fw pi-print',
                            command: () => this.envoyer(false)
                        },
                        {
                            label: '2 pages', icon: 'pi pi-fw pi-print',
                            command: () => this.envoyer(true)
                        },
                    ]
                },
                {
                    label: 'Voir la soumission', icon: 'pi pi-fw pi-eye',
                    visible: this.bonTravail.bonTravailLie != null,
                    command: () => this.routerService.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                        this.routerService.navigate(['mecanique/bontravail/' + this.bonTravail.bonTravailLie.bonTravailId]);
                    })
                },
                {
                    label: 'Actions', icon: 'pi pi-fw pi-bars',
                    disabled: !this.modeConsultation(),
                    items: [
                        {
                            label: 'Envoyer en approbation', icon: 'pi pi-fw pi-forward',
                            visible: /*(this.estAutorise('bontravail_envoyer_approb') || this.estAutorise('temp_mec')) &&*/ this.bonTravail.statut.statutId != EStatuts.Complete && this.bonTravail.statut.statutId != EStatuts.EnApprobation, //TODO
                            disabled: !this.auMoinsUnEtapeFait(),
                            command: _ => this.envoyerApprobation()
                        },
                        {
                            label: 'Approuver le bon de travail', icon: 'pi pi-fw pi-forward',
                            visible: /*(this.estAutorise('bontravail_approuver') || this.estAutorise('temp_mec')) &&*/ this.bonTravail.statut.statutId == EStatuts.EnApprobation, //TODO
                            disabled: !this.auMoinsUnEtapeFait(),
                            command: _ => this.approuver()
                        },
                        { separator: true },
                        {
                            label: 'Supprimer le bon de travail', icon: 'pi pi-fw pi-times',
                            styleClass: 'menuSuppression',
                            visible: (this.estAutorise('bontravail_supprimer') || this.estAutorise('temp_mec')) && this.bonTravail.statut.statutId != EStatuts.Complete,
                            //TODO
                            disabled: this.bonTravail.statut.statutId == EStatuts.Complete,
                            command: () => this.supprimerbonTravail()
                        }
                    ]
                },
            ];
        }
    }

    initialiserBonTravail() {
        if (this.bonTravail) {

            this.bonTravail.dateCreation = this.bonTravail.dateCreation ? new Date(this.bonTravail.dateCreation) : null;
            this.bonTravail.dateAssignation = this.bonTravail.dateAssignation ? new Date(this.bonTravail.dateAssignation) : null;
            this.bonTravail.dateTravail = this.bonTravail.dateTravail ? new Date(this.bonTravail.dateTravail) : null;
            this.bonTravail.dateFin = this.bonTravail.dateFin ? new Date(this.bonTravail.dateFin) : null;
            this.bonTravail.dateFermeture = this.bonTravail.dateFermeture ? new Date(this.bonTravail.dateFermeture) : null;

            if (this.bonTravail.mecanicien) {
                this.bonTravail.mecanicien = this.employes.find(x => x.employeId == this.bonTravail.mecanicien.employeId);
            }
            if (this.bonTravail.contreMaitre) {
                this.bonTravail.contreMaitre = this.employes.find(x => x.employeId == this.bonTravail.contreMaitre.employeId);
            }
            if (this.bonTravail.raison) {
                this.bonTravail.raison = this.raisons.find(x => x.raisonBonTravailId == this.bonTravail.raison.raisonBonTravailId);
            }
            if (this.bonTravail.fournisseur) {
                this.bonTravail.fournisseur = this.fournisseurs.find(x => x.fournisseurId == this.bonTravail.fournisseur.fournisseurId);
            }
            if (this.bonTravail.emplacement) {
                this.bonTravail.emplacement = this.emplacements.find(x => x.emplacementId == this.bonTravail.emplacement.emplacementId);
            }

            if (this.bonTravail.etapes) {
                this.bonTravail.etapes.forEach(et => {

                    et.idTemporaire = this.newGuid();

                    if (et.mecanicien) {
                        et.mecanicien = this.employes.find(x => x.employeId == et.mecanicien.employeId);
                    }

                    if (et.pieces) {
                        et.pieces.forEach(p => {
                            p.idTemporaire = et.idTemporaire;
                            p.idTemporaire2 = this.newGuid();
                        });
                    }
                });
            }

            this.bonTravail.etapes.forEach(et => {
                et.optionEtapeBonTravails.forEach(o => {
                    o.id = this.newGuid();
                });
                et.optionEtapeBonTravailIdTemporaire = et.optionEtapeBonTravails.find(o => o.optionEtapeBonTravailId == et.optionEtapeBonTravailId)?.id;
            });

            this.chargerDocuments();
            this.CalculTotal();
        }
    }

    initialiserDonneesRetour(bonTravail: BonTravailEntite) {
        this.bonTravail.statut = this.sdb.Statut(bonTravail.statut.statutId);
        this.bonTravail.dateAssignation = bonTravail.dateAssignation ? new Date(bonTravail.dateAssignation) : null;
        this.bonTravail.dateFin = bonTravail.dateFin ? new Date(bonTravail.dateFin) : null;
        this.bonTravail.dateFermeture = bonTravail.dateFermeture ? new Date(bonTravail.dateFermeture) : null;
        this.bonTravail.heuresCompteur = bonTravail.heuresCompteur;
        this.bonTravail.heuresReelles = bonTravail.heuresReelles;
        this.bonTravail.coutReelHeures = bonTravail.coutReelHeures;
        this.bonTravail.coutReelPieces = bonTravail.coutReelPieces;
        this.bonTravail.etapes = bonTravail.etapes;
    }

    CalculTotal() {
        this.bonTravail.coutReelHeures =  0;
        this.bonTravail.coutReelPieces =  0;

        if (this.bonTravail.indSoumission) {
            this.bonTravail.etapes.forEach(et => {
                if (et.indAFaire) {
                    var option = et.optionEtapeBonTravails.find(x => x.id == et.optionEtapeBonTravailIdTemporaire);

                    if (option) {
                        this.bonTravail.coutReelHeures += option.nbHeures ? option.nbHeures : 0;
                        this.bonTravail.coutReelPieces += option.montantPieces ? option.montantPieces : 0;
                    }
                }
            });
        } else {

        }
    }

    initialiserNoEtapes() {

        // if (!this.pieces) {
        //     this.pieces = [];
        // }

        this.pieces = [];

        var noEtape: number = 1;
        this.bonTravail.etapes.forEach(et => { 

            et.noEtape = noEtape;
            et.ordre = noEtape;

            if (et.pieces) {
                et.pieces.forEach(p => {
                    p.noEtape = noEtape;
                    this.pieces.push(p);
                });
            }

            noEtape = noEtape + 1;
        });

    }

    surChangementSelectionEtape() {
        console.log(this.lignesOuvertes);
    }

    ouvrirFicheComplete() {
        this.routerService.navigateByUrl('mecanique/bontravail/' + this.bonTravailId);
        this.appMain.afficherDetailBonTravail = false;
    }

    imprimer(multiPages: boolean) {
        this.messageService.add({ severity: 'info', summary: 'Demande de rapport', detail: `Votre demande de rapport a été envoyée.` });

        this.rapportService.ProduireRapportBonTravail(this.bonTravail.bonTravailId, multiPages, this.bonTravail.indSoumission).then(x => {
            if (x) {
                this.messageService.add({ severity: 'success', summary: 'Succès', detail: `Votre rapport a été généré.` });
            } else {
                this.messageService.add({ severity: 'error', summary: 'Erreur', detail: `Une erreur est survenue pendant la génération de votre rapport.` });
            }
        });
    }

    imprimerSoumission() {
        this.messageService.add({ severity: 'info', summary: 'Demande de rapport', detail: `Votre demande de rapport a été envoyée.` });
    }

    btnModifier_click() {
        this.Modifier();
        this.initialiserMenu();
    }

    annulerModification() {
        this.DemarrerChargement();

        this.bonTravailService.ObtenirDetail(this.bonTravailId).then(x => {

            this.initialiserDonneesRetour(x.valeur);
            this.initialiserBonTravail();
            this.Consulter();
            this.initialiserMenu();
            this.expandRows();
            this.CompleterChargement(`Bon de travail #${this.bonTravail.numero} (${this.bonTravail.item.numero})`)
        });

        this.initialiserMenu();

        this.Consulter();
    }

    submitted = false;

    /**
     * This function is used to save the modifications made to the work order.
     * It first validates the modifications, then blocks the UI, and sends the modified work order to the server.
     * If the server response is successful, it initializes the work order with the new data, consults the work order, initializes the menu, and converts the work order steps to manual if their some.
     * If the server response is not successful, it displays the error messages.
     *
     * Note: The commented out code at the end of the function seems to be a previous implementation for saving modifications to a purchase order. It's currently not in use.
     */
    async enregistrerModifications(): Promise<boolean> {
        // Set the submitted flag to true
        var erreurs = false;
        // Clear any existing messages
        this.messageService.clear();

        this.bonTravail.etapes.forEach(et => {
            if (this.isLastOptionEmpty(et.optionEtapeBonTravails)){
                et.optionEtapeBonTravails.splice(et.optionEtapeBonTravails.length - 1, 1);
            }
        });

        this.submitted = true;

        // Block the UI
        this.Bloquer();

        // Set the type of the work order
        this.bonTravail.type = this.selectionTypeBonTravail.cle;

        // Send the modified work order to the server
        const result = await this.bonTravailService.Modifier(this.bonTravail)
        
        // Unblock the UI
        this.Debloquer();
        if (result.estUnSucces) {
            this.initialiserDonneesRetour(result.valeur);
            this.initialiserBonTravail();
            this.Consulter();
            this.initialiserMenu();
            this.expandRows();
            this.dtTravail.reset();
            this.saveConvertirEtapesBonTravailToManuel()
                .then(x => {
                    if (x.some(x => !x.estUnSucces)) {
                        this.messageService.add({
                            severity: 'error',
                            summary: 'Erreur',
                            detail: 'Une erreur est survenue lors de la conversion de certaines étapes.'
                        });
                    } else {
                        this.messageService.add({
                            severity: 'success',
                            summary: 'Succès',
                            detail: 'Bon de travail modifié.'
                        });
                    }
                });

            this.routerService.navigate(['mecanique/bontravail/' + this.bonTravailId]).then();
            console.log(this.bonTravail.etapes);
            return true;
        } else {
            // If the server response is not successful, display the error messages
            result.messages.forEach(x => {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Erreur',
                    detail: x.description,
                    sticky: true
                });
            });
            return false;
        }
    }

    /**
     * This function is used to convert work order steps to manual.
     * It maps over the array of work order step IDs that need to be converted to manual,
     * and for each ID, it calls the ConvertirEtapeBonTravailToManuel function from the bonTravailService.
     * It returns a Promise that resolves when all the work order steps have been converted to manual.
     *
     * @returns {Promise} A Promise that resolves when all the work order steps have been converted to manual.
     */
    async saveConvertirEtapesBonTravailToManuel(): Promise<any> {
        const out = [];
        for (const id of this.idsEtapeBtConversionManuelle) {
            out.push(await this.bonTravailService.ConvertirEtapeBonTravailToManuel(id));
        }
        return out;
    }

    validerModification(): boolean {

        /*  if (!this.bonTravail.fournisseur) {
             return false;
         }

         if (!this.bonTravail.emplacement) {
             return false;
         }

         if (!this.bonTravail.projet) {
             return false;
         }

         if (!this.bonTravail.dateAchat) {
             return false;
         } */

        return true;
    }

    Approbation() {
        const ref = this.dialogService.open(SelectionEmployesComponent, {
            data: {
                employesIds: []
            },
            header: 'Envoi du bon de travail',
            width: '60%'
        }).onClose.subscribe((res: EmployeEntite[]) => {
            if (res) {

                this.Bloquer();

                var requete: RequeteApprobationSoumission = {
                    commentaire: this.commentaire,
                    destinataires: res.map(x => x.courriel)
                };

                this.bonTravailService.EnvoyerApprobationSoumission(this.bonTravail.bonTravailId, requete).subscribe({
                    next: (res) => {
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: "Soumission envoyée en approbation" });
                        this.bonTravail.statut = this.sdb.Statut(EStatuts.EnApprobation);
                        this.Debloquer();
                        this.confirmDialog.close(); 
                    },
                    error: (err) => {
                        this.bonTravailService.errorHandler(err, "Bon de travail");
                        this.Debloquer();
                    }
                });
            }
        });
    }

    OuvrirPopupApprobation() {
        if (this.modeApprobation) {
            this.transfererEnBonTravail();
            return;
        }

        this.confirmationService.confirm({
            target: event.target,
            key: 'transformer-soumission-bon-travail',
            message: 'Voulez-vous envoyer cette soumission en approbation ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
        });
    }

    auMoinsUnEtapeFait() {
        return this.bonTravail.etapes.filter(x => x.indComplete).length > 0
    }

    surChangementTypeBonTravail() {
        // TODO
    }

    surChangementOrdreEtape(event: TableRowReorderEvent) { 
        var etape = this.bonTravail.etapes.find(x => x.ordre - 1 == event.dragIndex);
        this.bonTravail.etapes.find(x => x.ordre - 1 == event.dropIndex).ordre = event.dragIndex + 1;
        etape.ordre = event.dropIndex + 1;
    }

    isLastOptionEmpty(options: OptionEtapeBonTravail[]): boolean {
        if (options.length === 0) {
            return true;
          }
          
          const lastOption = options[options.length - 1];
          return (
            (lastOption.montantPieces === null || lastOption.montantPieces === 0 || lastOption.montantPieces === undefined) &&
            (lastOption.nbHeures === null || lastOption.nbHeures === 0 || lastOption.nbHeures === undefined) &&
            (lastOption.description === '' || lastOption.description === undefined)
          );
    }

    supprimerbonTravail() {
        this.confirmationService.confirm({
            target: event.target,
            key: 'basic',
            message: 'Voulez-vous vraiment supprimer le bon de travail courant ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.bonTravailService.Supprimer(this.bonTravail.bonTravailId).then(res => {
                    if (res.estUnSucces) {
                        this.routerService.navigateByUrl('mecanique/bonstravail');
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Bon de travail supprimé.' });
                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                });

            }
        });
    }

    envoyerApprobation() {

        const ref = this.dialogService.open(EnvoyerApprobationBonTravailComponent, {
            data: {
                bonTravail: this.bonTravail
            },
            header: 'Envoyer le bon de travail en approbation',
            width: '50%'
        }).onClose.subscribe((res: RetourEnvoiApprobationBonTravail) => {

            if (res) {

                this.bonTravail.heuresMecanicien = res.nbHeures;
                this.bonTravail.kmMecanicien = res.nbKm;
                this.bonTravail.dateFin = new Date(res.dateFin);

                this.bonTravailService.EnvoyerApprobation(this.bonTravail).then(res => {
                    if (res.estUnSucces) {
                        this.initialiserDonneesRetour(res.valeur);
                        this.initialiserMenu();
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Bon de travail envoyé en approbation.' });
                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                });
            }
        });

    }

    approuver() {

        const ref = this.dialogService.open(ApprouverBonTravailComponent, {
            data: {
                bonTravail: this.bonTravail,
                alerteEtapesNonCompletes: this.bonTravail.etapes.filter(x => !x.indComplete).length > 0
            },
            header: 'Approuver le bon de travail',
            width: '50%',
        }).onClose.subscribe((res: BonTravailEntite) => {

            if (res) {
                this.initialiserDonneesRetour(res);
                this.initialiserMenu();
                this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Bon de travail complété.' });
            }
        });
    }

    transfererEnBonTravail() {
        this.bonTravailService.TransformerSoumission(this.bonTravail).then(res => {
            if (res.estUnSucces) {
                this.routerService.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                    this.routerService.navigate(['mecanique/bontravail/' + res.valeur.bonTravailLie.bonTravailId]);
                });
            } else {
                res.messages.forEach(x => {
                    this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                });
            }
        });
    }



    /* ------------   ÉTAPES   ----------- */

    surMenuContextuelEtape(event, etape: EtapeBonTravailEntite) {

        this.contenuMenuContextuelEtape = [
            {
                label: etape.indElementMajeur ? 'Enlever Importante' : 'Mettre Importante', icon: 'pi pi-fw pi-star',
                visible: this.estAutorise('temp_mec') && !this.modeConsultation() && this.bonTravail.indSoumission,
                command: _ => {etape.indElementMajeur = !etape.indElementMajeur;}
            },
            {
                label: 'Voir l\'entretien', icon: 'pi pi-fw pi-eye',
                visible: this.modeConsultation() && this.estAutorise('temp_mec') && etape.typeEtapeBonTravail.typeEtapeBonTravailId == ETypeEtapeBonTravail.EntretienPreventif,
                command: _ => this.routerService.navigateByUrl('mecanique/entretienpreventif/' + etape.entretienPreventif.entretienPreventifId)
            },
            {
                label: 'Voir l\'historique', icon: 'pi pi-fw pi-calendar',
                visible: this.estAutorise('temp_mec') && etape.typeEtapeBonTravail.typeEtapeBonTravailId == ETypeEtapeBonTravail.EntretienPreventif,
                command: _ => this.consulterHistoriqueEntretienPreventif(this.bonTravail.item, etape.entretienPreventif)
            },
            {
                label: 'Annuler l\'entretien préventif', icon: 'pi pi-fw pi-times',
                visible: this.modeConsultation() && this.estAutorise('temp_mec') && etape.typeEtapeBonTravail.typeEtapeBonTravailId == ETypeEtapeBonTravail.EntretienPreventif && this.bonTravail.statut.statutId == EStatuts.Complete && etape.indComplete,
                command: _ => this.annulerEntretienPreventifComplete(etape)
            },
            {
                label: 'Modifier l\'entretien correctif', icon: 'pi pi-fw pi-pencil',
                visible: this.estAutorise('temp_mec') && etape.typeEtapeBonTravail.typeEtapeBonTravailId == ETypeEtapeBonTravail.EntretienCorrectif,
                command: _ => this.modifierEntretienCorrectif(etape, etape.entretienCorrectif)
            },
            // { separator: true },
            // {
            //     label: 'Ajouter une note', icon: 'pi pi-fw pi-pencil',
            //     visible: this.estAutorise('temp_mec') && !this.modeApercu,
            //     disabled: this.bonTravail.statut.statutId == EStatuts.Complete,
            //     command: () => this.ajouterNoteEtape(etape)
            // },
            // { separator: true },
            // {
            //     label: 'Supprimer', icon: 'pi pi-fw pi-times',
            //     visible: this.estAutorise('temp_mec') && !this.modeApercu,
            //     disabled: this.bonTravail.statut.statutId == EStatuts.Complete,
            //     command: () => this.supprimerEtapes(event, [etape])
            // }
        ];


        this.menuContextuelEtape.toggle(event);
    }

    descendreEtapeComplet(event, etape: EtapeBonTravailEntite) {
        var index = this.bonTravail.etapes.indexOf(etape);

        this.bonTravail.etapes.splice(index, 1);
        this.bonTravail.etapes.push(etape);

        this.initialiserNoEtapes();
    }


    monterEtapeComplet(event, etape: EtapeBonTravailEntite) {
        var index = this.bonTravail.etapes.indexOf(etape);

        this.bonTravail.etapes.splice(index, 1);
        this.bonTravail.etapes.unshift(etape);

        this.initialiserNoEtapes();
    }

    supprimerEtapes(event, itemsBonsTravail: EtapeBonTravailEntite[]) {
        var ids = itemsBonsTravail.map(b => b.idTemporaire);
        var noEtapes = itemsBonsTravail.map(b => b.noEtape);

        this.confirmationService.confirm({
            target: event.target,
            key: 'basic',
            message: itemsBonsTravail.length == 1 ? 'Voulez-vous vraiment supprimer l\'étape sélectionnée ?' : 'Voulez-vous vraiment supprimer les étapes sélectionnées ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {

                this.pieces = this.pieces.filter((p: PieceBonTravailEntite) => !noEtapes.includes(p.noEtape))

                this.bonTravail.etapes = this.bonTravail.etapes.filter((et: EtapeBonTravailEntite) => !ids.includes(et.idTemporaire))

                this.initialiserNoEtapes();
                this.CalculTotal();
            }
        });
    }

    /**
     * This function is used to convert work order steps to manual.
     * It first maps over the array of work order step IDs that need to be converted to manual,
     * and stores the IDs in the idsEtapeBtConversionManuelle array.
     * Then it checks if all the selected work order steps are of type 'EntretienCorrectif'.
     * If true, it opens a confirmation dialog to confirm the conversion of the steps to manual.
     * If the user confirms, it maps over the work order steps and changes their type to 'Autre'.
     * It then clears the selectionMultipleEtapes array and reinitializes the work order steps.
     * If not all the selected work order steps are of type 'EntretienCorrectif', it displays an error message.
     *
     * @param {any} event - The event object.
     * @param {EtapeBonTravailEntite[]} itemsBonsTravail - The array of work order steps to be converted to manual.
     */
    convertirEtapesVersAut(event, itemsBonsTravail: EtapeBonTravailEntite[]) {
        // Ajout d'un message d'erreur si une étape n'a pas d'identifiant
        if (itemsBonsTravail.some(x => x.etapeBonTravailId === 0)) {
            this.messageService.add({
                severity: 'error',
                summary: 'Erreur',
                detail: 'Veuillez enregistrer les étapes avant de les convertir.'
            });
            return;
        }
        this.idsEtapeBtConversionManuelle = itemsBonsTravail.map(b => b.etapeBonTravailId);

        // Verifier si toutes les étapes sont des entretiens préventifs
        // True = Convertir
        // False = Ne pas convertir
        var should_process = !!itemsBonsTravail.every(x => x.typeEtapeBonTravail.typeEtapeBonTravailId === ETypeEtapeBonTravail.EntretienCorrectif);
        if (should_process) {
            this.confirmationService.confirm({
                target: event.target,
                key: 'basic',
                message: itemsBonsTravail.length == 1 ? 'Voulez-vous vraiment convertir l\'étape sélectionnée en entretien manuel ?' : 'Voulez-vous vraiment convertir les étapes sélectionnées en entretiens manuels ?',
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.bonTravail.etapes = this.bonTravail.etapes.map((et: EtapeBonTravailEntite) => {
                        if (this.idsEtapeBtConversionManuelle.includes(et.etapeBonTravailId)) {
                            return {
                                ...et,
                                typeEtapeBonTravail: {
                                    typeEtapeBonTravailId: ETypeEtapeBonTravail.Autre,
                                    description: 'Autre',
                                    code: 'AUT'
                                },
                            }
                        } else {
                            return et;
                        }
                    });

                    this.selectionMultipleEtapes = [];

                    this.initialiserNoEtapes();
                }
            });
        } else {
            this.messageService.add({
                severity: 'error',
                summary: 'Erreur',
                detail: 'Certaines étapes sélectionné ne sont pas des EC. Seuls les EC peuvent etre convertie en manuel'
            });
        }
    }

    modifierEtapeBonTravail(itemBonTravail: EtapeBonTravailEntite) {

        /* const ref = this.dialogService.open(ModificationItemBonTravailComponent, {
            data: {
                itemBonTravail: itemBonTravail
            },
            header: 'Modifier un item',
            width: '90%'
        }).onClose.subscribe((res: ItemBonTravail) => {
            console.log(res);
            if (res) {
                itemBonTravail.quantite = res.quantite;
                itemBonTravail.noFournisseur = res.noFournisseur;
                itemBonTravail.prix = res.prix;
                itemBonTravail.codeProjet = res.codeProjet;
                itemBonTravail.codeActivite = res.codeActivite;
                itemBonTravail.codeCategorie = res.codeCategorie;
                itemBonTravail.note = res.note;
                this.messageService.add({ severity: 'success', summary: 'Succès', detail: `Item modifié.` });
            }

        }); */

    }

    modifierNote(event, etape: EtapeBonTravailEntite) {
        const ref = this.dialogService.open(SaisieNoteMecaniqueComponent, {
            data: {
                note: etape.note ? etape.note : '',
                afficherAjoutFiche: true,
                indAjouterFiche: false
            },
            header: 'Ajouter une note',
            width: '80%'
        }).onClose.subscribe((res: ParametresSaisieNoteMecanique) => {
            if (res) {
                etape.note = res.note;

                if (res.indAjouterFiche) {
                    this.Bloquer();
                    console.log('Ajout note a la fiche');
                    this.noteService.Ajouter({
                        date: new Date(),
                        employe: this.profilService.ProfilCourant(),
                        item: this.bonTravail.item,
                        description: res.note
                    }).then(res => {
                        this.Debloquer();
                    });
                }
            }
        });
    }

    annulerEntretienPreventifComplete(etape: EtapeBonTravailEntite) {
        this.confirmationService.confirm({
            target: event.target,
            key: 'basic',
            message: 'Voulez-vous vraiment l\'entretien préventif sélectionné ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {

                this.bonTravailService.AnnulerEntretienPreventifComplete(etape.etapeBonTravailId).then(res => {
                    if (res.estUnSucces) {
                        etape.indComplete = false;
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Étape annulée.' });
                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                });

            }
        });
    }

    // ajouterNoteEtape(etapeBonTravail: EtapeBonTravailEntite) {
    //     const ref = this.dialogService.open(SaisieNoteMecaniqueComponent, {
    //         data: {
    //             note: etapeBonTravail.note
    //         },
    //         header: 'Ajouter une note',
    //         width: '80%'
    //     }).onClose.subscribe((res: ParametresSaisieNoteMecanique) => {
    //         if (res) {

    //             this.Bloquer();

    //             const ancienneNote = etapeBonTravail.note;
    //             const anciennIndNotePersistante = etapeBonTravail.indNotePersistante;
    //             etapeBonTravail.note = res.note;
    //             etapeBonTravail.note = res.note;
    //             this.bonTravailService.Modifier(etapeBonTravail).then(resModif => {

    //                 this.Debloquer();

    //                 if (resModif.estUnSucces) {

    //                     this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Le note a été modifiée.' });

    //                 } else {
    //                     etapeBonTravail.note = ancienneNote;
    //                     etapeBonTravail.indNotePersistante = anciennIndNotePersistante;
    //                     resModif.messages.forEach(x => {
    //                         this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
    //                     });
    //                 }
    //             });
    //         }
    //     });
    // }


    estFenetreAjoutEtapeActive: boolean = false;

    surAjoutEtape() {
        this.estFenetreAjoutEtapeActive = true;
        const ref = this.dialogService.open(SelectionEtapesComponent, {
            data: {
                bonTravailId: this.bonTravail.bonTravailId,
                itemId: this.bonTravail.item.itemId,
                modeSoumission: this.bonTravail.indSoumission,
                entretienPreventifIds: this.bonTravail.etapes.filter(x => x.entretienPreventif).map(x => x.entretienPreventif.entretienPreventifId),
                entretienCorrectifIds: this.bonTravail.etapes.filter(x => x.entretienCorrectif).map(x => x.entretienCorrectif.entretienCorrectifId)
            },
            header: 'Ajouter une étape',
            width: '95%'
        }).onClose.subscribe((res: ResultatSelectionEtapes) => {
            this.estFenetreAjoutEtapeActive = false;
            if (res) {

                if (res.etapesPreventif) {
                    res.etapesPreventif.forEach(et => {
                        console.log(et);
                        et.idTemporaire = this.newGuid();
                        this.bonTravail.etapes.push(et);

                        if (et.entretienPreventif.pieces) {
                            et.entretienPreventif.pieces.forEach(p => {

                                var piece = {
                                    idTemporaire: this.newGuid(),
                                    idTemporaire2: this.newGuid(),
                                    item: p.item,
                                    noEtape: et.noEtape,
                                    quantite: p.quantite
                                };

                                //this.pieces.push(piece);

                                if (!et.pieces) {
                                    et.pieces = [];
                                }

                                et.pieces.push(piece)

                            });
                        }
                    });
                }
                if (res.etapesCorrectif) {
                    res.etapesCorrectif.forEach(et => {
                        et.idTemporaire = this.newGuid();
                        et.optionEtapeBonTravailIdTemporaire = this.newGuid();
                        this.bonTravail.etapes.push(et);
                    });
                }
                if (res.etapesVerification) {
                    res.etapesVerification.forEach(et => {
                        et.idTemporaire = this.newGuid();
                        this.bonTravail.etapes.push(et);
                    });
                }
                if (res.etapeManuel) {
                    res.etapeManuel.idTemporaire = this.newGuid();
                    res.etapeManuel.optionEtapeBonTravailIdTemporaire = this.newGuid();
                    this.bonTravail.etapes.push(res.etapeManuel);
                }

                this.initialiserNoEtapes();
                this.CalculTotal(); 
                this.expandRows();
                this.bonTravail = JSON.parse(JSON.stringify(this.bonTravail));

                this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Étape(s) ajoutée(s).' });
            }


        });

    }

    expandRows() {
        this.lignesOuvertes = this.bonTravail.etapes.reduce((acc, p) => (acc[p.idTemporaire] = true) && acc, {});
    }

    ajouterDocument() {

        this.contexteService.Vider();
        this.contexteService.Ajouter("bonTravailId", this.bonTravail.bonTravailId.toString());
        this.contexteService.Ajouter("numero", this.bonTravail.numero.toString());

        this.routerService.navigateByUrl('document/nouveau');
    }

    afficherImage(doc: DocumentEntite) {
        window.open(`${environment.apiDocumentUrl}vbt/${doc.cheminFichier}/${doc.nomFichier}`, "_blank");
    }

    telechargerDocument(doc: DocumentEntite) {
        window.open(`${environment.apiDocumentUrl}tbt/${doc.cheminFichier}/${doc.nomFichier}`, "_blank");
    }

    supprimerDocument(doc: DocumentEntite) {
        this.confirmationService.confirm({
            target: event.target,
            key: 'basic',
            message: 'Voulez-vous vraiment supprimer le document sélectionné ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.documentService.SupprimerDocument(doc.documentId).then(res => {
                    if (res.estUnSucces) {
                        this.documents = this.documents.filter(val => val.documentId != doc.documentId);
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Document supprimé.' });
                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                });

            }
        });
    }

    surChangementSelection() {
        this.initialiserMenu();
    }

    consulterHistoriqueEntretienPreventif(item: ItemMin, entretien: EntretienPreventifEntite) {
        const ref = this.dialogService.open(HistoriqueEntretienComponent, {
            data: {
                itemId: item.itemId,
                entretienPreventifId: entretien.entretienPreventifId
            },
            header: `Historique d'entretien - ${item.numero} - ${item.description} - ${entretien.description}`,
            width: '75%'
        }).onClose.subscribe(() => {


        });
    }

    modifierEntretienCorrectif(etape: EtapeBonTravailEntite, entretien: EntretienCorrectifEntite) {

        const ref = this.dialogService.open(DetailEntretienCorrectifComponent, {
            data: {
                entretienCorrectif: entretien
            },
            header: `Modifier un entretien correctif`,
            width: '75%'
        }).onClose.subscribe((res: EntretienCorrectifEntite) => {

            if (res) {
                entretien = res;
                etape.description = res.description;
                etape.categorie = res.categorie;
                etape.note = res.note;
                this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Entretien correctif modifié.' });
            }

        });

    }


    /* --------------  PIÈCES  ---------------- */


    surMenuContextuelPiece(event, pieceBonTravail: PieceBonTravailEntite) {

        this.contenuMenuContextuelPiece = [
            {
                label: 'Voir la pièce', icon: 'pi pi-fw pi-eye',
                visible: this.estAutorise('temp_mec'),
                command: _ => this.routerService.navigateByUrl('item/' + pieceBonTravail.item.itemId)
            },
            {
                label: 'Voir les fournisseurs', icon: 'pi pi-fw pi-user',
                visible: this.estAutorise('temp_mec'),
                command: _ => this.consulterFournisseurs(pieceBonTravail)
            },
            {
                label: 'Voir l\'historique de pose (unité)',
                icon: 'pi pi-fw pi-calendar',
                visible: this.estAutorise('temp_mec'),
                command: () => this.historiquePosePiece(pieceBonTravail, false)
            },
            {
                label: 'Voir l\'historique de pose (complet)',
                icon: 'pi pi-fw pi-calendar',
                visible: this.estAutorise('temp_mec'),
                command: () => this.historiquePosePiece(pieceBonTravail, true)
            },
            { separator: true },
            {
                label: 'Modifier la quantité', icon: 'pi pi-fw pi-pencil',
                visible: this.estAutorise('temp_mec') && !this.modeApercu,
                command: _ => this.modifierQuantite(pieceBonTravail)
            },
            // {
            //     label: 'Ajouter une note', icon: 'pi pi-fw pi-pencil',
            //     visible: this.estAutorise('temp_mec') && !this.modeApercu,
            //     disabled: this.bonTravail.statut.statutId == EStatuts.Complete,
            //     command: _ => this.ajouterNotePiece(pieceBonTravail)
            // },
            { separator: true, visible: this.estAutorise('temp_mec') },
            {
                label: 'Commander', icon: 'pi pi-fw pi-shopping-cart',
                visible: this.estAutorise('temp_mec'),
                /*  disabled: !itemCommande.item.categorie.estSuivi,
                 command: () => this.commander(itemCommande) */
            },
            {
                label: 'Ajuster l\'inventaire', icon: 'pi pi-fw pi-sliders-h',
                visible: this.estAutorise('temp_mec'),
                disabled: !pieceBonTravail.item.categorie?.estSuivi,
                command: () => this.ajusterInventaire(pieceBonTravail)
            },
            { separator: true },
            {
                label: 'Supprimer', icon: 'pi pi-fw pi-times',
                visible: this.estAutorise('temp_mec') && !this.modeApercu,
                disabled: this.bonTravail.statut.statutId == EStatuts.Complete
            }
        ];


        this.menuContextuelPiece.toggle(event);
    }

    ajouterPiece(event, etape: EtapeBonTravailEntite) {
        const ref = this.dialogService.open(SelectionPieceComponent, {
            data: {
                uniteId: this.bonTravail.item.itemId
            },
            header: 'Sélection d\'une pièce',
            width: '95%'
        }).onClose.subscribe((res: ResultatSelectionItem) => {

            if (res) {

                if (!etape.pieces) {
                    etape.pieces = [];
                }
                if (!etape.pieces) {
                    etape.pieces = [];
                }

                etape.pieces.push({
                    pieceBonTravailId: 0,
                    noEtape: etape.noEtape,
                    item: res.item,
                    quantite: res.quantiteDemande.toString(),
                    idTemporaire: etape.idTemporaire,
                    idTemporaire2: this.newGuid(),
                    note: res.note
                })

                this.initialiserNoEtapes();
                this.CalculTotal();
            }
        });
    }

    modifierNotePiece(event, piece: PieceBonTravailEntite) {
        const ref = this.dialogService.open(SaisieNoteMecaniqueComponent, {
            data: {
                note: piece.note ? piece.note : '',
                afficherAjoutFiche: false,
                indAjouterFiche: false
            },
            header: 'Ajouter une note',
            width: '80%'
        }).onClose.subscribe((res: ParametresSaisieNoteMecanique) => {
            if (res) {
                piece.note = res.note;
            }
        });
    }



    supprimerPieces(event, pieces: PieceBonTravailEntite[]) {
        var ids = pieces.map(b => b.idTemporaire2);
        this.confirmationService.confirm({
            target: event.target,
            key: 'basic',
            message: pieces.length == 1 ? 'Voulez-vous vraiment supprimer la pièce sélectionnée ?' : 'Voulez-vous vraiment supprimer les pièces sélectionnées ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {

                this.pieces = this.pieces.filter((p: PieceBonTravailEntite) => !ids.includes(p.idTemporaire2))

                if (this.bonTravail.etapes) {
                    this.bonTravail.etapes.forEach(et => {
                        if (et.pieces) {
                            et.pieces = et.pieces.filter((p: PieceBonTravailEntite) => !ids.includes(p.idTemporaire2))
                        }
                    });
                }

                this.CalculTotal();
            }
        });
    }

    ajusterInventaire(pieceBonTravail: PieceBonTravailEntite) {

        const ref = this.dialogService.open(AjustementInventaireComponent, {
            data: {
                itemId: pieceBonTravail.item.itemId,
                emplacementId: 10000,
                numero: pieceBonTravail.item.numero,
                description: pieceBonTravail.item.description,
                emplacement: this.sdb.Emplacement(10000),
                quantiteCourante: pieceBonTravail.qteInventaire // TODO : donner la responsabilité au composant d'ajustement d'aller chercher l'info sur l'inventaire courant
            },
            header: 'Ajustement d\'inventaire',
            width: '50%'
        }).onClose.subscribe((res: ResultatAjustementInventaire) => {
            if (res) {
                pieceBonTravail.qteInventaire = res.nouvelleQuantite;
                this.messageService.add({ severity: 'success', summary: 'Succès', detail: `L'inventaire a été ajusté.` });
            }
        });

    }

    historiquePosePiece(piece: PieceBonTravailEntite, complet: boolean) {
        const ref = this.dialogService.open(HistoriquePosePieceComponent, {
            data: {
                pieceId: piece.item.itemId,
                uniteId: complet ? null : this.bonTravail.item.itemId
            },
            header: 'Historique de pose',
            width: '90%'
        });
    }


    consulterFournisseurs(pieceBonTravail: PieceBonTravailEntite) {
        const ref = this.dialogService.open(ConsulterFournisseursComponent, {
            data: {
                itemId: pieceBonTravail.item.itemId,
                afficherSelection: true,
                afficherCommander: false
            },
            header: 'Consulter les fournisseurs',
            width: '50%'
        }).onClose.subscribe((res: PrixFournisseurEntite) => {
            console.log(res);
            if (res) {

            }

        });
    }


    // ajouterNotePiece(pieceBonTravail: PieceBonTravailEntite) {
    //     const ref = this.dialogService.open(SaisieNoteMecaniqueComponent, {
    //         data: {
    //             note: pieceBonTravail.note,
    //             indAjouterFiche: false
    //         },
    //         header: 'Ajouter une note',
    //         width: '80%'
    //     }).onClose.subscribe((res: ParametresSaisieNoteMecanique) => {
    //         if (res) {

    //             //TODO: TEMP
    //             pieceBonTravail.note = res.note;

    //             /* this.Bloquer();

    //             const ancienneNote = pieceBonTravail.note;
    //             const anciennIndNotePersistante = pieceBonTravail.indNotePersistante;
    //             pieceBonTravail.note = res.note;
    //             pieceBonTravail.note = res.note;
    //             this.bonTravailService.ModifierPieceBonTravail(pieceBonTravail).then(resModif => {

    //                 this.Debloquer();

    //                 if (resModif.estUnSucces) {

    //                     this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Le note a été modifiée.' });

    //                 } else {
    //                     etapeBonTravail.note = ancienneNote;
    //                     etapeBonTravail.indNotePersistante = anciennIndNotePersistante;
    //                     resModif.messages.forEach(x => {
    //                         this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
    //                     });
    //                 }
    //             }); */
    //         }
    //     });
    // }

    modifierQuantite(pieceBonTravail: PieceBonTravailEntite) {
        const ref = this.dialogService.open(SaisieQuantiteComponent, {
            data: {
                quantite: pieceBonTravail.quantite,
                min: 1
            },
            header: 'Saisir la quantité',
            width: '50%'
        }).onClose.subscribe((res: number) => {
            if (res) {

                //TODO: TEMP
                pieceBonTravail.quantite = res.toString();

                /* this.bonTravailService.ModifierPieceBonCommande({
                    employe: this.profilService.ProfilCourant(),
                    emplacement: this.emplacement,
                    itemsCommande: [{
                        itemCommandeId: itemCommande.itemCommandeId,
                        quantite: res
                    }]
                }).then(res => {
                    if (res.estUnSucces) {
            
                        res.valeur.itemCommandeModifies.forEach(x => {
                            var itemCommandeAModifier = this.itemsCommande.find(ic => ic.itemCommandeId == x.itemCommandeId);
                            itemCommandeAModifier.quantiteDemande = x.quantiteDemande;
                            itemCommandeAModifier.quantite = x.quantite;
                        });
        
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: `Quantité modifiée` });
        
                        this.initialiserMenu();
        
                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                }).catch(() => {
                    this.routerService.navigateByUrl('error');
                }); */
            }

        });
    }


    envoyer(multiPages: boolean) {
        const ref = this.dialogService.open(SelectionEmployesComponent, {
            data: {
                employesIds: this.bonTravail.mecanicien ? [this.bonTravail.mecanicien.employeId] : []
            },
            header: 'Envoi du bon de travail',
            width: '60%'
        }).onClose.subscribe((res: EmployeEntite[]) => {
            if (res) {

                this.Bloquer();

                var destinataires: string[] = res.map(x => x.courriel);

                this.rapportService.EnvoyerRapportBonTravail(this.bonTravail.bonTravailId, multiPages, this.bonTravail.indSoumission, destinataires).then(res => {

                    this.Debloquer();

                    if (res) {
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Le bon de travail a été envoyée.' });
                    } else {
                        this.messageService.add({ severity: 'error', summary: 'Erreur', detail: 'Une erreur s\'est produite lors de l\'envoi du courriel.' });
                    }
                });
            }
        });
    }


    envoyerSoumission() {
        this.messageService.add({ severity: 'info', summary: 'Soumission', detail: `Votre soumission a été envoyée.` });
    }



    /*   NOTES    */

    surMenuContextuelNote(event, note: NoteEntite) {
        this.contenuMenuContextuelNote = [
            {
                label: 'Modifier',
                icon: 'pi pi-fw pi-pencil',
                visible: this.estAutorise('item_note_modifier'),
                command: () => this.modifierNoteUnite(note)
            },
            { separator: true },
            {
                label: 'Ajouter en tant qu\'entretien correctif',
                icon: 'pi pi-fw pi-briefcase',
                visible: this.estAutorise('entretien_correctif_creer') || this.estAutorise('temp_mec'),
                disabled: !this.modeModification(),
                command: () => this.creerEntretienCorrectifDepuisNote(note)
            },
            { separator: true },
            {
                label: 'Supprimer',
                icon: 'pi pi-fw pi-trash',
                visible: this.estAutorise('item_note_supprimer'),
                command: () => this.supprimerNote(note)
            },
        ];

        this.menuContextuelNote.toggle(event);
    }

    ajouterNoteUnite() {
        const ref = this.dialogService.open(SaisieNoteComponent, {
            data: {
            },
            header: 'Ajouter une note',
            width: '80%'
        }).onClose.subscribe((res: string) => {
            if (res) {

                this.Bloquer();

                this.noteService.Ajouter({
                    date: new Date(),
                    description: res,
                    employe: this.profilService.ProfilCourant(),
                    item: {
                        itemId: this.bonTravail.item.itemId
                    }
                }).then(resAjoutNote => {

                    this.Debloquer();

                    if (resAjoutNote.estUnSucces) {

                        this.notes.push(resAjoutNote.valeur)

                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Le note a été ajoutée.' });

                    } else {
                        resAjoutNote.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                })
            }
        });
    }

    modifierNoteUnite(note: NoteEntite) {
        const ref = this.dialogService.open(SaisieNoteComponent, {
            data: {
                note: note.description
            },
            header: 'Modifier une note',
            width: '80%'
        }).onClose.subscribe((res: string) => {
            if (res) {

                this.Bloquer();

                const ancienneNote = note.description;
                note.description = res
                this.noteService.Modifier(note).then(resModifNote => {

                    this.Debloquer();

                    if (resModifNote.estUnSucces) {

                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Le note a été modifiée.' });

                    } else {
                        note.description = ancienneNote;
                        resModifNote.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                })
            }
        });
    }


    supprimerNote(note: NoteEntite) {
        this.confirmationService.confirm({
            key: 'basic',
            message: `Voulez-vous vraiment supprimer le note sélectionnée ?`,
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.noteService.Supprimer(note.noteId).then(res => {
                    if (res.estUnSucces) {
                        this.notes = this.notes.filter(val => val.noteId !== note.noteId);
                        //this.nbEntreesNotes--;
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Note supprimée' });
                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                });
            }
        });
    }

    creerEntretienCorrectifDepuisNote(note: NoteEntite) {

        const ref = this.dialogService.open(DetailEntretienCorrectifComponent, {
            data: {
                entretienCorrectif: {
                    entretienCorrectifId: 0,
                    item: {
                        itemId: this.bonTravail.item.itemId
                    },
                    description: note.description,
                    employe: this.profilService.ProfilCourant()
                }
            },
            header: `Ajouter un entretien correctif`,
            width: '75%'
        }).onClose.subscribe((res: EntretienCorrectifEntite) => {

            if (res) {


                this.bonTravail.etapes.push({
                    idTemporaire: this.newGuid(),
                    etapeBonTravailId: 0,
                    entretienCorrectif: res,
                    categorie: res.categorie,
                    description: res.description,
                    indAFaire: true,
                    indComplete: false,
                    typeEtapeBonTravail: this.sdb.TypeEtapeBonTravail(ETypeEtapeBonTravail.EntretienCorrectif)
                });

                this.initialiserNoEtapes();

                this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Entretien correctif ajouté. Vous devez sauvegarder le bon de travail pour appliquer les modifications.' });
            }

        });
    }

    copierMecanicien(etape: EtapeBonTravailEntite) {
        if (etape.mecanicien) {
            var employeId = etape.mecanicien.employeId;

            if (this.selectionMultipleEtapes && this.selectionMultipleEtapes.length > 0) {
                this.selectionMultipleEtapes.forEach(et => {
                    et.mecanicien = this.employes.find(x => x.employeId == employeId);
                });
            } else {
                this.bonTravail.etapes.forEach(et => {
                    et.mecanicien = this.employes.find(x => x.employeId == employeId);
                });
            }

        }
    }

    get EStatuts() {
        return EStatuts;
    }

    get ETypeLienDocument() {
        return ETypeLienDocument;
    }

    get CloseEvent() {
        return CloseEvent;
    }
}
