import { Component, OnInit, OnDestroy, Optional } from "@angular/core"; import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/app.breadcrumb.service";
import { AppMainComponent } from "src/app/app.main.component";
import { BaseComponent } from "src/app/base/base-component";
import { DonneesBaseService } from "src/app/services/donnees-base-service";
import { NonConformiteService } from "src/app/services/non-conformite-service";
import { ProfilService } from "src/app/services/profil-service";
import { NonConformiteSimpleEntite } from "src/app/entites/non-conformite-simple";
import { ActionCorrectiveEntite } from "src/app/entites/action-corrective";
import { DetailNonConformiteComponent } from "./detail-non-conformite.component";
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";

@Component({
    selector: 'app-ajout-action-corrective',
    templateUrl: './ajout-action-corrective.component.html',
    providers: [MessageService, NonConformiteService]
})
export class AjoutActionCorrectiveComponent extends BaseComponent implements OnInit {
    nonConformites: NonConformiteSimpleEntite[] = [];
    actionCorrective: ActionCorrectiveEntite;
    nonConformiteInputDisabled: boolean = false;
    submitted: boolean = false;

    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute,
        routerService: Router,
        appMain: AppMainComponent, location: Location, 
        private nonConformiteService: NonConformiteService,
        private messageService: MessageService,
        private breadcrumbService: BreadcrumbService,
        private dialogService: DialogService,
        @Optional() private config: DynamicDialogConfig,
        @Optional() private ref: DynamicDialogRef
    ) {
        super(sdb, profilService, route, routerService, appMain);
        this.breadcrumbService.setItems([
            { label: 'Action Corrective', routerLink: '/assurancequalite/actioncorrective' },
            { label: 'Ajouter' }
          ]);
    }

    ngOnInit(): void {
        this.actionCorrective = {
            actionCorrectiveId: 0,
            statut: null,
            numero: '',
            demande: '',
            dateCible: null,
            date: new Date(),
            nonConformite: null,
            demandeur: this.profilService.ProfilCourant()
        }

        this.nonConformiteService.Obtenir().then(
            (rep) => {
                this.nonConformites = rep.valeur;
                if (this.config.data != null) {
                    this.actionCorrective.nonConformite = this.nonConformites.find(nc => nc.idNonConformite == this.config.data);
                    this.nonConformiteInputDisabled = true;
                }
                this.CompleterChargement();
            }
        );
    }

    creerActionCorrective() {
        if (!this.valider()) {
            this.submitted = true;
            return;
        }

        this.nonConformiteService.CreerActionCorrective(this.actionCorrective).then(
            (rep) => {
                this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Action corrective créée avec succès' });
                this.ref.close(rep.valeur);
            },
            (err) => {
                this.messageService.add({ severity: 'error', summary: 'Erreur', detail: 'Erreur lors de la création de l\'action corrective' });
            }
        );
    }

    valider() {
        if (this.actionCorrective.demande == null
            || this.actionCorrective.dateCible == null 
            || this.actionCorrective.nonConformite.idNonConformite == null
        ) {
            return false;
        }
        return true;
    }

    voirNonConformite(nonConformite: NonConformiteSimpleEntite) {
        if (this.actionCorrective.nonConformite != null) {
            this.dialogService.open(DetailNonConformiteComponent, {
                data: this.actionCorrective.nonConformite.idNonConformite,
                header: `Non-conformité ${this.actionCorrective.nonConformite.numero}`,
                width: '80%'
            });
        } else {
            this.breadcrumbService.setItems([
                { label: 'Action corrective', routerLink: ['/assurancequalite/actionscorrectives'] },
                { label: 'Ajouter' }
              ]);
            this.messageService.add({ severity: 'warn', summary: 'Attention', detail: 'Aucune non-conformité sélectionnée' });
        }
    }
}