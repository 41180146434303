import { Component, OnInit, ViewChild, Input, ElementRef } from "@angular/core"; import { Location } from "@angular/common";
import { Table } from 'primeng/table';
import { BreadcrumbService } from '../../app.breadcrumb.service';

import { CommandeMin } from '../../entites/commande-min';
import { MenuItem, TreeNode } from 'primeng/api';
import { MessageService, LazyLoadEvent, ConfirmationService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ItemMin } from '../../entites/item-min';
import { ItemService } from '../../services/item-service';
import { Router, ActivatedRoute } from '@angular/router';

import { ToolbarModule } from 'primeng/toolbar';

import { MenubarModule } from 'primeng/menubar';
import { BaseComponent } from '../../base/base-component';
import { Item } from '../../entites/item';
import { Inventaire } from '../../entites/inventaire';
import { NoteEntite } from '../../entites/note';
import { Commande } from '../../entites/commande';
import { CommandeService } from '../../services/commande-service';
import { Emplacement } from '../../entites/emplacement';
import { EmplacementService } from '../../services/emplacement-service';
import { EntreeCommande } from '../../entites/entree-commande';
import { CleValeur } from 'src/app/entites/cleValeur';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { RapportService } from 'src/app/services/rapport-service';
import { SelectionItemComponent, ETypeSelectionItem, ResultatSelectionItem } from 'src/app/controles/selection-item.component';
import { ItemCommandeEntite } from 'src/app/entites/item-commande';
import { ContextMenu } from 'primeng/contextmenu/public_api';
import { Statut } from 'src/app/entites/statut';
import { EStatuts } from 'src/app/entites/enums/statuts';
import { RequeteCreerItemCommande } from 'src/app/services/requetes/requete-creer-item-commande';
import { SaisieQuantiteComponent } from 'src/app/controles/saisie-quantite.component';
import { ProfilService } from 'src/app/services/profil-service';
import { DatePipe } from '@angular/common';
import { InfoChangementStatut } from 'src/app/entites/info-changement-statut';
import { RequeteModifierItemCommande } from 'src/app/services/requetes/requete-modifier-item-commande';
import { AjustementInventaireComponent, ResultatAjustementInventaire } from 'src/app/controles/ajustement-inventaire.component';
import { AppMainComponent } from 'src/app/app.main.component';
import { SelectionTransporteurComponent } from 'src/app/controles/selection-transporteur.component';
import { ConfirmationTransporteurComponent } from 'src/app/controles/confirmation-transporteurs.component';
import { RequisitionService } from 'src/app/services/requisition-service';
import { TransporteurEntite } from 'src/app/entites/transporteur';
import { getRtlScrollAxisType } from '@angular/cdk/platform';
import { ConfirmationCreationRequisitionComponent } from 'src/app/controles/confirmation-creation-requisitions.component';
import { ConsulterFournisseursComponent } from 'src/app/controles/consulter-fournisseurs.component';
import { PrixFournisseurEntite } from 'src/app/entites/prix-fournisseur';
import { CreationCommandeComponent, ResultatCreationBonCommande } from 'src/app/controles/creation-commande.component';
import { ETypesRequisition } from 'src/app/entites/enums/type-requisition';
import { ContexteService } from 'src/app/services/contexte-service';
import { DepartementEntite } from 'src/app/entites/departement';
import { SelectionDepartementComponent } from 'src/app/controles/selection-departement.component';
import { CategorieEntite } from 'src/app/entites/categorie';

@Component({
    templateUrl: './detail-commande.component.html',
    providers: [MessageService, ConfirmationService, DialogService, DatePipe],
    host: {
        '(document:keydown)': 'handleKeyboardEvents($event)'
    }
})
export class DetailCommandeComponent extends BaseComponent implements OnInit {

    emplacementId: number;
    emplacement: Emplacement;

    itemsCommandeComplet: ItemCommandeEntite[];
    itemsCommande: ItemCommandeEntite[];
    selectionItemCommande: ItemCommandeEntite;
    selectionMultipleItemCommande: ItemCommandeEntite[] = [];
    menuContextuelEntrees: MenuItem[];

    
    categories: CategorieEntite[];
    categorie: CategorieEntite[];

    statuts: Statut[] = this.sdb.StatutsCommande();

    departements: DepartementEntite[] = this.sdb.Departements();

    demandeurIdPrecedent?: number = null;

    ref: DynamicDialogRef;

    menu: MenuItem[];

    panneauFiltreAffiche: boolean = false;


    @ViewChild('dt') table: Table;
    @ViewChild('menuContextuelItemCommande') menuContextuelItemCommande: ContextMenu;

    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute,
        appMain: AppMainComponent, location: Location, 
        private breadcrumbService: BreadcrumbService,
        private CommandeService: CommandeService,
        private EmplacementService: EmplacementService,
        private requisitionService: RequisitionService,
        private ItemsService: ItemService,
        private contexteService: ContexteService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        public dialogService: DialogService,
        public rapportService: RapportService,
        public routerService: Router,
        public datepipe: DatePipe) {

        super(sdb, profilService, route, routerService, appMain);

        this.breadcrumbService.setItems([
            { label: 'Commande', routerLink: '/commandes' },
            { label: 'Détails' }
        ]);

        this.route.params.subscribe(params => {

            this.emplacementId = params.id as number;
        });



    }

    handleKeyboardEvents(event: KeyboardEvent) {
        // if (event.key == '+' && !this.estFenetreAjoutItemActive) {
        //     this.surAjoutItem();
        // }
    }

    ngOnInit() {

        this.verifierAccesPage('commande_detail');


        this.DemarrerChargement();

        this.categories = [...this.sdb.Categories()];
        this.categories.push({
            categorieId: 5,
            nom: 'Divers',
            description: 'Divers',
            estFacturable: false,
            estSuivi: false,
            estUnique: false,
            attributs: null
        });

        this.CommandeService.ObtenirCommande(this.emplacementId).then(x => {


            this.itemsCommandeComplet = x.valeur.itemsCommande;
            this.emplacement = x.valeur.emplacement;

            this.itemsCommandeComplet.forEach(ic => {
                
                if (ic.employe) {
                    ic.employe.prenomNom = ic.employe.prenom + ' ' + ic.employe.nom;
                }
                if (ic.demandeur) {
                    ic.demandeur.prenomNom = ic.demandeur.prenom + ' ' + ic.demandeur.nom;
                }
            });

            this.filtrer();

            this.CompleterChargement(`Commande - ${this.emplacement.nom} (${this.emplacement.code})`);
        });

        this.initialiserMenu();

        if (this.profilService.Profil().roles.filter(x => x.nom == 'SIHC_ContremaitreMecanique').length > 0) {
            this.colonnesDisponibles = [
                { champ: 'categorie', entete: 'Catégorie', afficheParDefaut: false },
                { champ: 'marque', entete: 'Marque', afficheParDefaut: true },
                { champ: 'modele', entete: 'Modèle', afficheParDefaut: true },
                { champ: 'inventaire', entete: 'Inventaire', afficheParDefaut: true },
                { champ: 'demandeur', entete: 'Demandeur', afficheParDefaut: true },
                { champ: 'dateDemande', entete: 'Date de demande', afficheParDefaut: true },
                { champ: 'dateRequis', entete: 'Date requise', afficheParDefaut: true },
                { champ: 'expediteur', entete: 'Expéditeur', afficheParDefaut: true },
                { champ: 'camion', entete: 'Camion', afficheParDefaut: false },
                { champ: 'remorque', entete: 'Remorque', afficheParDefaut: false },
                { champ: 'chauffeur', entete: 'Chauffeur', afficheParDefaut: false },
                { champ: 'unite', entete: 'Unité', afficheParDefaut: false },
                { champ: 'codeProjet', entete: 'Code Projet', afficheParDefaut: false },
                { champ: 'codeActivite', entete: 'Code Activité', afficheParDefaut: false },
                { champ: 'codeCategorie', entete: 'Code Catégorie', afficheParDefaut: false },
                { champ: 'departement', entete: 'Département', afficheParDefaut: true },
                { champ: 'note', entete: 'Note', afficheParDefaut: true }
            ];
        } else {
            this.colonnesDisponibles = [
                { champ: 'categorie', entete: 'Catégorie', afficheParDefaut: false },
                { champ: 'marque', entete: 'Marque', afficheParDefaut: false },
                { champ: 'modele', entete: 'Modèle', afficheParDefaut: false },
                { champ: 'inventaire', entete: 'Inventaire', afficheParDefaut: true },
                { champ: 'demandeur', entete: 'Demandeur', afficheParDefaut: true },
                { champ: 'dateDemande', entete: 'Date de demande', afficheParDefaut: true },
                { champ: 'dateRequis', entete: 'Date requise', afficheParDefaut: true },
                { champ: 'expediteur', entete: 'Expéditeur', afficheParDefaut: true },
                { champ: 'camion', entete: 'Camion', afficheParDefaut: false },
                { champ: 'remorque', entete: 'Remorque', afficheParDefaut: false },
                { champ: 'chauffeur', entete: 'Chauffeur', afficheParDefaut: false },
                { champ: 'unite', entete: 'Unité', afficheParDefaut: false },
                { champ: 'codeProjet', entete: 'Code Projet', afficheParDefaut: false },
                { champ: 'codeActivite', entete: 'Code Activité', afficheParDefaut: false },
                { champ: 'codeCategorie', entete: 'Code Catégorie', afficheParDefaut: false },
                { champ: 'departement', entete: 'Département', afficheParDefaut: true },
                { champ: 'note', entete: 'Note', afficheParDefaut: true }
            ];
        }
        this.colonnesSelectionnees = this.colonnesDisponibles.filter(x => x.afficheParDefaut);
    }

    filtrer() {
        this.Bloquer();
           this.itemsCommande = this.itemsCommandeComplet;
           if (this.categorie && this.categorie.length > 0 ) {
                var ids = this.categorie.map(x => x.categorieId);
               this.itemsCommande = this.itemsCommande.filter(x => ids.includes(x.item?.categorie?.categorieId));
           }
        
        this.Debloquer();
    }

    initialiserMenu() {
        this.menu = [
            {
                label: 'Retour', icon: 'pi pi-fw pi-angle-double-left',
                command: () => this.routerService.navigateByUrl('commandes')
            },
            {
                label: 'Imprimer', icon: 'pi pi-fw pi-print', command: () => this.imprimer()
            },
            {
                label: 'Pré-commande',
                icon: 'pi pi-fw pi-eye',
                visible: this.estAutorise('approbation_precommande_detail'),
                command: () => this.routerService.navigateByUrl('approbationprecommande/' + this.emplacementId)
            },
            {
                label: 'Chargement',
                icon: 'pi pi-fw pi-arrow-circle-up',
                visible: this.estAutorise('commande_statut_charge'),
                command: () => this.routerService.navigateByUrl('chargement/' + this.emplacementId)
            },
            {
                label: 'Réquisition',
                icon: 'pi pi-fw pi-plus',
                visible: this.estAutorise('requisition_creer'),
                items: [
                    {
                        label: 'Simple', icon: 'pi pi-fw pi-plus',
                        command: () => this.creerRequisition(event)
                    },
                    {
                        label: 'Selon transporteurs', icon: 'pi pi-fw pi-list',
                        command: () => this.creerRequisitionSelonTransporteurs(event)
                    }
                ]
            },
            {
                label: 'Actions multiples', icon: 'pi pi-fw pi-bars', disabled: this.selectionMultipleItemCommande.length == 0,
                items: [
                    {
                        label: 'En attente',
                        icon: 'pi pi-fw pi-backward',
                        visible: this.estAutorise('commande_statut_enattente'),
                        command: () => this.changerStatutLot(5),
                        disabled: this.selectionMultipleItemCommande.filter(x => x.statut.statutId != 9 && x.statut.statutId != 27).length != 0
                    },
                    {
                        label: 'Prêt',
                        icon: 'pi pi-fw pi-forward',
                        visible: this.estAutorise('commande_statut_pret'),
                        command: () => this.changerStatutLot(9),
                        disabled: this.selectionMultipleItemCommande.filter(x => x.statut.statutId != 5 && x.statut.statutId != 11 && x.statut.statutId != 27).length != 0
                    },
                    {
                        label: 'Chargé',
                        icon: 'pi pi-fw pi-forward',
                        visible: this.estAutorise('commande_statut_charge'),
                        command: () => this.changerStatutLot(11),
                        disabled: this.selectionMultipleItemCommande.filter(x => x.statut.statutId != 9).length != 0
                    },
                    {
                        separator: true, visible: this.estAutorise('commande_supprimer_item')
                    },
                    {
                        label: 'Supprimer',
                        icon: 'pi pi-fw pi-times',
                        visible: this.estAutorise('commande_supprimer_item'),
                        command: () => this.supprimerItems(this.selectionMultipleItemCommande)
                    },
                ]
            },
        ];
    }

    afficherPanneauFiltres() {
        this.panneauFiltreAffiche = !this.panneauFiltreAffiche;
    }

    imprimer() {
        this.messageService.add({ severity: 'info', summary: 'Demande de rapport', detail: `Votre demande de rapport a été envoyée.` });

        /* var parametres = {
            parametresCommuns: {
                demandeur: `${this.profilService.ProfilCourant().prenom} ${this.profilService.ProfilCourant().nom}`,
                detail: `${this.emplacement.nom} (${this.emplacement.code})`
            },
            emplacement: `${this.emplacement.nom} (${this.emplacement.code})`,
            itemsCommande: []
        };

        this.itemsCommande.forEach(ic => {
            parametres.itemsCommande.push({
                date: this.datepipe.transform(ic.dateDemande, 'yyyy-MM-dd'),
                qteDemande: ic.quantiteDemande,
                qte: `${ic.quantite}/${ic.quantiteDemande}`,
                statut: ic.statut?.nom,
                categorie: ic.item?.categorie?.nom,
                numero: ic.item?.numero,
                description: ic.item?.description,
                qteInventaire: ic.item?.quantite,
                demandeur: ic.demandeur ? `${ic.demandeur.prenom} ${ic.demandeur.nom}` : '',
                expediteur: ic.expediteur
            })
        }); */

        var itemIds: number[] = [];

        if (this.table.filteredValue) {
            itemIds = this.table.filteredValue.map(x => x.itemCommandeId);
        } else {
            itemIds = this.itemsCommande.map(x => x.itemCommandeId);
        }

        this.rapportService.ProduireRapportCommande({
            emplacementId: this.emplacement.emplacementId,
            itemIds: itemIds
        }).then(x => {
            if (x) {
                this.messageService.add({ severity: 'success', summary: 'Succès', detail: `Votre rapport a été généré.` });
            } else {
                this.messageService.add({ severity: 'error', summary: 'Erreur', detail: `Une erreur est survenue pendant la génération de votre rapport.` });
            }
        });
    }

    estFenetreAjoutItemActive: boolean = false;
    surAjoutItem() {

        this.estFenetreAjoutItemActive = true;
        const ref = this.dialogService.open(SelectionItemComponent, {
            data: {
                type: ETypeSelectionItem.Commande,
                demandeurId: this.demandeurIdPrecedent,
                emplacementIdVerificationTransfertExpress: 10000
            },
            header: 'Sélection d\'un item',
            width: '95%'
        }).onClose.subscribe((res: ResultatSelectionItem) => {
            this.estFenetreAjoutItemActive = false;
            if (res) {
                var itemCommande: ItemCommandeEntite = {
                    itemCommandeId: 0,
                    emplacement: { emplacementId: this.emplacement.emplacementId },
                    item: res.item,
                    statut: this.sdb.Statut(EStatuts.EnAttente),
                    employe: { employeId: this.profilService.ProfilCourant().employeId },
                    dateDemande: res.dateDemande,
                    dateRequis: res.dateRequis,
                    quantiteDemande: res.quantiteDemande,
                    quantite: res.quantiteDemande,
                    expediteur: res.expediteur,
                    demandeur: res.demandeur,
                    unite: res.unite,
                    indPrecommande: false
                }

                this.CommandeService.CreerItemCommande({
                    itemCommande: itemCommande
                }).then(res => {
                    if (res.estUnSucces) {

                        itemCommande.itemCommandeId = res.valeur.itemCommande.itemCommandeId;

                        var itemExistant = this.itemsCommande.find(x => x.itemCommandeId == itemCommande.itemCommandeId);

                        if (itemExistant) {

                            itemExistant.quantite = res.valeur.itemCommande.quantite;
                            itemExistant.quantiteDemande = res.valeur.itemCommande.quantiteDemande;

                            var itemsAAJuster = this.itemsCommande.filter(x => x.item.itemId == res.valeur.itemCommande.item.itemId &&
                                x.demandeur.employeId == res.valeur.itemCommande.demandeur.employeId);

                            itemsAAJuster.forEach(x => {
                                x.quantiteDemande = res.valeur.itemCommande.quantiteDemande;
                            });

                        } else {

                            this.itemsCommande.push(itemCommande);

                        }

                        this.updateVisibility();
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: `L'item '${itemCommande.item.description} (${itemCommande.item.numero})' a été ajouté.` });

                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                });

                this.demandeurIdPrecedent = res.demandeur?.employeId;
            }


        });

    }

    surChangementSelection() {
        this.initialiserMenu();
        //this.menu[this.menu.length - 1].items.forEach(x => x.disabled = this.selectionMultipleItemCommande.length == 0);
    }

    menuVisible: boolean = true;
    rafraichirMenu() {
        this.menuVisible = false;
        setTimeout(() => this.menuVisible = true, 0);
    }

    visible: boolean = true;
    updateVisibility(): void {
        this.visible = false;
        setTimeout(() => this.visible = true, 0);
    }

    supprimerItems(items: ItemCommandeEntite[]) {
        var ids = items.map(b => b.itemCommandeId);
        this.confirmationService.confirm({
            message: items.length == 1 ? 'Voulez-vous vraiment supprimer l\'item sélectionné ?' : 'Voulez-vous vraiment supprimer les items sélectionnés ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.CommandeService.SupprimerItemsCommande(ids).then(res => {
                    if (res.estUnSucces) {
                        this.itemsCommande = this.itemsCommande.filter((ic: ItemCommandeEntite) => !ids.includes(ic.itemCommandeId))
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Items supprimés.' });
                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                });

            }
        });
    }

    surMenuContextuelItem(event, itemCommande: ItemCommandeEntite) {
        this.selectionItemCommande = itemCommande;

        this.menuContextuelEntrees = [
            {
                label: 'Voir l\'item', icon: 'pi pi-fw pi-search',
                visible: this.estAutorise('item_detail'),
                command: () => this.routerService.navigateByUrl(`item/${itemCommande.item.itemId}`)
            },
            {
                label: 'Changer la quantité', icon: 'pi pi-fw pi-sort',
                visible: this.estAutorise('commande_modifier_item'),
                command: () => this.modifierQuantite(itemCommande)
            },
            { separator: true, visible: this.estAutorise('commande_statut_enattente') || this.estAutorise('commande_statut_pret') },
            {
                label: 'En attente', icon: 'pi pi-fw pi-forward',
                visible: this.estAutorise('commande_statut_enattente') && itemCommande.statut.statutId == 27,
                command: () => this.changerStatut(itemCommande.itemCommandeId, itemCommande.quantite, 5, false)
            },
            {
                label: 'En attente (partiel)', icon: 'pi pi-fw pi-caret-right',
                visible: this.estAutorise('commande_statut_enattente') && itemCommande.statut.statutId == 27,
                disabled: itemCommande.quantite == 1,
                command: () => this.changerStatut(itemCommande.itemCommandeId, itemCommande.quantite, 5, true)
            },
            {
                label: 'En attente', icon: 'pi pi-fw pi-backward',
                visible: this.estAutorise('commande_statut_enattente') && itemCommande.statut.statutId == 9,
                command: () => this.changerStatut(itemCommande.itemCommandeId, itemCommande.quantite, 5, false)
            },
            {
                label: 'En attente (partiel)', icon: 'pi pi-fw pi-caret-left',
                visible: this.estAutorise('commande_statut_enattente') && itemCommande.statut.statutId == 9,
                disabled: itemCommande.quantite == 1,
                command: () => this.changerStatut(itemCommande.itemCommandeId, itemCommande.quantite, 5, true)
            },
            {
                label: 'Prêt', icon: 'pi pi-fw pi-forward',
                visible: this.estAutorise('commande_statut_pret') && (itemCommande.statut.statutId == 5 || itemCommande.statut.statutId == 27),
                command: () => this.changerStatut(itemCommande.itemCommandeId, itemCommande.quantite, 9, false)
            },
            {
                label: 'Prêt (partiel)', icon: 'pi pi-fw pi-caret-right',
                visible: this.estAutorise('commande_statut_pret') && (itemCommande.statut.statutId == 5 || itemCommande.statut.statutId == 27),
                disabled: itemCommande.quantite == 1,
                command: () => this.changerStatut(itemCommande.itemCommandeId, itemCommande.quantite, 9, true)
            },
            {
                label: 'Prêt', icon: 'pi pi-fw pi-backward',
                visible: this.estAutorise('commande_statut_pret') && itemCommande.statut.statutId == 11,
                command: () => this.changerStatut(itemCommande.itemCommandeId, itemCommande.quantite, 9, false)
            },
            {
                label: 'Prêt (partiel)', icon: 'pi pi-fw pi-caret-left',
                visible: this.estAutorise('commande_statut_pret') && itemCommande.statut.statutId == 11,
                disabled: itemCommande.quantite == 1,
                command: () => this.changerStatut(itemCommande.itemCommandeId, itemCommande.quantite, 9, true)
            },
            {
                label: 'Chargé', icon: 'pi pi-fw pi-forward',
                visible: this.estAutorise('commande_statut_charge') && itemCommande.statut.statutId == 9,
                command: () => this.changerStatut(itemCommande.itemCommandeId, itemCommande.quantite, 11, false)
            },
            {
                label: 'Chargé (partiel)', icon: 'pi pi-fw pi-caret-right',
                visible: this.estAutorise('commande_statut_charge') && itemCommande.statut.statutId == 9,
                disabled: itemCommande.quantite == 1,
                command: () => this.changerStatut(itemCommande.itemCommandeId, itemCommande.quantite, 11, true)
            },
            { separator: true, visible: this.estAutorise('bdc_creer') },
            {
                label: 'Commander', icon: 'pi pi-fw pi-shopping-cart',
                visible: this.estAutorise('bdc_creer'),
                disabled: !itemCommande.item.categorie.estSuivi,
                command: () => this.commander(itemCommande)
            },
            { separator: true, visible: this.estAutorise('commande_modifier_item') },
            {
                label: 'Assigner',
                icon: 'pi pi-fw pi-forward',
                visible: this.estAutorise('commande_modifier_item'),
                command: x => this.assigner(itemCommande)
            },
            { separator: true, visible: this.estAutorise('item_ajuster_inventaire') },
            {
                label: 'Ajuster l\'inventaire', icon: 'pi pi-fw pi-sliders-h',
                visible: this.estAutorise('item_ajuster_inventaire'),
                disabled: !itemCommande.item.categorie.estSuivi,
                command: () => this.ajustementInventaire(itemCommande)
            }
        ];


        this.menuContextuelItemCommande.toggle(event);
    }

    modifierQuantite(itemCommande: ItemCommandeEntite) {
        const ref = this.dialogService.open(SaisieQuantiteComponent, {
            data: {
                quantite: itemCommande.quantiteDemande,
                min: 1
            },
            header: 'Saisir la quantité',
            width: '50%'
        }).onClose.subscribe((res: number) => {
            if (res) {
                this.CommandeService.ModifierqteItemCommande({
                    employe: this.profilService.ProfilCourant(),
                    emplacement: this.emplacement,
                    itemsCommande: [{
                        itemCommandeId: itemCommande.itemCommandeId,
                        quantite: res
                    }]
                }).then(res => {
                    if (res.estUnSucces) {

                        res.valeur.itemCommandeModifies.forEach(x => {
                            var itemCommandeAModifier = this.itemsCommande.find(ic => ic.itemCommandeId == x.itemCommandeId);
                            itemCommandeAModifier.quantiteDemande = x.quantiteDemande;
                            itemCommandeAModifier.quantite = x.quantite;
                        });

                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: `Statut modifié` });

                        this.initialiserMenu();

                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                }).catch(() => {
                    this.routerService.navigateByUrl('error');
                });
            }

        });
    }

    changerStatut(itemCommandeId: number, quantite: number, statutId: number, partiel: boolean) {

        if (partiel) {

            const ref = this.dialogService.open(SaisieQuantiteComponent, {
                data: {
                    quantite: quantite,
                    min: 1,
                    max: quantite
                },
                header: 'Saisir la quantité',
                width: '50%'
            }).onClose.subscribe((res: number) => {
                if (res) {
                    this.confirmerChangerStatut([{
                        itemCommandeId: itemCommandeId,
                        quantite: res,
                        statutId: statutId,
                        indPrecommande: false
                    }]);
                }

            });

        } else {
            this.confirmerChangerStatut([{
                itemCommandeId: itemCommandeId,
                quantite: quantite,
                statutId: statutId,
                indPrecommande: false
            }]);
        }
    }

    changerStatutLot(statutId: number) {
        if (this.selectionMultipleItemCommande.length > 0) {
            var infos: InfoChangementStatut[] = [];

            this.selectionMultipleItemCommande.forEach(ic => {
                infos.push({
                    itemCommandeId: ic.itemCommandeId,
                    quantite: ic.quantite,
                    statutId: statutId,
                    indPrecommande: false
                });
            });

            this.confirmerChangerStatut(infos);
        }
    }

    confirmerChangerStatut(info: InfoChangementStatut[]) {

        var p1 = new Promise<TransporteurEntite>((resolve, reject) => {
            if (info[0].statutId == EStatuts.Charge) {
                const ref = this.dialogService.open(SelectionTransporteurComponent, {
                    data: {
                        emplacementId: this.emplacement.emplacementId
                    },
                    header: 'Sélectionner le transporteur',
                    width: '50%'
                }).onClose.subscribe((res: TransporteurEntite) => {
                    if (res) {
                        resolve(res);
                    } else {
                        reject();
                    }
                });
            } else {
                resolve(null);
            }
        });

        p1.then(r => {
            var requete: RequeteModifierItemCommande = {
                employe: this.profilService.ProfilCourant(),
                emplacement: this.emplacement,
                itemsCommande: []
            };

            info.forEach(x => {
                requete.itemsCommande.push({
                    itemCommandeId: x.itemCommandeId,
                    quantite: x.quantite,
                    statutId: x.statutId,
                    camion: r?.camion,
                    remorque: r?.remorque,
                    chauffeur: r?.chauffeur,
                    indPrecommande: false
                });
            });

            this.CommandeService.ModifierItemCommande(requete).then(res => {
                if (res.estUnSucces) {

                    res.valeur.itemCommandeSupprimes.forEach(x => {
                        this.itemsCommande = this.itemsCommande.filter(ic => ic.itemCommandeId != x.itemCommandeId);
                    });

                    res.valeur.itemCommandeAjoutes.forEach(x => {
                        this.itemsCommande.push(x);
                    });

                    res.valeur.itemCommandeModifies.forEach(x => {
                        var itemCommandeAModifier = this.itemsCommande.find(ic => ic.itemCommandeId == x.itemCommandeId);
                        itemCommandeAModifier.statut = this.sdb.Statut(x.statut.statutId);
                        itemCommandeAModifier.quantite = x.quantite;
                        itemCommandeAModifier.transporteur = x.transporteur;
                        itemCommandeAModifier.camion = x.camion;
                        itemCommandeAModifier.remorque = x.remorque;
                        itemCommandeAModifier.chauffeur = x.chauffeur;
                    });

                    this.messageService.add({ severity: 'success', summary: 'Succès', detail: `Statut modifié` });

                    this.initialiserMenu();

                } else {
                    res.messages.forEach(x => {
                        this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                    });
                }
            }).catch(() => {
                this.routerService.navigateByUrl('error');
            });
        }, r => { });

    }

    assigner(item: ItemCommandeEntite) {

        const ref = this.dialogService.open(SelectionDepartementComponent, {
            header: 'Sélectionner un département',
            width: '50%'
        }).onClose.subscribe((resSelectionDepartement: DepartementEntite) => {
            if (resSelectionDepartement) {
                this.CommandeService.ModifierAssignationItemCommande({
                    employe: this.profilService.ProfilCourant(),
                    emplacement: this.emplacement,
                    itemsCommande: [{
                        itemCommandeId: item.itemCommandeId,
                        quantite: item.quantite,
                        statutId: item.statut.statutId,
                        transporteur: item.transporteur,
                        camion: item.camion,
                        remorque: item.remorque,
                        chauffeur: item.chauffeur,
                        departementId: resSelectionDepartement.departementId,
                        indPrecommande: item.indPrecommande
                    }]
                }).then(res => {
                    if (res.estUnSucces) {

                       item.departement = this.sdb.Departement(resSelectionDepartement.departementId);

                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: `Item réassigné` });

                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                }).catch(() => {
                    this.routerService.navigateByUrl('error');
                });
            }
        });
    }

    commander(item: ItemCommandeEntite) {

        const ref = this.dialogService.open(ConsulterFournisseursComponent, {
            data: {
                itemId: item.item.itemId,
                afficherSelection: true,
                afficherCommander: false
            },
            header: 'Sélectionner un fournisseur',
            width: '50%'
        }).onClose.subscribe((res: PrixFournisseurEntite) => {
            if (res) {

                var codeProjetId = item.codeProjet ? item.codeProjet.codeProjetId : this.emplacement.codeProjet?.codeProjetId;

                const ref2 = this.dialogService.open(CreationCommandeComponent, {
                    data: {
                        itemId: item.item.itemId,
                        numeroItem: item.item.numero,
                        descriptionItem: item.item.description,
                        fournisseurId: res.fournisseur.fournisseurId,
                        nomFournisseur: res.fournisseur.nom,
                        numeroFournisseur: res.fournisseur.numero,
                        numeroItemFournisseur: res.numeroFournisseur,
                        prix: res.prix,
                        selectionCodeProjet: codeProjetId,
                        selectionCodeActivite: item.codeActivite?.codeActiviteId

                    },
                    header: 'Création d\'un bon de commande',
                    width: '60%'
                }).onClose.subscribe((res2: ResultatCreationBonCommande) => {
                    if (res2) {
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'L\'item a été ajouté au bon de commande.' });
                        /* if (res2.redirection) {
                            this.routerService.navigateByUrl('achat/' + res2.bonCommandeId);
                        } else {
                            this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'L\'item a été ajouté au bon de commande.' });
                        } */
                    }
                });
            }
        });
    }

    creerRequisition(event: Event) {

        this.contexteService.Ajouter('commande.emplacementId', this.emplacementId.toString());

        this.routerService.navigateByUrl('requisition/nouveau');

    }

    creerRequisitionSelonTransporteurs(event: Event) {
        const ref = this.dialogService.open(ConfirmationTransporteurComponent, {
            data: {
                emplacementId: this.emplacement.emplacementId
            },
            header: 'Confirmer réquisition(s)',
            width: '50%'
        }).onClose.subscribe((res: TransporteurEntite[]) => {
            if (res) {
                this.Bloquer();
                this.requisitionService.CreerSelonTransporteurs({
                    emplacementId: this.emplacement.emplacementId,
                    transporteurs: res
                }).then(resCreation => {
                    if (resCreation.estUnSucces) {

                        const ref = this.dialogService.open(ConfirmationCreationRequisitionComponent, {
                            data: {
                                requisitionIds: resCreation.valeur
                            },
                            header: 'Réquisitions créées',
                            width: '50%'
                        }).onClose.subscribe((r) => {

                            this.CommandeService.ObtenirCommande(this.emplacementId).then(x => {
                                this.itemsCommande = x.valeur.itemsCommande;
                            });

                        });


                        this.itemsCommande = this.itemsCommande.filter((ic: ItemCommandeEntite) => !resCreation.valeur.includes(ic.itemCommandeId))

                        this.Debloquer();

                        //this.messageService.add({ severity: 'success', summary: 'Succès', detail: `Réquisitions créées` });
                    } else {
                        resCreation.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description, sticky: true });
                        });
                    }
                })
            }
        });
    }

    ajustementInventaire(itemCommande: ItemCommandeEntite) {
        const ref = this.dialogService.open(AjustementInventaireComponent, {
            data: {
                itemId: itemCommande.item.itemId,
                emplacementId: 10000, // TODO: Gérer le multi entrepot.
                numero: itemCommande.item.numero,
                description: itemCommande.item.description,
                emplacement: `TODO`,
                quantiteCourante: itemCommande.item.quantite
            },
            header: 'Ajustement d\'inventaire',
            width: '50%'
        }).onClose.subscribe((res: ResultatAjustementInventaire) => {
            if (res) {
                itemCommande.item.quantite = res.nouvelleQuantite;
                this.messageService.add({ severity: 'success', summary: 'Succès', detail: `L'inventaire de l'item '${itemCommande.item.description} (${itemCommande.item.numero})' a été ajusté.` });
            }
        });
    }
}
