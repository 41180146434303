<div class="card">
	<div class="grid">
		<div class="flex flex-column gap-2 col-12 md:col-6 p-lg-6 fluid">
			<label for="description">Description<span class="requis">*</span></label>
			<input #descriptionItem type="text" [disabled]="this.item.item.numero !== 'DIVERS'" pInputText id="description"
			       [(ngModel)]="this.item.item.description"
			       autocomplete="off" autofocus/>
			<small class="p-error" *ngIf="submitted && !this.item.item.description">La description
				est
				obligatoire.</small>
		</div>


	</div>

	<div class="grid" *ngIf="this.item.item">
		<div class="flex flex-column gap-2 col-12 lg:col-3 fluid">
			<label for="quantity">Quantite<span class="requis">*</span></label>
			<p-inputNumber #champQuantite [(ngModel)]="this.item.quantiteDemande">
			</p-inputNumber>
			<small class="p-error" *ngIf="submitted && !this.item.quantiteDemande">La quantité est obligatoire.</small>
		</div>

		<div class="flex flex-column gap-2 col-12 md:col-6 lg:col-3 fluid">
			<label for="demandeur">Demandeur<span class="requis">*</span></label>
			<p-dropdown #champDemandeur [options]="listeEmployes" [(ngModel)]="selectionDemandeur" optionLabel="nom"
			            [showClear]="true" (onHide)="surFermetureDemandeur()" filter="true" filterBy="prenom,nom"
			            appendTo="body" placeholder="Sélectionner un demandeur...">
				<ng-template pTemplate="selectedItem">
					<div *ngIf="selectionDemandeur">
						<div>{{ selectionDemandeur.prenom }} {{ selectionDemandeur.nom }}</div>
					</div>
				</ng-template>
				<ng-template let-employe pTemplate="item">
					<div>
						<div>{{ employe.prenom }} {{ employe.nom }}</div>
					</div>
				</ng-template>
			</p-dropdown>
			<small class="p-error" *ngIf="submitted && !selectionDemandeur">Le demandeur est obligatoire.</small>
			<!-- <input #champDemandeur type="text" pInputText id="demandeur" [(ngModel)]="demandeur" autocomplete="off"
					 /> -->
		</div>
		<div class="flex flex-column gap-2 col-12 md:col-6 lg:col-3 fluid">
			<label for="dateRequis">Date requise<span class="requis">*</span></label>
			<p-calendar appendTo="body" showIcon=true [(ngModel)]="dateRequis" dateFormat="yy-mm-dd" [yearNavigator]="true"
			            [monthNavigator]="true" yearRange="2000:2040"></p-calendar>
			<small class="p-error" *ngIf="submitted && !dateRequis">La date requise est obligatoire.</small>
		</div>
	</div>

	<div class="fluid formgrid grid">
		<div class="flex flex-column gap-2 col-12 md:col-6 lg:col-3">
			<label for="codeProjet">Code projet<span class="requis">*</span></label>
			<p-dropdown #champCodeProjet [disabled]="true" [options]="listeCodeProjet" [(ngModel)]="selectionCodeProjet" optionLabel="code"
			            [showClear]="true" (onHide)="surFermetureCodeProjet()" filter="true" filterBy="code,nom"
			            (onChange)="surChangementCodeProjet()"
			            appendTo="body" placeholder="Sélectionner un code de projet...">
				<ng-template pTemplate="selectedItem">
					<div *ngIf="selectionCodeProjet">
						<div>{{ selectionCodeProjet.code }}</div>
					</div>
				</ng-template>
				<ng-template let-code pTemplate="item">
					<div>
						<div>{{ code.code }} - {{ code.nom }}</div>
					</div>
				</ng-template>
			</p-dropdown>
			<small class="p-error" *ngIf="submitted && !selectionCodeProjet">Le code de projet est obligatoire.</small>
		</div>
		<div class="flex flex-column gap-2 col-12 md:col-6 lg:col-3">
			<label for="codeActivite">Code d'activité<span *ngIf="estCodeCategorieObligatoire" class="requis">*</span></label>
			<p-dropdown #champCodeActivite [options]="listeCodeActivite" [(ngModel)]="selectionCodeActivite"
			            optionLabel="code"
			            [disabled]="!selectionCodeProjet"
			            (onChange)="surChangementCodeActivite()"
			            [showClear]="true" (onHide)="surFermetureCodeActivite()" filter="true" filterBy="code,nom"
			            appendTo="body" placeholder="Sélectionner un code d'activité...">
				<ng-template pTemplate="selectedItem">
					<div *ngIf="selectionCodeActivite">
						<div>{{ selectionCodeActivite.code }}</div>
					</div>
				</ng-template>
				<ng-template let-code pTemplate="item">
					<div>
						<div>{{ code.code }} - {{ code.nom }}</div>
					</div>
				</ng-template>
			</p-dropdown>
			<small class="p-error" *ngIf="submitted && estCodeActiviteObligatoire && !selectionCodeActivite">Le code
				d'activité est obligatoire.</small>
		</div>
		<div class="flex flex-column gap-2 col-12 md:col-6 lg:col-3">
			<label for="codeCategorie">Catégorie de coût<span *ngIf="estCodeCategorieObligatoire"
			                                                  class="requis">*</span></label>
			<p-dropdown #champCodeCategorie [options]="listeCodeCategorie" [(ngModel)]="selectionCodeCategorie"
			            optionLabel="code"
			            [disabled]="!selectionCodeProjet || codeCategorieBloque"
			            [showClear]="true" (onHide)="surFermetureCodeCategorie()" filter="true" filterBy="code,nom"
			            appendTo="body" placeholder="Sélectionner une catégorie de coût...">
				<ng-template pTemplate="selectedItem">
					<div *ngIf="selectionCodeCategorie">
						<div>{{ selectionCodeCategorie.code }}</div>
					</div>
				</ng-template>
				<ng-template let-code pTemplate="item">
					<div>
						<div>{{ code.code }} - {{ code.nom }}</div>
					</div>
				</ng-template>
			</p-dropdown>
			<small class="p-error" *ngIf="submitted && estCodeCategorieObligatoire && !selectionCodeCategorie">La catégorie de
				coût est obligatoire.</small>
		</div>
		<div class="flex flex-column gap-2 col-12 md:col-6 lg:col-3">
			<label for="codeCategorie">Équipement<span
							*ngIf="selectionCodeProjet?.code == 'ENTRET' && selectionCodeActivite?.code == '280'"
							class="requis">*</span></label>
			<p-dropdown #champCodeEquipement [options]="listeCodeEquipement" [(ngModel)]="selectionCodeEquipement"
			            optionLabel="code"
			            [disabled]="codeEquipementBloque"
			            [showClear]="true" filter="true" filterBy="code,nom"
			            (onChange)="surChangementCodeEquipement()"
			            appendTo="body" placeholder="Sélectionner un équipement..">
				<ng-template pTemplate="selectedItem">
					<div *ngIf="selectionCodeEquipement">
						<div>{{ selectionCodeEquipement.code }}</div>
					</div>
				</ng-template>
				<ng-template let-code pTemplate="item">
					<div>
						<div>{{ code.code }} - {{ code.nom }}</div>
					</div>
				</ng-template>
			</p-dropdown>
			<small class="p-error"
			       *ngIf="submitted && selectionCodeProjet?.code == 'ENTRET' && selectionCodeActivite?.code == '280' && !selectionCodeEquipement">La
				catégorie de coût est obligatoire.</small>
		</div>
		<div class="flex flex-column gap-2 col-12 md:col-6 lg:col-3">
			<label for="codeCategorie">Extra</label>
			<p-dropdown #champCodeExtra [options]="listeCodeExtra" [(ngModel)]="selectionCodeExtra" optionLabel="code"
			            [showClear]="true" filter="true" filterBy="code,nom"
			            appendTo="body" placeholder="Sélectionner un extra..">
				<ng-template pTemplate="selectedItem">
					<div *ngIf="selectionCodeExtra">
						<div>{{ selectionCodeExtra.code }}</div>
					</div>
				</ng-template>
				<ng-template let-code pTemplate="item">
					<div>
						<div>{{ code.code }} - {{ code.nom }}</div>
					</div>
				</ng-template>
			</p-dropdown>
		</div>
		<div class="flex flex-column gap-2 col-12 md:col-6 lg:col-3">
			<label for="emplacement">Emplacement</label>
			<p-dropdown #champEmplacement [options]="listeEmplacement" [(ngModel)]="selectionEmplacement" optionLabel="code"
			            [showClear]="true" filter="true" filterBy="code,nom"
			            appendTo="body" placeholder="Sélectionner un emplacement..">
				<ng-template pTemplate="selectedItem">
					<div *ngIf="selectionEmplacement">
						<div>{{ selectionEmplacement.code }}</div>
					</div>
				</ng-template>
				<ng-template let-emplacement pTemplate="item">
					<div>
						<div>{{ emplacement | nomEmplacement }}</div>
					</div>
				</ng-template>
			</p-dropdown>
		</div>
	</div>

    <div class="fluid formgrid grid">
        <div class="flex flex-column gap-2 col-12 p-lg-9">
            <label for="note">Note</label>
            <input #champNote type="text" pInputText id="note" [(ngModel)]="this.item.note"
                   autocomplete="off" />
        </div>

    </div>


	<div class="grid">
		<div class="col-12 text-right ">
			<p-button label="Enregistrer" class="" icon="pi pi-save" iconPos="left" (onClick)="Confirmer()"></p-button>
			<p-button label="Annuler" class="p-button-text ml-2" icon="pi pi-ban" iconPos="left"
			          (onClick)="Annuler()"></p-button>
=======

	<!------------ Precommande Pièces Jointes ------------>

	<div class="p-fluid p-formgrid p-grid">
		<div class="p-field p-col-12">
			<label>Liste des pièces jointes</label>
			<p-table [value]="documents">
				<ng-template pTemplate="header">
					<tr>
						<th class="nomFichier">Nom</th>
						<th class="actions">Actions</th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-fichier>
					<tr>
						<td class="nomFichier">{{ fichier.nom }}</td>
						<td class="actions">
							<p-button label="" icon="pi pi-download" iconPos="left" (onClick)="telechargerDocument(fichier)"></p-button>
							<p-button label="" icon="pi pi-trash" iconPos="left" (onClick)="supprimerDocument(fichier)"></p-button>
						</td>
					</tr>
				</ng-template>
			</p-table>
		</div>
		<div class="p-field p-col-12">
			<label>Ajouter une pièce jointe</label>
			<p-fileUpload #fu name="myfile[]"
			              multiple="multiple"
			              customUpload="true"
			              mode="advanced"
			              maxFileSize="209715200"
			              invalidFileSizeMessageSummary="{0}: Fichier trop volumineux, "
			              invalidFileSizeMessageDetail="la grosseur maximale est {0}."
			              chooseLabel="Ajouter"
			              uploadLabel="Confirmer"
			              cancelLabel="Annuler"
			              (onUpload)="onBasicUpload($event)"
			              (uploadHandler)="onUpload($event)"
			              (onSelect)="onSelect($event)">
			</p-fileUpload>
>>>>>>> 6a3936d5af075d19ce66179cd6eb771406993866
		</div>
	</div>

	<div class="p-grid">
		<div class="p-col-12 p-text-right ">
			<p-button [disabled]="submitted" label="Enregistrer" class="" icon="pi pi-save" iconPos="left" (onClick)="Confirmer()"></p-button>
			<p-button label="Annuler" class="p-button-text p-ml-2" icon="pi pi-ban" iconPos="left"
			          [disabled]="submitted" (onClick)="Annuler()"></p-button>
		</div>
	</div>
</div>
<style>
    .nomFichier {
        width: 80%; /* Ajustez cette valeur selon vos besoins */
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .actions {
        width: 20%; /* Ajustez cette valeur selon vos besoins */
        text-align: right;
    }
</style>