import { Component, OnInit, ViewChild, Input } from "@angular/core"; import { Location } from "@angular/common";
import { Table } from 'primeng/table';
import { BreadcrumbService } from '../../app.breadcrumb.service';

import { RequisitionMin } from '../../entites/requisition-min';
import { MenuItem, TreeNode } from 'primeng/api';
import { MessageService, LazyLoadEvent, ConfirmationService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { EnvoiRequisitionComponent } from '../../controles/envoi-requisition.component';
import { ItemMin } from '../../entites/item-min';
import { ItemService } from '../../services/item-service';
import { Router, ActivatedRoute } from '@angular/router';

import { ToolbarModule } from 'primeng/toolbar';

import { MenubarModule } from 'primeng/menubar';
import { BaseComponent } from '../../base/base-component';
import { Item } from '../../entites/item';
import { Inventaire } from '../../entites/inventaire';
import { NoteEntite } from '../../entites/note';
import { Requisition } from '../../entites/requisition';
import { RequisitionService } from '../../services/requisition-service';
import { Emplacement } from '../../entites/emplacement';
import { EmplacementService } from '../../services/emplacement-service';
import { AchatService } from '../../services/achat-service';
import { BonCommandeEntite } from '../../entites/bon-commande';
import { FournisseurService } from 'src/app/services/fournisseur-service';
import { Fournisseur } from 'src/app/entites/fournisseur';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { ProfilService } from 'src/app/services/profil-service';
import { AppMainComponent } from 'src/app/app.main.component';
import { CodeProjetEntite } from 'src/app/entites/code-projet';
import { EStatuts } from 'src/app/entites/enums/statuts';
import { CleValeur } from 'src/app/entites/cleValeur';

@Component({
    templateUrl: './ajout-achat.component.html',
    styleUrls: ['./ajout-achat.component.scss'],
    providers: [MessageService, ConfirmationService, DialogService]
})
export class AjoutAchatComponent extends BaseComponent implements OnInit {


    fournisseurs: Fournisseur[];
    selectionFournisseur: Fournisseur;

    dateBonCommande: Date;
    requisLe: Date;
    note:string;

    listeCodeProjet: CodeProjetEntite[];
    selectionCodeProjet: CodeProjetEntite;

    listeEmplacement: Emplacement[];
    selectionEmplacement: Emplacement;

    listeTypeBdc: CleValeur[];
    typeBdc: CleValeur;
    
    frequences: CleValeur[];
    selectionFrequence: CleValeur;

    submitted:boolean = false;

    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute, 
		appMain: AppMainComponent, location: Location, 
        private breadcrumbService: BreadcrumbService,
        private achatService: AchatService,
        private fournisseurService: FournisseurService,
        private emplacementService: EmplacementService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        public dialogService: DialogService,
        public routerService: Router ) {

        super(sdb, profilService, route, routerService, appMain);

        this.breadcrumbService.setItems([
            { label: 'Bons de commande', routerLink: '/achats' },
            { label: 'Ajouter' }
        ]);
    }

    ngOnInit() {

        this.verifierAccesPage('bdc_creer');

        this.dateBonCommande = new Date();

        this.listeCodeProjet = this.sdb.CodesProjet();

        if (this.profilService.Profil().roles.filter(x => x.nom == 'SIHC_ChargeProjet').length > 0) {
            this.listeTypeBdc = [
                {cle: 'BCA', valeur:'BCA'},
                {cle: 'BCS', valeur:'BCS'}
            ]
            this.typeBdc = this.listeTypeBdc[0];
        } else {
            this.listeTypeBdc = [
                {cle: 'BCF', valeur:'BCF'},
                {cle: 'BCS', valeur:'BCS'}
            ]
            this.typeBdc = this.listeTypeBdc[0];
        }
        

        this.frequences = [
            {cle: 'N', valeur:'Aucune'},
            {cle: 'J', valeur:'Jours'},
            {cle: 'H', valeur:'Semaine'},
            {cle: 'M', valeur:'Mois'},
            {cle: 'A', valeur:'Année'},
            {cle: 'D', valeur:'Détaillée'}
        ]
        this.selectionFrequence = this.frequences[0];

        var p1 = new Promise<number>((resolve, reject) => {
            this.fournisseurService.ObtenirFournisseurs().then(x => {
                this.fournisseurs = x.valeur;
                resolve(null);
            });
        });

        var p2 = new Promise<number>((resolve, reject) => {
            this.emplacementService.Obtenir().then(x => {
                this.listeEmplacement = x.valeur.filter(x => x.statut.statutId != EStatuts.Archive && x.adresse != null && x.adresse != undefined && x.adresse.adresseId != 0);;
                this.selectionEmplacement = this.listeEmplacement.find(x => x.emplacementId == 10000);
                resolve(null);
            });
        });
        
        Promise.all([p1, p2]).then(x => {
            this.CompleterChargement();
        });
    }

    CreerBdc() {

        

        this.submitted = true;
        this.messageService.clear();

        if (this.valider()) {

            this.DemarrerChargement();
            
            this.achatService.CreerBonCommande({
                fournisseur: this.selectionFournisseur,
                dateAchat: this.dateBonCommande,
                projet: this.selectionCodeProjet,
                employe: this.profilService.ProfilCourant(),
                emplacement: this.selectionEmplacement,
                note: this.note,
                typeBonCommande: this.typeBdc.cle,
                frequence: this.selectionFrequence?.cle,
                requisLe: this.requisLe
            }).then(x => {
                if (x.estUnSucces) {
                    this.routerService.navigateByUrl('achat/' + x.valeur);
                } else {
                    x.messages.forEach(x => {
                        this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description, sticky: true });
                    });

                    this.CompleterChargement();
                }
            });
        } else {

        }
    }

    valider(): boolean {


        if (!this.selectionFournisseur || !this.dateBonCommande || !this.selectionCodeProjet) {
            return false;
        }
            
        return true;
    }
}
