import { Component, ViewChild, ElementRef, ɵConsole } from "@angular/core"; import { Location } from "@angular/common";
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { CleValeur, CleValeurNumerique } from '../entites/cleValeur';
import { EntreeCommande } from '../entites/entree-commande';
import { ItemMin } from '../entites/item-min';
import { Statut } from '../entites/statut';
import { DonneesBaseService } from '../services/donnees-base-service';
import { BaseComponent } from '../base/base-component';
import { ItemService } from '../services/item-service';
import { BlockUIModule } from 'primeng/blockui';
import { FournisseurService } from '../services/fournisseur-service';
import { Fournisseur } from '../entites/fournisseur';
import { EmployeEntite } from '../entites/employe';
import { ItemCommandeEntite } from '../entites/item-commande';
import { MessageService } from 'primeng/api';
import { ProfilService } from '../services/profil-service';
import { ActivatedRoute, Router } from '@angular/router';
import { AchatService } from '../services/achat-service';
import { ContactEntite } from '../entites/contact';
import { ContactService } from '../services/contact-service';
import { RapportService } from '../services/rapport-service';
import { AppMainComponent } from '../app.main.component';

export interface ParametresEnvoiBonCommande {
    bonCommandeId: number;
    fournisseurId: number;
}


@Component({
    selector: 'app-envoi-bon-commande',
    templateUrl: './envoi-bon-commande.component.html',
    styleUrls: ['./envoi-bon-commande.component.scss']
})
export class EnvoiBonCommandeComponent extends BaseComponent {

    donnees: ParametresEnvoiBonCommande;

    contacts: ContactEntite[];
    selectionContacts: ContactEntite[];

    autreCourriels: string[] = [];
    listeCourriels: string[];
    suggestionsCourriel: string[];

    submitted: boolean;
    blocked: boolean = false;

    boutonEnvoyerDisponible: boolean = false;

    constructor(
        profilService: ProfilService,
        route: ActivatedRoute, 
        routerService: Router,
		appMain: AppMainComponent, location: Location, 
        public contactService: ContactService,
        public achatService: AchatService,
        public rapportService: RapportService,
        public fournisseurService: FournisseurService,
        public messageService: MessageService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public sdb: DonneesBaseService) {
        super(sdb, profilService, route, routerService, appMain);
    }

    ngOnInit() {

        this.donnees = this.config.data;


        this.contactService.ObtenirListecouriels().then(x => this.listeCourriels = x.valeur);

        this.contactService.ObtenirListeParFournisseur(this.donnees.fournisseurId).then(res => {
            if (res.estUnSucces) {

                this.contacts = res.valeur;

                if (this.contacts.length == 1) {
                    this.selectionContacts = [...this.contacts];
                } else {
                    this.selectionContacts = [...this.contacts.filter(x => x.indPrincipal)];
                }

                this.surChangementSelection();

                this.CompleterChargement();
            }
        })

    }

    annuler() {
        this.ref.close(null);
    }

    envoyer() {

        this.submitted = true;
        if (!this.valider()) {
            this.messageService.add({ key: 'envoiBonCommande', severity: 'error', summary: 'Erreurs', detail: 'Veuillez corriger les erreurs de saisie.' });
            return;
        }

        this.blocked = true;

        var destinataires: string[] = this.selectionContacts.map(x => x.courriel);
        destinataires.push(...this.autreCourriels);

        this.rapportService.EnvoyerRapportBonCommande(this.donnees.bonCommandeId, destinataires).then(res => {
            if (res) {
                this.ref.close(true);
            } else {
                this.messageService.add({ key: 'envoiBonCommande', severity: 'error', summary: 'Erreur', detail: 'Une erreur s\'est produite lors de l\'envoi du courriel.' });
            }
            
        }); 
    }

    valider() {
        return (this.selectionContacts && this.selectionContacts.length > 0)
        ||
        (this.autreCourriels && this.autreCourriels.length > 0);
    }

    surChangementSelection() {
        this.boutonEnvoyerDisponible = 
            (this.selectionContacts && this.selectionContacts.length > 0)
            ||
            (this.autreCourriels && this.autreCourriels.length > 0);
    }

    rechercherCourriel(event) {
       this.suggestionsCourriel = this.listeCourriels.filter(x => event.query.localeCompare(x.substring(0, event.query.length), 'en', { sensitivity: 'base'}) == 0);
    }

    onKeyUp(event: KeyboardEvent) {
        if (event.key == "Enter") {
         let tokenInput = event.srcElement as any;
         if (tokenInput.value) {
            this.autreCourriels.push(tokenInput.value);
            tokenInput.value = "";
         }
        }
      }
}