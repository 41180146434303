import { Component, OnInit, ViewChild, Input, ɵConsole } from "@angular/core"; import { Location } from "@angular/common";
import { MenuItem } from 'primeng/api';
import { MessageService, ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Router, ActivatedRoute } from '@angular/router';
import { FournisseurService } from 'src/app/services/fournisseur-service';
import { Fournisseur } from 'src/app/entites/fournisseur';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { ProfilService } from 'src/app/services/profil-service';
import { AppMainComponent } from 'src/app/app.main.component';
import { LocationEntite } from 'src/app/entites/location';
import { TypeLocationEntite } from 'src/app/entites/type-location';
import { EStatuts } from 'src/app/entites/enums/statuts';
import { LocationService } from 'src/app/services/location-service';
import { UniteEntite } from 'src/app/entites/unite';
import { CoutLocationEntite } from 'src/app/entites/cout-location';
import { ETypesLocation } from 'src/app/entites/enums/type-location';
import { ContextMenu } from 'primeng/contextmenu/public_api';
import { DocumentEntite } from 'src/app/entites/document';
import { DocumentService } from 'src/app/services/document-service';
import { environment } from 'src/environments/environment';
import { RequeteObtenirDocuments } from 'src/app/services/requetes/requete-obtenir-documents';
import { ContexteService } from 'src/app/services/contexte-service';
import { BaseComponent } from 'src/app/base/base-component';
import { Emplacement } from 'src/app/entites/emplacement';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { AchatService } from 'src/app/services/achat-service';
import { ConfirmerDebutLocationComponent, ResultatConfirmerDebutLocation } from '../../locations/controles/confirmer-debut-location.component';
import { InfoBonLocationComponent, ResultatInfoBonLocation } from '../../locations/controles/info-bon-location.component';
import { ConfirmerFinLocationComponent } from '../../locations/controles/confirmer-fin-location.component';
import { DetailDocumentComponent } from '../../documents/controles/detail-document.component';
import { LigneHeureEntite } from 'src/app/entites/mecanique/ligne-heure';
import { DepartementEntite } from 'src/app/entites/departement';
import { HeureService } from 'src/app/services/heure-service';
import { DepartementMecaniqueEntite } from 'src/app/entites/mecanique/departement-mecanique';

@Component({
    templateUrl: './approbation-heures.component.html',
    providers: [MessageService, ConfirmationService, DialogService]
})
export class ApprobationHeuresComponent extends BaseComponent implements OnInit {

    heures: LigneHeureEntite[] = [];
    heuresCompletes: LigneHeureEntite[] = [];
    selectionHeures: LigneHeureEntite[] = [];

    departements: DepartementMecaniqueEntite[];
    departement: DepartementMecaniqueEntite;
    emplacements: Emplacement[];
    emplacement: Emplacement;

    menu: MenuItem[];

    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute, 
		appMain: AppMainComponent, location: Location, 
        private breadcrumbService: BreadcrumbService,
        private heureService: HeureService,
        private documentService: DocumentService,
        private locationService: LocationService,
        private fournisseurService: FournisseurService,
        private contexteService: ContexteService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        public dialogService: DialogService,
        public routerService: Router ) {

        super(sdb, profilService, route, routerService, appMain);

        this.breadcrumbService.setItems([
            { label: 'Saisie des heures' }
        ]);

    }

    ngOnInit() {

        this.verifierAccesPage('temp_mec');

        this.departements = this.sdb.DepartementsMecanique();
        this.emplacements = this.sdb.Emplacements();

        this.heureService.ObtenirListeApprobation().then(res => {
            
            if (res.estUnSucces) {
      
                this.heuresCompletes = res.valeur;
                this.filtrer();
            }

            this.initialiserMenu();

            this.CompleterChargement();


        });
       
    }


    initialiserMenu() {
        this.menu = [
            {
                label: 'Retour', icon: 'pi pi-fw pi-angle-double-left', command: () => this.routerService.navigateByUrl('mecanique/heures')
            },
            {
                label: 'Refuser', icon: 'pi pi-fw pi-ban', 
                disabled: !this.selectionHeures || this.selectionHeures.length == 0,
                command: () => this.refuser(this.selectionHeures)
            },
            {
                label: 'Approuver', icon: 'pi pi-fw pi-check', 
                disabled: !this.selectionHeures || this.selectionHeures.length == 0,
                command: () => this.approuver(this.selectionHeures)
            }
        ];
    }

    surChangementSelection() {
        this.initialiserMenu();
    }

    
    filtrer() {

        console.log(this.departement);
        console.log(this.emplacement);

        this.heures = this.heuresCompletes;
        if (this.departement) {
            this.heures = this.heures.filter(x => x.departementId == this.departement.departementMecaniqueId);
        }
        if (this.emplacement) {
            this.heures = this.heures.filter(x => x.emplacementId == this.emplacement.emplacementId);
        }

    }

    retirerFiltres() {
        this.departement = null;
        this.emplacement = null;
        this.filtrer();
    }

    approuver(heures: LigneHeureEntite[]) {

        this.confirmationService.confirm({
            target: event.target,
            message: 'Voulez-vous les heures sélectionnées ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.Bloquer();
                this.heureService.Approuver({
                    employeId: this.profilService.ProfilCourant().employeId,
                    lignesHeure: this.selectionHeures
                }).then(res => {
                    this.Debloquer();
                    if (res.estUnSucces) {
                        var ids = this.selectionHeures.map(x => x.itemId);
                        this.heures = this.heures.filter(h => !ids.includes(h.itemId));
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: `Heures approuvées.` });
                    }
                    else{
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description, sticky: true });
                        });
                    }
                })
            }
        });

        
    }

    refuser(heures: LigneHeureEntite[]) {

        this.confirmationService.confirm({
            target: event.target,
            message: 'Voulez-vous vraiment refuser les heures sélectionnées ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.Bloquer();
                this.heureService.Refuser({
                    employeId: this.profilService.ProfilCourant().employeId,
                    lignesHeure: this.selectionHeures
                }).then(res => {
                    this.Debloquer();
                    if (res.estUnSucces) {
                        var ids = this.selectionHeures.map(x => x.itemId);
                        this.heures = this.heures.filter(h => !ids.includes(h.itemId));
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: `Heures refusées.` });
                    }
                    else{
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description, sticky: true });
                        });
                    }
                })

            }
        });

        
    }

    
}
