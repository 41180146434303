import { Component, OnInit, ViewChild, Input } from "@angular/core"; import { Location } from "@angular/common";
import { Table } from 'primeng/table';
import { MenuItem } from 'primeng/api/menuitem';
import { MessageService, ConfirmationService } from 'primeng/api';
import {DialogService} from 'primeng/dynamicdialog';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseComponent } from 'src/app/base/base-component';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { ProfilService } from 'src/app/services/profil-service';
import { ContextMenu } from 'primeng/contextmenu/public_api';
import { EStatuts } from 'src/app/entites/enums/statuts';
import { AppMainComponent } from 'src/app/app.main.component';
import { Statut } from 'src/app/entites/statut';
import { BonTravailEntite } from 'src/app/entites/mecanique/bon-travail';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { AchatService } from 'src/app/services/achat-service';
import { BonTravailService } from 'src/app/services/bon-travail-service';
import { TypeBonTravail } from 'src/app/entites/mecanique/type-bon-travail';
import { ETypeBonTravail } from 'src/app/entites/enums/type-bon-travail'

@Component({
    templateUrl: './bons-travail.component.html',
    styles: [`
        @media screen and (max-width: 960px) {
            :host ::ng-deep .p-datatable.p-datatable-customers.rowexpand-table .p-datatable-tbody > tr > td:nth-child(6) {
                display: flex;
            }
        }

    `],
    providers: [MessageService, ConfirmationService, DialogService],
    host: {
        '(document:keydown)': 'handleKeyboardEvents($event)'
    }
})
export class BonsTravailComponent extends BaseComponent implements OnInit {

    bonsTravail: BonTravailEntite[];
    soumissions: BonTravailEntite[];
    liste: BonTravailEntite[];
    selectionBonTravail: BonTravailEntite;

    detailBdtId: number;
    afficherDetailBdt: boolean = false;
    indexActif: number;
    showAnalyse: boolean = false;

    get activeIndex(): number {
        return this.indexActif;
    }
    set activeIndex(index: number) {
        this.indexActif = index;
        this.DemarrerChargement();

        if (index == 0 && !this.soumissions) {
            this.showAnalyse = false;
            this.bonTravailService.ObtenirSoumissions().then(x => {
                this.soumissions = x.valeur;
                this.liste = x.valeur;

                this.CompleterChargement();
            });
        } else if (index == 1 && !this.bonsTravail) {
            this.showAnalyse = false;
            this.bonTravailService.Obtenir().then(x => {
                this.bonsTravail = x.valeur;
                this.liste = x.valeur;
                // if (this.profilService.Profil().roles.filter(x => x.nom == 'SIHC_Mecanicien').length > 0
                // && this.profilService.Profil().roles.filter(x => x.nom == 'SIHC_Administrateur').length ==0
                // && this.profilService.Profil().roles.filter(x => x.nom == 'SIHC_ContremaitreMecanique').length == 0) {
                //     this.bonsTravail = x.valeur.filter(x => x.mecanicien && x.mecanicien.employeId == this.profilService.ProfilCourant().employeId);
                // } else {
                //     this.bonsTravail = x.valeur;
                // }
    
                this.CompleterChargement();
            });
        } else {
            if (index == 0) {
                this.showAnalyse = false;
                this.liste = this.soumissions;
            } else if (index == 1) {
                this.showAnalyse = false;
                this.liste = this.bonsTravail;
            } else if (index == 2) {
                this.showAnalyse = true;
            }
            this.CompleterChargement();
        }
    }

    statuts: Statut[] = this.sdb.Statuts();
    types: TypeBonTravail[] = [{
        id_type_bon_travail: ETypeBonTravail.Interne,
        nom: 'Interne'
    }, {
        id_type_bon_travail: ETypeBonTravail.Externe,
        nom: 'Externe'
    }, {
        id_type_bon_travail: ETypeBonTravail.Chantier,
        nom: 'Chantier'
    }];

    menuContextuel: MenuItem[];

    @ViewChild('dt') table: Table;
    @ViewChild('menuContextuelItemCommande') menuContextuelItemCommande: ContextMenu;

    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute,
		appMain: AppMainComponent, location: Location, 
        private breadcrumbService: BreadcrumbService,
        private bonTravailService: BonTravailService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        public dialogService: DialogService,
        public routerService: Router) {

            super(sdb, profilService, route, routerService, appMain);

        this.breadcrumbService.setItems([
            { label: 'Bons de travail' }
        ]); 
    }

    ngOnInit() {

        this.verifierAccesPageAuMoisUnAcces(['bonstravail','temp_mec']);

        this.colonnesDisponibles = [
            /* { champ: 'projet', entete: 'Projet', afficheParDefaut: true },
            { champ: 'nbItems', entete: 'Nb items', afficheParDefaut: false },
            { champ: 'coutTotal', entete: 'Coût total', afficheParDefaut: false },
            { champ: 'employe', entete: 'Employé', afficheParDefaut: true },
            { champ: 'dateEnvoi', entete: 'Date d\'envoi', afficheParDefaut: true },
            { champ: 'envoyeA', entete: 'Envoyé à', afficheParDefaut: true } */
        ];
        this.colonnesSelectionnees = this.colonnesDisponibles.filter(x => x.afficheParDefaut);

        this.activeIndex = 1;
    }

    loadBonsTravail(){
    }

    handleKeyboardEvents(event: KeyboardEvent) {
        // if (event.key == '+') {
        //     this.AjouterBdt();
        // }
    }

    menuContextuelEntrees: MenuItem[];
    surMenuContextuelItem(event, bonTravail: BonTravailEntite) {
        this.selectionBonTravail = bonTravail;

        this.menuContextuelEntrees = [
            {
                label: 'Voir l\'unité',
                icon: 'pi pi-fw pi-eye',
                visible: this.estAutorise('item_detail'),
                command: () => this.routerService.navigateByUrl('item/' + bonTravail.item.itemId)
            },
            {
                label: 'Voir le fournisseur',
                icon: 'pi pi-fw pi-eye',
                visible: this.estAutorise('fournisseur_detail'),
                command: () => this.routerService.navigateByUrl('fournisseur/' + bonTravail.fournisseur.fournisseurId)
            },
            {separator: true},
            {
                label: 'Supprimer',
                icon: 'pi pi-fw pi-times',
                visible: (this.estAutorise('bontravail_supprimer') || this.estAutorise('temp_mec')),
                disabled: bonTravail.statut.statutId != EStatuts.EnCours && bonTravail.statut.statutId != EStatuts.EnAttente,
                command: () => this.supprimerBonTravail(bonTravail)
            }
        ];

        this.menuContextuelItemCommande.toggle(event);
    }

    supprimerBonTravail(bonTravail: BonTravailEntite) {
         this.confirmationService.confirm({
            target: event.target,
            message: 'Voulez-vous vraiment supprimer le bon de travail sélectionné ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.bonTravailService.Supprimer(bonTravail.bonTravailId).then(res => {
                    if (res.estUnSucces) {
                        this.liste = this.liste.filter(val => val.bonTravailId != bonTravail.bonTravailId);
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Bon de travail supprimé.' });
                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                });

            }
        });
    }

    AjouterBdt(){
        this.routerService.navigateByUrl("mecanique/bontravail/nouveau")
    }

    OuvrirDetailBdt(bonTravailId: number) {
        this.routerService.navigateByUrl(`mecanique/bontravail/${bonTravailId}`)
    }

    OuvrirDetailBdtNouvelOnglet(itemId: number) {
        this.appMain.detailBonTravailId = itemId;
        this.appMain.afficherDetailBonTravail = true;
    }
}
