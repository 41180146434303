import {Component} from "@angular/core"; import { Location } from "@angular/common";
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import { EmployeEntite } from '../entites/employe';
import { DonneesBaseService } from '../services/donnees-base-service';

@Component({
    templateUrl: './envoi-requisition.component.html',
})
export class EnvoiRequisitionComponent {

    employes: EmployeEntite[];
    selectionEmploye: string;
            
    constructor(
        public sdb: DonneesBaseService,
        public ref: DynamicDialogRef, 
        public config: DynamicDialogConfig) { }

    ngOnInit() {
        this.employes = this.sdb.Employes();
    }

    Envoyer() {

    }

    Annuler() {

    }
}