<p-toast key="finLocation"></p-toast>


<p-card *ngIf="!EstCharge()">
    <div class="fluid formgrid grid">
        <div class="flex flex-column gap-2 col-12 text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-card>

<div #contenu *ngIf="EstCharge()">

    <div class="fluid formgrid grid">
        <div class="flex flex-column gap-2 col-12 md:col-6 lg:col-3">
            <label for="lastname">Date de fin de location<span class="requis">*</span></label>
            <p-calendar appendTo="body" showIcon=true [(ngModel)]="location.dateFin" dateFormat="yy-mm-dd" [yearNavigator]="true" [monthNavigator]="true" yearRange="2000:2040" ></p-calendar>
            <small class="p-error" *ngIf="submitted && !location.dateFin">La date de fin de location est obligatoire.</small>
        </div>   
        <div class="flex flex-column gap-2 col-3">
            <label for="description">Heures à la fin de la location<span class="requis">*</span></label>
            <p-inputNumber [min]="0" [(ngModel)]="location.heuresFin"></p-inputNumber>
            <small class="p-error" *ngIf="submitted && !location.heuresFin  && location.heuresFin != 0">Les heures à la fin de la location sont obligatoires.</small>
        </div>   
    </div>
    <div class="fluid formgrid grid">
        <div class="flex flex-column gap-2 col-12">
            <label for="etat">État de l'équipement au retour<span class="requis">*</span></label>
            <input  type="text" pInputText id="etat" [(ngModel)]="location.etat" autocomplete="off"  />
            <small class="p-error" *ngIf="submitted && !location.etat">L'état au retour est obligatoire.</small>
        </div>
       
    </div>

    

    <div class="text-right mt-3">

        <button pButton pRipple label="Sauvegarder" icon="pi pi-save" class="p-button-primary"
        (click)="sauvegarder()"></button>
        <button pButton pRipple label="Annuler" icon="pi pi-ban" class="p-button-text" (click)="annuler()"></button>
        

    </div>

</div>