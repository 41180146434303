<div class="card">
<div class="grid ">
    <div class="fluid col-12">
            <h5>Employé</h5>
            <p-dropdown #champDemandeur [options]="employes" [(ngModel)]="selection" optionLabel="nom"
						[showClear]="true" filter="true" filterBy="prenom,nom"
						appendTo="body" placeholder="Sélectionner un demandeur...">
						<ng-template pTemplate="selectedItem">
							<div *ngIf="selection">
								<div>{{selection.prenom}} {{selection.nom}}</div>
							</div>
						</ng-template>
						<ng-template let-employe pTemplate="item">
							<div>
								<div>{{employe.prenom}} {{employe.nom}}</div>
							</div>
						</ng-template>
					</p-dropdown>
            <br>

    </div>
</div>
<div class="grid">
    <div class="col-12 text-right ">
        <button pButton pRipple label="Confirmer" icon="pi pi-check" class="p-button-primary" [disabled]="!selection"
            (click)="Envoyer()"></button>
        <button pButton pRipple label="Annuler" icon="pi pi-ban" class="p-button-text"
            (click)="Annuler()"></button>
    </div>
</div>
</div>