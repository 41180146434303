import { Component, OnInit, ViewChild, Input } from "@angular/core"; import { Location } from "@angular/common";
import { BreadcrumbService } from '../../app.breadcrumb.service';
import { MessageService, ConfirmationService } from 'primeng/api';

import { DialogService } from 'primeng/dynamicdialog';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseComponent } from '../../base/base-component';
import { Requisition } from '../../entites/requisition';
import { CleValeur, CleValeurNumerique } from 'src/app/entites/cleValeur';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { ProfilService } from 'src/app/services/profil-service';
import { Emplacement } from 'src/app/entites/emplacement';
import { EmployeEntite } from 'src/app/entites/employe';
import { RequisitionService } from 'src/app/services/requisition-service';
import { ETypesRequisition } from 'src/app/entites/enums/type-requisition';
import { EStatuts } from 'src/app/entites/enums/statuts';
import { AppMainComponent } from 'src/app/app.main.component';
import { ContexteService } from 'src/app/services/contexte-service';

@Component({
    templateUrl: './ajout-requisition.component.html',
    styleUrls: ['./ajout-requisition.component.scss'],
    providers: [MessageService, ConfirmationService, DialogService]
})
export class AjoutRequisitionComponent extends BaseComponent implements OnInit {

    requisition: Requisition;

    typesRequisition: CleValeurNumerique[];
    selectionTypeRequisition: CleValeurNumerique;

    dateRequisition: Date;
    transporteur: string;
    camion: string;
    remorque: string;
    chauffeur: string;

    emplacements: Emplacement[];
    selectionEmplacementSource: Emplacement;
    selectionEmplacementDestination: Emplacement;

    listeEmployes: EmployeEntite[] = [];
    selectionDemandeur: EmployeEntite;

    submitted:boolean = false;

    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute, 
		appMain: AppMainComponent, location: Location, 
        private messageService: MessageService,
        private requisitionServie: RequisitionService,
        private contexteService: ContexteService,
        private breadcrumbService: BreadcrumbService,
        public dialogService: DialogService,
        public routerService: Router ) {

        super(sdb, profilService, route, routerService, appMain);

        this.breadcrumbService.setItems([
            { label: 'Réquisitions', routerLink: '/requisitions' },
            { label: 'Ajouter' }
        ]);
    }

    ngOnInit() {

        this.dateRequisition = new Date();

        this.typesRequisition = [
            { "cle": ETypesRequisition.Requisition, "valeur": "Réquisition" },
            { "cle": ETypesRequisition.Retour, "valeur": "Retour" },
            { "cle": ETypesRequisition.Transfert, "valeur": "Transfert" }
        ];
        this.selectionTypeRequisition = this.typesRequisition[0];

        this.listeEmployes = this.sdb.Employes();
        this.emplacements = this.sdb.Emplacements();

        this.selectionEmplacementSource = this.emplacements.find(x => x.emplacementId == 10000);

        if (this.contexteService.ValiderPresence(['commande.emplacementId'])) {
            this.selectionEmplacementDestination = this.emplacements.find(x => x.emplacementId == Number(this.contexteService.Obtenir('commande.emplacementId')));
            this.contexteService.Supprimer('commande.emplacementId');
        }

        this.CompleterChargement();
    }

    surChangementTypeRequisition() {
        if (this.selectionTypeRequisition.cle == ETypesRequisition.Requisition) {
            this.selectionEmplacementSource = this.emplacements.find(x => x.emplacementId == 10000);
            this.selectionEmplacementDestination = null;
        } else if (this.selectionTypeRequisition.cle == ETypesRequisition.Retour) {
            this.selectionEmplacementSource = null
            this.selectionEmplacementDestination = this.emplacements.find(x => x.emplacementId == 10000);
        } else {
            this.selectionEmplacementSource = null;
            this.selectionEmplacementDestination = null;
        }
    }

    CreerRequisition() {

        this.Bloquer();

        this.submitted = true;
        this.messageService.clear();

        if (this.validerNouvelleRequisition()) {

            this.requisitionServie.CreerRequisition({
                date: this.dateRequisition,
                de: this.selectionEmplacementSource,
                a: this.selectionEmplacementDestination,
                demandeur: this.selectionDemandeur,
                transporteur: this.transporteur,
                camion: this.camion,
                remorque: this.remorque,
                chauffeur: this.chauffeur,
                type: { typeRequisitionId: this.selectionTypeRequisition.cle },
                statut: this.sdb.Statut(EStatuts.Actif)
            }).then(x => {
                if (x.estUnSucces) {
                    this.Debloquer();
                    this.routerService.navigateByUrl('requisition/' + x.valeur.requisitionId);
                } else {
                    x.messages.forEach(x => {
                        this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description, sticky: true });
                    });

                    this.Debloquer();
                }
            });
        } else {
            this.messageService.add({ severity: 'error', summary: 'Erreurs', detail: 'Veuillez corriger les erreurs de saisie.' });
            this.Debloquer();
        }
    }

    validerNouvelleRequisition(): boolean {


        if (!this.selectionEmplacementSource || !this.selectionEmplacementDestination || !this.dateRequisition) {
            return false;
        }

        if ((this.selectionTypeRequisition.cle == ETypesRequisition.Requisition
            || this.selectionTypeRequisition.cle == ETypesRequisition.Transfert) && !this.selectionDemandeur) {
            return false;
        }
            
        return true;
    }
}
