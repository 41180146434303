<p-toast key="envoiBonCommande"></p-toast>

<p-blockUI [blocked]="blocked">
    <div class="fluid formgrid grid">
        <div class="flex flex-column gap-2 my-2 col-12 text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-blockUI>


<p-card *ngIf="!EstCharge()">
    <div class="fluid formgrid grid">
        <div class="flex flex-column gap-2 my-2 col-12 text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-card>

<div #contenu *ngIf="EstCharge()">

    <!-- <div class="fluid formgrid grid">
        <div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3">
            <label for="numero">Numéro</label>
            <input id="numero" type="text" pInputText [readonly]="true" [(ngModel)]="donnees.numeroItem">
        </div>
        <div class="flex flex-column gap-2 my-2 col-12 md:col-12 lg:col-9">
            <label for="description">Description</label>
            <input id="description" type="text" pInputText [readonly]="true" [(ngModel)]="donnees.descriptionItem">
        </div>
    </div> -->
    <div class="fluid formgrid grid">
        <div class="flex flex-column gap-2 my-2 col-12">
            <div class="card">
    
                        <p-table #dt [value]="contacts" dataKey="contactId" tableStyleClass="tableNormale"
                        [(selection)]="selectionContacts" (onRowSelect)="surChangementSelection()" (onRowUnselect)="surChangementSelection()"
                        (onHeaderCheckboxToggle)="surChangementSelection()"
                            autoLayout="true" styleClass="p-datatable-sm p-datatable-striped"
                            [rowHover]="true"  [filterDelay]="0"
                            [globalFilterFields]="['nom','prenom','courriel']">
                            <ng-template pTemplate="caption">
                                <div class="flex align-content-center justify-content-between">
                                    <h5 class="titreTableau m-0">Contacts</h5>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="min-width: 3rem">
                                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                    </th>
                                    <th style="min-width: 5rem">
                                    </th>
                                    <th>Nom</th>
									<th>Prénom</th>
									<th>Fonction</th>
									<th>Téléphone</th>
									<th>Cellulaire</th>
									<th>Courriel</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-contact>
                            
                                <tr>
                                    <td>
                                        <p-tableCheckbox [value]="contact"></p-tableCheckbox>
                                    </td>
                                    <td>
										<i *ngIf="contact.indPrincipal" class="pi pi-star"></i>
									</td>
									<td>{{contact.nom}}</td>
									<td>{{contact.prenom}}</td>
									<td>{{contact.fonction}}</td>
									<td>{{contact.telephone}}</td>
									<td>{{contact.cellulaire}}</td>
									<td>{{contact.courriel}}</td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="8">Aucun contact.</td>
                                </tr>
                            </ng-template>
                        </p-table>
    
            </div>
        </div>
    
        <div class="flex flex-column gap-2 my-2 col-12">
            
            <label for="modele">Autre destinataires</label>
            <p-autoComplete  
                appendTo="body" 
                [(ngModel)]="autreCourriels" 
                [suggestions]="suggestionsCourriel" 
                (completeMethod)="rechercherCourriel($event)" 
                [multiple]="true" 
                (onKeyUp)="onKeyUp($event)"
                (onSelect)="surChangementSelection()"
                (onUnselect)="surChangementSelection()">
            </p-autoComplete>
        </div>
    
    </div>
    
    
    

    <div class="text-right mt-3">

        <button pButton pRipple label="Envoyer" icon="pi pi-send" class="p-button-primary" [disabled]="!boutonEnvoyerDisponible"
            (click)="envoyer()"></button>
        <button pButton pRipple label="Annuler" icon="pi pi-ban" class="p-button-text" (click)="annuler()"></button>
        

    </div>

</div>