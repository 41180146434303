<div class="layout-wrapper" [ngClass]="{
    'layout-overlay': app.menuMode === 'overlay',
    'layout-static': app.menuMode === 'static',
    'layout-slim': app.menuMode === 'slim',
    'layout-sidebar-dim': app.colorScheme === 'dim',
    'layout-sidebar-dark': app.colorScheme === 'dark',
    'layout-overlay-active': overlayMenuActive,
    'layout-mobile-active': staticMenuMobileActive,
    'layout-static-inactive': staticMenuDesktopInactive && app.menuMode === 'static',
    'p-input-filled': app.inputStyle === 'filled', 'p-ripple-disabled': !app.ripple}"
    [class]="app.colorScheme === 'light' ? app.menuTheme : ''" [attr.data-theme]="app.colorScheme"
    (click)="onLayoutClick()">

    <app-menu></app-menu>

    <div class="layout-content-wrapper">
        <app-topbar></app-topbar>
        <div class="layout-content">
            <p-card>
                <p-blockUI [blocked]="mainBlocked">
                    <div class="fluid formgrid grid">
                        <div class="flex flex-column gap-2 col-12 text-center">
                            <p-progressSpinner></p-progressSpinner>
                        </div>
                    </div>
                </p-blockUI>


                <p-dialog header=" " [(visible)]="showIdleDialog" [modal]="true" [style]="{width: '50vw'}"
                    [baseZIndex]="10000" [draggable]="false" [resizable]="false">
                    <h5 class="m-0">
                        <i class="pi pi-moon mr-3" style="font-size: 2rem"></i>
                        Vous avez été inactif pendant plus de 5 minutes. L'application a été désactivée.
                    </h5>

                    <ng-template pTemplate="footer">
                        <div (click)="surFermetureDialogueIdle()"
                            style="cursor: pointer; color: #0B7AD1; margin-right: 10px; margin-bottom: 10px;">
                            <span
                                style="border: 1px solid #2196F3; background: #2196F3; color: #fff; padding: .8rem; border-radius: 4px;">
                                <i class="pi pi-check mr-3"></i>
                                Je suis de retour
                            </span>
                        </div>
                    </ng-template>
                </p-dialog>

                <router-outlet></router-outlet>

                <p-sidebar [(visible)]="afficherDetailItem" [style]="{width:'95%'}" position="left">
                    <app-detail-item [itemId]="detailItemId" *ngIf="afficherDetailItem"></app-detail-item>
                </p-sidebar>

                <p-sidebar [(visible)]="afficherDetailBonTravail" [style]="{width:'95%'}" position="left">
                    <app-detail-bdt [bdtId]="detailBonTravailId" *ngIf="afficherDetailBonTravail"></app-detail-bdt>
                </p-sidebar>
            </p-card>
        </div>


        <app-footer></app-footer>
    </div>

    <!--    <app-config></app-config> -->

    <app-search></app-search>

    <div class="layout-mask modal-in"></div>
</div>