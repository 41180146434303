import { Component, OnInit, ViewChild, Input, ɵConsole, ElementRef } from "@angular/core"; import { Location } from "@angular/common";
import { Table } from 'primeng/table';
import { BreadcrumbService } from '../../app.breadcrumb.service';

import { RequisitionMin } from '../../entites/requisition-min';
import { MenuItem } from 'primeng/api/menuitem';
import { MessageService, LazyLoadEvent, ConfirmationService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

import { DialogService } from 'primeng/dynamicdialog';
import { EnvoiRequisitionComponent } from '../../controles/envoi-requisition.component';
import { ItemMin } from '../../entites/item-min';
import { ItemService } from '../../services/item-service';
import { EmplacementService } from '../../services/emplacement-service';
import { Router, ActivatedRoute } from '@angular/router';
import { ReparationEntite } from 'src/app/entites/reparation';
import { BaseComponent } from 'src/app/base/base-component';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { ContextMenu } from 'primeng/contextmenu/public_api';
import { ProfilService } from 'src/app/services/profil-service';
import { RetourReparationComponent } from 'src/app/controles/retour-reparation.component';
import { AppMainComponent } from 'src/app/app.main.component';
import { LocationEntite } from 'src/app/entites/location';
import { LocationService } from 'src/app/services/location-service';
import { environment } from 'src/environments/environment';
import { LocationChangeEvent } from '@angular/common';
import { RapportService } from 'src/app/services/rapport-service';
import { Emplacement } from 'src/app/entites/emplacement';
import { Value } from 'sass';

@Component({
    templateUrl: './locations.component.html',
    providers: [MessageService, ConfirmationService, DialogService]
})
export class LocationsComponent extends BaseComponent implements OnInit {

    items: LocationEntite[];
    emplacements: Emplacement[];
    selectionItem: LocationEntite;
    selectionEmplacements: number[];

    seuilRouge = environment.parametres.garanties.seuilRouge;
    seuilJaune = environment.parametres.garanties.seuilJaune;

    menu: MenuItem[];

    @ViewChild('dt') table: Table;
    
    contenuMenuContextuelLocation: MenuItem[];
    @ViewChild('menuContextuelLocation') menuContextuelLocation: ContextMenu;

    constructor(
        public sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute, 
		appMain: AppMainComponent, location: Location, 
        private breadcrumbService: BreadcrumbService,
        private ItemsService: ItemService,
        private emplacementService: EmplacementService,
        private locationService: LocationService,
        private rapportService: RapportService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        public dialogService: DialogService,
        public routerService: Router) {
        super(sdb, profilService, route, routerService, appMain);
        this.breadcrumbService.setItems([
            { label: 'Locations' }
        ]);
    }


    ngOnInit() {

        this.verifierAccesPage('locations');

        this.colonnesDisponibles = [
            { champ: 'categorie', entete: 'Catégorie', afficheParDefaut: false },
            { champ: 'sousCategorie', entete: 'Sous-catégorie', afficheParDefaut: false },
            { champ: 'marque', entete: 'Marque', afficheParDefaut: false },
            { champ: 'modele', entete: 'Modèle', afficheParDefaut: false }
        ];

        this.colonnesSelectionnees = this.colonnesDisponibles.filter(x => x.afficheParDefaut);

        Promise.all([this.locationService.ObtenirListe(), this.emplacementService.Obtenir()]).then(values => {
            this.items = values[0].valeur;
            this.emplacements = values[1].valeur;
            this.CompleterChargement();
            this.initialiserMenu();
        });
    }

    ObtenirListeNomEmplacements(array: Emplacement[]): number[] {
        var temp: number[] = [];
        array.forEach(x => {
            if (x.emplacementId != null)
                temp.push(x.emplacementId)
        });
        console.log(array);
        return temp;
    }

    initialiserMenu() {
        this.menu = [
            {
                label: 'Produire rapport', 
                icon: 'pi pi-fw pi-print',
                disabled: this.items.length == 0,
                command: () => this.produireRapportLocations()
            },
            {
                label: 'Produire rapport ISO', 
                icon: 'pi pi-fw pi-print',
                disabled: this.items.length == 0,
                command: () => this.produireRapportLocationsIso()
            },
            {
                label: 'Produire rapport heures location', 
                icon: 'pi pi-fw pi-print',
                disabled: this.items.length == 0,
                command: () => this.produireRapportHeuresLocation()
            }
        ];
    }

    OuvrirDetailLocation(location: LocationEntite) {
        
        this.routerService.navigateByUrl('location/' + location.locationId);
    }

    produireRapportLocations() {
        this.messageService.add({ severity: 'info', summary: 'Demande de rapport', detail: `Votre demande de rapport a été envoyée.` });

        this.rapportService.ProduireRapportLocation().then(x => {
            if (x) {
                this.messageService.add({ severity: 'success', summary: 'Succès', detail: `Votre rapport a été généré.` });
            } else {
                this.messageService.add({ severity: 'error', summary: 'Erreur', detail: `Une erreur est survenue pendant la génération de votre rapport.` });
            }
        });
    }

    produireRapportLocationsIso() {
        this.messageService.add({ severity: 'info', summary: 'Demande de rapport', detail: `Votre demande de rapport a été envoyée.` });

        this.rapportService.ProduireRapportLocationIso().then(x => {
            if (x) {
                this.messageService.add({ severity: 'success', summary: 'Succès', detail: `Votre rapport a été généré.` });
            } else {
                this.messageService.add({ severity: 'error', summary: 'Erreur', detail: `Une erreur est survenue pendant la génération de votre rapport.` });
            }
        });
    }

    produireRapportHeuresLocation() {
        this.naviguer('rapport/heureslocation');
    }


    ajouterLocation() {
        this.routerService.navigateByUrl('location/0');
    }

    supprimer(location: LocationEntite) {
        this.confirmationService.confirm({
            target: event.target,
            message: 'Voulez-vous vraiment supprimer la location sélectionnée ? Le bon de commande associé sera également supprimé.',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.locationService.Supprimer(location.locationId).then(res => {
                    if (res.estUnSucces) {
                        this.items = this.items.filter(val => val.locationId != location.locationId);
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Location supprimée.' });
                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                });

            }
        });
    }
    
    surMenuContextuelLocation(event, location: LocationEntite) {
        this.selectionItem = location;

        this.contenuMenuContextuelLocation = [
            { 
                label: 'Voir le fournisseur', 
                icon: 'pi pi-fw pi-eye', 
                visible: this.estAutorise('fournisseur_detail'),
                command: () => this.routerService.navigateByUrl('fournisseur/' + this.selectionItem.fournisseur.fournisseurId) 
            },
            { 
                label: 'Voir le bon de commande', 
                icon: 'pi pi-fw pi-shopping-cart', 
                visible: this.estAutorise('bdc_detail'),
                disabled: !this.selectionItem.bonCommande,
                command: () => this.routerService.navigateByUrl('achat/' + this.selectionItem.bonCommande.bonCommandeId) 
            },
            { separator: true, visible: this.estAutorise('location_supprimer') },
            { 
                label: 'Supprimer', 
            icon: 'pi pi-fw pi-trash', 
            visible: this.estAutorise('location_supprimer'),
            command: () => this.supprimer(this.selectionItem) }
        ];

        this.menuContextuelLocation.toggle(event);
    }
}
