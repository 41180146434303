import {Component, OnInit} from "@angular/core"; import { Location } from "@angular/common";
import {AppMainComponent} from './app.main.component';
import { ProfilEntite } from './entites/profil';
import { ProfilService } from './services/profil-service';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html',
})
export class AppMenuComponent implements OnInit {

    model: any[];

    constructor(public appMain: AppMainComponent, location: Location,  public profilService: ProfilService) {}

    ngOnInit() {
         this.model = [
            {
                visible: true,
                items: [
                    {label: 'Accueil', icon: 'pi pi-fw pi-home', routerLink: ['/'], visible: true}
                ]
            },
            {separator: true},
            {
                visible: true,
                label: 'Inventaire', icon: 'pi pi-fw pi-chart-bar', routerLink: ['utilities'],
                items: [
                    {label: 'Items', icon: 'pi pi-fw pi-id-card', routerLink: ['/items'], visible: this.profilService.estAutoriseTache('items')},
                    {separator: true},
                    {label: 'Items incomplets', icon: 'pi pi-fw pi-id-card', routerLink: ['/items/incomplet'], visible: this.profilService.estAutoriseTache('items_incomplets')},
                    {label: 'Items archivés', icon: 'pi pi-fw pi-id-card', routerLink: ['/items/archive'], visible: this.profilService.estAutoriseTache('items_archives')},
                    {label: 'Réparations', icon: 'pi pi-fw pi-id-card', routerLink: ['/items/reparations'], visible: this.profilService.estAutoriseTache('reparations')},
                    {label: 'Garanties', icon: 'pi pi-fw pi-shield', routerLink: ['/garanties'], visible: this.profilService.estAutoriseTache('garanties')},
                    {label: 'Emplacements', icon: 'pi pi-fw pi-map', routerLink: ['/pilotage/emplacements'], visible: this.profilService.estAutoriseTache('emplacements')},
                    {label: 'Locations', icon: 'pi pi-fw pi-tag', routerLink: ['/locations'], visible: this.profilService.estAutoriseTache('locations')},
                    {label: 'Boites d\'outils', icon: 'pi pi-fw pi-cog', routerLink: ['/pilotage/boitesOutil'], visible: this.profilService.estAutoriseTache('boites_outils_consulter')},
                    /* {
                        label: 'Autres', icon: 'pi pi-fw pi-plus-circle', routerLink: ['utilities'],
                        items: [
                            {label: 'Items archivés', icon: 'pi pi-fw pi-id-card', routerLink: ['/archives']},
                            {label: 'Réparations', icon: 'pi pi-fw pi-id-card', routerLink: ['/items/reparations']},
                        ]
                    } */
                ]
            },
            {separator: this.profilService.estAutoriseTache('unites') || this.profilService.estAutoriseTache('temp_mec')},
            {
                label: 'Mécanique', icon: 'pi pi-fw pi-briefcase', routerLink: ['utilities'], visible: this.profilService.estAutoriseTache('unites') || this.profilService.estAutoriseTache('temp_mec') || this.profilService.estAutoriseTache('temp_mec'),
                items: [
                    {label: 'Unités', icon: 'pi pi-fw pi-id-card', routerLink: ['/mecanique/unites'], visible: this.profilService.estAutoriseTache('unites') || this.profilService.estAutoriseTache('temp_mec')},
                    {label: 'Pièces', icon: 'pi pi-fw pi-id-card', routerLink: ['/mecanique/pieces'], visible: this.profilService.estAutoriseTache('pieces') || this.profilService.estAutoriseTache('temp_mec')},
                    {label: 'Bons de travail', icon: 'pi pi-fw pi-check-square', routerLink: ['/mecanique/bonstravail'], visible: this.profilService.estAutoriseTache('bonstravail') || this.profilService.estAutoriseTache('temp_mec')},
                    {label: 'Planification', icon: 'pi pi-fw pi-calendar', routerLink: ['/mecanique/planification'], visible: this.profilService.estAutoriseTache('temp_mec')},
                    {label: 'Heures/Km', icon: 'pi pi-fw pi-clock', routerLink: ['/mecanique/heures'], visible: this.profilService.estAutoriseTache('temp_mec')},
                    // {label: 'Notes', icon: 'pi pi-fw pi-pencil', routerLink: ['/mecanique/notes'], visible: this.profilService.estAutoriseTache('temp_mec')}
                ]
            },
            {separator: this.profilService.estAutoriseTache('precommande') || this.profilService.estAutoriseTache('commandes') || this.profilService.estAutoriseTache('requisitions')},
            {
                label: 'Réquisitions', icon: 'pi pi-fw pi-chart-bar', routerLink: ['utilities'], visible: this.profilService.estAutoriseTache('precommande') || this.profilService.estAutoriseTache('commandes') || this.profilService.estAutoriseTache('requisitions'),
                items: [
                    {label: 'Pré-commandes', icon: 'pi pi-fw pi-pencil', routerLink: ['/precommandes'], visible: this.profilService.estAutoriseTache('precommande')},
                    {label: 'Pré-commandes', icon: 'pi pi-fw pi-check-square', routerLink: ['/approbationprecommandes'], visible: this.profilService.estAutoriseTache('approbation_precommande')},
                    {label: 'Commandes', icon: 'pi pi-fw pi-book', routerLink: ['/commandes'], visible: this.profilService.estAutoriseTache('commandes') || this.profilService.estAutoriseTache('commandes_emplacements')},
                    {label: 'Commandes complet', icon: 'pi pi-fw pi-book', routerLink: ['/commandescomplet'], visible: this.profilService.estAutoriseTache('commandes') || this.profilService.estAutoriseTache('commandes_emplacements')},
                    {label: 'Réquisitions', icon: 'pi pi-fw pi-tablet', routerLink: ['/requisitions'], visible: this.profilService.estAutoriseTache('requisitions')},
                    {label: 'Retour', icon: 'pi pi-fw pi-arrow-left', routerLink: ['/retour'], visible: this.profilService.estAutoriseTache('retours')},
                ]
            },
            {separator: this.profilService.estAutoriseTache('fournisseurs') || this.profilService.estAutoriseTache('bdcs')},
            {
                label: 'Achats', icon: 'pi pi-fw pi-chart-bar', routerLink: ['utilities'], visible: this.profilService.estAutoriseTache('fournisseurs') || this.profilService.estAutoriseTache('bdcs'),
                items: [
                    {label: 'Fournisseurs', icon: 'pi pi-user', routerLink: ['/fournisseurs'], visible: this.profilService.estAutoriseTache('fournisseurs')},
                    {label: 'Bons de commande', icon: 'pi pi-shopping-cart', routerLink: ['/achats'], visible: this.profilService.estAutoriseTache('bdcs') || this.profilService.estAutoriseTache('bdcs_proprietaire')},
                    {label: 'Compléter une liste', icon: 'pi pi-fw pi-pencil', routerLink: ['/listeachat'], visible: this.profilService.estAutoriseTache('liste_achat')},
                    {label: 'Approuver une liste', icon: 'pi pi-fw pi-check-square', routerLink: ['/approbationlisteachat'], visible: this.profilService.estAutoriseTache('approuver_liste_achat')},
                    {label: 'Soumissions', icon: 'pi pi-dollar', routerLink: ['/soumissions'], visible: this.profilService.estAutoriseTache('soumissions')}
                ]
            },
            {separator: true},
            {
                label: 'Assurance qualité', icon: 'pi pi-fw pi-shield', routerLink: ['utilities'], visible: true /*this.profilService.estAutoriseTache('assurance_qualite')*/,
                items: [
                    {label: 'Non conformités', icon: 'pi pi-fw pi-exclamation-triangle', routerLink: ['/assurancequalite/nonconformite'], visible: true},
                    {label: 'Actions correctives', icon: 'pi pi-fw pi-file-check', routerLink: ['/assurancequalite/actioncorrective'], visible: true},
                ]
            },
            {separator:  this.profilService.estAutoriseTache('rapport_liste_equipement') || this.profilService.estAutoriseTache('rapport_utilisation_equipement')},
            {
                label: 'Rapports', icon: 'pi pi-fw pi-chart-bar', routerLink: ['utilities'], visible: this.profilService.estAutoriseTache('rapport_liste_equipement') || this.profilService.estAutoriseTache('rapport_utilisation_equipement') || this.profilService.estAutoriseTache('rapport_fiche_unite') || this.profilService.estAutoriseTache('rapport_pep_saaq') || this.profilService.estAutoriseTache('rapport_utilisation_equipement'),
                items: [
                    {
                        label: 'Inventaire', icon: 'pi pi-fw pi-chart-bar', visible: this.profilService.estAutoriseTache('rapport_liste_equipement'),
                        items: [
                            {label: 'Liste des équipements', icon: 'pi pi-fw pi-chart-bar', routerLink: ['/rapport/listeequipement'], visible: this.profilService.estAutoriseTache('rapport_liste_equipement')},
                            {label: 'Entrées / Sorties', icon: 'pi pi-fw pi-chart-bar', routerLink: ['/rapport/entreesortie'], visible: this.profilService.estAutoriseTache('rapport_entrees_sorties')},
                            {label: 'Heures des équipements', icon: 'pi pi-fw pi-chart-bar', routerLink: ['/rapport/heures'], visible: this.profilService.estAutoriseTache('rapport_entrees_sorties')},
                        ]
                    },
                    {
                        label: 'Comptabilité', icon: 'pi pi-fw pi-chart-bar', visible: this.profilService.estAutoriseTache('rapport_utilisation_equipement'),
                        items: [
                            {label: 'Utilisation équipements', icon: 'pi pi-fw pi-chart-bar', routerLink: ['rapport/utilisationequipement'], visible: this.profilService.estAutoriseTache('rapport_utilisation_equipement')},
                        ]
                    },
                    {
                        label: 'Mécanique', icon: 'pi pi-fw pi-chart-bar', visible: this.profilService.estAutoriseTache('rapport_fiche_unite') || this.profilService.estAutoriseTache('rapport_pep_saaq'),
                        items: [
                            {label: 'Fiche d\'entretien', icon: 'pi pi-fw pi-chart-bar', routerLink: ['rapport/ficheEntretien'], visible: this.profilService.estAutoriseTache('rapport_fiche_unite')},
                            {label: 'Entretiens PEP', icon: 'pi pi-fw pi-chart-bar', routerLink: ['rapport/entretienspep'], visible: this.profilService.estAutoriseTache('rapport_pep_saaq')},
                            {label: 'Entretiens SAAQ', icon: 'pi pi-fw pi-chart-bar', routerLink: ['rapport/entretienssaaq'], visible: this.profilService.estAutoriseTache('rapport_pep_saaq')},
                        ]
                    },
                ]
            },
            {separator: this.profilService.estAutoriseTache('admin_attributs')},
            {
                label: 'Administration', icon: 'pi pi-fw pi-cog', routerLink: ['/pages'], visible: this.profilService.estAutoriseTache('admin_attributs') || this.profilService.estAutoriseTache('temp_mec'),
                items: [
                    {label: 'Attributs', icon: 'pi pi-fw pi-cog', routerLink: ['/pilotage/attributs'], visible: this.profilService.estAutoriseTache('admin_attributs')},
                    {label: 'Catégories', icon: 'pi pi-fw pi-cog', routerLink: ['/pilotage/categories'], visible: this.profilService.estAutoriseTache('admin_categories')},
                    {label: 'Boites d\'outils', icon: 'pi pi-fw pi-cog', routerLink: ['/pilotage/boitesOutil'], visible: this.profilService.estAutoriseTache('admin_boites_outils')},
                    {label: 'Listes d\'achat', icon: 'pi pi-fw pi-cog', routerLink: ['/pilotage/modelesListeAchat'], visible: this.profilService.estAutoriseTache('admin_listes_achats')},
                    {label: 'Listes de vérification', icon: 'pi pi-fw pi-cog', routerLink: ['mecanique/pilotage/listesverification'], visible: this.profilService.estAutoriseTache('temp_mec')},
                    {separator: true},
                    {label: 'Employés', icon: 'pi pi-fw pi-users', routerLink: ['/admin/employes'], visible: this.profilService.estAutoriseTache('admin_utilisateur')},
                    {separator: true},
                    {label: 'Accès', icon: 'pi pi-fw pi-users', routerLink: ['/admin/utilisateurs'], visible: this.profilService.estAutoriseTache('admin_utilisateur')},
                ]
            }
        ]; 
    }
}
