import { Component, Input } from '@angular/core';
import { EmployeEntite } from '../entites/employe';
import { MenuItem, TooltipOptions } from 'primeng/api';

@Component({
  selector: 'app-user-info',
  styles: [`
    .p-tooltip {
      width: 400px;
    }
  `],
  templateUrl: './avatar.component.html'
})
export class UserInfoComponent {
  @Input({required: true}) employe: EmployeEntite;
  @Input() loading: boolean = false;

  actions: MenuItem[] = [
    {
      label: 'Actions',
      items: [
        {
          label: 'Conversation Teams',
          icon: 'pi pi-microsoft',
          command: () => this.onConversationTeamsClick()
        }
      ]
    }
  ];

  tooltipOptions: any = {
    autoHide: false
  };

  backgroundColor() {
    const name = this.employe.prenom + this.employe.nom;
    const hash = name.split('').reduce((acc, char) => {
      return char.charCodeAt(0) + ((acc << 5) - acc);
    }, 0);
    const hue = Math.abs(hash % 360);
    const saturation = 60 + (hash % 20);
    const lightness = 80 + (hash % 10);

    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  }

  onEmailClick(email: string) {
    window.open(`mailto:${email}`, '_blank');
  }

  onConversationTeamsClick() {
    window.open(`msteams:/l/chat/0/0?users=${this.employe.courriel}`, '_blank');
  }
}