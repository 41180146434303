import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from "@angular/core"; import { Location } from "@angular/common";

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { ItemMin } from '../entites/item-min';
import { Router } from '@angular/router';
import { ReponseBase } from './reponse-base';
import { Item } from '../entites/item';
import { ReparationEntite } from '../entites/reparation';
import { Inventaire } from '../entites/inventaire';
import { HistoriqueItem } from '../entites/historiqueItem';
import { NoteEntite } from '../entites/note';
import { ValeurAttribut } from '../entites/valeur-attribut';
import { AnalyseItem } from '../entites/analyse-item';
import { RequeteRetourReparation } from './requetes/requete-retour-reparation';
import { RequeteAjusterInventaireItem } from './requetes/requete-ajuster-inventaire-item';
import { RequisitionMin } from '../entites/requisition-min';
import { Fournisseur } from '../entites/fournisseur';
import { ItemBonCommande } from '../entites/item-bon-commande';

@Injectable({ providedIn: 'root' })
export class RechercheService {

  private itemsUrl = environment.apiBaseUrl + 'recherche/items/{requete}';
  private requisitionsUrl = environment.apiBaseUrl + 'recherche/requisitions/{requete}';
  private fournisseursUrl = environment.apiBaseUrl + 'recherche/fournisseurs/{requete}';
  private bonCommandeUrl = environment.apiBaseUrl + 'recherche/bonscommande/{requete}';
  private bonCommandeCPUrl = environment.apiBaseUrl + 'recherche/bonscommandecp/{employeId}/{requete}';

  constructor(
    private http: HttpClient,
    public router: Router) { }

  public RechercherItems(requete: string): Promise<ReponseBase<ItemMin[]>> {
    var p = this.http.get<ReponseBase<ItemMin[]>>(this.itemsUrl.replace('{requete}', requete))
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public RechercherRequisitions(requete: string): Promise<ReponseBase<RequisitionMin[]>> {
    var p = this.http.get<ReponseBase<RequisitionMin[]>>(this.requisitionsUrl.replace('{requete}', requete))
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  
  public RechercherFournisseurs(requete: string): Promise<ReponseBase<Fournisseur[]>> {
    var p = this.http.get<ReponseBase<Fournisseur[]>>(this.fournisseursUrl.replace('{requete}', requete))
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public RechercherBonsCommande(requete: string): Promise<ReponseBase<ItemBonCommande[]>> {
    var p = this.http.get<ReponseBase<ItemBonCommande[]>>(this.bonCommandeUrl.replace('{requete}', requete))
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public RechercherBonsCommandeCP(requete: string, employeId: number): Promise<ReponseBase<ItemBonCommande[]>> {
    var p = this.http.get<ReponseBase<ItemBonCommande[]>>(this.bonCommandeCPUrl.replace('{requete}', requete).replace('{employeId}', employeId.toString()))
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);

      this.log(`${operation} failed: ${error.message}`);

      this.router.navigateByUrl('erreurtechnique');

      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(message);
  }
}
