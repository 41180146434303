import { Component, ViewChild, ElementRef, ɵConsole, Input } from "@angular/core"; import { Location } from "@angular/common";
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { DonneesBaseService } from '../services/donnees-base-service';
import { BaseComponent } from '../base/base-component';
import { MessageService } from 'primeng/api';
import { ProfilService } from '../services/profil-service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppMainComponent } from '../app.main.component';
import { CommandeService } from '../services/commande-service';
import { TransporteurEntite } from '../entites/transporteur';

export interface ParametresConfirmationTransporteur {
    emplacementId: number;
}

@Component({
    selector: 'app-confirmation-transporteurs',
    templateUrl: './confirmation-transporteurs.component.html'
})
export class ConfirmationTransporteurComponent extends BaseComponent {

    donnees: ParametresConfirmationTransporteur;

    transporteurs: TransporteurEntite[];
    selection: TransporteurEntite[];

    boutonConfirmerDisponible: boolean = true;

    @ViewChild('boutonConfirmer') boutonConfirmer;
    @ViewChild('boutonAnnuler') boutonAnnuler;

    constructor(
        profilService: ProfilService,
        route: ActivatedRoute, 
        routerService: Router,
        appMain: AppMainComponent, location: Location, 
        private commandeService: CommandeService,
        public messageService: MessageService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public sdb: DonneesBaseService) {
        super(sdb, profilService, route, routerService, appMain);
    }

    ngOnInit() {

        this.donnees = this.config.data;

        this.commandeService.ObtenirTransporteur(this.donnees.emplacementId).then(res => {
            if (res.estUnSucces) {

                
                this.transporteurs = res.valeur
                this.selection = res.valeur

                if (this.transporteurs.length > 0) {
                    setTimeout(() => {this.boutonConfirmer.nativeElement.focus(); }, 100);
                } else {
                    setTimeout(() => { this.boutonAnnuler.nativeElement.focus() }, 100);
                }

                this.CompleterChargement();
                
            }
        });

        

    }

    annuler() {
        this.ref.close(null);
    }

    confirmer() {

        this.ref.close(this.selection);

    }

    surChangementSelection() {
        this.boutonConfirmerDisponible = this.selection && this.selection.length > 0;
    }

}