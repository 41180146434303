<p-card *ngIf="!EstCharge()">
	<div class="fluid formgrid grid">
		<div class="flex flex-column gap-2 my-2 col-12 text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-card>
<div class="card" *ngIf="EstCharge()">
<div class="fluid formgrid grid">
    <div class="flex flex-column gap-2 my-2 col-12">
        <p-table #dt [value]="bdcs" dataKey="bonCommandeId" autoLayout="true" tableStyleClass="tableNormale"
                styleClass="p-datatable-sm p-datatable-striped" [rowHover]="true">
                <!-- <ng-template pTemplate="caption">
                    <div class="flex align-content-center justify-content-between">
                        <div>
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input #rechercheItem pInputText type="text"
                                    (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    (keyup.enter)="surEntreeRecherche()" placeholder="Recherche..." />
                            </span>
                        </div>
                        <div>
                            <h5 class="titreTableau m-0">Unités</h5>
                        </div>

                        <div>
                            <p-multiSelect [options]="colonnesDisponibles" [(ngModel)]="selectedColumns"
                                optionLabel="entete" (onChange)="surChangementColonnes($event)"
                                selectedItemsLabel="{0} colonnes sélectionnés" [style]="{minWidth: '200px'}"
                                placeholder="Colonnes...">
                            </p-multiSelect>
                        </div>
                    </div>
                </ng-template> -->
                <ng-template pTemplate="header" let-columns>
                    <tr>

                        <th pSortableColumn="numero">
                            <div class="flex justify-content-between align-items-center">
                                Numéro
                                <p-sortIcon field="numero"></p-sortIcon>
                                <p-columnFilter type="text" field="numero" display="menu" class="ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="dateAchat">
                            <div class="flex justify-content-between align-items-center">
                                Date
                                <p-sortIcon field="dateAchat"></p-sortIcon>
                                <p-columnFilter type="date" field="dateAchat" display="menu" class="ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="fournisseur.nom">
                            <div class="flex justify-content-between align-items-center">
                                Fournisseur
                                <p-sortIcon field="fournisseur.nom"></p-sortIcon>
                                <p-columnFilter type="text" field="fournisseur.nom" display="menu" class="ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="projet.nom">
                            <div class="flex justify-content-between align-items-center">
                                Projet
                                <p-sortIcon field="projet.nom"></p-sortIcon>
                                <p-columnFilter type="text" field="projet.nom" display="menu" class="ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="statut.nom">
                            <div class="flex justify-content-between align-items-center">
                                Statut
                                <p-sortIcon field="statut.nom"></p-sortIcon>
                                <p-columnFilter type="text" field="statut.nom" display="menu" class="ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                       
                        <th style="min-width: 4rem"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-bdc let-columns="columns">
                    <tr>
                        <td>{{bdc.numero}}</td>
                        <td>{{bdc.dateAchat | date:'yyyy-MM-dd'}}</td>
                        <td>{{bdc.fournisseur.nom}}</td>
                        <td>{{bdc.projet?.nom}}</td>
                        <td><span [class]="'statut ' + bdc.statut.nom.toLowerCase().replace(' ', '')">
                                {{bdc.statut.nom}}
                            </span></td>
                        <td>
                            <button pButton pRipple icon="pi pi-check"
                                class="p-button-rounded p-button-success p-button-sm mr-2"
                                (click)="surSelection(bdc)"></button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
    </div>
   
</div>
<div class="grid">
    <div class="col-12 text-right ">
    
        <button pButton pRipple label="Annuler" icon="pi pi-ban" class="p-button-text" (click)="annuler()"></button>
    </div>
</div>
</div>