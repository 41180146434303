<p-card *ngIf="!EstCharge()">
    <div class="fluid formgrid grid">
        <div class="flex flex-column gap-2 my-2 col-12 text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-card>

<div *ngIf="EstCharge()" class="fluid formgrid grid">

    <p-menu #menuContextuelDocuments popup="popup" [model]="contenuMenuContextuelDocuments">
    </p-menu>

    <div class="flex gap-4 w-full">
        <div *ngIf="showFolders">
            <p-panel [styleClass]="'h-full'">
                <button pButton type="button" icon="pi pi-plus" (click)="ajouterDocument()"
                    label="Ajouter un document" class="mb-1"></button>
                <p-tree #menudossiers [value]="typesDocuments" selectionMode="single"
                    [(selection)]="selectionTypeDocument" (onNodeSelect)="onSelectionDocument($event)"
                    (onNodeUnselect)="onDeselectionDocument($event)">
                </p-tree>
            </p-panel>
        </div>
        <div class="w-full">
            <p-dataView #dv [value]="documentsFiltres" [layout]="layout" [rows]="8" [paginator]="true"
                [sortField]="sortField" [sortOrder]="sortOrder" (onPage)="onLazyLoad($event)">
                <ng-template pTemplate="header">
                    <div class="flex flex-wrap justify-content-between mt-3 md:mt-0 gap-2">
                        <div class="flex flex-wrap gap-2">
                            <button *ngIf="!showFolders" pButton type="button" icon="pi pi-plus" (click)="ajouterDocument()"
                                label="Ajouter un document" class="mb-1"></button>
                            <p-dropdown [options]="sortOptions" [(ngModel)]="sortField" (onChange)="onSortChange($event)" />
                        </div>
                        <p-dataViewLayoutOptions [layout]="layout" />
                    </div>
                </ng-template>
                <ng-template let-docs pTemplate="grid">
                    <div *ngIf="documentsFiltres.length != 0" class="grid mt-3">
                        <p-card *ngFor="let doc of docs" class="col-12 md:col-4 lg:col-3 my-3" styleClass="h-full">
                            <div #divImage class="flex justify-content-center">
                                <div *ngIf="doc.apercu">
                                    <p-image [src]="doc.apercu" [previewImageSrc]="afficherImage(doc)" [alt]="doc.nom"
                                        [style]="{maxWidth: divImage.clientWidth + 'px', overflow: 'hidden'}"
                                        height="160" preview="true" (onShow)="checkType(doc)" />
                                </div>
                                <div *ngIf="!doc.apercu" class="flex justify-content-center align-items-center"
                                    style="width: 160px; height: 160px;">
                                    <i *ngIf="doc.typeFichier == ETypeFichier.Image" class="pi pi-exclamation-triangle"
                                        style="font-size: 40px;color: red" pTooltip="Apercu indisponible"
                                        tooltipPosition="bottom"></i>
                                    <i *ngIf="doc.typeFichier == ETypeFichier.Autre" class="pi pi-file-o"
                                        style="font-size: 60px"></i>
                                </div>
                            </div>
                            <div class="flex flex-column align-content-center justify-content-between w-full">
                                <div class="flex flex-column w-full">
                                    <h5 class="m-0 font-medium">{{doc.nom}}</h5>
                                    <div class="text-xs">{{doc.date | date:'yyyy-MM-dd'}}</div>
                                    <div class="text-xs">
                                        <i class="pi pi-folder mr-1"></i>
                                        <span class="dossierFichier">{{doc.dossier}}</span>
                                    </div>
                                </div>
                                <div class="sectionBoutonsFichier">
                                    <div class="flex gap-1">
                                        <p-button (click)="telechargerDocument(doc)" icon="pi pi-download"
                                            title="Télécharger le fichier"></p-button>
                                    </div>
                                    <div *ngIf="!readonly">
                                        <p-confirmPopup>
                                            <ng-template pTemplate="content" let-message>
                                                <div
                                                    class="flex flex-column align-items-center w-full gap-3 border-bottom-1 surface-border p-3 mb-3">
                                                    <i [class]="message.icon" class="text-6xl text-primary-500"></i>
                                                    <p>{{ message.message }}</p>
                                                </div>
                                            </ng-template>
                                        </p-confirmPopup>
                                        <p-menu #optionsmenu [model]="contenuMenuContextuelDocuments" [popup]="true"
                                            appendTo="body" />
                                        <p-button
                                            *ngIf="estAutorise('item_document_modifier') || estAutorise('item_document_supprimer')"
                                            pRipple icon="pi pi-bars" severity="secondary"
                                            (onClick)="surMenuContextuelDocuments($event, doc)" />
                                    </div>
                                </div>
                            </div>
                        </p-card>
                    </div>
                </ng-template>
                <ng-template let-docs pTemplate="list">
                    <div *ngFor="let doc of docs" class="mt-3">
                        <p-card>
                            <div class="flex justify-content-between align-items-center w-full">
                                <div class="flex align-items-center gap-1">
                                    <div class="flex justify-content-center align-items-center"
                                        style="width: 50px; height: 50px;">
                                        <p-image *ngIf="doc.apercu" [src]="doc.apercu"
                                            [previewImageSrc]="afficherImage(doc)" [alt]="doc.nom" height="50"
                                            preview="true" (onShow)="checkType(doc)" />
                                        <i *ngIf="doc.typeFichier == ETypeFichier.Image"
                                            class="pi pi-exclamation-triangle" style="font-size: 30px;color: red"
                                            pTooltip="Apercu indisponible" tooltipPosition="bottom"></i>
                                        <i *ngIf="doc.typeFichier == ETypeFichier.Autre" class="pi pi-file-o"
                                            style="font-size: 30px"></i>
                                    </div>
                                    <p-divider layout="vertical"></p-divider>
                                    <div class="flex flex-column">
                                        <h5 class="m-0 font-medium">{{doc.nom}}</h5>
                                        <div class="text-xs">{{doc.date | date:'yyyy-MM-dd'}}</div>
                                        <div class="text-xs">
                                            <i class="pi pi-folder mr-1"></i>
                                            <span class="dossierFichier">{{doc.dossier}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex gap-3 flex-column md:flex-row">
                                    <p-button (click)="telechargerDocument(doc)" icon="pi pi-download"></p-button>
                                    <div *ngIf="!readonly">
                                        <p-confirmPopup>
                                            <ng-template pTemplate="content" let-message>
                                                <div
                                                    class="flex flex-column align-items-center w-full gap-3 border-bottom-1 surface-border p-3 mb-3">
                                                    <i [class]="message.icon" class="text-6xl text-primary-500"></i>
                                                    <p>{{ message.message }}</p>
                                                </div>
                                            </ng-template>
                                        </p-confirmPopup>
                                        <p-menu #optionsmenu [model]="contenuMenuContextuelDocuments" [popup]="true"
                                            appendTo="body" />
                                        <p-button
                                            *ngIf="estAutorise('item_document_modifier') || estAutorise('item_document_supprimer')"
                                            pRipple icon="pi pi-bars" severity="secondary"
                                            (onClick)="surMenuContextuelDocuments($event, doc)" />
                                    </div>
                                </div>
                            </div>
                        </p-card>
                        <p-divider></p-divider>
                    </div>
                </ng-template>
                <ng-template pTemplate="empty">
                    <div class="flex flex-column align-items-center justify-content-center h-full"
                        style="margin-top: 50px;">
                        <i class="pi pi-file-o text-6xl text-primary-500"></i>
                        <p>Aucun document</p>
                    </div>
                </ng-template>
            </p-dataView>
        </div>
    </div>
</div>