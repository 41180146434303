import { Component, OnInit, ViewChild, Input } from "@angular/core"; import { Location } from "@angular/common";
import { MessageService, LazyLoadEvent, ConfirmationService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Router, ActivatedRoute } from '@angular/router';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { ProfilService } from 'src/app/services/profil-service';
import { AppMainComponent } from 'src/app/app.main.component';
import { BaseComponent } from 'src/app/base/base-component';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { ListeVerificationService } from 'src/app/services/liste-verification-service';

@Component({
    templateUrl: './ajout-liste-verification.component.html',
    providers: [MessageService, ConfirmationService, DialogService]
})
export class AjoutListeVerificationComponent extends BaseComponent implements OnInit {

    nom: string;
    description: string;

    submitted:boolean = false;

    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute, 
		appMain: AppMainComponent, location: Location, 
        private breadcrumbService: BreadcrumbService,
        private listeVerificationService: ListeVerificationService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        public dialogService: DialogService,
        public routerService: Router ) {

        super(sdb, profilService, route, routerService, appMain);

        this.breadcrumbService.setItems([
            { label: 'Ajout d\'une liste de vérification' }
        ]);
    }

    ngOnInit() {
        this.CompleterChargement();
    }

    CreerModele() {

        

        this.submitted = true;
        this.messageService.clear();

        if (this.valider()) {

            this.DemarrerChargement();
            
            this.listeVerificationService.CreerListe({
                nom: this.nom,
                description: this.description
            }).then(x => {
                if (x.estUnSucces) {
                    this.routerService.navigateByUrl('mecanique/pilotage/listeverification/' + x.valeur);
                } else {
                    x.messages.forEach(x => {
                        this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description, sticky: true });
                    });

                    this.CompleterChargement();
                }
            });
        }
    }

    valider(): boolean {


        if (!this.nom) {
            return false;
        }
            
        return true;
    }
}
