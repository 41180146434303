<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<p-card *ngIf="!EstCharge()">
    <div class="fluid formgrid grid">
        <div class="flex flex-column gap-2 my-2 col-12 text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-card>

<div *ngIf="EstCharge() && !modePopup" class="grid">
    <div class="col-12">
        <div class="card">
            <h5>{{nonConformite.numero}}
                <span [class]="'statut ' + nonConformite.statut.nom.toLowerCase()">
                    {{nonConformite.statut.nom}}
                </span>
            </h5>

            <p-menubar [model]="menu" [autoDisplay]="false">
                <p-button *ngIf="modeConsultation()" (click)="Modifier()" icon="pi pi-pencil"
                    styleClass="p-button-primary"></p-button>
                <p-button *ngIf="modeModification()" (onClick)="enregistrerModifications()" label icon="pi pi-check"
                    styleClass="p-button-success mr-2">
                </p-button>
                <p-button *ngIf="modeModification()" (onClick)="Consulter()" icon="pi pi-times"
                    styleClass="p-button-secondary"></p-button>
            </p-menubar>
        </div>
    </div>
</div>

<div *ngIf="EstCharge()" class="grid">
    <div class="col-12">
        <div class="card">
            <p-tabView #tabView [(activeIndex)]="tabIndex" (onChange)="surChangementOnglet($event)">

                <p-messages *ngIf="this.nonConformite.actionCorrectives.length > 0" severity="info">
                    <ng-template pTemplate>
                        <div class="flex justify-content-between align-items-center" style="width: 100%;">
                            <div class="ml-2">Cette non-conformité a reçu une demande d'action corrective.</div>
                            <button pButton label="Voir l'action corrective" icon="pi pi-eye" class="ml-2"
                                (click)="voirActionCorrective(nonConformite.actionCorrectives[0].actionCorrectiveId)"></button>
                        </div>
                    </ng-template>
                </p-messages>

                <!-- Informations générales -->
                <p-tabPanel header="Informations générales" leftIcon="pi pi-info-circle">
                    <div class="fluid formgrid grid">
                        <div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3">
                            <label for="type">Type<span class="requis">*</span></label>
                            <p-dropdown [options]="types" [(ngModel)]="nonConformite.type" optionValue="id"
                                optionLabel="nom" [disabled]="modeConsultation()" [required]="true">
                            </p-dropdown>
                        </div>
                        <div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3">
                            <label for="codeProjet">Projet<span class="requis">*</span></label>
                            <p-dropdown [options]="codeProjets" [(ngModel)]="nonConformite.codeProjet.codeProjetId"
                                optionValue="codeProjetId" optionLabel="nom" [disabled]="modeConsultation()"
                                [required]="true">
                            </p-dropdown>
                        </div>
                        <div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3">
                            <label for="dateEmission">Date d'émission<span class="requis">*</span></label>
                            <p-calendar *ngIf="modeModification()" [(ngModel)]="nonConformite.dateEmission"
                                dateFormat="yy-mm-dd" [showIcon]="true" [required]="true"></p-calendar>
                            <input *ngIf="modeConsultation()" id="dateEmission" type="text" pInputText
                                value="{{nonConformite.dateEmission | date:'yyyy-MM-dd'}}" [readOnly]="true">
                        </div>
                        <div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3">
                            <label for="dateDetection">Date de détection</label>
                            <p-calendar *ngIf="modeModification()" [(ngModel)]="nonConformite.dateDetection"
                                dateFormat="yy-mm-dd" [showIcon]="true"></p-calendar>
                            <input *ngIf="modeConsultation()" id="dateDetection" type="text" pInputText
                                value="{{nonConformite.dateDetection | date:'yyyy-MM-dd'}}" [readOnly]="true">
                        </div>
                    </div>
                    <div class="fluid formgrid grid">
                        <div class="flex flex-column gap-2 my-2 col-12 md:col-6">
                            <label for="entrepreneur">Créateur de la non-conformité</label>
                            <input id="entrepreneur" pInputText [ngModel]="nonConformite.employe | nomEmploye" readonly>
                        </div>
                    </div>

                    <p-divider align="left" type="solid">
                        <b class="text-xl">Intervenants</b>
                    </p-divider>
                    <div class="fluid formgrid grid">
                        <div class="flex flex-column gap-2 my-2 col-12 md:col-4">
                            <div class="flex flex-column gap-2 my-2 col-12">
                                <label for="entrepreneur">Entrepreneur</label>
                                <input id="entrepreneur" pInputText [(ngModel)]="nonConformite.entrepreneur"
                                    [readonly]="modeConsultation()" rows="5">
                            </div>
                            <div class="flex flex-column gap-2 my-2 col-12">
                                <label for="responsableQualite">Responsable qualité</label>
                                <input id="responsableQualite" pInputText [(ngModel)]="nonConformite.responsableQualite"
                                    [readonly]="modeConsultation()" rows="5">
                            </div>
                        </div>

                        <div class="flex flex-column gap-2 my-2 col-12 md:col-4">
                            <div class="flex flex-column gap-2 my-2 col-12">
                                <label for="sousTraitant">Sous-traitant</label>
                                <input id="sousTraitant" pInputText [(ngModel)]="nonConformite.sousTraitant.nom"
                                    [readonly]="modeConsultation()" rows="5">
                            </div>
                            <div class="flex flex-column gap-2 my-2 col-12">
                                <label for="representant">Représentant {{nonConformite.sousTraitant.nom}}</label>
                                <input id="representant" pInputText [(ngModel)]="nonConformite.representant"
                                    [readonly]="modeConsultation()" rows="5">
                            </div>
                        </div>

                        <div class="flex flex-column gap-2 my-2 col-12 md:col-4">
                            <div class="flex flex-column gap-2 my-2 col-12">
                                <label for="client">Client</label>
                                <input id="client" pInputText [(ngModel)]="nonConformite.client"
                                    [readonly]="modeConsultation()" rows="5">
                            </div>
                            <div class="flex flex-column gap-2 my-2 col-12">
                                <label for="representantClient">Représentant {{nonConformite.client}}</label>
                                <input id="representantClient" pInputText [(ngModel)]="nonConformite.representantClient"
                                    [readonly]="modeConsultation()" rows="5">
                            </div>
                        </div>
                    </div>
                    <p-divider align="left" type="solid">
                        <b class="text-xl">Informations de la non-conformité</b>
                    </p-divider>
                    <div class="fluid formgrid grid">
                        <div class="flex flex-column gap-2 my-2 col-12">
                            <label for="titre">Titre<span class="requis">*</span></label>
                            <input id="titre" type="text" pInputText [(ngModel)]="nonConformite.titre"
                                [readonly]="modeConsultation()" [required]="true">
                        </div>
                        <div class="flex flex-column gap-2 my-2 col-12 md:col-6">
                            <label for="description">Description</label>
                            <textarea id="description" pInputTextarea [(ngModel)]="nonConformite.description"
                                [readonly]="modeConsultation()" rows="5"></textarea>
                        </div>
                        <div class="flex flex-column gap-2 my-2 col-12 md:col-6">
                            <label for="causesPossibles">Causes possibles</label>
                            <textarea id="causesPossibles" pInputTextarea [(ngModel)]="nonConformite.causesPossibles"
                                [readonly]="modeConsultation()" rows="5"></textarea>
                        </div>
                    </div>

                    <div class="fluid formgrid grid">
                        <div class="flex flex-column gap-4 my-2 col-12 md:col-4">
                            <label for="typeNonConformite">Type de non-conformité</label>
                            <input id="typeNonConformite" pInputText [(ngModel)]="nonConformite.objet"
                                [readonly]="modeConsultation()" rows="5">
                        </div>
                        <div class="flex flex-column gap-4 my-2 col-12 md:col-4">
                            <label for="responsable">Responsable</label>
                            <input id="responsable" pInputText [(ngModel)]="nonConformite.responsable"
                                [readonly]="modeConsultation()" rows="5">
                        </div>
                        <div class="flex flex-column gap-4 my-2 col-12 md:col-4">
                            <label for="document">Documents</label>
                            <p-floatLabel>
                                <input id="noArticle" type="text" pInputText [(ngModel)]="nonConformite.noArticle"
                                    [readonly]="modeConsultation()" />
                                <label for="noArticle">N° Article</label>
                            </p-floatLabel>
                            <p-floatLabel>
                                <input id="noPlan" type="text" pInputText [(ngModel)]="nonConformite.noPlan"
                                    [readonly]="modeConsultation()" />
                                <label for="noPlan">N° Plan</label>
                            </p-floatLabel>
                            <p-floatLabel>
                                <input id="noBonCommande" type="text" pInputText
                                    [(ngModel)]="nonConformite.noBonCommande" [readonly]="modeConsultation()" />
                                <label for="noBonCommande">N° Bon de commande</label>
                            </p-floatLabel>
                            <p-floatLabel>
                                <input id="noPiece" type="text" pInputText [(ngModel)]="nonConformite.noPiece"
                                    [readonly]="modeConsultation()" />
                                <label for="noPiece">N° Pièce</label>
                            </p-floatLabel>
                        </div>
                    </div>

                </p-tabPanel>

                <p-tabPanel [disabled]="nonConformite.statut.statutId != EStatuts.Traite" header="Traitement" 
                leftIcon="pi pi-list-check" 
                [tooltip]="this.nonConformite.statut.statutId == EStatuts.Nouveau ? 'Cette non-conformité n\'est pas encore traitée. Veuillez la complétée avec le menu actions.' : ''" 
                tooltipPosition="top" >
                    <ng-template pTemplate="content">
                        <app-approbation-non-conformite [nonConformite]="nonConformite" [isModeConsultation]="modeConsultation()" (onViewDocuments)="VoirDocumentsTraitement()"></app-approbation-non-conformite>
                    </ng-template>
                </p-tabPanel>

                <p-tabPanel header="Documents" leftIcon="pi pi-file-o">
                    <ng-template pTemplate="content">
                        <app-document [typeLienDocument]="ETypeLienDocument.NonConformite" [idLien]="nonConformiteId" [selectedTreeNode]="'action_corrective'"
                        [numero]="nonConformite.numero" [readonly]="modePopup"></app-document>
                    </ng-template>
                </p-tabPanel>
            </p-tabView>
        </div>
    </div>
</div>