<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-menu #menuContextuelItemCommande popup="popup" [model]="menuContextuelEntrees"></p-menu>

<p-card *ngIf="!EstCharge()">
	<div class="fluid formgrid grid">
		<div class="flex flex-column gap-2 my-2 col-12 text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-card>

<div *ngIf="EstCharge()" class="grid">
	<div class="col-12">
		<div class="card">
			<h5>{{listeVerification.nom}}
			</h5>

			<p-menubar autoDisplay="false" [model]="menu">
				<p-button *ngIf="estAutorise('temp_mec') && modeConsultation() && estModifiable"
					(click)="btnModifier_click()" icon="pi pi-pencil" styleClass="p-button-primary"></p-button>
				<p-button *ngIf="modeAjoutModification()" (onClick)="enregistrerModifications()" label
					icon="pi pi-check" styleClass="p-button-success mr-2">
				</p-button>
				<p-button *ngIf="modeAjoutModification()" (onClick)="annulerModification($event)" icon="pi pi-times"
					styleClass="p-button-secondary"></p-button>
			</p-menubar>
		</div>
	</div>
</div>

<div *ngIf="EstCharge()" class="grid">
	<div class="col-12">
		<div class="card">
			<p-tabView orientation="left">
				<p-tabPanel header="Informations générales" leftIcon="pi pi-info-circle">
					<div class="fluid formgrid grid">
						<div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3">
							<label for="nom">Nom</label>
							<input id="nom" type="text" pInputText [readonly]="modeConsultation()"
								[(ngModel)]="listeVerification.nom">
						</div>
						<div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-9">
							<label for="description">Description</label>
							<input id="description" type="text" pInputText [readonly]="modeConsultation()"
								[(ngModel)]="listeVerification.description">
						</div>
					</div>

					<p-table #dt [value]="listeVerification.items" [columns]="selectedColumns"
						dataKey="itemListeVerificationId" tableStyleClass="tableNormale"
						[(selection)]="selectionMultipleEntree" (onRowSelect)="surChangementSelection()"
						(onRowUnselect)="surChangementSelection()" (onHeaderCheckboxToggle)="surChangementSelection()"
						 [rowHover]="true" [rows]="25" [paginator]="true"
						[filterDelay]="0" [globalFilterFields]="['description','categorie.code','categorie.description']">
						<ng-template pTemplate="caption">
							<div class="flex align-content-center justify-content-between">
								<div>
									<span class="p-input-icon-left">
										<i class="pi pi-search"></i>
										<input pInputText type="text"
											(input)="dt.filterGlobal($event.target.value, 'contains')"
											placeholder="Recherche..." />
									</span>

									<button *ngIf="estModifiable && !modeApercu && modeConsultation()" pButton pRipple
										icon="pi pi-plus" class="p-button-success mr-2 ml-4 mb-2 boutonGrille"
										(click)="surAjoutItem()"></button>
								</div>
								<h5 class="titreTableau m-0">Liste des items</h5>
								<div>
									<p-multiSelect disabled="true" [style]="{minWidth: '200px'}"
										placeholder="Colonnes..."></p-multiSelect>
								</div>
							</div>
						</ng-template>

						<ng-template pTemplate="header">
							<tr>
								<th style="min-width: 3rem; width: 7rem;">
									<p-tableHeaderCheckbox></p-tableHeaderCheckbox>
								</th>
								<th pSortableColumn="description">
									<div class="flex justify-content-between align-items-center">
										Description
										<p-sortIcon field="description"></p-sortIcon>
										<p-columnFilter type="text" field="description" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="categorie.description">
									<div class="flex justify-content-between align-items-center">
										Catégorie
										<p-sortIcon field="categorie.description"></p-sortIcon>
										<p-columnFilter type="text" field="categorie.description" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th *ngIf="modeConsultation()" style="min-width: 8rem; width: 8rem;"></th>
								<th *ngIf="!modeConsultation()" style="min-width: 20rem; width: 20rem;"></th>

							</tr>
						</ng-template>
						<ng-template pTemplate="body" let-entree>
							<tr>
								<td>
									<p-tableCheckbox [value]="entree"></p-tableCheckbox>
								</td>
								<td>{{entree.description}}</td>
								<td>{{entree.categorie?.code}} {{entree.categorie?.description}}</td>
								<td>

									<button pButton pRipple icon="pi pi-bars" *ngIf="modeConsultation()"
										class="p-button-secondary mr-2 boutonGrille"
										(click)="surMenuContextuelItem($event, entree)"></button>
									<button *ngIf="!modeConsultation()" pButton pRipple icon="pi pi-angle-double-down"
										[disabled]="listeVerification.items.indexOf(entree) == listeVerification.items.length - 1"
										class="p-button-secondary mr-2 boutonGrille"
										(click)="descendreEtapeComplet($event, entree)"></button>
									<button *ngIf="!modeConsultation()" pButton pRipple icon="pi pi-chevron-down"
										[disabled]="listeVerification.items.indexOf(entree) == listeVerification.items.length - 1"
										class="p-button-secondary mr-2 boutonGrille"
										(click)="descendreEtape($event, entree)"></button>
									<button *ngIf="!modeConsultation()" pButton pRipple icon="pi pi-chevron-up"
										[disabled]="listeVerification.items.indexOf(entree) == 0"
										class="p-button-secondary mr-2 boutonGrille"
										(click)="monterEtape($event, entree)"></button>
									<button *ngIf="!modeConsultation()" pButton pRipple icon="pi pi-angle-double-up"
										[disabled]="listeVerification.items.indexOf(entree) == 0"
										class="p-button-secondary mr-2 boutonGrille"
										(click)="monterEtapeComplet($event, entree)"></button>
								</td>

							</tr>
						</ng-template>
						<ng-template pTemplate="emptymessage">
							<tr>
								<td colspan="3">Aucun item.</td>
							</tr>
						</ng-template>
					</p-table>
				</p-tabPanel>
			</p-tabView>
		</div>
	</div>


</div>