<p-toast></p-toast>
<p-confirmDialog #confirmDialog>
    <ng-template pTemplate="headless" let-message>
        <div class="border-round p-3">
            <span>{{ message.message }}</span>
            <div class="flex align-items-center gap-2 mt-3">
                <p-splitButton *ngIf="!config.data.modeApprobation" label="Envoyer" [model]="splitBtnItems" (onClick)="Accept(true)" />
                <p-button *ngIf="config.data.modeApprobation" (onClick)="Accept(false)" label="Confirmer" />
                <p-button (onClick)="confirmDialog.close($event)" label="Annuler" [outlined]="true" />
            </div>
        </div>
    </ng-template>
</p-confirmDialog>

<p-card *ngIf="!EstCharge()">
    <div class="fluid formgrid grid">
        <div class="flex flex-column gap-2 col-12 text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-card>

<div *ngIf="EstCharge()">
    <div class="fluid formgrid grid mb-5">
        <div class="flex flex-column gap-2 col-12 md:col-6 p-lg-4">
            <label for="suiviCouts">Faire un suivi des couts?</label>
            <p-toggleButton [(ngModel)]="bonTravail.indSuiviCouts" onLabel="Oui" offLabel="Non" />
        </div>
    </div>

    <p-table #dtTravail [value]="bonTravail.etapes" dataKey="etapeBonTravailId"
        tableStyleClass="tableNormale" autoLayout="true"
        [expandedRowKeys]="lignesOuvertes" 
        [rowHover]="true" [reorderableColumns]="false"
        [scrollable]="true" [sortField]="'ordre'"
        [globalFilterFields]="['description','note','categorie.code','categorie.description']">
        <ng-template pTemplate="caption">
            <div class="flex flex-wrap align-content-center justify-content-between">
                <div>
                    <span class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="dtTravail.filterGlobal($event.target.value, 'contains')"
                            placeholder="Recherche..." />
                    </span>
                </div>
                <h5 class="titreTableau m-0">Travaux</h5>
                <div>&nbsp;</div>
            </div>
        </ng-template>

        <ng-template pTemplate="header" let-columns>
            <tr>
                <th *ngIf="bonTravail.indSoumission" style="min-width: 3rem; width: 3rem;"></th>
                <th pSortableColumn="ordre">
                    <div class="flex justify-content-between align-items-center">
                        #
                        <p-sortIcon field="ordre"></p-sortIcon>
                        <p-columnFilter type="text" field="ordre" display="menu" class="ml-auto">
                        </p-columnFilter>
                    </div>
                </th>
                <th pSortableColumn="nom">
                    <div class="flex justify-content-between align-items-center">
                        Type
                        <p-sortIcon field="nom"></p-sortIcon>
                        <p-columnFilter type="text" field="nom" display="menu" class="ml-auto">
                        </p-columnFilter>
                    </div>
                </th>
                <th pSortableColumn="description">
                    <div class="flex justify-content-between align-items-center">
                        Catégorie
                        <p-sortIcon field="description"></p-sortIcon>
                        <p-columnFilter type="text" field="description" display="menu" class="ml-auto">
                        </p-columnFilter>
                    </div>
                </th>
                <th pSortableColumn="description">
                    <div class="flex justify-content-between align-items-center">
                        Description
                        <p-sortIcon field="description"></p-sortIcon>
                        <p-columnFilter type="text" field="description" display="menu" class="ml-auto">
                        </p-columnFilter>
                    </div>
                </th>
                <th pSortableColumn="note">
                    <div class="flex justify-content-between align-items-center">
                        Note
                        <p-sortIcon field="note"></p-sortIcon>
                        <p-columnFilter type="text" field="note" display="menu" class="ml-auto">
                        </p-columnFilter>
                    </div>
                </th>
                <th *ngIf="!bonTravail.indSoumission" pSortableColumn="fait">
                    <div class="flex justify-content-between align-items-center">
                        Fait
                        <p-sortIcon field="fait"></p-sortIcon>
                        <p-columnFilter type="text" field="fait" display="menu" class="ml-auto">
                        </p-columnFilter>
                    </div>
                </th>
                <th *ngIf="!bonTravail.indSoumission" pSortableColumn="fait">
                    <div class="flex justify-content-between align-items-center">
                        Par
                        <p-sortIcon field="fait"></p-sortIcon>
                        <p-columnFilter type="text" field="fait" display="menu" class="ml-auto">
                        </p-columnFilter>
                    </div>
                </th>
                <th style="min-width: 7rem; width: 7rem;"></th>
                <th *ngIf="bonTravail.indSoumission" style="width: 8rem;">
                    A Faire
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-entree let-columns="columns" let-index="rowIndex" let-expanded="expanded">
            <tr [pReorderableRow]="index">
                <td *ngIf="bonTravail.indSoumission" style="min-width: 3rem; width: 3rem;">
                    <p-button *ngIf="entree.optionEtapeBonTravails" type="button" pRipple [pRowToggler]="entree"
                        [text]="true" [rounded]="true" [plain]="true"
                        [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'" />
                </td>
                <td>{{entree.ordre}}</td>
                <td>{{entree.typeEtapeBonTravail?.code}}</td>
                <td>{{entree.categorie | categorieEntretien}}</td>
                <td>{{entree.description}}</td>
                <td>{{entree.note}}</td>
                <td *ngIf="!bonTravail.indSoumission">
                    <p-checkbox [disabled]="true" [(ngModel)]="entree.indComplete"
                        [binary]="true"></p-checkbox>
                </td>
                <td *ngIf="!bonTravail.indSoumission">
                    <span>{{entree.mecanicien | nomEmploye}}</span>
                </td>
                <td>
                    <button pButton pRipple icon="pi pi-bars" class="p-button-secondary mr-2 boutonGrille"
                        (click)="surMenuContextuelEtape($event, entree)"></button>
                </td>
                <td *ngIf="bonTravail.indSoumission">
                    <p-checkbox [(ngModel)]="entree.indAFaire" [binary]="true" (onChange)="CalculTotal()"></p-checkbox>
                </td>
            </tr>
        </ng-template>
        <ng-template *ngIf="bonTravail.indSoumission" pTemplate="rowexpansion" let-etape>
            <tr>
                <td [attr.colspan]="8" class="pl-0 pr-0"
                    style="padding-bottom: 0rem !important; padding-top: 0rem !important;">
                    <div class="flex justify-content-between border-bottom-1">
                        <table class="p-datatable-header py-0">
                            <tr>
                                <th>
                                    <span class="font-bold">Options</span>
                                </th>
                            </tr>
                        </table>
                        <div class="flex-grow-1">
                            <option-etape-bon-travail [options]="etape.optionEtapeBonTravails"
                                [(optionSelectionnee)]="etape.optionEtapeBonTravailIdTemporaire" [readonly]="true" [modifySelection]="true"
                                [disabled]="!etape.indAFaire" (onChange)="CalculTotal()"></option-etape-bon-travail>
                        </div>
                    </div>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="footer">
            <tr>
                <th [attr.colspan]="6"></th>
                <th alignFrozen="right" pFrozenColumn [frozen]="true" class="p-datatable-header">
                    Montant Total
                </th>
                <th alignFrozen="right" pFrozenColumn [frozen]="true" class="p-datatable-header">
                    Heures Total
                </th>
            </tr>
            <tr>
                <th [attr.colspan]="6"></th>
                <td alignFrozen="right" pFrozenColumn [frozen]="true">
                    {{bonTravail.coutReelPieces | number : '1.2-2'}} $
                </td>
                <td alignFrozen="right" pFrozenColumn [frozen]="true">
                    {{bonTravail.coutReelHeures}} Heures
                </td>
            </tr>
        </ng-template>
    </p-table>

    <div class="flex gap-1 justify-content-end mt-5">
        <p-button label="Confirmer" icon="pi pi-check" (onClick)="Confirmer()"></p-button>
        <p-button label="Annuler" [text]="true" icon="pi pi-times" (onClick)="Annuler()"></p-button>
    </div>
</div>