import {RouterModule} from '@angular/router';
import {NgModule} from "@angular/core"; import { Location } from "@angular/common";
import {AccueilComponent} from './pages/commun/accueil.component';
import {AppMainComponent} from './app.main.component';
import {AppNotfoundComponent} from './pages/commun/app.notfound.component';
import {AppErrorComponent} from './pages/commun/app.error.component';
import {AppAccessdeniedComponent} from './pages/commun/app.accessdenied.component';
import {AppLoginComponent} from './pages/commun/app.login.component';
import {ItemsComponent} from './pages/items/items.component';
import {DetailItemComponent} from './pages/items/detail-item.component';
import { RequisitionsComponent } from './pages/requisitions/requisitions.component';
import { DetailRequisitionComponent } from './pages/requisitions/detail-requisition.component';
import { FournisseursComponent } from './pages/achats/fournisseurs.component';
import { DetailFournisseurComponent } from './pages/achats/detail-fournisseur.component';
import { ModelesListeAchatComponent } from './pages/pilotage/modeles-liste-achat.component';
import { SaisirListeAchatEtape1Component } from './pages/achats/saisir-liste-achat-e1.component';
import { SaisirListeAchatComponent } from './pages/achats/saisir-liste-achat.component';
import { SaisirListeAchatEtape2Component } from './pages/achats/saisir-liste-achat-e2.component';
import { SaisirListeAchatEtape3Component } from './pages/achats/saisir-liste-achat-e3.component';
import { SaisirListeAchatEtape4Component } from './pages/achats/saisir-liste-achat-e4.component';
import { ApprobationListeAchatComponent } from './pages/achats/approbation-liste-achat.component';
import { DetailApprobationListeAchatComponent } from './pages/achats/detail-approbation-liste-achat.component';
import { AchatsComponent } from './pages/achats/achats.component';
import { DetailAchatComponent } from './pages/achats/detail-achat.component';
import { CategoriesComponent } from './pages/pilotage/categories.component';
import { AjoutAchatComponent } from './pages/achats/ajout-achat.component';
import { DetailCategorieComponent } from './pages/pilotage/detail-categorie.component';
import { AjoutRequisitionComponent } from './pages/requisitions/ajout-requisition.component';
import { DetailCommandeComponent } from './pages/requisitions/detail-commande.component';
import { CommandessComponent } from './pages/requisitions/commandes.component';
import { ReparationsComponent } from './pages/items/reparations.component';

import { AuthGuard } from '@auth0/auth0-angular';
import { PrecommandesComponent } from './pages/requisitions/precommandes.component';
import { DetailPrecommandeComponent } from './pages/requisitions/detail-precommande.component';
import { DetailApprobationPrecommandeComponent } from './pages/requisitions/detail-approbation-precommande.component';
import { ApprobationPrecommandesComponent } from './pages/requisitions/approbation-precommandes.component';
import { EmplacementsComponent } from './pages/pilotage/emplacements.component';
import { DetailEmplacementComponent } from './pages/pilotage/detail-emplacement.component';
import { ResultatRechercheComponent } from './pages/commun/resultat-recherche.component';
import { AjoutModeleListeAchatComponent } from './pages/pilotage/ajout-modele-liste-achat.component';
import { DetailModeleListeAchatComponent } from './pages/pilotage/detail-modele-liste-achat.component';
import { AttributsComponent } from './pages/pilotage/attributs.component';
import { DetailAttributComponent } from './pages/pilotage/detail-attribut.component';
import { GarantiesComponent } from './pages/garanties/garanties.component';
import { RapportUtilisationEquipementComponent } from './pages/rapports/rapport-utilisation-equipement.component';
import { BoitesOutilComponent } from './pages/pilotage/boites-outil.component';
import { DetailBoiteOutilComponent } from './pages/pilotage/detail-boite-outil.component';
import { SoumissionsComponent } from './pages/soumissions/soumissions.component';
import { DetailSoumissionComponent } from './pages/soumissions/detail-soumission.component';
import { AjoutSoumissionComponent } from './pages/soumissions/ajout-soumission.component';
import { CreerSerieSoumissionsComponent } from './pages/soumissions/creer-serie.component';
import { AjoutFournisseurComponent } from './pages/achats/ajout-fournisseur.component';
import { DetailSerieComponent } from './pages/soumissions/detail-serie.component';
import { EnvoiCourrielComponent } from './pages/soumissions/envoi-courriel.component';
import { LocationsComponent } from './pages/locations/locations.component';
import { DetailLocationComponent } from './pages/locations/detail-location.component';
import { ArchiverEmplacementComponent } from './pages/pilotage/archiver-emplacement.component';
import { RapportListeEquipementComponent } from './pages/rapports/rapport-liste-equipement.component';
import { RetourComponent } from './pages/requisitions/retour.component';
import { AjoutDocumentComponent } from './pages/documents/ajout-document.component';
import { UtilisateursComponent } from './pages/utilisateurs/utilisateurs.component';
import { RapportEntreeSortieComponent } from './pages/rapports/rapport-entree-sortie.component';
import { HeuresComponent } from './pages/rapports/heures.component';
import { TransfertPrixComponent } from './pages/soumissions/transfert-prix.component';
import { DetailEmployeComponent } from './pages/admin/detail-employe.component';
import { EmployesComponent } from './pages/admin/employes.component';
import { BonsTravailComponent } from './pages/mecanique/bonsTravail/bons-travail.component';
import { DetailBonTravailComponent } from './pages/mecanique/bonsTravail/detail-bons-travail.component';
import { AjoutBonTravailComponent } from './pages/mecanique/bonsTravail/ajout-bon-travail.component';
import { DetailEntretienPreventifComponent } from './pages/mecanique/entretiens/detail-entretien-preventif.component';
import { SaisieHeuresComponent } from './pages/mecanique/heures/saisie-heures.component';
import { NotesComponent } from './pages/mecanique/notes/notes.component';
import { PlanificationComponent } from './pages/mecanique/planification/planification.component';
import { DetailListeVerificationComponent } from './pages/mecanique/pilotage/detail-liste-verification.component';
import { ListesVerificationComponent } from './pages/mecanique/pilotage/listes-verification.component';
import { AjoutListeVerificationComponent } from './pages/mecanique/pilotage/ajout-liste-verification.component';
import { DetailCommandeDepartementComponent } from './pages/requisitions/detail-commande-departement.component';
import { ApprobationHeuresComponent } from './pages/mecanique/heures/approbation-heures.component';
import { AiguillageHeuresComponent } from './pages/mecanique/heures/heures.component';
import { ApprobationKmsComponent } from './pages/mecanique/heures/approbation-kms.component';
import { SaisieKmsComponent } from './pages/mecanique/heures/saisie-kms.component';
import { SaisieHeuresKmsComponent } from './pages/mecanique/heures/saisie-heures-kms.component';
import { RapportEntretiensPEPComponent } from './pages/rapports/rapport-entretiens-pep.component';
import { RapportEntretiensSAAQComponent } from './pages/rapports/rapport-entretiens-saaq.component';
import { DetailCommandeTousComponent } from './pages/requisitions/detail-commande-tous.component';
import { NonConformiteComponent } from './pages/assurancequalite/non-conformite.component';
import { AjoutNonConformiteComponent } from './pages/assurancequalite/ajout-non-conformite.component';
import { DetailNonConformiteComponent } from './pages/assurancequalite/detail-non-conformite.component';
import { ActionCorrectiveComponent } from './pages/assurancequalite/action-corrective.component';
import { AjoutActionCorrectiveComponent } from './pages/assurancequalite/ajout-action-corrective.component';
import { DetailActionCorrectiveComponent } from './pages/assurancequalite/detail-action-corrective.component';
import { RapportHeuresLocationComponent } from './pages/rapports/rapport-heure-location.component';
import { PendingChangesGuard } from './services/pending-changes.guard';
import { AnalyseBonTravailComponent } from './pages/mecanique/bonsTravail/analyse-bontravail.component';
import { DetailAnalyseBonTravailComponent } from './pages/mecanique/bonsTravail/detail-analyse-bon-travail.component';

@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: '', component: AppMainComponent , canActivate: [AuthGuard] ,
                children: [
                    {path: '', component: AccueilComponent},
                    {path: 'recherche', component: ResultatRechercheComponent},
                    {path: 'items', component: ItemsComponent},
                    {path: 'items/reparations', component: ReparationsComponent},
                    {path: 'items/archive', component: ItemsComponent, data: {statutId:2}},
                    {path: 'items/incomplet', component: ItemsComponent, data: {statutId:15}},
                    { path: 'item/:id', component: DetailItemComponent },
                    { path: 'garanties', component: GarantiesComponent },
                    {path: 'requisitions', component: RequisitionsComponent},
                    {path: 'requisition/nouveau', component: AjoutRequisitionComponent},
                    { path: 'requisition/:id', component: DetailRequisitionComponent },
                    { path: 'retour', component: RetourComponent },
                    {path: 'precommandes', component: PrecommandesComponent},
                    { path: 'precommande/:id', component: DetailPrecommandeComponent },
                    {path: 'approbationprecommandes', component: ApprobationPrecommandesComponent},
                    { path: 'approbationprecommande/:id', component: DetailApprobationPrecommandeComponent },
                    {path: 'commandes', component: CommandessComponent},
                    {path: 'commandescomplet', component: DetailCommandeTousComponent},
                    { path: 'commande/departement/:id', component: DetailCommandeDepartementComponent },
                    { path: 'commande/:id', component: DetailCommandeComponent },
                    { path: 'chargement/:id', component: DetailCommandeComponent },
                    {path: 'fournisseurs', component: FournisseursComponent},
                    {path: 'fournisseur/ajout', component: AjoutFournisseurComponent},
                    {path: 'fournisseur/:id', component: DetailFournisseurComponent},
                    {path: 'achats', component: AchatsComponent},
                    {path: 'achat/nouveau', component: AjoutAchatComponent},
                    {path: 'achat/:id', component: DetailAchatComponent},
                    {path: 'listeachat', component: SaisirListeAchatComponent, children:[
                        {path:'', redirectTo: 'etape1', pathMatch: 'full'},
                        {path: 'etape1', component: SaisirListeAchatEtape1Component},
                        {path: 'etape2', component: SaisirListeAchatEtape2Component},
                        {path: 'etape3', component: SaisirListeAchatEtape3Component},
                        {path: 'etape4', component: SaisirListeAchatEtape4Component}
                    ]},
                    {path: 'approbationlisteachat', component: ApprobationListeAchatComponent},
                    {path: 'approbationlisteachat/:id', component: DetailApprobationListeAchatComponent},
                    {path: 'soumissions', component: SoumissionsComponent},
                    {path: 'soumission/nouveau', component: AjoutSoumissionComponent},
                    {path: 'soumission/courriel', component: EnvoiCourrielComponent},
                    {path: 'soumission/courriel/:soumissionId', component: EnvoiCourrielComponent},
                    {path: 'soumission/serie', component: CreerSerieSoumissionsComponent},
                    {path: 'soumission/serie/:dateDebut/:dateFin', component: DetailSerieComponent},
                    {path: 'soumission/transfertprix', component: TransfertPrixComponent},
                    {path: 'soumission/transfertprix/:soumissionId', component: TransfertPrixComponent},
                    {path: 'soumission/:id', component: DetailSoumissionComponent},
                    {path: 'locations', component: LocationsComponent},
                    // {path: 'location/nouveau', component: AjoutLocationComponent},
                    {path: 'location/:id', component: DetailLocationComponent},
                    {path: 'pilotage/modelesListeAchat', component: ModelesListeAchatComponent},
                    {path: 'pilotage/modeleListeAchat/nouveau', component: AjoutModeleListeAchatComponent},
                    {path: 'pilotage/modeleListeAchat/:id', component: DetailModeleListeAchatComponent},
                    {path: 'pilotage/emplacements', component: EmplacementsComponent},
                    {path: 'pilotage/emplacement/:id', component: DetailEmplacementComponent},
                    {path: 'pilotage/emplacement/archiver/:id', component: ArchiverEmplacementComponent},
                    {path: 'pilotage/categories', component: CategoriesComponent},
                    {path: 'pilotage/categorie/:id', component: DetailCategorieComponent},
                    {path: 'pilotage/attributs', component: AttributsComponent},
                    {path: 'pilotage/attribut/:id', component: DetailAttributComponent},
                    {path: 'pilotage/boitesOutil', component: BoitesOutilComponent},
                    {path: 'pilotage/boiteOutil/:id', component: DetailBoiteOutilComponent},
                    {path: 'rapport/utilisationequipement', component: RapportUtilisationEquipementComponent},
                    {path: 'rapport/listeequipement', component: RapportListeEquipementComponent},
                    {path: 'rapport/entreesortie', component: RapportEntreeSortieComponent},
                    {path: 'rapport/heures', component: HeuresComponent},
                    {path: 'rapport/entretienspep', component: RapportEntretiensPEPComponent},
                    {path: 'rapport/entretienssaaq', component: RapportEntretiensSAAQComponent},
                    {path: 'rapport/heureslocation', component: RapportHeuresLocationComponent},
                    {path: 'admin/utilisateurs', component: UtilisateursComponent},
                    {path: 'admin/employes', component: EmployesComponent},
                    {path: 'admin/employe/:id', component: DetailEmployeComponent},
                    {path: 'document/nouveau', component: AjoutDocumentComponent},
                    {path: 'mecanique/unites', component: ItemsComponent, data: {statutId:901}},
                    {path: 'mecanique/pieces', component: ItemsComponent, data: {statutId:902}},
                    {path: 'mecanique/bonstravail', component: BonsTravailComponent},
                    {path: 'mecanique/bontravail/nouveau', component: AjoutBonTravailComponent},
                    {path: 'mecanique/bontravail/nouveau/:id', component: AjoutBonTravailComponent},
                    {path: 'mecanique/bontravail/:id', component: DetailBonTravailComponent, canDeactivate: [PendingChangesGuard]},
                    {path: 'mecanique/bontravail/analyse/:id', component: DetailAnalyseBonTravailComponent},
                    {path: 'mecanique/entretienpreventif', component: DetailEntretienPreventifComponent},
                    {path: 'mecanique/entretienpreventif/:id', component: DetailEntretienPreventifComponent},
                    {path: 'mecanique/planification', component: PlanificationComponent},
                    {path: 'mecanique/heures', component: AiguillageHeuresComponent},
                    {path: 'mecanique/saisieheureskms', component: SaisieHeuresKmsComponent},
                    {path: 'mecanique/saisieheures', component: SaisieHeuresComponent},
                    {path: 'mecanique/approbationheures', component: ApprobationHeuresComponent},
                    {path: 'mecanique/saisiekms', component: SaisieKmsComponent},
                    {path: 'mecanique/approbationkms', component: ApprobationKmsComponent},
                    {path: 'mecanique/notes', component: NotesComponent},
                    {path: 'mecanique/pilotage/listesverification', component: ListesVerificationComponent},
                    {path: 'mecanique/pilotage/listeverification/nouveau', component: AjoutListeVerificationComponent},
                    {path: 'mecanique/pilotage/listeverification/:id', component: DetailListeVerificationComponent},
                    {path: 'assurancequalite/nonconformite', component: NonConformiteComponent},
                    {path: 'assurancequalite/nonconformite/nouveau', component: AjoutNonConformiteComponent},
                    {path: 'assurancequalite/nonconformite/:id', component: DetailNonConformiteComponent},
                    {path: 'assurancequalite/actioncorrective', component: ActionCorrectiveComponent},
                    {path: 'assurancequalite/actioncorrective/nouveau', component: AjoutActionCorrectiveComponent},
                    {path: 'assurancequalite/actioncorrective/:id', component: DetailActionCorrectiveComponent}
                ]
            },
            {path: 'error', component: AppErrorComponent},
            {path: 'access', component: AppAccessdeniedComponent},
            {path: 'notfound', component: AppNotfoundComponent},
            {path: 'login', component: AppLoginComponent},
            {path: '**', redirectTo: '/notfound'},
        ], {scrollPositionRestoration: 'enabled'})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
