import { Component, OnInit, ViewChild } from "@angular/core"; import { Location } from "@angular/common";
import { Table } from 'primeng/table';
import { BreadcrumbService } from '../../app.breadcrumb.service';

import { RequisitionMin } from '../../entites/requisition-min';
import { MenuItem, TreeNode } from 'primeng/api';
import { MessageService, LazyLoadEvent, ConfirmationService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

import { DialogService } from 'primeng/dynamicdialog';
import { EnvoiRequisitionComponent } from '../../controles/envoi-requisition.component';
import { ItemMin } from '../../entites/item-min';
import { ItemService } from '../../services/item-service';
import { Router, ActivatedRoute } from '@angular/router';

import { ToolbarModule } from 'primeng/toolbar';

import { MenubarModule } from 'primeng/menubar';
import { BaseComponent } from '../../base/base-component';
import { Item } from '../../entites/item';
import { Inventaire } from '../../entites/inventaire';
import { NoteEntite } from '../../entites/note';
import { Fournisseur } from '../../entites/fournisseur';
import { Achat } from '../../entites/achat';
import { FournisseurService } from '../../services/fournisseur-service';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { EntreeHistoriqueAchat } from 'src/app/entites/entree-historique-achat';
import { AnalyseItemComponent } from 'src/app/controles/analyse-item.component';
import { ProfilService } from 'src/app/services/profil-service';
import { ContactEntite } from 'src/app/entites/contact';
import { ContactService } from 'src/app/services/contact-service';
import { ContextMenu } from 'primeng/contextmenu/public_api';
import { AppMainComponent } from 'src/app/app.main.component';
import { SommaireAchatEntite } from 'src/app/entites/sommaireAchatEntite';
import { CreationCommandeComponent, ResultatCreationBonCommande } from 'src/app/controles/creation-commande.component';
import { ContexteService } from 'src/app/services/contexte-service';
import { RapportService } from 'src/app/services/rapport-service';

@Component({
    templateUrl: './detail-fournisseur.component.html',
    styleUrls: ['./detail-fournisseur.component.scss'],
    providers: [MessageService, ConfirmationService, DialogService]
})
export class DetailFournisseurComponent extends BaseComponent implements OnInit {




    fournisseurId: number;
    fournisseur: Fournisseur;

    menu: MenuItem[];

    statuses: any[];

    selectionNote: NoteEntite;
    menuContexteNote: MenuItem[];

    chargementCompleteHistorique: boolean = false;
    historique: EntreeHistoriqueAchat[];
    selectionHistorique: EntreeHistoriqueAchat;
    contenuMenuContextuelAchat: MenuItem[];
    @ViewChild('menuContextuelAchat') menuContextuelAchat: ContextMenu;

    chargementCompleteSommaire: boolean = false;
    sommaire: SommaireAchatEntite[];

    chargementCompleteReparations: boolean = false;


    //Contacts
    contact: ContactEntite;
    principalForce: boolean = false;
    dialogueOuvert: boolean;
    submitted: boolean;

    

    @ViewChild('rechercheHistorique') inputRechercheHistorique;
@ViewChild('prenomContact') champPrenomContact;
@ViewChild('menuContextuelContact') menuContextuelContact: ContextMenu;
@ViewChild('dtHistorique') tableHistorique: Table;

    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute, 
		appMain: AppMainComponent, location: Location, 
        private breadcrumbService: BreadcrumbService,
        private fournisseurService: FournisseurService,
        private contactService: ContactService,
        private contexteService: ContexteService,
        public rapportService: RapportService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        public dialogService: DialogService,
        public routerService: Router) {

        super(sdb, profilService, route, routerService, appMain);

        this.breadcrumbService.setItems([
            { label: 'Fournisseurs', routerLink: '/fournisseurs' },
            { label: 'Détails' }
        ]);

        this.route.params.subscribe(params => this.fournisseurId = params.id);
    }

    ngOnInit() {

        this.verifierAccesPage('fournisseur_detail');

        this.fournisseurService.ObtenirFournisseur(this.fournisseurId).then(x => {
            this.fournisseur = x.valeur;
            if (!this.fournisseur.contacts) {
                this.fournisseur.contacts = [];
            }
            this.CompleterChargement(`Fournisseur - ${this.fournisseur.nom} (${this.fournisseur.numero})`);
        });

        this.menu = [
            {
                label: 'Retour', icon: 'pi pi-fw pi-angle-double-left', command: () => this.routerService.navigateByUrl('fournisseurs')
            },
           /*  {
                label: 'Rapports', icon: 'pi pi-fw pi-chart-bar',
                items: [
                    {
                        label: 'Historique d\'achat', icon: 'pi pi-fw pi-file-pdf'
                    }
                ]
            }, */
            {
                label: 'Actions', icon: 'pi pi-fw pi-bars',
                visible: this.estAutorise('fournisseur_action_archiver'),
                items: [
                    {
                        label: 'Archiver', icon: 'pi pi-fw pi-times', 
                        visible: this.estAutorise('fournisseur_action_archiver'),
                        command: () => this.archiver(this.fournisseur)
                    }
                ]
            },
        ];

        

        this.menuContexteNote = [
            {label: 'Supprimer la note', icon: 'pi pi-fw pi-times'}
        ];

    }

    surChangementOnglet(event) {
        if (event.index == 1) {
            this.fournisseurService.ObtenirHistorique(this.fournisseurId).then(x => {
                this.historique = x.valeur;
                this.chargementCompleteHistorique = true;

                setTimeout(() => { this.inputRechercheHistorique.nativeElement.focus(); }, 100);
            });
        }
        if (event.index == 2) {
            this.fournisseurService.ObtenirSommaire(this.fournisseur.fournisseurId).then(x => {
                this.sommaire = x.valeur;
                this.chargementCompleteSommaire = true;
            });
        }
        /* if (event.index == 2) {
            setTimeout(() => { this.chargementCompleteReparations = true; }, 800);
        } */
    }

    btnModifier_click() {
        this.Modifier();
    }

    annulerModification() {
        this.DemarrerChargement();

        this.fournisseurService.ObtenirFournisseur(this.fournisseurId).then(x => {
            this.fournisseur = x.valeur;
            if (!this.fournisseur.contacts) {
                this.fournisseur.contacts = [];
            }
            this.CompleterChargement();
        });

        this.Consulter();
    }

    fournisseurSubmitted = false;

    enregistrerModifications() {
        this.fournisseurSubmitted = true;
        this.messageService.clear();

        if (this.validerModification()) {
            this.DemarrerChargement();
            this.fournisseurService.ModifierFournisseur(this.fournisseur).then(x => {
                this.CompleterChargement();
                if (x.estUnSucces) {
                    this.Consulter();
                    this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Fournisseur modifié.' });
                } else {
                    x.messages.forEach(x => {
                        this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description, sticky: true });
                    });

                }

            });


        }
    }

    validerModification(): boolean {
        return true;
    }



    


    // CONTACTS

    contenuMenuContextuelContact: MenuItem[];
    surMenuContextuelContact(event, contact: ContactEntite) {
        this.contact = contact;

        this.contenuMenuContextuelContact = [
            { 
                label: 'Modifier', icon: 'pi pi-fw pi-pencil', 
                visible: this.estAutorise('fournisseur_modifier_contact'),
            command: () => this.modifierContact(this.contact) 
        },
            { separator: true },
            { 
                label: 'Supprimer', 
                icon: 'pi pi-fw pi-trash',  
                visible: this.estAutorise('fournisseur_supprimer_contact'),
                command: () => this.supprimerContact(this.contact) 
            },
        ];

        this.menuContextuelContact.toggle(event);
    }

    ajouterContact() {
        this.contact = {};
        this.principalForce = false;

        if (this.fournisseur.contacts.length == 0) {
            this.contact.indPrincipal = true;
            this.principalForce = true;
        }

        this.submitted = false;
        this.dialogueOuvert = true;

        this.champPrenomContact.nativeElement.focus();
    }

    modifierContact(contact: ContactEntite) {
        this.contact = { ...contact };
        this.principalForce = false;

        if (this.fournisseur.contacts.length == 1) {
            this.contact.indPrincipal = true;
            this.principalForce = true;
        }

        this.dialogueOuvert = true;
    }

    supprimerContact(contact: ContactEntite) {
        this.confirmationService.confirm({
            message: `Voulez-vous vraiment supprimer le contact ${contact.prenom} ${contact.nom}  ?`,
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.contactService.Supprimer(contact.contactId).then(res => {
                    if (res.estUnSucces) {
                        this.fournisseur.contacts = this.fournisseur.contacts.filter(val => val.contactId !== contact.contactId);
                        this.contact = {};
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Contact supprimé' });
                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                });
            }
        });
    }

    annulerContact() {
        this.dialogueOuvert = false;
        this.submitted = false;
    }

    sauvegarderContact() {
        this.submitted = true;

        this.contact.fournisseurId = this.fournisseur.fournisseurId;

        if (!this.valider()) {
            this.messageService.add({ severity: 'error', summary: 'Erreurs', detail: 'Veuillez corriger les erreurs de saisie.' });
            return;
        }

        if (this.contact.contactId) {

            this.contactService.Modifier(this.contact).then(res => {
                if (res.estUnSucces) {
                    this.contact = res.valeur;
                    if (this.contact.indPrincipal) {
                        this.fournisseur.contacts.forEach(c => {
                            c.indPrincipal = false;
                        });
                    }
                    this.fournisseur.contacts[this.findIndexById(this.contact.contactId)] = this.contact;
                    this.fournisseur.contacts = [...this.fournisseur.contacts];
                    this.dialogueOuvert = false;
                    this.contact = {};
                    this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Contact modifié' });
                } else {
                    res.messages.forEach(x => {
                        this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                    });
                }
            });

        }
        else {

            this.contactService.Ajouter(this.contact).then(res => {
                if (res.estUnSucces) {
                    this.contact = res.valeur;
                    if (this.contact.indPrincipal) {
                        this.fournisseur.contacts.forEach(c => {
                            c.indPrincipal = false;
                        });
                    }
                    this.fournisseur.contacts.push(this.contact);
                    this.fournisseur.contacts = [...this.fournisseur.contacts];
                    this.dialogueOuvert = false;
                    this.contact = {};
                    this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Contact ajouté' });
                } else {
                    res.messages.forEach(x => {
                        this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                    });
                }
            });
        }

    }

    valider() {
        return this.contact.nom && this.contact.prenom
    }

    findIndexById(id: number): number {
        let index = -1;
        for (let i = 0; i < this.fournisseur.contacts.length; i++) {
            if (this.fournisseur.contacts[i].contactId === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    archiver(fournisseur: Fournisseur) {
        this.confirmationService.confirm({
            message: `Voulez-vous vraiment archiver le fournisseur ?`,
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.fournisseurService.ArchiverFournisseur(fournisseur).then(res => {
                    if (res.estUnSucces) {
                        this.routerService.navigateByUrl('fournisseurs');
                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                });
            }
        });
    }

    /* HISTORIQUE ACHATS */

    surMenuContextuelAchat(event, entree: EntreeHistoriqueAchat) {
        this.selectionHistorique = entree;

        this.contenuMenuContextuelAchat = [
            {
                label: 'Voir le bon de commande', 
                icon: 'pi pi-fw pi-eye', 
                visible : this.estAutorise('bdc_detail'),
                command: () => this.routerService.navigateByUrl('achat/' + this.selectionHistorique.bonCommande.bonCommandeId)
            },
            {
                label: 'Voir l\'item', 
                icon: 'pi pi-fw pi-eye', 
                visible : this.estAutorise('item_detail'),
                command: () => this.routerService.navigateByUrl('item/' + this.selectionHistorique.item.itemId)
            },
            {
                label: 'Historique d\'achat de l\'item', 
                icon: 'pi pi-fw pi-clock',
                visible : this.estAutorise('item_detail'),
                command: () => {
                    this.contexteService.Ajouter("tab", "4");
                    this.routerService.navigateByUrl('item/' + this.selectionHistorique.item.itemId);
                }
            },
            {
                label: 'Analyser l\'item', 
                icon: 'pi pi-fw pi-chart-line', 
                visible : this.estAutorise('fournisseur_historique_analyser'),
                command: () => this.analyserItem(this.selectionHistorique.item.itemId)
            },
            {
                label: 'Commander', 
                icon: 'pi pi-fw pi-shopping-cart', 
                visible : this.estAutorise('bdc_creer'),
                command: () => this.commander(this.selectionHistorique.item, this.fournisseur, this.selectionHistorique.numeroFournisseur, this.selectionHistorique.prix)
            },
        ];

        this.menuContextuelAchat.toggle(event);
    }

    analyserItem(itemId: number) {
        const ref = this.dialogService.open(AnalyseItemComponent, {
            data: {
                "itemId": itemId
            },
            header: 'Analyse du prix',
            width: '50%'
        });
    }

    commander(item: Item, fournisseur: Fournisseur, numeroFournisseur: string, prix: number) {
        const ref = this.dialogService.open(CreationCommandeComponent, {
            data: {
                itemId: item.itemId,
                numeroItem: item.numero,
                descriptionItem: item.description,
                fournisseurId: fournisseur.fournisseurId,
                nomFournisseur: fournisseur.nom,
                numeroFournisseur: fournisseur.numero,
                numeroItemFournisseur: numeroFournisseur,
                prix: prix 
            },
            header: 'Création d\'un bon de commande',
            width: '60%'
        }).onClose.subscribe((res: ResultatCreationBonCommande) => {
            if (res) {
                if (res.redirection) {
                    this.routerService.navigateByUrl('achat/' + res.bonCommandeId);
                } else {
                    this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'L\'item a été ajouté au bon de commande.' });
                }
            }


        });
    }

    
    exportExcel() {

        var t = this.tableHistorique.value;

        if (this.tableHistorique.filteredValue) {
            t = this.tableHistorique.filteredValue;
        }

        var lignes: any[] = t.map(x => ({
            Date: this.normaliserValeurChainePourexportationExcel(x.bonCommande.dateAchat),
            Numéro: this.normaliserValeurChainePourexportationExcel(x.item.numero),
            Description: this.normaliserValeurChainePourexportationExcel(x.item.description),
            Marque: this.normaliserValeurChainePourexportationExcel(x.item.marque),
            Modèle: this.normaliserValeurChainePourexportationExcel(x.item.modele),
            Quantité: this.normaliserValeurChainePourexportationExcel(x.quantite),
            Prix: this.normaliserValeurChainePourexportationExcel(x.prix),
            NoBDC: this.normaliserValeurChainePourexportationExcel(x.bonCommande.numero)
        }));

        for (let index = 0; index < lignes.length; index++) {
            /* if (this.colonnesSelectionnees.filter(x => x.champ == 'categorie').length == 1) {
              if (t[index].categorie && t[index].categorie.nom){
                  lignes[index].Categorie = t[index].categorie?.nom;
              } else{
                  lignes[index].Categorie = '';
              }
              lignes[index].Categorie = t[index].categorie?.nom;
            } 
          if (this.colonnesSelectionnees.filter(x => x.champ == 'sousCategorie').length == 1) {
              if (t[index].sousCategorie && t[index].sousCategorie.nom){
                  lignes[index].SousCategorie = t[index].sousCategorie?.nom;
              } else{
                  lignes[index].SousCategorie = '';
              }
            } */
        }

        this.messageService.add({ severity: 'info', summary: 'Demande de rapport', detail: `Votre demande de rapport a été envoyée.` });

        this.rapportService.ProduireRapportExcel({
            contenu: JSON.stringify(lignes)
        }).then(x => {
            if (x) {
                this.messageService.add({ severity: 'success', summary: 'Succès', detail: `Votre rapport a été généré.` });
            } else {
                this.messageService.add({ severity: 'error', summary: 'Erreur', detail: `Une erreur est survenue pendant la génération de votre rapport.` });
            }
        });

    }

}
