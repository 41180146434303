import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService, ConfirmationService, MenuItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { AppMainComponent } from 'src/app/app.main.component';
import { BaseComponent } from 'src/app/base/base-component';
import { AnalyseBonTravail } from 'src/app/entites/analyse-bon-travail';
import { BonTravailService } from 'src/app/services/bon-travail-service';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { ProfilService } from 'src/app/services/profil-service';
import { RapportService } from 'src/app/services/rapport-service';

@Component({
  selector: 'app-detail-analyse-bon-travail',
  templateUrl: './detail-analyse-bon-travail.component.html',
  providers: [MessageService, ConfirmationService, DialogService]
})
export class DetailAnalyseBonTravailComponent extends BaseComponent implements OnInit {

  menu: MenuItem[];
  analyseBonTravail: AnalyseBonTravail;
  analyseBonTravailId: number;

  constructor(
    public sdb: DonneesBaseService,
    public profilService: ProfilService,
    public route: ActivatedRoute,
    public router: Router,
    public appMain: AppMainComponent,
    private bonTravailService: BonTravailService,
    private breadcrumbService: BreadcrumbService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    public dialogService: DialogService,
    private rapportService: RapportService
  ) {
    super(sdb, profilService, route, router, appMain);

    this.breadcrumbService.setItems([
      { label: 'Bons de travail', routerLink: '/mecanique/bonstravail' },
      { label: 'Analyse', routerLink: '/mecanique/bonstravail' },
      { label: 'Détails' }
    ]);

    this.route.params.subscribe(params => this.analyseBonTravailId = params.id);
  }

  ngOnInit(): void {
    this.DemarrerChargement();
    this.initialiserMenu();
    this.bonTravailService.ObtenirAnalyseBonTravail(this.analyseBonTravailId).subscribe({
      next: (response) => {
        this.analyseBonTravail = response.body;
        this.CompleterChargement(`Analyse du bon de travail #${this.analyseBonTravail.numero}`);
      },
      error: (error) => {
        this.CompleterChargement();
        this.router.navigateByUrl('error');
      }
    });
  }

  initialiserMenu(): void {
    this.menu = [
      {label: 'Retour', icon: 'pi pi-arrow-left', command: () => this.router.navigateByUrl('/mecanique/bonstravail')},
      { label: 'Voir la soumission', icon: 'pi pi-eye', command: () => this.ouvrirDetailSoumission() },
      { label: 'Voir le bon de travail', icon: 'pi pi-eye', command: () => this.ouvrirDetailBonTravail() },
      { label: 'Actions', icon: 'pi pi-cog', items: [
        { label: 'Télécharger le PDF', icon: 'pi pi-file-pdf', command: () => this.telechargerPDF() }
      ]}
    ];
  }

  telechargerPDF(): void {
    this.messageService.add({ severity: 'info', summary: 'Info', detail: 'Le rapport est en cours de génération' });
    this.rapportService.ProduireRapportAnalyseBonTravail(this.analyseBonTravail.analyseBonTravailId).then((result) => {
      if (result) {
        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Le rapport a été généré avec succès' });
      } else {
        this.messageService.add({ severity: 'error', summary: 'Erreur', detail: 'Une erreur est survenue lors de la génération du rapport' });
      }
    });
  }

  ouvrirDetailBonTravail(): void {
    this.router.navigateByUrl(`/mecanique/bontravail/${this.analyseBonTravail.bonTravailId}`);
  }

  ouvrirDetailSoumission(): void {
    this.router.navigateByUrl(`/mecanique/bontravail/${this.analyseBonTravail.soumissionId}`);
  }

  commencerModification(): void {
    this.Modifier();
  }

  enregistrerModifications(): void {
    this.bonTravailService.ModifierAnalyseBonTravail(this.analyseBonTravail).subscribe({
      next: (response) => {
        this.analyseBonTravail = response.body;
        this.CompleterChargement(`Analyse du bon de travail #${this.analyseBonTravail.numero}`);
      },
      error: (error) => {
        this.CompleterChargement();
        this.router.navigateByUrl('error');
      }
    });
  }

  annulerModification(): void {
    this.router.navigateByUrl('/').then(() => {
      this.router.navigateByUrl(`/mecanique/bontravail/analyse/${this.analyseBonTravailId}`);
    });
  }

  // Add more methods as needed for component functionality
}