<div [classList]="[appMain.isDesktop() ? 'topbar flex justify-content-between align-items-center' : 'flex-wrap w-full']">
    <div [classList]="[appMain.isMobile() ? 'topbar w-full flex align-items-center' : 'flex align-items-center']">
        <p-button severity="secondary" [raised]="true" [icon]="appMain.isMobile() ? 'pi pi-bars' : appMain.menuButtonIcon"
            (click)="appMain.onMenuButtonClick($event)">
        </p-button>
        <p-divider layout="vertical" />
        <p-breadcrumb class="max-w-full" [model]="items" [home]="home" />
    </div>

    <div [classList]="[appMain.isMobile() ? 'topbar w-full' : '']">
        <div class="" [classList]="[appMain.isMobile() ? 'flex justify-content-around align-items-center' : 'flex justify-content-end align-items-center']">
            <h5 *ngIf="afficherAlerteDev && appMain.isDesktop()"
                style="color: darkred; background-color: yellow; padding: 5px; margin: 1px 12px 0 0;">ENVIRONNEMENT DE
                DÉVELOPPEMENT</h5>
            <p-button class="topbar-button" (onClick)="appMain.onSearchClick($event)" icon="pi pi-search" [text]="true"
                severity="contrast" />
            <div>
                <p-overlayPanel #fav>
                    <ng-template pTemplate="content">
                        <p-table [value]="favoris" [style]="{'min-width': '400px'}">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>Favoris</th>
                                </tr>
                            </ng-template>
                            <ng-template let-favori pTemplate="body">
                                <tr>
                                    <td class="flex align-items-center p-2">
                                        <i class="pi pi-heart"></i>
                                        <div pButton severity="secondary" [text]="true" (click)="naviguer(favori.url)" class="ml-2">{{favori.description}}</div>
                                        <div style="margin-left: auto;">
                                            <p-button severity="secondary" [text]="true" icon="pi pi-times" (onClick)="supprimerFavori(favori.favoriId)" />
                                        </div>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td class="flex justify-content-center p-2">
                                        Aucun favoris.
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </ng-template>
                </p-overlayPanel>
                <p-button class="topbar-button" (onClick)="fav.toggle($event)" icon="pi pi-heart" [text]="true"
                    severity="contrast" />
            </div>
            <div>
                <p-overlayPanel #rap>
                    <ng-template pTemplate="content">
                            <p-table [value]="rapports" [style]="{'min-width': '400px'}">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th>Rapports</th>
                                    </tr>
                                </ng-template>
                                <ng-template let-rapport pTemplate="body">
                                    <tr>
                                        <td class="flex align-items-center p-2">
                                            <i class="pi pi-file-pdf"></i>
                                            <div pButton severity="secondary" [text]="true" (click)="naviguerRapport(rapport)" class="ml-2">{{rapport.type}}</div>
                                            <div style="margin-left: auto;">
                                                <p-button severity="secondary" [text]="true" icon="pi pi-times" (onClick)="supprimerRapport(rapport)" />
                                            </div>
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td class="flex justify-content-center p-2">
                                            Aucun rapport.
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                    </ng-template>
                </p-overlayPanel>
                <p-button class="topbar-button" (onClick)="rap.toggle($event)" icon="pi pi-file-pdf"
                    [badge]="rapports.length" [text]="true" severity="contrast" />
            </div>
            <div>
                <p-overlayPanel #hist>
                    <ng-template pTemplate="content">
                            <p-table [value]="historiques" [style]="{'min-width': '400px'}">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th>Historique</th>
                                    </tr>
                                </ng-template>
                                <ng-template let-historique pTemplate="body">
                                    <tr>
                                        <td class="flex align-items-center p-2">
                                            <i class="pi pi-clock"></i>
                                            <div pButton severity="secondary" [text]="true" (click)="naviguer(historique.url)" class="ml-2">{{historique.description}}</div>
                                            <div style="margin-left: auto;">
                                                <p-button severity="secondary" [text]="true" icon="pi pi-times" (onClick)="supprimerHistorique(historique.historiqueId)" />
                                            </div>
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td class="flex justify-content-center p-2">
                                            Aucun historique.
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                    </ng-template>
                </p-overlayPanel>
                <p-button class="topbar-button" (onClick)="hist.toggle($event)" icon="pi pi-history" [text]="true"
                    severity="contrast" />
            </div>
            <p-divider layout="vertical" />
            <div class="profile-item" [ngClass]="{'active-menuitem fadeInDown': appMain.topbarUserMenuActive}">
                <p-toast />
                <p-menu #menu [model]="userItems" [popup]="true" />
                <p-button (onClick)="menu.toggle($event)" [text]="true" severity="contrast">
                    <p-avatar icon="pi pi-user" size="normal" styleClass="mr-2" shape="circle" />
                    <span>{{nomUtilisateur}}</span>
                </p-button>
            </div>
            <p-divider layout="vertical" />
        </div>
    </div>
</div>