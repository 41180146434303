import { Component, ViewChild, ElementRef, ɵConsole, Input } from "@angular/core"; import { Location } from "@angular/common";
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { CleValeur, CleValeurNumerique } from '../entites/cleValeur';
import { EntreeCommande } from '../entites/entree-commande';
import { ItemMin } from '../entites/item-min';
import { Statut } from '../entites/statut';
import { DonneesBaseService } from '../services/donnees-base-service';
import { BaseComponent } from '../base/base-component';
import { ItemService } from '../services/item-service';
import { BlockUIModule } from 'primeng/blockui';
import { FournisseurService } from '../services/fournisseur-service';
import { Fournisseur } from '../entites/fournisseur';
import { EmployeEntite } from '../entites/employe';
import { ItemCommandeEntite } from '../entites/item-commande';
import { MessageService } from 'primeng/api';
import { ProfilService } from '../services/profil-service';
import { ActivatedRoute, Router } from '@angular/router';
import { AchatService } from '../services/achat-service';
import { RaisonArchive } from '../entites/raison-archive';
import { AppMainComponent } from '../app.main.component';
import { RequisitionService } from '../services/requisition-service';
import { CommandessComponent } from '../pages/requisitions/commandes.component';
import { CommandeService } from '../services/commande-service';
import { TransporteurEntite } from '../entites/transporteur';

export interface ParametresSelectionTransporteur {
    emplacementId: number;
}

@Component({
    selector: 'app-selection-transporteur',
    templateUrl: './selection-transporteur.component.html'
})
export class SelectionTransporteurComponent extends BaseComponent {

    donnees: ParametresSelectionTransporteur;

    transporteurs: TransporteurEntite[];
    transporteur: TransporteurEntite;
    autreCamion: string;
    autreRemorque: string;
    autreChauffeur: string;
    transporteurAucun: TransporteurEntite;

    submitted: boolean;
    blocked: boolean = false;

    @ViewChild('champAutre') champAutre;
    @ViewChild('boutonConfirmer') boutonConfirmer;

    constructor(
        profilService: ProfilService,
        route: ActivatedRoute, 
        routerService: Router,
        appMain: AppMainComponent, location: Location, 
        private commandeService: CommandeService,
        public messageService: MessageService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public sdb: DonneesBaseService) {
        super(sdb, profilService, route, routerService, appMain);
    }

    ngOnInit() {

        this.donnees = this.config.data;

        this.transporteurAucun = {camion: null, chauffeur: null, remorque: null};

        this.commandeService.ObtenirTransporteur(this.donnees.emplacementId).then(res => {
            if (res.estUnSucces) {
                this.transporteurs = res.valeur

                if (this.transporteurs.length > 0) {
                    this.transporteur = this.transporteurs[0];
                    setTimeout(() => {this.boutonConfirmer.nativeElement.focus(); }, 100);
                } else {
                    setTimeout(() => { this.champAutre.nativeElement.focus() }, 100);
                }

                this.CompleterChargement();
                
            }
        });

        

    }

    annuler() {
        this.ref.close(null);
    }

    confirmer() {

        this.submitted = true;
        if (!this.valider()) {
            this.messageService.add({ key: 'selectionTransporteur', severity: 'error', summary: 'Erreurs', detail: 'Veuillez corriger les erreurs de saisie.' });
            return;
        }

        if (this.transporteur) {
            this.ref.close(this.transporteur);
        } else {
            this.ref.close({
                camion: this.autreCamion,
                remorque: this.autreRemorque,
                chauffeur: this.autreChauffeur,
            });
        }
        

    }

    valider() {
        return this.transporteur || (this.autreCamion && this.autreRemorque && this.autreChauffeur);
    }
}