<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-menu #menuContextuelItemCommande popup="popup" [model]="menuContextuelEntrees"></p-menu>
<p-card *ngIf="!EstCharge()">
	<div class="fluid formgrid grid">
		<div class="flex flex-column gap-2 my-2 col-12 text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-card>

<div *ngIf="EstCharge()" class="grid">
	<div class="col-12">
		<div class="card">
			<h5>Bon de commande #{{bonCommande.numero}} ({{bonCommande.typeBonCommande}})
				<span [class]="'statut ' + bonCommande.statut.nom.toLowerCase().replace(' ','')">
					{{bonCommande.statut.nom}}
				</span>
				<span *ngIf="modeApercu"> 
					<i class="pi pi-id-card ml-4" ></i>
					<a class="lienFicheComplete" (click)="ouvrirFicheComplete()">Aller à la fiche complète</a>
				</span>
			</h5>
			<h6 *ngIf="bonCommande.dateEnvoi"><i class="pi pi-send" style="font-size: 0.8rem"></i> Envoyé le :
				{{bonCommande.dateEnvoi | date:'yyyy-MM-dd HH:mm:ss'}}</h6>

			<p-menubar autoDisplay="false" [model]="menu" *ngIf="!modeApercu">
				<p-button *ngIf="estAutorise('bdc_modifier') && modeConsultation() && estModifiable"
					(click)="btnModifier_click()" icon="pi pi-pencil" styleClass="p-button-primary"></p-button>
				<p-button *ngIf="modeAjoutModification()" (onClick)="enregistrerModifications()" label
					icon="pi pi-check" styleClass="p-button-success mr-2">
				</p-button>
				<p-button *ngIf="modeAjoutModification()" (onClick)="annulerModification($event)" icon="pi pi-times"
					styleClass="p-button-secondary"></p-button>
			</p-menubar>

		</div>
	</div>
</div>


<div *ngIf="EstCharge()" class="grid">
	<div class="col-12">
		<div class="card">
			<p-tabView orientation="left">
				<p-tabPanel header="Informations générales" leftIcon="pi pi-info-circle">
					<div class="fluid formgrid grid">

						<div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-1">
							<label for="firstname">Numéro</label>
							<input id="firstname" type="text" pInputText readonly value="{{bonCommande.numero}}">
						</div>
                        <div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-1">
                            <label for="revisionBdc">Révision</label>
                            <input id="revisionBdc" type="text" pInputText [maxlength]="2" [readonly]="modeConsultation()" [(ngModel)]="bonCommande.revision">
                        </div>
						<div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-2">
							<label for="dateBdc">Date</label>
							<input *ngIf="modeConsultation()" id="dateBdc" type="date" pInputText readonly
								value="{{bonCommande.dateAchat | date:'yyyy-MM-dd'}}">
							<p-calendar *ngIf="!modeConsultation()" appendTo="body" showIcon=true [(ngModel)]="bonCommande.dateAchat"
								dateFormat="yy-mm-dd" [yearNavigator]="true" [monthNavigator]="true"
								yearRange="2000:2040"></p-calendar>
							<small class="p-error" *ngIf="submitted && !bonCommande.dateAchat">La date est
								obligatoire.</small>
						</div>
						<div class="flex flex-column gap-2 my-2 col-12 md:col-3">
							<label for="projet">Projet</label>
							<p-dropdown #champCodeProjet [readonly]="modeConsultation()" [options]="listeCodeProjet" 
								[(ngModel)]="bonCommande.projet" [showClear]="false"
								optionLabel="code" filter="true"
								filterBy="code,nom" appendTo="body"
								placeholder="Sélectionner un code de projet...">
								<ng-template pTemplate="selectedItem">
									<div *ngIf="bonCommande.projet">
										<div>{{bonCommande.projet.nom}}</div>
									</div>
								</ng-template>
								<ng-template let-code pTemplate="item">
									<div>
										<div>{{code.code}} - {{code.nom}}</div>
									</div>
								</ng-template>
							</p-dropdown>
							<small class="p-error" *ngIf="submitted && !bonCommande.projet">Le code de projet est
								obligatoire.</small>
						</div>
						<div class="flex flex-column gap-2 my-2 col-12 md:col-3">
							<label for="lastname">Fournisseur</label>
							<p-dropdown [readonly]="modeConsultation()" [options]="fournisseurs" [(ngModel)]="bonCommande.fournisseur" optionLabel="nom"
								[showClear]="!modeConsultation()" filter="true" filterBy="numero,nom"
								placeholder="Sélectionner un fournisseur...">
								<ng-template pTemplate="selectedItem">
									<div *ngIf="bonCommande.fournisseur">
										<div>{{bonCommande.fournisseur.nom}} ({{bonCommande.fournisseur.numero}})</div>
									</div>
								</ng-template>
								<ng-template let-fournisseur pTemplate="item">
									<div>
										<div>{{fournisseur.nom}} ({{fournisseur.numero}})</div>
									</div>
								</ng-template>
							</p-dropdown>
							<small class="p-error" *ngIf="submitted && !bonCommande.fournisseur">Le fournisseur est
								obligatoire.</small>
						</div>

						<div class="flex flex-column gap-2 my-2 col-12 lg:col-3">
							<label for="lastname">Fréquence</label>
							<p-dropdown [readonly]="modeConsultation()" [options]="frequences" [(ngModel)]="selectionFrequence" optionLabel="valeur"
								[showClear]="false" filter="true" filterBy="numero,nom"
								placeholder="Sélectionner une fréquence...">
								<ng-template pTemplate="selectedItem">
									<div *ngIf="selectionFrequence">
										<div>{{selectionFrequence.valeur}}</div>
									</div>
								</ng-template>
								<ng-template let-frequence pTemplate="item">
									<div>
										<div>{{frequence.valeur}}</div>
									</div>
								</ng-template>
							</p-dropdown>
						</div>

						<div class="flex flex-column gap-2 my-2 col-12 md:col-3">
							<label for="employe">Employé</label>
							<input id="employe" type="text" pInputText readonly
								value="{{bonCommande.employe | nomEmploye}}">
						</div>
					</div>
					<div class="fluid formgrid grid">
						<div class="flex flex-column gap-2 my-2 col-6">
							<label for="lastname">Emplacement de livraison<span class="requis">*</span></label>
							<p-dropdown [readonly]="modeConsultation()" [options]="listeEmplacement" [(ngModel)]="bonCommande.emplacement" optionLabel="nom"
								[showClear]="!modeConsultation()"
								appendTo="body" filter="true" filterBy="code,nom"
								placeholder="Sélectionner un emplacement...">
								<ng-template pTemplate="selectedItem">
									<div *ngIf="bonCommande.emplacement">
										<div>{{bonCommande.emplacement.nom}} ({{bonCommande.emplacement.code}})</div>
									</div>
								</ng-template>
								<ng-template let-emplacement pTemplate="item">
									<div>
										<div>{{emplacement.nom}} ({{emplacement.code}})</div>
									</div>
								</ng-template>
							</p-dropdown>
							<small class="p-error" *ngIf="submitted && !bonCommande.emplacement">L'emplacement de livraison est
								obligatoire.</small>
						</div>
						<div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3">
							<label for="lastname">Requis le</label>
							<input *ngIf="modeConsultation()" id="dateBdc" type="date" pInputText readonly
								value="{{bonCommande.requisLe | date:'yyyy-MM-dd'}}">
							<p-calendar *ngIf="!modeConsultation()" appendTo="body" showIcon=true [(ngModel)]="bonCommande.requisLe" dateFormat="yy-mm-dd"
								[yearNavigator]="true" [monthNavigator]="true" yearRange="2000:2040"></p-calendar>
						</div>
					</div>
		
					<div class="fluid formgrid grid">
						<div class="flex flex-column gap-2 my-2 col-12">
							<label for="note">Note</label>
							<textarea [rows]="5" pInputTextarea autoResize="autoResize" [readonly]="modeConsultation()"
								[(ngModel)]="bonCommande.note"></textarea>
						</div>
					</div>
					<!-- <div class="fluid formgrid grid">
						<div class="flex flex-column gap-2 my-2 col-12">
							<label for="conditions">Conditions</label>
							<input #champNote [readonly]="modeConsultation()" type="text" pInputText id="conditions"
								[(ngModel)]="bonCommande.conditions" autocomplete="off" />
						</div>
					</div> -->
					<p-table #dt [value]="bonCommande.itemsBonCommande" [columns]="selectedColumns"
						dataKey="itemBonCommandeId" tableStyleClass="tableNormale" autoLayout="true"
						[(selection)]="selectionMultipleEntree" (onRowSelect)="surChangementSelection()"
						(onRowUnselect)="surChangementSelection()" (onHeaderCheckboxToggle)="surChangementSelection()"
						 [rowHover]="true" [filterDelay]="0"
						[globalFilterFields]="['item.numero','item.description','noFournisseur']">
						<ng-template pTemplate="caption">
							<div class="flex align-content-center justify-content-between">
								<div>
									<span class="p-input-icon-left">
										<i class="pi pi-search"></i>
										<input pInputText type="text"
											(input)="dt.filterGlobal($event.target.value, 'contains')"
											placeholder="Recherche..." />
									</span>
									<button *ngIf="estModifiable && !modeApercu" pButton pRipple icon="pi pi-plus"
										class="p-button-success mr-2 ml-4 mb-2 boutonGrille"
										(click)="surAjoutItem()"></button>


								</div>
								<h5 class="titreTableau m-0">Liste des items</h5>
								<div>
									<p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
										selectedItemsLabel="{0} colonnes sélectionnés" [style]="{minWidth: '200px'}"
										placeholder="Colonnes..."></p-multiSelect>
								</div>
							</div>
						</ng-template>

						<ng-template pTemplate="header" let-columns>
							<tr>
								<th style="min-width: 3rem">
									<p-tableHeaderCheckbox></p-tableHeaderCheckbox>
								</th>
								<th pSortableColumn="numero">
									<div class="flex justify-content-between align-items-center">
										No SIHC
										<p-sortIcon field="numero"></p-sortIcon>
										<p-columnFilter type="text" field="numero" display="menu" class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="description">
									<div class="flex justify-content-between align-items-center">
										Description
										<p-sortIcon field="description"></p-sortIcon>
										<p-columnFilter type="text" field="description" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="noFournisseur">
									<div class="flex justify-content-between align-items-center">
										No pièce fournisseur
										<p-sortIcon field="noFournisseur"></p-sortIcon>
										<p-columnFilter type="text" field="noFournisseur" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="quantite">
									<div class="flex justify-content-between align-items-center">
										Quantité
										<p-sortIcon field="quantite"></p-sortIcon>
										<p-columnFilter type="text" field="quantite" display="menu" class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="unite.code">
									<div class="flex justify-content-between align-items-center">
										Unité
										<p-sortIcon field="unite.code"></p-sortIcon>
										<p-columnFilter type="text" field="unite.code" display="menu" class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="prix">
									<div class="flex justify-content-between align-items-center">
										Prix
										<p-sortIcon field="prix"></p-sortIcon>
										<p-columnFilter type="text" field="prix" display="menu" class="ml-auto">
										</p-columnFilter>
									</div>
								</th>

								<th pSortableColumn="codeProjet">
									<div class="flex justify-content-between align-items-center">
										Projet
										<p-sortIcon field="codeProjet"></p-sortIcon>
										<p-columnFilter type="text" field="codeProjet" display="menu" class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="codeActivite">
									<div class="flex justify-content-between align-items-center">
										Code d'activité
										<p-sortIcon field="codeActivite"></p-sortIcon>
										<p-columnFilter type="text" field="codeActivite" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="codeEquipement">
									<div class="flex justify-content-between align-items-center">
										Code d'équipement
										<p-sortIcon field="codeEquipement"></p-sortIcon>
										<p-columnFilter type="text" field="codeEquipement" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="emplacement.nom">
									<div class="flex justify-content-between align-items-center">
									  Emplacement
										<p-sortIcon field="emplacement.nom"></p-sortIcon>
										<p-columnFilter type="text" field="emplacement.nom" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>

								<!-- <th pSortableColumn="col.flex flex-column gap-2 my-2" *ngFor="let col of columns">
									<div class="flex justify-content-between align-items-center">
										{{col.header}}
										<p-sortIcon field="col.flex flex-column gap-2 my-2"></p-sortIcon>
										<p-columnFilter type="text" field="col.flex flex-column gap-2 my-2" display="menu" class="ml-auto">
										</p-columnFilter>
									</div>
								</th> -->
								<th style="min-width: 5rem;"></th>

							</tr>
						</ng-template>
						<ng-template pTemplate="body" let-entree let-columns="columns">
							<tr>
								<td>
									<p-tableCheckbox [value]="entree"></p-tableCheckbox>
								</td>
								<td>{{entree.item.numero}}</td>
								<td>{{entree.item.description}}</td>
								<td>{{entree.noFournisseur}}</td>
								<td>{{entree.quantite}}</td>
								<td>{{entree.uniteQte}}</td>
								<td>{{entree.prix | currency:'CAD':'symbol-narrow':'1.4'}}</td>
								<td>{{entree.codeProjet?.code}}</td>
								<td>{{entree.codeActivite?.code}}</td>
								<td>{{entree.codeEquipement?.code}}</td>
								<td>{{entree.emplacement | nomEmplacement}}</td>
								<!-- <td *ngFor="let col of columns">
									{{entree[col.flex flex-column gap-2 my-2]}}
								</td> -->
								<td>
									<button pButton pRipple icon="pi pi-bars"
										class="p-button-secondary mr-2 boutonGrille"
										(click)="surMenuContextuelItem($event, entree)"></button>
								</td>

							</tr>
						</ng-template>
						<ng-template pTemplate="emptymessage">
							<tr>
								<td colspan="8">Aucun item.</td>
							</tr>
						</ng-template>
					</p-table>

					<div style="font-weight: 700; margin-top: 10px; text-align: right;">
						Prix total : {{prixTotal | currency}}
					</div>

					<!-- ##########   DIALOGUE DÉTAIL ENTRÉÉ BDC   ########## -->

					<p-dialog [(visible)]="productDialog" [style]="{width: '85%'}" header="Détail de l'item"
						[modal]="true" styleClass="fluid formgrid grid">
						<ng-template pTemplate="content">
							<div class="formgrid grid">
								<div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3">
									<p-selectButton [options]="typesSaisie" [(ngModel)]="selectionTypeSaisie"
										optionLabel="valeur"></p-selectButton>
								</div>

							</div>
							<div class="formgrid grid">
								<div *ngIf="typeSaisieInventaire()" class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3">
									<label for="noSIHC">No SIHC</label>
									<input type="text" pInputText id="noSIHC" [(ngModel)]="product.item.numero" required
										readonly disabled autocomplete="off" autofocus />
									<small class="p-error" *ngIf="submitted && !product.item.numero">Le numéro SIHC est
										obligatoire.</small>
								</div>
								<div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-6">
									<label for="description">Description</label>
									<input #descriptionItem type="text" pInputText id="description"
										[(ngModel)]="product.item.description" [readonly]="typeSaisieInventaire()"
										[disabled]="typeSaisieInventaire()" autocomplete="off" autofocus />
									<small class="p-error" *ngIf="submitted && !product.item.description">La description
										est
										obligatoire.</small>
								</div>
								<div *ngIf="typeSaisieInventaire()" class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3">
									<button pButton pRipple icon="pi pi-search"
										class="p-button-rounded p-button-primary mr-2 mt-3"
										(click)="RechercherItem()"></button>
								</div>
							</div>
							<div class="formgrid grid">
								<!-- 	<div class="flex flex-column gap-2 my-2">
								<label for="description">Description</label>
								<textarea id="description" pInputTextarea [(ngModel)]="product.description" required rows="3" cols="20"></textarea>
							</div>
							<div class="flex flex-column gap-2 my-2">
								<label for="inventoryStatus">Inventory Status</label>
								<p-dropdown [(ngModel)]="product.inventoryStatus" inputId="inventoryStatus" [options]="statuses" placeholder="Select">
									<ng-template let-option pTemplate="item">
										<span [class]="'product-badge status-' + option.value">{{option.label}}</span>
									</ng-template>
								</p-dropdown>
							</div> -->
								<div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3">
									<label for="noFournisseur">No pièce fournisseur</label>
									<p-inputNumber id="noFournisseur" [(ngModel)]="product.noFournisseur">
									</p-inputNumber>
								</div>

							</div>
							<div class="formgrid grid">
								<div class="flex flex-column gap-2 my-2 col-12 lg:col-3">
									<label for="quantity">Quantite</label>
									<p-inputNumber id="quantity" [(ngModel)]="product.quantite"></p-inputNumber>
								</div>
								<div class="flex flex-column gap-2 my-2 col-12 lg:col-3">
									<label for="price">Prix</label>
									<p-inputNumber id="price" [(ngModel)]="product.prix" mode="currency" currency="USD"
										locale="en-US"></p-inputNumber>
								</div>
								<div class="flex flex-column gap-2 my-2 col-12 lg:col-3">
									<label for="quantity">No de projet</label>
									<p-inputNumber id="quantity" [(ngModel)]="product.noProjet"></p-inputNumber>
								</div>
								<div class="flex flex-column gap-2 my-2 col-12 lg:col-3">
									<label for="price">Code d'activité</label>
									<p-inputNumber id="price" [(ngModel)]="product.codeActivite"></p-inputNumber>
								</div>

							</div>
						</ng-template>

						<ng-template pTemplate="footer">
							<button pButton pRipple label="Annuler" icon="pi pi-times" class="p-button-text"
								(click)="hideDialog()"></button>
							<button pButton pRipple label="Ajouter" icon="pi pi-check" class="p-button-text"
								(click)="saveProduct(false)"></button>
							<button pButton pRipple label="Ajouter et fermer" icon="pi pi-check" class="p-button-text"
								(click)="saveProduct(true)"></button>
						</ng-template>
					</p-dialog>

				</p-tabPanel>
			</p-tabView>
		</div>
	</div>


</div>