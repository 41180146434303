<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<p-table [value]="options" editMode="row" [styleClass]="'p-datatable-sm'" [tableStyle]="{'min-width': '50rem'}">
    <ng-template pTemplate="body" let-option let-index="rowIndex" let-editing="modification">
        <tr>
            <td [pEditableColumn]="option.description" pEditableColumnField="description" style="width: 40%">
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <input pInputText type="text" 
                        placeholder="Entrez une description" 
                        [(ngModel)]="option.description" 
                        [disabled]="readonly" 
                        (change)="surChangementDescription(index)" />
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{option.description == '' ? 'Entrez une description' : option.description}}
                    </ng-template>
                </p-cellEditor>
            </td>
            <td [pEditableColumn]="option.montantPieces" pEditableColumnField="montantPieces" style="width: 25%">
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <p-inputNumber 
                        [(ngModel)]="option.montantPieces"  
                        (change)="this.onChange.emit(option)"
                        mode="currency" 
                        currency="CAD" 
                        locale="fr-CA" 
                        [showClear]="true"
                        [disabled]="readonly" /> 
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{option.montantPieces | number : '1.2-2'}} $ 
                    </ng-template>
                </p-cellEditor>
            </td>
            <td [pEditableColumn]="option.nbHeures" pEditableColumnField="nbHeures" style="width: 30%">
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <input pInputText type="number" [(ngModel)]="option.nbHeures" (change)="this.onChange.emit(option)" [disabled]="readonly" />
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{option.nbHeures}}h
                    </ng-template>
                </p-cellEditor>
            </td>
            <td  style="width: 8rem;">
                <p-radioButton [name]="'option'" [value]="option.id" [(ngModel)]="_optionSelectionnee" [disabled]="(readonly || disabled || option.description == '') && !modifySelection" (click)="changementOption(option)"></p-radioButton>
            </td>
            <td style="width: 4rem;" *ngIf="!readonly && index != options.length - 1">
                <div class="flex justify-content-center">
                    <button pButton pRipple text="true" rounded="true" type="button" icon="pi pi-trash" class="p-button-danger"
                    (click)="removeOption(option)" [disabled]="options.length == 1" ></button>
                </div>
            </td>
        </tr>
    </ng-template>
</p-table>