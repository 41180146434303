import { Component, ViewChild, ElementRef, ɵConsole } from "@angular/core"; import { Location } from "@angular/common";
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { BlockUIModule } from 'primeng/blockui';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table/table';
import { HostListener } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from 'src/app/base/base-component';
import { CodeProjetEntite } from 'src/app/entites/code-projet';
import { CodeActiviteEntite } from 'src/app/entites/code-activite';
import { ProfilService } from 'src/app/services/profil-service';
import { AppMainComponent } from 'src/app/app.main.component';
import { ItemService } from 'src/app/services/item-service';
import { AchatService } from 'src/app/services/achat-service';
import { FournisseurService } from 'src/app/services/fournisseur-service';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { CleValeur } from 'src/app/entites/cleValeur';

export interface ParametresInfoBonLocation {
    selectionCodeProjet?: number;
    selectionCodeActivite?: number;
}

export interface ResultatInfoBonLocation {
    selectionCodeProjet: number;
    selectionCodeActivite: number;
    selectionFrequence: string;
}

@Component({
    templateUrl: './info-bon-location.component.html'
})
export class InfoBonLocationComponent extends BaseComponent {

    donnees: ParametresInfoBonLocation;

    listeCodeProjet: CodeProjetEntite[];
    selectionCodeProjet: CodeProjetEntite;

    listeCodeActivite: CodeActiviteEntite[];
    selectionCodeActivite: CodeActiviteEntite;

    frequences: CleValeur[];
    selectionFrequence: CleValeur;
    
    submitted: boolean;

    constructor(
        profilService: ProfilService,
        route: ActivatedRoute, 
        routerService: Router,
		appMain: AppMainComponent, location: Location, 
        public itemService: ItemService,
        public achatService: AchatService,
        public fournisseurService: FournisseurService,
        public messageService: MessageService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public sdb: DonneesBaseService) {
        super(sdb, profilService, route, routerService, appMain);
    }

    ngOnInit() {

        this.donnees = this.config.data;

        this.listeCodeProjet = this.sdb.CodesProjet();

        this.frequences = [
            {cle: 'N', valeur:'Aucune'},
            {cle: 'J', valeur:'Jours'},
            {cle: 'H', valeur:'Semaine'},
            {cle: 'M', valeur:'Mois'},
            {cle: 'A', valeur:'Année'},
            {cle: 'D', valeur:'Détaillée'}
        ]
        this.selectionFrequence = this.frequences[0];

        if (this.donnees.selectionCodeProjet) {
            this.selectionCodeProjet = this.listeCodeProjet.find(x => x.codeProjetId == this.donnees.selectionCodeProjet);
            this.surChangementCodeProjet();

            if (this.donnees.selectionCodeActivite) {
                this.selectionCodeActivite = this.listeCodeActivite.find(x => x.codeActiviteId == this.donnees.selectionCodeActivite);
            }

        }

        this.CompleterChargement();
    }

    annuler() {
        this.ref.close(null);
    }

    surChangementCodeProjet() {
        this.selectionCodeActivite = null;
        this.listeCodeActivite = this.selectionCodeProjet.codesActivite;
    }

    confirmer() {

        this.submitted = true;
        if (!this.valider()) {
            this.messageService.add({ key: 'creationBonCommande', severity: 'error', summary: 'Erreurs', detail: 'Veuillez corriger les erreurs de saisie.' });
            return;
        }

        this.ref.close({
            selectionCodeProjet: this.selectionCodeProjet.codeProjetId,
            selectionCodeActivite: this.selectionCodeActivite.codeActiviteId,
            selectionFrequence: this.selectionFrequence.cle

        });
        
    }

    valider() {
        return this.selectionCodeProjet && this.selectionCodeActivite;
    }
}