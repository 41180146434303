<p-toast></p-toast>
<p-contextMenu #cm [model]="menuContextuelRequisitions"></p-contextMenu>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<div class="grid table-demo">


	<!-- 	<button *ngIf="(auth.isAuthenticated$ | async) == false" class="btn btn-primary btn-block" (click)="loginWithRedirect()">
		Log in
	  </button>

	  <button class="btn btn-primary btn-block" (click)="logout()">
		Log out
	  </button>

	  <button class="btn btn-primary btn-block" (click)="getProfile()">
		Get profile
	  </button> -->
<!-- 
	  <div class="col-12">


		<div class="fluid formgrid grid">

			<div class="flex flex-column gap-2 my-2 col-12">
				<span class="p-input-icon-left">
					<i class="pi pi-search"></i>
					<input type="text" class="shadow-4" pInputText placeholder="Recherche"
						(keyup.enter)="Rechercher()">
				</span>
			</div>
		</div>

	</div>
 -->

	<div class="col-12" *ngIf="!rechercheDeclenchee">
		<div>
			<div class="grid">
				<div *ngIf="estAutorise('items')" class="col-12 md:col-6 lg:col-3">
					<p-card styleClass="shadow-3 cursor-pointer hover:surface-hover h-full" routerLink="/items">
						<div class="flex flex-column justify-content-center align-items-center">
							<i class="pi pi-id-card" style="font-size: 2rem"></i>
							<h4>Items</h4>
						</div>
					</p-card>
				</div>
				<div *ngIf="estAutorise('reparations')" class="col-12 md:col-6 lg:col-3">
					<p-card styleClass="shadow-3 cursor-pointer hover:surface-hover h-full" routerLink="/items/reparations">
						<div class="flex flex-column justify-content-center align-items-center">
							<i class="pi pi-id-card" style="font-size: 2rem"></i>
							<h4>Réparations</h4>
						</div>
					</p-card>
				</div>
				<div *ngIf="estAutorise('emplacements')" class="col-12 md:col-6 lg:col-3">
					<p-card styleClass="shadow-3 cursor-pointer hover:surface-hover h-full" routerLink="/pilotage/emplacements">
						<div class="flex flex-column justify-content-center align-items-center">
							<i class="pi pi-map" style="font-size: 2rem"></i>
							<h4>Emplacements</h4>
						</div>
					</p-card>
				</div>
				<div *ngIf="estAutorise('garanties')" class="col-12 md:col-6 lg:col-3">
					<p-card styleClass="shadow-3 cursor-pointer hover:surface-hover h-full" routerLink="/garanties">
						<div class="flex flex-column justify-content-center align-items-center">
							<i class="pi pi-shield" style="font-size: 2rem"></i>
							<h4>Garanties</h4>
						</div>
					</p-card>
				</div>
	
				<div *ngIf="estAutorise('precommande')" class="col-12 md:col-6 lg:col-3">
					<p-card styleClass="shadow-3 cursor-pointer hover:surface-hover h-full" routerLink="/precommandes">
						<div class="flex flex-column justify-content-center align-items-center">
							<i class="pi pi-pencil" style="font-size: 2rem"></i>
							<h4>Pré-commandes</h4>
						</div>
					</p-card>
				</div>
				<div *ngIf="estAutorise('approbation_precommande')" class="col-12 md:col-6 lg:col-3">
					<p-card styleClass="shadow-3 cursor-pointer hover:surface-hover h-full" routerLink="/approbationprecommandes">
						<div class="flex flex-column justify-content-center align-items-center">
							<i class="pi pi-check-square" style="font-size: 2rem"></i>
							<h4>Approbation pré-commandes</h4>
						</div>
					</p-card>
				</div>
				<div *ngIf="estAutorise('commandes')" class="col-12 md:col-6 lg:col-3">
					<p-card styleClass="shadow-3 cursor-pointer hover:surface-hover h-full" routerLink="/commandes">
						<div class="flex flex-column justify-content-center align-items-center">
							<i class="pi pi-book" style="font-size: 2rem"></i>
							<h4>Commandes</h4>
						</div>
					</p-card>
				</div>
				<div *ngIf="estAutorise('commandes')" class="col-12 md:col-6 lg:col-3">
					<p-card styleClass="shadow-3 cursor-pointer hover:surface-hover h-full" routerLink="/commandescomplet">
						<div class="flex flex-column justify-content-center align-items-center">
							<i class="pi pi-book" style="font-size: 2rem"></i>
							<h4>Commandes (complet)</h4>
						</div>
					</p-card>
				</div>
				<div *ngIf="estAutorise('requisitions')" class="col-12 md:col-6 lg:col-3">
					<p-card styleClass="shadow-3 cursor-pointer hover:surface-hover h-full" routerLink="/requisitions">
						<div class="flex flex-column justify-content-center align-items-center">
							<i class="pi pi-tablet" style="font-size: 2rem"></i>
							<h4>Réquisitions</h4>
						</div>
					</p-card>
				</div>
			
				<div *ngIf="estAutorise('fournisseurs')" class="col-12 md:col-6 lg:col-3">
					<p-card styleClass="shadow-3 cursor-pointer hover:surface-hover h-full" routerLink="/fournisseurs">
						<div class="flex flex-column justify-content-center align-items-center">
							<i class="pi pi-user" style="font-size: 2rem"></i>
							<h4>Fournisseurs</h4>
						</div>
					</p-card>
				</div>
				<div *ngIf="estAutorise('bdcs') || estAutorise('bdcs_proprietaire')" class="col-12 md:col-6 lg:col-3">
					<p-card styleClass="shadow-3 cursor-pointer hover:surface-hover h-full" routerLink="/achats">
						<div class="flex flex-column justify-content-center align-items-center">
							<i class="pi pi-shopping-cart" style="font-size: 2rem"></i>
							<h4>Bons de commande</h4>
						</div>
					</p-card>
				</div>
				<div *ngIf="estAutorise('assurance_qualite')" class="col-12 md:col-6 lg:col-3">
					<p-card styleClass="shadow-3 cursor-pointer hover:surface-hover h-full" routerLink="/assurancequalite/nonconformite">
						<div class="flex flex-column justify-content-center align-items-center">
							<i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i>
							<h4>Non-conformités</h4>
						</div>
					</p-card>
				</div>
				<div *ngIf="estAutorise('unites')" class="col-12 md:col-6 lg:col-3">
					<p-card styleClass="shadow-3 cursor-pointer hover:surface-hover h-full" routerLink="/mecanique/unites">
						<div class="flex flex-column justify-content-center align-items-center">
							<i class="pi pi-id-card" style="font-size: 2rem"></i>
							<h4>Unités</h4>
						</div>
					</p-card>
				</div>
				<div *ngIf="estAutorise('pieces')" class="col-12 md:col-6 lg:col-3">
					<p-card styleClass="shadow-3 cursor-pointer hover:surface-hover h-full" routerLink="/mecanique/pieces">
						<div class="flex flex-column justify-content-center align-items-center">
							<i class="pi pi-id-card" style="font-size: 2rem"></i>
							<h4>Pièces</h4>
						</div>
					</p-card>
				</div>
				<div *ngIf="estAutorise('bonstravail') || estAutorise('temp_mec')" class="col-12 md:col-6 lg:col-3">
					<p-card styleClass="shadow-3 cursor-pointer hover:surface-hover h-full" routerLink="/mecanique/bonstravail">
						<div class="flex flex-column justify-content-center align-items-center">
							<i class="pi pi-check-square" style="font-size: 2rem"></i>
							<h4>Bons de travail</h4>
						</div>
					</p-card>
				</div>
				<div *ngIf="estAutorise('temp_mec')" class="col-12 md:col-6 lg:col-3">
					<p-card styleClass="shadow-3 cursor-pointer hover:surface-hover h-full" routerLink="/mecanique/planification">
						<div class="flex flex-column justify-content-center align-items-center">
							<i class="pi pi-calendar" style="font-size: 2rem"></i>
							<h4>Planification</h4>
						</div>
					</p-card>
				</div>
				<div *ngIf="estAutorise('heures_saisie')" class="col-12 md:col-6 lg:col-3">
					<p-card styleClass="shadow-3 cursor-pointer hover:surface-hover h-full" routerLink="/mecanique/heures">
						<div class="flex flex-column justify-content-center align-items-center">
							<i class="pi pi-clock" style="font-size: 2rem"></i>
							<h4>Heures</h4>
						</div>
					</p-card>
				</div>
				
			</div>
		</div>
	</div>

	

</div>