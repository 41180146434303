<p-toast key="detailDocument"></p-toast>


<p-card *ngIf="!EstCharge()">
    <div class="fluid formgrid grid">
        <div class="flex flex-column gap-2 col-12 text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-card>

<div #contenu *ngIf="EstCharge()">

    <div class="fluid formgrid grid my-2">
        <div class="flex flex-column gap-2 col-12 md:col-6 lg:col-3">
            <label for="lastname">Date<span class="requis">*</span></label>
            <p-calendar appendTo="body" showIcon=true [(ngModel)]="document.date" dateFormat="yy-mm-dd" [yearNavigator]="true" [monthNavigator]="true" yearRange="2000:2040" ></p-calendar>
            <small class="p-error" *ngIf="submitted && !document.date">La date est obligatoire.</small>
        </div>
        <div class="flex flex-column gap-2 col-12 md:col-6 lg:col-3">
            <label for="nom">Nom<span class="requis">*</span></label>
            <input id="nom" type="text" pInputText 
                [(ngModel)]="document.nom">
                <small class="p-error" *ngIf="submitted && !document.nom">Le nom est obligatoire.</small>
        </div>
        
    </div>
    <div class="fluid formgrid grid my-2">
        <div class="flex flex-column gap-2 col-12">
            <label for="description">Description</label>
            <input id="description" type="text" pInputText
                [(ngModel)]="document.description">
        </div>
        
    </div>
    <div class="text-right my-2">

        <button pButton pRipple label="Sauvegarder" icon="pi pi-save" class="p-button-primary"
        (click)="sauvegarder()"></button>
        <button pButton pRipple label="Annuler" icon="pi pi-ban" class="p-button-text" (click)="annuler()"></button>
        

    </div>

</div>