import { Component, OnInit, ViewChild } from "@angular/core"; import { Location } from "@angular/common";
import { Table } from 'primeng/table';
import { MessageService, ConfirmationService } from 'primeng/api';
import { NonConformiteSimpleEntite } from '../../entites/non-conformite-simple';
import { NonConformiteService } from '../../services/non-conformite-service';
import { BaseComponent } from '../../base/base-component';
import { DonneesBaseService } from '../../services/donnees-base-service';
import { ProfilService } from '../../services/profil-service';
import { ActivatedRoute } from '@angular/router';
import { AppMainComponent } from '../../app.main.component';
import { Router } from '@angular/router';
import { BreadcrumbService } from '../../app.breadcrumb.service';
import { Statut } from '../../entites/statut';
import { DialogService } from 'primeng/dynamicdialog';
import { AjoutNonConformiteComponent } from './ajout-non-conformite.component';
import { MenuItem } from 'primeng/api';


@Component({
  selector: 'app-non-conformite',
  styleUrls: ['./non-conformite.component.scss'],
  templateUrl: './non-conformite.component.html',
  providers: [MessageService, ConfirmationService, NonConformiteService, DialogService],
})
export class NonConformiteComponent extends BaseComponent implements OnInit {
  nonConformites: NonConformiteSimpleEntite[];
  selectedNonConformites: NonConformiteSimpleEntite[];
  nonConformite: NonConformiteSimpleEntite;
  nonConformiteDialog: boolean;
  statuses: any[];
  contenuMenuContextuelNonConformite: any[];
  menuContextuelNonConformite: MenuItem;

  @ViewChild('dt') table: Table;

  statuts: Statut[] = this.sdb.Statuts();

  constructor(
    sdb: DonneesBaseService,
    profilService: ProfilService,
    route: ActivatedRoute, 
		appMain: AppMainComponent, location: Location, 
    private nonConformiteService: NonConformiteService,
    private messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    public routerService: Router,
    public dialogService: DialogService,
  ) {super(sdb, profilService, route, routerService, appMain)
    this.breadcrumbService.setItems([
      { label: 'Non-conformités' }
    ]);
  }

  ngOnInit() {
    this.colonnesDisponibles = [
      {base: true, entete: 'Titre', champ: 'titre', afficheParDefaut: true},
      {base: true, entete: 'Code Projet', champ: 'codeProjet', afficheParDefaut: true},
      {base: true, entete: 'Statut', champ: 'statut', afficheParDefaut: true},
      {base: true, entete: 'Date d\'emission', champ: 'dateEmission', afficheParDefaut: true},
      {base: true, entete: 'Date de détection', champ: 'dateDetection', afficheParDefaut: false},
      {base: true, entete: 'Objet', champ: 'objet', afficheParDefaut: false},
      {base: true, entete: 'Employé', champ: 'employe', afficheParDefaut: true},
      {base: true, entete: 'Approbation', champ: 'approbation', afficheParDefaut: true},
    ];
    this.colonnesSelectionnees = this.colonnesDisponibles.filter(colonne => colonne.afficheParDefaut);
    this.loadNonConformites();
  }

  loadNonConformites() {
    this.nonConformiteService.Obtenir().then(
      (data) => {
        this.nonConformites = data.valeur;
        this.CompleterChargement();
      },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Erreur', detail: 'Erreur lors du chargement des non-conformités' });
      }
    );
  }

  hideDialog() {
    this.nonConformiteDialog = false;
  }

  clearFilters(table: Table) {
    table.clear();
  }

  surChangementColonnes(event) {
    this.colonnesSelectionnees = event.value;
  }

  newNonConformite() {
    this.dialogService.open(AjoutNonConformiteComponent, {
      header: 'Nouveau rapport de Non-conformité',
      width: '90%'
    }).onClose.subscribe((nonConformite: NonConformiteSimpleEntite) => {
        if (nonConformite) {
          this.routerService.navigateByUrl('assurancequalite/nonconformite/' + nonConformite.idNonConformite);
        } else {
          this.breadcrumbService.setItems([
            { label: 'Non-conformités' }
          ]);
          this.routerService.navigateByUrl('assurancequalite/nonconformite');
        }
    });
  }

  openNonConformite(idNonConformite: number) {
    this.routerService.navigateByUrl('assurancequalite/nonconformite/' + idNonConformite);
  }

  openHamburgerMenu() {
    this.contenuMenuContextuelNonConformite = [
      {
          label: 'Dupliquer',
          icon: 'pi pi-fw pi-copy',
          command: () => {}
      }
    ];
    this.menuContextuelNonConformite.toggle(event);
  }
}