import { Component, ViewChild } from "@angular/core"; import { Location } from "@angular/common";
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from 'src/app/base/base-component';
import { EntretienPreventifEntite } from 'src/app/entites/mecanique/entretien-preventif';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { ProfilService } from 'src/app/services/profil-service';
import { AppMainComponent } from 'src/app/app.main.component';
import { EntretienPreventifService } from 'src/app/services/entretien-preventif-service';

export interface ParametresSelection {
    selection?: number;
}

@Component({
    templateUrl: './selection-entretien-preventif.component.html',
})
export class SelectionEntretienPreventifComponent extends BaseComponent {

    donnees: ParametresSelection;

    liste: EntretienPreventifEntite[];
    selection: EntretienPreventifEntite;

    submitted: boolean = false;

    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute,
        routerService: Router,
        appMain: AppMainComponent, location: Location, 
        private entretienPreventifService: EntretienPreventifService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig) {
        super(sdb, profilService, route, routerService, appMain);
    }


    ngOnInit() {

        this.donnees = this.config.data;

        this.entretienPreventifService.ObtenirArchivesParItem(this.config.data).then(x => {
            this.liste = x.valeur;
            this.CompleterChargement();
        })
    }

    confirmer() {

        this.submitted = true;

        if(!this.selection) {
            return;
        }
        this.ref.close(this.selection);
    }

    annuler() {
        this.ref.close(null);
    }
}