import { Component, OnInit, SecurityContext, Input } from "@angular/core"; import { Location } from "@angular/common";
import { FormControl, FormGroup, FormArray, AbstractControl } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';
import { DomSanitizer } from '@angular/platform-browser';
import { EModeAffichage } from './mode-affichage';
import { DonneesBaseService } from '../services/donnees-base-service';
import { DefinitionColonne } from '../entites/definition-colonne';
import { ProfilService } from '../services/profil-service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppMainComponent } from '../app.main.component';
import { CategorieEntite } from '../entites/categorie';
import EventEmitter from "events";
import { Observable, tap } from "rxjs";

function delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));

}

@Component({
    template: '',
})
export class BaseComponent {

    protected titrePage: string;
    s
    protected estModifiable: boolean = true;

    protected readonly CATEGORIE_ACCESSOIRE: number = 1;
    protected readonly CATEGORIE_EQUIPEMENT: number = 2;
    protected readonly CATEGORIE_INVENTAIRE: number = 3;
    protected readonly CATEGORIE_OUTILLAGES: number = 4;

    constructor(protected sdb: DonneesBaseService,
        protected profilService: ProfilService,
        protected route: ActivatedRoute,
        protected routerService: Router,
        public appMain: AppMainComponent
    ) {
        this.modeAffichage = EModeAffichage.Consultation;
    }

    // SÉCURITÉ

    estAutorise(code: string): boolean {
        return this.profilService.estAutoriseTache(code);
    }

    verifierAccesPage(tache: string) {
        if (!this.estAutorise(tache)) {
            this.routerService.navigateByUrl('/access');
        }
    }

    verifierAccesPageAuMoisUnAcces(taches: string[]) {

        var autorise: boolean = false;
        taches.forEach(t => {
            if (this.estAutorise(t)) {
                autorise = true;
            }
        });
        if (!autorise) {
            this.routerService.navigateByUrl('/access');
        }
    }

    //CHARGEMENT

    chargementComplete: boolean = false;

    DemarrerChargement() {
        this.chargementComplete = false;
    }

    CompleterChargement(titrePage: string = '') {

        this.chargementComplete = true;
        this.titrePage = titrePage;

        if (titrePage.length > 0) {
            this.route.url.subscribe(x => {
                this.profilService.ajouterHistorique({
                    description: titrePage,
                    url: x.join('/')
                })
            });
        }
    }

    EstCharge() {
        return this.chargementComplete;
    }

    public Bloquer() {
        this.appMain.Bloquer();
    }

    public Debloquer() {
        this.appMain.Debloquer();
    }

    // MODE AFFICHAGE

    modeAffichage: EModeAffichage;

    public modeConsultation(): boolean {
        return this.modeAffichage == EModeAffichage.Consultation;
    }

    public modeAjout(): boolean {
        return this.modeAffichage == EModeAffichage.Ajout;
    }

    public modeModification(): boolean {
        return this.modeAffichage == EModeAffichage.Modification;
    }

    public modeAjoutModification(): boolean {
        return this.modeAffichage == EModeAffichage.Ajout || this.modeAffichage == EModeAffichage.Modification;
    }

    public Consulter() {
        this.modeAffichage = EModeAffichage.Consultation;
    }

    public Modifier() {
        this.modeAffichage = EModeAffichage.Modification;
    }

    public Ajouter() {
        this.modeAffichage = EModeAffichage.Ajout;
    }

    public newGuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0,
                v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    // FAVORIS



    //GESTION DES COLONNES

    colonnesDisponibles: DefinitionColonne[];
    colonnesSelectionnees: DefinitionColonne[];

    EstColonneSelectionnee(champ: string): boolean {
        return this.colonnesSelectionnees.filter(x => x.champ == champ).length > 0;
    }


    @Input() get selectedColumns(): any[] {
        return this.colonnesSelectionnees;
    }

    set selectedColumns(val: any[]) {
        this.colonnesSelectionnees = this.colonnesDisponibles.filter(col => val.includes(col));
    }



    ////////

    public naviguer(url: string) {

        this.routerService.navigateByUrl('/', { skipLocationChange: true }).then(() =>
            this.routerService.navigate([url]));

    }

    public estNulOuUndefined(value: any) {
        return value == null || value == undefined;
    }

    public estChampInvalideRequis(formulaire: FormGroup, control: string): boolean {
        return formulaire.get(control).invalid
            && (formulaire.get(control).dirty || formulaire.get(control).touched)
            && formulaire.get(control).errors.required;
    }
    public normaliserValeur(champ: AbstractControl): string {
        if (champ.value === undefined || champ.value === null || champ.value === '') {
            return null;
        } else {
            return champ.value;
        }
    }

    public normaliserValeurChaine(valeur: string): string {
        if (valeur === undefined || valeur === null || valeur === '') {
            return null;
        } else {
            return valeur;
        }
    }

    public normaliserValeurChainePourexportationExcel(valeur: string): string {
        if (valeur === undefined || valeur === null || valeur === '') {
            return null;
        } else {
            return valeur;
        }
    }

    public validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach((field) => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                if (control.enabled) {
                    control.markAsTouched({ onlySelf: true });
                }
            } else if (control instanceof FormGroup) {
                this.validateAllFormFields(control);
            }
        });
    }



    public async scrollToFirstInvalidControl() {

        await delay(200);

        const firstInvalidControl: HTMLElement = document.querySelector(
            'form [aria-invalid=true]'
        );

        window.scroll({
            top: this.getTopOffset(firstInvalidControl),
            left: 0,
            behavior: 'smooth'
        });

        firstInvalidControl.focus();
    }

    private getTopOffset(controlEl: HTMLElement): number {
        const labelOffset = 50;
        return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
    }

    public formToConsole(formGroup: FormGroup, pad: string = '') {
        Object.keys(formGroup.controls).forEach((field) => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                console.log(pad + field + ' -> enabled: ' + control.enabled + ', valid: ' + control.valid + ' value: ' + control.value + (control.enabled && control.invalid ? '                    **********' : ''));
                if (control.invalid) {
                    console.log(control.errors);
                }
            } else if (control instanceof FormGroup) {
                console.log(pad + field + ' -> enabled: ' + control.enabled + ', valid: ' + control.valid + (control.enabled && control.invalid ? '                    **********' : ''));
                if (control.invalid) {
                    console.log(control.errors);
                }
                this.formToConsole(control, pad + '   ');
            } else if (control instanceof FormArray) {
                for (let control2 of control.controls) {
                    if (control2 instanceof FormControl) {
                        console.log(pad + field + ' -> enabled: ' + control.enabled + ', valid: ' + control.valid + ' value: ' + control.value + (control.enabled && control.invalid ? '                    **********' : ''));
                        if (control.invalid) {
                            console.log(control.errors);
                        }
                    }
                    if (control2 instanceof FormGroup) {
                        console.log(pad + field + ' -> enabled: ' + control.enabled + ', valid: ' + control.valid + (control.enabled && control.invalid ? '                    **********' : ''));
                        if (control.invalid) {
                            console.log(control.errors);
                        }
                        this.formToConsole(control2, pad + '   ');
                    }
                }
            }
        });
    }

    protected assignFilter(name: string, value: any = null, setEvent: Observable<any> = null): any {
        const filter = {
            _value: value,
            _name: name,
            _self: this,
            get value(): any {
                return filter._value;
            },
            set value(value: any) {
                filter._self.routerService.navigate([], {
                    queryParams: {
                        [name]: JSON.stringify(value)
                    },
                    queryParamsHandling: 'merge',
                });
            }
        };

        if (setEvent) {
            setEvent.pipe(
                tap(value => {
                    filter.value = value.filters;
                })
            ).subscribe();
        }

        this.route.queryParams.subscribe(params => {
            if (params[filter._name]) {
                if (params[filter._name] == 'true') {
                    filter._value = true;
                } else if (params[filter._name] == 'false') {
                    filter._value = false;
                } else {
                    filter._value = JSON.parse(params[filter._name]);
                }
            } else {
                this.routerService.navigate([], {
                    queryParams: {
                        [filter._name]: JSON.stringify(value)
                    },
                    queryParamsHandling: 'merge',
                });
            }
        });

        return filter;
    }
}
