import { HttpClient, HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { Router } from "@angular/router";
import { MessageService } from "primeng/api";
import { Observable, Observer } from "rxjs";

export class BaseService {

    constructor(public http: HttpClient, public router: Router, public messageService: MessageService) { }

    public responseHandler = (response: HttpResponse<any>): Observable<HttpResponse<any>> => {
        switch (response.status) {
            case 201:
                this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Création effectuée avec succès' });
                return;
            case 202:
                this.messageService.add({ severity: 'info', summary: 'Info', detail: 'Opération en cours' });
                return;
            case 204:
                this.messageService.add({ severity: 'info', summary: 'Info', detail: 'Aucun contenu' });
                return;
            default:
                return;
        }
    }

  public errorHandler = (response: HttpResponse<any>, objet: string): Observable<HttpErrorResponse> => {
    switch (response.status) {
        case 400:
            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: `Erreur de requête pour ${objet}` });
            break;
        case 401:
            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: `Accès non autorisé pour ${objet}` });
            break;
        case 403:
            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: `Accès non autorisé pour ${objet}` });
            break;
        case 404:
            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: `Ressource non trouvée pour ${objet}` });
            break;
        case 405:
            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: `Méthode non autorisée pour ${objet}` });
            break;
        case 409:
            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: `La ressource existe déjà pour ${objet}` });
            break;
        case 500:
            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: `Erreur interne du serveur pour ${objet}` });
            break;
        default:
            this.router.navigate(['/error']);
        }
        return;
    }
}