<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-confirmPopup key="itemsnc"></p-confirmPopup>



<p-card *ngIf="!EstCharge()">
    <div class="fluid formgrid grid">
        <div class="flex flex-column gap-2 my-2 col-12 text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-card>

<div *ngIf="EstCharge()" class="grid">
    <div class="col-12">

        <div class="card">
            <p-table #dt [value]="items" [columns]="selectedColumns" dataKey="itemId" autoLayout="true" tableStyleClass="tableNormale"
                styleClass="p-datatable-sm p-datatable-striped" [rowHover]="true" [rows]="25" [paginator]="true" [rowsPerPageOptions]="[10,25,50,100]" [filterDelay]="0"
                [globalFilterFields]="['numero','description','statut','categorie','sousCategorie','marque','modele','noSerie','noPlaque']">
                <ng-template pTemplate="caption">
                    <div class="flex align-content-center justify-content-between">
                        <div>
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input #rechercheItem pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                (keyup.enter)="surEntreeRecherche()"
                                    placeholder="Recherche..." />
                            </span>              
                        </div>
                        <div>
                            <h5 class="titreTableau m-0">Items</h5>
                        </div>
                        <div>
                            <p-multiSelect [options]="colonnesDisponibles" [(ngModel)]="selectedColumns"
                                optionLabel="entete" (onChange)="surChangementColonnes($event)"
                                selectedItemsLabel="{0} colonnes sélectionnés" [style]="{minWidth: '200px'}"
                                placeholder="Colonnes..."></p-multiSelect>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                    <tr>

                        <th pSortableColumn="numero">
                            <div class="flex justify-content-between align-items-center">
                                Numéro
                                <p-sortIcon field="numero"></p-sortIcon>
                                <p-columnFilter type="text" field="numero" display="menu" class="ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="description">
                            <div class="flex justify-content-between align-items-center">
                                Description
                                <p-sortIcon field="description"></p-sortIcon>
                                <p-columnFilter type="text" field="description" display="menu" class="ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th>
                            <div class="flex justify-content-between align-items-center">
                                Statut
                                <p-columnFilter field="statut" matchMode="in" display="menu" [showMatchModes]="false"
                                    [showOperator]="false" [showAddButton]="false">
                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                        <p-multiSelect [ngModel]="value" [options]="statuts" placeholder="Any"
                                            (onChange)="filter($event.value)" optionLabel="name">
                                            <ng-template let-option pTemplate="item">
                                                <div class="multiselect-representative-option">

                                                    <span class="ml-1">{{option.nom}}</span>
                                                </div>
                                            </ng-template>
                                        </p-multiSelect>
                                    </ng-template>
                                </p-columnFilter>
                            </div>
                        </th>
                        <th *ngIf="EstColonneSelectionnee('categorie')" pSortableColumn="categorie.nom">
                            <div class="flex justify-content-between align-items-center">
                                Catégorie
                                <p-sortIcon field="categorie.nom"></p-sortIcon>
                                <p-columnFilter field="categorie.nom" matchMode="in" display="menu"
                                    [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                        <p-multiSelect [ngModel]="value" [options]="categories" placeholder="Any"
                                            (onChange)="filter($event.value)" optionLabel="name">
                                            <ng-template let-option pTemplate="item">
                                                <div class="multiselect-representative-option">
                                                    <span class="ml-1">{{option}}</span>
                                                </div>
                                            </ng-template>
                                        </p-multiSelect>
                                    </ng-template>
                                </p-columnFilter>
                            </div>
                        </th>
                        <th *ngIf="EstColonneSelectionnee('sousCategorie')" pSortableColumn="sousCategorie.nom">
                            <div class="flex justify-content-between align-items-center">
                                Sous-catégorie
                                <p-sortIcon field="sousCategorie.nom"></p-sortIcon>
                                <p-columnFilter type="text" field="sousCategorie" display="menu" class="ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th *ngIf="EstColonneSelectionnee('marque')" pSortableColumn="modele">
                            <div class="flex justify-content-between align-items-center">
                                Marque
                                <p-sortIcon field="modele"></p-sortIcon>
                                <p-columnFilter type="text" field="modele" display="menu" class="ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th *ngIf="EstColonneSelectionnee('modele')" pSortableColumn="modele">
                            <div class="flex justify-content-between align-items-center">
                                Modèle
                                <p-sortIcon field="modele"></p-sortIcon>
                                <p-columnFilter type="text" field="description" display="menu" class="ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th *ngIf="EstColonneSelectionnee('emplacement')" pSortableColumn="emplacement.nom">
                            <div class="flex justify-content-between align-items-center">
                                Emplacement
                                <p-sortIcon field="emplacement.nom"></p-sortIcon>
                                <p-columnFilter type="text" field="emplacement.nom" display="menu" class="ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th *ngIf="EstColonneSelectionnee('compagnie')" pSortableColumn="compagnie.nom">
                            <div class="flex justify-content-between align-items-center">
                                Compagnie
                                <p-sortIcon field="compagnie.nom"></p-sortIcon>
                                <p-columnFilter type="text" field="compagnie.nom" display="menu" class="ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th style="min-width: 4rem"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item let-columns="columns">
                    <tr [pContextMenuRow]="item">
                        <td>{{item.numero}}
                        </td>
                        <td>{{item.description}}</td>
                        <td><span [class]="'statut ' + item.statut.nom.toLowerCase()">
                                {{item.statut.nom}}
                            </span>
                        </td>
                        <td *ngIf="EstColonneSelectionnee('categorie')">
                            {{item.categorie.nom}}
                        </td>
                        <td *ngIf="EstColonneSelectionnee('sousCategorie')">
                            {{item.sousCategorie?.nom}}
                        </td>
                        <td *ngIf="EstColonneSelectionnee('marque')">
                            {{item.marque}}
                        </td>
                        <td *ngIf="EstColonneSelectionnee('modele')">
                            {{item.modele}}
                        </td>
                        <td *ngIf="EstColonneSelectionnee('emplacement')">
                            {{item.emplacement | nomEmplacement}}
                        </td>
                        <td *ngIf="EstColonneSelectionnee('compagnie')">
                            {{item.compagnie.nom}}
                        </td>
                        <td class="celluleTableau">
                            <div class="flex">
                                <button *ngIf="estAutorise('requisition_creer') && item.emplacement?.emplacementId != 10000" pButton pRipple icon="pi pi-arrow-left"
                                    class="p-button-primary mr-2 boutonGrille"
                                    (click)="Retourner(item)"></button>
                            </div>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="8">Aucun item.</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>