import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from "@angular/core"; import { Location } from "@angular/common";
import { Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { ReponseBase } from './reponse-base';
import { ItemService } from './item-service';
import { EntretienPreventifEntite } from '../entites/mecanique/entretien-preventif';
import { ESeverites } from '../entites/enums/severites';
import { HistoriqueEntretienPreventifEntite } from '../entites/mecanique/historique-entretien-preventif';
import { DonneesBaseService } from './donnees-base-service';
import { EStatuts } from '../entites/enums/statuts';
import { BonTravailEntite } from '../entites/mecanique/bon-travail';
import { RequeteAjouterEtapesBonTravail } from './requetes/requete-ajouter-etapes-bon-travail';
import { ReponseAjouterEtapesBonTravail } from './reponses/reponse-ajouter-etapes-bon-travail';
import { MajService } from './maj-service';
import { RequeteCreerBonTravailPartiel } from './requetes/requete-creer-bon-travail-partiel';
import { EtapeBonTravailEntite } from '../entites/mecanique/etape-bon-travail';
import { BaseService } from './base-service';
import { MessageService } from 'primeng/api';
@Injectable({ providedIn: 'root' })
export class BonTravailService extends BaseService {

  private obtenirUrl = environment.apiBaseUrl + 'bontravail';
  private obtenirSoumissionsUrl = environment.apiBaseUrl + 'bontravail/soumissions';
  private obtenirParItemUrl = environment.apiBaseUrl + 'bontravail/item/{id}';
  private obtenirOuvertsParItemUrl = environment.apiBaseUrl + 'bontravail/item/{id}/ouverts';
  private detailUrl = environment.apiBaseUrl + 'bontravail/{id}';
  private creerUrl = environment.apiBaseUrl + 'bontravail';
  private modifierUrl = environment.apiBaseUrl + 'bontravail/modifier';
  private envoyerapprobationUrl = environment.apiBaseUrl + 'bontravail/envoyerapprobation';
  private envoyerapprobationSoumissionUrl = environment.apiBaseUrl + 'bontravail/{id}/envoyerapprobationSoumission';
  private completerUrl = environment.apiBaseUrl + 'bontravail/completer';
  private supprimerUrl = environment.apiBaseUrl + 'bontravail/{id}';
  private ajouterEtapesUrl = environment.apiBaseUrl + 'bontravail/etapes';
  private annulerEPUrl = environment.apiBaseUrl + 'bontravail/supprimerentretiencomplete/{etapeId}';
  private creerPartielUrl = environment.apiBaseUrl + 'bontravail/creerpartiel';
  private convertirEtapeBonTravailToManuelUrl = environment.apiBaseUrl + 'bontravail/convertiretapebontravailtomanuel/{etapeId}';
  private transformerSoumissionUrl = environment.apiBaseUrl + 'bontravail/transformersoumission';
  

  public httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(
    private majService: MajService,
    public sdb: DonneesBaseService,
    public itemService: ItemService,
    public http: HttpClient,
    public router: Router,
    public messageService: MessageService
  ) { 
      super(http, router, messageService);
    }

    public Obtenir(): Promise<ReponseBase<BonTravailEntite[]>> {
      var p = this.http.get<ReponseBase<BonTravailEntite[]>>(this.obtenirUrl)
        .toPromise();
        p.catch(() => this.router.navigateByUrl('error'));
        return p;
    }

    public ObtenirSoumissions(): Promise<ReponseBase<BonTravailEntite[]>> {
      var p = this.http.get<ReponseBase<BonTravailEntite[]>>(this.obtenirSoumissionsUrl)
        .toPromise();
        p.catch(() => this.router.navigateByUrl('error'));
        return p;
    }

    public ObtenirParItem(id: number): Promise<ReponseBase<BonTravailEntite[]>> {
      var p = this.http.get<ReponseBase<BonTravailEntite[]>>(this.obtenirParItemUrl.replace('{id}', id.toString()))
        .toPromise();
        p.catch(() => this.router.navigateByUrl('error'));
        return p;
    }

    public ObtenirOuvertsParItem(id: number): Promise<ReponseBase<BonTravailEntite[]>> {
      var p = this.http.get<ReponseBase<BonTravailEntite[]>>(this.obtenirOuvertsParItemUrl.replace('{id}', id.toString()))
        .toPromise();
        p.catch(() => this.router.navigateByUrl('error'));
        return p;
    }


    public ObtenirDetail(id: number): Promise<ReponseBase<BonTravailEntite>> {
      var p = this.http.get<ReponseBase<BonTravailEntite>>(this.detailUrl.replace('{id}', id.toString()))
        .toPromise();
        p.catch(() => this.router.navigateByUrl('error'));
        return p;
    }

    public Creer(entretien: BonTravailEntite): Promise<ReponseBase<BonTravailEntite>> {
      var p = this.http.post<ReponseBase<BonTravailEntite>>(this.creerUrl, JSON.stringify(entretien), this.httpOptions)
        .toPromise();
        p.then(() => this.majService.Traiter());
        p.catch(() => this.router.navigateByUrl('error'));
        return p;
    }

    public TransformerSoumission(entretien: BonTravailEntite): Promise<ReponseBase<BonTravailEntite>> {
      var p = this.http.post<ReponseBase<BonTravailEntite>>(this.transformerSoumissionUrl, JSON.stringify(entretien), this.httpOptions)
        .toPromise();
        p.then(() => this.majService.Traiter());
        p.catch(() => this.router.navigateByUrl('error'));
        return p;
    }

    public Modifier(entretien: BonTravailEntite): Promise<ReponseBase<BonTravailEntite>> {
      var p = this.http.post<ReponseBase<BonTravailEntite>>(this.modifierUrl, JSON.stringify(entretien), this.httpOptions)
        .toPromise();
        p.then(() => this.majService.Traiter());
        p.catch(() => this.router.navigateByUrl('error'));
        return p;
    }
  
    public EnvoyerApprobationSoumission(id: number, destinataires: string[]): Observable<HttpResponse<void>> {
      var p = this.http.post<void>(this.envoyerapprobationSoumissionUrl.replace('{id}', id.toString()), JSON.stringify(destinataires), {headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response'})
      return p;
    }

    public EnvoyerApprobation(entretien: BonTravailEntite): Promise<ReponseBase<BonTravailEntite>> {
      var p = this.http.post<ReponseBase<BonTravailEntite>>(this.envoyerapprobationUrl, JSON.stringify(entretien), this.httpOptions)
        .toPromise();
        p.catch(() => this.router.navigateByUrl('error'));
        return p;
    }

    public Completer(entretien: BonTravailEntite): Promise<ReponseBase<BonTravailEntite>> {
      var p = this.http.post<ReponseBase<BonTravailEntite>>(this.completerUrl, JSON.stringify(entretien), this.httpOptions)
        .toPromise();
        p.then(() => this.majService.Traiter());
        p.catch(() => this.router.navigateByUrl('error'));
        return p;
    }

    public CreerPartiel(requete: RequeteCreerBonTravailPartiel): Promise<ReponseBase<string>> {
      var p = this.http.post<ReponseBase<string>>(this.creerPartielUrl, JSON.stringify(requete), this.httpOptions)
        .toPromise();
        p.then(() => this.majService.Traiter());
        p.catch(() => this.router.navigateByUrl('error'));
        return p;
    }

    public Supprimer(id: number): Promise<ReponseBase<number>> {
      var p = this.http.delete<ReponseBase<number>>(this.supprimerUrl.replace('{id}', id.toString()))
        .toPromise();
        p.then(() => this.majService.Traiter());
        p.catch(() => this.router.navigateByUrl('error'));
        return p;
    }

    public AjouterEtapes(requete: RequeteAjouterEtapesBonTravail): Promise<ReponseBase<ReponseAjouterEtapesBonTravail>> {
      var p = this.http.post<ReponseBase<ReponseAjouterEtapesBonTravail>>(this.ajouterEtapesUrl, JSON.stringify(requete), this.httpOptions)
        .toPromise();
        p.then(() => this.majService.Traiter());
        p.catch(() => this.router.navigateByUrl('error'));
        return p;
    }

    public AnnulerEntretienPreventifComplete(etapeId: number): Promise<ReponseBase<number>> {
      var p = this.http.post<ReponseBase<number>>(this.annulerEPUrl.replace('{etapeId}', etapeId.toString()), null, this.httpOptions)
        .toPromise();
        p.then(() => this.majService.Traiter());
        p.catch(() => this.router.navigateByUrl('error'));
        return p;
    }

  /**
   * This method is used to convert a work order step to manual.
   * It sends a POST request to the server with the ID of the work order step that needs to be converted.
   * If the server response is successful, it triggers the majService to process the changes.
   * If the server response is not successful, it redirects the user to the error page.
   *
   * @param {number} etapeId - The ID of the work order step that needs to be converted to manual.
   * @returns {Promise<ReponseBase<number>>} A Promise that resolves with the server response.
   */
  public ConvertirEtapeBonTravailToManuel(etapeId: number): Promise<ReponseBase<number>> {
    var p = this.http.post<ReponseBase<number>>(this.convertirEtapeBonTravailToManuelUrl.replace('{etapeId}', etapeId.toString()), null, this.httpOptions)
      .toPromise();
    p.then(() => this.majService.Traiter());
    p.catch(() => this.router.navigateByUrl('error'));
    return p;
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);

      this.log(`${operation} failed: ${error.message}`);

      this.router.navigateByUrl('erreurtechnique');

      return of(result as T);
    };
  }

  private log(message: string) {
      console.log(message);
  }
}
