import { Component, ViewChild, ElementRef, ɵConsole } from "@angular/core"; import { Location } from "@angular/common";
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { CleValeur, CleValeurNumerique } from '../entites/cleValeur';
import { EntreeCommande } from '../entites/entree-commande';
import { ItemMin } from '../entites/item-min';
import { Statut } from '../entites/statut';
import { DonneesBaseService } from '../services/donnees-base-service';
import { BaseComponent } from '../base/base-component';
import { ItemService } from '../services/item-service';
import { BlockUIModule } from 'primeng/blockui';
import { FournisseurService } from '../services/fournisseur-service';
import { Fournisseur } from '../entites/fournisseur';
import { EmployeEntite } from '../entites/employe';
import { ItemCommandeEntite } from '../entites/item-commande';
import { MessageService } from 'primeng/api';
import { ProfilService } from '../services/profil-service';
import { ActivatedRoute, Router } from '@angular/router';
import { AchatService } from '../services/achat-service';
import { AppMainComponent } from '../app.main.component';
import { CodeProjetEntite } from '../entites/code-projet';
import { CodeActiviteEntite } from '../entites/code-activite';
import { CodeCategorieEntite } from '../entites/code-categorie';
import { Requisition } from '../entites/requisition';
import { RequisitionService } from '../services/requisition-service';
import { EStatuts } from '../entites/enums/statuts';
import { ETypesRequisition } from '../entites/enums/type-requisition';

export interface ParametresCreationRetour {
    emplacementId: number;
    date?: Date;
}


@Component({
    templateUrl: './creation-retour.component.html'
})
export class CreationRetourComponent extends BaseComponent {

    donnees: ParametresCreationRetour;

    listeEmployes: EmployeEntite[];

    retour: Requisition = {};

    submitted: boolean;

    constructor(
        profilService: ProfilService,
        route: ActivatedRoute, 
        routerService: Router,
		appMain: AppMainComponent, location: Location, 
        public requisitionService: RequisitionService,
        public messageService: MessageService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public sdb: DonneesBaseService) {
        super(sdb, profilService, route, routerService, appMain);


    }

    ngOnInit() {

        this.donnees = this.config.data;

        this.retour = {
            date: this.donnees.date,
            de: this.sdb.Emplacement(this.donnees.emplacementId),
            a: this.sdb.Emplacement(10000),
            statut: this.sdb.Statut(EStatuts.Actif),
            type: {
                typeRequisitionId: ETypesRequisition.Retour
            }
        };
        
        if (!this.retour.date) {
            this.retour.date = new Date();
        }

        this.listeEmployes = this.sdb.Employes();

        this.CompleterChargement();
    }

    annuler() {
        this.ref.close(null);
    }


    creerRetour() {

        this.submitted = true;
        if (!this.valider()) {
            this.messageService.add({ key: 'creationRetour', severity: 'error', summary: 'Erreurs', detail: 'Veuillez corriger les erreurs de saisie.' });
            return;
        }

        this.Bloquer();

        this.requisitionService.CreerRequisition(this.retour).then(res => {

            this.Debloquer();

            if (res.estUnSucces) {

                
                this.ref.close(res.valeur);
            }
            else{
                res.messages.forEach(x => {
                    this.messageService.add({ key: 'creationBonCommande', severity: 'error', summary: 'Erreur', detail: x.description, sticky: true });
                });
            }

        }).catch(err => {
            this.ref.close(null);
        });
    }

    valider() {

        return this.retour.date;
        
    }
}