<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<p-card *ngIf="!EstCharge()">
	<div class="fluid formgrid grid">
		<div class="flex flex-column gap-2 col-12 text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-card>

<div *ngIf="EstCharge()" class="grid">
	<div class="col-12">
		<div class="card">
			<h5>Modèle de liste d'achat
				<span class="statut nouveau">
					Nouveau
				</span>
			</h5>
		</div>

	</div>
</div>


<div *ngIf="EstCharge()" class="grid">
	<div class="col-12">
		<div class="card">
			<div class="fluid formgrid grid">
				<div class="flex flex-column gap-2 col-12 lg:col-3">
					<label for="nom">Nom</label>
					<input type="text" pInputText id="nom" [(ngModel)]="nom" autocomplete="off" />
					<small class="p-error" *ngIf="submitted && !nom">Le nom est obligatoire.</small>
				</div>
				<div class="flex flex-column gap-2 col-12 p-lg-9">
					<label for="description">Description</label>
					<input type="text" pInputText id="description" [(ngModel)]="description" autocomplete="off" />
				</div>

			</div>


			<div class="col-12 text-right">
				<p-button label="Suivant" (click)="CreerModele()" icon="pi pi-save" iconPos="left"></p-button>
			</div>
		</div>
	</div>


</div>