import {Component, ViewChild} from "@angular/core"; import { Location } from "@angular/common";
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import { EmployeEntite } from '../../entites/employe';
import { DonneesBaseService } from '../../services/donnees-base-service';

export interface ParametresSaisieNoteMecanique {
    note?: string;
    afficherAjoutFiche: boolean;
    indAjouterFiche: boolean;
}

@Component({
    templateUrl: './saisie-note-mecanique.component.html',
})
export class SaisieNoteMecaniqueComponent {

    donnees: ParametresSaisieNoteMecanique;

    note?: string;
    indAjouterFiche: boolean = true;
    afficherAjoutFiche: boolean = true;
    
    @ViewChild('champNote') champNote;
            
    constructor(
        public sdb: DonneesBaseService,
        public ref: DynamicDialogRef, 
        public config: DynamicDialogConfig) { }

    ngOnInit() {

        this.donnees = this.config.data;
        
         this.note = this.donnees.note;
         this.afficherAjoutFiche = this.donnees.afficherAjoutFiche;
         this.indAjouterFiche = this.afficherAjoutFiche && this.donnees.indAjouterFiche;

         setTimeout(() => { 
            this.champNote.nativeElement.focus();
         }, 100);
    }


    Confirmer() {
        this.donnees.note = this.note;
        this.donnees.indAjouterFiche = this.indAjouterFiche;
        this.ref.close(this.donnees);
    }

    Annuler() {
        this.ref.close(null);
    }
}