<p-card *ngIf="!EstCharge()">
	<div class="fluid formgrid grid">
		<div class="flex flex-column gap-2 col-12 text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-card>
<div class="card" *ngIf="EstCharge()">
<div class="fluid formgrid grid">
    <div class="flex flex-column gap-2 col-12">
        <label for="lastname">Entretien</label>
                <p-dropdown [options]="liste" [(ngModel)]="selection" optionLabel="description" [showClear]="true" appendTo="body"
                    filter="true" filterBy="description"
                    placeholder="Sélectionner un entretien...">
                    <ng-template pTemplate="selectedItem">
                        <div *ngIf="selection">
                            <div>{{selection.description}}</div>
                        </div>
                    </ng-template>
                    <ng-template let-item pTemplate="item">
                        <div>
                            <div>{{item.description}}</div>
                        </div>
                    </ng-template>
                </p-dropdown>
                <small class="p-error" *ngIf="submitted && !selection">Veuillez faire une sélection.</small>
    </div>
   
</div>
<div class="grid">
    <div class="col-12 text-right ">
        <button pButton pRipple label="Confirmer" icon="pi pi-save" class="p-button-primary"
        (click)="confirmer()"></button>
        <button pButton pRipple label="Annuler" icon="pi pi-ban" class="p-button-text" (click)="annuler()"></button>
    </div>
</div>
</div>