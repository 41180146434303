<p-toast key="selectionTransporteur"></p-toast>

<p-card *ngIf="!EstCharge()">
    <div class="fluid formgrid grid">
        <div class="flex flex-column gap-2 col-12 text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-card>

<div #contenu *ngIf="EstCharge()">

    <div class="grid">
        <div class="col-12">
            <div class="card">
    
                        <p-table #dt [value]="donnees.bonsCommande" dataKey="bonCommandeId" tableStyleClass="tableNormale" autoLayout="true"
                            autoLayout="true" styleClass="p-datatable-sm p-datatable-striped"
                            [rowHover]="true" >
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>Fournisseur</th>
									<th>Date</th>
									<th style="min-width: 5rem;"></th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-bdc>
                                <tr>
									<td>{{bdc.fournisseur.nom}} ({{bdc.fournisseur.numero}})</td>
                                    <td>{{bdc.dateAchat | date:'yyyy-MM-dd'}}</td>
                                    <td>
                                        <button pButton pRipple icon="pi pi-eye"
                                            class="p-button-success mr-2 boutonGrille"
                                            (click)="voirBonCommande(bdc)"></button>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="3">Aucun bon de commande.</td>
                                </tr>
                            </ng-template>
                        </p-table>
    
            </div>
        </div>
    
    
    </div>

    <div class="text-right mt-3">

        <button  #boutonConfirmer pButton pRipple label="OK" icon="pi pi-check" class="p-button-primary"
            (click)="confirmer()"></button>

    </div>

</div>