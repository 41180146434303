import { Component, OnInit, ViewChild } from "@angular/core"; import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { AppMainComponent } from "src/app/app.main.component";
import { BaseComponent } from "src/app/base/base-component";
import { DefinitionColonne } from "src/app/entites/definition-colonne";
import { DonneesBaseService } from "src/app/services/donnees-base-service";
import { ProfilService } from "src/app/services/profil-service";
import { NonConformiteService } from "src/app/services/non-conformite-service";
import { ActionCorrectiveEntite } from "src/app/entites/action-corrective";
import { ConfirmationService, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/app.breadcrumb.service";
import { DialogService } from "primeng/dynamicdialog";
import { AjoutActionCorrectiveComponent } from "./ajout-action-corrective.component";
import { Statut } from "src/app/entites/statut";
import { Table } from "primeng/table";

@Component({
  selector: 'action-corrective',
  templateUrl: './action-corrective.component.html',
  providers: [MessageService, NonConformiteService, ConfirmationService, DialogService]
})
export class ActionCorrectiveComponent extends BaseComponent implements OnInit {
  actionscorrectives: ActionCorrectiveEntite[];
  statuts: Statut[] = this.sdb.Statuts();
  colonnesDisponibles: DefinitionColonne[];

  @ViewChild('dt') table: Table;

  constructor(
    sdb: DonneesBaseService,
    profilService: ProfilService,
    route: ActivatedRoute,
    routerService: Router,
    appMain: AppMainComponent, location: Location,
    private nonConformiteService: NonConformiteService,
    private messageService: MessageService,
    private breadcrumbService: BreadcrumbService,
    private dialogService: DialogService
  ) {
    super(sdb, profilService, route, routerService, appMain);
    this.breadcrumbService.setItems([
      { label: 'Actions Correctives' }
    ]);
  }

  ngOnInit(): void {
    this.colonnesDisponibles = [
      { base: true, entete: 'Numéro', champ: 'numero', afficheParDefaut: true },
      { base: true, entete: 'Date', champ: 'date', afficheParDefaut: true },
      { base: true, entete: 'Demandeur', champ: 'demandeur', afficheParDefaut: true },
      { base: true, entete: 'NC', champ: 'nonConformite', afficheParDefaut: true },
    ];
    this.colonnesSelectionnees = this.colonnesDisponibles.filter(colonne => colonne.afficheParDefaut);
    this.loadActionsCorrectives();
  }

  loadActionsCorrectives() {
    this.nonConformiteService.ObtenirActionCorrectives().then(
      (data) => {
        this.actionscorrectives = data.valeur;
        this.CompleterChargement();

        this.table.filters = this.assignFilter('tableFilter', this.table.filters, this.table.onFilter).value;
      },
      (error) => {
        this.messageService.add({ severity: 'error', summary: 'Erreur', detail: 'Erreur lors du chargement des actions correctives' });
      }
    );
  }

  newActionCorrective() {
    this.dialogService.open(AjoutActionCorrectiveComponent, {
      header: 'Nouvelle action corrective',
      width: '90%',
      contentStyle: { 'overflow': 'visible' }
    }).onClose.subscribe((actionCorrective: ActionCorrectiveEntite) => {
      if (actionCorrective) {
        this.routerService.navigateByUrl('assurancequalite/actioncorrective/' + actionCorrective.actionCorrectiveId);
      } else {
        this.breadcrumbService.setItems([
          { label: 'Actions Correctives' }
        ]);
        this.routerService.navigateByUrl('assurancequalite/actioncorrective');
      }
    });
  }

  surChangementColonnes(event) {
    this.colonnesSelectionnees = event.value;
  }

  openHamburgerMenu() {

  }
}