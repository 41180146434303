import { Component, OnInit, ViewChild, Input } from "@angular/core"; import { Location } from "@angular/common";
import { MessageService, ConfirmationService } from 'primeng/api';

import { DialogService } from 'primeng/dynamicdialog';
import { Router, ActivatedRoute } from '@angular/router';
import { CleValeur, CleValeurNumerique } from 'src/app/entites/cleValeur';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { ProfilService } from 'src/app/services/profil-service';
import { Emplacement } from 'src/app/entites/emplacement';
import { EmployeEntite } from 'src/app/entites/employe';
import { EStatuts } from 'src/app/entites/enums/statuts';
import { AppMainComponent } from 'src/app/app.main.component';
import { ContexteService } from 'src/app/services/contexte-service';
import { BaseComponent } from 'src/app/base/base-component';
import { BonTravailEntite } from 'src/app/entites/mecanique/bon-travail';
import { BonTravailService } from 'src/app/services/bon-travail-service';
import { ETypeBonTravail } from 'src/app/entites/enums/type-bon-travail';
import { ItemMin } from 'src/app/entites/item-min';
import { SelectionUniteMecaniqueComponent } from 'src/app/controles/mecanique/selection-unite-mecanique.component';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { Fournisseur } from 'src/app/entites/fournisseur';
import { RaisonBonTravailEntite } from 'src/app/entites/mecanique/raison-bon-travail';
import { FournisseurService } from 'src/app/services/fournisseur-service';
import { ItemService } from 'src/app/services/item-service';

@Component({
    templateUrl: './ajout-bon-travail.component.html',
    providers: [MessageService, ConfirmationService, DialogService]
})
export class AjoutBonTravailComponent extends BaseComponent implements OnInit {

    bonTravail: BonTravailEntite;
    itemId?: number;

    typesBonTravail: CleValeurNumerique[];
    selectionTypeBonTravail: CleValeurNumerique;

    dateBonTravail: Date;

    emplacements: Emplacement[];
    fournisseurs: Fournisseur[];
    raisons: RaisonBonTravailEntite[];
    listeEmployes: EmployeEntite[] = [];

    changementUnitePermis: boolean = true;

    submitted:boolean = false;

    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute, 
		appMain: AppMainComponent, location: Location, 
        private messageService: MessageService,
        private fournisseurService: FournisseurService,
        private bonTravailServie: BonTravailService,
        private itemService: ItemService,
        private contexteService: ContexteService,
        private breadcrumbService: BreadcrumbService,
        public dialogService: DialogService,
        public routerService: Router ) {

        super(sdb, profilService, route, routerService, appMain);

        this.breadcrumbService.setItems([
            { label: 'Bons de travail', routerLink: '/mecanique/bonstravail' },
            { label: 'Ajouter' }
        ]);

        this.route.params.subscribe(params => {
            this.itemId = params.id as number;
            console.log(this.itemId);

        });
    }

    ngOnInit() {

        this.dateBonTravail = new Date();

        this.raisons = this.sdb.RaisonsBonTravail();

        this.emplacements = this.sdb.Emplacements();

        this.typesBonTravail = [
            { "cle": ETypeBonTravail.Interne, "valeur": "Interne" },
            { "cle": ETypeBonTravail.Externe, "valeur": "Externe" }
        ];
        this.selectionTypeBonTravail = this.typesBonTravail[0];

        this.listeEmployes = this.sdb.EmployesMecanique();

        this.bonTravail = {
            bonTravailId:0,
            dateTravail: new Date(),
            dateCreation: new Date(),
            item: {itemId:0},
            emplacement: this.emplacements.find(x => x.emplacementId == 10000)
        };



        var p1 = new Promise<number | void>((resolve, reject) => {
            this.fournisseurService.ObtenirFournisseurs().then(x => {
                this.fournisseurs = x.valeur;
                resolve();
            });
        });

        
        var p2 = new Promise<number | void | void>((resolve, reject) => {
            if (this.itemId) {
                this.itemService.ObtenirItem(this.itemId).then(item => {
                    var contreMaitre = null;
                    if (item.valeur.emplacement) {
                        this.bonTravail.emplacement = this.emplacements.find(x => x.emplacementId == item.valeur.emplacement.emplacementId);
                    }
                    if (item.valeur.departementMecanique && item.valeur.departementMecanique.contreMaitre) {
                        contreMaitre = this.listeEmployes.find(x => x.employeId == item.valeur.departementMecanique.contreMaitre.employeId)
                    }

                    this.bonTravail.contreMaitre = contreMaitre;
                    this.bonTravail.item = this.itemService.ItemVersItemMin(item.valeur);
                    resolve();
                });
                
            } else {
                resolve();
            }
        });

        Promise.all([p1, p2]).then(x => {

            this.CompleterChargement();

            if (this.bonTravail.item.itemId == 0) {
                this.rechercherUnite();
            }
        });

        
    }

    rechercherUnite() {

        const ref = this.dialogService.open(SelectionUniteMecaniqueComponent, {
            header: 'Sélection d\'une unité',
            width: '95%'
        }).onClose.subscribe((res: ItemMin) => {
            if (res) {
                this.bonTravail.item = res;

                console.log(this.bonTravail.item);

                if (this.bonTravail.item.emplacement) {
                    this.bonTravail.emplacement = this.emplacements.find(x => x.emplacementId == this.bonTravail.item.emplacement.emplacementId);
                }

                if (this.bonTravail.item.departementMecanique && this.bonTravail.item.departementMecanique.contreMaitre) {
                    this.bonTravail.contreMaitre = this.listeEmployes.find(x => x.employeId == this.bonTravail.item.departementMecanique.contreMaitre.employeId)
                }
            }
        });
    }

    surChangementTypeBonTravail() {
        
    }

    CreerBonTravail() {

        this.Bloquer();

        this.submitted = true;
        this.messageService.clear();

        if (this.validerNouveauBonTravail()) {

            this.bonTravail.type = this.selectionTypeBonTravail.cle;

            if (this.bonTravail.type == ETypeBonTravail.Interne) {
                if (this.bonTravail.mecanicien) {
                    this.bonTravail.dateAssignation = new Date();
                    this.bonTravail.statut = this.sdb.Statut(EStatuts.EnCours);
                } else {
                    this.bonTravail.statut = this.sdb.Statut(EStatuts.EnAttente);
                }
            } else {
                if (this.bonTravail.fournisseur) {
                    this.bonTravail.dateAssignation = new Date();
                    this.bonTravail.statut = this.sdb.Statut(EStatuts.EnCours);
                } else {
                    this.bonTravail.statut = this.sdb.Statut(EStatuts.EnAttente);
                }
            }
           
            this.bonTravailServie.Creer(this.bonTravail).then(x => {
                if (x.estUnSucces) {
                    this.Debloquer();
                    this.routerService.navigate([`mecanique/bontravail/${x.valeur.bonTravailId}`, { mode: 'M' }]);
                } else {
                    x.messages.forEach(x => {
                        this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description, sticky: true });
                    });

                    this.Debloquer();
                }
            });
        } else {
            this.messageService.add({ severity: 'error', summary: 'Erreurs', detail: 'Veuillez corriger les erreurs de saisie.' });
            this.Debloquer();
        }
    }

    validerNouveauBonTravail(): boolean {


        if (!this.bonTravail.dateTravail || !this.bonTravail.contreMaitre || !this.bonTravail.raison) {
            return false;
        }
            
        return true;
    }
}
