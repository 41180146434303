import { Component, OnInit, ViewChild } from "@angular/core"; import { Location } from "@angular/common";
import { Table } from 'primeng/table';
import { BreadcrumbService } from '../../app.breadcrumb.service';

import { RequisitionMin } from '../../entites/requisition-min';
import { MenuItem, TreeNode } from 'primeng/api';
import { MessageService, LazyLoadEvent, ConfirmationService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

import { DialogService } from 'primeng/dynamicdialog';
import { Router, ActivatedRoute } from '@angular/router';

import { BaseComponent } from '../../base/base-component';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { ProfilService } from 'src/app/services/profil-service';
import { AppMainComponent } from 'src/app/app.main.component';
import { RapportService } from 'src/app/services/rapport-service';

@Component({
    templateUrl: './rapport-entretiens-pep.component.html',
    providers: [MessageService, ConfirmationService, DialogService]
})
export class RapportEntretiensPEPComponent extends BaseComponent implements OnInit {

    dateDebut: Date;
    dateFin: Date;

    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute,
        appMain: AppMainComponent, location: Location, 
        private breadcrumbService: BreadcrumbService,
        private rapportService: RapportService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        public dialogService: DialogService,
        public routerService: Router) {

        super(sdb, profilService, route, routerService, appMain);

        this.breadcrumbService.setItems([
            { label: 'Rapport d\'entretiens PEP' }
        ]);
    }

    ngOnInit() {

        this.verifierAccesPage('rapport_pep_saaq');

        var date = new Date();
        this.dateDebut = new Date(date.getFullYear(), date.getMonth(), 1);
        this.dateFin = new Date(date.getFullYear(), date.getMonth() + 11, 1);

        this.CompleterChargement();

    }

    submitted = false;

    generer() {

        this.submitted = true;
        this.messageService.clear();

        if (this.valider()) {

            this.Bloquer();
            
            this.rapportService.ProduireRapportEntretienPEP(this.dateDebut, this.dateFin).then(res => {
                this.Debloquer();

                if (res) {
                    this.messageService.add({ severity: 'success', summary: 'Succès', detail: `Votre rapport a été généré.` });
                } else {
                    this.messageService.add({ severity: 'error', summary: 'Erreur', detail: `Une erreur est survenue pendant la génération de votre rapport.` });
                }

            });
        }
    }

    valider() {
        return this.dateDebut &&
            this.dateFin
    }

}
