
export enum ETypeLienDocument {
    Item = 1,
    Requisition = 2,
    Location = 3,
    BonTravail = 4,
    NonConformite = 5,
    ItemCommande = 6,
    ActionCorrective = 7,
    Message = 8,
}