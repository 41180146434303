<p-toast key="creationPiece"></p-toast>

<p-blockUI [blocked]="blocked">
    <div class="fluid formgrid grid">
        <div class="flex flex-column gap-2 col-12 text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-blockUI>


<p-card *ngIf="!EstCharge()">
    <div class="fluid formgrid grid">
        <div class="flex flex-column gap-2 col-12 text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-card>

<div #contenu *ngIf="EstCharge()">

	<div class="fluid formgrid grid">
		
		<div class="flex flex-column gap-2 col-12 md:col-6 lg:col-3">
            <label for="noFournisseur">No pièce fournisseur</label>
            <input #champNoFournisseur type="text" pInputText id="noFournisseur" [(ngModel)]="prixFournisseur.numeroFournisseur"
                autocomplete="off" />
        </div>
        <div class="flex flex-column gap-2 col-12 md:col-6 lg:col-3">
            <label for="prix">Prix</label>
            <p-inputNumber #champPrix [(ngModel)]="prixFournisseur.prix" mode="currency" currency="USD" locale="en-US" [minFractionDigits]="2" [maxFractionDigits]="4"></p-inputNumber>
        </div>
	</div>


</div>


<div class="text-right mt-3">
	<button pButton pRipple label="Modifier" icon="pi pi-save" class="p-button-primary"
	(click)="enregistrer()"></button>
<button pButton pRipple label="Annuler" icon="pi pi-ban" class="p-button-text"
	(click)="annuler()"></button>
	

</div>