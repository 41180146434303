<p-toast></p-toast>

<div class="grid">
    <div class="col-12">

        <div class="card">

            <p-table #dt [value]="listeEmplacementsActifs" [columns]="selectedColumns" dataKey="emplacementId" tableStyleClass="tableNormale"
                autoLayout="true"  [rowHover]="true" [filterDelay]="0" [globalFilterFields]="['nom','code']">
                <ng-template pTemplate="caption">
                    <div class="flex align-content-center justify-content-between">
                        <div>
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Recherche..." />
                            </span>
                        </div>
                        <h5 class="titreTableau m-0">Pré-commandes</h5>
                        <div>
                            <p-multiSelect disabled="true" [style]="{minWidth: '200px'}" placeholder="Colonnes..."></p-multiSelect>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                    <tr>

                        <th pSortableColumn="code">
                            <div class="flex justify-content-between align-items-center">
                                Numéro
                                <p-sortIcon field="code"></p-sortIcon>
                                <p-columnFilter type="text" field="code" display="menu" class="ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>

                        <th pSortableColumn="nom">
                            <div class="flex justify-content-between align-items-center">
                                Emplacement
                                <p-sortIcon field="nom"></p-sortIcon>
                                <p-columnFilter type="text" field="nom" display="menu" class="ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="nombre">
                            <div class="flex justify-content-between align-items-center">
                                Nombre d'item
                                <p-sortIcon field="nombre"></p-sortIcon>
                                <p-columnFilter type="text" field="nombre" display="menu" class="ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th style="min-width: 5rem; width: 5rem;"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-commande>
                    <tr [pContextMenuRow]="commande">
                        <!-- <td>
							<p-tableCheckbox [value]="Item"></p-tableCheckbox>
                        </td> -->

                        <td>
                            <a *ngIf="estAutorise('approbation_precommande_detail')" [routerLink]="'/approbationprecommande/' + commande.emplacementId">{{commande.code}}</a>
                            <span *ngIf="!estAutorise('approbation_precommande_detail')">
                                {{commande.code}}
                            </span>
                        </td>

                        <td>{{commande.nom}}</td>
                        <td>{{commande.nombre}}</td>
                        <td>
                             <button *ngIf="estAutorise('approbation_precommande_detail')" pButton pRipple icon="pi pi-eye"
                                class="p-button-success mr-2 boutonGrille"
                                (click)="OuvrirDetailCommande(commande.emplacementId)"></button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="3">Aucune pré-commande.</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>


</div>