import { Pipe, PipeTransform } from "@angular/core"; import { Location } from "@angular/common";
import { EmployeEntite } from '../entites/employe';
import { CategorieEntretienEntite } from '../entites/mecanique/categorie-entretien';

@Pipe({
  name: 'categorieEntretien'
})
export class CategorieEntretienPipe implements PipeTransform {

  transform(value: CategorieEntretienEntite): string {
    console.log('categ');
    console.log(value);
    if (value != null && value != undefined) {
      return value.code + ' - ' + value.description
    } else {
        return '';
    }
  }

}
