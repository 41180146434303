import { Component, OnInit, ViewChild } from "@angular/core"; import { Location } from "@angular/common";
import { Table } from 'primeng/table';
import { BreadcrumbService } from '../../app.breadcrumb.service';

import { RequisitionMin } from '../../entites/requisition-min';
import { MenuItem, TreeNode } from 'primeng/api';
import { MessageService, LazyLoadEvent, ConfirmationService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

import { DialogService } from 'primeng/dynamicdialog';
import { EnvoiRequisitionComponent } from '../../controles/envoi-requisition.component';
import { ItemMin } from '../../entites/item-min';
import { ItemService } from '../../services/item-service';
import { Router, ActivatedRoute } from '@angular/router';

import { ToolbarModule } from 'primeng/toolbar';

import { MenubarModule } from 'primeng/menubar';
import { BaseComponent } from '../../base/base-component';
import { Item } from '../../entites/item';
import { Inventaire } from '../../entites/inventaire';
import { NoteEntite } from '../../entites/note';
import { Fournisseur } from 'src/app/entites/fournisseur';
import { FournisseurService } from 'src/app/services/fournisseur-service';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { ValeurAttribut } from 'src/app/entites/valeur-attribut';
import { CategorieMin } from 'src/app/entites/categorie';
import { SousCategorieEntite } from 'src/app/entites/sousCategorie';
import { EStatuts } from 'src/app/entites/enums/statuts';
import { ProfilService } from 'src/app/services/profil-service';
import { AppMainComponent } from 'src/app/app.main.component';
import { ContextMenu } from 'primeng/contextmenu/public_api';
import { BoiteOutilEntite, ItemBoiteOutilEntite } from 'src/app/entites/boite-outil';
import { BoiteOutilService } from 'src/app/services/boite-outil-service';
import { SelectionItemSimpleComponent, ResultatSelectionItemSimple } from 'src/app/controles/selection-item-simple.component';
import { SaisieQuantiteComponent } from 'src/app/controles/saisie-quantite.component';
import { RapportService } from 'src/app/services/rapport-service';

@Component({
    templateUrl: './detail-boite-outil.component.html',
    providers: [MessageService, ConfirmationService, DialogService]
})
export class DetailBoiteOutilComponent extends BaseComponent implements OnInit {

    boiteOutilId: number;
    boiteOutil: BoiteOutilEntite;

    menu: MenuItem[];

    
    contenuMenuContextuelItem: MenuItem[];
    @ViewChild('menuContextuelItem') menuContextuelItem: ContextMenu;

    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute, 
		appMain: AppMainComponent, location: Location, 
        private breadcrumbService: BreadcrumbService,
        private ItemsService: ItemService,
        private rapportService: RapportService,
        private boiteOutilService: BoiteOutilService,
        private messageService: MessageService,
        private fournisseurService: FournisseurService,
        private confirmationService: ConfirmationService,
        public dialogService: DialogService,
        public routerService: Router) {

        super(sdb, profilService, route, routerService, appMain);

        this.breadcrumbService.setItems([
            { label: 'Boites d\'outils', routerLink: '/pilotage/boitesOutil' },
            { label: 'Détails' }
        ]);

        this.route.params.subscribe(params => this.boiteOutilId = params.id);
    }

    ngOnInit() {

        this.boiteOutilService.Obtenir(this.boiteOutilId).then(res => {
        
            this.boiteOutil = res.valeur;
            this.CompleterChargement();

           this.initialiserMenu();

        });       
    }

    initialiserMenu() {
        this.menu = [
            {
                label: 'Retour', icon: 'pi pi-fw pi-angle-double-left', command: () => this.routerService.navigateByUrl('pilotage/boitesOutil')
            },
            {
                label: 'Imprimer', icon: 'pi pi-fw pi-print', command: () => this.imprimer()
            },
           /*  {
                label: 'Actions', icon: 'pi pi-fw pi-bars', disabled: this.boiteOutil.statut.statutId == EStatuts.Archive,
                items: [
                    {
                        label: 'Archiver', icon: 'pi pi-fw pi-times', command: () => this.archiver()
                    }
                ]
            }, */
        ];
    }

    imprimer() {
        this.messageService.add({ severity: 'info', summary: 'Demande de rapport', detail: `Votre demande de rapport a été envoyée.` });


        this.rapportService.ProduireRapportBoiteOutil(this.boiteOutil.boiteOutilId).then(x => {
            if (x) {
                this.messageService.add({ severity: 'success', summary: 'Succès', detail: `Votre rapport a été généré.` });
            } else {
                this.messageService.add({ severity: 'error', summary: 'Erreur', detail: `Une erreur est survenue pendant la génération de votre rapport.` });
            }
        });
    }

    btnModifier_click() {
        this.Modifier();
    }

    annulerModification() {
        this.DemarrerChargement();

        this.boiteOutilService.Obtenir(this.boiteOutilId).then(res => {

            this.boiteOutil = res.valeur;
            this.CompleterChargement();
            this.Consulter();

        });        
    }

    submitted = false;

    enregistrerModifications() {
        this.submitted = true;
        this.messageService.clear();

        if (this.validerModificationItem()) {
            this.DemarrerChargement();
            this.boiteOutilService.Modifier(this.boiteOutil).then(x => {
                this.CompleterChargement();
                if (x.estUnSucces) {
                    this.Consulter();
                    this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Boite d\'outils modifiée.' });
                } else {
                    x.messages.forEach(x => {
                        this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description, sticky: true });
                    });

                }

            });


        }
    }

    validerModificationItem(): boolean {

        if (!this.boiteOutil.nom) {
            return false;
        }

        return true;
    }

    surAjoutItem() {
        
        const ref = this.dialogService.open(SelectionItemSimpleComponent, {
            data: {
                choixCategorieDisponible: false,
                categorieIds: [3]
            },
            header: 'Sélection d\'un item',
            width: '95%'
        }).onClose.subscribe((res: ResultatSelectionItemSimple) => {

            if (res) {
                const ref2 = this.dialogService.open(SaisieQuantiteComponent, {
                    data: {
                        quantite: 1,
                        min: 1
                    },
                    header: 'Saisir la quantité',
                    width: '50%'
                }).onClose.subscribe((resQte: number) => {
                    if (resQte) {
                       
                        this.boiteOutilService.AjouterItem({
                            boiteOutilId: this.boiteOutil.boiteOutilId,
                            item: res.item,
                            quantite: resQte
                        }).then(res => {
                            if (res.estUnSucces) {
                                this.boiteOutil.itemsBoiteOutil.push(res.valeur);
                                this.messageService.add({ severity: 'success', summary: 'Succès', detail: `L'item '${res.valeur.item.description} (${res.valeur.item.numero})' a été ajouté.` });
        
                            } else {
                                res.messages.forEach(x => {
                                    this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                                });
                            }
                        });
                    }
    
                });
            }

            


        });

    }

    OuvrirDetailItem(itemId: number) {
        this.routerService.navigateByUrl(`item/${itemId}`)
        this.messageService.add({ severity: 'info', summary: 'Item Selected', detail: itemId.toString() });
    }

/*     archiver() {
         this.confirmationService.confirm({
            message: `Voulez-vous vraiment archiver l'boiteOutil ${this.boiteOutil.nom} ?`,
            accept: () => {
                //TODO
                this.boiteOutil.statut = this.sdb.Statut(EStatuts.Archive);
                this.initialiserMenu();
                this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'BoiteOutil archivé' });
            }
        });
    }
 */


    surMenuContextuelItem(event, item: ItemBoiteOutilEntite) {
        this.contenuMenuContextuelItem = [
            { label: 'Voir l\'item', icon: 'pi pi-fw pi-eye', command: () => this.routerService.navigateByUrl('item/' + item.item.itemId) },
            { separator: true },
            { label: 'Supprimer', icon: 'pi pi-fw pi-times', command: () => this.supprimerItem(item) }
        ];

        this.menuContextuelItem.toggle(event);

    }

    supprimerItem(item: ItemBoiteOutilEntite) {
        
        this.confirmationService.confirm({
            target: event.target,
            message: 'Voulez-vous vraiment supprimer l\'item sélectionné ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.boiteOutilService.SupprimerItem(item.itemBoiteOutilId).then(res => {
                    if (res.estUnSucces) {
                        this.boiteOutil.itemsBoiteOutil = this.boiteOutil.itemsBoiteOutil.filter(x => x.itemBoiteOutilId != item.itemBoiteOutilId)
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Item supprimé.' });
                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                });

            }
        });

    }


}
