<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-menu #menuContextuelLocation popup="popup" [model]="contenuMenuContextuelLocation"></p-menu>

<p-card *ngIf="!EstCharge()">
    <div class="fluid formgrid grid">
        <div class="flex flex-column gap-2 my-2 col text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-card>

<div *ngIf="EstCharge() && !modeAjout()" class="grid">
	<div class="col">
		<div class="card">
			<p-menubar autoDisplay="false" [model]="menu">
			</p-menubar>
		</div>

	</div>
</div>

<div *ngIf="EstCharge()" class="grid">

    <div class="col-12">

        <div class="card">
            <p-table #dt [value]="items" [columns]="selectedColumns" dataKey="locationId" autoLayout="true"
                tableStyleClass="tableNormale"  [rowHover]="true"
                [rows]="100" [paginator]="true" [rowsPerPageOptions]="[10,25,50,100]" [filterDelay]="0"
                [globalFilterFields]="['item.numero','item.description','type.nom','description','dateFin','statut.nom']">

                <ng-template pTemplate="caption" let-state>
                    <div class="flex align-items-center justify-content-between">
                        <div>
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Recherche..." />
                            </span>
                            <button *ngIf="estAutorise('location_creer')" pButton pRipple icon="pi pi-plus"
                            class="p-button-success mr-2 ml-4 mb-2 boutonGrille"
                            (click)="ajouterLocation()"></button>
                        </div>
                        <div>
                            <h5 class="titreTableau m-0">Locations</h5>
                           
                        </div>

                        <div>
                            <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
                                (onChange)="surChangementColonnes($event)"
                                selectedItemsLabel="{0} colonnes sélectionnés" [style]="{minWidth: '200px'}"
                                placeholder="Colonnes..."></p-multiSelect>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th pSortableColumn="dateDebut">
                            <div class="flex justify-content-between align-items-center">
                                Date de début
                                <p-sortIcon field="dateDebut"></p-sortIcon>
                                <p-columnFilter type="date" field="dateDebut" display="menu" class="ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="description">
                            <div class="flex justify-content-between align-items-center">
                                Description
                                <p-sortIcon field="description"></p-sortIcon>
                                <p-columnFilter type="text" field="description" display="menu" class="ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="fournisseur.nom">
                            <div class="flex justify-content-between align-items-center">
                                Fournisseur
                                <p-sortIcon field="fournisseur.nom"></p-sortIcon>
                                <p-columnFilter type="text" field="fournisseur.nom" display="menu" class="ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="statut.nom">
                            <div class="flex justify-content-between align-items-center">
                                Statut
                                <p-sortIcon field="statut.nom"></p-sortIcon>
                                <p-columnFilter type="text" field="statut.nom" display="menu" class="ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="typeLocation.nom">
                            <div class="flex justify-content-between align-items-center">
                                Type de location
                                <p-sortIcon field="typeLocation.nom"></p-sortIcon>
                                <p-columnFilter type="text" field="typeLocation.nom" display="menu" class="ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="nbJoursRestants">
                            <div class="flex justify-content-between align-items-center">
                                Nb jours restants
                                <p-sortIcon field="nbJoursRestants"></p-sortIcon>
                                <p-columnFilter type="numeric" field="nbJoursRestants" display="menu" class="ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="emplacement.nom">
                            <div class="flex justify-content-between align-items-center">
                                Emplacement
                                <p-sortIcon field="emplacement.nom"></p-sortIcon>
                                <p-columnFilter class="ml-auto" field="emplacement.emplacementId" matchMode="in" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false" (click)="$event.stopPropagation()">
                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                        <p-multiSelect [(ngModel)]="selectionEmplacements" [options]="emplacements" [showToggleAll]="true" optionLabel="nom" (onChange)="filter(ObtenirListeNomEmplacements($event.value))" [showClear]="true">
                                        </p-multiSelect>
                                    </ng-template>
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="bonCommande.numero">
                            <div class="flex justify-content-between align-items-center">
                                Bon de commande
                                <p-sortIcon field="bonCommande.numerom"></p-sortIcon>
                                <p-columnFilter type="text" field="bonCommande.numero" display="menu" class="ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th style="min-width: 7rem"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-location>
                    <tr [pContextMenuRow]="location">
                        <td>{{location.dateDebut | date:'yyyy-MM-dd'}}
                        </td>
                        <td>{{location.description}}</td>
                        <td>{{location.fournisseur.nom}}</td>
                        <td><span [class]="'statut ' + location.statut.nom.replace(' ','').toLowerCase()">
                                {{location.statut.nom}}
                            </span>
                        </td>
                        <td>{{location.typeLocation.nom}}</td>
                        <td>
                            <span *ngIf="location.nbJoursRestants" [ngClass]="location.nbJoursRestants > seuilRouge ? location.nbJoursRestants > seuilJaune ? 'seuil vert' : 'seuil jaune' : 'seuil rouge'">
                                {{location.nbJoursRestants}}
                            </span>
                        </td>
                        <td>
                            {{location.emplacement | nomEmplacement}}
                        </td>
                        <td>
                            {{location.bonCommande?.numero}}
                        </td>
                        <td>
                            <div class="flex">
                                <button *ngIf="estAutorise('location_detail')" pButton pRipple icon="pi pi-eye" class="p-button-success mr-2 boutonGrille"
                                (click)="OuvrirDetailLocation(location)"></button>
                            <button pButton pRipple icon="pi pi-bars" class="p-button-secondary mr-2 boutonGrille"
									(click)="surMenuContextuelLocation($event, location)"></button>
                            </div>
                        </td>
                    </tr>
                </ng-template>

                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="8">Aucune location.</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="paginatorleft" let-state>
                    {{state.totalRecords}} entrée(s).
                </ng-template>
            </p-table>



        </div>
    </div>


</div>