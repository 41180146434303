import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from "@angular/core"; import { Location } from "@angular/common";

import { Observable, of, interval, Subscription } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { ItemMin } from '../entites/item-min';
import { Router } from '@angular/router';
import { ReponseBase } from './reponse-base';
import { Item } from '../entites/item';
import { ReparationEntite } from '../entites/reparation';
import { Inventaire } from '../entites/inventaire';
import { HistoriqueItem } from '../entites/historiqueItem';
import { NoteEntite } from '../entites/note';
import { ValeurAttribut } from '../entites/valeur-attribut';
import { AnalyseItem } from '../entites/analyse-item';
import { RequeteRetourReparation } from './requetes/requete-retour-reparation';
import { RequeteAjusterInventaireItem } from './requetes/requete-ajuster-inventaire-item';
import { RequeteCreerPiece } from './requetes/requete-creer-piece';

@Injectable({ providedIn: 'root' })
export class ItemService {

  private obtenirListeUrl = environment.apiBaseUrl + 'item';
  private obtenirHeuresUrl = environment.apiBaseUrl + 'item/heures';
  private obtenirListeParStatutUrl = environment.apiBaseUrl + 'item/?statutId={statutId}';
  private obtenirDetailUrl = environment.apiBaseUrl + 'item/';
  private obtenirInventaireUrl = environment.apiBaseUrl + 'item/inventaire/';
  private obtenirHistoriqueUrl = environment.apiBaseUrl + 'item/historique/';
  private obtenirHistoriquePiecesUrl = environment.apiBaseUrl + 'item/historiquepieces/';
  private obtenirLiaisonsUrl = environment.apiBaseUrl + 'item/liaisons/';
  private obtenirNotesUrl = environment.apiBaseUrl + 'item/notes/';
  private obtenirAttributsUrl = environment.apiBaseUrl + 'item/attributs/';
  private modifierAttributsUrl = environment.apiBaseUrl + 'item/attributs/';
  private obtenirAnalyseUrl = environment.apiBaseUrl + 'item/{id}/analyse';
  private obtenirProchainUrl = environment.apiBaseUrl + 'item/prochainNumero';
  private archiverUrl = environment.apiBaseUrl + 'item/archiver';
  private verifierExisteUrl = environment.apiBaseUrl + 'item/existe/{numero}';
  private modifierNumeroUrl = environment.apiBaseUrl + 'item/modifiernumero';
  private fusionnerUrl = environment.apiBaseUrl + 'item/fusionner/{idSource}/{idCible}';
  private indiquerMecaniqueUrl = environment.apiBaseUrl + 'item/mecanique';

  private creerItemUrl = environment.apiBaseUrl + "item";
  private creerPieceUrl = environment.apiBaseUrl + "item/piece";
  private modifierItemUrl = environment.apiBaseUrl + "item/modifier";
  private modifierStatutItemUrl = environment.apiBaseUrl + "item/{itemId}/statut/modifier/{statutId}";
  private ajusterInventaireItemUrl = environment.apiBaseUrl + "item/ajusterInventaire";
  private retourReparationUrl = environment.apiBaseUrl + "item/retourReparation";

  private items: ItemMin[];
  private actif: boolean = true;

  subscription: Subscription;

  public httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(
    private http: HttpClient,
    public router: Router) {

      const source = interval(180000);
      this.subscription = source.subscribe(val => {
        console.log("Intervalle atteint");
        if (this.actif) {
          this.ReinitialiserItems()
        }
      });

      this.ReinitialiserItems();

     }

     public Activer() {
      
      this.ReinitialiserItems().then(x => {
        console.log("Activation du service item");
        this.actif = true;

      });
       
     }

     public Desactiver() {
      console.log("Désactivation du service item");
      this.actif = false;
    }

  public ObtenirItems(): Promise<ReponseBase<ItemMin[]>> {

    console.log('a');
    var prom = new Promise<ReponseBase<ItemMin[]>>((resolve, reject) => {

      if (!this.items) {
        console.log('b');
        var p = this.http.get<ReponseBase<ItemMin[]>>(this.obtenirListeUrl)
          .toPromise();

        p.catch(() => this.router.navigateByUrl('error'));

        p.then(res => {
          console.log('c');
          this.items = res.valeur;
          resolve({
            valeur: this.items,
            estUnSucces: true,
            messages: null
          });
        })

      } else {
        console.log('d');
        resolve({
          valeur: this.items,
          estUnSucces: true,
          messages: null
        });
      }


    });

    console.log('e');

    return prom;

  }

  
  
  public ReinitialiserItems(): Promise<boolean> {

    console.log("Réinitialisation des items");

    var prom = new Promise<boolean>((resolve, reject) => {

      var p = this.http.get<ReponseBase<ItemMin[]>>(this.obtenirListeUrl)
        .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      p.then(res => {

        console.log("Réinitialisation complétée");

        this.items = res.valeur;
        resolve(true);
      })
    });
    
    return prom;
  }

  public ObtenirItemsParStatut(statutId: number): Promise<ReponseBase<ItemMin[]>> {

    var p = this.http.get<ReponseBase<ItemMin[]>>(this.obtenirListeParStatutUrl.replace('{statutId}', statutId.toString()))
      .toPromise();

    p.catch(() => this.router.navigateByUrl('error'));

    return p;

  }


  public ObtenirHeures(): Promise<ReponseBase<ItemMin[]>> {

    var p = this.http.get<ReponseBase<ItemMin[]>>(this.obtenirHeuresUrl)
      .toPromise();

    p.catch(() => this.router.navigateByUrl('error'));

    return p;
  }



  public ObtenirItem(id: number): Promise<ReponseBase<Item>> {
    var p = this.http.get<ReponseBase<Item>>(this.obtenirDetailUrl + id.toString())
      .toPromise();

    p.catch(() => this.router.navigateByUrl('error'));

    return p;
  }


  public ObtenirInventaireItem(id: number): Promise<ReponseBase<Inventaire[]>> {
    var p = this.http.get<ReponseBase<Inventaire[]>>(this.obtenirInventaireUrl + id.toString())
      .toPromise();

    p.catch(() => this.router.navigateByUrl('error'));

    return p;
  }

  public ObtenirHistoriqueItem(id: number): Promise<ReponseBase<HistoriqueItem[]>> {
    var p = this.http.get<ReponseBase<HistoriqueItem[]>>(this.obtenirHistoriqueUrl + id.toString())
      .toPromise();

    p.catch(() => this.router.navigateByUrl('error'));

    return p;
  }

  public ObtenirHistoriquePiecesItem(id: number): Promise<ReponseBase<HistoriqueItem[]>> {
    var p = this.http.get<ReponseBase<HistoriqueItem[]>>(this.obtenirHistoriquePiecesUrl + id.toString())
      .toPromise();

    p.catch(() => this.router.navigateByUrl('error'));

    return p;
  }

  public ObtenirLiaisonsItem(id: number): Promise<ReponseBase<ItemMin[]>> {
    var p = this.http.get<ReponseBase<ItemMin[]>>(this.obtenirLiaisonsUrl + id.toString())
      .toPromise();

    p.catch(() => this.router.navigateByUrl('error'));

    return p;
  }

  public ObtenirNotesItem(id: number): Promise<ReponseBase<NoteEntite[]>> {
    var p = this.http.get<ReponseBase<NoteEntite[]>>(this.obtenirNotesUrl + id.toString())
      .toPromise();

    p.catch(() => this.router.navigateByUrl('error'));

    return p;
  }

  public ObtenirAttributsItem(id: number): Promise<ReponseBase<ValeurAttribut[]>> {
    var p = this.http.get<ReponseBase<ValeurAttribut[]>>(this.obtenirAttributsUrl + id.toString())
      .toPromise();

    p.catch(() => this.router.navigateByUrl('error'));

    return p;
  }

  public ModifierAttributsItem(id: number, valeurs: ValeurAttribut[]): Promise<ReponseBase<ValeurAttribut[]>> {
    var p = this.http.post<ReponseBase<ValeurAttribut[]>>(this.modifierAttributsUrl + id.toString(), JSON.stringify(valeurs), this.httpOptions)
      .toPromise();

    p.catch(() => this.router.navigateByUrl('error'));

    p.then(() => this.ReinitialiserItems());

    return p;
  }


  public ObtenirAnalyseItem(id: number): Promise<ReponseBase<AnalyseItem>> {
    var p = this.http.get<ReponseBase<AnalyseItem>>(this.obtenirAnalyseUrl.replace('{id}', id.toString()))
      .toPromise();

    p.catch(() => this.router.navigateByUrl('error'));

    return p;
  }

  public ObtenirProchainNumero(prefixe: string): Promise<ReponseBase<string>> {
    var p = this.http.post<ReponseBase<string>>(this.obtenirProchainUrl, JSON.stringify(prefixe), this.httpOptions)
      .pipe(
        tap((_) => this.log('')),
        catchError(this.handleError<ReponseBase<string>>('ObtenirProchainNumero', null)),
      ).toPromise();

    p.catch(() => this.router.navigateByUrl('error'));

    return p;
  }

  public CreerItem(item: Item): Promise<ReponseBase<Item>> {
    var p = this.http.post<ReponseBase<Item>>(this.creerItemUrl, JSON.stringify(item), this.httpOptions).toPromise();

    p.catch(() => this.router.navigateByUrl('error'));

    p.then(() => this.ReinitialiserItems());

    return p;
  }

  public CreerPiece(requete: RequeteCreerPiece): Promise<ReponseBase<Item>> {
    var p = this.http.post<ReponseBase<Item>>(this.creerPieceUrl, JSON.stringify(requete), this.httpOptions).toPromise();

    p.catch(() => this.router.navigateByUrl('error'));

    p.then(() => this.ReinitialiserItems());

    return p;
  }

  public ModifierItem(item: Item): Promise<ReponseBase<Item>> {
    var p = this.http.post<ReponseBase<Item>>(this.modifierItemUrl, JSON.stringify(item), this.httpOptions).toPromise();

    p.catch(() => this.router.navigateByUrl('error'));

    p.then(() => this.ReinitialiserItems());

    return p;
  }

  public ModifierStatut(itemId: number, statutId: number): Promise<ReponseBase<number>> {
    var p = this.http.post<ReponseBase<number>>(this.modifierStatutItemUrl.replace('{itemId}', itemId.toString()).replace('{statutId}', statutId.toString()), null, this.httpOptions).toPromise();

    p.catch(() => this.router.navigateByUrl('error'));

    p.then(() => this.ReinitialiserItems());

    return p;
  }


  public AjusterInventaire(requete: RequeteAjusterInventaireItem): Promise<ReponseBase<number>> {
    var p = this.http.post<ReponseBase<number>>(this.ajusterInventaireItemUrl, JSON.stringify(requete), this.httpOptions).toPromise();

    p.catch(() => this.router.navigateByUrl('error'));

    p.then(() => this.ReinitialiserItems());

    return p;
  }

  public RetourReparation(req: RequeteRetourReparation): Promise<ReponseBase<number>> {
    var p = this.http.post<ReponseBase<number>>(this.retourReparationUrl, JSON.stringify(req), this.httpOptions).toPromise();

    p.catch(() => this.router.navigateByUrl('error'));

    p.then(() => this.ReinitialiserItems());

    return p;
  }

  public Archiver(item: Item): Promise<ReponseBase<Item>> {
    var p = this.http.post<ReponseBase<Item>>(this.archiverUrl, JSON.stringify(item), this.httpOptions)
      .toPromise();

    p.catch(() => this.router.navigateByUrl('error'));

    p.then(() => this.ReinitialiserItems());

    return p;
  }

  public VerifierExiste(numero: string): Promise<ReponseBase<number>> {
    var p = this.http.get<ReponseBase<number>>(this.verifierExisteUrl.replace('{numero}', numero.toString()))
      .toPromise();

    p.catch(() => this.router.navigateByUrl('error'));

    return p;
  }

  public ModifierNumero(item: Item): Promise<ReponseBase<Item>> {
    var p = this.http.post<ReponseBase<Item>>(this.modifierNumeroUrl, JSON.stringify(item), this.httpOptions).toPromise();

    p.catch(() => this.router.navigateByUrl('error'));

    p.then(() => this.ReinitialiserItems());

    return p;
  }

  public Fusionner(idSource: number, idCible: number): Promise<ReponseBase<boolean>> {
    var p = this.http.post<ReponseBase<boolean>>(this.fusionnerUrl.replace('{idSource}', idSource.toString()).replace('{idCible}', idCible.toString()), null, this.httpOptions).toPromise();

    p.catch(() => this.router.navigateByUrl('error'));

    p.then(() => this.ReinitialiserItems());

    return p;
  }

  public IndiquerMecanique(item: Item): Promise<ReponseBase<Item>> {
    var p = this.http.post<ReponseBase<Item>>(this.indiquerMecaniqueUrl, JSON.stringify(item), this.httpOptions).toPromise();

    p.catch(() => this.router.navigateByUrl('error'));

    p.then(() => this.ReinitialiserItems());

    return p;
  }

  public ItemVersItemMin(item: Item): ItemMin {
    return {
      itemId: item.itemId,
      numero: item.numero,
      description: item.description,
      categorie: item.categorie,
      statut: item.statut
    };
  }


  /* 
    public TeleverserDocument(fichier: FichierJoint): Observable<ReponseBase<FichierJoint>> {
      var p = this.http.post<ReponseBase<FichierJoint>>(this.televerserDocumentUrl, JSON.stringify(fichier), this.httpOptions)
        .pipe(
          tap((_) => this.log('posted fichier')),
          catchError(this.handleError<ReponseBase<FichierJoint>>('TeleverserDocument', null)),
        );
    } */

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);

      this.log(`${operation} failed: ${error.message}`);

      this.router.navigateByUrl('erreurtechnique');

      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(message);
  }
}
