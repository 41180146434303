import { Component, Input, Output, OnInit, EventEmitter, Optional } from "@angular/core"; import { Location } from "@angular/common";
import { NonConformiteEntite } from '../../entites/non-conformite';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { NonConformiteService } from '../../services/non-conformite-service';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { FileUpload } from 'primeng/fileupload';
import { ViewChild } from "@angular/core";
import { BaseComponent } from 'src/app/base/base-component';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { ProfilService } from 'src/app/services/profil-service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { DocumentEntite } from 'src/app/entites/document';
import { AppMainComponent } from 'src/app/app.main.component';
import { DocumentService } from 'src/app/services/document-service';
import { ETypeLienDocument } from 'src/app/entites/enums/type-lien-document';

@Component({
    selector: 'app-approbation-non-conformite',
    templateUrl: './approbation-non-conformite.component.html',
    providers: [MessageService, NonConformiteService]
})


export class ApprobationComponent extends BaseComponent implements OnInit {
    @Input() nonConformite: NonConformiteEntite;
    @Input() isModeConsultation: boolean;

    @Output() onViewDocuments = new EventEmitter<void>();

    optionsMode: any[] = [{ label: 'Réel', value: false }, { label: 'Estimatif', value: true }];
    valeurMode: boolean = true;
    minDate: Date = new Date();
    approbationAutre: string;
    valeursApprobation = ['Approuvée', 'Rejetée', 'Approuvé sous conditions'];
    modePopup: boolean = false;

    @ViewChild('fu') public fileUpload: FileUpload;

    constructor(
        public sdb: DonneesBaseService,
        public profilService: ProfilService,
        public route: ActivatedRoute,
        public routerService: Router,
        public appMain: AppMainComponent, location: Location,
        private messageService: MessageService,
        private nonConformiteService: NonConformiteService,
        private documentService: DocumentService,
        @Optional() private config: DynamicDialogConfig,
        @Optional() private ref: DynamicDialogRef,
    ) {
        super(sdb, profilService, route, routerService, appMain);

        if (config) {
            this.modePopup = true;
            this.nonConformite = JSON.parse(JSON.stringify(config.data.nonConformite));
        }
    }

    ngOnInit(): void {
        if (!this.valeursApprobation.includes(this.nonConformite.approbation)) {
            this.approbationAutre = this.nonConformite.approbation;
            this.nonConformite.approbation = 'Autre';
        }

        if (this.isModeConsultation)
            this.Consulter();
        else
            this.Modifier();
    }

    onSelect(event: any) {
        for (let file of event.files) {
            if (file.name.length > 100) {
                this.messageService.add({ severity: 'error', summary: 'Erreur', detail: 'Le nom du fichier ne doit pas dépasser 100 caractères incluant l\'extension.' });
                // Supprime le fichier de la file d'attente de téléchargement
                const index = this.fileUpload.files.indexOf(file);
                this.fileUpload.remove(file, index);
            }
        }
    }

    confirmerApprobation() {
        if (this.nonConformite.approbation === 'Autre') {
            this.nonConformite.approbation = this.approbationAutre;
        }

        this.nonConformiteService.ApprobationNonConformite(this.nonConformite).then(response => {
            if (response.estUnSucces) {
                this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Approbation enregistrée' });
                this.ref.close(response.valeur);
            } else {
                this.messageService.add({ severity: 'error', summary: 'Erreur', detail: response.messages[0].description });
                this.nonConformite.approbation = 'Autre';
            }
        });
    }

    onUpload(event: any) {
        if (!this.nonConformite.approbation || !this.nonConformite.monetaire) {
            return;
        }

        this.Bloquer();

        this.documentService.AjouterDocuments(ETypeLienDocument.NonConformite,
            this.nonConformite.idNonConformite,
            new Date(),
            this.fileUpload.files,
            this.sdb.TypesDocument().find(t => t.code === 'traitement_nc')).then(res => {
                if (res) {
                    this.Debloquer();

                    this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Les documents ont été ajoutés.' });
                    this.fileUpload.clear();
                    this.confirmerApprobation();
                } else {
                    this.Debloquer();
                    this.messageService.add({ severity: 'error', summary: 'Erreur', detail: 'Une erreur est survenue lors de l\'ajout des documents.' });
                    return;
                }
            });
    }
}