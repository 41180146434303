import { Component, OnInit, ViewChild, Input } from "@angular/core"; import { Location } from "@angular/common";
import { Table } from 'primeng/table';
import { BreadcrumbService } from '../../app.breadcrumb.service';

import { RequisitionMin } from '../../entites/requisition-min';
import { MenuItem, TreeNode } from 'primeng/api';
import { MessageService, LazyLoadEvent, ConfirmationService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { EnvoiRequisitionComponent } from '../../controles/envoi-requisition.component';
import { ItemMin } from '../../entites/item-min';
import { ItemService } from '../../services/item-service';
import { Router, ActivatedRoute } from '@angular/router';

import { ToolbarModule } from 'primeng/toolbar';

import { MenubarModule } from 'primeng/menubar';
import { BaseComponent } from '../../base/base-component';
import { Item } from '../../entites/item';
import { Inventaire } from '../../entites/inventaire';
import { NoteEntite } from '../../entites/note';
import { Requisition } from '../../entites/requisition';
import { RequisitionService } from '../../services/requisition-service';
import { Emplacement } from '../../entites/emplacement';
import { EmplacementService } from '../../services/emplacement-service';
import { AchatService } from '../../services/achat-service';
import { BonCommandeEntite } from '../../entites/bon-commande';
import { FournisseurService } from 'src/app/services/fournisseur-service';
import { Fournisseur } from 'src/app/entites/fournisseur';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { ProfilService } from 'src/app/services/profil-service';
import { ListeAchatService } from 'src/app/services/liste-achat-service';
import { ModeleListeAchatService } from 'src/app/services/modele-liste-achat-service';
import { AppMainComponent } from 'src/app/app.main.component';

@Component({
    templateUrl: './ajout-modele-liste-achat.component.html',
    styleUrls: ['./ajout-modele-liste-achat.component.scss'],
    providers: [MessageService, ConfirmationService, DialogService]
})
export class AjoutModeleListeAchatComponent extends BaseComponent implements OnInit {

    nom: string;
    description: string;

    submitted:boolean = false;

    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute, 
		appMain: AppMainComponent, location: Location, 
        private breadcrumbService: BreadcrumbService,
        private modeleListeAchatService: ModeleListeAchatService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        public dialogService: DialogService,
        public routerService: Router ) {

        super(sdb, profilService, route, routerService, appMain);

        this.breadcrumbService.setItems([
            { label: 'Ajout d\'un modèle de liste d\'achat' }
        ]);
    }

    ngOnInit() {
this.CompleterChargement();
    }

    CreerModele() {

        

        this.submitted = true;
        this.messageService.clear();

        if (this.valider()) {

            this.DemarrerChargement();
            
            this.modeleListeAchatService.CreerModele({
                nom: this.nom,
                description: this.description
            }).then(x => {
                if (x.estUnSucces) {
                    this.routerService.navigateByUrl('pilotage/modeleListeAchat/' + x.valeur);
                } else {
                    x.messages.forEach(x => {
                        this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description, sticky: true });
                    });

                    this.CompleterChargement();
                }
            });
        } else {

        }
    }

    valider(): boolean {


        if (!this.nom) {
            return false;
        }
            
        return true;
    }
}
