import { Component, ViewChild, ElementRef, ɵConsole } from "@angular/core"; import { Location } from "@angular/common";
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { CleValeur, CleValeurNumerique } from '../entites/cleValeur';
import { EntreeCommande } from '../entites/entree-commande';
import { ItemMin } from '../entites/item-min';
import { Statut } from '../entites/statut';
import { DonneesBaseService } from '../services/donnees-base-service';
import { BaseComponent } from '../base/base-component';
import { ItemService } from '../services/item-service';
import { BlockUIModule } from 'primeng/blockui';
import { FournisseurService } from '../services/fournisseur-service';
import { Fournisseur } from '../entites/fournisseur';
import { EmployeEntite } from '../entites/employe';
import { ItemCommandeEntite } from '../entites/item-commande';
import { MessageService } from 'primeng/api';
import { ProfilService } from '../services/profil-service';
import { ActivatedRoute, Router } from '@angular/router';
import { AchatService } from '../services/achat-service';
import { RaisonArchive } from '../entites/raison-archive';
import { ExecFileOptionsWithStringEncoding } from 'child_process';
import { AppMainComponent } from '../app.main.component';

export interface ParametresSelectionDelai {
    dateDebut: Date;
}

@Component({
    templateUrl: './selection-delai.component.html'
})
export class SelectionDelaiComponent extends BaseComponent {

    donnees: ParametresSelectionDelai;

    quantite: number;
    periodes: CleValeur[];
    selectionPeriode: CleValeur;

    submitted:boolean = false;
    
    @ViewChild('champQuantite') inputQuantite;

    constructor(
        profilService: ProfilService,
        route: ActivatedRoute, 
        routerService: Router,
		appMain: AppMainComponent, location: Location, 
        public itemService: ItemService,
        public achatService: AchatService,
        public fournisseurService: FournisseurService,
        public messageService: MessageService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public sdb: DonneesBaseService) {
        super(sdb, profilService, route, routerService, appMain);
    }

    ngOnInit() {

        this.donnees = this.config.data;

        this.periodes = [
            { "cle": "J", "valeur": "Jour(s)" },
            { "cle": "S", "valeur": "Semaine(s)" },
            { "cle": "M", "valeur": "Mois" },
            { "cle": "A", "valeur": "Année(s)" }
        ];

        this.selectionPeriode = this.periodes[2];

        this.CompleterChargement();

        setTimeout(() => this.inputQuantite.input.nativeElement.focus(), 50)
    }

    annuler() {
        this.ref.close(null);
    }
    surEntree() {
        this.confirmer();
    }

    confirmer() {

        this.submitted = true;
        if (!this.valider()) {
            this.messageService.add({ key: 'creationBonCommande', severity: 'error', summary: 'Erreurs', detail: 'Veuillez corriger les erreurs de saisie.' });
            return;
        }

        this.Bloquer();

        this.sdb.CalculerDelai(this.donnees.dateDebut, this.quantite, this.selectionPeriode.cle).then(res => {
            this.Debloquer();
            if (res.estUnSucces) {
                this.ref.close(res.valeur);
            } else {
                res.messages.forEach(x => {
                    this.messageService.add({ key: 'archivage', severity: 'error', summary: 'Erreur', detail: x.description, sticky: true });
                });
            }
        });

    }

    valider() {
        return this.quantite && this.selectionPeriode;
    }
}