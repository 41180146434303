import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from "@angular/core"; import { Location } from "@angular/common";

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { ReponseBase } from './reponse-base';
import { EmployeEntite } from '../entites/employe';

@Injectable({ providedIn: 'root' })
export class EmployeService {

  private obtenirListeUrl = environment.apiBaseUrl + 'employe';
  private obtenirUrl = environment.apiBaseUrl + 'employe/{id}';
  private ajouterUrl = environment.apiBaseUrl + 'employe';
  private modifierUrl = environment.apiBaseUrl + 'employe/modifier';
  private supprimerUrl = environment.apiBaseUrl + 'employe/{id}';


  public httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(
    private http: HttpClient,
    public router: Router) { }

  public ObtenirListe(): Promise<ReponseBase<EmployeEntite[]>> {
    var p = this.http.get<ReponseBase<EmployeEntite[]>>(this.obtenirListeUrl)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public Obtenir(id: number): Promise<ReponseBase<EmployeEntite>> {
    var p = this.http.get<ReponseBase<EmployeEntite>>(this.obtenirUrl.replace('{id}', id.toString()))
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public Ajouter(employe: EmployeEntite): Promise<ReponseBase<EmployeEntite>> {
    var p = this.http.post<ReponseBase<EmployeEntite>>(this.ajouterUrl, JSON.stringify(employe), this.httpOptions)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public Modifier(employe: EmployeEntite): Promise<ReponseBase<EmployeEntite>> {
    var p = this.http.post<ReponseBase<EmployeEntite>>(this.modifierUrl, JSON.stringify(employe), this.httpOptions)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public Supprimer(id: number): Promise<ReponseBase<number>> {
    var p = this.http.delete<ReponseBase<number>>(this.supprimerUrl.replace('{id}', id.toString()))
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);

      this.log(`${operation} failed: ${error.message}`);

      this.router.navigateByUrl('erreurtechnique');

      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(message);
  }
}
