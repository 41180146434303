import { Component, OnInit, ViewChild, Input } from "@angular/core"; import { Location } from "@angular/common";
import { Table } from 'primeng/table';
import { BreadcrumbService } from '../../app.breadcrumb.service';

import { RequisitionService } from '../../services/requisition-service';
import { RequisitionMin } from '../../entites/requisition-min';
import { MenuItem } from 'primeng/api/menuitem';
import { MessageService, LazyLoadEvent, ConfirmationService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

import { DialogService } from 'primeng/dynamicdialog';
import { EnvoiRequisitionComponent } from '../../controles/envoi-requisition.component';
import { Router, ActivatedRoute } from '@angular/router';
import { ListeAchatService } from 'src/app/services/liste-achat-service';
import { CategorieMin, CategorieEntite } from 'src/app/entites/categorie';
import { CategorieService } from 'src/app/services/categorie-service';
import { AttributEntite } from 'src/app/entites/attribut';
import { AttributService } from 'src/app/services/attribut-service';
import { BaseComponent } from 'src/app/base/base-component';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { ProfilService } from 'src/app/services/profil-service';
import { ContextMenu } from 'primeng/contextmenu/public_api';
import { AppMainComponent } from 'src/app/app.main.component';

@Component({
    templateUrl: './attributs.component.html',
    styleUrls: ['./attributs.component.scss'],
    styles: [`
        @media screen and (max-width: 960px) {
            :host ::ng-deep .p-datatable.p-datatable-customers.rowexpand-table .p-datatable-tbody > tr > td:nth-child(6) {
                display: flex;
            }
        }

    `],
    providers: [MessageService, ConfirmationService, DialogService]
})
export class AttributsComponent extends BaseComponent implements OnInit {

    attributs: AttributEntite[];
    selectionAttribut: AttributEntite;

    attribut: AttributEntite;


    dialogueOuvert: boolean;
    submitted: boolean;

    menuContextuel: MenuItem[];

    @ViewChild('dt') table: Table;
    @ViewChild('menuContextuelItemCommande') menuContextuelItemCommande: ContextMenu;

    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute, 
		appMain: AppMainComponent, location: Location, 
        private breadcrumbService: BreadcrumbService,
        private attributService: AttributService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        public dialogService: DialogService,
        public routerService: Router) {
        super(sdb, profilService, route, routerService, appMain);
        this.breadcrumbService.setItems([
            { label: 'Attributs' }
        ]);
    }

    ngOnInit() {
        this.verifierAccesPage('admin_attributs');

        console.log('A');
        this.attributService.ObtenirListe().then(x => {
console.log('B');
            this.attributs = x.valeur;

            this.CompleterChargement();
        });


    }

    menuContextuelEntrees: MenuItem[];
    surMenuContextuelItem(event, attribut: AttributEntite) {
        this.selectionAttribut = attribut;

        this.menuContextuelEntrees = [
            { label: 'Modifier', icon: 'pi pi-fw pi-pencil', command: () => this.modifier(this.selectionAttribut) },
            { separator: true },
            { label: 'Supprimer', icon: 'pi pi-fw pi-trash',  command: () => this.supprimer(this.selectionAttribut) },
        ];

        this.menuContextuelItemCommande.toggle(event);
    }

    ajouter() {
        this.routerService.navigateByUrl('pilotage/attribut/0');
        return;

        this.attribut = {
            typeChamp: 1,
            indObligatoire: false
        };
        this.submitted = false;
        this.dialogueOuvert = true;
    }

    modifier(attr: AttributEntite) {
        this.routerService.navigateByUrl(`pilotage/attribut/${attr.attributId}`);
        return;

        this.attribut = { ...attr };
        this.dialogueOuvert = true;
    }

    supprimer(attr: AttributEntite) {
        this.confirmationService.confirm({
            message: 'Voulez-vous vraiment supprimer l\'attribut ' + attr.nom + ' et toutes les valeurs qui lui sont rattachées ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.attributService.Supprimer(attr.attributId).then(res => {
                    if (res.estUnSucces) {
                        this.attributs = this.attributs.filter(val => val.attributId !== attr.attributId);
                        this.attribut = {};
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Attribut supprimé' });
                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                });
            }
        });
    }

    annuler() {
        this.dialogueOuvert = false;
        this.submitted = false;
    }

    sauvegarder() {
        this.submitted = true;

        if (!this.valider()) {
            this.messageService.add({ severity: 'error', summary: 'Erreurs', detail: 'Veuillez corriger les erreurs de saisie.' });
            return;
        }

        this.attribut.typeChamp = parseInt(this.attribut.typeChamp.toString());

        if (this.attribut.attributId) {

            this.attributService.Modifier(this.attribut).then(res => {
                if (res.estUnSucces) {
                    this.attribut = res.valeur;
                    this.attributs[this.findIndexById(this.attribut.attributId)] = this.attribut;
                    this.attributs = [...this.attributs];
                    this.dialogueOuvert = false;
                    this.attribut = {};
                    this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Attribut modifié' });
                } else {
                    res.messages.forEach(x => {
                        this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                    });
                }
            });

        }
        else {

            this.attributService.Ajouter(this.attribut).then(res => {
                if (res.estUnSucces) {
                    this.attribut = res.valeur;
                    this.attributs.push(this.attribut);
                    this.attributs = [...this.attributs];
                    this.dialogueOuvert = false;
                    this.attribut = {};
                    this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Attribut ajouté' });
                } else {
                    res.messages.forEach(x => {
                        this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                    });
                }
            });
        }

    }

    valider() {
        return this.attribut.nom
    }

    findIndexById(id: number): number {
        let index = -1;
        for (let i = 0; i < this.attributs.length; i++) {
            if (this.attributs[i].attributId === id) {
                index = i;
                break;
            }
        }

        return index;
    }


}
