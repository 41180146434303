<p-toast></p-toast>


<p-card *ngIf="!EstCharge()">
	<div class="fluid formgrid grid">
		<div class="flex flex-column gap-2 my-2 col-12 text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-card>

<div *ngIf="EstCharge()" class="grid">
    <div class="col-12">

        <div class="card">
            <p-table #dt [value]="fournisseurs" dataKey="fournisseurId" tableStyleClass="tableNormale" autoLayout="true"
                styleClass="p-datatable-sm p-datatable-striped" [rowHover]="true"  [filterDelay]="0" sortfield="nom"
                [globalFilterFields]="['numero','nom', 'contactPrincipal.nom', 'contactPrincipal.prenom', 'contactPrincipal.courriel', 'contactPrincipal.cellulaire', 'contactPrincipal.telephone']">
                <ng-template pTemplate="caption">
                    <div class="flex align-content-center justify-content-between">
                        <div>
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input #rechercheItem pInputText type="text"
                                (keyup.enter)="surEntreeRecherche()"
                                    (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Recherche..." />
                            </span>
                            <button *ngIf="estAutorise('fournisseur_creer')" pButton pRipple icon="pi pi-plus"
											class="p-button-success mr-2 ml-4 mb-2 boutonGrille"
											(click)="ajouterFournisseur()"></button>
                        </div>
                        <div>
                            <h5 class="titreTableau m-0">Fournisseurs</h5>
                        </div>
                        <div>
                            <p-multiSelect disabled="true" [style]="{minWidth: '200px'}" placeholder="Colonnes..."></p-multiSelect>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        
                        <th pSortableColumn="numero">
                            <div class="flex justify-content-between align-items-center">
                                Numéro
                                <p-sortIcon field="numero"></p-sortIcon>
                                <p-columnFilter type="text" field="numero" display="menu" class="ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="Nom">
                            <div class="flex justify-content-between align-items-center">
                                Nom
                                <p-sortIcon field="Nom"></p-sortIcon>
                                <p-columnFilter type="text" field="Nom" display="menu" class="ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="contactPrincipal.prenom">
                            <div class="flex justify-content-between align-items-center">
                                Contact principal
                                <p-sortIcon field="contactPrincipal.prenom"></p-sortIcon>
                                <p-columnFilter type="text" field="contactPrincipal.prenom" display="menu" class="ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="contactPrincipal.courriel">
                            <div class="flex justify-content-between align-items-center">
                                Courriel
                                <p-sortIcon field="contactPrincipal.courriel"></p-sortIcon>
                                <p-columnFilter type="text" field="contactPrincipal.courriel" display="menu" class="ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="contactPrincipal.telephone">
                            <div class="flex justify-content-between align-items-center">
                                Téléphone
                                <p-sortIcon field="contactPrincipal.telephone"></p-sortIcon>
                                <p-columnFilter type="text" field="contactPrincipal.telephone" display="menu" class="ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="contactPrincipal.cellulaire">
                            <div class="flex justify-content-between align-items-center">
                                Cellulaire
                                <p-sortIcon field="contactPrincipal.cellulaire"></p-sortIcon>
                                <p-columnFilter type="text" field="contactPrincipal.cellulaire" display="menu" class="ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th style="min-width: 5rem"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-fournisseur>
                    <tr [pContextMenuRow]="fournisseur">
                        <td>
                            <a *ngIf="estAutorise('fournisseur_detail')" [routerLink]="'/fournisseur/' + fournisseur.fournisseurId">{{fournisseur.numero}}</a>
                            <span *ngIf="!estAutorise('fournisseur_detail')">{{fournisseur.numero}}</span>

                        </td>
                        <td>{{fournisseur.nom}}</td>
                        <td>{{fournisseur.contactPrincipal?.prenom}} {{fournisseur.contactPrincipal?.nom}}</td>
                        <td>{{fournisseur.contactPrincipal?.courriel}}</td>
                        <td>{{fournisseur.contactPrincipal?.telephone}}</td>
                        <td>{{fournisseur.contactPrincipal?.cellulaire}}</td>
                        <td >
                            <button *ngIf="estAutorise('fournisseur_detail')" pButton pRipple icon="pi pi-eye"
                                class="p-button-success mr-2 boutonGrille"
                                (click)="OuvrirDetailFournisseur(fournisseur.fournisseurId)"></button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="7">Aucun fournisseur.</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>


</div>