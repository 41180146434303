import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { ConfirmationService } from 'primeng/api';

export interface ComponentCanDeactivate {
  canDeactivate: () => Promise<boolean>;
}

@Injectable()
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {

    async canDeactivate(component: ComponentCanDeactivate): Promise<boolean> {
        const x = await component.canDeactivate();
        return x;
    }
}