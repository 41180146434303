<p-blockUI [blocked]="!EstCharge()">
    <div class="fluid formgrid grid">
        <div class="flex flex-column gap-2 col-12 text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-blockUI>
<div class="stepsdemo-content">
    <p-card *ngIf="estSucces">
        <ng-template pTemplate="title">
            Confirmation
        </ng-template>
        <ng-template pTemplate="content">
            <div class="fluid formgrid grid">
                <div class="flex flex-column gap-2 col-12">
                    <h5>Votre liste d'achat à été envoyée pour approbation.</h5>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="footer">

        </ng-template>
         
    </p-card>
    <p-card *ngIf="estErreur">
        <ng-template pTemplate="title">
            Erreur
        </ng-template>
        <ng-template pTemplate="content">
            <div class="fluid formgrid grid">
                <div class="flex flex-column gap-2 col-12">
                    <h5>Une erreur s'est produite lors de l'envoi de la liste d'achat. Votre liste n'a pas été soumise.</h5>
                    
                </div>
            </div>
            <button pButton pRipple label="Réessayer" icon="pi pi-refresh" class="p-button-text"
            (click)="reessayerEnvoi()"></button>
        </ng-template>
        <ng-template pTemplate="footer">

        </ng-template>
         
    </p-card>
</div>