import { Component, OnInit, ViewChild, Input } from "@angular/core"; import { Location } from "@angular/common";
import { Table } from 'primeng/table';
import { BreadcrumbService } from '../../app.breadcrumb.service';

import { RequisitionService } from '../../services/requisition-service';
import { RequisitionMin } from '../../entites/requisition-min';
import { MenuItem } from 'primeng/api/menuitem';
import { MessageService, LazyLoadEvent, ConfirmationService } from 'primeng/api';
import {ToastModule} from 'primeng/toast';

import {DialogService} from 'primeng/dynamicdialog';
import { EnvoiRequisitionComponent } from '../../controles/envoi-requisition.component';
import { Router, ActivatedRoute } from '@angular/router';
import { ListeAchatService } from 'src/app/services/liste-achat-service';
import { Emplacement } from 'src/app/entites/emplacement';
import { EmployeEntite } from 'src/app/entites/employe';
import { EmplacementService } from 'src/app/services/emplacement-service';
import { BaseComponent } from 'src/app/base/base-component';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { ProfilService } from 'src/app/services/profil-service';
import { AppMainComponent } from 'src/app/app.main.component';

@Component({
    templateUrl: './emplacements.component.html',
    styleUrls: ['./emplacements.component.scss'],
    styles: [`
        @media screen and (max-width: 960px) {
            :host ::ng-deep .p-datatable.p-datatable-customers.rowexpand-table .p-datatable-tbody > tr > td:nth-child(6) {
                display: flex;
            }
        }

    `],
    providers: [MessageService, ConfirmationService, DialogService]
})
export class EmplacementsComponent extends BaseComponent implements OnInit {

    emplacements: Emplacement[];
    selectionEmplacement: Emplacement;

    dialogueNouvelEmplacement = false;
    submitted = false;
    nouvelEmplacement: Emplacement;

    
    @ViewChild('champRecherche') inputRecherche;
    @ViewChild('dt') table: Table;

    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute, 
		appMain: AppMainComponent, location: Location, 
        private breadcrumbService: BreadcrumbService,
        private emplacementService: EmplacementService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        public dialogService: DialogService,
        public routerService: Router) {
            super(sdb, profilService, route, routerService, appMain);
        this.breadcrumbService.setItems([
            { label: 'Emplacements' }
        ]);
    }

    ngOnInit() {



        this.verifierAccesPage('emplacements');

        this.emplacements = this.sdb.EmplacementsComplet();


        if (this.profilService.Profil().roles.find(x => x.nom == 'SIHC_ChargeProjet')) {
            if (this.profilService.Profil().employe.emplacementIdsCP) {
                this.emplacements = this.sdb.Emplacements().filter(x => x.commandeActive && this.profilService.Profil().employe.emplacementIdsCP.includes(x.emplacementId));
            } else {
                this.emplacements = [];    
            }
        } else {
            this.emplacements = this.sdb.EmplacementsComplet();
        }

        this.CompleterChargement();

        setTimeout(() => { this.inputRecherche.nativeElement.focus(); }, 100);
           
    }

    surEntreeRecherche() {

        if (this.table.filteredValue != undefined &&
            this.table.filteredValue.length == 1) {
                this.OuvrirDetail(this.table.filteredValue[0].emplacementId);
            }

    }

    OuvrirDetail(emplacementId: number) {
        this.routerService.navigateByUrl(`pilotage/emplacement/${emplacementId}`)
    }

    surAjoutEmplacement() {
        this.nouvelEmplacement = { emplacementId: 0};
        this.dialogueNouvelEmplacement = true;
    }

    creerNouvelEmplacement() {

        //this.dialogueNouvelItem = false;
        //this.routerService.navigateByUrl('item/595');
        this.submitted = true;
        this.messageService.clear("nouvelEmplacement");

        if (this.validerNouvelEmplacement()) {

            this.emplacementService.AjouterEmplacement(this.nouvelEmplacement).then(x => {
                console.log(x);
                if (x.estUnSucces) {
                    this.routerService.navigateByUrl('pilotage/emplacement/' + x.valeur.emplacementId);
                } else {
                    x.messages.forEach(x => {
                        this.messageService.add({ key: "nouvelEmplacement" ,severity: 'error', summary: 'Erreur', detail: x.description, sticky: true });
                    });
                }
            });
        }
    }

    validerNouvelEmplacement(): boolean {
        if (!this.nouvelEmplacement.code || !this.nouvelEmplacement.nom) {
            return false;
        }

        return true;
    }

    annulerNouvelEmplacement() {
        this.dialogueNouvelEmplacement = false;
    }
}
