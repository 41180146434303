<p-toast key="selectionItem"></p-toast>
<p-menu appendTo="body" #menuContextuelAjout popup="popup" [model]="contenuMenuContextuelAjout"></p-menu>

<p-blockUI [blocked]="blocked">
    <div class="fluid formgrid grid">
        <div class="flex flex-column gap-2 my-2 col-12 text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-blockUI>


<p-card *ngIf="!EstCharge()">
    <div class="fluid formgrid grid">
        <div class="flex flex-column gap-2 my-2 col-12 text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-card>

<div #contenu *ngIf="EstCharge()">
    
    <!------------ TYPE SAISIE ET CATÉGORIE ------------>
    
    <div class="fluid formgrid grid" *ngIf="estTypeSaisieDisponible">
        <div class="col-12 md:col-6 lg:col-3 mb-3">
            <p-selectButton [options]="typesSaisie" [(ngModel)]="selectionTypeSaisie" optionLabel="valeur">
            </p-selectButton>
        </div>
        <div class="col-12 md:col-6 lg:col-3 mb-3" *ngIf="!itemSelectionne && typeSaisieInventaire()">
            <p-selectButton (onChange)="surChangementCategorie()" [options]="listeCategories" [(ngModel)]="selectionCategorie" optionLabel="valeur">
            </p-selectButton>
        </div>
        <div *ngIf="nouveauDisponible && !typeSaisieDivers() && estAutorise('item_creer')" class="">
            <button (click)="nouvelItem($event)" pButton type="button" label="Nouveau" icon="pi pi-plus" [text]="true" [outlined]="true" [severity]="'success'"></button>
            <!-- <i class="pi pi-plus mr-3"></i>
			<a class="lienFicheComplete" (click)="nouvelItem($event)">Nouveau</a> -->
		</div>

    </div>

    <!------------ LISTE SÉLECTION INVENTAIRE ------------>

    <div class="fluid formgrid grid" *ngIf="typeSaisieInventaire() && !itemSelectionne">
        <div class="col-12">
            <p-table #dt [value]="items" [columns]="selectedColumns" dataKey="itemId" autoLayout="true" tableStyleClass="tableNormale"
                styleClass="p-datatable-sm p-datatable-striped" [(contextMenuSelection)]="selectionItem"
                [contextMenu]="cm" [rowHover]="true" [rows]="10" [paginator]="true" [filterDelay]="0"
                [globalFilterFields]="['numero','description','statut','categorie','sousCategorie','marque','modele','noSerie','noPlaque','produitsFournisseurChaine','ancienNumero']">
                <ng-template pTemplate="caption">
                    <div class="flex align-content-center justify-content-between">
                        <div>
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input #rechercheItem pInputText type="text"
                                    (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    (keyup.enter)="surEntreeRecherche()" placeholder="Recherche..." />
                            </span>
                        </div>
                        <div>
                            <h5 class="titreTableau m-0">Items</h5>
                        </div>

                        <div>
                            <p-multiSelect [options]="colonnesDisponibles" [(ngModel)]="selectedColumns"
                                optionLabel="entete" (onChange)="surChangementColonnes($event)"
                                selectedItemsLabel="{0} colonnes sélectionnés" [style]="{minWidth: '200px'}"
                                placeholder="Colonnes...">
                            </p-multiSelect>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                    <tr>

                        <th pSortableColumn="numero" *ngIf="afficherColonneNumero">
                            <div class="flex justify-content-between align-items-center">
                                Numéro
                                <p-sortIcon field="numero"></p-sortIcon>
                                <p-columnFilter type="text" field="numero" display="menu" class="ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="description">
                            <div class="flex justify-content-between align-items-center">
                                Description
                                <p-sortIcon field="description"></p-sortIcon>
                                <p-columnFilter type="text" field="description" display="menu" class="ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th *ngIf="afficherColonneStatut">
                            <div class="flex justify-content-between align-items-center">
                                Statut
                                <p-columnFilter field="statut" matchMode="in" display="menu" [showMatchModes]="false"
                                    [showOperator]="false" [showAddButton]="false">
                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                        <p-multiSelect [ngModel]="value" [options]="statuts" placeholder="Any"
                                            (onChange)="filter($event.value)" optionLabel="name">
                                            <ng-template let-option pTemplate="item">
                                                <div class="multiselect-representative-option">

                                                    <span class="ml-1">{{option.nom}}</span>
                                                </div>
                                            </ng-template>
                                        </p-multiSelect>
                                    </ng-template>
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="quantite" *ngIf="afficherColonneInventaire">
                            <div class="flex justify-content-between align-items-center">
                                Quantité
                                <p-sortIcon field="quantite"></p-sortIcon>
                                <p-columnFilter type="number" field="quantite" display="menu" class="ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th *ngIf="EstColonneSelectionnee('categorie')" pSortableColumn="categorie.nom">
                            <div class="flex justify-content-between align-items-center">
                                Catégorie
                                <p-sortIcon field="categorie.nom"></p-sortIcon>
                                <p-columnFilter #filtreCategorie field="categorie.nom" matchMode="in" display="menu"
                                    [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                        <p-multiSelect #champFiltreCategorie [ngModel]="value" [options]="categories" placeholder="Any"
                                            (onChange)="filter($event.value)" optionLabel="name">
                                            <ng-template let-option pTemplate="item">
                                                <div class="multiselect-representative-option">
                                                    <span class="ml-1">{{option}}</span>
                                                </div>
                                            </ng-template>
                                        </p-multiSelect>
                                    </ng-template>
                                </p-columnFilter>
                            </div>
                        </th>
                        <th *ngIf="EstColonneSelectionnee('sousCategorie')" pSortableColumn="sousCategorie.nom">
                            <div class="flex justify-content-between align-items-center">
                                Sous-catégorie
                                <p-sortIcon field="sousCategorie.nom"></p-sortIcon>
                                <p-columnFilter type="text" field="sousCategorie" display="menu" class="ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th *ngIf="EstColonneSelectionnee('marque')" pSortableColumn="modele">
                            <div class="flex justify-content-between align-items-center">
                                Marque
                                <p-sortIcon field="modele"></p-sortIcon>
                                <p-columnFilter type="text" field="modele" display="menu" class="ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th *ngIf="EstColonneSelectionnee('modele')" pSortableColumn="modele">
                            <div class="flex justify-content-between align-items-center">
                                Modèle
                                <p-sortIcon field="modele"></p-sortIcon>
                                <p-columnFilter type="text" field="description" display="menu" class="ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        
                        <th *ngIf="EstColonneSelectionnee('noProduit')" >
                            <div class="flex justify-content-between align-items-center">
                                No pièce fournisseur
            
                            </div>
                        </th>
                        <th *ngIf="EstColonneSelectionnee('emplacement')" pSortableColumn="emplacement">
                            <div class="flex justify-content-between align-items-center">
                                Emplacement
                                <p-sortIcon field="emplacement"></p-sortIcon>
                                <p-columnFilter type="text" field="emplacement" display="menu" class="ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th style="min-width: 8rem"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item let-columns="columns">
                    <tr [pContextMenuRow]="item">
                        <td *ngIf="afficherColonneNumero">{{item.numero}}
                        </td>
                        <td>{{item.description}}</td>
                        <td *ngIf="afficherColonneStatut">
                            <span [class]="'statut ' + item.statut.nom.toLowerCase()">
                                {{item.statut.nom}}
                            </span>
                        </td>
                        <td *ngIf="afficherColonneInventaire">
                            <span [ngClass]="item.quantite > 0 ? 'inventaire disponible' : 'inventaire nondisponible'">
                                {{item.quantite}}
                            </span>
                        </td>
                        <td *ngIf="EstColonneSelectionnee('categorie')">
                            {{item.categorie.nom}}
                        </td>
                        <td *ngIf="EstColonneSelectionnee('sousCategorie')">
                            {{item.sousCategorie?.nom}}
                        </td>
                        <td *ngIf="EstColonneSelectionnee('marque')">
                            {{item.marque}}
                        </td>
                        <td *ngIf="EstColonneSelectionnee('modele')">
                            {{item.modele}}
                        </td>
                        <td *ngIf="EstColonneSelectionnee('noProduit')" style="white-space: pre-line;">
                            {{item.produitsFournisseurChaine}}
                        </td>
                        <td *ngIf="EstColonneSelectionnee('emplacement')">
                            {{item.emplacement | nomEmplacement}}
                        </td>
                        <td>
                            <button *ngIf="estAutorise('item_detail')" pButton pRipple icon="pi pi-external-link"
                                class="p-button-secondary mr-2 boutonGrille"
                                (click)="OuvrirDetailItemNouvelOnglet(item.itemId)"></button>
                            <button [disabled]="(estTypeRequisition() || estTypeCommande()) && item.estLie" pButton pRipple icon="pi pi-check" class="p-button-success mr-2 boutonGrille"
                            (click)="surSelectionItemInventaire(item)"></button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="8">Aucun item.</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>

    <!------------ NUMÉRO/DESCRIPTION ------------>

    <div class="fluid formgrid grid" *ngIf="!typeSaisieInventaire() || itemSelectionne">
        <div *ngIf="typeSaisieInventaire() && !estTypePreCommande()" class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3">
            <label for="noSIHC">No SIHC<span class="requis">*</span></label>
            <input type="text" pInputText id="noSIHC" [(ngModel)]="selectionItem.numero" required readonly disabled
                autocomplete="off" autofocus />
            <small class="p-error" *ngIf="submitted && !selectionItem.numero">Le numéro SIHC est
                obligatoire.</small>
        </div>
        <div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-6">
            <label for="description">Description<span class="requis">*</span></label>
            <input #descriptionItem type="text" pInputText id="description" [(ngModel)]="selectionItem.description"
                [readonly]="typeSaisieInventaire()" [disabled]="typeSaisieInventaire()" autocomplete="off" autofocus />
            <small class="p-error" *ngIf="submitted && !selectionItem.description">La description
                est
                obligatoire.</small>
        </div>
        <div *ngIf="typeSaisieInventaire()" class="flex align-items-end gap-2 my-2 col-12 md:col-6 lg:col-3">
            <button pButton pRipple icon="pi pi-search" class="p-button-rounded p-button-primary mr-2 mt-3"
                (click)="RechercherItem()"></button>

            <p-menu #menuContextuelReleve popup="popup" appendTo="body" [model]="contenuMenuContextuelReleve">
                </p-menu>
            <button pButton pRipple icon="pi pi-bars" class="p-button-rounded p-button-secondary mr-2 mt-3"
                (click)="surMenuContextuelItem($event)"></button>

        </div>
    </div>

    <!------------ CHAMPS SPÉCIFIQUES ------------>

    <div class="fluid formgrid grid" *ngIf="!typeSaisieInventaire() || itemSelectionne">
        <div class="flex flex-column gap-2 my-2 col-12 lg:col-3">
            <label for="quantity">Quantite<span class="requis">*</span></label>
            <p-inputNumber *ngIf="!estTypeBonCommande()" #champQuantite [(ngModel)]="quantiteDemande"
                [readonly]="typeSaisieInventaire() && selectionItem && selectionItem.categorie.estUnique"
                [disabled]="typeSaisieInventaire() && selectionItem && selectionItem.categorie.estUnique">
            </p-inputNumber>
            <p-inputNumber *ngIf="estTypeBonCommande()" #champQuantite [(ngModel)]="quantiteDemande" 
            [minFractionDigits]="2" [maxFractionDigits]="2" locale="en-US"
                [readonly]="typeSaisieInventaire() && selectionItem && selectionItem.categorie.estUnique"
                [disabled]="typeSaisieInventaire() && selectionItem && selectionItem.categorie.estUnique">
            </p-inputNumber>
            <small class="p-error" *ngIf="submitted && !quantiteDemande">La quantité est obligatoire.</small>
        </div>
        <div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3" *ngIf="estTypeBonCommande()">
            <label>Unités</label>
            <input type="text" pInputText [(ngModel)]="uniteQte"
                autocomplete="off" />
           <!--  <small class="p-error" *ngIf="submitted && !noFournisseur">Le numéro de fournisseur est obligatoire.</small> -->
        </div>
        <div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3" *ngIf="estTypePreCommande() || estTypeCommande() || estTypeRequisition()">
            <label for="demandeur">Demandeur<span class="requis">*</span></label>
            <p-dropdown #champDemandeur [options]="listeEmployes" [(ngModel)]="selectionDemandeur" optionLabel="nom"
                [showClear]="true" (onHide)="surFermetureDemandeur()" filter="true" filterBy="prenom,nom"
                appendTo="body" placeholder="Sélectionner un demandeur...">
                <ng-template pTemplate="selectedItem">
                    <div *ngIf="selectionDemandeur">
                        <div>{{selectionDemandeur.prenom}} {{selectionDemandeur.nom}}</div>
                    </div>
                </ng-template>
                <ng-template let-employe pTemplate="item">
                    <div>
                        <div>{{employe.prenom}} {{employe.nom}}</div>
                    </div>
                </ng-template>
            </p-dropdown>
            <small class="p-error" *ngIf="submitted && !selectionDemandeur">Le demandeur est obligatoire.</small>
            <!-- <input #champDemandeur type="text" pInputText id="demandeur" [(ngModel)]="demandeur" autocomplete="off"
                 /> -->
        </div>
        <div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3" *ngIf="estTypeBonCommande()">
            <label for="noFournisseur">No pièce fournisseur</label>
            <input #champNoFournisseur type="text" pInputText id="noFournisseur" [(ngModel)]="noFournisseur"
                autocomplete="off" />
           <!--  <small class="p-error" *ngIf="submitted && !noFournisseur">Le numéro de fournisseur est obligatoire.</small> -->
        </div>
        <div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3" *ngIf="estTypeBonCommande()">
            <label for="prix">Prix<span class="requis">*</span></label>
           <!--  <p-inputNumber #champPrix [(ngModel)]="prix" mode="currency"></p-inputNumber> -->
            <p-inputNumber #champPrix [(ngModel)]="prix" mode="currency" currency="USD" locale="en-US" [minFractionDigits]="4" [maxFractionDigits]="4"></p-inputNumber>
            <small class="p-error" *ngIf="submitted && !prix && prix != 0">Le prix est obligatoire.</small>
        </div>
        <div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3" *ngIf="estTypeCommande()">
            <label for="dateDemande">Date de demande<span class="requis">*</span></label>
            <p-calendar appendTo="body" showIcon=true [(ngModel)]="dateDemande" dateFormat="yy-mm-dd" [yearNavigator]="true" [monthNavigator]="true" yearRange="2000:2040" ></p-calendar>
            <small class="p-error" *ngIf="submitted && !dateDemande">La date de demande est obligatoire.</small>
        </div>
        <div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3" *ngIf="estTypePreCommande() || estTypeCommande()">
            <label for="dateRequis">Date requise<span class="requis">*</span></label>
            <p-calendar appendTo="body" showIcon=true [(ngModel)]="dateRequis" dateFormat="yy-mm-dd" [yearNavigator]="true" [monthNavigator]="true" yearRange="2000:2040" ></p-calendar>
            <small class="p-error" *ngIf="submitted && !dateRequis">La date requise est obligatoire.</small>
        </div>
        <div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3" *ngIf="itemSelectionne && selectionItem.categorie.categorieId == 6 && (estTypePreCommande() || estTypeCommande() || estTypeRequisition())">
            <label for="unite">Unité</label>
            <p-dropdown #champDemandeur [options]="listeUnites" [(ngModel)]="selectionUnite" optionLabel="description"
                [showClear]="true" filter="true" filterBy="numero,description,marque,modele"
                appendTo="body" placeholder="Sélectionner une unité...">
                <ng-template pTemplate="selectedItem">
                    <div *ngIf="selectionUnite">
                        <div>{{selectionUnite.numero}} {{selectionUnite.description}}</div>
                    </div>
                </ng-template>
                <ng-template let-unite pTemplate="item">
                    <div>
                        <div>{{unite.numero}} {{unite.description}}</div>
                    </div>
                </ng-template>
            </p-dropdown>
        </div>
    </div>

    <!------------ BONS DE COMMANDE / PRECOMMANDES - CODES ------------>

    <div class="fluid formgrid grid" *ngIf="(estTypeBonCommande() || estTypePreCommande()) && (!typeSaisieInventaire() || itemSelectionne)">
        <div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3">
            <label for="codeProjet">Code projet<span class="requis">*</span></label>
            <p-dropdown #champCodeProjet [options]="listeCodeProjet" [(ngModel)]="selectionCodeProjet" optionLabel="code"
                [showClear]="true" (onHide)="surFermetureCodeProjet()" filter="true" filterBy="code,nom"
                (onChange)="surChangementCodeProjet()"
                appendTo="body" placeholder="Sélectionner un code de projet...">
                <ng-template pTemplate="selectedItem">
                    <div *ngIf="selectionCodeProjet">
                        <div>{{selectionCodeProjet.code}}</div>
                    </div>
                </ng-template>
                <ng-template let-code pTemplate="item">
                    <div>
                        <div>{{code.code}} - {{code.nom}}</div>
                    </div>
                </ng-template>
            </p-dropdown>
            <small class="p-error" *ngIf="submitted && !selectionCodeProjet">Le code de projet est obligatoire.</small>
        </div>
        <div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3">
            <label for="codeActivite">Code d'activité<span *ngIf="estCodeCategorieObligatoire" class="requis">*</span></label>
            <p-dropdown #champCodeActivite [options]="listeCodeActivite" [(ngModel)]="selectionCodeActivite" optionLabel="code"
                [disabled]="!selectionCodeProjet"
                (onChange)="surChangementCodeActivite()"
                [showClear]="true" (onHide)="surFermetureCodeActivite()" filter="true" filterBy="code,nom"
                appendTo="body" placeholder="Sélectionner un code d'activité...">
                <ng-template pTemplate="selectedItem">
                    <div *ngIf="selectionCodeActivite">
                        <div>{{selectionCodeActivite.code}}</div>
                    </div>
                </ng-template>
                <ng-template let-code pTemplate="item">
                    <div>
                        <div>{{code.code}} - {{code.nom}}</div>
                    </div>
                </ng-template>
            </p-dropdown>
            <small class="p-error" *ngIf="submitted && estCodeActiviteObligatoire && !selectionCodeActivite">Le code d'activité est obligatoire.</small>
        </div>
        <div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3">
            <label for="codeCategorie">Catégorie de coût<span *ngIf="estCodeCategorieObligatoire" class="requis">*</span></label>
            <p-dropdown #champCodeCategorie [options]="listeCodeCategorie" [(ngModel)]="selectionCodeCategorie" optionLabel="code"
                [disabled]="!selectionCodeProjet || codeCategorieBloque"
                [showClear]="true" (onHide)="surFermetureCodeCategorie()" filter="true" filterBy="code,nom"
                appendTo="body" placeholder="Sélectionner une catégorie de coût...">
                <ng-template pTemplate="selectedItem">
                    <div *ngIf="selectionCodeCategorie">
                        <div>{{selectionCodeCategorie.code}}</div>
                    </div>
                </ng-template>
                <ng-template let-code pTemplate="item">
                    <div>
                        <div>{{code.code}} - {{code.nom}}</div>
                    </div>
                </ng-template>
            </p-dropdown>
            <small class="p-error" *ngIf="submitted && estCodeCategorieObligatoire && !selectionCodeCategorie">La catégorie de coût est obligatoire.</small>
        </div>
        <div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3">
            <label for="codeCategorie">Équipement<span *ngIf="selectionCodeProjet?.code == 'ENTRET' && selectionCodeActivite?.code == '280'" class="requis">*</span></label>
            <p-dropdown #champCodeEquipement [options]="listeCodeEquipement" [(ngModel)]="selectionCodeEquipement" optionLabel="code"
                [disabled]="codeEquipementBloque"
                [showClear]="true" filter="true" filterBy="code,nom"
                (onChange)="surChangementCodeEquipement()"
                appendTo="body" placeholder="Sélectionner un équipement..">
                <ng-template pTemplate="selectedItem">
                    <div *ngIf="selectionCodeEquipement">
                        <div>{{selectionCodeEquipement.code}}</div>
                    </div>
                </ng-template>
                <ng-template let-code pTemplate="item">
                    <div>
                        <div>{{code.code}} - {{code.nom}}</div>
                    </div>
                </ng-template>
            </p-dropdown>
            <small class="p-error" *ngIf="submitted && selectionCodeProjet?.code == 'ENTRET' && selectionCodeActivite?.code == '280' && !selectionCodeEquipement">La catégorie de coût est obligatoire.</small>
        </div>
        <div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3">
            <label for="codeCategorie">Extra</label>
            <p-dropdown #champCodeExtra [options]="listeCodeExtra" [(ngModel)]="selectionCodeExtra" optionLabel="code"
                [showClear]="true" filter="true" filterBy="code,nom"
                appendTo="body" placeholder="Sélectionner un extra..">
                <ng-template pTemplate="selectedItem">
                    <div *ngIf="selectionCodeExtra">
                        <div>{{selectionCodeExtra.code}}</div>
                    </div>
                </ng-template>
                <ng-template let-code pTemplate="item">
                    <div>
                        <div>{{code.code}} - {{code.nom}}</div>
                    </div>
                </ng-template>
            </p-dropdown>
        </div>
        <div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3">
            <label for="emplacement">Emplacement</label>
            <p-dropdown #champEmplacement [options]="listeEmplacement" [(ngModel)]="selectionEmplacement" optionLabel="code"
                [showClear]="true" filter="true" filterBy="code,nom"
                appendTo="body" placeholder="Sélectionner un emplacement..">
                <ng-template pTemplate="selectedItem">
                    <div *ngIf="selectionEmplacement">
                        <div>{{selectionEmplacement.code}}</div>
                    </div>
                </ng-template>
                <ng-template let-emplacement pTemplate="item">
                    <div>
                        <div>{{emplacement | nomEmplacement}}</div>
                    </div>
                </ng-template>
            </p-dropdown>
        </div>
       


       
    </div>


    <div class="fluid formgrid grid" *ngIf="estTypeBonCommande() && (!typeSaisieInventaire() || itemSelectionne)">

        <div class="flex flex-column gap-2 my-2 col-12">
            <label for="lastname">Ajouter l'item dans la commande</label>
            <p-checkbox [binary]="true" [(ngModel)]="indAjoutCommande" ></p-checkbox>
        </div>

        <div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3" *ngIf="indAjoutCommande">
            <label for="demandeur">Demandeur<span class="requis">*</span></label>
            <p-dropdown #champDemandeur [options]="listeEmployes" [(ngModel)]="selectionDemandeur" optionLabel="nom"
                [showClear]="true" (onHide)="surFermetureDemandeur()" filter="true" filterBy="prenom,nom"
                appendTo="body" placeholder="Sélectionner un demandeur...">
                <ng-template pTemplate="selectedItem">
                    <div *ngIf="selectionDemandeur">
                        <div>{{selectionDemandeur.prenom}} {{selectionDemandeur.nom}}</div>
                    </div>
                </ng-template>
                <ng-template let-employe pTemplate="item">
                    <div>
                        <div>{{employe.prenom}} {{employe.nom}}</div>
                    </div>
                </ng-template>
            </p-dropdown>
            <small class="p-error" *ngIf="submitted && !selectionDemandeur">Le demandeur est obligatoire.</small>
            <!-- <input #champDemandeur type="text" pInputText id="demandeur" [(ngModel)]="demandeur" autocomplete="off"
                 /> -->
        </div>
        <div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3" *ngIf="indAjoutCommande">
            <label for="emplacement">Commande<span class="requis">*</span></label>
            <p-dropdown #champEmplacement [options]="listeEmplacementAjoutCommande" [(ngModel)]="selectionEmplacementAjoutCommande" optionLabel="code"
                [showClear]="true" filter="true" filterBy="code,nom"
                appendTo="body" placeholder="Sélectionner un emplacement..">
                <ng-template pTemplate="selectedItem">
                    <div *ngIf="selectionEmplacementAjoutCommande">
                        <div>{{selectionEmplacementAjoutCommande.code}}</div>
                    </div>
                </ng-template>
                <ng-template let-emplacement pTemplate="item">
                    <div>
                        <div>{{emplacement | nomEmplacement}}</div>
                    </div>
                </ng-template>
            </p-dropdown>
            <small class="p-error" *ngIf="submitted && !selectionEmplacementAjoutCommande">La commande est obligatoire.</small>
        </div>
        <div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-2" *ngIf="indAjoutCommande">
            <label for="dateDemande">Date de demande<span class="requis">*</span></label>
            <p-calendar appendTo="body" showIcon=true [(ngModel)]="dateDemande" dateFormat="yy-mm-dd" [yearNavigator]="true" [monthNavigator]="true" yearRange="2000:2040" ></p-calendar>
            <small class="p-error" *ngIf="submitted && !dateDemande">La date de demande est obligatoire.</small>
        </div>
        <div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-2" *ngIf="indAjoutCommande">
            <label for="dateRequis">Date requise<span class="requis">*</span></label>
            <p-calendar appendTo="body" showIcon=true [(ngModel)]="dateRequis" dateFormat="yy-mm-dd" [yearNavigator]="true" [monthNavigator]="true" yearRange="2000:2040" ></p-calendar>
            <small class="p-error" *ngIf="submitted && !dateRequis">La date requise est obligatoire.</small>
        </div>
        <div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3" *ngIf="indAjoutCommande">
            <label for="unite">Unité</label>
            <p-dropdown [options]="listeUnites" [(ngModel)]="selectionUniteAjoutCommande" optionLabel="description"
                [showClear]="true" filter="true" filterBy="numero,description,marque,modele"
                appendTo="body" placeholder="Sélectionner une unité...">
                <ng-template pTemplate="selectedItem">
                    <div *ngIf="selectionUniteAjoutCommande">
                        <div>{{selectionUniteAjoutCommande.numero}} {{selectionUniteAjoutCommande.description}}</div>
                    </div>
                </ng-template>
                <ng-template let-unite pTemplate="item">
                    <div>
                        <div>{{unite.numero}} {{unite.description}}</div>
                    </div>
                </ng-template>
            </p-dropdown>
        </div>


    </div>

    <!------------ NOTE ------------>

    <div class="fluid formgrid grid" *ngIf="estTypePreCommande() && (!typeSaisieInventaire() || itemSelectionne)">
        <div class="flex flex-column gap-2 my-2 col-12 lg:col-9">
            <label for="note">Note</label>
            <input #champNote type="text" pInputText id="note" [(ngModel)]="note"
                autocomplete="off" />
        </div>
       
    </div>

    <!------------ AJUSTEMENT INVENTAIRE ------------>
    
    <div *ngIf="selectionTypeSaisie.cle == 'N' && (estTypeRequisition() || estTypeCommande())" class="fluid formgrid grid mt-2">

        <div class="flex flex-column gap-2 my-2 col-12">
            <h5><i class="pi pi-exclamation-triangle " style="color:#dd8500; font-size: 1.4rem"></i> Ajustement d'inventaire</h5>
            <span>Veuillez choisir parmi les options suivantes</span>
            <br>
            <p-radioButton label="Créer un bon de commande au fournisseur suivant:" name="typeAjustement" value="1"
                [(ngModel)]="selectionTypeAjustement" class="mt-4"></p-radioButton>

            <div class="fluid formgrid grid">
                <div class="flex flex-column gap-2 my-2 col-12 lg:col-6">
                    <p-dropdown [options]="fournisseurs" [(ngModel)]="selectionFournisseur" optionLabel="nom"
                        [showClear]="true" filter="true" filterBy="numero,nom"
                        placeholder="Sélectionner un fournisseur..." appendTo="body">
                        <ng-template pTemplate="selectedItem">
                            <div *ngIf="selectionFournisseur">
                                <div>{{selectionFournisseur.nom}} ({{selectionFournisseur.numero}})</div>
                            </div>
                        </ng-template>
                        <ng-template let-fournisseur pTemplate="item">
                            <div>
                                <div>{{fournisseur.nom}} ({{fournisseur.numero}})</div>
                            </div>
                        </ng-template>
                    </p-dropdown>
                </div>
            </div>


            <br>
            <p-radioButton label="Effectuer un ajustement d'inventaire automatique." name="typeAjustement" value="2"
                [(ngModel)]="selectionTypeAjustement" class="mt-2"></p-radioButton>
        </div>


    </div>

    <!------------ RETOUR EXPRESS ------------>

    <div *ngIf="(estTypeCommande() || estTypeRequisition()) &&
        selectionTypeSaisie.cle == 'I' 
        && selectionItem && selectionItem.emplacement && selectionItem.emplacement.emplacementId != emplacementIdVerificationTransfertExpress
        && selectionItem.categorie.estUnique" class="fluid formgrid grid mt-2">

        <div class="flex flex-column gap-2 my-2 col-12 mb-3">
            <h5><i class="pi pi-exclamation-triangle " style="color:#dd8500; font-size: 1.4rem"></i> Ajustement d'inventaire</h5>
            <span>L'emplacement actuel de l'item ne permet pas le déplacement. Un retour/transfert automatique sera effectué en date du:</span>
        </div>
        <div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3 mb-3">
            <p-calendar appendTo="body" showIcon=true [(ngModel)]="dateRetourAuto" dateFormat="yy-mm-dd" [yearNavigator]="true" [monthNavigator]="true" yearRange="2000:2040" ></p-calendar>
        </div>


    </div>

    <!------------ ASSOCIATIONS PIÈCES MÉCANIQUES ------------>

    <div *ngIf="estTypeBonCommande()
        && selectionTypeSaisie.cle == 'I' 
        && selectionItem
        && selectionItem.categorie
        && selectionItem.categorie.categorieId == 6
        && selectionCodeEquipement
        && estAutorise('piece_associer')" class="fluid formgrid grid mt-2">
        
        <div class="flex flex-column gap-2 my-2 col-12 md:col-3 p-xl-2 mb-3">
            <label for="lastname">Associer la pièce à l'unité</label>
            <p-checkbox [binary]="true" [(ngModel)]="indAssocier" ></p-checkbox>
        </div>
        <div class="flex flex-column gap-2 my-2 col-12 md:col-3">
            <label for="lastname">Code de catégorie</label>
            <p-dropdown [options]="listeCategoriesEntretien"
                [(ngModel)]="selectionCategorieEntretien" [showClear]="true"
                optionLabel="description" [showClear]="true" filter="true"
                filterBy="code,description" appendTo="body" (onChange)="filtrerPieces()"
                placeholder="Sélectionner une catégorie...">
                <ng-template pTemplate="selectedItem">
                    <div *ngIf="selectionCategorieEntretien">
                        <div>{{selectionCategorieEntretien.code}} -
                            {{selectionCategorieEntretien.description}}
                        </div>
                    </div>
                </ng-template>
                <ng-template let-categorie pTemplate="item">
                    <div>
                        <div>{{categorie.code}} - {{categorie.description}}</div>
                    </div>
                </ng-template>
            </p-dropdown>


        </div>


    </div>

    <!------------ BOUTONS ------------>

    <div class="text-right mt-3" *ngIf="!typeSaisieInventaire() || itemSelectionne">

        <button pButton pRipple label="Ajouter" icon="pi pi-check" class="p-button-primary mr-2"
            (click)="selectionner(false)"></button>
        <button pButton pRipple label="Annuler" icon="pi pi-times" class="p-button-outlined mr-2"
            (click)="annuler()"></button>

        <!-- <button pButton pRipple label="Ajouter et fermer" icon="pi pi-check" class="p-button-outlined"
            (click)="selectionner(true)"></button> -->
    </div>

</div>


<p-sidebar [(visible)]="afficherDetailItem" [style]="{width:'95%'}" position="left">
    <app-detail-item [itemId]="detailItemId" *ngIf="afficherDetailItem"></app-detail-item>
</p-sidebar>