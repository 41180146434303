<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<p-card *ngIf="!EstCharge()">
	<div class="fluid formgrid grid">
		<div class="flex flex-column gap-2 col-12 text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-card>

<div *ngIf="EstCharge()" class="grid">
	<div class="col-12">
		<div class="card">
			<h5 class="mb-0">Soumission de bon de travail
				<span class="statut nouveau ml-2">
					Nouveau
				</span>
			</h5>
		</div>
	</div>
</div>


<div *ngIf="EstCharge()" class="grid">
	<div class="col-12">
		<div class="card">
			<div class="fluid formgrid grid">
				<div class="col-12 md:col-6 lg:col-3 mb-3">
					<p-selectButton [options]="typesBonTravail" [(ngModel)]="selectionTypeBonTravail"
						(onChange)="surChangementTypeBonTravail()" optionLabel="valeur">
					</p-selectButton>
				</div>

			</div>

			<div class="fluid formgrid grid">
				<div class="flex flex-column gap-2 col-12 mb-3">
					<label for="numeroUnite">Unité</label>
					<div class="flex gap-2 col-6">
						<span class="champConsultation">{{bonTravail.item.numero | valeurVide}}</span>
						<span class="champConsultation ml-3">{{bonTravail.item.description | valeurVide}}
							<i *ngIf="changementUnitePermis" class="pi pi-pencil ml-3" (click)="rechercherUnite()"></i>
						</span>
					</div>

				</div>
			</div>

			<div class="fluid formgrid grid">
				<div class="flex flex-column gap-2 col-12 md:col-6 lg:col-3 mb-3">
					<label for="lastname">Date<span class="requis">*</span></label>
					<p-calendar appendTo="body" showIcon=true [(ngModel)]="bonTravail.dateTravail" dateFormat="yy-mm-dd" [yearNavigator]="true" [monthNavigator]="true" yearRange="2000:2040" ></p-calendar>
					<small class="p-error" *ngIf="submitted && !bonTravail.dateTravail">La date est obligatoire.</small>
				</div>
			</div>

			<div class="fluid formgrid grid mb-3">
				<div class="flex flex-column gap-2 col-12 md:col-3">
					<label for="lastname">Raison<span class="requis">*</span></label>
					<p-dropdown [options]="raisons" [(ngModel)]="bonTravail.raison"
						optionLabel="description" [showClear]="!modeConsultation()" filter="true"
						filterBy="code,description" placeholder="Sélectionner une raison...">
						<ng-template pTemplate="selectedItem">
							<div *ngIf="bonTravail.raison">
								<div>{{bonTravail.raison.description}} ({{bonTravail.raison.code}})</div>
							</div>
						</ng-template>
						<ng-template let-raison pTemplate="item">
							<div>
								<div>{{raison.description}} ({{raison.code}})</div>
							</div>
						</ng-template>
					</p-dropdown>
					<small class="p-error" *ngIf="submitted && !bonTravail.raison">La raison est obligatoire.</small>
				</div>
	

				<div class="flex flex-column gap-2 col-12 md:col-3">
					<label for="contreMaitre">Contre-Maître<span class="requis">*</span></label>
					<p-dropdown [options]="listeEmployes" [(ngModel)]="bonTravail.contreMaitre" optionLabel="nom"
						[showClear]="true" filter="true" filterBy="prenom,nom" appendTo="body"
						placeholder="Sélectionner un contre-maître...">
						<ng-template pTemplate="selectedItem">
							<div *ngIf="bonTravail.contreMaitre">
								<div>{{bonTravail.contreMaitre.prenom}} {{bonTravail.contreMaitre.nom}}</div>
							</div>
						</ng-template>
						<ng-template let-employe pTemplate="item">
							<div>
								<div>{{employe.prenom}} {{employe.nom}}</div>
							</div>
						</ng-template>
					</p-dropdown>
					<small class="p-error" *ngIf="submitted && !bonTravail.contreMaitre">Le contre-maitre est obligatoire.</small>
				</div>

				<div class="flex flex-column gap-2 col-12 md:col-3" *ngIf="selectionTypeBonTravail.cle == 1">
					<label for="mecanicien">Mécanicien</label>
					<p-dropdown [options]="listeEmployes" [(ngModel)]="bonTravail.mecanicien" optionLabel="nom"
						[showClear]="true" filter="true" filterBy="prenom,nom" appendTo="body"
						placeholder="Sélectionner un mécanicien...">
						<ng-template pTemplate="selectedItem">
							<div *ngIf="bonTravail.mecanicien">
								<div>{{bonTravail.mecanicien.prenom}} {{bonTravail.mecanicien.nom}}</div>
							</div>
						</ng-template>
						<ng-template let-employe pTemplate="item">
							<div>
								<div>{{employe.prenom}} {{employe.nom}}</div>
							</div>
						</ng-template>
					</p-dropdown>
				</div>

				<div class="flex flex-column gap-2 col-12 md:col-3" *ngIf="selectionTypeBonTravail.cle == 2">
					<label for="lastname">Fournisseur</label>
					<p-dropdown [options]="fournisseurs" [(ngModel)]="bonTravail.fournisseur" optionLabel="nom"
						[showClear]="!modeConsultation()" filter="true" filterBy="numero,nom"
						placeholder="Sélectionner un fournisseur...">
						<ng-template pTemplate="selectedItem">
							<div *ngIf="bonTravail.fournisseur">
								<div>{{bonTravail.fournisseur.nom}} ({{bonTravail.fournisseur.numero}})</div>
							</div>
						</ng-template>
						<ng-template let-fournisseur pTemplate="item">
							<div>
								<div>{{fournisseur.nom}} ({{fournisseur.numero}})</div>
							</div>
						</ng-template>
					</p-dropdown>
				</div>


				<div class="flex flex-column gap-2 col-12 md:col-3">
					<label for="contreMaitre">Emplacement</label>
					<p-dropdown [options]="emplacements" [(ngModel)]="bonTravail.emplacement" optionLabel="nom"
						[showClear]="true" filter="true" filterBy="nom" appendTo="body"
						placeholder="Sélectionner un emplacement...">
						<ng-template pTemplate="selectedItem">
							<div *ngIf="bonTravail.emplacement">
								<div>{{bonTravail.emplacement.nom}}</div>
							</div>
						</ng-template>
						<ng-template let-emplacement pTemplate="item">
							<div>
								<div>{{emplacement.nom}}</div>
							</div>
						</ng-template>
					</p-dropdown>
				</div>


			</div>
			<div class="fluid formgrid grid">
				<div class="flex flex-column gap-2 col-12 mb-3">
					<label for="note">Note</label>
					<input #champNote type="text" pInputText id="note"
						[(ngModel)]="bonTravail.note" autocomplete="off" />
				</div>
			</div>
			<div class="col-12 flex justify-content-end">
				<p-button label="Suivant" (click)="CreerBonTravail()" icon="pi pi-save" iconPos="left"></p-button>
			</div>
		</div>
	</div>


</div>