<p-toast key="selectionTransporteur"></p-toast>

<p-card *ngIf="!EstCharge()">
    <div class="fluid formgrid grid">
        <div class="flex flex-column gap-2 my-2 col-12 text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-card>

<div #contenu *ngIf="EstCharge()">

    <div class="grid">
        <div class="col-12">
            <div class="card">
    
                        <p-table #dt [value]="transporteurs" dataKey="nom" tableStyleClass="tableNormale" autoLayout="true"
                        [(selection)]="selection" (onRowSelect)="surChangementSelection()" (onRowUnselect)="surChangementSelection()"
                        (onHeaderCheckboxToggle)="surChangementSelection()"
                            autoLayout="true" styleClass="p-datatable-sm p-datatable-striped"
                            [rowHover]="true" >
                            <ng-template pTemplate="caption">
                                <div class="flex align-content-center justify-content-between">
                                    <h5 class="titreTableau m-0">Transporteurs</h5>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="min-width: 3rem">
                                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                    </th>
                                    <th>Camion</th>
                                    <th>Remorque</th>
                                    <th>Chauffeur</th>
									<th>NbItems</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-transporteur>
                            
                                <tr>
                                    <td>
                                        <p-tableCheckbox [value]="transporteur"></p-tableCheckbox>
                                    </td>
									<td>{{transporteur.camion}}</td>
									<td>{{transporteur.remorque}}</td>
									<td>{{transporteur.chauffeur}}</td>
									<td>{{transporteur.nbItems}}</td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="5">Aucun transporteur.</td>
                                </tr>
                            </ng-template>
                        </p-table>
    
            </div>
        </div>
    
    
    </div>

    <div class="text-right mt-3">

        <button [disable]="transporteurs.length == 0 || !boutonConfirmerDisponible" #boutonConfirmer pButton pRipple label="Confirmer" icon="pi pi-save" class="p-button-primary"
            (click)="confirmer()"></button>
        <button #boutonAnnuler pButton pRipple label="Annuler" icon="pi pi-ban" class="p-button-text" (click)="annuler()"></button>

    </div>

</div>