import { Component, OnInit, ViewChild, Input, ElementRef } from "@angular/core"; import { Location } from "@angular/common";
import { Table } from 'primeng/table';
import { BreadcrumbService } from '../../app.breadcrumb.service';

import { RequisitionMin } from '../../entites/requisition-min';
import { MenuItem, TreeNode } from 'primeng/api';
import { MessageService, LazyLoadEvent, ConfirmationService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { EnvoiRequisitionComponent } from '../../controles/envoi-requisition.component';
import { ItemMin } from '../../entites/item-min';
import { ItemService } from '../../services/item-service';
import { Router, ActivatedRoute } from '@angular/router';

import { ToolbarModule } from 'primeng/toolbar';

import { MenubarModule } from 'primeng/menubar';
import { BaseComponent } from '../../base/base-component';
import { Item } from '../../entites/item';
import { Inventaire } from '../../entites/inventaire';
import { NoteEntite } from '../../entites/note';
import { Requisition } from '../../entites/requisition';
import { RequisitionService } from '../../services/requisition-service';
import { Emplacement } from '../../entites/emplacement';
import { EmplacementService } from '../../services/emplacement-service';
import { AchatService } from '../../services/achat-service';
import { CleValeur } from 'src/app/entites/cleValeur';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { ProfilService } from 'src/app/services/profil-service';
import { SelectionItemComponent, ETypeSelectionItem, ResultatSelectionItem } from 'src/app/controles/selection-item.component';
import { ContextMenu } from 'primeng/contextmenu/public_api';
import { EStatuts } from 'src/app/entites/enums/statuts';
import { ModeleListeAchatEntite } from 'src/app/entites/modele-liste-achat';
import { ItemModeleListeAchatEntite } from 'src/app/entites/item-modele-liste-achat';
import { ModeleListeAchatService } from 'src/app/services/modele-liste-achat-service';
import { AppMainComponent } from 'src/app/app.main.component';

@Component({
    templateUrl: './detail-modele-liste-achat.component.html',
    styleUrls: ['./detail-modele-liste-achat.component.scss'],
    providers: [MessageService, ConfirmationService, DialogService],
    host: {
        '(document:keydown)': 'handleKeyboardEvents($event)'
    }
})
export class DetailModeleListeAchatComponent extends BaseComponent implements OnInit {

    modeleListeAchatId: number;
    modeleListeAchat: ModeleListeAchatEntite;
    selectionEntree: ItemModeleListeAchatEntite;
    selectionMultipleEntree: ItemModeleListeAchatEntite[] = [];


    menuContextuelEntrees: MenuItem[];

    ref: DynamicDialogRef;

    menu: MenuItem[];

    @ViewChild('descriptionItem') champDescription: ElementRef;
    @ViewChild('menuContextuelItemCommande') menuContextuelItemCommande: ContextMenu;

    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute, 
		appMain: AppMainComponent, location: Location, 
        private breadcrumbService: BreadcrumbService,
        private modeleListeAchatService: ModeleListeAchatService,
        private EmplacementService: EmplacementService,
        private ItemsService: ItemService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        public dialogService: DialogService,
        public routerService: Router) {

        super(sdb, profilService, route, routerService, appMain);

        this.breadcrumbService.setItems([
            { label: 'Bons de commande', routerLink: '/achats' },
            { label: 'Détails' }
        ]);

        this.route.params.subscribe(params => this.modeleListeAchatId = params.id);
    }

    handleKeyboardEvents(event: KeyboardEvent) {
        // if (event.key == '+' && !this.estFenetreAjoutItemActive) {
        //     this.surAjoutItem();
        // }
    }

    ngOnInit() {

        

        this.modeleListeAchatService.ObtenirModele(this.modeleListeAchatId).then(x => {

            console.log(x.valeur);

            this.modeleListeAchat = x.valeur;

            this.initialiserMenu();

            this.CompleterChargement()
        });

    }

    initialiserMenu() {
        this.menu = [
            {
                label: 'Retour', icon: 'pi pi-fw pi-angle-double-left', command: () => this.routerService.navigateByUrl('pilotage/modelesListeAchat')
            },
            {
                label: 'Imprimer', icon: 'pi pi-fw pi-print', command: () => this.imprimer()
            },
            /* {
                label: 'Envoyer', icon: 'pi pi-fw pi-send', disabled: this.modeleListeAchat.statut.statutId != EStatuts.Complete, command: () => this.Envoyer()
            }, */
            {
                label: 'Actions', icon: 'pi pi-fw pi-bars',
                items: [
                    {
                        label: 'Supprimer le modèle', icon: 'pi pi-fw pi-times', command: () => this.supprimermodeleListeAchat()
                    }
                ]
            },
            {
                label: 'Actions multiples', icon: 'pi pi-fw pi-bars', disabled: this.selectionMultipleEntree.length == 0,
                items: [
                    {
                        label: 'Supprimer les items sélectionnés', icon: 'pi pi-fw pi-times', command: () => this.supprimerItems(this.selectionMultipleEntree)
                    },
                ]
            },
        ];
    }

    surMenuContextuelItem(event, itemModeleListeAchat: ItemModeleListeAchatEntite) {
        this.selectionEntree = itemModeleListeAchat;

        this.menuContextuelEntrees = [
            { label: 'Voir l\'item', icon: 'pi pi-fw pi-eye', command: () => this.routerService.navigateByUrl(`item/${itemModeleListeAchat.item.itemId}`) },
            { separator: true },
            { label: 'Supprimer', icon: 'pi pi-fw pi-times', command: () => this.supprimerItems([itemModeleListeAchat]) }
        ];


        this.menuContextuelItemCommande.toggle(event);
    }

    imprimer() {
        this.messageService.add({ severity: 'info', summary: 'Demande de rapport', detail: `Votre demande de rapport a été envoyée.` });

        /* var parametres = {
            parametresCommuns: {
                demandeur: `${this.profilService.ProfilCourant().prenom} ${this.profilService.ProfilCourant().nom}`,
                detail: `${this.emplacement.nom} (${this.emplacement.code})`
            },
            emplacement: `${this.emplacement.nom} (${this.emplacement.code})`,
            itemsCommande: []
        };

        this.itemsCommande.forEach(ic => {
            parametres.itemsCommande.push({
                date: this.datepipe.transform(ic.dateDemande, 'yyyy-MM-dd'),
                qteDemande: ic.quantiteDemande,
                qte: `${ic.quantite}/${ic.quantiteDemande}`,
                statut: ic.statut?.nom,
                categorie: ic.item?.categorie?.nom,
                numero: ic.item?.numero,
                description: ic.item?.description,
                qteInventaire: ic.item?.quantite,
                demandeur: ic.demandeur ? `${ic.demandeur.prenom} ${ic.demandeur.nom}` : '',
                expediteur: ic.expediteur
            })
        });

        this.rapportService.ProduireRapportCommande(parametres).then(x => {
            if (x) {
                this.messageService.add({ severity: 'success', summary: 'Succès', detail: `Votre rapport a été généré.` });
            } else {
                this.messageService.add({ severity: 'error', summary: 'Erreur', detail: `Une erreur est survenue pendant la génération de votre rapport.` });
            }
        }); */
    }

    supprimermodeleListeAchat() {
        this.confirmationService.confirm({
            target: event.target,
            message: 'Voulez-vous vraiment supprimer le modèle courant ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.modeleListeAchatService.SupprimerModele(this.modeleListeAchat.modeleListeAchatId).then(res => {
                    if (res.estUnSucces) {
                        this.routerService.navigateByUrl('pilotage/modelesListeAchat');
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Modèle supprimé.' });
                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                });

            }
        });
    }


    supprimerItems(itemsModele: ItemModeleListeAchatEntite[]) {
        var ids = itemsModele.map(b => b.itemModeleListeAchatId);
        this.confirmationService.confirm({
            target: event.target,
            message: itemsModele.length == 1 ? 'Voulez-vous vraiment supprimer l\'item sélectionné ?' : 'Voulez-vous vraiment supprimer les items sélectionnés ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.modeleListeAchatService.SupprimerItemsModele(ids).then(res => {
                    if (res.estUnSucces) {
                        this.modeleListeAchat.itemsModeleListeAchat = this.modeleListeAchat.itemsModeleListeAchat.filter((ibc: ItemModeleListeAchatEntite) => !ids.includes(ibc.itemModeleListeAchatId))
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: itemsModele.length == 1 ? 'Modèle supprimé.' : 'Modèles supprimés' });
                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                });

            }
        });
    }


    estFenetreAjoutItemActive: boolean = false;
    surAjoutItem() {
        this.estFenetreAjoutItemActive = true;
        const ref = this.dialogService.open(SelectionItemComponent, {
            data: {
                type: ETypeSelectionItem.ListeAchat
            },
            header: 'Sélection d\'un item',
            width: '95%'
        }).onClose.subscribe((res: ResultatSelectionItem) => {
            this.estFenetreAjoutItemActive = false;
            if (res) {
                this.modeleListeAchatService.CreerItemModele({
                    itemModeleListeAchatId: 0,
                    modeleListeAchatId: this.modeleListeAchat.modeleListeAchatId,
                    item: res.item,
                }).then(res => {
                    if (res.estUnSucces) {
                        this.modeleListeAchat.itemsModeleListeAchat.push(res.valeur);
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: `L'item '${res.valeur.item.description} (${res.valeur.item.numero})' a été ajouté.` });

                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                })
            }


        });

    }

    surChangementSelection() {
        this.initialiserMenu();
    }

   
    /* Envoyer() {
        this.confirmationService.confirm({
            message: `Voulez-vous vraiment envoyé le bon de commmande #${this.modeleListeAchat.numero} à tbouchard@macpek.com ?`,
            accept: () => {
                this.messageService.add({ severity: 'info', summary: 'Bon de commande envoyé', detail: 'Envoyé à: tbouchard@macpek.com' });
            }
        });
    } */


}
