import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from "@angular/core"; import { Location } from "@angular/common";

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { ReponseBase } from './reponse-base';
import { RequeteAjouterHeures } from './requetes/requete-ajouter-heures';
import { MajService } from './maj-service';
import { AjustementHeureEntite } from '../entites/ajustement-heure';
import { ReleveHeureEntite } from '../entites/releve-heures';
import { LigneHeureKmEntite } from '../entites/mecanique/ligne-heure-km';
import { RequeteAjouterHeuresKms } from './requetes/requete-ajouter-heures-kms';

@Injectable({ providedIn: 'root' })
export class HeureKmService {

  private obtenirListeUrl = environment.apiBaseUrl + 'heurekm';
  private ajouterHeuresUrl = environment.apiBaseUrl + 'heurekm';
  private approuverHeuresUrl = environment.apiBaseUrl + 'heurekm/approuver';
  private refuserHeuresUrl = environment.apiBaseUrl + 'heurekm/refuser';


  public httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(
    private majService: MajService,
    private http: HttpClient,
    public router: Router) { }

  public ObtenirListe(): Promise<ReponseBase<LigneHeureKmEntite[]>> {
    var p = this.http.get<ReponseBase<LigneHeureKmEntite[]>>(this.obtenirListeUrl)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }


  public Ajouter(requete: RequeteAjouterHeuresKms): Promise<ReponseBase<LigneHeureKmEntite[]>> {
    var p = this.http.post<ReponseBase<LigneHeureKmEntite[]>>(this.ajouterHeuresUrl, JSON.stringify(requete), this.httpOptions)
      .toPromise();

      p.then(() => {
        if (requete.ignorerApprobation) {
          this.majService.Traiter()
        }
        
      });

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public Approuver(requete: RequeteAjouterHeuresKms): Promise<ReponseBase<LigneHeureKmEntite[]>> {
    var p = this.http.post<ReponseBase<LigneHeureKmEntite[]>>(this.approuverHeuresUrl, JSON.stringify(requete), this.httpOptions)
      .toPromise();

      p.then(() => this.majService.Traiter());

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public Refuser(requete: RequeteAjouterHeuresKms): Promise<ReponseBase<LigneHeureKmEntite[]>> {
    var p = this.http.post<ReponseBase<LigneHeureKmEntite[]>>(this.refuserHeuresUrl, JSON.stringify(requete), this.httpOptions)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }


  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);

      this.log(`${operation} failed: ${error.message}`);

      this.router.navigateByUrl('erreurtechnique');

      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(message);
  }
}
