<p-toast key="assignerEntretien"></p-toast>

<p-blockUI [blocked]="blocked">
	<div class="fluid formgrid grid">
		<div class="flex flex-column gap-2 col-12 text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-blockUI>


<p-card *ngIf="!EstCharge()">
	<div class="fluid formgrid grid">
		<div class="flex flex-column gap-2 col-12 text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-card>

<div #contenu *ngIf="EstCharge()">

	
	<div class="fluid formgrid grid">
		
		<div class="flex flex-column gap-2 col-12 md:col-6 lg:col-3">
			<label for="date">Date de fin</label>
			<p-calendar appendTo="body" showIcon=true [(ngModel)]="dateFin"
								dateFormat="yy-mm-dd" [yearNavigator]="true" [monthNavigator]="true"
								yearRange="2000:2040"></p-calendar>
		</div>
		<div class="flex flex-column gap-2 col-12 md:col-6 lg:col-3">
			<label for="date">Heures au compteur</label>
			<input #inputHeures type="number" pInputText id="heures"
								[(ngModel)]="nbHeures" autocomplete="off" />
		</div>
		<div class="flex flex-column gap-2 col-12 md:col-6 lg:col-3">
			<label for="date">KMs au compteur</label>
			<input #inputHeures type="number" pInputText id="heures"
								[(ngModel)]="nbKm" autocomplete="off" />
		</div>
		
	</div>



	<div class="text-right mt-3">

		<button pButton pRipple label="Envoyer" icon="pi pi-save" class="p-button-primary"
			(click)="approuver()"></button>
		<button pButton pRipple label="Annuler" icon="pi pi-ban" class="p-button-text" (click)="annuler()"></button>

	</div>

</div>