import { Component, OnInit } from "@angular/core"; import { Location } from "@angular/common";
import { ItemService } from 'src/app/services/item-service';

@Component({
  selector: 'app-accessdenied',
  templateUrl: './app.accessdenied.component.html',
})
export class AppAccessdeniedComponent implements OnInit {

  constructor(private itemService: ItemService) {}

  ngOnInit() {
    this.itemService.Desactiver();
  }
  
}
