<p-card *ngIf="!EstCharge()">
	<div class="fluid formgrid grid">
		<div class="flex flex-column gap-2 my-2 col-12 text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-card>
<div class="card" *ngIf="EstCharge()">
<div class="fluid formgrid grid">
    <div class="flex flex-column gap-2 my-2 col-12">
		<p-table #dtPieces [value]="entretien.pieces" [columns]="selectedColumns"
		dataKey="itemId" autoLayout="true" tableStyleClass="tableNormale"
		styleClass="p-datatable-sm p-datatable-striped" [rowHover]="true" [rows]="50" [paginator]="true"
		[filterDelay]="0" [globalFilterFields]="['numero','description','marque','modele']">
		<ng-template pTemplate="caption">
			<div class="flex align-content-center justify-content-between">
				<div>
					<span class="p-input-icon-left">
						<i class="pi pi-search"></i>
						<input #rechercheItem pInputText type="text"
							(input)="dtPieces.filterGlobal($event.target.value, 'contains')"
							 placeholder="Recherche..." />
					</span>
					<button *ngIf="estAutorise('temp_mec') && !modeConsultation()" pButton pRipple icon="pi pi-plus"
						class="p-button-success mr-2 ml-4 mb-2 boutonGrille"
						(click)="ajouterPiece()"></button>
				</div>
				<div>
					<h5 class="titreTableau m-0">Pièces</h5>
				</div>

				<div>
					<p-multiSelect disabled="true" [style]="{minWidth: '200px'}"
						placeholder="Colonnes..."></p-multiSelect>
				</div>
			</div>
		</ng-template>
		<ng-template pTemplate="header" let-columns>
			<tr>
				<th pSortableColumn="item.numero">
					<div class="flex justify-content-between align-items-center">
						Numéro
						<p-sortIcon field="item.numero"></p-sortIcon>
						<p-columnFilter type="text" field="item.numero" display="menu" class="ml-auto">
						</p-columnFilter>
					</div>
				</th>
				<th pSortableColumn="item.description">
					<div class="flex justify-content-between align-items-center">
						Description
						<p-sortIcon field="item.description"></p-sortIcon>
						<p-columnFilter type="text" field="item.description" display="menu" class="ml-auto">
						</p-columnFilter>
					</div>
				</th>
				<th pSortableColumn="quantite">
					<div class="flex justify-content-between align-items-center">
						Quantité
						<p-sortIcon field="quantite"></p-sortIcon>
						<p-columnFilter type="numeric" field="quantite" display="menu" class="ml-auto">
						</p-columnFilter>
					</div>
				</th>
				<th pSortableColumn="modele">
					<div class="flex justify-content-between align-items-center">
						Marque
						<p-sortIcon field="modele"></p-sortIcon>
						<p-columnFilter type="text" field="modele" display="menu" class="ml-auto">
						</p-columnFilter>
					</div>
				</th>
				<th pSortableColumn="modele">
					<div class="flex justify-content-between align-items-center">
						Modèle
						<p-sortIcon field="modele"></p-sortIcon>
						<p-columnFilter type="text" field="description" display="menu" class="ml-auto">
						</p-columnFilter>
					</div>
				</th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body" let-piece let-columns="columns">
			<tr >
				<td>{{piece.item.numero}}</td>
				<td>{{piece.item.description}}</td>
				<td>{{piece.quantite}}</td>
				<td>{{piece.item.marque}}</td>
				<td>{{piece.item.modele}}</td>
				

			</tr>
		</ng-template>
		<ng-template pTemplate="emptymessage">
			<tr>
				<td colspan="6">Aucune pièce.</td>
			</tr>
		</ng-template>
	</p-table>
    </div>
  
</div>
<div class="grid">
    <div class="col-12 text-right ">
        <button pButton pRipple label="Fermer" icon="pi pi-ban" class="p-button-text" (click)="annuler()"></button>
    </div>
</div>
</div>