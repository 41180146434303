import { Pipe, PipeTransform } from "@angular/core"; import { Location } from "@angular/common";
import { EmployeEntite } from '../entites/employe';

@Pipe({
  name: 'nomEmploye'
})
export class NomEmployePipe implements PipeTransform {

  transform(value: EmployeEntite): string {
    if (value != null && value != undefined) {
      return value.prenom + ' ' + value.nom
    } else {
        return '';
    }
  }

}
