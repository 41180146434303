import {Component, OnInit, ViewChild} from "@angular/core"; import { Location } from "@angular/common";
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import { DonneesBaseService } from '../services/donnees-base-service';

import { ItemCommandeEntite } from '../entites/item-commande';
import {EmployeEntite} from "../entites/employe";
import {CodeProjetEntite} from "../entites/code-projet";
import {CodeActiviteEntite} from "../entites/code-activite";
import {Emplacement} from "../entites/emplacement";
import {CodeCategorieEntite} from "../entites/code-categorie";
import {CodeEquipementEntite} from "../entites/code-equipement";
import {CodeExtraEntite} from "../entites/code-extra";
import {DocumentEntite} from "../entites/document";
import {EStatuts} from "../entites/enums/statuts";
import {RequeteAjouterDocuments} from "../services/requetes/requete-ajouter-documents";
import {ETypeLienDocument} from "../entites/enums/type-lien-document";
import { ProfilService } from 'src/app/services/profil-service';
import {DocumentService} from "../services/document-service";
import {ConfirmationService, MessageService} from "primeng/api";
import {FileUpload} from "primeng/fileupload/fileupload";
import {environment} from "../../environments/environment";

@Component({
	templateUrl: './modifier-pre-commande.component.html',
})
export class ModifierPreCommandeComponent implements OnInit {

	item: ItemCommandeEntite;
	submitted: boolean = false;

	listeEmployes: EmployeEntite[];
	listeCodeProjet: CodeProjetEntite[];
	listeCodeActivite: CodeActiviteEntite[];
	listeEmplacementAjoutCommande: Emplacement[];
	listeCodeCategorie: CodeCategorieEntite[];
	listeCodeEquipement: CodeEquipementEntite[];
	listeCodeExtra: CodeExtraEntite[];
	listeEmplacement: Emplacement[];


	selectionEmplacement: Emplacement;
	selectionDemandeur: EmployeEntite;
	selectionCodeProjet: CodeProjetEntite;
	selectionCodeActivite: CodeActiviteEntite;
	selectionEmplacementAjoutCommande: Emplacement;
	selectionCodeCategorie: CodeCategorieEntite;
	selectionCodeEquipement: CodeEquipementEntite;
	selectionCodeExtra: CodeExtraEntite;

	codeEquipementBloque: boolean = false;
	codeCategorieBloque: boolean = false;


	dateRequis?: Date;
	
	documents: DocumentEntite[] = [];

	estCodeActiviteObligatoire: boolean = true;
	estCodeCategorieObligatoire: boolean = true;

	@ViewChild('champExpediteur') inputExpediteur;
	@ViewChild('champCodeProjet') champCodeProjet;
	@ViewChild('champCodeActivite') champCodeActivite;
	@ViewChild('champCodeCategorie') champCodeCategorie;
	@ViewChild('champNote') champNote;
	@ViewChild('fu') fileUploadControl: FileUpload;

	constructor(
		public sdb: DonneesBaseService,
		public ref: DynamicDialogRef,
		private profilService: ProfilService,
		private documentService: DocumentService,
		private messageService: MessageService,
		private confirmationService: ConfirmationService,
		public config: DynamicDialogConfig) { }

	ngOnInit() {
		this.initBaseData();
		this.setInitialValues();
		this.fetchFiles().then();
	}
	
	async fetchFiles() {
		const data = await this.documentService.ObtenirListeItemCommande({id: this.item.itemCommandeId});
		this.documents = data.valeur.documents;
	}

	setInitialValues() {
		this.listeEmplacementAjoutCommande = this.sdb.Emplacements().filter(x => x.commandeActive);
		this.selectionDemandeur = this.listeEmployes.find(x => x.employeId == this.item.demandeur.employeId);
		this.dateRequis = new Date(this.item.dateRequis);
		this.selectionCodeProjet = this.listeCodeProjet.find(x => x.codeProjetId == this.item.codeProjet.codeProjetId);
		if(this.selectionCodeProjet)
			this.listeCodeActivite = this.selectionCodeProjet.codesActivite;
		this.selectionCodeActivite = this.listeCodeActivite.find(x => x.codeActiviteId == this.item.codeActivite.codeActiviteId);
		this.selectionCodeCategorie = this.listeCodeCategorie.find(x => x.codeCategorieId == this.item.codeCategorie.codeCategorieId);
		if (this.item.emplacement)
			this.selectionEmplacement = this.listeEmplacement.find(x => x.emplacementId == this.item.emplacement.emplacementId);
		if (this.item.codeEquipement)
			this.selectionCodeEquipement = this.listeCodeEquipement.find(x => x.codeEquipementId == this.item.codeEquipement.codeEquipementId);
		if (this.item.codeExtra)
			this.selectionCodeExtra = this.listeCodeExtra.find(x => x.codeExtraId == this.item.codeExtra.codeExtraId);
		if (this.item.codeCategorie)
			this.selectionCodeCategorie = this.listeCodeCategorie.find(x => x.codeCategorieId == this.item.codeCategorie.codeCategorieId);
	}

	setDataBeforeSave() {
		this.item.demandeur = this.selectionDemandeur;
		this.item.dateRequis = this.dateRequis;
		this.item.codeProjet = this.selectionCodeProjet;
		this.item.codeActivite = this.selectionCodeActivite;
		this.item.codeCategorie = this.selectionCodeCategorie;
		this.item.emplacement = this.selectionEmplacement;
		this.item.codeEquipement = this.selectionCodeEquipement;
		this.item.codeExtra = this.selectionCodeExtra;
		this.item.codeCategorie = this.selectionCodeCategorie;
	}

	initBaseData() {
		this.item = this.config.data.item;
		this.submitted = false;
		this.listeEmployes = this.sdb.Employes();
		this.listeCodeProjet = this.sdb.CodesProjet();
		this.listeCodeCategorie = this.sdb.CodesCategorie();
		this.listeCodeEquipement = this.sdb.CodesEquipement();
		this.listeCodeExtra = this.sdb.CodesExtra();
		this.listeEmplacement = this.sdb.Emplacements();
	}

	surEntree() {
		this.Confirmer();
	}

	Confirmer() {
		this.submitted = true;
		this.setDataBeforeSave();
		this.ref.close(this.item);
	}

	Annuler() {
		this.ref.close(null);
	}

	surFermetureDemandeur() {

		if (this.inputExpediteur != undefined) {
			this.inputExpediteur.nativeElement.focus();
		}
	}

	surFermetureCodeProjet() {
		this.champCodeActivite.applyFocus();
	}

	surFermetureCodeActivite() {
		this.champCodeCategorie.applyFocus();
	}

	surFermetureCodeCategorie() {
		this.champNote.nativeElement.focus();
	}

	surChangementCodeProjet() {
		this.selectionCodeActivite = null;
		this.listeCodeActivite = this.selectionCodeProjet.codesActivite;

		if (this.selectionCodeProjet) {
			this.selectionEmplacementAjoutCommande = this.listeEmplacementAjoutCommande.find(x => x.codeProjet?.codeProjetId == this.selectionCodeProjet.codeProjetId);
		} else {
			this.selectionEmplacementAjoutCommande = null;
		}

		this.autoFillCategoriesCost();
	}

	surChangementCodeActivite() {
		if (this.selectionCodeActivite &&
			(this.selectionCodeActivite.code == 'CG08' || this.selectionCodeActivite.code == 'M5' || this.selectionCodeActivite.code == 'M6' || this.selectionCodeActivite.code == 'M8' || this.selectionCodeActivite.code == 'M9')) {
			this.selectionCodeEquipement = null;
			this.codeEquipementBloque = true;
		} else {
			this.codeEquipementBloque = false;
		}

		this.autoFillCategoriesCost();
	}

	surChangementCodeEquipement() {

	}

	autoFillCategoriesCost() {
		if (this.selectionCodeProjet && this.selectionCodeProjet.code == 'ENTRET' &&
			this.selectionCodeActivite && this.selectionCodeActivite.code == '280') {
			this.selectionCodeCategorie = this.listeCodeCategorie.find(x => x.code == '2');
		}
	}

	onBasicUpload(event) {}

	async onUpload(event) {
		this.submitted = true;

		if (await this.documentService.AjouterDocuments(ETypeLienDocument.ItemCommande, this.item.itemCommandeId, new Date(), this.fileUploadControl.files, null)) {
            this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Les documents ont été ajoutés.' });
            this.fileUploadControl.clear();
		 	this.submitted = false;
			 this.fetchFiles().then();
        } else {
            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: 'Une erreur est survenue lors de l\'ajout des documents.' });
        }
	}

	telechargerDocument(doc: DocumentEntite) {
		window.open(`${environment.apiDocumentUrl}tic/${doc.cheminFichier}/${doc.nomFichier}`, "_blank");
	}

	supprimerDocument(doc: DocumentEntite) {
		this.confirmationService.confirm({
			target: event.target,
			message: 'Voulez-vous vraiment supprimer le document sélectionné ?',
			header: 'Confirmation',
			icon: 'pi pi-exclamation-triangle',
			accept: () => {
				this.documentService.SupprimerDocument(doc.documentId).then(res => {
					if (res.estUnSucces) {
						this.documents = this.documents.filter(val => val.documentId != doc.documentId);
						this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Document supprimé.' });
					} else {
						res.messages.forEach(x => {
							this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
						});
					}
				});

			}
		});
	}
	
	onSelect(event) {}
}