<p-toast key="detailEntretien"></p-toast>

<p-blockUI [blocked]="blocked">
    <div class="fluid formgrid grid">
        <div class="flex flex-column gap-2 col-12 text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-blockUI>


<p-card *ngIf="!EstCharge()">
    <div class="fluid formgrid grid">
        <div class="flex flex-column gap-2 col-12 text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-card>

<div #contenu *ngIf="EstCharge()">

    <div class="fluid formgrid grid">
        <div class="flex flex-column gap-2 col-12">
            <label for="description">Description</label>
            <input #champDescription id="description" type="text" pInputText [(ngModel)]="entretien.description">
            <small class="p-error" *ngIf="submitted && !entretien.description">La description est obligatoire.</small>
        </div>
    </div>

    <div class="fluid formgrid grid">
        <div class="flex flex-column gap-2 col-12 p-md-3">
            <label for="lastname">Sévérité</label>
            <p-dropdown [options]="severites" [(ngModel)]="entretien.severite" optionLabel="nom" filter="true"
                appendTo="body" filterBy="nom" placeholder="Sélectionner une sévérité...">
                <ng-template pTemplate="selectedItem">
                    <div *ngIf="entretien.severite">
                        <div>{{entretien.severite.nom}}</div>
                    </div>
                </ng-template>
                <ng-template let-severite pTemplate="item">
                    <div>
                        <div>{{severite.nom}}</div>
                    </div>
                </ng-template>
            </p-dropdown>
            <small class="p-error" *ngIf="submitted && !entretien.severite">La sévérité est obligatoire.</small>
        </div>
        <div class="flex flex-column gap-2 col-12 p-md-3">
            <label for="lastname">Code de catégorie</label>
            <p-dropdown [options]="categoriesEntretien" [(ngModel)]="entretien.categorie" optionLabel="description"
                [showClear]="true" filter="true" filterBy="code,description" appendTo="body"
                placeholder="Sélectionner une catégorie...">
                <ng-template pTemplate="selectedItem">
                    <div *ngIf="entretien.categorie">
                        <div>{{entretien.categorie.code}} - {{entretien.categorie.description}}</div>
                    </div>
                </ng-template>
                <ng-template let-categorie pTemplate="item">
                    <div>
                        <div>{{categorie.code}} - {{categorie.description}}</div>
                    </div>
                </ng-template>
            </p-dropdown>

            <small class="p-error" *ngIf="submitted && !entretien.categorie">La catégorie est obligatoire.</small>
        </div>
        <div class="flex flex-column gap-2 col-12 p-md-3">
            <label for="lastname">Saisi par</label>
            <p-dropdown [options]="employes" [(ngModel)]="entretien.employe" optionLabel="nom" filter="true"
                appendTo="body" filterBy="prenom,nom" placeholder="Sélectionner une catégorie...">
                <ng-template pTemplate="selectedItem">
                    <div *ngIf="entretien.employe">
                        <div>{{entretien.employe.prenom}} {{entretien.employe.nom}}</div>
                    </div>
                </ng-template>
                <ng-template let-employe pTemplate="item">
                    <div>
                        <div>{{employe.prenom}} {{employe.nom}}</div>
                    </div>
                </ng-template>
            </p-dropdown>
            <small class="p-error" *ngIf="submitted && !entretien.employe">L'employé est obligatoire.</small>
        </div>
        <div class="flex flex-column gap-2 col-12 p-md-3">
            <label for="lastname">Pièce en commande</label>
            <p-checkbox [binary]="true" [(ngModel)]="entretien.indPieceEnCommande" style="display: block;"></p-checkbox>
        </div>
    </div>

    <div class="fluid formgrid grid">
        <div class="flex flex-column gap-2 col-12">
            <label for="note">Note</label>
            <input id="note" type="text" pInputText [(ngModel)]="entretien.note">
        </div>
    </div>

</div>


<div class="text-right mt-3">

    <button pButton pRipple label="Enregistrer" icon="pi pi-save" class="p-button-primary"
        (click)="enregistrer()"></button>
    <button pButton pRipple label="Annuler" icon="pi pi-ban" class="p-button-text" (click)="annuler()"></button>

</div>