import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from "@angular/core"; import { Location } from "@angular/common";

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { ReponseBase } from './reponse-base';
import { ContactEntite } from '../entites/contact';
import { LiaisonEntite } from '../entites/liaison';

@Injectable({ providedIn: 'root' })
export class LiaisonService {

  private ajouterUrl = environment.apiBaseUrl + 'liaison';
  private supprimerUrl = environment.apiBaseUrl + 'liaison/supprimer';


  public httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(
    private http: HttpClient,
    public router: Router) { }

  public Ajouter(liaison: LiaisonEntite): Promise<ReponseBase<LiaisonEntite>> {
    var p = this.http.post<ReponseBase<LiaisonEntite>>(this.ajouterUrl, JSON.stringify(liaison), this.httpOptions)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }


  public Supprimer(liaison: LiaisonEntite): Promise<ReponseBase<number>> {
    var p = this.http.post<ReponseBase<number>>(this.supprimerUrl, JSON.stringify(liaison), this.httpOptions)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);

      this.log(`${operation} failed: ${error.message}`);

      this.router.navigateByUrl('erreurtechnique');

      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(message);
  }
}
