import { Component, OnInit, ViewChild, Input } from "@angular/core"; import { Location } from "@angular/common";
import { Table } from 'primeng/table';
import { BreadcrumbService } from '../../app.breadcrumb.service';

import { CommandeService } from '../../services/commande-service';
import { CommandeMin } from '../../entites/commande-min';
import { MenuItem } from 'primeng/api/menuitem';
import { MessageService, LazyLoadEvent, ConfirmationService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

import { DialogService } from 'primeng/dynamicdialog';
import { Router, ActivatedRoute } from '@angular/router';
import { Emplacement } from 'src/app/entites/emplacement';
import { EmplacementService } from 'src/app/services/emplacement-service';
import { BaseComponent } from 'src/app/base/base-component';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { ProfilService } from 'src/app/services/profil-service';
import { AppMainComponent } from 'src/app/app.main.component';

@Component({
    templateUrl: './precommandes.component.html',
    styleUrls: ['./precommandes.component.scss'],
    styles: [`
        @media screen and (max-width: 960px) {
            :host ::ng-deep .p-datatable.p-datatable-customers.rowexpand-table .p-datatable-tbody > tr > td:nth-child(6) {
                display: flex;
            }
        }

    `],
    providers: [MessageService, ConfirmationService, DialogService]
})
export class PrecommandesComponent extends BaseComponent implements OnInit {

    selectionCommande: Emplacement;
    
    listeEmplacementsActifs: Emplacement[];

    @ViewChild('dt') table: Table;

    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute, 
		appMain: AppMainComponent, location: Location, 
        private breadcrumbService: BreadcrumbService,
        private CommandesService: CommandeService,
        private emplacementService: EmplacementService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        public dialogService: DialogService,
        public routerService: Router) {
        
            super(sdb, profilService, route, routerService, appMain);
            this.breadcrumbService.setItems([
                { label: 'Pré-commandes' }
            ]);

    }

    ngOnInit() {

        this.verifierAccesPage('precommande');

       

        if (this.profilService.Profil().roles.find(x => x.nom == 'SIHC_Administrateur')) {
            this.listeEmplacementsActifs = this.sdb.Emplacements().filter(x => x.commandeActive);
        } else {
            if (this.profilService.Profil().employe.emplacementIdsCP) {
                this.listeEmplacementsActifs = this.sdb.Emplacements().filter(x => x.commandeActive && this.profilService.Profil().employe.emplacementIdsCP.includes(x.emplacementId));
            } else {
                this.listeEmplacementsActifs = [];    
            }
        }
        
        this.CompleterChargement();
    }

    OuvrirDetailCommande(emplacementId: number) {
        this.routerService.navigateByUrl(`precommande/${emplacementId}`)
    }
}
