import {Component, ViewChild, ElementRef} from "@angular/core"; import { Location } from "@angular/common";
import {AppMainComponent} from './app.main.component';
import {animate, state, style, transition, trigger, AnimationEvent} from '@angular/animations';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';

@Component({
    /* tslint:disable:component-selector */
    selector: 'app-search',
    /* tslint:enable:component-selector */
    template: `
    <p-toast key="search"></p-toast>
        <div class="layout-search">
            <div class="search-container" [@animation]="appMain.search ? 'visible' : 'hidden'" (@animation.done)="onAnimationEnd($event)">
                <i class="pi pi-search"></i>
                <input #input [(ngModel)]="recherche" type="text" class="p-inputtext search-input" placeholder="Recherche..." (click)="appMain.searchClick = true;" (keydown)="onInputKeydown($event)"/>
                
            </div>
        </div>
    `,
    animations: [
        trigger('animation', [
            state('hidden', style({
                transform: 'translateY(20px)',
                opacity: 0,
                visibility: 'hidden'
            })),
            state('visible', style({
                opacity: 1,
                visibility: 'visible'
            })),
            transition('hidden <=> visible', animate('.4s cubic-bezier(.05,.74,.2,.99)'))
        ])
    ],
    providers: [MessageService]
})
export class AppSearchComponent {

    recherche: string;

    @ViewChild('input') inputElement: ElementRef;

    constructor(public appMain: AppMainComponent, location: Location, 
        private messageService: MessageService,
        private router: Router) {}

    onAnimationEnd(event: AnimationEvent) {
        if (event.toState === 'visible') {
            this.inputElement.nativeElement.focus();
        }
    }

    onInputKeydown(event) {
        const key = event.which;

        // escape, tab and enter
        if (key === 27 || key === 9 ) {
            this.recherche = '';
            this.appMain.onSearchHide(event);
        }

        if ( key === 13) {

            if (this.recherche && this.recherche.length >= 3) {
                this.appMain.recherche = this.recherche;
                this.recherche = '';
                this.appMain.onSearchHide(event);
                this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
                    this.router.navigate(['recherche']));
            } else {
                this.messageService.add({ key: 'search', severity: 'warn', summary: 'Attention', detail: `Veuillez saisir au moins 3 caractères.` });
            }

            


        }
    }

   


}
