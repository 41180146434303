import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from "@angular/core"; import { Location } from "@angular/common";

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { ItemMin } from '../entites/item-min';
import { Router } from '@angular/router';
import { ReponseBase } from './reponse-base';
import { Item } from '../entites/item';
import { BonCommandeEntite } from '../entites/bon-commande';
import { ItemBonCommande } from '../entites/item-bon-commande';
import { RequeteTransfererVersCtrl } from './requetes/requete-transfert-ctrl';
import { ItemService } from './item-service';

@Injectable({ providedIn: 'root' })
export class AchatService {

  private bonCommandeUrl = environment.apiBaseUrl + 'achat/bonsCommande';
  private bonCommandeSeulUrl = environment.apiBaseUrl + 'achat/bonsCommande/{bonCommandeId}';
  private obtenirDetailUrl = environment.apiBaseUrl + 'achat/bonsCommande/{bonCommandeId}';
  private itemBonCommandeUrl = environment.apiBaseUrl + 'achat/bonsCommande/item';
  private obtenirNumeroCtrlUrl = environment.apiBaseUrl + 'achat/bonsCommande/obtenirNumeroCTRL';
  private transfererVersCtrlUrl = environment.apiBaseUrl + 'achat/bonsCommande/transfertCTRL';
  private obtenirHsitoriqueItemUrl = environment.apiBaseUrl + 'achat/historiqueItem/{itemId}';
  private obtenirBonCommandeOuvertFournisseurUrl = environment.apiBaseUrl + 'achat/bonsCommandeOuvert/{fournisseurId}/{employeId}';
  private obtenirBonCommandeLocationOuvertFournisseurUrl = environment.apiBaseUrl + 'achat/bonsCommandeLocationOuvert/{fournisseurId}/{employeId}';
  private   corrigerBonCommandeUrl = environment.apiBaseUrl + 'achat/bonsCommande/corriger';
  private obtenirHistoriqueAchatPiecesUrl = environment.apiBaseUrl + 'achat/historiqueachatpieces/{uniteId}';
  private completerBonCommandeUrl = environment.apiBaseUrl + 'achat/bonCommande/completer';
  
  public httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(
    public itemService: ItemService,
    private http: HttpClient,
    public router: Router) { }

  public ObtenirBonsCommande(statutId: number, employeId: number): Promise<ReponseBase<BonCommandeEntite[]>> {
      var params = new HttpParams()
      if (statutId) {
        params = params.set('statutId', statutId.toString());
      }
      if (employeId) {
        params = params.set('employeId', employeId.toString());
      }
      var p = this.http.get<ReponseBase<BonCommandeEntite[]>>(this.bonCommandeUrl, { params })
      .toPromise(); 

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public ObtenirHistoriqueItem(itemId: number): Promise<ReponseBase<ItemBonCommande[]>> {
    var p = this.http.get<ReponseBase<ItemBonCommande[]>>(this.obtenirHsitoriqueItemUrl.replace('{itemId}',itemId.toString()))
    .toPromise();

    p.catch(() => this.router.navigateByUrl('error'));

    return p;
}

public ObtenirHistoriqueAchatPieces(uniteId: number): Promise<ReponseBase<ItemBonCommande[]>> {
  var p = this.http.get<ReponseBase<ItemBonCommande[]>>(this.obtenirHistoriqueAchatPiecesUrl.replace('{uniteId}',uniteId.toString()))
  .toPromise();

  p.catch(() => this.router.navigateByUrl('error'));

  return p;
}

public SupprimerBonCommande(bonCommandeId: number): Promise<ReponseBase<boolean>> {
  var p = this.http.delete<ReponseBase<boolean>>(this.bonCommandeSeulUrl.replace('{bonCommandeId}', bonCommandeId.toString()), this.httpOptions).toPromise();

  p.catch(() => this.router.navigateByUrl('error'));

  return p;
}

public SupprimerItemsBonCommande(bonCommandeIds: number[]): Promise<ReponseBase<boolean>> {
  var params = new HttpParams().appendAll({ ids: bonCommandeIds });
  var p = this.http.delete<ReponseBase<boolean>>(this.itemBonCommandeUrl, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), params }).toPromise();

  p.catch(() => this.router.navigateByUrl('error'));

  return p;
}

public CreerBonCommande(requete: BonCommandeEntite): Promise<ReponseBase<number>> {
  var p = this.http.post<ReponseBase<number>>(this.bonCommandeUrl, JSON.stringify(requete), this.httpOptions).toPromise();

  p.catch(() => this.router.navigateByUrl('error'));

  p.then(() => this.itemService.ReinitialiserItems());

  return p;
}

public CreerItemBonCommande(requete: ItemBonCommande): Promise<ReponseBase<ItemBonCommande>> {
  var p = this.http.post<ReponseBase<ItemBonCommande>>(this.itemBonCommandeUrl, JSON.stringify(requete), this.httpOptions).toPromise();

  p.catch(() => this.router.navigateByUrl('error'));

  p.then(() => this.itemService.ReinitialiserItems());

  return p;
}

public ModifierItemBonCommande(requete: ItemBonCommande): Promise<ReponseBase<ItemBonCommande>> {
  var p = this.http.put<ReponseBase<ItemBonCommande>>(this.itemBonCommandeUrl, JSON.stringify(requete), this.httpOptions).toPromise();

  p.catch(() => this.router.navigateByUrl('error'));

  p.then(() => this.itemService.ReinitialiserItems());

  return p;
}

public CorrigerBonCommande(requete: BonCommandeEntite): Promise<ReponseBase<BonCommandeEntite>> {
  var p = this.http.post<ReponseBase<BonCommandeEntite>>(this.corrigerBonCommandeUrl, JSON.stringify(requete), this.httpOptions).toPromise();

  p.catch(() => this.router.navigateByUrl('error'));

  return p;
}

public ObtenirNumeroCtrl(bonCommandeId: number): Promise<ReponseBase<string>> {
  var p = this.http.post<ReponseBase<string>>(this.obtenirNumeroCtrlUrl, JSON.stringify(bonCommandeId), this.httpOptions).toPromise();

  p.catch(() => this.router.navigateByUrl('error'));

  return p;
}

public TransfererVersCtrl(requete: RequeteTransfererVersCtrl): Promise<ReponseBase<string>> {
  var p = this.http.post<ReponseBase<string>>(this.transfererVersCtrlUrl, JSON.stringify(requete), this.httpOptions).toPromise();

  p.catch(() => this.router.navigateByUrl('error'));

  p.then(() => this.itemService.ReinitialiserItems());

  return p;
}

public ObtenirBonCommande(bonCommandeId: number): Promise<ReponseBase<BonCommandeEntite>> {
  var p = this.http.get<ReponseBase<BonCommandeEntite>>(this.obtenirDetailUrl.replace('{bonCommandeId}',bonCommandeId.toString()))
  .toPromise();

  p.catch(() => this.router.navigateByUrl('error'));

  return p;
}


public ModifierBonCommande(requete: BonCommandeEntite): Promise<ReponseBase<BonCommandeEntite>> {
  var p = this.http.put<ReponseBase<ItemBonCommande>>(this.bonCommandeUrl, JSON.stringify(requete), this.httpOptions).toPromise();

  p.catch(() => this.router.navigateByUrl('error'));

  return p;
}


public CompleterBonCommandeSansTransfertCtrl(requete: BonCommandeEntite): Promise<ReponseBase<BonCommandeEntite>> {
  var p = this.http.post<ReponseBase<ItemBonCommande>>(this.completerBonCommandeUrl, JSON.stringify(requete), this.httpOptions).toPromise();

  p.catch(() => this.router.navigateByUrl('error'));

  return p;
}

public ObtenirBonCommandeOuvertFournisseur(fournisseurId: number, employeId: number): Promise<ReponseBase<BonCommandeEntite>> {
  var p = this.http.get<ReponseBase<BonCommandeEntite>>(this.obtenirBonCommandeOuvertFournisseurUrl.replace('{fournisseurId}',fournisseurId.toString()).replace('{employeId}',employeId.toString()))
  .toPromise();

  p.catch(() => this.router.navigateByUrl('error'));

  return p;
}

public ObtenirBonCommandeLocationOuvertFournisseur(fournisseurId: number, employeId: number): Promise<ReponseBase<BonCommandeEntite[]>> {
  var p = this.http.get<ReponseBase<BonCommandeEntite[]>>(this.obtenirBonCommandeLocationOuvertFournisseurUrl.replace('{fournisseurId}',fournisseurId.toString()).replace('{employeId}',employeId.toString()))
  .toPromise();

  p.catch(() => this.router.navigateByUrl('error'));

  return p;
}


  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);

      this.log(`${operation} failed: ${error.message}`);

      this.router.navigateByUrl('erreurtechnique');

      return of(result as T);
    };
  }

  private log(message: string) {
      console.log(message);
  }
}
