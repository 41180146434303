<p-toast></p-toast>
<p-confirmDialog header="Confirmation" key="basic" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-menu #menuContextuelEtape popup="popup" [model]="contenuMenuContextuelEtape"></p-menu>
<p-menu #menuContextuelPiece popup="popup" [model]="contenuMenuContextuelPiece"></p-menu>
<p-menu #menuContextuelNote popup="popup" [model]="contenuMenuContextuelNote"></p-menu>
<p-card *ngIf="!EstCharge()">
	<div class="fluid formgrid grid">
		<div class="flex flex-column gap-2 my-2 col-12 text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-card>

<div *ngIf="EstCharge()" class="grid">
	<div class="col-12">
		<div class="card">
			<h5>{{bonTravail.indSoumission ? 'Soumission' : 'Bon de travail'}} #{{bonTravail.numero}}
				<span [class]="'statut ' + bonTravail.statut.nom.toLowerCase().replace(' ','')">
					{{bonTravail.statut.nom}}
				</span>
				<span *ngIf="modeApercu"> 
					<i class="pi pi-id-card ml-4" ></i>
					<a class="lienFicheComplete" (click)="ouvrirFicheComplete()">Aller à la fiche complète</a>
				</span>
			</h5>
			<h6 *ngIf="bonTravail.dateEnvoi"><i class="pi pi-send" style="font-size: 0.8rem"></i> Assigné le :
				{{bonTravail.dateDebut | date:'yyyy-MM-dd HH:mm:ss'}}</h6>

			<p-menubar autoDisplay="false" [model]="menu" *ngIf="!modeApercu">
				<p-button *ngIf="(estAutorise('bontravail_modifier') || estAutorise('temp_mec')) && modeConsultation() && estModifiable"
					(click)="btnModifier_click()" icon="pi pi-pencil" styleClass="p-button-primary"></p-button>
				<p-button *ngIf="modeAjoutModification()" (onClick)="enregistrerModifications()" label
					icon="pi pi-check" styleClass="p-button-success mr-2">
				</p-button>
				<p-button *ngIf="modeAjoutModification()" (onClick)="annulerModification($event)" icon="pi pi-times"
					styleClass="p-button-secondary"></p-button>
			</p-menubar>

		</div>
	</div>
</div>


<div *ngIf="EstCharge()" class="grid">
	<div class="col-12">
		<div class="card">
			<p-tabView orientation="left">
				<p-tabPanel header="Informations générales" leftIcon="pi pi-info-circle">

					<div class="fluid formgrid grid mb-6">
						<div class="col-12 md:col-9">
							<div class="fluid formgrid grid">
								<div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-1">
									<label for="numeroItem">Unité</label>
									<a style="font-weight: 700;" [routerLink]="'/item/' + bonTravail.item.itemId">
										<span class="champConsultation">{{bonTravail.item.numero | valeurVide}}</span>
									</a>
								</div>
								<div class="flex flex-column gap-2 my-2 col-12 md:col-4">
									<label for="descriptionItem">Description</label>
									<input pInputText value="{{bonTravail.item.description | valeurVide}}" readonly />
								</div>
								<div class="flex flex-column gap-2 my-2 col-12 md:col-2">
									<label for="heuresItem">Heures (compteur/réel)</label>
									<input pInputText value="{{bonTravail.heuresCompteur | valeurVide}} / {{bonTravail.heuresReelles | valeurVide}}" readonly />
								</div>
								<div class="flex flex-column gap-2 my-2 col-12 md:col-2">
									<label for="kmItem">KM (compteur/réel)</label>
									<input pInputText value="{{bonTravail.kmCompteur | valeurVide}} / {{bonTravail.kmReels | valeurVide}}" readonly />
								</div>
								<div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3">
									<label for="kmItem">Type</label>
									<input pInputText *ngIf="modeConsultation()" value="{{selectionTypeBonTravail?.valeur | valeurVide}}" readonly />
									<p-selectButton *ngIf="!modeConsultation()" [options]="typesBonTravail" [(ngModel)]="selectionTypeBonTravail" (onChange)="surChangementTypeBonTravail()" optionLabel="valeur">
									</p-selectButton>
								</div>
						
							</div>
							
							<div class="fluid formgrid grid">
								
								<div class="flex flex-column gap-2 my-2 col-12 md:col-3">
									<label for="contreMaitre">Contre-Maître<span *ngIf="!modeConsultation()" class="requis">*</span></label>
									<input pInputText *ngIf="modeConsultation()" value="{{bonTravail.contreMaitre | nomEmploye | valeurVide}}" readonly />
									<p-dropdown *ngIf="!modeConsultation()" [options]="employes" [(ngModel)]="bonTravail.contreMaitre" optionLabel="nom"
										[showClear]="true" filter="true" filterBy="prenom,nom" appendTo="body"
										placeholder="Sélectionner un contre-maître...">
										<ng-template pTemplate="selectedItem">
											<div *ngIf="bonTravail.contreMaitre">
												<div>{{bonTravail.contreMaitre.prenom}} {{bonTravail.contreMaitre.nom}}</div>
											</div>
										</ng-template>
										<ng-template let-employe pTemplate="item">
											<div>
												<div>{{employe.prenom}} {{employe.nom}}</div>
											</div>
										</ng-template>
									</p-dropdown>
								
								</div>
		
								<div class="flex flex-column gap-2 my-2 col-12 md:col-3" *ngIf="selectionTypeBonTravail.cle == 1">
									<label for="mecanicien">Mécanicien</label>
									<input pInputText *ngIf="modeConsultation()" value="{{bonTravail.mecanicien | nomEmploye | valeurVide}}" readonly />
									<p-dropdown *ngIf="!modeConsultation()" [options]="employes" [(ngModel)]="bonTravail.mecanicien" optionLabel="nom"
										[showClear]="true" filter="true" filterBy="prenom,nom" appendTo="body"
										placeholder="Sélectionner un mécanicien...">
										<ng-template pTemplate="selectedItem">
											<div *ngIf="bonTravail.mecanicien">
												<div>{{bonTravail.mecanicien.prenom}} {{bonTravail.mecanicien.nom}}</div>
											</div>
										</ng-template>
										<ng-template let-employe pTemplate="item">
											<div>
												<div>{{employe.prenom}} {{employe.nom}}</div>
											</div>
										</ng-template>
									</p-dropdown>
								
								</div>
		
								<div class="flex flex-column gap-2 my-2 col-12 md:col-3" *ngIf="selectionTypeBonTravail.cle == 2">
									<label for="lastname">Fournisseur</label>
									<input pInputText *ngIf="modeConsultation()" value="{{bonTravail.fournisseur?.nom | valeurVide}}" readonly />
									<p-dropdown [options]="fournisseurs" [(ngModel)]="bonTravail.fournisseur" optionLabel="nom"
										*ngIf="!modeConsultation()" filter="true" filterBy="numero,nom"
										placeholder="Sélectionner un fournisseur...">
										<ng-template pTemplate="selectedItem">
											<div *ngIf="bonTravail.fournisseur">
												<div>{{bonTravail.fournisseur.nom}} ({{bonTravail.fournisseur.numero}})</div>
											</div>
										</ng-template>
										<ng-template let-fournisseur pTemplate="item">
											<div>
												<div>{{fournisseur.nom}} ({{fournisseur.numero}})</div>
											</div>
										</ng-template>
									</p-dropdown>
								</div>
								
								<div class="flex flex-column gap-2 my-2 col-12 md:col-3">
									<label for="contreMaitre">Emplacement</label>
									<input pInputText *ngIf="modeConsultation()" value="{{bonTravail.emplacement?.nom | valeurVide}}" readonly />
									<p-dropdown *ngIf="!modeConsultation()" [options]="emplacements" [(ngModel)]="bonTravail.emplacement" optionLabel="nom"
										[showClear]="true" filter="true" filterBy="nom" appendTo="body"
										placeholder="Sélectionner un emplacement...">
										<ng-template pTemplate="selectedItem">
											<div *ngIf="bonTravail.emplacement">
												<div>{{bonTravail.emplacement.nom}}</div>
											</div>
										</ng-template>
										<ng-template let-emplacement pTemplate="item">
											<div>
												<div>{{emplacement.nom}}</div>
											</div>
										</ng-template>
									</p-dropdown>
								</div>
								
								<div class="flex flex-column gap-2 my-2 col-12 md:col-3">
									<label for="lastname">Raison</label>
									<input pInputText *ngIf="modeConsultation()" value="{{bonTravail.raison?.description | valeurVide}}" readonly />
									<p-dropdown *ngIf="!modeConsultation()" [options]="raisons" [(ngModel)]="bonTravail.raison" optionLabel="description"
										filter="true" filterBy="code,description"
										placeholder="Sélectionner une raison...">
										<ng-template pTemplate="selectedItem">
											<div *ngIf="bonTravail.raison">
												<div>{{bonTravail.raison.description}} ({{bonTravail.raison.code}})</div>
											</div>
										</ng-template>
										<ng-template let-raison pTemplate="item">
											<div>
												<div>{{raison.description}} ({{raison.code}})</div>
											</div>
										</ng-template>
									</p-dropdown>
								</div>
								<div *ngIf="!bonTravail.indSoumission" class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3">
									<label for="kmItem">Montant Total</label>
									<input pInputText value="{{bonTravail.coutReelPieces | valeurVide}}" readonly />
								</div>
								<div *ngIf="!bonTravail.indSoumission" class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3">
									<label for="kmItem">Heures Total</label>
									<input pInputText value="{{bonTravail.coutReelHeures | valeurVide}}" readonly />
								</div>
								<div class="flex flex-column gap-2 mb-2 mt-4 col-12">
									<label for="note">Note du bon de travail</label>
									<span class="champConsultation" *ngIf="modeConsultation()">{{bonTravail.note | valeurVide}}</span>
									<input #champNote *ngIf="!modeConsultation()" type="text" pInputText id="note"
										[(ngModel)]="bonTravail.note" autocomplete="off" />
								</div>
		
								<div class="flex flex-column gap-2 my-2 col-12">
									<label for="emplacement">Note mécanique de l'unité</label>
									<span class="champConsultation" style="white-space: pre-line; font-size: 1.5rem; color: red;">{{bonTravail.item.noteMecanique | valeurVide}}</span>
								</div>
							</div>
						</div>
						<div class="col-12 md:col-3 mt-2 mb-5">
							<p-timeline [value]="events" layout="vertical" align="left">
								<ng-template pTemplate="content" let-event>
									{{ event.label }}
								</ng-template>
								<ng-template pTemplate="opposite" let-event>
									<div *ngIf="modeConsultation()" style="text-wrap: nowrap;">{{bonTravail[event.key] | date:'dd/MM/yy' | valeurVide}}</div>
									<p-calendar *ngIf="!modeConsultation()" appendTo="body" showIcon=true [(ngModel)]="bonTravail[event.key]"
										dateFormat="yy-mm-dd" [yearNavigator]="true" [monthNavigator]="true"
										yearRange="2000:2040"></p-calendar>
								</ng-template>
							</p-timeline>
						</div>
					</div>

					<!-------------  TRAVAIL  -------------->

					<p-table #dtTravail [value]="bonTravail.etapes" dataKey="idTemporaire" tableStyleClass="tableNormale" autoLayout="true" 
						[columns]="selectedColumns"
						[(selection)]="selectionMultipleEtapes"
						[expandedRowKeys]="lignesOuvertes"
						(onRowSelect)="surChangementSelectionEtape()"
						(onRowUnselect)="surChangementSelectionEtape()"
						(onHeaderCheckboxToggle)="surChangementSelectionEtape()"
						(onRowReorder)="surChangementOrdreEtape($event)"
						[rowHover]="true"
						[reorderableRows]="true" 
						[scrollable]="true"
						[filterDelay]="0"
						[sortField]="'ordre'"
						[globalFilterFields]="['description','note','categorie.code','categorie.description']">
						<ng-template pTemplate="caption">
							<div class="flex flex-wrap align-content-center justify-content-between">
								<div>
									<span class="p-input-icon-left">
										<i class="pi pi-search"></i>
										<input pInputText type="text"
											(input)="dtTravail.filterGlobal($event.target.value, 'contains')"
											placeholder="Recherche..." />
									</span>
									<button *ngIf="!modeConsultation()" pButton pRipple icon="pi pi-plus"
										class="p-button-success mr-2 ml-4 mb-2 boutonGrille"
									        pTooltip="Ajouter une étape" tooltipPosition="top"
										(click)="surAjoutEtape()"></button>

									<button *ngIf="!modeConsultation() && selectionMultipleEtapes && selectionMultipleEtapes.length > 0" pButton pRipple icon="pi pi-times"
										class="p-button-danger mr-2 ml-4 mb-2 boutonGrille"
									        pTooltip="Supprimer les étapes sélectionnées" tooltipPosition="top"
										(click)="supprimerEtapes($event, selectionMultipleEtapes)"></button>

									<button *ngIf="!modeConsultation() && selectionMultipleEtapes && selectionMultipleEtapes.length > 0 && this.estAutorise('bontravail_approuver')" pButton pRipple icon="pi pi-refresh"
									        pTooltip="Convertir les étapes sélectionnées en manuel" tooltipPosition="top"
										class="p-button-info mr-2 ml-4 mb-2 boutonGrille"
										(click)="convertirEtapesVersAut($event, selectionMultipleEtapes)"></button>


								</div>
								<h5 class="titreTableau m-0">Travaux</h5>
								<div>
									<p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
										selectedItemsLabel="{0} colonnes sélectionnés" [style]="{minWidth: '200px'}"
										placeholder="Colonnes..."></p-multiSelect>
								</div>
							</div>
						</ng-template>

						<ng-template pTemplate="header" let-columns>
							<tr>
								<th *ngIf="!modeConsultation()" style="min-width: 3rem; width: 3rem;"></th>
								<th *ngIf="bonTravail.indSoumission" style="min-width: 3rem; width: 3rem;"></th>
								<th *ngIf="!modeConsultation()" style="min-width: 3rem; width: 3rem;">
									<p-tableHeaderCheckbox></p-tableHeaderCheckbox>
								</th>
								<th pSortableColumn="ordre">
									<div class="flex justify-content-between align-items-center">
										#
										<p-sortIcon field="ordre"></p-sortIcon>
										<p-columnFilter type="text" field="ordre" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="nom">
									<div class="flex justify-content-between align-items-center">
										Type
										<p-sortIcon field="nom"></p-sortIcon>
										<p-columnFilter type="text" field="nom" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="description">
									<div class="flex justify-content-between align-items-center">
										Catégorie
										<p-sortIcon field="description"></p-sortIcon>
										<p-columnFilter type="text" field="description" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="description">
									<div class="flex justify-content-between align-items-center">
										Description
										<p-sortIcon field="description"></p-sortIcon>
										<p-columnFilter type="text" field="description" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="note">
									<div class="flex justify-content-between align-items-center">
										Note
										<p-sortIcon field="note"></p-sortIcon>
										<p-columnFilter type="text" field="note" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th>
									<!-- <div class="flex justify-content-between align-items-center">
										A faire
										<p-sortIcon field="fait"></p-sortIcon>
										<p-columnFilter type="text" field="fait" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div> -->
								</th>
								<th *ngIf="!bonTravail.indSoumission" pSortableColumn="fait">
									<div class="flex justify-content-between align-items-center">
										Fait
										<p-sortIcon field="fait"></p-sortIcon>
										<p-columnFilter type="text" field="fait" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th *ngIf="!bonTravail.indSoumission" pSortableColumn="fait">
									<div class="flex justify-content-between align-items-center">
										Par
										<p-sortIcon field="fait"></p-sortIcon>
										<p-columnFilter type="text" field="fait" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th *ngIf="!modeModification()" style="min-width: 7rem; width: 7rem;"></th>
								<th *ngIf="modeModification()" style="min-width: 14rem; width: 14rem;"></th>
								<th *ngIf="bonTravail.indSoumission" style="width: 8rem;">
									A Faire
								</th>
							</tr>
						</ng-template>
						<ng-template pTemplate="body" let-entree let-columns="columns" let-index="rowIndex" let-expanded="expanded">
							<tr [pReorderableRow]="index">
								<td *ngIf="!modeConsultation()">
									<span class="pi pi-bars" pReorderableRowHandle></span>
								</td>
								<td *ngIf="bonTravail.indSoumission" style="min-width: 3rem; width: 3rem;"> 
									<p-button *ngIf="entree.optionEtapeBonTravails" 
									type="button" 
									pRipple 
									[pRowToggler]="entree" 
									[text]="true" 
									[rounded]="true" 
									[plain]="true" 
									[icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'" />
								</td>
								<td *ngIf="!modeConsultation()">
									<p-tableCheckbox [value]="entree"></p-tableCheckbox>
								</td>
								<td>{{entree.ordre}}</td>
								<td>{{entree.typeEtapeBonTravail?.code}}</td>
								<td>{{entree.categorie | categorieEntretien}}</td>
								<td>{{entree.description}}</td>
								<td>{{entree.note}} <p-tag *ngIf="!entree.indAFaire && !bonTravail.indSoumission" severity="warning" value="Ne pas faire"/>
									<button *ngIf="!modeConsultation()" pButton pRipple icon="pi pi-pencil"
										class="p-button-primary p-button-outlined mr-2 boutonGrille"
										(click)="modifierNote($event, entree)"></button>
								</td>
								<td>
									<!-- <p-checkbox [disabled]="modeConsultation()" [(ngModel)]="entree.indAFaire" [binary]="true"></p-checkbox> -->
								</td>
								<td *ngIf="!bonTravail.indSoumission">
									<p-checkbox [disabled]="modeConsultation()" [(ngModel)]="entree.indComplete" [binary]="true"></p-checkbox>
								</td>
								<td *ngIf="!bonTravail.indSoumission">
									<span *ngIf="modeConsultation()">{{entree.mecanicien | nomEmploye}}</span>
									<p-dropdown *ngIf="!modeConsultation()" [options]="employes" [(ngModel)]="entree.mecanicien" optionLabel="initiales"
										[showClear]="true" filter="true" filterBy="prenom,nom,initiales" appendTo="body"
										placeholder="...">
										<ng-template pTemplate="selectedItem">
											<div *ngIf="entree.mecanicien">
												<div>{{entree.mecanicien.initiales}}</div>
											</div>
										</ng-template>
										<ng-template let-employe pTemplate="item">
											<div>
												<div>{{employe.prenom}} {{employe.nom}}</div>
											</div>
										</ng-template>
									</p-dropdown>
									<i *ngIf="!modeConsultation() && entree.mecanicien" class="pi pi-copy ml-2" style="font-size: 1rem; cursor: pointer;" (click)="copierMecanicien(entree)" ></i>
								</td>
								<td>
									<div class="flex gap-1">
										<button *ngIf="!modeConsultation() && !bonTravail.indSoumission" pButton pRipple icon="pi pi-search-plus"
											class="p-button-primary boutonGrille"
											(click)="ajouterPiece($event, entree)"></button>
										<button *ngIf="!modeConsultation()" pButton pRipple icon="pi pi-trash"
											class="p-button-danger boutonGrille"
											(click)="supprimerEtapes($event, [entree])"></button>
										<button  pButton pRipple icon="pi pi-bars"
											class="p-button-secondary boutonGrille"
											(click)="surMenuContextuelEtape($event, entree)"></button>
										<button *ngIf="!modeConsultation()"  pButton pRipple icon="pi pi-angle-double-up"
											class="p-button-secondary boutonGrille"
											(click)="monterEtapeComplet($event, entree)"></button>
										<button *ngIf="!modeConsultation()"  pButton pRipple icon="pi pi-angle-double-down"
											class="p-button-secondary boutonGrille"
											(click)="descendreEtapeComplet($event, entree)"></button>
									</div>									
								</td>
								<td *ngIf="bonTravail.indSoumission">
									<p-checkbox [disabled]="bonTravail.statut.statutId == EStatuts.Complete || modeConsultation()" [(ngModel)]="entree.indAFaire" [binary]="true" (onChange)="CalculTotal()"></p-checkbox>
								</td>
							</tr>
						</ng-template>
						<ng-template *ngIf="bonTravail.indSoumission" pTemplate="rowexpansion" let-etape>
							<tr>
								<td [attr.colspan]="modeConsultation() ? 10 : 11" class="pl-0 pr-0" style="padding-bottom: 0rem !important; padding-top: 0rem !important;">
									<div class="flex justify-content-between border-bottom-1">
										<table class="p-datatable-header py-0">
											<tr>
												<th>
													<span class="font-bold">Options</span>
												</th>
											</tr>
										</table>
										<div class="flex-grow-1">
											<option-etape-bon-travail [(options)]="etape.optionEtapeBonTravails" 
											[(optionSelectionnee)]="etape.optionEtapeBonTravailIdTemporaire" 
											[readonly]="modeConsultation()"
											[disabled]="!etape.indAFaire" 
											(onChange)="CalculTotal()"></option-etape-bon-travail>
										</div>
									</div>
								</td>
							</tr>
						</ng-template>
						<ng-template *ngIf="bonTravail.indSoumission" pTemplate="footer">
							<tr>
								<th [attr.colspan]="modeConsultation() ? 7 : 9"></th>
								<th alignFrozen="right" pFrozenColumn [frozen]="true" class="p-datatable-header">
									Montant Total
								</th>
								<th alignFrozen="right" pFrozenColumn [frozen]="true" class="p-datatable-header">
									Heures Total
								</th>
							</tr>
							<tr>
								<th [attr.colspan]="modeConsultation() ? 7 : 9"></th>
								<td alignFrozen="right" pFrozenColumn [frozen]="true">
									{{bonTravail.coutReelPieces | number : '1.2-2'}} $
								</td>
								<td alignFrozen="right" pFrozenColumn [frozen]="true">
									{{bonTravail.coutReelHeures}} Heures
								</td>
							</tr>
						</ng-template>
						<ng-template pTemplate="emptymessage">
							<tr>
								<td colspan="10">Aucun travail.</td>
							</tr>
						</ng-template>
					</p-table>
					<br>

					<!-------------  PIÈCES  -------------->

					<p-table *ngIf="!bonTravail.indSoumission" #dtPieces [value]="pieces" [columns]="selectedColumns"
						dataKey="pieceBonTravailId" tableStyleClass="tableNormale" autoLayout="true"
						[(selection)]="selectionMultiplePieces" 
						 [rowHover]="true" [filterDelay]="0"
						[globalFilterFields]="['item.numero','item.description','quantite']">
						<ng-template pTemplate="caption">
							<div class="flex align-content-center justify-content-between">
								<div>
									<span class="p-input-icon-left">
										<i class="pi pi-search"></i>
										<input pInputText type="text"
											(input)="dtPieces.filterGlobal($event.target.value, 'contains')"
											placeholder="Recherche..." />
									</span>
									<!-- <button *ngIf="estModifiable && !modeApercu && selectionMultipleEtapes && selectionMultipleEtapes.length == 1" pButton pRipple icon="pi pi-plus"
										class="p-button-success mr-2 ml-4 mb-2 boutonGrille"
										(click)="surAjoutPiece()"></button> -->

									<button *ngIf="!modeConsultation() && selectionMultiplePieces && selectionMultiplePieces.length > 0" pButton pRipple icon="pi pi-times"
										class="p-button-danger mr-2 ml-4 mb-2 boutonGrille"
										(click)="supprimerPieces($event, selectionMultiplePieces)"></button>


								</div>
								<h5 class="titreTableau m-0">Pièces</h5>
								<div>
									<p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
										selectedItemsLabel="{0} colonnes sélectionnés" [style]="{minWidth: '200px'}"
										placeholder="Colonnes..."></p-multiSelect>
								</div>
							</div>
						</ng-template>

						<ng-template pTemplate="header" let-columns>
							<tr>
								<th *ngIf="!modeConsultation()" style="min-width: 3rem; width: 3rem;">
									<p-tableHeaderCheckbox></p-tableHeaderCheckbox>
								</th>
								<th pSortableColumn="noEtape">
									<div class="flex justify-content-between align-items-center">
										#
										<p-sortIcon field="noEtape"></p-sortIcon>
										<p-columnFilter type="text" field="noEtape" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								
								<th pSortableColumn="item.numero">
									<div class="flex justify-content-between align-items-center">
										Numéro
										<p-sortIcon field="item.numero"></p-sortIcon>
										<p-columnFilter type="text" field="item.numero" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="item.description">
									<div class="flex justify-content-between align-items-center">
										Description
										<p-sortIcon field="item.description"></p-sortIcon>
										<p-columnFilter type="text" field="item.description" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="item.marque">
									<div class="flex justify-content-between align-items-center">
										Marque
										<p-sortIcon field="item.marque"></p-sortIcon>
										<p-columnFilter type="text" field="item.marque" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="item.modele">
									<div class="flex justify-content-between align-items-center">
										Modèle
										<p-sortIcon field="item.modele"></p-sortIcon>
										<p-columnFilter type="text" field="item.modele" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="quantite">
									<div class="flex justify-content-between align-items-center">
										Qte nécessaire
										<p-sortIcon field="quantite"></p-sortIcon>
										<p-columnFilter type="numeric" field="quantite" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="qteInventaire">
									<div class="flex justify-content-between align-items-center">
										Qte inventaire
										<p-sortIcon field="qteInventaire"></p-sortIcon>
										<p-columnFilter type="numeric" field="qteInventaire" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="note">
									<div class="flex justify-content-between align-items-center">
										Note
										<p-sortIcon field="note"></p-sortIcon>
										<p-columnFilter type="text" field="note" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th style="min-width: 5rem; width: 5rem;"></th>

							</tr>
						</ng-template>
						<ng-template pTemplate="body" let-entree let-columns="columns" let-index="rowIndex">
							<tr>
								<td *ngIf="!modeConsultation()">
									<p-tableCheckbox [value]="entree"></p-tableCheckbox>
								</td>
								<td>{{entree.noEtape}}</td>
								<td>{{entree.item.numero}}</td>
								<td>{{entree.item.description}}</td>
								<td>{{entree.item.marque}}</td>
								<td>{{entree.item.modele}}</td>
								<td>{{entree.quantite}}</td>
								<td>
									<span [ngClass]="entree.qteInventaire && entree.qteInventaire > 0 ? entree.qteInventaire >= entree.quantite ? 'inventaire disponible' : 'inventaire insuffisant' : 'inventaire nondisponible'">
										{{entree.qteInventaire}}
									</span>
								</td>
								<td>
									{{entree.note}}
									<button *ngIf="!modeConsultation()" pButton pRipple icon="pi pi-pencil"
										class="p-button-primary p-button-outlined mr-2 boutonGrille"
										(click)="modifierNotePiece($event, entree)"></button>
								</td>
								<td>
									<button *ngIf="!modeConsultation()" pButton pRipple icon="pi pi-trash"
										class="p-button-danger mr-2 boutonGrille"
										(click)="supprimerPieces($event, [entree])"></button>
									<button *ngIf="modeConsultation()" pButton pRipple icon="pi pi-bars"
										class="p-button-secondary mr-2 boutonGrille"
										(click)="surMenuContextuelPiece($event, entree)"></button>
								</td>

							</tr>
						</ng-template>
						<ng-template pTemplate="emptymessage">
							<tr>
								<td colspan="9">Aucune pièce.</td>
							</tr>
						</ng-template>
					</p-table>

				</p-tabPanel>
				<p-tabPanel header="Notes de l'unité" leftIcon="pi pi-pencil">


					<p-table #dtNotes [value]="notes" dataKey="noteId" tableStyleClass="tableNormale"
							autoLayout="true"  [rowHover]="true"
							[filterDelay]="0" [globalFilterFields]="['description']">
							<ng-template pTemplate="caption">
								<div class="flex align-content-center justify-content-between">
									<div>
										<span class="p-input-icon-left">
											<i class="pi pi-search"></i>
											<input pInputText type="text"
												(input)="dtNotes.filterGlobal($event.target.value, 'contains')"
												placeholder="Recherche..." />
										</span>
										
										<button *ngIf="estAutorise('item_note_creer') && !modeApercu" pButton pRipple icon="pi pi-plus"
											class="p-button-success mr-2 ml-4 mb-2 boutonGrille"
											(click)="ajouterNoteUnite()"></button>

									</div>
									<h5 class="titreTableau m-0">Notes</h5>
									<div>
										<p-multiSelect disabled="true" [style]="{minWidth: '200px'}"
											placeholder="Colonnes..."></p-multiSelect>
									</div>
								</div>
							</ng-template>
							<ng-template pTemplate="header">
								<tr>
									<th pSortableColumn="date">
										<div class="flex justify-content-between align-items-center">
											Date
											<p-sortIcon field="date"></p-sortIcon>
											<p-columnFilter type="date" field="date" display="menu" class="ml-auto">
											</p-columnFilter>
										</div>
									</th>
									<th pSortableColumn="description">
										<div class="flex justify-content-between align-items-center">
											Note
											<p-sortIcon field="description"></p-sortIcon>
											<p-columnFilter type="text" field="description" display="menu"
												class="ml-auto">
											</p-columnFilter>
										</div>
									</th>
									<th pSortableColumn="demandeur.nom">
										<div class="flex justify-content-between align-items-center">
											Auteur
											<p-sortIcon field="demandeur"></p-sortIcon>
											<p-columnFilter type="text" field="demandeur" display="menu"
												class="ml-auto">
											</p-columnFilter>
										</div>
									</th>
									<th *ngIf="!modeApercu" style="min-width: 5rem; width: 5rem;"></th>
								</tr>
							</ng-template>
							<ng-template pTemplate="body" let-note>

								<tr>
									<td>{{note.date | date:'yyyy-MM-dd'}}</td>
									<td>{{note.description}}</td>
									<td>{{note.employe | nomEmploye}}</td>
									<td *ngIf="!modeApercu">
										<button
											*ngIf="estAutorise('item_note_modifier') || estAutorise('item_note_supprimer')"
											pButton pRipple icon="pi pi-bars"
											class="p-button-secondary mr-2 boutonGrille"
											(click)="surMenuContextuelNote($event, note)"></button>
									</td>
								</tr>
							</ng-template>
							<ng-template pTemplate="emptymessage">
								<tr>
									<td colspan="4">Aucune note.</td>
								</tr>
							</ng-template>
						</p-table>

				</p-tabPanel>
				<p-tabPanel *ngIf="!bonTravail.indSoumission" header="Garanties" leftIcon="pi pi-shield">

				</p-tabPanel>
				<p-tabPanel header="Documents ({{this.documents?.length}})" leftIcon="pi pi-file">
					<ng-template pTemplate="body">
						<div class="col-6 lg:col-3">
							<button pButton type="button" icon="pi pi-plus" (click)="ajouterDocument()"
							        label="Ajouter un document" class="mb-1"></button>
						</div>
						<br/>
						<div class="grid">
							<div class="col-12 md:col-6 lg:col-3" *ngFor="let doc of documents">
								<div class="col-12">
									<div class="card tuileFichier">
										<div class="imageFichier">
											<img id="myimage" [src]='doc.apercu' />
										</div>

										<div class="">
											<div class="nomFichier">{{doc.nom}}</div>
											<div class="dateFichier">{{doc.date | date:'yyyy-MM-dd'}}</div>
										</div>
										<div class="sectionBoutonsFichier">
											<div class="fluid formgrid grid">
												<div class="flex flex-column gap-2 my-2 col-6 text-left">
													<p-button *ngIf="doc.typeFichier == 1 || doc.typeFichier == 2"
													          (click)="afficherImage(doc)" icon="pi pi-search"
													          class="p-button-success mr-2"></p-button>
													<p-button (click)="telechargerDocument(doc)"
													          icon="pi pi-download"></p-button>
												</div>
												<div *ngIf="!modeApercu" class="flex flex-column gap-2 my-2 col-6 text-right">
													<button pButton pRipple icon="pi pi-trash"
													        class="p-button-danger"
													        (click)="supprimerDocument(doc)"></button>
												</div>
											</div>

										</div>
									</div>
								</div>
							</div>
						</div>
					</ng-template>
				</p-tabPanel>
			</p-tabView>
		</div>
	</div>


</div>