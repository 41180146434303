<p-toast key="creationBonCommande"></p-toast>


<p-card *ngIf="!EstCharge()">
    <div class="fluid formgrid grid">
        <div class="flex flex-column gap-2 col-12 text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-card>

<div #contenu *ngIf="EstCharge()">
   
    <div class="fluid formgrid grid">
        <div class="flex flex-column gap-2 col-12 md:col-6">
            <label for="codeProjet">Code projet<span class="requis">*</span></label>
            <p-dropdown #champCodeProjet [options]="listeCodeProjet" [(ngModel)]="selectionCodeProjet" optionLabel="code"
                [showClear]="true" (onHide)="surFermetureCodeProjet()" filter="true" filterBy="code,nom"
                (onChange)="surChangementCodeProjet()"
                appendTo="body" placeholder="Sélectionner un code de projet...">
                <ng-template pTemplate="selectedItem">
                    <div *ngIf="selectionCodeProjet">
                        <div>{{selectionCodeProjet.code}}</div>
                    </div>
                </ng-template>
                <ng-template let-code pTemplate="item">
                    <div>
                        <div>{{code.code}} - {{code.nom}}</div>
                    </div>
                </ng-template>
            </p-dropdown>
            <small class="p-error" *ngIf="submitted && !selectionCodeProjet">Le code de projet est obligatoire.</small>
        </div>
        <div class="flex flex-column gap-2 col-12 md:col-6">
            <label for="codeActivite">Code d'activité<span class="requis">*</span></label>
            <p-dropdown #champCodeActivite [options]="listeCodeActivite" [(ngModel)]="selectionCodeActivite" optionLabel="code"
                [disabled]="!selectionCodeProjet"
                [showClear]="true" (onHide)="surFermetureCodeActivite()" filter="true" filterBy="code,nom"
                appendTo="body" placeholder="Sélectionner un code d'activité...">
                <ng-template pTemplate="selectedItem">
                    <div *ngIf="selectionCodeActivite">
                        <div>{{selectionCodeActivite.code}}</div>
                    </div>
                </ng-template>
                <ng-template let-code pTemplate="item">
                    <div>
                        <div>{{code.code}} - {{code.nom}}</div>
                    </div>
                </ng-template>
            </p-dropdown>
            <small class="p-error" *ngIf="submitted && !selectionCodeActivite">Le code d'activité est obligatoire.</small>
        </div>
        <div class="flex flex-column gap-2 col-12 lg:col-3">
            <label for="lastname">Fréquence</label>
            <p-dropdown [options]="frequences" [(ngModel)]="selectionFrequence" optionLabel="valeur"
             filter="true" filterBy="numero,nom" appendTo="body"
                placeholder="Sélectionner une fréquence...">
                <ng-template pTemplate="selectedItem">
                    <div *ngIf="selectionFrequence">
                        <div>{{selectionFrequence.valeur}}</div>
                    </div>
                </ng-template>
                <ng-template let-frequence pTemplate="item">
                    <div>
                        <div>{{frequence.valeur}}</div>
                    </div>
                </ng-template>
            </p-dropdown>
        </div>
    </div>




    
    

    <div class="text-right mt-3">

        <button pButton pRipple label="Créer" icon="pi pi-save" class="p-button-primary"
            (click)="confirmer()"></button>

        <button pButton pRipple label="Annuler" icon="pi pi-ban" class="p-button-text" (click)="annuler()"></button>
        
    </div>

</div>