<div class="card">
<div class="grid">
    <div class="col-6">
        

            <h5>Numéro</h5>
            <input #champNumero id="numero" type="text" pInputText [(ngModel)]="numero" (keyup.enter)="Confirmer()" autocomplete="off">
    </div>
</div>
<div class="grid">
    <div class="col-12 text-right ">
        <p-button label="Confirmer" [disabled]="!numero" class="p-button-primary" icon="pi pi-check" iconPos="left" (onClick)="Confirmer()"></p-button>     
        <p-button label="Annuler" class="p-button-text ml-2" icon="pi pi-ban" iconPos="left" (onClick)="Annuler()"></p-button>
    </div>
</div>
</div>