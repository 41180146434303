import {NgModule, LOCALE_ID} from "@angular/core"; import { Location } from "@angular/common";
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HashLocationStrategy, LocationStrategy, PathLocationStrategy, DatePipe} from '@angular/common';
import {AppRoutingModule} from './app-routing.module';
import { APP_INITIALIZER } from "@angular/core";

import {AccordionModule} from 'primeng/accordion';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {AvatarModule} from 'primeng/avatar';
import {AvatarGroupModule} from 'primeng/avatargroup';
import {BadgeModule} from 'primeng/badge';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {ButtonModule} from 'primeng/button';
import {CalendarModule} from 'primeng/calendar';
import {CardModule} from 'primeng/card';
import {CarouselModule} from 'primeng/carousel';
import {CascadeSelectModule} from 'primeng/cascadeselect';
import {ChartModule} from 'primeng/chart';
import {CheckboxModule} from 'primeng/checkbox';
import {ChipModule} from 'primeng/chip';
import {ChipsModule} from 'primeng/chips';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmPopupModule} from 'primeng/confirmpopup';
import {ColorPickerModule} from 'primeng/colorpicker';
import {ContextMenuModule} from 'primeng/contextmenu';
import {DataViewModule} from 'primeng/dataview';
import {DialogModule} from 'primeng/dialog';
import {DynamicDialogModule} from 'primeng/dynamicdialog';
import {DividerModule} from 'primeng/divider';
import {DropdownModule} from 'primeng/dropdown';
import {FieldsetModule} from 'primeng/fieldset';
import {FileUploadModule} from 'primeng/fileupload';
import {GalleriaModule} from 'primeng/galleria';
import {InplaceModule} from 'primeng/inplace';
import {InputNumberModule} from 'primeng/inputnumber';
import {InputMaskModule} from 'primeng/inputmask';
import {InputSwitchModule} from 'primeng/inputswitch';
import {InputTextModule} from 'primeng/inputtext';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {KnobModule} from 'primeng/knob';
import {ListboxModule} from 'primeng/listbox';
import {MegaMenuModule} from 'primeng/megamenu';
import {MenuModule} from 'primeng/menu';
import {MenubarModule} from 'primeng/menubar';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {MultiSelectModule} from 'primeng/multiselect';
import {OrderListModule} from 'primeng/orderlist';
import {OrganizationChartModule} from 'primeng/organizationchart';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {PaginatorModule} from 'primeng/paginator';
import {PanelModule} from 'primeng/panel';
import {PanelMenuModule} from 'primeng/panelmenu';
import {PasswordModule} from 'primeng/password';
import {PickListModule} from 'primeng/picklist';
import {ProgressBarModule} from 'primeng/progressbar';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {RadioButtonModule} from 'primeng/radiobutton';
import {RatingModule} from 'primeng/rating';
import {RippleModule} from 'primeng/ripple';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {ScrollTopModule} from 'primeng/scrolltop';
import {SelectButtonModule} from 'primeng/selectbutton';
import {SidebarModule} from 'primeng/sidebar';
import {SkeletonModule} from 'primeng/skeleton';
import {SlideMenuModule} from 'primeng/slidemenu';
import {SliderModule} from 'primeng/slider';
import {SplitButtonModule} from 'primeng/splitbutton';
import {SplitterModule} from 'primeng/splitter';
import {StepsModule} from 'primeng/steps';
import {TabMenuModule} from 'primeng/tabmenu';
import {TableModule} from 'primeng/table';
import {TabViewModule} from 'primeng/tabview';
import {TagModule} from 'primeng/tag';
import {TerminalModule} from 'primeng/terminal';
import {TieredMenuModule} from 'primeng/tieredmenu';
import {TimelineModule} from 'primeng/timeline';
import {ToastModule} from 'primeng/toast';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {ToolbarModule} from 'primeng/toolbar';
import {TooltipModule} from 'primeng/tooltip';
import {TreeModule} from 'primeng/tree';
import {TreeTableModule} from 'primeng/treetable';
import {VirtualScrollerModule} from 'primeng/virtualscroller';
import {BlockUIModule} from 'primeng/blockui';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; 
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { MomentModule } from 'ngx-moment'; 
import { FloatLabelModule } from 'primeng/floatlabel';
import { ImageModule } from 'primeng/image';
import { EditorModule } from 'primeng/editor';
import { MeterGroupModule } from 'primeng/metergroup';

import {AppComponent} from './app.component';
import {AppMainComponent} from './app.main.component';
import {AppConfigComponent} from './app.config.component';
import {AppMenuComponent} from './app.menu.component';
import {AppMenuitemComponent} from './app.menuitem.component';
import {AppTopBarComponent} from './app.topbar.component';
import {AppSearchComponent} from './app.search.component';
import {AppFooterComponent} from './app.footer.component';
import {AccueilComponent} from './pages/commun/accueil.component';
import {AppHelpComponent} from './pages/commun/app.help.component';
import {AppNotfoundComponent} from './pages/commun/app.notfound.component';
import {AppErrorComponent} from './pages/commun/app.error.component';
import {AppAccessdeniedComponent} from './pages/commun/app.accessdenied.component';
import {AppLoginComponent} from './pages/commun/app.login.component';
import {EnvoiRequisitionComponent} from './controles/envoi-requisition.component';
import {ItemsComponent} from './pages/items/items.component';
import {DetailItemComponent} from './pages/items/detail-item.component';
import { RequisitionsComponent } from './pages/requisitions/requisitions.component';
import { ChoixCategorieComponent } from './pages/commun/choix-categorie.component';

import {BreadcrumbService} from './app.breadcrumb.service';
import {MenuService} from './app.menu.service';
import {MessageService} from 'primeng/api';
import { QuillModule } from 'ngx-quill';

import { DetailRequisitionComponent } from './pages/requisitions/detail-requisition.component';
import { FournisseursComponent } from './pages/achats/fournisseurs.component';
import { DetailFournisseurComponent } from './pages/achats/detail-fournisseur.component';
import { SaisirListeAchatComponent } from './pages/achats/saisir-liste-achat.component';
import { SaisirListeAchatEtape1Component } from './pages/achats/saisir-liste-achat-e1.component';
import { SaisirListeAchatEtape2Component } from './pages/achats/saisir-liste-achat-e2.component';
import { SaisirListeAchatEtape3Component } from './pages/achats/saisir-liste-achat-e3.component';
import { SaisirListeAchatEtape4Component } from './pages/achats/saisir-liste-achat-e4.component';
import { ApprobationListeAchatComponent } from './pages/achats/approbation-liste-achat.component';
import { DetailApprobationListeAchatComponent } from './pages/achats/detail-approbation-liste-achat.component';
import { AchatsComponent } from './pages/achats/achats.component';
import { DetailAchatComponent } from './pages/achats/detail-achat.component';
import { CategoriesComponent } from './pages/pilotage/categories.component';
import { AjoutAchatComponent } from './pages/achats/ajout-achat.component';
import { DetailCategorieComponent } from './pages/pilotage/detail-categorie.component';
import { AjoutRequisitionComponent } from './pages/requisitions/ajout-requisition.component';
import { CommandessComponent } from './pages/requisitions/commandes.component';
import { DetailCommandeComponent } from './pages/requisitions/detail-commande.component';
import { ReparationsComponent } from './pages/items/reparations.component';
import { AnalyseItemComponent } from './controles/analyse-item.component';

import { AuthModule } from '@auth0/auth0-angular';
import { NgxEditorModule } from 'ngx-editor';
import { environment as env } from '../environments/environment';
import { SelectionItemComponent } from './controles/selection-item.component';
import { PrecommandesComponent } from './pages/requisitions/precommandes.component';
import { SaisieQuantiteComponent } from './controles/saisie-quantite.component';
import { DetailPrecommandeComponent } from './pages/requisitions/detail-precommande.component';
import { DetailApprobationPrecommandeComponent } from './pages/requisitions/detail-approbation-precommande.component';
import { ApprobationPrecommandesComponent } from './pages/requisitions/approbation-precommandes.component';
import { SplashScreenStateService } from './services/splash-screen-state.service';
import { SplashScreenComponent } from './controles/splash-screen.component';
import { DonneesBaseService } from './services/donnees-base-service';
import { NomEmployePipe } from './pipes/nom-employe.pipe';
import { EmplacementsComponent } from './pages/pilotage/emplacements.component';
import { DetailEmplacementComponent } from './pages/pilotage/detail-emplacement.component';
import { BoutonFavoriComponent } from './controles/bouton-favori.component';
import { AjustementInventaireComponent } from './controles/ajustement-inventaire.component';
import { ResultatRechercheComponent } from './pages/commun/resultat-recherche.component';
import { NomEmplacementPipe } from './pipes/nom-emplacement.pipe';
import { ModelesListeAchatComponent } from './pages/pilotage/modeles-liste-achat.component';
import { AjoutModeleListeAchatComponent } from './pages/pilotage/ajout-modele-liste-achat.component';
import { DetailModeleListeAchatComponent } from './pages/pilotage/detail-modele-liste-achat.component';
import { CreationCommandeComponent } from './controles/creation-commande.component';
import { AttributsComponent } from './pages/pilotage/attributs.component';
import { TypeChampPipe } from './pipes/type-champ.pipe';
import { DetailAttributComponent } from './pages/pilotage/detail-attribut.component';
import { EnvoiBonCommandeComponent } from './controles/envoi-bon-commande.component';
import { ArchivageComponent } from './controles/archivage.component';
import { ReparationComponent } from './controles/reparation.component';
import { RetourReparationComponent } from './controles/retour-reparation.component';
import { SaisieNumeroComponent } from './controles/saisie-numero.component';
import { GarantiesComponent } from './pages/garanties/garanties.component';
import { NavigationDirecteComponent } from './controles/navigation-directe.component';
import { BoutonTableauComponent } from './controles/bouton-tableau.component';
import { SelectionTransporteurComponent } from './controles/selection-transporteur.component';
import { ConfirmationTransporteurComponent } from './controles/confirmation-transporteurs.component';
import { ConfirmationCreationRequisitionComponent } from './controles/confirmation-creation-requisitions.component';
import { SelectionEmployesComponent } from './controles/selection-employes.component';
import { RapportUtilisationEquipementComponent } from './pages/rapports/rapport-utilisation-equipement.component';
import { SelectionItemSimpleComponent } from './controles/selection-item-simple.component';
import { BoitesOutilComponent } from './pages/pilotage/boites-outil.component';
import { DetailBoiteOutilComponent } from './pages/pilotage/detail-boite-outil.component';
import { SoumissionsComponent } from './pages/soumissions/soumissions.component';
import { DetailSoumissionComponent } from './pages/soumissions/detail-soumission.component';
import { SaisieDetailItemSoumissionComponent } from './controles/saisie-detail-item-soumission.component';
import { AjoutSoumissionComponent } from './pages/soumissions/ajout-soumission.component';
import { SelectionFournisseurComponent } from './controles/selection-fournisseur.component';
import { ConsulterFournisseursComponent } from './controles/consulter-fournisseurs.component';
import { ConfirmationCreationBonCommandeComponent } from './controles/confirmation-creation-bon-commande.component';
import { CreerSerieSoumissionsComponent } from './pages/soumissions/creer-serie.component';
import { AjoutFournisseurComponent } from './pages/achats/ajout-fournisseur.component';
import { SelectionBoiteOutilsComponent } from './controles/selection-boite-outils.component';
import { DetailSerieComponent } from './pages/soumissions/detail-serie.component';
import { EnvoiCourrielComponent } from './pages/soumissions/envoi-courriel.component';
import { SelectionDelaiComponent } from './controles/selection-delai.component';
import { LocationsComponent } from './pages/locations/locations.component';
import { DetailLocationComponent } from './pages/locations/detail-location.component';
import { SelectionCompagnieComponent } from './controles/selection-compagnie.component';
import { SelectionDepartementComponent } from './controles/selection-departement.component';
import { ConfirmerDebutLocationComponent } from './pages/locations/controles/confirmer-debut-location.component';
import { ConfirmerFinLocationComponent } from './pages/locations/controles/confirmer-fin-location.component';
import { InfoBonLocationComponent } from './pages/locations/controles/info-bon-location.component';
import { InitialisationService } from './services/initialisation-service';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthHttpInterceptor } from '@auth0/auth0-angular';
import { ArchiverEmplacementComponent } from './pages/pilotage/archiver-emplacement.component';
import { SelectionItemMultipleComponent } from './controles/selection-item-multiple.component';
import { RapportListeEquipementComponent } from './pages/rapports/rapport-liste-equipement.component';
import { RetourComponent } from './pages/requisitions/retour.component';
import { SelectionRetourComponent } from './controles/selection-retour.component';
import { CreationRetourComponent } from './controles/creation-retour.component';
import { AjoutDocumentComponent } from './pages/documents/ajout-document.component';
import { DetailDocumentComponent } from './pages/documents/controles/detail-document.component';
import { UtilisateursComponent } from './pages/utilisateurs/utilisateurs.component';
import { RapportEntreeSortieComponent } from './pages/rapports/rapport-entree-sortie.component';
import { SelectionEmplacementComponent } from './controles/selection-emplacement.component';
import { ModificationItemBonCommandeComponent } from './pages/achats/controles/modification-item-bon-commande.component';
import { HeuresComponent } from './pages/rapports/heures.component';
import { TransfertPrixComponent } from './pages/soumissions/transfert-prix.component';
import { DetailSousCategorieComponent } from './pages/pilotage/controles/detail-sous-categorie.component';
import { FusionSousCategorieComponent } from './pages/pilotage/controles/fusion-sous-categorie.component';
import { SaisieNoteComponent } from './controles/saisie-note.component';
import { SelectionEmployeComponent } from './controles/selection-employe.component';
import { DetailEmployeComponent } from './pages/admin/detail-employe.component';
import { EmployesComponent } from './pages/admin/employes.component';
import { RechercheAdresseComponent } from './controles/recherche-adresse.component';
import { BonsTravailComponent } from './pages/mecanique/bonsTravail/bons-travail.component';
import { DetailBonTravailComponent } from './pages/mecanique/bonsTravail/detail-bons-travail.component';
import { SaisieNoteMecaniqueComponent } from './controles/mecanique/saisie-note-mecanique.component';
import { SelectionUniteMecaniqueComponent } from './controles/mecanique/selection-unite-mecanique.component';
import { AjoutBonTravailComponent } from './pages/mecanique/bonsTravail/ajout-bon-travail.component';
import { SelectionEtapesComponent } from './pages/mecanique/bonsTravail/controles/selection-etapes.component';
import { SelectionPieceComponent } from './controles/mecanique/selection-piece.component';
import { HistoriqueEntretienComponent } from './controles/mecanique/historique-entretien.component';
import { DetailEntretienCorrectifComponent } from './controles/mecanique/detail-entretien-correctif.component';
import { DetailEntretienPreventifComponent } from './pages/mecanique/entretiens/detail-entretien-preventif.component';
import { SelectionEntretienComponent } from './controles/mecanique/selection-entretien.component';
import { SaisieHeuresComponent } from './pages/mecanique/heures/saisie-heures.component';
import { NotesComponent } from './pages/mecanique/notes/notes.component';
import { PlanificationComponent } from './pages/mecanique/planification/planification.component';
import { AssignerEntretiensComponent } from './pages/mecanique/bonsTravail/controles/assigner-entretiens.component';
import { ListesVerificationComponent } from './pages/mecanique/pilotage/listes-verification.component';
import { DetailListeVerificationComponent } from './pages/mecanique/pilotage/detail-liste-verification.component';
import { AjoutListeVerificationComponent } from './pages/mecanique/pilotage/ajout-liste-verification.component';
import { DetailVadComponent } from './controles/mecanique/detail-vad.component';
import { DetailCommandeDepartementComponent } from './pages/requisitions/detail-commande-departement.component';
import { ValeurVidePipe } from './pipes/valeur-vide.pipe';
import { HistoriquePosePieceComponent } from './controles/mecanique/historique-pose-piece.component';
import { SelectionSimplePieceComponent } from './controles/mecanique/selection-simple-piece.component';
import { DetailPieceUniteComponent } from './controles/mecanique/detail-piece-unite.component';
import { CreationPieceComponent } from './controles/mecanique/creation-piece.component';
import { SelectionMutipleUniteMecaniqueComponent } from './controles/mecanique/selection-multiple-unite-mecanique.component';
import { AjoutFournisseurPieceComponent } from './controles/mecanique/ajout-fournisseur-piece.component';
import { SelectionCategorieEntretienComponent } from './controles/mecanique/selection-categorie-entretiendetail-piece-unite.component';
import { CreationItemComponent } from './controles/creation-item.component';
import { DetailItemListeVerificationComponent } from './pages/mecanique/pilotage/controles/detail-item-liste-verification.component';
import { CategorieEntretienPipe } from './pipes/categorie-entretien.pipe';
import { SelectionBdcComponent } from './controles/selection-bdc.component';
import { ApprobationHeuresComponent } from './pages/mecanique/heures/approbation-heures.component';
import { AiguillageHeuresComponent } from './pages/mecanique/heures/heures.component';
import { AjustementsComponent } from './pages/mecanique/heures/controles/ajustements.component';
import { AjouterAjustementComponent } from './pages/mecanique/heures/controles/ajouter-ajustement.component';
import { ApprouverBonTravailComponent } from './pages/mecanique/bonsTravail/controles/approuver-bon-travail.component';
import { DupliquerSoumissionComponent } from './pages/soumissions/dupliquer-soumission.component';
import { AjoutUniteMecaniqueComponent } from './controles/mecanique/ajout-unite-mecanique.component';
import { ApprobationKmsComponent } from './pages/mecanique/heures/approbation-kms.component';
import { SaisieKmsComponent } from './pages/mecanique/heures/saisie-kms.component';
import { AjouterHeureComponent } from './pages/mecanique/heures/controles/ajouter-heure.component';
import { ModifierFournisseurPieceComponent } from './controles/mecanique/modifier-fournisseur-piece.component';
import { AjouterKmComponent } from './pages/mecanique/heures/controles/ajouter-km.component';
import { SaisieHeuresKmsComponent } from './pages/mecanique/heures/saisie-heures-kms.component';
import { AjouterAjustementKmComponent } from './pages/mecanique/heures/controles/ajouter-ajustement-km.component';
import { AjustementsKmComponent } from './pages/mecanique/heures/controles/ajustements-km.component';
import { RapportEntretiensPEPComponent } from './pages/rapports/rapport-entretiens-pep.component';
import { ConsulterPiecesComponent } from './controles/consulter-pieces.component';
import { DupliquerListeVerificationComponent } from './pages/mecanique/pilotage/controles/dupliquer-liste-verification.component';
import { RapportEntretiensSAAQComponent } from './pages/rapports/rapport-entretiens-saaq.component';
import { SelectionEntretienPreventifComponent } from './controles/mecanique/selection-entretien-preventif.component';
import { EnvoyerApprobationBonTravailComponent } from './pages/mecanique/bonsTravail/controles/envoyer-approbation-bon-travail.component';
import { DetailCommandeTousComponent } from './pages/requisitions/detail-commande-tous.component';
import {ModifierPreCommandeComponent} from "./controles/modifier-pre-commande.component";
import { NonConformiteComponent } from './pages/assurancequalite/non-conformite.component';
import { AjoutNonConformiteComponent } from './pages/assurancequalite/ajout-non-conformite.component';
import { DetailNonConformiteComponent } from './pages/assurancequalite/detail-non-conformite.component';
import { BreadcrumbPipe } from './pipes/breadcrumb.pipe';
import { DocumentComponent } from './pages/documents/document.component';
import { ApprobationComponent} from './pages/assurancequalite/approbation-non-conformite.component';
import { ActionCorrectiveComponent } from './pages/assurancequalite/action-corrective.component';
import { AjoutActionCorrectiveComponent } from './pages/assurancequalite/ajout-action-corrective.component';
import { DetailActionCorrectiveComponent } from './pages/assurancequalite/detail-action-corrective.component';
import { OptionEtapeBonTravailComponent } from './pages/mecanique/bonsTravail/controles/option-etape-bon-travail.component';
import { RapportHeuresLocationComponent } from "./pages/rapports/rapport-heure-location.component";
import { FilConversationComponent } from './controles/fil-conversation.component';  
import { UserInfoComponent } from './controles/avatar.component'
import { PendingChangesGuard } from "./services/pending-changes.guard";
import { AnalyseBonTravailComponent } from "./pages/mecanique/bonsTravail/analyse-bontravail.component";
import { DetailAnalyseBonTravailComponent } from "./pages/mecanique/bonsTravail/detail-analyse-bon-travail.component";
@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        AccordionModule,
        AutoCompleteModule,
        AvatarModule,
        AvatarGroupModule,
        BadgeModule,
        BreadcrumbModule,
        ButtonModule,
        CalendarModule,
        CardModule,
        CarouselModule,
        CascadeSelectModule,
        ChartModule,
        CheckboxModule,
        ChipModule,
        ChipsModule,
        ConfirmDialogModule,
        ConfirmPopupModule,
        ColorPickerModule,
        ContextMenuModule,
        DataViewModule,
        DialogModule,
        DynamicDialogModule,
        DividerModule,
        DropdownModule,
        FieldsetModule,
        FileUploadModule,
        GalleriaModule,
        InplaceModule,
        InputNumberModule,
        InputMaskModule,
        InputSwitchModule,
        InputTextModule,
        InputTextareaModule,
        KnobModule,
        ListboxModule,
        MegaMenuModule,
        MenuModule,
        MenubarModule,
        MessageModule,
        MessagesModule,
        MultiSelectModule,
        OrderListModule,
        OrganizationChartModule,
        OverlayPanelModule,
        PaginatorModule,
        PanelModule,
        PanelMenuModule,
        PasswordModule,
        PickListModule,
        ProgressBarModule,
        ProgressSpinnerModule,
        RadioButtonModule,
        RatingModule,
        RippleModule,
        ScrollPanelModule,
        ScrollTopModule,
        SelectButtonModule,
        SidebarModule,
        SkeletonModule,
        SlideMenuModule,
        SliderModule,
        SplitButtonModule,
        SplitterModule,
        StepsModule,
        TableModule,
        TabMenuModule,
        TabViewModule,
        TagModule,
        TerminalModule,
        TimelineModule,
        TieredMenuModule,
        ToastModule,
        ToggleButtonModule,
        ToolbarModule,
        TooltipModule,
        TreeModule,
        TreeTableModule,
        VirtualScrollerModule,
        BlockUIModule,
        InputGroupModule,
        InputGroupAddonModule,
        FloatLabelModule,
        ImageModule,
        EditorModule,
        NgIdleKeepaliveModule.forRoot(),
        MomentModule,
        QuillModule,
        MeterGroupModule,
        AuthModule.forRoot({
            ...env.auth,
            httpInterceptor: {
                allowedList: [
                    `${env.dev.serverUrl}achat*`,
                    `${env.dev.serverUrl}attribut*`,
                    `${env.dev.serverUrl}conversation*`,
                    `${env.dev.serverUrl}mecanique*`,
                    `${env.dev.serverUrl}boiteoutil*`,
                    `${env.dev.serverUrl}bontravail*`,
                    `${env.dev.serverUrl}categorie*`,
                    `${env.dev.serverUrl}commande*`,
                    `${env.dev.serverUrl}contact*`,
                    `${env.dev.serverUrl}conversion*`,
                    `${env.dev.serverUrl}ctrl*`,
                    `${env.dev.serverUrl}document*`,
                    `${env.dev.serverUrl}donneesbase*`,
                    `${env.dev.serverUrl}emplacement*`,
                    `${env.dev.serverUrl}employe*`,
                    `${env.dev.serverUrl}evenement*`,
                    `${env.dev.serverUrl}fournisseur*`,
                    `${env.dev.serverUrl}garantie*`,
                    `${env.dev.serverUrl}inventaire*`,
                    `${env.dev.serverUrl}item*`,
                    `${env.dev.serverUrl}liaison*`,
                    `${env.dev.serverUrl}listeachat*`,
                    `${env.dev.serverUrl}listeverification*`,
                    `${env.dev.serverUrl}location*`,
                    `${env.dev.serverUrl}note*`,
                    `${env.dev.serverUrl}rapport*`,
                    `${env.dev.serverUrl}recherche*`,
                    `${env.dev.serverUrl}releveheure*`,
                    `${env.dev.serverUrl}relevekm*`,
                    `${env.dev.serverUrl}reparation*`,
                    `${env.dev.serverUrl}requisition*`,
                    `${env.dev.serverUrl}soumission*`,
                    `${env.dev.serverUrl}assurancequalite*`,
                    `${env.dev.serverUrl}nonconformite*`
                ],
              },
          })
    ],
    declarations: [
        AppComponent,
        AppMainComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        AppConfigComponent,
        AppTopBarComponent,
        AppSearchComponent,
        AppFooterComponent,
        AppLoginComponent,
        AppHelpComponent,
        AppNotfoundComponent,
        AppErrorComponent,
        AppAccessdeniedComponent,
        AccueilComponent,
        EnvoiRequisitionComponent,
        ItemsComponent,
        DetailItemComponent,
        RequisitionsComponent,
        DetailRequisitionComponent,
        ChoixCategorieComponent,
        FournisseursComponent,
        DetailFournisseurComponent,
        ModelesListeAchatComponent,
        DetailModeleListeAchatComponent,
        SaisirListeAchatComponent,
        SaisirListeAchatEtape1Component,
        SaisirListeAchatEtape2Component,
        SaisirListeAchatEtape3Component,
        SaisirListeAchatEtape4Component,
        ApprobationListeAchatComponent,
        DetailApprobationListeAchatComponent,
        AchatsComponent,
        DetailAchatComponent,
        CategoriesComponent,
        AjoutAchatComponent,
        DetailCategorieComponent,
        AjoutRequisitionComponent,
        CommandessComponent,
        DetailCommandeComponent,
        ReparationsComponent,
        AnalyseItemComponent,
        SelectionItemComponent,
        PrecommandesComponent,
        SaisieQuantiteComponent,
        ModifierPreCommandeComponent,
        DetailPrecommandeComponent,
        ApprobationPrecommandesComponent,
        DetailApprobationPrecommandeComponent,
        SplashScreenComponent,
        EmplacementsComponent,
        DetailEmplacementComponent,
        BoutonFavoriComponent,
        NomEmployePipe,
        NomEmplacementPipe,
        ResultatRechercheComponent,
        AjustementInventaireComponent,
        AjoutModeleListeAchatComponent,
        CreationCommandeComponent,
        AttributsComponent,
        TypeChampPipe,
        DetailAttributComponent,
        EnvoiBonCommandeComponent,
        ArchivageComponent,
        ReparationComponent,
        RetourReparationComponent,
        SaisieNumeroComponent,
        GarantiesComponent,
        NavigationDirecteComponent,
        BoutonTableauComponent,
        SelectionTransporteurComponent,
        ConfirmationTransporteurComponent,
        ConfirmationCreationRequisitionComponent,
        SelectionEmployesComponent,
        RapportUtilisationEquipementComponent,
        SelectionItemSimpleComponent,
        BoitesOutilComponent,
        DetailBoiteOutilComponent,
        SoumissionsComponent,
        DetailSoumissionComponent,
        SaisieDetailItemSoumissionComponent,
        AjoutSoumissionComponent,
        SelectionFournisseurComponent,
        ConsulterFournisseursComponent,
        ConfirmationCreationBonCommandeComponent,
        CreerSerieSoumissionsComponent,
        AjoutFournisseurComponent,
        SelectionBoiteOutilsComponent,
        DetailSerieComponent,
        EnvoiCourrielComponent,
        SelectionDelaiComponent,
        LocationsComponent,
        // AjoutLocationComponent,
        DetailLocationComponent,
        SelectionCompagnieComponent,
        SelectionDepartementComponent,
        ConfirmerDebutLocationComponent,
        ConfirmerFinLocationComponent,
        InfoBonLocationComponent,
        ArchiverEmplacementComponent,
        SelectionItemMultipleComponent,
        RapportListeEquipementComponent,
        RetourComponent,
        SelectionRetourComponent,
        CreationRetourComponent,
        AjoutDocumentComponent,
        DetailDocumentComponent,
        UtilisateursComponent,
        RapportEntreeSortieComponent,
        SelectionEmplacementComponent,
        ModificationItemBonCommandeComponent,
        HeuresComponent,
        TransfertPrixComponent,
        DetailSousCategorieComponent,
        FusionSousCategorieComponent,
        SaisieNoteComponent,
        SelectionEmployeComponent,
        EmployesComponent,
        DetailEmployeComponent,
        RechercheAdresseComponent,
        BonsTravailComponent,
        DetailBonTravailComponent,
        SaisieNoteMecaniqueComponent,
        SelectionUniteMecaniqueComponent,
        AjoutBonTravailComponent,
        SelectionEtapesComponent,
        SelectionPieceComponent,
        HistoriqueEntretienComponent,
        DetailEntretienCorrectifComponent,
        DetailEntretienPreventifComponent,
        SelectionEntretienComponent,
        SaisieHeuresComponent,
        NotesComponent,
        PlanificationComponent,
        AssignerEntretiensComponent,
        ListesVerificationComponent,
        DetailListeVerificationComponent,
        AjoutListeVerificationComponent,
        DetailVadComponent,
        DetailCommandeDepartementComponent,
        ValeurVidePipe,
        HistoriquePosePieceComponent,
        SelectionSimplePieceComponent,
        DetailPieceUniteComponent,
        CreationPieceComponent,
        SelectionMutipleUniteMecaniqueComponent,
        AjoutFournisseurPieceComponent,
        SelectionCategorieEntretienComponent,
        CreationItemComponent,
        DetailItemListeVerificationComponent,
        CategorieEntretienPipe,
        SelectionBdcComponent,
        ApprobationHeuresComponent,
        AiguillageHeuresComponent,
        AjustementsComponent,
        AjouterAjustementComponent,
        ApprouverBonTravailComponent,
        DupliquerSoumissionComponent,
        AjoutUniteMecaniqueComponent,
        ApprobationKmsComponent,
        SaisieKmsComponent,
        AjouterHeureComponent,
        ModifierFournisseurPieceComponent,
        AjouterKmComponent,
        SaisieHeuresKmsComponent,
        AjouterAjustementKmComponent,
        AjustementsKmComponent,
        RapportEntretiensPEPComponent,
        RapportEntretiensSAAQComponent,
        ConsulterPiecesComponent,
        DupliquerListeVerificationComponent,
        SelectionEntretienPreventifComponent,
        EnvoyerApprobationBonTravailComponent,
        DetailCommandeTousComponent,
        NonConformiteComponent,
        AjoutNonConformiteComponent,
        DetailNonConformiteComponent,
        BreadcrumbPipe,
        DocumentComponent,
        ApprobationComponent,
        ActionCorrectiveComponent,
        AjoutActionCorrectiveComponent,
        DetailActionCorrectiveComponent,
        OptionEtapeBonTravailComponent,
        DetailActionCorrectiveComponent,
        RapportHeuresLocationComponent,
        FilConversationComponent,
        UserInfoComponent,
        AnalyseBonTravailComponent,
        DetailAnalyseBonTravailComponent
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: resourceProviderFactory,
            deps: [InitialisationService],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthHttpInterceptor,
            multi: true,
        },
        {provide: LocationStrategy, useClass: PathLocationStrategy},
        MenuService, 
        BreadcrumbService, 
        SplashScreenStateService, 
        DatePipe, 
        BreadcrumbPipe,
        MessageService,
        PendingChangesGuard
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}

export function resourceProviderFactory(provider: InitialisationService) {
    var p = provider.initialiser();
    return () => p
    }
