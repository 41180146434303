import { Component, OnInit, ViewChild, Input, ɵConsole, ElementRef } from "@angular/core"; import { Location } from "@angular/common";
import { Table } from 'primeng/table';
import { BreadcrumbService } from '../../app.breadcrumb.service';

import { RequisitionMin } from '../../entites/requisition-min';
import { MenuItem } from 'primeng/api/menuitem';
import { MessageService, LazyLoadEvent, ConfirmationService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

import { DialogService } from 'primeng/dynamicdialog';
import { EnvoiRequisitionComponent } from '../../controles/envoi-requisition.component';
import { ItemMin } from '../../entites/item-min';
import { ItemService } from '../../services/item-service';
import { Router, ActivatedRoute } from '@angular/router';
import { Statut } from 'src/app/entites/statut';
import { DefinitionColonne } from 'src/app/entites/definition-colonne';
import { CategorieMin, CategorieEntite } from 'src/app/entites/categorie';
import { BaseComponent } from 'src/app/base/base-component';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { SousCategorieEntite } from 'src/app/entites/sousCategorie';
import { Emplacement } from 'src/app/entites/emplacement';
import { EmplacementService } from 'src/app/services/emplacement-service';
import { Item } from 'src/app/entites/item';
import { ProfilService } from 'src/app/services/profil-service';
import { AppMainComponent } from 'src/app/app.main.component';
import { SpawnSyncOptionsWithBufferEncoding } from 'child_process';
import { CompagnieEntite } from 'src/app/entites/compagnie';
import { LocalisationEntrepotEntite } from 'src/app/entites/localisation-entrepot';
import { TypeFacturationEntite } from 'src/app/entites/type-facturation';
import { RapportService } from 'src/app/services/rapport-service';
import { AttributEntite } from 'src/app/entites/attribut';
import { EStatuts } from 'src/app/entites/enums/statuts';
import { EStatutsSpeciaux } from 'src/app/entites/enums/statuts-speciaux';
import { ContexteService } from 'src/app/services/contexte-service';
import { CreationPieceComponent, ResultatCreationPiece } from 'src/app/controles/mecanique/creation-piece.component';
import { ContextMenu } from 'primeng/contextmenu';
import { AjoutUniteMecaniqueComponent } from 'src/app/controles/mecanique/ajout-unite-mecanique.component';


@Component({
    templateUrl: './items.component.html',
    styleUrls: ['./items.component.scss'],
    providers: [MessageService, ConfirmationService, DialogService]
})
export class ItemsComponent extends BaseComponent implements OnInit {

    items: ItemMin[];
    //selectionItem: ItemMin;

    detailItemId: number;
    afficherDetailItem: boolean = false;

    attributs: AttributEntite[];

    filtreCategories: string[];
    filtreSousCategories: string[];
    filtreCompagnies: string[];
    filtreEmplacement: string[];

    statuts: Statut[] = this.sdb.Statuts();

    dialogueNouvelItem = false;
    submitted = false;
    nouvelItem: Item;
    listeCategories: CategorieMin[];
    listeSousCategoriesComplet: SousCategorieEntite[];
    listeSousCategories: SousCategorieEntite[];
    emplacements: Emplacement[];
    selectionEmplacement: Emplacement;
    listeCompagnies: CompagnieEntite[];
    listeLocalisations: LocalisationEntrepotEntite[];
    listeTypesFacturation: TypeFacturationEntite[];

    descriptions: string[];
    suggestionDescription: string[];

    marques: string[];
    suggestionMarque: string[];

    contenuMenuContextuelAjout: MenuItem[];
    @ViewChild('menuContextuelAjout') menuContextuelAjout: ContextMenu;
    contenuMenuContextuelItem: MenuItem[];
    @ViewChild('menuContextuelItem') menuContextuelItem: ContextMenu;

    @ViewChild('rechercheItem') inputRechercheItem;
    @ViewChild('descriptionNouvelItem') champDescriptionNouvelItem: ElementRef;
    @ViewChild('champDescription') champDescription;
    @ViewChild('champNumero') champNumero;
    @ViewChild('dt') tableItems: Table;

    statutId: number;

    contientItemsIncomplets: boolean = false;

    activerPagination: boolean = true;

    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute,
        appMain: AppMainComponent, location: Location, 
        private breadcrumbService: BreadcrumbService,
        private emplacementService: EmplacementService,
        private ItemsService: ItemService,
        private contexteService: ContexteService,
        private messageService: MessageService,
        public rapportService: RapportService,
        private confirmationService: ConfirmationService,
        public dialogService: DialogService,
        public routerService: Router) {
        super(sdb, profilService, route, routerService, appMain);
        this.breadcrumbService.setItems([
            { label: 'Items' }
        ]);

        this.route.data.subscribe(x => {
            if (x.statutId && !isNaN(+x.statutId)) {
                this.statutId = x.statutId;

                if (this.statutId == EStatutsSpeciaux.UnitesMecanique) {
                    this.activerPagination = false;
                }
            }

        });

        /* this.route.params.subscribe(x => {
            if (x.filtre && !isNaN(+x.filtre)) {
                this.statutId = x.filtre;

                if (this.statutId == EStatutsSpeciaux.UnitesMecanique) {
                    this.activerPagination = false;
                }
            }

        }); */
    }

    emplacementCharge = true;


    // surChangementColonnes(event) {



    //     if (this.EstColonneSelectionnee('emplacement') && !this.emplacementCharge) {
    //         setTimeout(() => {
    //             this.items.forEach(x => {
    //                 //x.emplacement = 'Micoua-Saguenay (HC220-133)';
    //             });
    //             this.emplacementCharge = true;
    //         }, 4000);
    //     }
    // }

    ngOnInit() {

        

        this.verifierAccesPage('items');


        this.attributs = this.sdb.Attributs();

        if (this.profilService.Profil().roles.filter(x => x.nom == 'SIHC_ContremaitreMecanique').length > 0) {
            this.colonnesDisponibles = [
                { base: true, champ: 'categorie', entete: 'Catégorie', afficheParDefaut: true },
                { base: true, champ: 'sousCategorie', entete: 'Sous-catégorie', afficheParDefaut: true },
                { base: true, champ: 'marque', entete: 'Marque', afficheParDefaut: true },
                { base: true, champ: 'modele', entete: 'Modèle', afficheParDefaut: true },
                { base: true, champ: 'noProduit', entete: 'No pièce fournisseur', afficheParDefaut: true },
                { base: true, champ: 'noCtrl', entete: 'No Ctrl', afficheParDefaut: false },
                { base: true, champ: 'emplacement', entete: 'Emplacement', afficheParDefaut: true },
                { base: true, champ: 'compagnie', entete: 'Compagnie', afficheParDefaut: false }
            ];
        } else {
            this.colonnesDisponibles = [
                { base: true, champ: 'categorie', entete: 'Catégorie', afficheParDefaut: true },
                { base: true, champ: 'sousCategorie', entete: 'Sous-catégorie', afficheParDefaut: true },
                { base: true, champ: 'marque', entete: 'Marque', afficheParDefaut: true },
                { base: true, champ: 'modele', entete: 'Modèle', afficheParDefaut: true },
                { base: true, champ: 'noProduit', entete: 'No pièce fournisseur', afficheParDefaut: false },
                { base: true, champ: 'noCtrl', entete: 'No Ctrl', afficheParDefaut: false },
                { base: true, champ: 'emplacement', entete: 'Emplacement', afficheParDefaut: true },
                { base: true, champ: 'compagnie', entete: 'Compagnie', afficheParDefaut: false }
            ];
        }

        
        this.attributs.forEach(att => {
            this.colonnesDisponibles.push({
                base: false,
                champ: 'att' + att.attributId,
                entete: att.nom,
                afficheParDefaut: false
            })
        });
        this.colonnesSelectionnees = this.colonnesDisponibles.filter(x => x.afficheParDefaut);

        this.listeCategories = this.sdb.Categories();
        this.filtreCategories = [];
        this.listeCategories.forEach(x => {
            this.filtreCategories.push(x.nom);
        });


        this.listeSousCategoriesComplet = this.sdb.SousCategories();
        this.listeSousCategories = this.listeSousCategoriesComplet;
        this.filtreSousCategories = [];
        this.listeSousCategories.forEach(x => {
            this.filtreSousCategories.push(x.nom);
        });


        this.emplacements = this.sdb.Emplacements();
        this.filtreEmplacement = [];

        this.descriptions = this.sdb.Descriptions();
        this.marques = this.sdb.Marques();

        this.listeCompagnies = this.sdb.Compagnies();
        this.filtreCompagnies = [];
        this.listeCompagnies.forEach(x => {
            this.filtreCompagnies.push(x.nom);
        });

        this.listeLocalisations = this.sdb.LocalisationsEntrepot();
        this.listeTypesFacturation = this.sdb.TypesFacturation();


        if (this.statutId) {

            this.ItemsService.ObtenirItemsParStatut(this.statutId).then(x => {
                this.items = x.valeur;
                this.CompleterChargement();
                setTimeout(() => { this.inputRechercheItem.nativeElement.focus(); }, 100);
            });

        } else {

            this.ItemsService.ObtenirItems().then(x => {
                this.items = x.valeur;
                this.CompleterChargement();
                setTimeout(() => { this.inputRechercheItem.nativeElement.focus(); }, 100);
            });

        }




    }

    clearFilters(table: Table) {
        if (this.statutId == 2) {
            this.routerService.navigateByUrl('/', { skipLocationChange: true }).then(() =>
                this.routerService.navigate(['items2/2']));
        } else if (this.statutId == 15) {
            this.routerService.navigateByUrl('/', { skipLocationChange: true }).then(() =>
                this.routerService.navigate(['items/15']));
        } else {
            this.routerService.navigateByUrl('/', { skipLocationChange: true }).then(() =>
                this.routerService.navigate(['items']));
        }

    }

    ObtenirIdEmplacements(value: Emplacement[]) {
        var temp = [];
        value.forEach(x => temp.push(x.emplacementId));
        return temp;
    }

    rechercherDescritpion(event) {
        //this.suggestionsFiltrees = this.suggestionsDescription.filter(x => x.toLowerCase().startsWith(this.nouvelItem.description.toLowerCase()));
        this.suggestionDescription = this.descriptions.filter(x => this.nouvelItem.description.localeCompare(x.substring(0, this.nouvelItem.description.length), 'en', { sensitivity: 'base' }) == 0);
    }

    rechercherMarque(event) {
        this.suggestionMarque = this.marques.filter(x => this.nouvelItem.marque.localeCompare(x.substring(0, this.nouvelItem.marque.length), 'en', { sensitivity: 'base' }) == 0);
    }

    surChangementSousCategorie(event) {

    }

    surFermetureSousCategorie(event) {
        if (this.nouvelItem.sousCategorie && this.nouvelItem.sousCategorie.prefixe) {
            this.Bloquer();

            this.ItemsService.ObtenirProchainNumero(this.nouvelItem.sousCategorie.prefixe).then(x => {
                this.Debloquer()
                this.nouvelItem.numero = this.nouvelItem.sousCategorie.prefixe + x.valeur;


                setTimeout(() => { this.champDescription.focusInput(); }, 100);
            });

        } else {
            setTimeout(() => { this.champNumero.nativeElement.focus(); }, 100);
        }
    }

    surEntreeRecherche() {

        if (this.tableItems.filteredValue != undefined &&
            this.tableItems.filteredValue.length == 1) {
            this.OuvrirDetailItem(this.tableItems.filteredValue[0].itemId);
        }

    }

    ItemsNonComplets(event: Event) {
        this.confirmationService.confirm({
            key: "itemsnc",
            target: event.target,
            message: 'Il y a des items à compléter. Voulez-vous voir la liste maintenant?',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.routerService.navigateByUrl('items/15');
            },
            reject: () => {
                //reject action
            }
        });
    }

    OuvrirDetailItem(itemId: number) {

        if (this.statutId && this.statutId == EStatutsSpeciaux.UnitesMecanique) {
            this.contexteService.Ajouter("tab", "7");
            this.contexteService.Ajouter("origine", "U");
        }
        else if (this.statutId && this.statutId == EStatutsSpeciaux.PiecesMecanique) {
            this.contexteService.Ajouter("origine", "P");
        }
        else {
            this.contexteService.Ajouter("origine", "I");
        }
        this.routerService.navigateByUrl(`item/${itemId}`)
    }

    OuvrirDetailItemNouvelOnglet(itemId: number) {
        this.detailItemId = itemId;
        this.afficherDetailItem = true;
    }

    surBoutonAjout(event) {

        if (this.statutId == EStatutsSpeciaux.PiecesMecanique) {

            this.AjouterPiece();

        } else if (this.statutId == EStatutsSpeciaux.UnitesMecanique) {

            const ref = this.dialogService.open(AjoutUniteMecaniqueComponent, {
                header: 'Ajouter une unité',
                width: '90%'
            }).onClose.subscribe((item: Item) => {
                if (item) {
                    this.contexteService.Ajouter("tab", "7");
                    this.routerService.navigateByUrl('item/' + item.itemId);
                }
    
    
            });

        } else {

            this.contenuMenuContextuelAjout = [];

            this.listeCategories.forEach(c => {
                this.contenuMenuContextuelAjout.push({
                    label: c.nom,
                    icon: 'pi pi-fw pi-tag',
                    command: () => this.AjouterItem(c)
                })
            });

            this.menuContextuelAjout.toggle(event);


        }
    }

    AjouterItem(c: CategorieMin) {
        if (c.categorieId == 6) {
            this.AjouterPiece();
        } else {
            this.nouvelItem = {
                categorie: c,
                emplacement: this.emplacements.find(x => x.emplacementId == 10000) // TODO : Hard-code : 10000
            };
            if (this.listeCompagnies.length == 1) {
                this.nouvelItem.compagnie = this.listeCompagnies[0];
            }
            this.surSelectionCategorie();
            this.dialogueNouvelItem = true;
        }

    }

    AjouterPiece() {
        const ref = this.dialogService.open(CreationPieceComponent, {
            data: {
            },
            header: 'Ajout d\'une pièce mécanique',
            width: '90%'
        }).onClose.subscribe((res: ResultatCreationPiece) => {
            if (res) {
                this.naviguer('item/' + res.item.itemId);
            }
        });
    }

    surMenuContextuelItem(event, item: ItemMin) {
        this.contenuMenuContextuelItem = [
            {
                label: 'Dupliquer',
                icon: 'pi pi-fw pi-copy',
                command: () => this.dupliquer(item)
            }
        ];
        this.menuContextuelItem.toggle(event);
    }

    dupliquer(item: ItemMin) {
        if (item.categorie.categorieId == 6) {
            const ref = this.dialogService.open(CreationPieceComponent, {
                data: {
                    description: item.description,
                    marque: item.marque,
                    modele: item.modele
                },
                header: 'Ajout d\'une pièce mécanique',
                width: '90%'
            }).onClose.subscribe((res: ResultatCreationPiece) => {
                if (res) {
                    this.naviguer('item/' + res.item.itemId);
                }
            });
        } else {
            this.nouvelItem = {
                categorie: item.categorie,
                description: item.description,
                marque: item.marque,
                modele: item.modele,
                emplacement: this.emplacements.find(x => x.emplacementId == 10000) // TODO : Hard-code : 10000
            };
            // if (item.sousCategorie) {
            //     this.nouvelItem.sousCategorie = this.listeSousCategories.find(x => x.sousCategorieId = item.sousCategorie.sousCategorieId);
            // }
            if (item.localisation) {
                this.nouvelItem.localisation = this.listeLocalisations.find(x => x.localisationEntrepotEntiteId = item.localisation.localisationEntrepotEntiteId);
            }
            this.dialogueNouvelItem = true;
        }
    }

    obtenirProchainNumero() {

        if (this.nouvelItem.numero.length > 0) {
            this.ItemsService.ObtenirProchainNumero(this.nouvelItem.numero).then(x => {
                this.nouvelItem.numero = this.nouvelItem.numero + x.valeur;
            });
        }
    }

    creerNouvelItem() {

        //this.dialogueNouvelItem = false;
        //this.routerService.navigateByUrl('item/595');
        this.submitted = true;
        this.messageService.clear("nouvelItem");

        if (this.validerNouvelItem()) {

            this.ItemsService.CreerItem(this.nouvelItem).then(x => {
                if (x.estUnSucces) {
                    this.routerService.navigateByUrl('item/' + x.valeur.itemId);
                } else {
                    x.messages.forEach(x => {
                        this.messageService.add({ key: "nouvelItem", severity: 'error', summary: 'Erreur', detail: x.description, sticky: true });
                    });

                }

            });


        }
    }

    validerNouvelItem(): boolean {


        if (!this.nouvelItem.categorie) {
            return false;
        }

        if (this.nouvelItem.categorie.estUnique &&
            (!this.nouvelItem.numero || !this.nouvelItem.description || !this.nouvelItem.emplacement || !this.nouvelItem.compagnie)) {
            return false;
        }

        return true;
    }

    annulerNouvelItem() {
        this.dialogueNouvelItem = false;
    }

    surSelectionCategorie() {
        this.listeSousCategories = this.listeSousCategoriesComplet.filter(x => x.categorieId == this.nouvelItem.categorie.categorieId);
    }

    
    obtenirValeurAttribut(item: Item, attributId: string): string {
        var attId = Number(attributId.replace('att', ''));
        var att = item.valeursAttributs.find(x => x.attribut.attributId == attId);
        if (att) {
            return att.valeur;
        } else {
            return '';
        }

    }

    exportExcel() {

        var t = this.tableItems.value;



        if (this.tableItems.filteredValue) {
            t = this.tableItems.filteredValue;
        }

        var lignes: any[] = t.map(x => ({
            Numéro: x.numero,
            Description: x.description,
            Statut: x.statut.nom
        }));

        for (let index = 0; index < lignes.length; index++) {
            if (this.colonnesSelectionnees.filter(x => x.champ == 'categorie').length == 1) {
                if (t[index].categorie && t[index].categorie.nom) {
                    lignes[index].Categorie = t[index].categorie?.nom;
                } else {
                    lignes[index].Categorie = '';
                }
                lignes[index].Categorie = t[index].categorie?.nom;
            }
            if (this.colonnesSelectionnees.filter(x => x.champ == 'sousCategorie').length == 1) {
                if (t[index].sousCategorie && t[index].sousCategorie.nom) {
                    lignes[index].SousCategorie = t[index].sousCategorie?.nom;
                } else {
                    lignes[index].SousCategorie = '';
                }
            }
            if (this.colonnesSelectionnees.filter(x => x.champ == 'marque').length == 1) {
                if (t[index].marque) {
                    lignes[index].Marque = t[index].marque;
                } else {
                    lignes[index].Marque = '';
                }
            }
            if (this.colonnesSelectionnees.filter(x => x.champ == 'modele').length == 1) {
                if (t[index].modele) {
                    lignes[index].Modele = t[index].modele;
                } else {
                    lignes[index].Modele = '';
                }
            }
            if (this.colonnesSelectionnees.filter(x => x.champ == 'noCtrl').length == 1) {
                if (t[index].noCtrl) {
                    lignes[index].NoCtrl = t[index].noCtrl;
                } else {
                    lignes[index].NoCtrl = '';
                }
            }
            if (this.colonnesSelectionnees.filter(x => x.champ == 'emplacement').length == 1) {
                if (t[index].emplacement && t[index].emplacement.nom) {
                    lignes[index].Emplacement = t[index].emplacement?.nom;
                } else {
                    lignes[index].Emplacement = '';
                }
            }
            if (this.colonnesSelectionnees.filter(x => x.champ == 'compagnie').length == 1) {
                if (t[index].compagnie && t[index].compagnie.nom) {
                    lignes[index].Compagnie = t[index].compagnie?.nom;
                } else {
                    lignes[index].Compagnie = '';
                }
            }

            this.attributs.forEach(att => {

                if (this.colonnesSelectionnees.filter(x => x.champ == 'att' + att.attributId).length == 1) {

                    var a = t[index].valeursAttributs.find(x => x.attribut.attributId == att.attributId);

                    if (a) {
                        lignes[index][att.nom] = a.valeur;
                    } else {
                        lignes[index][att.nom] = '';
                    }
                }
            });

        }



        this.messageService.add({ severity: 'info', summary: 'Demande de rapport', detail: `Votre demande de rapport a été envoyée.` });

        this.rapportService.ProduireRapportExcel({
            contenu: JSON.stringify(lignes)
        }).then(x => {
            if (x) {
                this.messageService.add({ severity: 'success', summary: 'Succès', detail: `Votre rapport a été généré.` });
            } else {
                this.messageService.add({ severity: 'error', summary: 'Erreur', detail: `Une erreur est survenue pendant la génération de votre rapport.` });
            }
        });


    }


}