import { Component, OnInit, ViewChild, Input, ɵConsole, ElementRef } from "@angular/core"; import { Location } from "@angular/common";
import { Table } from 'primeng/table';
import { BreadcrumbService } from '../../app.breadcrumb.service';

import { RequisitionMin } from '../../entites/requisition-min';
import { MenuItem } from 'primeng/api/menuitem';
import { MessageService, LazyLoadEvent, ConfirmationService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

import { DialogService } from 'primeng/dynamicdialog';
import { EnvoiRequisitionComponent } from '../../controles/envoi-requisition.component';
import { ItemMin } from '../../entites/item-min';
import { ItemService } from '../../services/item-service';
import { Router, ActivatedRoute } from '@angular/router';
import { ReparationEntite } from 'src/app/entites/reparation';
import { BaseComponent } from 'src/app/base/base-component';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { ContextMenu } from 'primeng/contextmenu/public_api';
import { ProfilService } from 'src/app/services/profil-service';
import { RetourReparationComponent } from 'src/app/controles/retour-reparation.component';
import { AppMainComponent } from 'src/app/app.main.component';
import { GarantieEntite, TypeGarantieEntite } from 'src/app/entites/garantie';
import { GarantieService } from 'src/app/services/garantie-service';
import { environment } from 'src/environments/environment';
import { Emplacement } from 'src/app/entites/emplacement';
import { RapportService } from 'src/app/services/rapport-service';

@Component({
    templateUrl: './garanties.component.html',
    styleUrls: ['./garanties.component.scss'],
    providers: [MessageService, ConfirmationService, DialogService]
})
export class GarantiesComponent extends BaseComponent implements OnInit {

    itemsComplet: GarantieEntite[];
    items: GarantieEntite[];
    selectionItem: GarantieEntite;

    types: TypeGarantieEntite[];
    type: TypeGarantieEntite;
    emplacements: Emplacement[];
    emplacement: Emplacement;

    menuContextuelItems: MenuItem[];

    seuilRouge = environment.parametres.garanties.seuilRouge;
    seuilJaune = environment.parametres.garanties.seuilJaune;

    filtreEmplacement: string[];

    @ViewChild('dt') table: Table;
    @ViewChild('menuContxtuelItem') menuContxtuelItem: ContextMenu;

    constructor(
        public sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute, 
		appMain: AppMainComponent, location: Location, 
        private breadcrumbService: BreadcrumbService,
        private ItemsService: ItemService,
        private garantieService: GarantieService,
        private messageService: MessageService,
        private rapportService: RapportService,
        private confirmationService: ConfirmationService,
        public dialogService: DialogService,
        public routerService: Router) {
        super(sdb, profilService, route, routerService, appMain);
        this.breadcrumbService.setItems([
            { label: 'Garanties' }
        ]);
    }


    ngOnInit() { 

        this.colonnesDisponibles = [
            { champ: 'categorie', entete: 'Catégorie', afficheParDefaut: false },
            { champ: 'sousCategorie', entete: 'Sous-catégorie', afficheParDefaut: false },
            { champ: 'marque', entete: 'Marque', afficheParDefaut: false },
            { champ: 'modele', entete: 'Modèle', afficheParDefaut: false },
            { champ: 'noTag', entete: 'No Tag', afficheParDefaut: false },
            { champ: 'noSerie', entete: 'No Série', afficheParDefaut: false },
        ];

        this.colonnesSelectionnees = this.colonnesDisponibles.filter(x => x.afficheParDefaut);

        
        this.types = this.sdb.TypesGarantie();
        this.emplacements = this.sdb.Emplacements();

        this.filtreEmplacement = [];
        this.sdb.Emplacements().forEach(x => {
            this.filtreEmplacement.push(x.nom);
        });

        this.garantieService.ObtenirListe().then(x => {
            this.itemsComplet = x.valeur;
            this.filtrer();
            this.CompleterChargement();
        });

        this.menuContextuelItems = [
            
            { label: 'Voir l\'item', icon: 'pi pi-fw pi-id-card', command: () => this.routerService.navigateByUrl(`item/${this.selectionItem.item.itemId}`) }
        ];


    }
    
    surMenuContextuelItem(event, garantie: GarantieEntite) {
        this.selectionItem = garantie;
        this.menuContxtuelItem.toggle(event);
    }

    
    filtrer() {
        this.Bloquer();
           this.items = this.itemsComplet;
           if (this.type) {
               this.items = this.items.filter(x => x.type?.typeGarantieId == this.type.typeGarantieId);
           }
           if (this.emplacement) {
            this.items = this.items.filter(x => x.item.emplacement.emplacementId == this.emplacement.emplacementId);
            }
        
        this.Debloquer();
    }

    retirerFiltres() {
        this.items = this.itemsComplet;
        this.type = null;
        this.emplacement = null;
    }

    exportExcel() {

        var t = this.table.value;
        
        if (this.table.filteredValue) {
            t = this.table.filteredValue;
        }

        var lignes: any[] = t.map(x => ({
            NumeroItem: x.item.numero ? x.item.numero : '',
            DescriptionItem: x.item.description ? x.item.description : '',
            Type: x.type?.nom ? x.type?.nom : '',
            Statut: x.statut.nom ? x.statut.nom : '',
            Description: x.description ? x.description : '',
            DateFin: x.dateFin ? x.dateFin : '',
            NbJoursRestants: x.nbJoursRestants ? x.nbJoursRestants : '',
            Emplacement: x.item.emplacement?.nom ? x.item.emplacement?.nom : ''
          }));

          for (let index = 0; index < lignes.length; index++) {


            // if (t[index].item.numero){
            //     lignes[index].NumeroItem = t[index].item.numero;
            // } else{
            //     lignes[index].NumeroItem = '';
            // }

              if (this.colonnesSelectionnees.filter(x => x.champ == 'categorie').length == 1) {
                if (t[index].item.categorie && t[index].item.categorie.nom){
                    lignes[index].Categorie = t[index].item.categorie?.nom;
                } else{
                    lignes[index].Categorie = '';
                }
                lignes[index].Categorie = t[index].categorie?.nom;
              }
              if (this.colonnesSelectionnees.filter(x => x.champ == 'sousCategorie').length == 1) {
                if (t[index].item.sousCategorie && t[index].item.sousCategorie.nom){
                    lignes[index].SousCategorie = t[index].item.sousCategorie?.nom;
                } else{
                    lignes[index].SousCategorie = '';
                }
              }
              if (this.colonnesSelectionnees.filter(x => x.champ == 'marque').length == 1) {
                if (t[index].item.marque){
                    lignes[index].Marque = t[index].item.marque;
                } else{
                    lignes[index].Marque = '';
                }
              }
              if (this.colonnesSelectionnees.filter(x => x.champ == 'modele').length == 1) {
                if (t[index].item.modele){
                    lignes[index].Modele = t[index].item.modele;
                } else{
                    lignes[index].Modele = '';
                }
              }
              if (this.colonnesSelectionnees.filter(x => x.champ == 'noTag').length == 1) {
                if (t[index].item && t[index].item.noTag){
                    lignes[index].NoTag = t[index].item.noTag;
                } else{
                    lignes[index].NoTag = '';
                }
              }
              if (this.colonnesSelectionnees.filter(x => x.champ == 'noSerie').length == 1) {
                if (t[index].item && t[index].item.noSerie){
                    lignes[index].NoSerie = t[index].item.noSerie;
                } else{
                    lignes[index].NoSerie = '';
                }
              }
          }



          this.messageService.add({ severity: 'info', summary: 'Demande de rapport', detail: `Votre demande de rapport a été envoyée.` });

          this.rapportService.ProduireRapportExcel({
            contenu: JSON.stringify(lignes)
        }).then(x => {
            if (x) {
                this.messageService.add({ severity: 'success', summary: 'Succès', detail: `Votre rapport a été généré.` });
            } else {
                this.messageService.add({ severity: 'error', summary: 'Erreur', detail: `Une erreur est survenue pendant la génération de votre rapport.` });
            }
        }); 
          

    }

}
