import { Component } from "@angular/core"; import { Location } from "@angular/common";
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { EmployeEntite } from 'src/app/entites/employe';
import { BaseComponent } from 'src/app/base/base-component';
import { ProfilService } from 'src/app/services/profil-service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppMainComponent } from 'src/app/app.main.component';
import { CleValeur } from 'src/app/entites/cleValeur';
import { BonTravailService } from 'src/app/services/bon-travail-service';
import { EntretienPreventifEntite } from 'src/app/entites/mecanique/entretien-preventif';
import { BonTravailEntite } from 'src/app/entites/mecanique/bon-travail';
import { EStatuts } from 'src/app/entites/enums/statuts';
import { ERaisonBonTravail } from 'src/app/entites/enums/raison-bon-travail';
import { EtapeBonTravailEntite } from 'src/app/entites/mecanique/etape-bon-travail';
import { RequeteAjouterEtapesBonTravail } from 'src/app/services/requetes/requete-ajouter-etapes-bon-travail';
import { EntretienPreventifService } from 'src/app/services/entretien-preventif-service';
import { ETypeEtapeBonTravail } from 'src/app/entites/enums/type-etape-bon-travail';
import { EntretienCorrectifEntite } from 'src/app/entites/mecanique/entretien-correctif';

export interface RetourEnvoiApprobationBonTravail {
    nbHeures?: number,
    nbKm?: number,
    dateFin?: Date
}

@Component({
    templateUrl: './envoyer-approbation-bon-travail.component.html',
})
export class EnvoyerApprobationBonTravailComponent extends BaseComponent {

    bonTravail: BonTravailEntite;

    nbHeures?: number = null;
    nbKm?: number = null;
    dateFin?: Date = new Date();
    
    blocked: boolean = false;

    constructor(
        profilService: ProfilService,
        route: ActivatedRoute,
        routerService: Router,
        appMain: AppMainComponent, location: Location, 
        public sdb: DonneesBaseService,
        public bonTravailService: BonTravailService,
        public ref: DynamicDialogRef,
        public messageService: MessageService,
        public config: DynamicDialogConfig) {
        super(sdb, profilService, route, routerService, appMain);
    }

    ngOnInit() {

        this.bonTravail = this.config.data;

        this.CompleterChargement();
       
    }

    approuver() {

        this.ref.close({
            nbHeures: this.nbHeures,
            nbKm: this.nbKm,
            dateFin: this.dateFin
        });     
    }

    annuler() {
        this.ref.close(null);
    }

    
}