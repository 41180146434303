import { Component, ViewChild, ElementRef, ɵConsole } from "@angular/core"; import { Location } from "@angular/common";
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table/table';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from 'src/app/base/base-component';
import { ProfilService } from 'src/app/services/profil-service';
import { AppMainComponent } from 'src/app/app.main.component';
import { ItemService } from 'src/app/services/item-service';
import { FournisseurService } from 'src/app/services/fournisseur-service';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { EntretienCorrectifEntite } from 'src/app/entites/mecanique/entretien-correctif';
import { SeveriteEntite } from 'src/app/entites/mecanique/severite';
import { CodeVmrsEntite } from 'src/app/entites/mecanique/code-vmrs';
import { EmployeEntite } from 'src/app/entites/employe';
import { CategorieEntretienEntite } from 'src/app/entites/mecanique/categorie-entretien';
import { EntretienCorrectifService } from 'src/app/services/entretien-correctif-service';
import { EStatuts } from 'src/app/entites/enums/statuts';
import { ItemMin } from 'src/app/entites/item-min';
import { PieceUniteEntite } from 'src/app/entites/piece-unite';
import { PrixFournisseurEntite } from 'src/app/entites/prix-fournisseur';
import { PieceService } from 'src/app/services/piece-service';

export interface ParametresSelectionCategorieEntretien {
    categorieEntretienId?: number;
    obligatoire: boolean;
}

@Component({
    selector: 'app-selection-categorie-entretien',
    templateUrl: './selection-categorie-entretien.component.html'
})
export class SelectionCategorieEntretienComponent extends BaseComponent {

    donnees: ParametresSelectionCategorieEntretien;

    categoriesEntretien: CategorieEntretienEntite[];
    selectionCategorieEntretien: CategorieEntretienEntite;

    submitted: boolean;
    blocked: boolean = false;

    @ViewChild('champDescription') inputDescription;

    constructor(
        profilService: ProfilService,
        route: ActivatedRoute, 
        routerService: Router,
		appMain: AppMainComponent, location: Location, 
        public pieceService: PieceService,
        public fournisseurService: FournisseurService,
        public messageService: MessageService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public sdb: DonneesBaseService) {
        super(sdb, profilService, route, routerService, appMain);
    }

    ngOnInit() {

        this.donnees = this.config.data;

        this.categoriesEntretien = this.sdb.CategoriesEntretien();

        if (this.donnees.categorieEntretienId) {
            this.selectionCategorieEntretien = this.categoriesEntretien.find(x => x.categorieEntretienId == this.donnees.categorieEntretienId);
        }

        this.CompleterChargement();
    }

    annuler() {
        this.ref.close(null);
    }

    suivant() {

        this.submitted = true;

        if (!this.valider()) {
            this.messageService.add({ key: 'detailEntretien', severity: 'error', summary: 'Erreurs', detail: 'Veuillez corriger les erreurs de saisie.' });
            return;
        }

        this.blocked = true;

        if (!this.selectionCategorieEntretien) {
            this.selectionCategorieEntretien = {
                categorieEntretienId: 0
            }
        }

        this.ref.close(this.selectionCategorieEntretien);
    }

    valider() {
        return !this.donnees.obligatoire || this.selectionCategorieEntretien;
        
    }
}