import {Component, ViewChild} from "@angular/core"; import { Location } from "@angular/common";
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import { EmployeEntite } from '../entites/employe';
import { DonneesBaseService } from '../services/donnees-base-service';

@Component({
    templateUrl: './saisie-quantite.component.html',
})
export class SaisieQuantiteComponent {

    quantite?: number;
    min: number;
    max: number;
    submitted: boolean = false;

    
    @ViewChild('champQuantite') inputQuantite;
            
    constructor(
        public sdb: DonneesBaseService,
        public ref: DynamicDialogRef, 
        public config: DynamicDialogConfig) { }

    ngOnInit() {
        this.quantite = this.config.data.quantite;

        this.min = this.config.data.min;
        this.max = this.config.data.max;


        setTimeout(() => { 
            this.inputQuantite.input.nativeElement.focus();
            this.inputQuantite.input.nativeElement.select();
         }, 100);
        
    }

    surEntree() {
        this.Confirmer();
    }

    Confirmer() {
        this.submitted = true;
        if (this.quantite) {
            this.ref.close(this.quantite);
        }
    }

    Annuler() {
        this.ref.close(null);
    }
}