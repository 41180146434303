<p-toast></p-toast>
<p-confirmDialog key="confirmDialog"></p-confirmDialog>

<p-card *ngIf="!EstCharge()">
  <div class="fluid formgrid grid">
    <div class="flex flex-column gap-2 my-2 col-12 text-center">
      <p-progressSpinner></p-progressSpinner>
    </div>
  </div>
</p-card>

<div *ngIf="EstCharge()" class="my-2">
  <p-dataView #dv [value]="messages" [lazy]="true" [lazyLoadOnInit]="true" [rows]="10" [totalRecords]="messages?.length" (onLazyLoad)="loadMessages($event)" [style]="{height: '700px', overflow: 'hidden', overflowY: 'scroll' }">
    <ng-template pTemplate="list" let-messagess>
        <div *ngFor="let message of messagess" class="p-3 mb-3">
          <div [class]="isOwner(message) ? 'flex flex-row-reverse' : 'flex'">
            <p-card styleClass="mt-2 message-card">
              <div class="flex flex-row flex-wrap justify-content-between">
                  <app-user-info [employe]="message.employe" [loading]="!message.texte" />
                <div class="flex flex-row align-items-center gap-2">
                  <small *ngIf="message.date">{{message.date | date:'short'}}</small>
                  <p-skeleton *ngIf="!message.date" width="50px"></p-skeleton>
                  <p-button *ngIf="isOwner(message) && !message.isEditing" icon="pi pi-ellipsis-h" [text]="true" [outlined]="true" pTooltip="Plus d'options" tooltipPosition="bottom" (onClick)="onShowMenu(message, $event)" />
                  <p-button *ngIf="message.isEditing" icon="pi pi-check" pTooltip="Valider" tooltipPosition="bottom" (onClick)="confirmEditMessage()" />
                  <p-button *ngIf="message.isEditing" icon="pi pi-times" pTooltip="Annuler" [text]="true" [outlined]="true" tooltipPosition="bottom" (onClick)="cancelEditMessage(message)" />
                  <p-menu #menu [model]="items" [popup]="true" [appendTo]="'body'" [style]="{'width': '14rem'}" />
                </div>
              </div>
              <div class="mt-3">
                <div *ngIf="!message.isEditing && message.texte" [innerHTML]="message.texte"></div>
                <p-skeleton *ngIf="!message.texte" width="100%"></p-skeleton>
                <p-editor *ngIf="message.isEditing" placeholder="Ecrivez votre message ici..." [modules]="sdb.modules" [(ngModel)]="messageToEdit.texte"
                  [style]="{ 'min-height': '100px !important' }">
                </p-editor>
                <p-fileUpload *ngIf="message.isEditing" #fileUploadMessage name="myfile[]" styleClass="mt-3"
                  multiple="multiple"
                  customUpload="true"
                  maxFileSize="209715200"
                  invalidFileSizeMessageSummary="{0}: Fichier trop volumineux, "
                  invalidFileSizeMessageDetail="la grosseur maximale est {0}.">
                  <ng-template pTemplate="header" let-files let-chooseCallback="chooseCallback" let-clearCallback="clearCallback">
                    <p-button *ngIf="message.isEditing" class="ml-3" icon="pi pi-plus" [text]="true" [outlined]="true" pTooltip="Ajouter un document" tooltipPosition="bottom" (click)="chooseCallback()" />
                  </ng-template>
                  <ng-template pTemplate="content" let-files let-uploadedFiles="uploadedFiles" let-removeFileCallback="removeFileCallback" let-removeUploadedFileCallback="removeUploadedFileCallback">
                    <p-chip *ngFor="let document of files" [removable]="true" (onRemove)="removeFileCallback(document)" styleClass="mb-3">
                      <div class="flex flex-row align-items-center gap-3">
                        <p-image *ngIf="document?.objectURL" [src]="document.objectURL" [preview]="true" [style]="{width: '32px', height: '32px'}" />
                        <span *ngIf="!document?.objectURL" class="pi pi-file"></span>
                        <span>{{document.name}}</span>
                      </div>
                    </p-chip>
                  </ng-template>
                  <ng-template pTemplate="file"></ng-template>
                  <ng-template pTemplate="empty"></ng-template>
                </p-fileUpload>
                <p-divider *ngIf="message.documents && message.documents?.length > 0"><span class="pi pi-file-pdf"></span></p-divider>
                <p-chip *ngFor="let document of message.documents" styleClass="mb-2 mr-2">
                  <div class="flex flex-row align-items-center gap-3">
                    <p-image *ngIf="document?.apercu" [src]="document.apercu" [preview]="true" [style]="{width: '32px', height: '32px'}" />
                    <span *ngIf="!document?.apercu" class="pi pi-file"></span>
                    <span>{{document.nomFichier}}</span>
                  </div>
                </p-chip>
              </div>
            </p-card>
          </div>
        </div>
    </ng-template>
    <ng-template pTemplate="empty"></ng-template>
  </p-dataView>
</div>

<div class="my-2" style="box-shadow: 0 -4px 6px -1px rgba(0, 0, 0, 0.1), 0 -2px 4px -1px rgba(0, 0, 0, 0.06);">
  <p-editor #editor placeholder="Ecrivez votre message ici..." [modules]="sdb.modules" [(ngModel)]="newMessage.texte"
    [style]="{ 'min-height': '100px !important', 'max-height': '300px', 'overflow': 'hidden', 'overflow-y': 'scroll' }">
  </p-editor>
  <div class="flex flex-row flex-wrap md:flex-nowrap gap-2 justify-content-between mt-1">
    <p-fileUpload #fileUpload name="myfile[]"
      multiple="multiple"
      customUpload="true"
      maxFileSize="209715200"
      invalidFileSizeMessageSummary="{0}: Fichier trop volumineux, "
      invalidFileSizeMessageDetail="la grosseur maximale est {0}.">
      <ng-template pTemplate="header" let-files let-chooseCallback="chooseCallback" let-clearCallback="clearCallback">
        <p-button class="ml-3" icon="pi pi-plus" [text]="true" [outlined]="true" pTooltip="Ajouter un document" tooltipPosition="bottom" (click)="chooseCallback()" />
      </ng-template>
      <ng-template pTemplate="content" let-files let-uploadedFiles="uploadedFiles" let-removeFileCallback="removeFileCallback" let-removeUploadedFileCallback="removeUploadedFileCallback">
        <p-chip *ngFor="let document of files" [removable]="true" (onRemove)="removeFileCallback(document)" styleClass="mb-3">
          <div class="flex flex-row align-items-center gap-3">
            <p-image *ngIf="document.objectURL" [src]="document.objectURL" [preview]="true" [style]="{width: '32px', height: '32px'}" />
            <span *ngIf="!document.objectURL" class="pi pi-file"></span>
            <span>{{document.name}}</span>
          </div>
        </p-chip>
      </ng-template>
      <ng-template pTemplate="file"></ng-template>
      <ng-template pTemplate="empty"></ng-template>
    </p-fileUpload>
    <div class="flex flex-row gap-2">
      <p-button icon="pi pi-send" label="Envoyer" (onClick)="sendMessage()" />
    </div>
  </div>
</div>