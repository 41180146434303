<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-contextMenu #cm [model]="menuContextuelFournisseurs"></p-contextMenu>


<p-card *ngIf="!EstCharge()">
	<div class="fluid formgrid grid">
		<div class="flex flex-column gap-2 my-2 col-12 text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-card>

<div *ngIf="EstCharge()" class="grid">
    <div class="col-12">

        <div class="card">
            <p-table #dt [value]="fournisseurs" dataKey="fournisseurId" tableStyleClass="tableNormale" autoLayout="true"
                 [(contextMenuSelection)]="selectionFournisseur"
                [contextMenu]="cm" [rowHover]="true" [rows]="25" [paginator]="true" [filterDelay]="0" sortfield="nom"
                [globalFilterFields]="['numero','nom']">
                <ng-template pTemplate="caption">
                    <div class="flex align-content-center justify-content-between">
                        <div>
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input #rechercheItem pInputText type="text"
                                (keyup.enter)="surEntreeRecherche()"
                                    (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Recherche..." />
                            </span>
                        </div>
                        <div>
                            <h5 class="titreTableau m-0">Fournisseurs disponibles pour ajout</h5>
                        </div>
                        <div>
                            <p-multiSelect disabled="true" [style]="{minWidth: '200px'}" placeholder="Colonnes..."></p-multiSelect>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        
                        <th pSortableColumn="numero">
                            <div class="flex justify-content-between align-items-center">
                                Numéro
                                <p-sortIcon field="numero"></p-sortIcon>
                                <p-columnFilter type="text" field="numero" display="menu" class="ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="Nom">
                            <div class="flex justify-content-between align-items-center">
                                Nom
                                <p-sortIcon field="Nom"></p-sortIcon>
                                <p-columnFilter type="text" field="Nom" display="menu" class="ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th style="min-width: 5rem"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-fournisseur>
                    <tr [pContextMenuRow]="fournisseur">
                        <td>{{fournisseur.numero}}</td>
                        <td>{{fournisseur.nom}}</td>
                        <td>
                            <button pButton pRipple icon="pi pi-plus"
                                class="p-button-success mr-2 boutonGrille"
                                (click)="ajouterFournisseur(fournisseur)"></button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="3">Aucun fournisseur.</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>


</div>