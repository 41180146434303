<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>


<p-card *ngIf="!EstCharge()">
    <div class="fluid formgrid grid">
        <div class="flex flex-column gap-2 col-12 text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-card>

<div *ngIf="EstCharge()" class="grid">
    <div class="col-12">
        <div class="card">
            <h5>
                Attribut - {{attribut.nom}}
                <span *ngIf="!attribut.attributId" class="statut nouveau">
					Nouveau
				</span>
            </h5>

            <p-menubar autoDisplay="false" [model]="menu"></p-menubar>

        </div>
    </div>
</div>

<div *ngIf="EstCharge()" class="grid">
    <div class="col-12">

        <div class="card">

            <div class="fluid formgrid grid">
                <div class="flex flex-column gap-2 col-12 md:col-6 lg:col-3">
                    <label for="nom">Nom<span class="requis">*</span></label>
                    <input #nomAttribut type="text" pInputText id="nom" [(ngModel)]="attribut.nom" autocomplete="off"
                        autofocus />
                    <small class="p-error" *ngIf="submitted && !attribut.nom">Le nom est
                        obligatoire.</small>
                </div>
                <div class="flex flex-column gap-2 col-12 md:col-6 p-lg-9">
                    <label for="description">Description</label>
                    <input #nomAttribut type="text" pInputText id="description" [(ngModel)]="attribut.description"
                        required autocomplete="off" />
                </div>
            </div>
            <div class="fluid formgrid grid">
                <div class="flex flex-column gap-2 col-12 my-3">
                    <label for="type">Type</label>
                    <div class="flex flex-wrap gap-2 col-12">
                        <p-radioButton label="Texte" name="type" value="1" [(ngModel)]="attribut.typeChamp"></p-radioButton>
                        <p-radioButton label="Numérique" name="type" value="2" [(ngModel)]="attribut.typeChamp"></p-radioButton>
                        <p-radioButton label="Décimale" name="type" value="3" [(ngModel)]="attribut.typeChamp"></p-radioButton>
                        <p-radioButton label="Date" name="type" value="4" [(ngModel)]="attribut.typeChamp"></p-radioButton>
                        <p-radioButton label="Vrai/Faux" name="type" value="5" [(ngModel)]="attribut.typeChamp"></p-radioButton>
                    </div>
                </div>
            </div>
          <!--   <div class="fluid formgrid grid">
                <div class="flex flex-column gap-2 col-12 md:col-6 lg:col-3">
                    <p-checkbox label="Obligatoire" [binary]="true" [(ngModel)]="attribut.indObligatoire"
                        class="mt-3"></p-checkbox>
                </div>
            </div> -->
            <div class="fluid formgrid grid">
                <div *ngFor="let lien of liensCategorie" class="flex flex-column gap-2 col-12 md:col-6 lg:col-3">
                    <h6 class="mt-3">{{lien.nomCategorie}}</h6>
                    <p-checkbox label="Utilisé" [binary]="true" [(ngModel)]="lien.utilise"
                        class="mr-4"></p-checkbox>
                    <p-checkbox label="Obligatoire" [binary]="true" [(ngModel)]="lien.indObligatoire"
                        class=""></p-checkbox>
                </div>
               
            </div>

            <div class="col-12 text-right mt-4">
                <button pButton pRipple label="Enregistrer" icon="pi pi-check" class="p-button-primary"
                (click)="sauvegarder()"></button>
            <button pButton pRipple label="Annuler" icon="pi pi-times" class="p-button-text"
                (click)="annuler()"></button>
            
            </div>
        </div>
    </div>
</div>
