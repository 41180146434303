import { Component, ViewChild, ElementRef, ɵConsole } from "@angular/core"; import { Location } from "@angular/common";
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { CleValeur, CleValeurNumerique } from '../../entites/cleValeur';
import { EntreeCommande } from '../../entites/entree-commande';
import { ItemMin } from '../../entites/item-min';
import { Statut } from '../../entites/statut';
import { DonneesBaseService } from '../../services/donnees-base-service';
import { BaseComponent } from '../../base/base-component';
import { ItemService } from '../../services/item-service';
import { BlockUIModule } from 'primeng/blockui';
import { FournisseurService } from '../../services/fournisseur-service';
import { Fournisseur } from '../../entites/fournisseur';
import { EmployeEntite } from '../../entites/employe';
import { ItemCommandeEntite } from '../../entites/item-commande';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table/table';
import { ProfilService } from '../../services/profil-service';
import { ActivatedRoute, Router } from '@angular/router';
import { RequisitionService } from '../../services/requisition-service';
import { AppMainComponent } from '../../app.main.component';
import { CategorieEntite } from '../../entites/categorie';
import { EStatutsSpeciaux } from '../../entites/enums/statuts-speciaux';

@Component({
    selector: 'app-ajout-unite-mecanique',
    templateUrl: './ajout-unite-mecanique.component.html'
})
export class AjoutUniteMecaniqueComponent extends BaseComponent {

    submitted: boolean;

    items: ItemMin[];
    selectionItem: ItemMin = { itemId: 0 };

    @ViewChild('rechercheItem') inputRechercheItem;
    @ViewChild('dt') tableItems: Table;

    constructor(
        profilService: ProfilService,
        route: ActivatedRoute,
        routerService: Router,
        appMain: AppMainComponent, location: Location, 
        public itemService: ItemService,
        public requisitionService: RequisitionService,
        public fournisseurService: FournisseurService,
        public messageService: MessageService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public sdb: DonneesBaseService) {
        super(sdb, profilService, route, routerService, appMain);
    }



    ngOnInit() {

        this.colonnesDisponibles = [
            { champ: 'categorie', entete: 'Catégorie', afficheParDefaut: true },
            { champ: 'sousCategorie', entete: 'Sous-catégorie', afficheParDefaut: true },
            { champ: 'marque', entete: 'Marque', afficheParDefaut: false },
            { champ: 'modele', entete: 'Modèle', afficheParDefaut: false }
        ];

        this.colonnesSelectionnees = this.colonnesDisponibles.filter(x => x.afficheParDefaut);

        this.itemService.ObtenirItemsParStatut(EStatutsSpeciaux.UnitesMecaniquePotentielles).then(x => {
            this.items = x.valeur;

            this.CompleterChargement();

            setTimeout(() => { this.inputRechercheItem.nativeElement.focus(); }, 100);

        });

    }

    surEntreeRecherche() {

        if (this.tableItems.filteredValue != undefined &&
            this.tableItems.filteredValue.length == 1) {
            this.surSelectionItemInventaire(this.tableItems.filteredValue[0]);
        }

    }

    annuler() {
        this.ref.close(null);
    }

    surSelectionItemInventaire(item: ItemMin) {

        this.Bloquer();

        this.itemService.IndiquerMecanique(item).then(x => {

            this.Debloquer();

            this.ref.close(item);

        });

        

    }
}