import { Component, OnInit } from "@angular/core"; import { Location } from "@angular/common";
import { BaseComponent } from '../../base/base-component';
import { DonneesBaseService } from '../../services/donnees-base-service';
import { ProfilService } from '../../services/profil-service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppMainComponent } from '../../app.main.component';
import { BreadcrumbService } from '../../app.breadcrumb.service';
import { NonConformiteService } from '../../services/non-conformite-service';
import { MessageService } from 'primeng/api';
import { NonConformiteEntite } from '../../entites/non-conformite';
import { EmplacementService } from '../../services/emplacement-service';
import { CodeProjetEntite } from '../../entites/code-projet';
import { FournisseurService } from '../../services/fournisseur-service';
import { Fournisseur } from '../../entites/fournisseur';

@Component({
    selector: 'app-ajout-non-conformite',
    templateUrl: './ajout-non-conformite.component.html',
    providers: [MessageService, NonConformiteService]
})
export class AjoutNonConformiteComponent extends BaseComponent implements OnInit {
    nonConformite: NonConformiteEntite;
    submitted: boolean;
    codeProjets: CodeProjetEntite[];
    fournisseurs: Fournisseur[];
    responsables: string[];
    objets: string[];
    autreObjet: string;
    autreResponsable: string;

    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute,
        routerService: Router,
        appMain: AppMainComponent, location: Location, 
        private breadcrumbService: BreadcrumbService,
        private nonConformiteService: NonConformiteService,
        private messageService: MessageService,
        private EmplacementService: EmplacementService,
        private FournisseurService: FournisseurService
    ) {
        super(sdb, profilService, route, routerService, appMain);
        this.breadcrumbService.setItems([
            { label: 'Non-conformités', routerLink: '/assurancequalite/nonconformite' },
            { label: 'Ajouter' }
        ]);
    }

    ngOnInit() {
        this.nonConformite = {
            idNonConformite: null,
            numero: null,
            entrepreneur: null,
            responsableQualite: null,
            sousTraitant: null,
            representantSousTraitant: null,
            client: null,
            representantClient: null,
            objet: null,
            responsable: null,
            causesPossibles: null,
            statut: null,
            codeProjet: {
                code: null,
                nom: null
            },
            type: null,
            dateEmission: null,
            dateDetection: null,
            description: null,
            titre: null,
            traitementPropose: null,
            approbation: null,
            dossierSst: null,
            modeEstimation: null,
            employe: this.profilService.ProfilCourant(),
            actionsCorrectives: []
        };

        var promises = [];

        promises.push(this.EmplacementService.ObtenirCodeProjets().then(
            (result) => {
                this.codeProjets = result.valeur;
            },
            (error) => {
                this.messageService.add({ severity: 'error', summary: 'Erreur', detail: 'Erreur lors de la récupération du code projet' });
            }
        ));

        promises.push(this.FournisseurService.ObtenirFournisseurs().then(
            (result) => {
                this.fournisseurs = result.valeur;
            },
            (error) => {
                this.messageService.add({ severity: 'error', summary: 'Erreur', detail: 'Erreur lors de la récupération des fournisseurs' });
            }
        ));

        Promise.all(promises).then(() => {
            this.CompleterChargement();
        });

        this.responsables = ['Client', 'Entrepreneur', 'Fournisseur', 'Sous-traitant'];
        this.objets = ['Contrat', 'Produit/Matériel', 'Processus', 'Équipement', 'Transport'];
    }

    creerNonConformite() {
        this.submitted = true;

        if (!(this.nonConformite.type &&
            this.nonConformite.codeProjet &&
            this.nonConformite.titre &&
            this.nonConformite.dateDetection &&
            this.nonConformite.dateEmission &&
            (this.nonConformite.objet || this.autreObjet) &&
            (this.nonConformite.responsable || this.autreResponsable))
        ) {return;}

        this.nonConformite.objet = this.nonConformite.objet == 'Autre' ? this.autreObjet : this.nonConformite.objet;
        this.nonConformite.responsable = this.nonConformite.responsable == 'Autre' ? this.autreResponsable : this.nonConformite.responsable;

        this.nonConformiteService.Creer(this.nonConformite).then(
            (result) => {
                this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Non-conformité ajoutée avec succès' });
                this.routerService.navigate(['/assurancequalite/nonconformite/' + result.valeur.idNonConformite]);
            },
            (error) => {
                this.messageService.add({ severity: 'error', summary: 'Erreur', detail: 'Erreur lors de l\'ajout de la non-conformité' });
            }
        );
    }
}