import { Component, ViewChild, ElementRef, ɵConsole, Input } from "@angular/core"; import { Location } from "@angular/common";
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { DonneesBaseService } from '../services/donnees-base-service';
import { BaseComponent } from '../base/base-component';
import { MessageService } from 'primeng/api';
import { ProfilService } from '../services/profil-service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppMainComponent } from '../app.main.component';
import { RequisitionService } from '../services/requisition-service';
import { RequisitionMin } from '../entites/requisition-min';

export interface ParametresConfirmationCreationRequisition {
    requisitionIds: number[];
}

@Component({
    selector: 'app-confirmation-creation-requisitions',
    templateUrl: './confirmation-creation-requisitions.component.html'
})
export class ConfirmationCreationRequisitionComponent extends BaseComponent {

    donnees: ParametresConfirmationCreationRequisition;

    requisitions: RequisitionMin[];

    @ViewChild('boutonConfirmer') boutonConfirmer;

    constructor(
        profilService: ProfilService,
        route: ActivatedRoute, 
        routerService: Router,
        appMain: AppMainComponent, location: Location, 
        private requisitionService: RequisitionService,
        public messageService: MessageService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public sdb: DonneesBaseService) {
        super(sdb, profilService, route, routerService, appMain);
    }

    ngOnInit() {

        this.donnees = this.config.data;

        this.requisitionService.ObtenirResumeRequisitions(this.donnees.requisitionIds).then(res => {
            if (res.estUnSucces) {

                this.requisitions = res.valeur

                this.CompleterChargement();

                setTimeout(() => {this.boutonConfirmer.nativeElement.focus(); }, 100);
                
            }
        });
    }

    confirmer() {
        this.ref.close();
    }

    voirDetailRequisition(req: RequisitionMin) {
        this.ref.close();
        this.routerService.navigateByUrl('requisition/' + req.requisitionId.toString());
    }
}