<p-toast></p-toast>

<p-card *ngIf="!EstCharge()">
    <div class="fluid formgrid grid">
        <div class="flex flex-column gap-2 my-2 col-12 text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-card>

<div *ngIf="EstCharge()" class="grid">
    <div class="col-12">
        <div class="card">
            <h5>Analyse du Bon de Travail #{{analyseBonTravail.numero}}
                <span [class]="'statut ' + analyseBonTravail.statut?.nom?.toLowerCase()?.replace(' ','')">
					{{analyseBonTravail.statut?.nom}}
				</span>
            </h5>

            <p-menubar autoDisplay="false" [model]="menu" *ngIf="!modeApercu">
                <p-button
                    *ngIf="(estAutorise('bontravail_modifier') || estAutorise('temp_mec')) && modeConsultation() && estModifiable"
                    (click)="commencerModification()" icon="pi pi-pencil" styleClass="p-button-primary"></p-button>
                <p-button *ngIf="modeAjoutModification()" (onClick)="enregistrerModifications()" label
                    icon="pi pi-check" styleClass="p-button-success mr-2">
                </p-button>
                <p-button *ngIf="modeAjoutModification()" (onClick)="annulerModification($event)" icon="pi pi-times"
                    styleClass="p-button-secondary"></p-button>
            </p-menubar>

        </div>
    </div>
</div>

<div *ngIf="EstCharge()" class="grid">
    <div class="col-12">
        <div class="card">
            <h5>Analyse du Bon de Travail #{{analyseBonTravail.numero}}</h5>

            <div class="p-fluid formgrid grid mb-3">
                <div class="field col-12 md:col-3">
                    <label>Numéro du Bon de Travail</label>
                    <div style="font-size: 1.2em;">
                        <a [routerLink]="['/mecanique/bontravail', analyseBonTravail.bonTravail.id]">{{analyseBonTravail.bonTravail.numero}}</a>
                    </div>
                </div>
                <div class="field col-12 md:col-3">
                    <label>Nombre d'Heures de travail</label>
                    <input pInputText [value]="analyseBonTravail.nbHeures" [readonly]="modeApercu" />
                </div>
                <div class="field col-12 md:col-3">
                    <label>Coût de la main-d'oeuvre</label>
                    <input pInputText
                        [value]="analyseBonTravail.coutMainDoeuvre | currency:'CAD':'symbol-narrow':'1.2-2'" [readonly]="modeApercu" />
                </div>
                <div class="field col-12 md:col-3">
                    <label>Nombre d'Items</label>
                    <input pInputText [value]="analyseBonTravail.nbItems" readonly />
                </div>
                <div class="field col-12 md:col-4">
                    <label>Coût Soumission</label>
                    <input pInputText
                        [value]="analyseBonTravail.coutSoumission | currency:'CAD':'symbol-narrow':'1.2-2'" [readonly]="modeApercu" />
                </div>
                <div class="field col-12 md:col-4">
                    <label>Coût Bon de Travail</label>
                    <input pInputText
                        [value]="analyseBonTravail.coutBonTravail | currency:'CAD':'symbol-narrow':'1.2-2'" readonly />
                </div>
                <div class="field col-12 md:col-4">
                    <label>Différence</label>
                    <input pInputText
                        [value]="(analyseBonTravail.coutBonTravail - analyseBonTravail.coutSoumission) | currency:'CAD':'symbol-narrow':'1.2-2'"
                        readonly />
                </div>
            </div>

            <p-table [value]="analyseBonTravail.items" [paginator]="true" [rows]="10"
                [showCurrentPageReport]="true"
                currentPageReportTemplate="Affichage {first} à {last} sur {totalRecords} entrées"
                [rowsPerPageOptions]="[10,25,50]" [globalFilterFields]="['item.description', 'quantite', 'prix']">
                <ng-template pTemplate="caption">
                    <div class="flex justify-content-between align-items-center">
                        <h5 class="m-0">Items du Bon de Commande</h5>
                        <span class="p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                placeholder="Rechercher..." />
                        </span>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th>Description</th>
                        <th>Quantité</th>
                        <th>Prix Unitaire</th>
                        <th>Total</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item>
                    <tr>
                        <td>{{item.item.description}}</td>
                        <td>{{item.quantite}}</td>
                        <td>{{item.prix | currency:'CAD':'symbol-narrow':'1.2-2'}}</td>
                        <td>{{item.quantite * item.prix | currency:'CAD':'symbol-narrow':'1.2-2'}}</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="footer">
                    <tr>
                        <td colspan="3">Total</td>
                        <td>{{(analyseBonTravail.coutBonTravail - (analyseBonTravail.coutMainDoeuvre ? analyseBonTravail.coutMainDoeuvre : 0)) | currency:'CAD':'symbol-narrow':'1.2-2'}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>