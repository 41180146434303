import { Component, OnInit, ViewChild, Input, ɵConsole } from "@angular/core"; import { Location } from "@angular/common";
import { MenuItem } from 'primeng/api';
import { MessageService, ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Router, ActivatedRoute } from '@angular/router';
import { FournisseurService } from 'src/app/services/fournisseur-service';
import { Fournisseur } from 'src/app/entites/fournisseur';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { ProfilService } from 'src/app/services/profil-service';
import { AppMainComponent } from 'src/app/app.main.component';
import { LocationEntite } from 'src/app/entites/location';
import { TypeLocationEntite } from 'src/app/entites/type-location';
import { EStatuts } from 'src/app/entites/enums/statuts';
import { LocationService } from 'src/app/services/location-service';
import { UniteEntite } from 'src/app/entites/unite';
import { CoutLocationEntite } from 'src/app/entites/cout-location';
import { ETypesLocation } from 'src/app/entites/enums/type-location';
import { ContextMenu } from 'primeng/contextmenu/public_api';
import { DocumentEntite } from 'src/app/entites/document';
import { DocumentService } from 'src/app/services/document-service';
import { environment } from 'src/environments/environment';
import { RequeteObtenirDocuments } from 'src/app/services/requetes/requete-obtenir-documents';
import { ContexteService } from 'src/app/services/contexte-service';
import { BaseComponent } from 'src/app/base/base-component';
import { Emplacement } from 'src/app/entites/emplacement';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { AchatService } from 'src/app/services/achat-service';
import { ConfirmerDebutLocationComponent, ResultatConfirmerDebutLocation } from '../../locations/controles/confirmer-debut-location.component';
import { InfoBonLocationComponent, ResultatInfoBonLocation } from '../../locations/controles/info-bon-location.component';
import { ConfirmerFinLocationComponent } from '../../locations/controles/confirmer-fin-location.component';
import { DetailDocumentComponent } from '../../documents/controles/detail-document.component';
import { LigneHeureEntite } from 'src/app/entites/mecanique/ligne-heure';
import { DepartementEntite } from 'src/app/entites/departement';
import { HeureService } from 'src/app/services/heure-service';
import { DepartementMecaniqueEntite } from 'src/app/entites/mecanique/departement-mecanique';

@Component({
    templateUrl: './heures.component.html',
    providers: [MessageService, ConfirmationService, DialogService]
})
export class AiguillageHeuresComponent extends BaseComponent implements OnInit {

    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute, 
		appMain: AppMainComponent, location: Location, 
        private breadcrumbService: BreadcrumbService,
        private heureService: HeureService,
        private documentService: DocumentService,
        private locationService: LocationService,
        private fournisseurService: FournisseurService,
        private contexteService: ContexteService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        public dialogService: DialogService,
        public routerService: Router ) {

        super(sdb, profilService, route, routerService, appMain);

        this.breadcrumbService.setItems([
            { label: 'Heures' }
        ]);

    }

    ngOnInit() {

        this.verifierAccesPage('heures_saisie');
this.CompleterChargement();
       
    }

  
}
