import { Component, OnInit, ViewChild, Input, ɵConsole, ElementRef } from "@angular/core"; import { Location } from "@angular/common";
import { Table } from 'primeng/table';
import { BreadcrumbService } from '../../app.breadcrumb.service';

import { RequisitionMin } from '../../entites/requisition-min';
import { MenuItem } from 'primeng/api/menuitem';
import { MessageService, LazyLoadEvent, ConfirmationService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

import { DialogService } from 'primeng/dynamicdialog';
import { EnvoiRequisitionComponent } from '../../controles/envoi-requisition.component';
import { ItemMin } from '../../entites/item-min';
import { ItemService } from '../../services/item-service';
import { Router, ActivatedRoute } from '@angular/router';
import { ReparationEntite } from 'src/app/entites/reparation';
import { BaseComponent } from 'src/app/base/base-component';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { ContextMenu } from 'primeng/contextmenu/public_api';
import { ProfilService } from 'src/app/services/profil-service';
import { RetourReparationComponent } from 'src/app/controles/retour-reparation.component';
import { AppMainComponent } from 'src/app/app.main.component';
import { LocationEntite } from 'src/app/entites/location';
import { LocationService } from 'src/app/services/location-service';
import { environment } from 'src/environments/environment';
import { LocationChangeEvent } from '@angular/common';
import { RapportService } from 'src/app/services/rapport-service';
import { Emplacement } from 'src/app/entites/emplacement';
import { EmplacementService } from 'src/app/services/emplacement-service';

@Component({
    templateUrl: './archiver-emplacement.component.html',
    providers: [MessageService, ConfirmationService, DialogService]
})
export class ArchiverEmplacementComponent extends BaseComponent implements OnInit {

    emplacementId: number;
    emplacement: Emplacement;

    inventaire: ItemMin[];
    itemInventaire: ItemMin;
    contenuMenuContextuelInventaire: MenuItem[];
    @ViewChild('menuContextuelInventaire') menuContextuelInventaire: ContextMenu;

    menu: MenuItem[];

    @ViewChild('dt') table: Table;


    constructor(
        public sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute,
        appMain: AppMainComponent, location: Location, 
        private breadcrumbService: BreadcrumbService,
        private ItemsService: ItemService,
        private emplacementService: EmplacementService,
        private locationService: LocationService,
        private rapportService: RapportService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        public dialogService: DialogService,
        public routerService: Router) {

        super(sdb, profilService, route, routerService, appMain);

        this.breadcrumbService.setItems([
            { label: 'Emplacements', routerLink: '/pilotage/emplacements' },
            { label: 'Archiver' }
        ]);

        
        this.route.params.subscribe(params => this.emplacementId = params.id);
    }


    ngOnInit() {

        this.colonnesDisponibles = [
            { champ: 'categorie', entete: 'Catégorie', afficheParDefaut: false },
            { champ: 'sousCategorie', entete: 'Sous-catégorie', afficheParDefaut: false },
            { champ: 'marque', entete: 'Marque', afficheParDefaut: false },
            { champ: 'modele', entete: 'Modèle', afficheParDefaut: false }
        ];

        this.colonnesSelectionnees = this.colonnesDisponibles.filter(x => x.afficheParDefaut);

        var p1 = new Promise((resolve) => {
            this.emplacementService.ObtenirInventaire(this.emplacementId).then(x => {
                this.inventaire = x.valeur.filter(x => x.categorie.estUnique);
                resolve(null);
            })
        });

        var p2 = new Promise((resolve) => {
            this.emplacementService.ObtenirDetail(this.emplacementId).then(x => {
                this.emplacement = x.valeur;
                resolve(null);
            })
        });

        Promise.all([p1]).then(x => {
            this.CompleterChargement();
        })
    }

    surMenuContextuelInventaire(event, inventaire: ItemMin) {
        this.itemInventaire = inventaire;

        this.contenuMenuContextuelInventaire = [
            { label: 'Voir l\'item', icon: 'pi pi-fw pi-eye', command: () => this.routerService.navigateByUrl('item/' + this.itemInventaire.itemId) }
        ];

        this.menuContextuelInventaire.toggle(event);

    }


    OuvrirDetailLocation(location: LocationEntite) {

        this.routerService.navigateByUrl('location/' + location.locationId);
    }


    archiver() {
        this.confirmationService.confirm({
            target: event.target,
            message: 'Voulez-vous vraiment archiver l\'emplacement ainsi que tous les items présents dans la liste ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.Bloquer();
                this.emplacementService.Archiver(this.emplacement).then(res => {
                    
                    if (res.estUnSucces) {
                        this.sdb.ReinitialiserEmplacements().then(() => {
                            this.Debloquer();
                            this.routerService.navigateByUrl('pilotage/emplacement/' + this.emplacement.emplacementId);
                        });
                        
                    } else {
                        this.Debloquer();
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                });

            }
        });
    }

    
}
