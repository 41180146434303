import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from 'src/app/base/base-component';
import { ProfilService } from 'src/app/services/profil-service';
import { AppMainComponent } from 'src/app/app.main.component';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { MessageService, ConfirmationService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BonTravailEntite } from 'src/app/entites/mecanique/bon-travail';
import { BonTravailService } from 'src/app/services/bon-travail-service';
import { EmployeService } from 'src/app/services/employe-service';
import { EmployeEntite } from 'src/app/entites/employe';
import { SelectionEmployesComponent } from 'src/app/controles/selection-employes.component';
import { MenuItem } from 'primeng/api';
@Component({
    selector: 'app-transformer-soumission-bon-travail',
    templateUrl: './transformer-soumission-bon-travail.component.html',
    providers: [MessageService, ConfirmationService, DialogService]
})
export class TransformerSoumissionBonTravailComponent extends BaseComponent implements OnInit {

    bonTravail: BonTravailEntite;
    lignesOuvertes: any;
    employes: EmployeEntite[];
    splitBtnItems: MenuItem[];

    constructor(
        profilService: ProfilService,
        route: ActivatedRoute, 
        routerService: Router,
        appMain: AppMainComponent,
        public messageService: MessageService,
        public dialogService: DialogService,
        public sdb: DonneesBaseService,
        public bonTravailService: BonTravailService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public employeService: EmployeService,
        public confirmationService: ConfirmationService
    ) {
        super(sdb, profilService, route, routerService, appMain);
    }

    ngOnInit() {
        var promises = [];

        this.splitBtnItems = [
            { label: 'Transformer sans approbation', icon: 'pi pi-send', command: () => this.Accept(false) }
        ];

        promises.push(this.employeService.ObtenirListe().then(res => {
            this.employes = res.valeur;
        }));

        promises.push(this.bonTravailService.ObtenirDetail(this.config.data.bonTravailId).then(res => {
            this.bonTravail = res.valeur;

            this.bonTravail.etapes.forEach(et => {
                et.optionEtapeBonTravails.forEach(o => {
                    o.id = this.newGuid();
                });

                et.optionEtapeBonTravailIdTemporaire = et.optionEtapeBonTravails.find(x => x.optionEtapeBonTravailId == et.optionEtapeBonTravailId)?.id;
            });
        }));

        Promise.all(promises).then(() => {
            this.CalculTotal();
            this.expandRows();
            this.CompleterChargement();
        });
    }

    Confirmer() {
        this.confirmationService.confirm({
            message: this.config.data.modeApprobation ? 'Est-vous sûr de transformer cette soumission en bon de travail?' : 'Voulez-vous envoyer ce bon de travail en approbation?',
            header: 'Confirmation',
            acceptLabel: 'Envoyer',
            rejectLabel: 'Envoyer sans approbation',
            icon: 'pi pi-info-circle',
        });
    }

    Accept(approbation: boolean) {

        if (!approbation) {
            this.ref.close(this.bonTravail);
            return;
        }

        const ref = this.dialogService.open(SelectionEmployesComponent, {
            data: {
                employesIds: []
            },
            header: 'Envoi du bon de travail',
            width: '60%'
        }).onClose.subscribe((res: EmployeEntite[]) => {
            if (res) {

                this.Bloquer();

                var destinataires: string[] = res.map(x => x.courriel);

                this.bonTravailService.EnvoyerApprobationSoumission(this.bonTravail.bonTravailId, destinataires).subscribe({
                    next: (res) => {
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: "Soumission envoyée en approbation" });

                        this.bonTravailService.Modifier(this.bonTravail).then(res => {
                            if (!res.estUnSucces) {
                                this.messageService.add({ severity: 'error', summary: 'Erreur', detail: "Impossible d'appliquer vos modifications" });
                                this.Debloquer();
                            } else {
                                this.messageService.add({ severity: 'success', summary: 'Succès', detail: "Soumission sauvegardée" });
                                setTimeout(() => {
                                    this.Debloquer();
                                    this.ref.close();
                                }, 1000);
                            }
                        });
                    },
                    error: (err) => {
                        this.bonTravailService.errorHandler(err, "Bon de travail");
                        this.Debloquer();
                    }
                });
            }
        });
    }

    Annuler() {
        this.ref.close();
    }

    expandRows() {
        this.lignesOuvertes = this.bonTravail.etapes.reduce((acc, p) => (acc[p.etapeBonTravailId] = true) && acc, {});
    }

    CalculTotal() {
        this.bonTravail.coutReelHeures =  0;
        this.bonTravail.coutReelPieces =  0;

        this.bonTravail.etapes.forEach(et => {
            if (et.indAFaire) {
                var option = et.optionEtapeBonTravails.find(x => x.optionEtapeBonTravailId == et.optionEtapeBonTravailId);

                if (option) {
                    this.bonTravail.coutReelHeures += option.nbHeures ? option.nbHeures : 0;
                    this.bonTravail.coutReelPieces += option.montantPieces ? option.montantPieces : 0;
                }
            }
        });
    }
}