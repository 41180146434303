<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-confirmPopup key="popup"></p-confirmPopup>
<p-menu #menuContextuelItemCommande popup="popup" [model]="menuContextuelEntrees"></p-menu>

<p-card *ngIf="!EstCharge()">
	<div class="fluid formgrid grid">
		<div class="flex flex-column gap-2 my-2 col-12 text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-card>

<div *ngIf="EstCharge()" class="grid">
	<div class="col-12">
		<div class="card">
			<h5>Pré-commande - {{emplacement.nom}} ({{emplacement.code}})
				<app-bouton-favori [titrePage]="titrePage"></app-bouton-favori>
				
			</h5>

			<p-menubar autoDisplay="false" [model]="menu"></p-menubar>

		</div>
	</div>
</div>


<div *ngIf="EstCharge()" class="grid">
	<div class="col-12">
		<div class="card">

					<p-table #dt [value]="itemsCommande" [columns]="selectedColumns" dataKey="itemCommandeId" tableStyleClass="tableNormale"
					[(selection)]="selectionMultipleItemCommande" (onRowSelect)="surChangementSelection()" (onRowUnselect)="surChangementSelection()"
					(onHeaderCheckboxToggle)="surChangementSelection()"
						autoLayout="true" 
						[rowHover]="true"  [filterDelay]="0"
						[globalFilterFields]="['item.numero','item.description','demandeur']">
						<ng-template pTemplate="caption">
							<div class="flex align-content-center justify-content-between">
								<div>
									<span class="p-input-icon-left">
										<i class="pi pi-search"></i>
										<input pInputText type="text"
											(input)="dt.filterGlobal($event.target.value, 'contains')"
											placeholder="Recherche..." />
									</span>

								</div>
								<h5 class="titreTableau m-0">Items</h5>
								<div>
									<p-multiSelect [options]="colonnesDisponibles" [(ngModel)]="colonnesSelectionnees" optionLabel="entete"
										selectedItemsLabel="{0} colonnes sélectionnés" [style]="{minWidth: '200px'}"
										placeholder="Colonnes..."></p-multiSelect>
								</div>
							</div>
						</ng-template>
						<ng-template pTemplate="header" let-columns>
							<tr>

								<th style="min-width: 3rem">
									<p-tableHeaderCheckbox></p-tableHeaderCheckbox>
								</th>
								<th pSortableColumn="quantite">
									<div class="flex justify-content-between align-items-center">
										Qte
										<p-sortIcon field="quantiteDemande"></p-sortIcon>
										<p-columnFilter type="text" field="quantiteDemande" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
							
								<th pSortableColumn="statut.nom">
									<div class="flex justify-content-between align-items-center">
										Statut
										<p-sortIcon field="statut.nom"></p-sortIcon>
										<p-columnFilter field="statut" matchMode="in" display="menu" [showMatchModes]="false"
										[showOperator]="false" [showAddButton]="false">
										<ng-template pTemplate="filter" let-value let-filter="filterCallback">
											<p-multiSelect [ngModel]="value" [options]="statuts" placeholder="Any"
												(onChange)="filter($event.value)" optionLabel="name">
												<ng-template let-option pTemplate="item">
													<div class="multiselect-representative-option">
	
														<span class="ml-1">{{option.nom}}</span>
													</div>
												</ng-template>
											</p-multiSelect>
										</ng-template>
									</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="item.numero">
									<div class="flex justify-content-between align-items-center">
										Numéro
										<p-sortIcon field="item.numero"></p-sortIcon>
										<p-columnFilter type="text" field="item.numero" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="item.description">
									<div class="flex justify-content-between align-items-center">
										Description
										<p-sortIcon field="item.description"></p-sortIcon>
										<p-columnFilter type="text" field="item.description" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="item.quantite" *ngIf="EstColonneSelectionnee('inventaire')">
									<div class="flex justify-content-between align-items-center">
										Inv.
										<p-sortIcon field="item.quantite"></p-sortIcon>
										<p-columnFilter type="number" field="item.quantite" display="menu" class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="demandeur.prenom" *ngIf="EstColonneSelectionnee('demandeur')">
									<div class="flex justify-content-between align-items-center">
										Demandeur
										<p-sortIcon field="demandeur.prenom"></p-sortIcon>
										<p-columnFilter type="text" field="demandeur.prenom" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="employe.prenom" *ngIf="EstColonneSelectionnee('saisiPar')">
									<div class="flex justify-content-between align-items-center">
										Saisi par
										<p-sortIcon field="employe.prenom"></p-sortIcon>
										<p-columnFilter type="text" field="employe.prenom" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="dateDemande" *ngIf="EstColonneSelectionnee('dateDemande')">
									<div class="flex justify-content-between align-items-center">
										Date de demande
										<p-sortIcon field="dateDemande"></p-sortIcon>
										<p-columnFilter type="text" field="dateDemande" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="dateRequis" *ngIf="EstColonneSelectionnee('dateRequis')">
									<div class="flex justify-content-between align-items-center">
										Date réquis
										<p-sortIcon field="dateRequis"></p-sortIcon>
										<p-columnFilter type="text" field="dateRequis" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="codeProjet" *ngIf="EstColonneSelectionnee('codeProjet')">
									<div class="flex justify-content-between align-items-center">
										Code projet
										<p-sortIcon field="codeProjet"></p-sortIcon>
										<p-columnFilter type="text" field="codeProjet" display="menu" class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="codeActivite" *ngIf="EstColonneSelectionnee('codeActivite')">
									<div class="flex justify-content-between align-items-center">
										Code activité
										<p-sortIcon field="codeActivite"></p-sortIcon>
										<p-columnFilter type="text" field="codeActivite" display="menu" class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th *ngIf="EstColonneSelectionnee('departement')" pSortableColumn="departement.nom">
									<div class="flex justify-content-between align-items-center">
										Département
										<p-sortIcon field="departement.nom"></p-sortIcon>
										<p-columnFilter type="text" field="departement.nom" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th  pSortableColumn="emplacement" *ngIf="EstColonneSelectionnee('emplacement')">
									<div class="flex justify-content-between align-items-center">
										Emplacement
										<p-sortIcon field="emplacement"></p-sortIcon>
										<p-columnFilter type="text" field="emplacement" display="menu" class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th  pSortableColumn="note" *ngIf="EstColonneSelectionnee('note')">
									<div class="flex justify-content-between align-items-center">
										Note
										<p-sortIcon field="note"></p-sortIcon>
										<p-columnFilter type="text" field="note" display="menu" class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th style="min-width: 7rem; width: 7rem;"></th>
							</tr>
						</ng-template>
						<ng-template pTemplate="body" let-item>
							
							<tr [pContextMenuRow]="item">
								<td>
									<p-tableCheckbox [value]="item"></p-tableCheckbox>
								</td>
								<td>{{item.quantiteDemande}}</td>
								<td><span [class]="'statut ' + item.statut.nom.replace(' ','').toLowerCase()">
									{{item.statut.nom}}
								</span>
								</td>
							
								<td>{{item.item.numero}}</td>
								<td>{{item.item.description}}</td>
								<td *ngIf="EstColonneSelectionnee('inventaire')">
									<span [ngClass]="item.item.quantite && item.item.quantite > 0 ? item.item.quantite >= item.quantiteDemande ? 'inventaire disponible' : 'inventaire insuffisant' : 'inventaire nondisponible'">
										{{item.item.quantite}}
									</span>
								</td>
								<td *ngIf="EstColonneSelectionnee('demandeur')">{{item.demandeur | nomEmploye}}</td>
								<td *ngIf="EstColonneSelectionnee('saisiPar')">{{item.employe | nomEmploye}}</td>
								<td *ngIf="EstColonneSelectionnee('dateDemande')">{{item.dateDemande | date:'yyyy-MM-dd'}}</td>
								<td *ngIf="EstColonneSelectionnee('dateRequis')">{{item.dateRequis | date:'yyyy-MM-dd'}}</td>
								<td *ngIf="EstColonneSelectionnee('codeProjet')">{{item.codeProjet?.code}}</td>
								  <td *ngIf="EstColonneSelectionnee('codeActivite')">{{item.codeActivite?.code}}</td>
								  <td *ngIf="EstColonneSelectionnee('departement')">{{item.departement?.nom}}</td>
								<td  *ngIf="EstColonneSelectionnee('emplacement')">
									{{item.item.emplacement | nomEmplacement}}
								</td>
								<td *ngIf="EstColonneSelectionnee('note')">{{item.note}}</td>
								<td>
									<button *ngIf="estAutorise('approbation_precommande_supprimer_item')" pButton pRipple icon="pi pi-trash"
										class="p-button-danger mr-2 boutonGrille"
										(click)="supprimerItemcommnde($event, item)"></button>
										<button pButton pRipple icon="pi pi-bars"
											class="p-button-secondary mr-2 boutonGrille"
											(click)="surMenuContextuelItem($event, item)"></button>
								</td>
							</tr>
						</ng-template>
						<ng-template pTemplate="emptymessage">
							<tr>
								<td colspan="6">Aucun item.</td>
							</tr>
						</ng-template>
					</p-table>
		</div>
	</div>


</div>

<div *ngIf="EstCharge()" class="grid">
	<div class="col-12">
		<div class="card">
			<p-menubar autoDisplay="false" [model]="menu"></p-menubar>

		</div>
	</div>
</div>