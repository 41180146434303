import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from "@angular/core"; import { Location } from "@angular/common";

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { ItemMin } from '../entites/item-min';
import { Router } from '@angular/router';
import { ReponseBase } from './reponse-base';
import { ItemService } from './item-service';
import { BonTravailEntite } from '../entites/mecanique/bon-travail';
import { resolve } from 'dns';
import { EtapeBonTravailEntite } from '../entites/mecanique/etape-bon-travail';
import { EStatuts } from '../entites/enums/statuts';
import { DonneesBaseService } from './donnees-base-service';
import { VadEntite } from '../entites/mecanique/verification-avant-depart';

@Injectable({ providedIn: 'root' })
export class VadService {

  private obtenirListeUrl = environment.apiBaseUrl + 'bonsTravail';
   private obtenirListeParStatutUrl = environment.apiBaseUrl + 'bonsTravail/?statutId={statutId}';
  private obtenirDetailUrl = environment.apiBaseUrl + 'bonTravail/{bonTravailId}';
  private modifierEtapeBonTravailUrl = environment.apiBaseUrl + 'bonTravail/etape/modifier';
/*  private creerUrl = environment.apiBaseUrl + 'achat/bonCommande';
  private modifierBonCommandeUrl = environment.apiBaseUrl + 'achat/bonCommande/modifier';
  private creerItemBonCommandeUrl = environment.apiBaseUrl + 'achat/bonCommande/item';
  private modifierItemBonCommandeUrl = environment.apiBaseUrl + 'achat/bonCommande/item/modifier';
  private supprimerUrl = environment.apiBaseUrl + 'achat/bonCommande/supprimer';
  private supprimerItemUrl = environment.apiBaseUrl + 'achat/bonCommande/supprimerItems';
  private obtenirNumeroCtrlUrl = environment.apiBaseUrl + 'achat/bonCommande/obtenirNumeroCTRL';
  private transfererVersCtrlUrl = environment.apiBaseUrl + 'achat/bonCommande/transfertCTRL';
  private obtenirHsitoriqueItemUrl = environment.apiBaseUrl + 'achat/historiqueItem/{itemId}';
  private obtenirBonCommandeOuvertFournisseurUrl = environment.apiBaseUrl + 'achat/bonCommandeOuvert/{fournisseurId}/{employeId}';
  private corrigerBonCommandeUrl = environment.apiBaseUrl + 'achat/bonCommande/corriger'; */
  
  public httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(
    private sdb: DonneesBaseService,
    public itemService: ItemService,
    private http: HttpClient,
    public router: Router) { }

  public ObtenirVads(itemId: number): Promise<ReponseBase<VadEntite[]>> {

    var p = new Promise<ReponseBase<VadEntite[]>>((resolve, reject) => {
      var r = new ReponseBase<VadEntite[]>();
      r.estUnSucces = true;

        r.valeur = [
          {
            vadId: 1,
            date: new Date(),
            chauffeur: 'GUILLAUME BOURGEOIS',
            description: 'Liste 1 SAAQ - Véhicules et Remorques',
            statut: this.sdb.Statut(EStatuts.Complete),
            nbItems: 84,
            nbDefauts: 0
          },
          {
            vadId: 2,
            date: new Date(),
            chauffeur: 'GUILLAUME BOURGEOIS',
            description: 'Liste 1 SAAQ - Véhicules et Remorques',
            statut: this.sdb.Statut(EStatuts.EnCours),
            nbItems: 84,
            nbDefauts: 2
          },
          {
            vadId: 3,
            date: new Date(),
            chauffeur: 'GUILLAUME BOURGEOIS',
            description: 'Liste 1 SAAQ - Véhicules et Remorques',
            statut: this.sdb.Statut(EStatuts.Complete),
            nbItems: 84,
            nbDefauts: 0
          }
        ];

           

      resolve(r);
      
    });

    return p;
    
      
  }

  public ObtenirVad(vadId: number): Promise<ReponseBase<VadEntite>> {

    var p = new Promise<ReponseBase<VadEntite>>((resolve, reject) => {
      var r = new ReponseBase<VadEntite>();
      r.estUnSucces = true;
      r.valeur = 
        {
          vadId: 2,
            date: new Date(),
            chauffeur: 'GUILLAUME BOURGEOIS',
            description: 'Liste 1 SAAQ - Véhicules et Remorques',
            statut: this.sdb.Statut(EStatuts.Complete),
            nbItems: 84,
            nbDefauts: 2,
          items:[
            {
              itemVadId:1, 
              description: '9.2 Un pneu, d’un même assemblage de roues, présente une matière étrangère logée dans la bande de roulement ou dans le flanc et qui peut causer une crevaison',
              indDefectueux: true,
              type: {
                typeItemVadId: 1,
                description: 'Mineur'
              }
            },
            {
              itemVadId:1, 
              description: '19.D Réduction importante de la capacité de freinage du frein de service',
              indDefectueux: true,
              type: {
                typeItemVadId: 1,
                description: 'Majeur'
              },
              noBonTravail: '001354'
            }
          ]
         
        }
      ;      

      resolve(r);
      
    });

    
    return p;

    /* var p = this.http.get<ReponseBase<BonTravailEntite>>(this.obtenirDetailUrl.replace('{bonTravailId}',bonTravailId.toString()))
    .toPromise();
  
    p.catch(() => this.router.navigateByUrl('error'));
  
    return p; */
  }


  
  public ModifierEtapeBonTravail(requete: EtapeBonTravailEntite): Promise<ReponseBase<EtapeBonTravailEntite>> {

    //TODO : Temp : 
    return new Promise<ReponseBase<EtapeBonTravailEntite>>((resolve) => {
      resolve({
        estUnSucces: true,
        valeur: requete,
        messages: null
      });
    });

    var p = this.http.post<ReponseBase<EtapeBonTravailEntite>>(this.modifierEtapeBonTravailUrl, JSON.stringify(requete), this.httpOptions).toPromise();
  
    p.catch(() => this.router.navigateByUrl('error'));
  
    return p;
  }



  /* public ObtenirHistoriqueItem(itemId: number): Promise<ReponseBase<ItemBonCommande[]>> {
    var p = this.http.get<ReponseBase<ItemBonCommande[]>>(this.obtenirHsitoriqueItemUrl.replace('{itemId}',itemId.toString()))
    .toPromise();

    p.catch(() => this.router.navigateByUrl('error'));

    return p;
}

public SupprimerBonCommande(bonCommandeId: number): Promise<ReponseBase<boolean>> {
  var p = this.http.post<ReponseBase<boolean>>(this.supprimerUrl, JSON.stringify(bonCommandeId), this.httpOptions ).toPromise();

  p.catch(() => this.router.navigateByUrl('error'));

  return p;
}

public SupprimerItemsBonCommande(bonCommandeIds: number[]): Promise<ReponseBase<boolean>> {
  var p = this.http.post<ReponseBase<boolean>>(this.supprimerItemUrl, JSON.stringify(bonCommandeIds), this.httpOptions ).toPromise();

  p.catch(() => this.router.navigateByUrl('error'));

  return p;
}

public CreerBonCommande(requete: BonCommandeEntite): Promise<ReponseBase<number>> {
  var p = this.http.post<ReponseBase<number>>(this.creerUrl, JSON.stringify(requete), this.httpOptions).toPromise();

  p.catch(() => this.router.navigateByUrl('error'));

  p.then(() => this.itemService.ReinitialiserItems());

  return p;
}

public CreerItemBonCommande(requete: ItemBonCommande): Promise<ReponseBase<ItemBonCommande>> {
  var p = this.http.post<ReponseBase<ItemBonCommande>>(this.creerItemBonCommandeUrl, JSON.stringify(requete), this.httpOptions).toPromise();

  p.catch(() => this.router.navigateByUrl('error'));

  p.then(() => this.itemService.ReinitialiserItems());

  return p;
}

public ModifierItemBonCommande(requete: ItemBonCommande): Promise<ReponseBase<ItemBonCommande>> {
  var p = this.http.post<ReponseBase<ItemBonCommande>>(this.modifierItemBonCommandeUrl, JSON.stringify(requete), this.httpOptions).toPromise();

  p.catch(() => this.router.navigateByUrl('error'));

  p.then(() => this.itemService.ReinitialiserItems());

  return p;
}

public CorrigerBonCommande(requete: BonCommandeEntite): Promise<ReponseBase<BonCommandeEntite>> {
  var p = this.http.post<ReponseBase<BonCommandeEntite>>(this.corrigerBonCommandeUrl, JSON.stringify(requete), this.httpOptions).toPromise();

  p.catch(() => this.router.navigateByUrl('error'));

  return p;
}

public ObtenirNumeroCtrl(bonCommandeId: number): Promise<ReponseBase<string>> {
  var p = this.http.post<ReponseBase<string>>(this.obtenirNumeroCtrlUrl, JSON.stringify(bonCommandeId), this.httpOptions).toPromise();

  p.catch(() => this.router.navigateByUrl('error'));

  return p;
}

public TransfererVersCtrl(requete: RequeteTransfererVersCtrl): Promise<ReponseBase<string>> {
  var p = this.http.post<ReponseBase<string>>(this.transfererVersCtrlUrl, JSON.stringify(requete), this.httpOptions).toPromise();

  p.catch(() => this.router.navigateByUrl('error'));

  p.then(() => this.itemService.ReinitialiserItems());

  return p;
}

public ObtenirBonCommande(bonCommandeId: number): Promise<ReponseBase<BonCommandeEntite>> {
  var p = this.http.get<ReponseBase<BonCommandeEntite>>(this.obtenirDetailUrl.replace('{bonCommandeId}',bonCommandeId.toString()))
  .toPromise();

  p.catch(() => this.router.navigateByUrl('error'));

  return p;
}


public ModifierBonCommande(requete: BonCommandeEntite): Promise<ReponseBase<BonCommandeEntite>> {
  var p = this.http.post<ReponseBase<ItemBonCommande>>(this.modifierBonCommandeUrl, JSON.stringify(requete), this.httpOptions).toPromise();

  p.catch(() => this.router.navigateByUrl('error'));

  return p;
}

public ObtenirBonCommandeOuvertFournisseur(fournisseurId: number, employeId: number): Promise<ReponseBase<BonCommandeEntite>> {
  var p = this.http.get<ReponseBase<BonCommandeEntite>>(this.obtenirBonCommandeOuvertFournisseurUrl.replace('{fournisseurId}',fournisseurId.toString()).replace('{employeId}',employeId.toString()))
  .toPromise();

  p.catch(() => this.router.navigateByUrl('error'));

  return p;
} */

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);

      this.log(`${operation} failed: ${error.message}`);

      this.router.navigateByUrl('erreurtechnique');

      return of(result as T);
    };
  }

  private log(message: string) {
      console.log(message);
  }
}
