<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-menu #menuContextuelItemCommande popup="popup" [model]="menuContextuelEntrees"></p-menu>

<p-card *ngIf="!EstCharge()">
    <div class="fluid formgrid grid">
        <div class="flex flex-column gap-2 my-2 col-12 text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-card>

<div class="grid" *ngIf="EstCharge()">
    <div class="col-12">
        <p-tabView [(activeIndex)]="activeIndex">
            <p-tabPanel header="Soumissions"></p-tabPanel>
            <p-tabPanel header="Bons de travail"></p-tabPanel>
        </p-tabView>
        <div class="card">
            <p-table #dt [value]="liste" [columns]="selectedColumns" dataKey="bonTravailId" autoLayout="true"
                tableStyleClass="tableNormale" 
                [(contextMenuSelection)]="selectionBonTravail" [rowHover]="true" [rows]="100" [paginator]="true"
                [rowsPerPageOptions]="[10,25,50,100]" [filterDelay]="0"
                [globalFilterFields]="['numero','date','item.numero','item.description','mecanicien.nom','mecanicien.prenom','contreMaitre.nom','contreMaitre.prenom']">
                <ng-template pTemplate="caption">
                    <div class="flex align-content-center justify-content-between flex-wrap gap-2">
                        <div>
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Recherche..." />
                            </span>
                            <button *ngIf="estAutorise('bontravail_creer') || estAutorise('temp_mec')" pButton pRipple icon="pi pi-plus"
                                class="p-button-success mr-2 ml-4 mb-2 boutonGrille"
                                (click)="AjouterBdt()"></button>
                        </div>
                        <h5 class="titreTableau m-0">{{activeIndex == 0 ? 'Soumissions' : 'Bons de travail'}}</h5>
                        <div>
                            <p-multiSelect [options]="colonnesDisponibles" [(ngModel)]="selectedColumns"
                            optionLabel="entete"
                            selectedItemsLabel="{0} colonnes sélectionnés" [style]="{minWidth: '200px'}"
                            placeholder="Colonnes..."></p-multiSelect>
                        </div>
                    </div>
                </ng-template>

                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th pSortableColumn="numero">
                            <div class="flex justify-content-between align-items-center">
                                Numéro
                                <p-sortIcon field="numero"></p-sortIcon>
                                <p-columnFilter type="text" field="numero" display="menu" class="ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="dateDebut">
                            <div class="flex justify-content-between align-items-center">
                                Date
                                <p-sortIcon field="dateDebut"></p-sortIcon>
                                <p-columnFilter type="date" field="dateDebut" display="menu" class="ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="item.numero">
                            <div class="flex justify-content-between align-items-center">
                                Unité
                                <p-sortIcon field="item.numero"></p-sortIcon>
                                <p-columnFilter type="text" field="item.numero" display="menu" class="ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="item.description">
                            <div class="flex justify-content-between align-items-center">
                                Description
                                <p-sortIcon field="item.description"></p-sortIcon>
                                <p-columnFilter type="text" field="item.description" display="menu" class="ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="type">
                            <div class="flex justify-content-between align-items-center">
                                Statut
                                <p-sortIcon field="type"></p-sortIcon>
                                <p-columnFilter field="statut" matchMode="in" display="menu" [showMatchModes]="false"
                                    [showOperator]="false" [showAddButton]="false">
                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                        <p-multiSelect [ngModel]="value" [options]="statuts" placeholder="Any"
                                            (onChange)="filter($event.value)" optionLabel="name">
                                            <ng-template let-option pTemplate="item">
                                                <div class="multiselect-representative-option">

                                                    <span class="ml-1">{{option.nom}}</span>
                                                </div>
                                            </ng-template>
                                        </p-multiSelect>
                                    </ng-template>
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="contreMaitre.prenom">
                            <div class="flex justify-content-between align-items-center">
                                Contre-Maître
                                <p-sortIcon field="contreMaitre.prenom"></p-sortIcon>
                                <p-columnFilter type="text" field="contreMaitre.prenom" display="menu" class="ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="mecanicien.prenom">
                            <div class="flex justify-content-between align-items-center">
                                Mécanicien
                                <p-sortIcon field="mecanicien.prenom"></p-sortIcon>
                                <p-columnFilter type="text" field="mecanicien.prenom" display="menu" class="ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>

                       <!--  <th *ngIf="EstColonneSelectionnee('nbItems')" pSortableColumn="nbItems">
                            <div class="flex justify-content-between align-items-center">
                                Nb items
                                <p-sortIcon field="nbItems"></p-sortIcon>
                                <p-columnFilter type="text" field="nbItems" display="menu" class="ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th *ngIf="EstColonneSelectionnee('coutTotal')" pSortableColumn="coutTotal">
                            <div class="flex justify-content-between align-items-center">
                                Coût total
                                <p-sortIcon field="coutTotal"></p-sortIcon>
                                <p-columnFilter type="text" field="coutTotal" display="menu" class="ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th *ngIf="EstColonneSelectionnee('employe')" pSortableColumn="employe.prenom">
                            <div class="flex justify-content-between align-items-center">
                                Employé
                                <p-sortIcon field="employe.prenom"></p-sortIcon>
                                <p-columnFilter type="text" field="employe.prenom" display="menu" class="ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th *ngIf="EstColonneSelectionnee('dateEnvoi')" pSortableColumn="dateEnvoi">
                            <div class="flex justify-content-between align-items-center">
                                Date d'envoi
                                <p-sortIcon field="dateEnvoi"></p-sortIcon>
                                <p-columnFilter type="date" field="dateEnvoi" display="menu" class="ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th *ngIf="EstColonneSelectionnee('envoyeA')" pSortableColumn="courrielEnvoi">
                            <div class="flex justify-content-between align-items-center">
                                Envoyé à
                                <p-sortIcon field="courrielEnvoi"></p-sortIcon>
                                <p-columnFilter type="text" field="courrielEnvoi" display="menu" class="ml-auto">
                                </p-columnFilter>
                            </div>
                        </th> -->
		                    <th pSortableColumn="type">
			                    <div class="flex justify-content-between align-items-center">
				                    Fournisseur
				                    <p-sortIcon field="fournisseur.nom"></p-sortIcon>
				                    <p-columnFilter type="text" field="fournisseur.nom" display="menu" class="ml-auto">
				                    </p-columnFilter>
			                    </div>
		                    </th>
                        <th style="min-width: 10rem; width: 10rem;"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-bdt let-columns="columns">
                    <tr>
                        <!--  <td style="text-align: center">
                            <button pButton (click)="OuvrirDetailBdc(bdc.bdcId)" type="button"
                                class="p-button-secondary" icon="pi pi-angle-double-right"></button>
                        </td> -->
                        <td>
                            <a *ngIf="estAutorise('bontravail_detail')" [routerLink]="'/mecanique/bontravail/' + bdt.bonTravailId">{{bdt.numero}}</a>
                            <span *ngIf="!estAutorise('bontravail_detail')">{{bdt.numero}}</span>

                        </td>
                        <td>{{bdt.dateTravail | date:'yyyy-MM-dd'}}</td>
                        <td>{{bdt.item.numero}}</td>
                        <td>{{bdt.item.description}}</td>
                        <td><span [class]="'statut ' + bdt.statut.nom.toLowerCase().replace(' ', '')">
                                {{bdt.statut.nom}}
                            </span></td>
                        <td >{{bdt.contreMaitre | nomEmploye}}</td>
                        <td>{{bdt.mecanicien | nomEmploye}}</td>
                        <!-- <td *ngIf="EstColonneSelectionnee('nbItems')">{{bdt.nbItems}} ({{bdt.nbItemsTotal}})</td>
                        <td *ngIf="EstColonneSelectionnee('coutTotal')">{{bdt.coutTotal | currency}}</td>
                        <td *ngIf="EstColonneSelectionnee('employe')">{{bdt.employe | nomEmploye}}</td>
                        <td *ngIf="EstColonneSelectionnee('dateEnvoi')">{{bdt.dateEnvoi | date:'yyyy-MM-dd HH:mm:ss'}}</td>
                        <td *ngIf="EstColonneSelectionnee('envoyeA')">{{bdt.courrielEnvoi}}</td> -->
	                      <td>{{bdt.fournisseur?.nom}}</td>
                        <td class="celluleTableau">
                            <div class="flex">  
                                <button *ngIf="estAutorise('bontravail_detail') || estAutorise('temp_mec')" pButton pRipple icon="pi pi-eye" class="p-button-success mr-2 boutonGrille"
                                    (click)="OuvrirDetailBdt(bdt.bonTravailId)"></button>
                                <button *ngIf="estAutorise('bontravail_detail') || estAutorise('temp_mec')" pButton pRipple icon="pi pi-external-link"
                                    class="p-button-secondary mr-2 boutonGrille"
                                        (click)="OuvrirDetailBdtNouvelOnglet(bdt.bonTravailId)"></button>
                                <button pButton pRipple icon="pi pi-bars" class="p-button-secondary mr-2 boutonGrille"
                                    (click)="surMenuContextuelItem($event, bdt)"></button>
                            </div>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="9">{{activeIndex == 0 ? 'Aucune soumission.' : 'Aucun bon de travail.'}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>


</div>

<p-sidebar [(visible)]="afficherDetailBdt" [style]="{width:'95%'}" position="left">
    <app-detail-bdc [bdcId]="detailBdtId" *ngIf="afficherDetailBdt"></app-detail-bdc>
	</p-sidebar>