<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<p-card *ngIf="!EstCharge()">
	<div class="fluid formgrid grid">
		<div class="flex flex-column gap-2 col-12 text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-card>

<div *ngIf="EstCharge()" class="grid">
	<div class="col-12">
		<div class="card">
			<p-menubar autoDisplay="false" [model]="menu">
			</p-menubar>

		</div>
	</div>
</div>


<div *ngIf="EstCharge()" class="grid">
	<div class="col-12">
		<div class="card">
				<div class="fluid formgrid grid">
					<div class="flex flex-column gap-2 mb-3 col-12 md:col-6 lg:col-3 flex flex-column">
						<label for="lastname">Date</label>
						<p-calendar class="p-calendar" appendTo="body" showIcon=true [(ngModel)]="dateDocument" dateFormat="yy-mm-dd" [yearNavigator]="true" [monthNavigator]="true" yearRange="2000:2040" ></p-calendar>
						<small class="p-error" *ngIf="submitted && !dateDocument">La date est obligatoire.</small>
					</div>
					<div *ngIf="modeDocument == 1 || modeDocument == 5" class="flex flex-column gap-2 mb-3 col-12 md:col-6 lg:col-3">
						<label for="lastname">Dossier</label>
						<p-dropdown [options]="typesDocument" [(ngModel)]="selectionTypeDocument" optionLabel="nom"
								 appendTo="body" filter="true" filterBy="nom"
								placeholder="Sélectionnez un type de document...">
								<ng-template pTemplate="selectedItem">
									<div *ngIf="selectionTypeDocument">
										<div>{{selectionTypeDocument.nom}}</div>
									</div>
								</ng-template>
								<ng-template let-compagnie pTemplate="item">
									<div>
										<div>{{compagnie.nom}}</div>
									</div>
								</ng-template>
							</p-dropdown>
							<small class="p-error" *ngIf="submitted && !selectionTypeDocument">Le dossier est obligatoire.</small>
					</div>
					<div class="flex flex-column gap-2 col-12">
						<p-fileUpload 
							#fu
							name="myfile[]"
							multiple="multiple"
							customUpload="true"
							maxFileSize="209715200"
							invalidFileSizeMessageSummary="{0}: Fichier trop volumineux, "
							invalidFileSizeMessageDetail="la grosseur maximale est {0}."
							chooseLabel="Ajouter"
							uploadLabel="Confirmer"
							cancelLabel="Annuler"
							(onUpload)="onBasicUpload($event)" 
							(uploadHandler)="onUpload($event)"
							(onSelect)="onSelect($event)">
							<ng-template pTemplate="content">
								<div class="flex gap-3 justify-content-center align-items-center border-1 border-dashed border-round surface-border surface-300 p-6">
									<i class="pi pi-cloud-upload text-6xl text-600"></i>
									<div class="text-900">Glisser-déposer un document ici</div>
								</div>
							</ng-template>
						</p-fileUpload>
					</div>
				</div>
		</div>
	</div>


</div>