import { Component, OnInit, ViewChild, Input } from "@angular/core"; import { Location } from "@angular/common";
import { Table } from 'primeng/table';
import { BreadcrumbService } from '../../app.breadcrumb.service';

import { RequisitionService } from '../../services/requisition-service';
import { RequisitionMin } from '../../entites/requisition-min';
import { MenuItem } from 'primeng/api/menuitem';
import { MessageService, LazyLoadEvent, ConfirmationService } from 'primeng/api';
import {ToastModule} from 'primeng/toast';

import {DialogService} from 'primeng/dynamicdialog';
import { EnvoiRequisitionComponent } from '../../controles/envoi-requisition.component';
import { Router, ActivatedRoute } from '@angular/router';
import { ListeAchatService } from '../../services/liste-achat-service';
import { ListeAchatEntite } from 'src/app/entites/liste-achat';
import { AppMainComponent } from 'src/app/app.main.component';
import { ProfilService } from 'src/app/services/profil-service';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { BaseComponent } from 'src/app/base/base-component';

@Component({
    templateUrl: './approbation-liste-achat.component.html',
    styles: [`
        @media screen and (max-width: 960px) {
            :host ::ng-deep .p-datatable.p-datatable-customers.rowexpand-table .p-datatable-tbody > tr > td:nth-child(6) {
                display: flex;
            }
        }

    `],
    providers: [MessageService, ConfirmationService, DialogService]
})
export class ApprobationListeAchatComponent extends BaseComponent implements OnInit {

    listes: ListeAchatEntite[];
    selectionListe: ListeAchatEntite;

    menuContextuel: MenuItem[];

    loaded: boolean = false;

    @ViewChild('dt') table: Table;

    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute, 
		appMain: AppMainComponent, location: Location, 
        private breadcrumbService: BreadcrumbService,
        private listeAchatService: ListeAchatService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        public dialogService: DialogService,
        public routerService: Router) {
            super(sdb, profilService, route, routerService, appMain);
        this.breadcrumbService.setItems([
            { label: 'Approbation des listes d\'achat' }
        ]);
    }

    ngOnInit() {

        this.verifierAccesPage('approuver_liste_achat');

           this.listeAchatService.ObtenirListes().then(x => {
               this.listes = x.valeur;
               this.CompleterChargement();
           });

           
        this.menuContextuel = [
            { label: 'Ouvrir', icon: 'pi pi-fw pi-search', command: () => this.OuvrirDetailListeAchat(this.selectionListe.listeAchatId) }
        ];

       
    }

    OuvrirDetailListeAchat(listeAchatId: number) {
        this.routerService.navigateByUrl(`approbationlisteachat/${listeAchatId}`)
    }
}
