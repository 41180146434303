import { Component, ViewChild, ElementRef, ɵConsole } from "@angular/core"; import { Location } from "@angular/common";
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { CleValeur, CleValeurNumerique } from '../entites/cleValeur';
import { EntreeCommande } from '../entites/entree-commande';
import { ItemMin } from '../entites/item-min';
import { Statut } from '../entites/statut';
import { DonneesBaseService } from '../services/donnees-base-service';
import { BaseComponent } from '../base/base-component';
import { ItemService } from '../services/item-service';
import { BlockUIModule } from 'primeng/blockui';
import { FournisseurService } from '../services/fournisseur-service';
import { Fournisseur } from '../entites/fournisseur';
import { EmployeEntite } from '../entites/employe';
import { ItemCommandeEntite } from '../entites/item-commande';
import { MessageService, MenuItem } from 'primeng/api';
import { Table } from 'primeng/table/table';
import { ProfilService } from '../services/profil-service';
import { ActivatedRoute, Router } from '@angular/router';
import { RequisitionService } from '../services/requisition-service';
import { AppMainComponent } from '../app.main.component';
import { CodeProjetEntite } from '../entites/code-projet';
import { CodeActiviteEntite } from '../entites/code-activite';
import { CodeCategorieEntite } from '../entites/code-categorie';
import { CodeEquipementEntite } from '../entites/code-equipement';
import { CodeExtraEntite } from '../entites/code-extra';
import { ReleveHeureEntite } from '../entites/releve-heures';
import { ReleveKmEntite } from '../entites/releve-kms';
import { ContextMenu } from 'primeng/contextmenu/public_api';
import { CreationPieceComponent, ResultatCreationPiece } from './mecanique/creation-piece.component';
import { Item } from '../entites/item';
import { CreationItemComponent } from './creation-item.component';
import { ItemSoumissionEntite } from '../entites/item-soumission';
import { CategorieEntretienEntite } from '../entites/mecanique/categorie-entretien';
import { Emplacement } from '../entites/emplacement';

export interface ParametresSelectionItem {
    type: ETypeSelectionItem;
    employeId?: number;
    description?: string;
    fournisseurId?: number;
    demandeurId?: number;
    selectionCodeProjet?: number;
    selectionCodeActivite?: number;
    selectionCodeCategorie?: number;
    selectionCodeEquipement?: number;
    indAjoutCommande?: boolean;
    selectionDemandeurId?: number;
    selectionUniteId?: number;
    selectionEmplacementCommandeId?: number;
    emplacementIdVerificationTransfertExpress?: number;
}

export interface ResultatSelectionItem {
    type: ETypeSelectionItem;
    item?: ItemMin;
    quantiteDemande?: number;
    uniteQte?: string;
    expediteur?: string;
    demandeur?: EmployeEntite;
    noFournisseur?: string;
    prix?: number;
    note?: string;
    codeProjet?: CodeProjetEntite;
    codeActivite?: CodeActiviteEntite;
    codeCategorie?: CodeCategorieEntite;
    codeEquipement?: CodeEquipementEntite;
    codeExtra?: CodeExtraEntite;
    emplacement?: Emplacement;
    dateRequis?: Date;
    dateDemande?: Date;
    unite?: ItemMin;
    indAssociation?: boolean;
    categorieEntretienId?: number;
    indConserverPrix?: boolean;

    indAjoutCommande?: boolean;
    emplacementAjoutCommande?: Emplacement;
    uniteAjoutCommande?: ItemMin;

}

export enum ETypeSelectionItem {
    Commande = 1,
    Requisition = 2,
    BonCommande = 3,
    ListeAchat = 4,
    PreCommande = 5,
    Recherche = 6
}

@Component({
    selector: 'app-selection-item',
    templateUrl: './selection-item.component.html',
    host: {
        '(document:keydown)': 'handleKeyboardEvents($event)'
    }
})
export class SelectionItemComponent extends BaseComponent {


    //Général

    donnees: ParametresSelectionItem;

    
    detailItemId: number;
    afficherDetailItem: boolean = false;

    typesSaisie: CleValeur[];
    selectionTypeSaisie: CleValeur;
    estTypeSaisieDisponible: boolean = true;

    listeCategories: CleValeurNumerique[];
    selectionCategorie: CleValeurNumerique;

    nouveauDisponible: boolean;

    submitted: boolean;
    blocked: boolean = false;

    itemsComplets: ItemMin[];
    items: ItemMin[];
    selectionItem: ItemMin = { itemId: 0 };
    categories: string[];
    statuts: Statut[] = this.sdb.Statuts();

    itemSelectionne: boolean = false;

    afficherColonneNumero: boolean = true;
    afficherColonneStatut: boolean = true;
    afficherColonneInventaire: boolean = true;
    afficherColonneNumeroEmplacement: boolean = true;


    //Mode Pré-commande
    dateRequis: Date = new Date();
    estCodeActiviteObligatoire: boolean = true;
    estCodeCategorieObligatoire: boolean = true;

    //Mode commande

    dateDemande: Date = new Date();
    listeEmployes: EmployeEntite[] = [];
    selectionDemandeur: EmployeEntite;

    quantiteDemande?: number;
    expediteur?: string;

    listeUnites: ItemMin[];
    selectionUnite: ItemMin;



    //Mode bon de commande

    noFournisseur: string;
    quantite: number;
    uniteQte: string;
    prix: number;
    note: string;

    indAjoutCommande: boolean = false;

    listeCodeProjet: CodeProjetEntite[];
    selectionCodeProjet: CodeProjetEntite;

    listeCodeActivite: CodeActiviteEntite[];
    selectionCodeActivite: CodeActiviteEntite;

    listeCodeCategorie: CodeCategorieEntite[];
    selectionCodeCategorie: CodeCategorieEntite;

    listeCodeEquipement: CodeEquipementEntite[];
    selectionCodeEquipement: CodeEquipementEntite;

    listeCodeExtra: CodeExtraEntite[];
    selectionCodeExtra: CodeExtraEntite;

    listeEmplacement: Emplacement[];
    selectionEmplacement: Emplacement;

    
    listeEmplacementAjoutCommande: Emplacement[];
    selectionEmplacementAjoutCommande: Emplacement;

    
    selectionUniteAjoutCommande: ItemMin;

    codeCategorieBloque: boolean = false;
    codeEquipementBloque: boolean = false;

    indAssocier: boolean = true;
    listeCategoriesEntretien: CategorieEntretienEntite[];
    selectionCategorieEntretien: CategorieEntretienEntite;
    // Ajustements inventaire
    emplacementIdVerificationTransfertExpress: number;
    estAjustementNecessaire: boolean = false;
    selectionTypeAjustement: string;
    fournisseurs: Fournisseur[];
    selectionFournisseur: Fournisseur;

    dateRetourAuto: Date = new Date();

    contenuMenuContextuelAjout: MenuItem[];
    @ViewChild('menuContextuelAjout') menuContextuelAjout: ContextMenu;

    @ViewChild('rechercheItem') inputRechercheItem;
    @ViewChild('champQuantite') inputQuantite;
    @ViewChild('champDemandeur') inputDemandeur;
    @ViewChild('champExpediteur') inputExpediteur;
    @ViewChild('champFiltreCategorie') inputFiltreCategorie;
    @ViewChild('champCodeProjet') champCodeProjet;
    @ViewChild('champCodeActivite') champCodeActivite;
    @ViewChild('champCodeCategorie') champCodeCategorie;
    @ViewChild('champNote') champNote;
    @ViewChild('filtreCategorie') filtreCategorie;
    @ViewChild('dt') tableItems: Table;

    constructor(
        profilService: ProfilService,
        route: ActivatedRoute,
        routerService: Router,
        public appMain: AppMainComponent, location: Location, 
        public itemService: ItemService,
        public requisitionService: RequisitionService,
        public fournisseurService: FournisseurService,
        public messageService: MessageService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public dialogService: DialogService,
        public sdb: DonneesBaseService) {
        super(sdb, profilService, route, routerService, appMain);
    }



    ngOnInit() {

        this.donnees = this.config.data;

        this.afficherColonneNumero = this.donnees.type != ETypeSelectionItem.PreCommande;
        this.afficherColonneStatut = this.donnees.type != ETypeSelectionItem.PreCommande;
        this.afficherColonneInventaire = this.donnees.type != ETypeSelectionItem.PreCommande;
        this.afficherColonneNumeroEmplacement = this.donnees.type != ETypeSelectionItem.PreCommande;

        this.emplacementIdVerificationTransfertExpress = this.donnees.emplacementIdVerificationTransfertExpress;

        if (this.donnees.type == ETypeSelectionItem.Recherche || this.donnees.type == ETypeSelectionItem.ListeAchat) {
            this.typesSaisie = [
                { "cle": "I", "valeur": "Inventorié" }
            ];
            this.estTypeSaisieDisponible = false;

        } else if (this.donnees.type == ETypeSelectionItem.PreCommande) {
            this.typesSaisie = [
                { "cle": "I", "valeur": "Inventorié" },
                { "cle": "D", "valeur": "Divers" }
            ];
        } else {
            this.typesSaisie = [
                { "cle": "I", "valeur": "Inventorié" },
                //{ "cle": "N", "valeur": "Nouveau" },
                { "cle": "D", "valeur": "Divers" }
            ];
        }
        this.selectionTypeSaisie = this.typesSaisie[0];

        this.nouveauDisponible = this.donnees.type != ETypeSelectionItem.Recherche &&
            this.donnees.type != ETypeSelectionItem.ListeAchat;

        if (this.donnees.type == ETypeSelectionItem.BonCommande) {

            this.listeCategories = [{ "cle": 0, "valeur": 'TOUS' }];
            this.sdb.Categories().filter(x => !x.estUnique).forEach(x => {
                this.listeCategories.push({ "cle": x.categorieId, "valeur": x.nom })
            });
            this.selectionCategorie = this.listeCategories[0];

            if (this.profilService.ProfilCourant().categorieId) {
                this.selectionCategorie = this.listeCategories.find(x => x.cle == this.profilService.ProfilCourant().categorieId)
            }

        } else {

            this.listeCategories = [{ "cle": 0, "valeur": "Tous" }];
            this.sdb.Categories().forEach(x => {
                this.listeCategories.push({ "cle": x.categorieId, "valeur": x.nom })
            });
            if (this.estTypePreCommande()) {
                this.listeCategories = this.listeCategories.filter(x => x.cle != 4);
                this.listeCategories.push({ "cle": -1, "valeur": "Outillage" })
            }
            this.selectionCategorie = this.listeCategories[0];

        }

        if (this.profilService.Profil().roles.filter(x => x.nom == 'SIHC_ContremaitreMecanique').length > 0) {

            this.colonnesDisponibles = [
                { champ: 'categorie', entete: 'Catégorie', afficheParDefaut: true },
                { champ: 'sousCategorie', entete: 'Sous-catégorie', afficheParDefaut: false },
                { champ: 'marque', entete: 'Marque', afficheParDefaut: true },
                { champ: 'modele', entete: 'Modèle', afficheParDefaut: true },
                { champ: 'noProduit', entete: 'No pièce fournisseur', afficheParDefaut: true }
            ];
        } else {
            this.colonnesDisponibles = [
                { champ: 'categorie', entete: 'Catégorie', afficheParDefaut: true },
                { champ: 'sousCategorie', entete: 'Sous-catégorie', afficheParDefaut: true },
                { champ: 'marque', entete: 'Marque', afficheParDefaut: false },
                { champ: 'modele', entete: 'Modèle', afficheParDefaut: true },
                { champ: 'noProduit', entete: 'No pièce fournisseur', afficheParDefaut: false }
            ];
        }


        if (this.donnees.type != ETypeSelectionItem.PreCommande && this.donnees.type != ETypeSelectionItem.ListeAchat) {
            this.colonnesDisponibles.push({ champ: 'emplacement', entete: 'Emplacement', afficheParDefaut: false });
        }
        this.colonnesSelectionnees = this.colonnesDisponibles.filter(x => x.afficheParDefaut);

        this.categories = [];
        this.sdb.Categories().forEach(y => {
            this.categories.push(y.nom);
        });

        this.listeEmployes = this.sdb.Employes();
        this.listeCategoriesEntretien = this.sdb.CategoriesEntretien();

        if (this.donnees.type == ETypeSelectionItem.PreCommande) {
            this.selectionDemandeur = this.listeEmployes.find(x => x.employeId == this.donnees.employeId);
        } else {
            if (this.donnees.demandeurId) {
                this.selectionDemandeur = this.listeEmployes.find(x => x.employeId == this.donnees.demandeurId);
            }
        }

        this.itemService.ObtenirItems().then(x => {
            this.itemsComplets = x.valeur;

            if (this.estTypePreCommande()) {
                this.itemsComplets = this.itemsComplets.filter(x => x.categorie?.categorieId != 4);
            }

            this.items = x.valeur;
            this.listeUnites = x.valeur.filter(x => x.indSuiviMecanique == true);

            this.CompleterChargement();

            this.surChangementCategorie();

            this.terminerPreRemplissageFormulaireUnite();

            setTimeout(() => { this.inputRechercheItem.nativeElement.focus(); }, 100);

        });

        this.fournisseurService.ObtenirFournisseurs().then(x => {
            this.fournisseurs = x.valeur;
        });

        this.dateRetourAuto.setDate(new Date().getDate() - 1);

        this.dateRequis.setDate(new Date().getDate() + 2);


        this.listeCodeProjet = this.sdb.CodesProjet();
        this.listeCodeCategorie = this.sdb.CodesCategorie();
        this.listeCodeEquipement = this.sdb.CodesEquipement();
        this.listeCodeExtra = this.sdb.CodesExtra();
        this.listeEmplacement = this.sdb.Emplacements();
        this.listeEmplacementAjoutCommande = this.sdb.Emplacements().filter(x => x.commandeActive);


        if (this.donnees.selectionCodeProjet) {
            this.selectionCodeProjet = this.listeCodeProjet.find(x => x.codeProjetId == this.donnees.selectionCodeProjet);
            this.surChangementCodeProjet();

            if (this.donnees.selectionCodeActivite) {
                this.selectionCodeActivite = this.listeCodeActivite.find(x => x.codeActiviteId == this.donnees.selectionCodeActivite);

                if (this.donnees.selectionCodeCategorie) {
                    this.selectionCodeCategorie = this.listeCodeCategorie.find(x => x.codeCategorieId == this.donnees.selectionCodeCategorie);
                }
            }
        }

        if(this.donnees.selectionCodeEquipement){
            this.selectionCodeEquipement = this.listeCodeEquipement.find(x => x.codeEquipementId == this.donnees.selectionCodeEquipement);
        }

        if(this.donnees.indAjoutCommande){
            this.indAjoutCommande = this.donnees.indAjoutCommande;

            if(this.donnees.selectionDemandeurId){
                this.selectionDemandeur = this.listeEmployes.find(x => x.employeId == this.donnees.selectionDemandeurId);
            }

            if(this.donnees.selectionEmplacementCommandeId){
                this.selectionEmplacementAjoutCommande = this.listeEmplacementAjoutCommande.find(x => x.emplacementId == this.donnees.selectionEmplacementCommandeId);
            }
        }
    }

    terminerPreRemplissageFormulaireUnite() {
        if(this.donnees.indAjoutCommande) {
            if(this.donnees.selectionUniteId){
                this.selectionUniteAjoutCommande = this.listeUnites.find(x => x.itemId == this.donnees.selectionUniteId);
            }
        }
    }

    handleKeyboardEvents(event: KeyboardEvent) {
        //TODO: mettre la touche raccoruci dans l'entite et générliser
        //TODO : valider si l'option est disponible ...

        //TEMP
        if (this.donnees.type == ETypeSelectionItem.BonCommande || this.donnees.type == ETypeSelectionItem.ListeAchat) {
            return;
        }

        if (event.key == 't' && event.altKey) {
            this.selectionCategorie = this.listeCategories[0];
            this.surChangementCategorie();
        } else if (event.key == 'a' && event.altKey) {
            this.selectionCategorie = this.listeCategories[1];
            this.surChangementCategorie();
        } else if (event.key == 'e' && event.altKey) {
            this.selectionCategorie = this.listeCategories[2];
            this.surChangementCategorie();
        } else if (event.key == 'i' && event.altKey) {
            this.selectionCategorie = this.listeCategories[3];
            this.surChangementCategorie();
        } else if (event.key == 'o' && event.altKey) {
            this.selectionCategorie = this.listeCategories[4];
            this.surChangementCategorie();
        }



    }

    nouvelItem(event) {

        this.contenuMenuContextuelAjout = [
            {
                label: 'Inventaire',
                icon: 'pi pi-fw pi-tag',
                command: () => this.nouvelInventaire()
            }, {
                label: 'Pièce mécanique',
                icon: 'pi pi-fw pi-tag',
                command: () => this.nouvellePiece()
            }
        ];

        this.menuContextuelAjout.toggle(event);


    }

    nouvelInventaire() {
        const subRef = this.dialogService.open(CreationItemComponent, {
            data: {
                description: this.selectionItem?.description,
                marque: this.selectionItem?.marque,
                modele: this.selectionItem?.modele
            },
            header: 'Ajout d\'un item',
            width: '90%'
        });

        subRef.onClose.subscribe((res: Item) => {
            if (res) {
                this.selectionItem = this.itemService.ItemVersItemMin(res);
                this.itemSelectionne = true;
                this.inputQuantite.input.nativeElement.focus();
            }
        });

        const dynamicDialogComponent = this.dialogService.dialogComponentRefMap.get(this.ref).instance;
        dynamicDialogComponent.unbindGlobalListeners();
        subRef.onDestroy.subscribe(_ => {
            dynamicDialogComponent.moveOnTop();
            dynamicDialogComponent.bindGlobalListeners();
            dynamicDialogComponent.focus();
        });
    }

    nouvellePiece() {
        const subRef = this.dialogService.open(CreationPieceComponent, {
            data: {
                description: this.selectionItem?.description,
                marque: this.selectionItem?.marque,
                modele: this.selectionItem?.modele
            },
            header: 'Ajout d\'une pièce',
            width: '90%'
        });

        subRef.onClose.subscribe((res: ResultatCreationPiece) => {
            if (res) {
                this.selectionItem = this.itemService.ItemVersItemMin(res.item);
                this.itemSelectionne = true;

                if (res.prixFournisseur && res.prixFournisseur.fournisseur && this.donnees.fournisseurId &&
                    res.prixFournisseur.fournisseur.fournisseurId == this.donnees.fournisseurId) {
                    this.noFournisseur = res.prixFournisseur.numeroFournisseur;
                    this.prix = res.prixFournisseur.prix;
                }

                this.inputQuantite.input.nativeElement.focus();
            }
        });

        const dynamicDialogComponent = this.dialogService.dialogComponentRefMap.get(this.ref).instance;
        dynamicDialogComponent.unbindGlobalListeners();
        subRef.onDestroy.subscribe(_ => {
            dynamicDialogComponent.moveOnTop();
            dynamicDialogComponent.bindGlobalListeners();
            dynamicDialogComponent.focus();
        });
    }

    surChangementCategorie() {
        switch (this.selectionCategorie.cle) {
            case 0:
                this.items = this.itemsComplets;
                break;
            case -1:
                //Note pour guillaume: Si tu connais l'id de la sous catégorie, peut tu la modifier, je l'avais pas dans ma db.
                //Tu peut retirer le commentaire si tu veux.
                this.items = this.itemsComplets.filter(x => x.categorie?.categorieId == 3 && x.sousCategorie?.nom == "Precommande");
                break;
            default:
                this.items = this.itemsComplets.filter(x => x.categorie.categorieId == this.selectionCategorie.cle);
                break;
        }
    }

    surEntreeRecherche() {

        if (this.tableItems.filteredValue != undefined &&
            this.tableItems.filteredValue.length == 1) {
            this.surSelectionItemInventaire(this.tableItems.filteredValue[0]);
        }

    }

    selectionner(close: boolean) {

        this.submitted = true;

        if (!this.validerSelection()) {
            this.messageService.add({ key: 'selectionItem', severity: 'error', summary: 'Erreurs', detail: 'Veuillez corriger les erreurs de saisie.' });
            return;
        }

        //TODO : Gérer la vérification d'inventaire.
        this.blocked = true;

        var retour: ResultatSelectionItem = {
            type: this.donnees.type
        }

        // Sélection de type "Inventaire"
        if (this.selectionTypeSaisie.cle == 'I') {

            // Retour express
            if ((this.estTypeCommande() || this.estTypeRequisition()) &&
                this.selectionTypeSaisie.cle == 'I' &&
                this.selectionItem.categorie.estSuivi &&
                this.selectionItem.categorie.estUnique &&
                this.selectionItem && this.selectionItem.emplacement.emplacementId != this.emplacementIdVerificationTransfertExpress &&
                this.selectionItem.categorie.estUnique) {

                this.requisitionService.RetourExpress({
                    itemId: this.selectionItem.itemId,
                    date: this.dateRetourAuto
                }).then(res => {
                    if (res.estUnSucces) {

                        retour.item = this.selectionItem;
                        this.completerRetour(retour)

                        this.blocked = false;
                        this.ref.close(retour);

                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ key: 'selectionItem', severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                        this.blocked = false;
                    }
                })

            } else {

                retour.item = this.selectionItem;
                this.completerRetour(retour)

                this.blocked = false;
                this.ref.close(retour);

            }
        } else if (this.selectionTypeSaisie.cle == 'N') {

            //TODO : ajustement d'inventaire

            this.itemService.CreerItem({
                "itemId": 0,
                "categorie": {
                    "categorieId": 3,
                    "nom": "",
                    "estSuivi": false,
                    "estUnique": false
                },
                "description": this.selectionItem.description
            }).then(x => {
                if (x.estUnSucces) {

                    this.itemService.AjusterInventaire({
                        employe: this.profilService.ProfilCourant(),
                        itemId: x.valeur.itemId,
                        emplacementId: 10000,
                        date: new Date(),
                        ajustement: this.quantiteDemande
                    }).then(resAjustInv => {
                        if (resAjustInv.estUnSucces) {

                            retour.item = this.itemService.ItemVersItemMin(x.valeur);
                            this.completerRetour(retour)

                            this.blocked = false;
                            this.ref.close(retour);

                        } else {
                            x.messages.forEach(x => {
                                this.messageService.add({ key: 'selectionItem', severity: 'error', summary: 'Erreur', detail: x.description });
                            });
                        }
                    });


                } else {
                    //TODO : Gérer l'erreur
                    x.messages.forEach(x => {
                        this.messageService.add({ key: 'selectionItem', severity: 'error', summary: 'Erreur', detail: x.description });
                    });

                }

            });
        } else if (this.selectionTypeSaisie.cle == 'D') {

            //TODO : le 5 hard codé
            this.itemService.CreerItem({
                "itemId": 0,
                "categorie": {
                    "categorieId": 5,
                    "nom": "",
                    "estSuivi": false,
                    "estUnique": false
                },
                "description": this.selectionItem.description
            }).then(x => {
                if (x.estUnSucces) {
                    retour.item = this.itemService.ItemVersItemMin(x.valeur);
                    this.completerRetour(retour)

                    this.blocked = false;
                    this.ref.close(retour);
                } else {
                    //TODO : Gérer l'erreur
                    x.messages.forEach(x => {
                        this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                    });

                }

            });
        }

    }

    completerRetour(retour: ResultatSelectionItem) {
        retour.quantiteDemande = this.quantiteDemande;
        retour.uniteQte = this.uniteQte;
        retour.demandeur = this.selectionDemandeur;
        retour.expediteur = this.expediteur;
        retour.noFournisseur = this.noFournisseur;
        retour.prix = this.prix;
        retour.note = this.note;
        retour.codeProjet = this.selectionCodeProjet;
        retour.codeActivite = this.selectionCodeActivite;
        retour.codeCategorie = this.selectionCodeCategorie;
        retour.codeEquipement = this.selectionCodeEquipement;
        retour.codeExtra = this.selectionCodeExtra;
        retour.emplacement = this.selectionEmplacement;
        retour.dateRequis = this.dateRequis;
        retour.dateDemande = this.dateDemande;
        retour.unite = this.selectionUnite;
        retour.note = this.note;
        retour.indAssociation = this.indAssocier;
        retour.categorieEntretienId = this.selectionCategorieEntretien?.categorieEntretienId;
        retour.indAjoutCommande = this.indAjoutCommande;
        retour.emplacementAjoutCommande = this.selectionEmplacementAjoutCommande;
        retour.uniteAjoutCommande = this.selectionUniteAjoutCommande;
    }

    validerSelection() {

        if (this.donnees.type == ETypeSelectionItem.Commande) {
            if (this.selectionTypeSaisie.cle == 'I') {
                return this.quantiteDemande
                    && this.dateDemande
                    && this.dateRequis
                    && this.selectionDemandeur;
            } else if (this.selectionTypeSaisie.cle == 'N') {
                return this.selectionItem.description
                    && this.quantiteDemande
                    && this.dateDemande
                    && this.dateRequis
                    && this.selectionDemandeur
            } else if (this.selectionTypeSaisie.cle == 'D') {
                return this.selectionItem.description
                    && this.quantiteDemande
                    && this.dateDemande
                    && this.dateRequis
                    && this.selectionDemandeur
            }
        } else if (this.donnees.type == ETypeSelectionItem.BonCommande) {

            if (this.selectionCodeProjet?.code == 'ENTRET' && this.selectionCodeActivite?.code == '280' && !this.selectionCodeEquipement) {
                return false;
            }

            if (this.indAjoutCommande) {
                if (!this.dateDemande
                || !this.dateRequis
                || !this.selectionDemandeur
                || !this.selectionEmplacementAjoutCommande) {
                    return false;
                }
            }

            if (this.selectionTypeSaisie.cle == 'I') {
                return this.quantiteDemande
                    //&& this.noFournisseur
                    && (this.prix || this.prix == 0)
                    && this.selectionCodeActivite
                    && this.selectionCodeCategorie
                    && this.selectionCodeProjet;
            } else if (this.selectionTypeSaisie.cle == 'N') {
                return this.selectionItem.description
                    && this.quantiteDemande
                    //&& this.noFournisseur
                    && (this.prix || this.prix == 0)
                    && this.selectionCodeActivite
                    && this.selectionCodeCategorie
                    && this.selectionCodeProjet;
            } else if (this.selectionTypeSaisie.cle == 'D') {
                return this.selectionItem.description
                    && this.quantiteDemande
                    /*  && this.noFournisseur */
                    && (this.prix || this.prix == 0)
                    && this.selectionCodeActivite
                    && this.selectionCodeCategorie
                    && this.selectionCodeProjet;
            }
        } else if (this.donnees.type == ETypeSelectionItem.PreCommande) {

            if (this.selectionTypeSaisie.cle == 'I' &&
                (this.selectionItem.categorie.categorieId == this.CATEGORIE_ACCESSOIRE ||
                    this.selectionItem.categorie.categorieId == this.CATEGORIE_EQUIPEMENT)) {
                return this.quantiteDemande
                    && this.selectionDemandeur
                    && this.dateRequis
                    && this.selectionCodeProjet;
            } else {
                return this.quantiteDemande
                    && this.selectionDemandeur
                    && this.dateRequis
                    && this.selectionCodeProjet
                    && this.selectionCodeCategorie
                    && this.selectionCodeActivite;
            }



        } else if (this.donnees.type == ETypeSelectionItem.Requisition) {

            if (this.selectionTypeSaisie.cle == 'I') {
                return this.quantiteDemande
                    && this.selectionDemandeur;
            } else {
                return this.quantiteDemande
                    && this.selectionDemandeur;
            }



        }

        return true;
    }

    annuler() {
        this.ref.close(null);
    }

    typeSaisieInventaire(): boolean {
        return this.selectionTypeSaisie.cle == 'I';
    }

    typeSaisieDivers(): boolean {
        return this.selectionTypeSaisie.cle == 'D';
    }

    RechercherItem() {
        this.selectionItem = null;

        this.quantiteDemande = null;

        this.itemSelectionne = false;
    }

    contenuMenuContextuelReleve: MenuItem[];
    @ViewChild('menuContextuelReleve') menuContextuelReleve: ContextMenu;

    surMenuContextuelItem(event) {
        this.contenuMenuContextuelReleve = [
            // {
            //     label: 'Ajouter un relevé d\'heures',
            //     icon: 'pi pi-fw pi-plus',
            //     visible: this.estAutorise('item_heure_creer'),
            //     command: () => this.ajouterReleveHeure()
            // },
            // {
            //     label: 'Ajouter un relevé de KM',
            //     icon: 'pi pi-fw pi-plus',
            //     visible: this.estAutorise('item_heure_creer'),
            //     command: () => this.ajouterReleveKm()
            // },
        ];

        this.menuContextuelReleve.toggle(event);
    }

    // ajouterReleveHeure() {
    //     const ref = this.dialogService.open(AjoutReleveHeureComponent, {
    //         data: {
    //             item: this.itemService.ItemVersItemMin(this.selectionItem)
    //         },
    //         header: 'Ajout d\'un relevé d\'heures',
    //         width: '50%'
    //     }).onClose.subscribe((res: ReleveHeureEntite) => {
    //         if (res) {
    //             this.messageService.add({ key: 'selectionItem', severity: 'success', summary: 'Succès', detail: 'Relevé d\'heures ajouté' });
    //         }
    //     });
    // }

    // ajouterReleveKm() {
    //     const ref = this.dialogService.open(AjoutReleveKmComponent, {
    //         data: {
    //             item: this.itemService.ItemVersItemMin(this.selectionItem)
    //         },
    //         header: 'Ajout d\'un relevé de KM',
    //         width: '50%'
    //     }).onClose.subscribe((res: ReleveKmEntite) => {
    //         if (res) {
    //             this.messageService.add({ key: 'selectionItem', severity: 'success', summary: 'Succès', detail: 'Relevé de KM ajouté' });
    //         }
    //     });
    // }

    surSelectionItemInventaire(item: ItemMin) {

        /*  if (this.estTypeRequisition() &&
         this.selectionTypeSaisie.cle == 'I' 
         && item.emplacement.emplacementId != this.emplacementIdVerificationTransfertExpress
         && this.emplacementIdVerificationTransfertExpress != 10000
         && item.categorie.estUnique) {
             this.messageService.add({ key: 'selectionItem', severity: 'error', summary: 'Erreur', detail: 'L\'item sélectionné ne peut être ajouté puisque son emplacement actuel ne le permet pas.' });
             return;
         } */

        this.selectionItem = item;

        if (this.donnees.type == ETypeSelectionItem.Recherche || this.donnees.type == ETypeSelectionItem.ListeAchat) {

            var retour: ResultatSelectionItem = {
                type: this.donnees.type
            }

            retour.item = this.selectionItem;

            this.blocked = false;
            this.ref.close(retour);

        } else if (this.estTypeBonCommande()) {

            this.blocked = true;

            this.fournisseurService.ObtenirListePrix(this.donnees.fournisseurId, this.selectionItem.itemId).then(res => {
                if (res.estUnSucces) {
                    var prixCourant = res.valeur;
                    if (prixCourant && prixCourant.length > 0) {
                        this.noFournisseur = prixCourant[0].numeroFournisseur;
                        this.prix = prixCourant[0].prix;
                    }
                }

                this.itemSelectionne = true;
                setTimeout(() => this.inputQuantite.input.nativeElement.focus(), 50)
                this.blocked = false;

            });

        } else {

            if (item.categorie.estUnique) {
                this.quantiteDemande = 1;
            }

            this.itemSelectionne = true;

            setTimeout(() => {
                if (item.categorie.estUnique) {
                    this.inputDemandeur.applyFocus();
                } else {
                    this.inputQuantite.input.nativeElement.focus();
                }

            }, 50)

        }

        if (this.estTypePreCommande()) {

            this.estCodeActiviteObligatoire = this.selectionTypeSaisie.cle != 'I' ||
                (this.selectionItem.categorie.categorieId != this.CATEGORIE_ACCESSOIRE &&
                    this.selectionItem.categorie.categorieId != this.CATEGORIE_EQUIPEMENT);

            this.estCodeCategorieObligatoire = this.estCodeActiviteObligatoire;

        }
    }

    surFermetureDemandeur() {

        if (this.inputExpediteur != undefined) {
            this.inputExpediteur.nativeElement.focus();
        }
    }

    surChangementCodeProjet() {
        this.selectionCodeActivite = null; 
        this.listeCodeActivite = this.selectionCodeProjet.codesActivite;

        if (this.selectionCodeProjet) {
            this.selectionEmplacementAjoutCommande = this.listeEmplacementAjoutCommande.find(x => x.codeProjet?.codeProjetId == this.selectionCodeProjet.codeProjetId);
        } else {
            this.selectionEmplacementAjoutCommande = null;
        }

        this.autoFillCategoriesCost();
    }

    surChangementCodeEquipement() {

    }

    autoFillCategoriesCost() {
        if (this.selectionCodeProjet && this.selectionCodeProjet.code == 'ENTRET' &&
            this.selectionCodeActivite && (this.selectionCodeActivite.code == '280' || this.selectionCodeActivite.code == '281')) {
            this.selectionCodeCategorie = this.listeCodeCategorie.find(x => x.code == '2');
        }
    }

    surChangementCodeActivite() {
        if (this.selectionCodeActivite && 
            (this.selectionCodeActivite.code == 'CG08' || this.selectionCodeActivite.code == 'M5' || this.selectionCodeActivite.code == 'M6' || this.selectionCodeActivite.code == 'M8' || this.selectionCodeActivite.code == 'M9')) {
            this.selectionCodeEquipement = null;
            this.codeEquipementBloque = true;
        } else {
            this.codeEquipementBloque = false;
        }

        this.autoFillCategoriesCost();
    }

    surFermetureCodeProjet() {
        this.champCodeActivite.applyFocus();
    }

    surFermetureCodeActivite() {
        this.champCodeCategorie.applyFocus();
    }

    surFermetureCodeCategorie() {
        this.champNote.nativeElement.focus();
    }


    OuvrirDetailItemNouvelOnglet(itemId: number) {
        this.appMain.detailItemId = itemId;
        this.appMain.afficherDetailItem = true;
    }


    estTypeBonCommande() { return this.donnees.type == ETypeSelectionItem.BonCommande; }
    estTypeCommande() { return this.donnees.type == ETypeSelectionItem.Commande; }
    estTypeListeAchat() { return this.donnees.type == ETypeSelectionItem.ListeAchat; }
    estTypePreCommande() { return this.donnees.type == ETypeSelectionItem.PreCommande; }
    estTypeRecherche() { return this.donnees.type == ETypeSelectionItem.Recherche; }
    estTypeRequisition() { return this.donnees.type == ETypeSelectionItem.Requisition; }
}