import { Component, OnInit, ViewChild, Inject } from "@angular/core"; import { Location } from "@angular/common";
import { Table } from 'primeng/table';
import { BreadcrumbService } from '../../app.breadcrumb.service';

import { RequisitionService } from '../../services/requisition-service';
import { RequisitionMin } from '../../entites/requisition-min';
import { MenuItem } from 'primeng/api/menuitem';
import { MessageService, LazyLoadEvent, ConfirmationService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

import { DialogService } from 'primeng/dynamicdialog';
import { EnvoiRequisitionComponent } from '../../controles/envoi-requisition.component';
import { ItemMin } from 'src/app/entites/item-min';
import { ItemService } from 'src/app/services/item-service';
import { Fournisseur } from 'src/app/entites/fournisseur';
import { BonCommandeEntite } from 'src/app/entites/bon-commande';
import { FournisseurService } from 'src/app/services/fournisseur-service';
import { AchatService } from 'src/app/services/achat-service';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { DOCUMENT } from '@angular/common';
import { ProfilService } from 'src/app/services/profil-service';

@Component({
    templateUrl: './accueil.component.html',
    styleUrls: ['./accueil.component.scss'],
    styles: [`
        @media screen and (max-width: 960px) {
            :host ::ng-deep .p-datatable.p-datatable-customers.rowexpand-table .p-datatable-tbody > tr > td:nth-child(6) {
                display: flex;
            }
        }

    `],
    providers: [MessageService, ConfirmationService, DialogService]
})
export class AccueilComponent implements OnInit {


    rowGroupMetadata: any;

    menuContextuelRequisitions: MenuItem[];

    loading: boolean;

    @ViewChild('dt') table: Table;


    rechercheDeclenchee: boolean = false;
    itemsLoaded: boolean = false;
    reqsLoaded: boolean = false;
    fournisseursLoaded: boolean = false;
    bdcsLoaded: boolean = false;

    items: ItemMin[];
    reqs: RequisitionMin[];
    fournisseurs: Fournisseur[];
    bdcs: BonCommandeEntite[];

    constructor(
        private breadcrumbService: BreadcrumbService,
        private requisitionService: RequisitionService,
        private fournisseurService: FournisseurService,
        private achatService: AchatService,
        private itemsService: ItemService,
        private profilService: ProfilService,
        public routerService: Router,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        public dialogService: DialogService,
        public auth: AuthService,
        @Inject(DOCUMENT) private doc: Document) {
        this.breadcrumbService.setItems([
            { label: 'Accueil' }
        ]);
    }

    estAutorise(tache: string) {
        return this.profilService.estAutoriseTache(tache);
    }

    test() {
        this.routerService.navigateByUrl('items');
    }

    loginWithRedirect() {
        this.auth.loginWithRedirect();
    }

    logout() {
        this.auth.logout();
    }

    profileJson: string = null;
    getProfile() {
        this.auth.user$.subscribe(
            (profile) => {
                this.profileJson = JSON.stringify(profile, null, 2);
            });
    }

    ngOnInit() {

        this.loading = true;


    }

}
