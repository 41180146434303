import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Output, EventEmitter } from "@angular/core"; import { Location } from "@angular/common";

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { ItemMin } from '../entites/item-min';
import { Router } from '@angular/router';
import { ReponseBase } from './reponse-base';
import { Item } from '../entites/item';
import { BonCommandeEntite } from '../entites/bon-commande';
import { Statut } from '../entites/statut';
import { CategorieMin, CategorieEntite } from '../entites/categorie';
import { SousCategorieEntite } from '../entites/sousCategorie';
import { Emplacement } from '../entites/emplacement';
import { AttributEntite } from '../entites/attribut';
import { EmployeEntite } from '../entites/employe';
import { EmplacementService } from './emplacement-service';
import { DonneesBaseService } from './donnees-base-service';
import { FavoriEntite } from '../entites/favori';
import { HistoriqueEntite } from '../entites/historique';
import { RapportEntite } from '../entites/rapport';
import { ProfilEntite } from '../entites/profil';

@Injectable({ providedIn: 'root' })
export class ProfilService {

  @Output() changementFavoris = new EventEmitter<FavoriEntite[]>();
  @Output() changementHistorique = new EventEmitter<HistoriqueEntite[]>();

  private employeConnecte: EmployeEntite;

  private favoris: FavoriEntite[] = null;

  private historiques: HistoriqueEntite[] = [];



  private obtenirFavorisUrl = environment.apiBaseUrl + 'profil/{employeId}/favoris';
  private ajouterFavorisUrl = environment.apiBaseUrl + 'profil/favori';
  private supprimerFavorisUrl = environment.apiBaseUrl + 'profil/favori/{favoriId}';
  private obtenirProfilUrl = environment.apiBaseUrl + 'profil/profil';

  public httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  private _profil: ProfilEntite;

  constructor(
    public sdb: DonneesBaseService,
    private http: HttpClient,
    public router: Router) { }

  public ProfilCourant(): EmployeEntite {
    return this._profil.employe;
  }

  public Profil(): ProfilEntite {
    return this._profil;
  }

  public estAutoriseTache(code: string): boolean {
    if (!this._profil){
      return false;
    }

    return this._profil.taches.filter(x => x.code == code).length > 0;
  }

  public ObtenirProfil(userId: string, email: string) {

    return new Promise((resolve, reject) => {

      this.http.post<ReponseBase<ProfilEntite>>(this.obtenirProfilUrl,
        {
          userID: userId,
          courriel: email
        }, this.httpOptions).toPromise().then(x => {
          this._profil = x.valeur
          resolve(null);
        }).catch(() => {
          reject();
        })

    });

  }


  public ObtenirFavoris(employeId: number): Promise<FavoriEntite[]> {
    return new Promise<FavoriEntite[]>(resolve => {
      if (this.favoris != null) {
        resolve(this.favoris);
      } else {
        this.http.get<ReponseBase<FavoriEntite[]>>(this.obtenirFavorisUrl.replace('{employeId}', employeId.toString())).toPromise().then(res => {
          this.favoris = res.valeur;
          resolve(this.favoris);
        });
      }
    });
  }

  public rafraichirFavoris(employeId: number) {
    this.favoris = null;
    this.ObtenirFavoris(employeId).then(x => {
      this.changementFavoris.emit(this.favoris);
    });
  }

  public ajouterFavori(requete: FavoriEntite): Promise<ReponseBase<FavoriEntite>> {
    var p = this.http.post<ReponseBase<FavoriEntite>>(this.ajouterFavorisUrl, JSON.stringify(requete), this.httpOptions).toPromise();

    p.catch(() => this.router.navigateByUrl('error'));

    return p;
  }

  public supprimerFavori(favoriId: number) {

    this.http.delete<ReponseBase<boolean>>(this.supprimerFavorisUrl.replace('{favoriId}', favoriId.toString())).toPromise().then(x => {
      this.favoris = this.favoris.filter(x => x.favoriId != favoriId);
      this.changementFavoris.emit(this.favoris);
    });

    //var p = this.http.delete<ReponseBase<boolean>>(this.supprimerFavorisUrl.replace('{favoriId}', favoriId.toString())).toPromise();
  }

  public verifierFavori(employeId: number, url: string): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      if (this.favoris != null) {
        resolve(this.favoris.filter(x => x.url == url).length > 0);
      } else {
        this.http.get<ReponseBase<FavoriEntite[]>>(this.obtenirFavorisUrl.replace('{employeId}', employeId.toString())).toPromise().then(res => {
          this.favoris = res.valeur;
          resolve(this.favoris.filter(x => x.url == url).length > 0);
        });
      }
    });
  }

  public obtenirHistorique() {
    return this.historiques;
  }

  public ajouterHistorique(requete: HistoriqueEntite) {
    this.historiques = this.historiques.filter(x => x.url != requete.url);
    this.historiques.unshift(requete);
    this.historiques = this.historiques.slice(0, 10);
    this.changementHistorique.emit(this.historiques);
  }

  public viderHistorique() {
    this.historiques = [];
    this.changementHistorique.emit(this.historiques);
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);

      this.log(`${operation} failed: ${error.message}`);

      this.router.navigateByUrl('erreurtechnique');

      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(message);
  }
}
