import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { AppMainComponent } from 'src/app/app.main.component';
import { BaseComponent } from 'src/app/base/base-component';
import { AnalyseBonTravail } from 'src/app/entites/analyse-bon-travail';
import { BonTravailService } from 'src/app/services/bon-travail-service';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { ProfilService } from 'src/app/services/profil-service';

@Component({
  selector: 'app-analyse-bontravail',
  templateUrl: './analyse-bontravail.component.html'
})
export class AnalyseBonTravailComponent extends BaseComponent implements OnInit {

  analyses: AnalyseBonTravail[];

  constructor(
    public sdb: DonneesBaseService,
    public profilService: ProfilService,
    public route: ActivatedRoute,
    public router: Router,
    public appMain: AppMainComponent,
    private bonTravailService: BonTravailService,
  ) { 
    super(sdb, profilService, route, router, appMain);
  }

  ngOnInit(): void {
    this.DemarrerChargement();
    this.colonnesDisponibles = [
      { entete: 'Numéro', champ: 'numero', afficheParDefaut: true },
      { entete: 'Nombre d\'Heures', champ: 'nbHeures', afficheParDefaut: true },
      { entete: 'Nombre d\'Items', champ: 'nbItems', afficheParDefaut: true },
      { entete: 'Coût Soumission', champ: 'coutSoumission', afficheParDefaut: true },
      { entete: 'Coût Bon Travail', champ: 'coutBonTravail', afficheParDefaut: true },
      { entete: 'Comparaison', champ: 'comparaison', afficheParDefaut: true }
    ];
    this.colonnesSelectionnees = this.colonnesDisponibles;
    this.bonTravailService.ObtenirAnalyses().subscribe({
      next: (response) => {
        this.analyses = response.body;
        this.CompleterChargement();
      },
      error: (error) => {
        this.CompleterChargement();
        this.router.navigateByUrl('error');
      }
    });
  }

  OuvrirDetailAnalyse(analyseBonTravailId: number): void {
    this.router.navigateByUrl(`/mecanique/bontravail/analyse/${analyseBonTravailId}`);
  }

}