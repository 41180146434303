import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from "@angular/core"; import { Location } from "@angular/common";
import { Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { ReponseBase } from './reponse-base';
import { NonConformiteEntite } from '../entites/non-conformite';
import { NonConformiteSimpleEntite } from '../entites/non-conformite-simple';
import { ActionCorrectiveEntite } from '../entites/action-corrective';

@Injectable({ providedIn: 'root' })
export class NonConformiteService {

  private obtenirListeUrl = environment.apiBaseUrl + 'nonconformite';
  private obtenirDetailUrl = environment.apiBaseUrl + 'nonconformite/{id}';
  private creerUrl = environment.apiBaseUrl + 'nonconformite';
  private modifierUrl = environment.apiBaseUrl + 'nonconformite';
  private approbationUrl = environment.apiBaseUrl + 'nonconformite/approbation';
  private supprimerUrl = environment.apiBaseUrl + 'nonconformite/{id}';
  private actionCorrectiveUrl = environment.apiBaseUrl + 'nonconformite/actioncorrective/';
  private obtenirActionCorrectiveUrl = environment.apiBaseUrl + 'nonconformite/actioncorrective/{id}';
  private actionCorrectiveParNonConformiteUrl = environment.apiBaseUrl + 'nonconformite/{id}/actioncorrective/';

  public httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(
    private http: HttpClient,
    public router: Router
  ) { }

  public Obtenir(): Promise<ReponseBase<NonConformiteSimpleEntite[]>> {
    var p = this.http.get<ReponseBase<NonConformiteSimpleEntite[]>>(this.obtenirListeUrl)
      .toPromise();
    p.catch(() => this.router.navigateByUrl('error'));
    return p;
  }

  public ObtenirDetail(id: number): Promise<ReponseBase<NonConformiteEntite>> {
    var p = this.http.get<ReponseBase<NonConformiteEntite>>(this.obtenirDetailUrl.replace('{id}', id.toString()))
      .toPromise();
    p.catch(() => this.router.navigateByUrl('error'));
    return p;
  }

  public Creer(nonConformite: NonConformiteEntite): Promise<ReponseBase<NonConformiteEntite>> {
    var p = this.http.post<ReponseBase<NonConformiteEntite>>(this.creerUrl, JSON.stringify(nonConformite), this.httpOptions)
      .toPromise();
    p.catch(() => this.router.navigateByUrl('error'));
    return p;
  }

  public Modifier(nonConformite: NonConformiteEntite): Promise<ReponseBase<boolean>> {
    var p = this.http.put<ReponseBase<boolean>>(this.modifierUrl, JSON.stringify(nonConformite), this.httpOptions)
      .toPromise();
    p.catch(() => this.router.navigateByUrl('error'));
    return p;
  }
  
  public ApprobationNonConformite(nonConformite: NonConformiteEntite): Promise<ReponseBase<NonConformiteEntite>> {
    const url = `${environment.apiBaseUrl}nonconformite/approbation`;
    const p = this.http.put<ReponseBase<NonConformiteEntite>>(url, JSON.stringify(nonConformite), this.httpOptions)
      .toPromise();
    p.catch(() => this.router.navigateByUrl('error'));
    return p;
  }

  public Archiver(id: number): Promise<ReponseBase<boolean>> {
    var p = this.http.delete<ReponseBase<boolean>>(this.supprimerUrl.replace('{id}', id.toString()))
      .toPromise();
    p.catch(() => this.router.navigateByUrl('error'));
    return p;
  }

  public ObtenirActionCorrectives(): Promise<ReponseBase<ActionCorrectiveEntite[]>> {
    const p = this.http.get<ReponseBase<ActionCorrectiveEntite[]>>(this.actionCorrectiveUrl)
      .toPromise();
    p.catch(() => this.router.navigateByUrl('error'));
    return p;
  }

  public ObtenirActionCorrectivesParNonConformite(id: number): Promise<ReponseBase<ActionCorrectiveEntite[]>> {
    const p = this.http.get<ReponseBase<ActionCorrectiveEntite[]>>(this.actionCorrectiveParNonConformiteUrl.replace('{id}', id.toString()))
      .toPromise();
    p.catch(() => this.router.navigateByUrl('error'));
    return p;
  }

  public ObtenirActionCorrective(id: number): Promise<ReponseBase<ActionCorrectiveEntite>> {
    const p = this.http.get<ReponseBase<ActionCorrectiveEntite>>(this.obtenirActionCorrectiveUrl.replace('{id}', id.toString()))
      .toPromise();
    p.catch(() => this.router.navigateByUrl('error'));
    return p;
  }

  public CreerActionCorrective(actionCorrective: ActionCorrectiveEntite): Promise<ReponseBase<ActionCorrectiveEntite>> {
    const p = this.http.post<ReponseBase<ActionCorrectiveEntite>>(this.actionCorrectiveUrl, JSON.stringify(actionCorrective), this.httpOptions)
      .toPromise();
    p.catch(() => this.router.navigateByUrl('error'));
    return p;
  }

  public ModifierActionCorrective(actionCorrective: ActionCorrectiveEntite): Promise<ReponseBase<boolean>> {
    const p = this.http.put<ReponseBase<boolean>>(this.actionCorrectiveUrl, JSON.stringify(actionCorrective), this.httpOptions)
      .toPromise();
    p.catch(() => this.router.navigateByUrl('error'));
    return p;
  }

  public ArchiverActionCorrective(id: number): Promise<ReponseBase<boolean>> {
    const p = this.http.delete<ReponseBase<boolean>>(this.actionCorrectiveUrl.replace('{id}', id.toString()))
      .toPromise();
    p.catch(() => this.router.navigateByUrl('error'));
    return p;
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      this.router.navigateByUrl('erreurtechnique');
      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(message);
  }
}
