<p-card *ngIf="!EstCharge()">
	<div class="fluid formgrid grid">
		<div class="flex flex-column gap-2 col-12 text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-card>
<div class="card" *ngIf="EstCharge()">
<div class="fluid formgrid grid">
    <div class="flex flex-column gap-2 col-12">
        <p-table #dtPrix [value]="prix"
						tableStyleClass="tableNormale" autoLayout="true"
						dataKey="prixFournisseurId"
						[globalFilterFields]="['fournisseur.nom','prix','fournisseur.numero','numeroFournisseur']">
						
						<ng-template pTemplate="header">
							<tr>
								<th pSortableColumn="numero">
									<div class="flex justify-content-between align-items-center">
										Numéro
										<p-sortIcon field="numero"></p-sortIcon>
										<p-columnFilter type="text" field="numero" display="menu" class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="Nom">
									<div class="flex justify-content-between align-items-center">
										Nom
										<p-sortIcon field="Nom"></p-sortIcon>
										<p-columnFilter type="text" field="Nom" display="menu" class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="numeroFournisseur">
									<div class="flex justify-content-between align-items-center">
										No pièce fournisseur
										<p-sortIcon field="numeroFournisseur"></p-sortIcon>
										<p-columnFilter type="text" field="numeroFournisseur" display="menu"
											class="ml-auto">
										</p-columnFilter>
									</div>
								</th>
								<th pSortableColumn="prix">
									<div class="flex justify-content-between align-items-center">
										Prix
										<p-sortIcon field="prix"></p-sortIcon>
										<p-columnFilter type="text" field="prix" display="menu" class="ml-auto">
										</p-columnFilter>
									</div>
                                </th>
                                <th style="min-width: 5rem;"></th>
							</tr>
						</ng-template>
						<ng-template pTemplate="body" let-prixFournisseur>
							<tr [pContextMenuRow]="prixFournisseur">
								<td>
									{{prixFournisseur.fournisseur.numero}}
								</td>
								<td>
									{{prixFournisseur.fournisseur.nom}}
								</td>
								<td>
									{{prixFournisseur.numeroFournisseur}}
								</td>
								<td>
									{{prixFournisseur.prix | currency}}
                                </td>
                                <td>
                                    <button *ngIf="donnees.afficherSelection" pButton pRipple icon="pi pi-check" class="p-button-success mr-2 boutonGrille"
									(click)="selectionner(prixFournisseur)"></button>
									<button *ngIf="donnees.afficherCommander" pButton pRipple icon="pi pi-shopping-cart" class="p-button-success mr-2 boutonGrille"
                                    (click)="commander(prixFournisseur)"></button>
                                </td>
							</tr>
						</ng-template>
						<ng-template pTemplate="emptymessage">
							<tr>
								<td colspan="5">Aucun fournisseur.</td>
							</tr>
						</ng-template>
					</p-table>
    </div>
  
</div>
<div class="grid">
    <div class="col-12 text-right ">
        <button pButton pRipple label="Fermer" icon="pi pi-ban" class="p-button-text" (click)="annuler()"></button>
    </div>
</div>
</div>