import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from "@angular/core"; import { Location } from "@angular/common";

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { ItemMin } from '../entites/item-min';
import { Router } from '@angular/router';
import { ReponseBase } from './reponse-base';
import { Item } from '../entites/item';
import { ListeAchatEntite } from '../entites/liste-achat';
import { ServiceBase } from './service-base';
import { RequeteApprouverListeAchat } from './requetes/requete-approuver-liste-achat';
import { BonCommandeEntite } from '../entites/bon-commande';

@Injectable({ providedIn: 'root' })
export class ListeAchatService extends ServiceBase {

  public etape: number;
  public modeleListeAchatId: number;

  public listeAchat: ListeAchatEntite;

  private creerUrl = environment.apiBaseUrl + 'listeachat';
  private rejeterUrl = environment.apiBaseUrl + 'listeachat/{id}/rejeter';
  private approuverUrl = environment.apiBaseUrl + 'listeachat/approuver';
  private obtenirListeUrl = environment.apiBaseUrl + 'listeachat';
  private obtenirDetailUrl = environment.apiBaseUrl + 'listeachat/{id}';

  public httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(
    private http: HttpClient,
    public router: Router) {
      super();
     }

     public CreerListe(requete: ListeAchatEntite): Promise<ReponseBase<number>> {
      var p = this.http.post<ReponseBase<number>>(this.creerUrl, JSON.stringify(requete), this.httpOptions).toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
    }

    public ObtenirListes(): Promise<ReponseBase<ListeAchatEntite[]>> {

      var p = this.http.get<ReponseBase<ListeAchatEntite[]>>(this.obtenirListeUrl)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public ObtenirListe(listeAchatId: number): Promise<ReponseBase<ListeAchatEntite>> {
    var p = this.http.get<ReponseBase<ListeAchatEntite>>(this.obtenirDetailUrl.replace('{id}',listeAchatId.toString()))
    .toPromise();

    p.catch(() => this.router.navigateByUrl('error'));

    return p;
}

public RejeterListe(id: number): Promise<ReponseBase<number>> {
  var p = this.http.post<ReponseBase<number>>(this.rejeterUrl.replace('{id}',id.toString()), null, this.httpOptions).toPromise();

  p.catch(() => this.router.navigateByUrl('error'));

  return p;
}

public ApprouverListe(requete: RequeteApprouverListeAchat): Promise<ReponseBase<BonCommandeEntite[]>> {
  var p = this.http.post<ReponseBase<BonCommandeEntite[]>>(this.approuverUrl, JSON.stringify(requete), this.httpOptions).toPromise();

  p.catch(() => this.router.navigateByUrl('error'));

  return p;
}
  

public ObtenirListeSaisie(): Promise<ReponseBase<ListeAchatEntite>> {
      
  var p = this.http.get<any>('assets/demo/data/listeAchatSaisie.json')
  .toPromise();

  p.catch(() => this.router.navigateByUrl('error'));

  return p;

}


  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);

      this.log(`${operation} failed: ${error.message}`);

      this.router.navigateByUrl('erreurtechnique');

      return of(result as T);
    };
  }

  private log(message: string) {
      console.log(message);
  }
}
