import { Component, OnInit, ViewChild, Input, ɵConsole } from "@angular/core"; import { Location } from "@angular/common";
import { MenuItem } from 'primeng/api';
import { MessageService, ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Router, ActivatedRoute } from '@angular/router';
import { FournisseurService } from 'src/app/services/fournisseur-service';
import { Fournisseur } from 'src/app/entites/fournisseur';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { ProfilService } from 'src/app/services/profil-service';
import { AppMainComponent } from 'src/app/app.main.component';
import { LocationEntite } from 'src/app/entites/location';
import { TypeLocationEntite } from 'src/app/entites/type-location';
import { EStatuts } from 'src/app/entites/enums/statuts';
import { UniteEntite } from 'src/app/entites/unite';
import { CoutLocationEntite } from 'src/app/entites/cout-location';
import { ETypesLocation } from 'src/app/entites/enums/type-location';
import { ContextMenu } from 'primeng/contextmenu/public_api';
import { DocumentEntite } from 'src/app/entites/document';
import { DocumentService } from 'src/app/services/document-service';
import { environment } from 'src/environments/environment';
import { RequeteObtenirDocuments } from 'src/app/services/requetes/requete-obtenir-documents';
import { ContexteService } from 'src/app/services/contexte-service';
import { BaseComponent } from 'src/app/base/base-component';
import { EntretienPreventifEntite } from 'src/app/entites/mecanique/entretien-preventif';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { CodeVmrsEntite } from 'src/app/entites/mecanique/code-vmrs';
import { PieceNecessaireEntite } from 'src/app/entites/mecanique/piece-necessaire';
import { ItemService } from 'src/app/services/item-service';
import { ResultatSelectionItem } from 'src/app/controles/selection-item.component';
import { SelectionPieceComponent } from 'src/app/controles/mecanique/selection-piece.component';
import { SelectionEntretienComponent } from 'src/app/controles/mecanique/selection-entretien.component';
import { EntretienPreventifService } from 'src/app/services/entretien-preventif-service';
import { CategorieEntretienEntite } from 'src/app/entites/mecanique/categorie-entretien';

@Component({
    templateUrl: './detail-entretien-preventif.component.html',
    providers: [MessageService, ConfirmationService, DialogService]
})
export class DetailEntretienPreventifComponent extends BaseComponent implements OnInit {

    entretienId: number;
    entretien: EntretienPreventifEntite = {}; 

    categories: CategorieEntretienEntite[];
    pieces: PieceNecessaireEntite[];

    bloquerDernierHeure: boolean = false;
    bloquerDernierKm: boolean = false;
    bloquerDernierMois: boolean = false;

    
    descriptionsEntretiens: string[];
    suggestionDescription: string[];

    menu: MenuItem[];

    submitted:boolean = false;

    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute, 
		appMain: AppMainComponent, location: Location, 
        private entretienPreventifService: EntretienPreventifService,
        private itemService: ItemService,
        private breadcrumbService: BreadcrumbService,
        private fournisseurService: FournisseurService,
        private contexteService: ContexteService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        public dialogService: DialogService,
        public routerService: Router ) {

        super(sdb, profilService, route, routerService, appMain);

        this.breadcrumbService.setItems([
            { label: 'Entretien préventif' }
        ]);

        this.route.params.subscribe(params => this.entretienId = params.id);
    }

    ngOnInit() {

        this.verifierAccesPageAuMoisUnAcces(['temp_mec', 'bonstravail']);

        this.categories = this.sdb.CategoriesEntretien();

        this.entretienPreventifService.Obtenir().then(x => this.descriptionsEntretiens = x.valeur.map(y => y.description));

        if (this.entretienId == 0) {

            this.entretien = {
                entretienPreventifId: 0,
                statut: this.sdb.Statut(EStatuts.Nouveau),
                pieces: []
            };

            if (!this.contexteService.ValiderPresence(["item"])) {
                this.routerService.navigateByUrl('mecanique/unites')
            }

            this.entretien.item = JSON.parse(this.contexteService.Obtenir("item"));
            this.contexteService.Supprimer("item");

            console.log('Mode Ajout');

            this.Ajouter();

            this.initialiserMenu();

            this.CompleterChargement();

        } else {

            console.log('Mode Consultation');

            this.Consulter();

            this.entretienPreventifService.ObtenirDetail(this.entretienId).then(res => {
                this.entretien = res.valeur;

                this.initialiserEntretien();
                
                this.initialiserMenu();
                
                this.CompleterChargement();
            });
        }

        

        
       
    }

    initialiserMenu() {

        //TODO
        this.estModifiable = true;

        this.menu = [
            {
                label: 'Retour', 
                icon: 'pi pi-fw pi-angle-double-left', 
                disabled: this.modeModification(),
                command: () => this.routerService.navigateByUrl(`item/${this.entretien.item.itemId}?t=7`)
            },
            {
                label: 'Actions', 
                icon: 'pi pi-fw pi-bars',
                disabled: this.modeModification(),
                items: [
                    {
                        label: 'Dupliquer un entretien existant', 
                        icon: 'pi pi-fw pi-copy',
                        visible: this.estAutorise('temp_mec') && this.modeAjout(),
                        command: _ => this.dupliquer()
                    },
                    {
                        label: 'Archiver', 
                        icon: 'pi pi-fw pi-times',
                        visible: this.estAutorise('temp_mec') && !this.modeAjout(),
                        command: _ => this.supprimer()
                    },
                ]
            },
        ];
    }

    initialiserEntretien() {
        if (this.entretien) {
            if (this.entretien.categorie) {
                this.entretien.categorie = this.categories.find(x => x.categorieEntretienId == this.entretien.categorie.categorieEntretienId)
            }
            if (this.entretien.pieces) {
                this.entretien.pieces.forEach(p => {
                    p.idUnique = this.newGuid();
                });
            }
        }
    }

    onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
      }

    rechercherDescritpion(event) {
        //this.suggestionsFiltrees = this.suggestionsDescription.filter(x => x.toLowerCase().startsWith(this.nouvelItem.description.toLowerCase()));
        this.suggestionDescription = this.descriptionsEntretiens.filter((x, i, a) => a.indexOf(x) === i && this.entretien.description.localeCompare(x.substring(0, this.entretien.description.length), 'en', { sensitivity: 'base' }) == 0);
    }

    surBoutonModifier() {
        this.bloquerDernierHeure = this.entretien.indSeuilHeure;
        this.bloquerDernierKm = this.entretien.indSeuilKm;
        this.bloquerDernierMois = this.entretien.indSeuilMois;
        this.Modifier();
    }

    surBoutonAnnulerModification() {
        
        this.Bloquer();
        this.entretienPreventifService.ObtenirDetail(this.entretien.entretienPreventifId).then(res => {
            this.entretien = res.valeur;
            this.initialiserEntretien();
            this.initialiserMenu();
            this.Debloquer();
        });

        this.Consulter();
    }

    enregistrerModifications() {
        this.submitted = true;
        this.messageService.clear();

        if (this.valider()) {
            this.Bloquer();
            if (this.entretien.entretienPreventifId == 0) {
                this.entretienPreventifService.Creer(this.entretien).then(x => {
                    this.Debloquer();
                    if (x.estUnSucces) {
                        this.Consulter();
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Entretien créé.' });
                    } else {
                        x.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description, sticky: true });
                        });
    
                    }
    
                });
            } else {
                this.entretienPreventifService.Modifier(this.entretien).then(x => {
                    this.Debloquer();
                    if (x.estUnSucces) {
                        this.Consulter();
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Entretien modifié.' });
                    } else {
                        x.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description, sticky: true });
                        });
    
                    }
    
                });
            }
        }
    }

    valider(): boolean {

        if (!this.entretien.description) {
            return false;
        }

        if (this.entretien.indSeuilHeure && (!this.entretien.nbHeure || !this.entretien.tolHeure)) {
            return false;
        }

        if (this.entretien.indSeuilHeure && !this.bloquerDernierHeure && !this.entretien.heureFaitCompteur && this.entretien.heureFaitCompteur != 0) {
            return false;
        }

        if (this.entretien.indSeuilHeure && !this.bloquerDernierHeure && this.entretien.heureFaitCompteur && this.entretien.heureFaitCompteur != 0 && !this.entretien.dateHeure) {
            return false;
        }

        if (this.entretien.indSeuilKm && (!this.entretien.nbKm || !this.entretien.tolKm)) {
            return false;
        }

        if (this.entretien.indSeuilKm && !this.bloquerDernierKm && !this.entretien.kmFaitCompteur && this.entretien.kmFaitCompteur != 0) {
            return false;
        }

        if (this.entretien.indSeuilKm && !this.bloquerDernierKm && this.entretien.kmFaitCompteur && this.entretien.kmFaitCompteur != 0 && !this.entretien.dateKm) {
            return false;
        }

        if (this.entretien.indSeuilMois && !this.bloquerDernierMois && !this.entretien.moisFait) {
            return false;
        }


        return true;

        
    }

    ajouterPiece(){

        const ref = this.dialogService.open(SelectionPieceComponent, {
            data: {
                uniteId: this.entretien.item.itemId
            },
            header: 'Sélection d\'une pièce',
            width: '95%'
        }).onClose.subscribe((res: ResultatSelectionItem) => {

            if (res) {

                this.entretien.pieces.push({
                    entretienPreventifId: this.entretien.entretienPreventifId,
                    item: res.item,
                    quantite: res.quantiteDemande.toString(),
                    idUnique: this.newGuid()
                });

                //TODO: temp
               // this.messageService.add({ severity: 'success', summary: 'Succès', detail: `La pièce '${res.item.description} (${res.item.numero})' a été ajouté.` });

                /*  this.achatService.CreerItemBonCommande({
                     itemBonCommandeId: 0,
                     bonCommandeId: this.bonCommande.bonCommandeId,
                     item: res.item,
                     noFournisseur: res.noFournisseur,
                     quantite: res.quantiteDemande,
                     note: res.note,
                     prix: res.prix,
                     codeProjet: res.codeProjet,
                     codeActivite: res.codeActivite,
                     codeCategorie: res.codeCategorie,
                     codeEquipement: res.codeEquipement,
                     codeExtra: res.codeExtra
                 }).then(res => {
                     if (res.estUnSucces) {
                         this.bonCommande.itemsBonCommande.push(res.valeur);
                         //this.updateVisibility();
                         this.messageService.add({ severity: 'success', summary: 'Succès', detail: `L'item '${res.valeur.item.description} (${res.valeur.item.numero})' a été ajouté.` });
     
                     } else {
                         res.messages.forEach(x => {
                             this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                         });
                     }
                 }); */


            }


        });
    }

    supprimerPiece(event, piece: PieceNecessaireEntite) {
        this.entretien.pieces = this.entretien.pieces.filter(x => x.idUnique != piece.idUnique);
    }

    surMenuContextuelPiece() {


        
    }

    dupliquer() {
        const ref = this.dialogService.open(SelectionEntretienComponent, {
            data: {
            },
            header: 'Dupliquer un entretien',
            width: '95%'
        }).onClose.subscribe((res: EntretienPreventifEntite) => {

            if (res) {
                this.entretien.description = res.description;
                this.entretien.categorie = this.categories.find(x => x.categorieEntretienId ==  res.categorie?.categorieEntretienId);
                this.entretien.indSeuilHeure = res.indSeuilHeure;
                this.entretien.nbHeure = res.nbHeure;
                this.entretien.tolHeure = res.tolHeure;
                this.entretien.indSeuilKm = res.indSeuilKm;
                this.entretien.nbKm = res.nbKm;
                this.entretien.tolKm = res.tolKm;
                this.entretien.indSeuilMois = res.indSeuilMois;
                this.entretien.nbMois = res.nbMois;
                this.entretien.tolMois = res.tolMois;
                this.entretien.indDebutMois = res.indDebutMois;

                this.entretien.pieces = [];

                if (res.pieces) {
                    res.pieces.forEach(x => {
                        this.entretien.pieces.push({
                            entretienPreventifId: this.entretien.entretienPreventifId,
                            item: x.item,
                            quantite: x.quantite.toString(),
                            idUnique: this.newGuid()
                        })
                    });
                }
            }
        });
    }

    supprimer() {

        this.confirmationService.confirm({
            target: event.target,
            message: 'Voulez-vous vraiment supprimer l\'entretien préventif ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.Bloquer();
                this.entretienPreventifService.Supprimer(this.entretien.entretienPreventifId).then(res => {
                    this.Debloquer();
                    if (res.estUnSucces) {
                        this.routerService.navigateByUrl(`item/${this.entretien.item.itemId}?t=7`)
                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                });
            }
        });
    }
}
