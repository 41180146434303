import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from "@angular/core"; import { Location } from "@angular/common";

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { ReponseBase } from './reponse-base';
import { GarantieEntite } from '../entites/garantie';

@Injectable({ providedIn: 'root' })
export class GarantieService {

  private obtenirListeUrl = environment.apiBaseUrl + 'garantie';
  private obtenirListeParItemUrl = environment.apiBaseUrl + 'garantie/item/{id}';
  private obtenirUrl = environment.apiBaseUrl + 'garantie/{id}';
  private ajouterUrl = environment.apiBaseUrl + 'garantie';
  private modifierUrl = environment.apiBaseUrl + 'garantie/modifier';
  private supprimerUrl = environment.apiBaseUrl + 'garantie/{id}';


  public httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(
    private http: HttpClient,
    public router: Router) { }

  public ObtenirListe(): Promise<ReponseBase<GarantieEntite[]>> {
    var p = this.http.get<ReponseBase<GarantieEntite[]>>(this.obtenirListeUrl)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public ObtenirListeParItem(id: number): Promise<ReponseBase<GarantieEntite[]>> {
    var p = this.http.get<ReponseBase<GarantieEntite[]>>(this.obtenirListeParItemUrl.replace('{id}', id.toString()))
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public Obtenir(id: number): Promise<ReponseBase<GarantieEntite>> {
    var p = this.http.get<ReponseBase<GarantieEntite>>(this.obtenirUrl.replace('{id}', id.toString()))
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public Ajouter(garantie: GarantieEntite): Promise<ReponseBase<GarantieEntite>> {
    var p = this.http.post<ReponseBase<GarantieEntite>>(this.ajouterUrl, JSON.stringify(garantie), this.httpOptions)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public Modifier(garantie: GarantieEntite): Promise<ReponseBase<GarantieEntite>> {
    var p = this.http.post<ReponseBase<GarantieEntite>>(this.modifierUrl, JSON.stringify(garantie), this.httpOptions)
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  public Supprimer(id: number): Promise<ReponseBase<number>> {
    var p = this.http.delete<ReponseBase<number>>(this.supprimerUrl.replace('{id}', id.toString()))
      .toPromise();

      p.catch(() => this.router.navigateByUrl('error'));

      return p;
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);

      this.log(`${operation} failed: ${error.message}`);

      this.router.navigateByUrl('erreurtechnique');

      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(message);
  }
}
