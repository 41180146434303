<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<p-card *ngIf="!EstCharge()">
    <div class="fluid formgrid grid">
        <div class="flex flex-column gap-2 my-2 col-12 text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-card>

<div *ngIf="EstCharge()" class="grid">
    <div class="col-12">
        <div class="card">
            <h5>Catégorie: {{categorie.description}}
            </h5>

            <p-menubar autoDisplay="false" [model]="menu">
               <!--  <p-button *ngIf="modeConsultation()" (click)="btnModifier_click()" label="Modifier" icon="pi pi-pencil"
                    iconPos="left"></p-button>
                <p-button *ngIf="modeAjoutModification()" label="Sauvegarder" icon="pi pi-pencil" iconPos="left">
                </p-button>
                <p-button *ngIf="modeAjoutModification()" label="Annuler" icon="pi pi-pencil" iconPos="left"
                    (onClick)="btnAnnuler_click()"></p-button> -->
            </p-menubar>

        </div>
    </div>
</div>

<div *ngIf="EstCharge()" class="grid">
    <div class="col-12">

        <div class="card">

            <p-tabView orientation="left">
                <p-tabPanel header="Informations générales" leftIcon="pi pi-info-circle">

                    <div class="fluid formgrid grid">

                        <div class="flex flex-column gap-2 my-2 col-12 md:col-6 lg:col-3">
                            <label for="firstname">Nom</label>
                            <input id="firstname" type="text" pInputText readonly value="{{categorie.nom}}">
                        </div>

                    </div>

                </p-tabPanel>
                <p-tabPanel header="Sous-catégories" leftIcon="pi pi-sitemap">

                    <p-menu #menuContextuelSousCategorie popup="popup" [model]="contenuMenuContextuelSousCategorie"></p-menu>

                    <p-table #dt [value]="sousCategories" dataKey="sousCategorieId" tableStyleClass="tableNormale" autoLayout="true"
                     [(contextMenuSelection)]="selectionSousCategorie"
                    [contextMenu]="cm" [rowHover]="true" [filterDelay]="0"
                    [globalFilterFields]="['nom','prefixe']">
                    <ng-template pTemplate="caption">
                        <div class="flex align-content-center justify-content-between">
                            <div>
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search"></i>
                                    <input pInputText type="text"
                                        (input)="dt.filterGlobal($event.target.value, 'contains')"
                                        placeholder="Recherche..." />
                                </span>
                                    <button pButton pRipple icon="pi pi-plus"
                                class="p-button-success mr-2 ml-4 mb-2 boutonGrille"
                                (click)="ajouterSousCategorie()"></button>
                            </div>
                            <h5 class="titreTableau m-0">Sous-Catégories</h5>
                            <div>
                               
                            </div>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <tr>
                            
                            <th pSortableColumn="nom">
                                <div class="flex justify-content-between align-items-center">
                                    Nom
                                    <p-sortIcon field="nom"></p-sortIcon>
                                    <p-columnFilter type="text" field="nom" display="menu" class="ml-auto">
                                    </p-columnFilter>
                                </div>
                            </th>
                            <th pSortableColumn="prefixe">
                                <div class="flex justify-content-between align-items-center">
                                    Préfixe
                                    <p-sortIcon field="prefixe"></p-sortIcon>
                                    <p-columnFilter type="text" field="prefixe" display="menu" class="ml-auto">
                                    </p-columnFilter>
                                </div>
                            </th>
                            <th pSortableColumn="nbItems">
                                <div class="flex justify-content-between align-items-center">
                                    Nombre d'items
                                    <p-sortIcon field="nbItems"></p-sortIcon>
                                    <p-columnFilter type="numeric" field="nbItems" display="menu" class="ml-auto">
                                    </p-columnFilter>
                                </div>
                            </th>
                            <th style="min-width: 5rem; width: 5rem;"></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-sousCategorie>
                        <tr [pContextMenuRow]="sousCategorie">
                            <td>{{sousCategorie.nom}}</td>
                            <td>{{sousCategorie.prefixe}}</td>
                            <td>{{sousCategorie.nbItems}}</td>
                            <td>
                                <button pButton pRipple icon="pi pi-bars" class="p-button-secondary mr-2 boutonGrille"
                                    (click)="surMenuContextuelSousCategorie($event, sousCategorie)"></button>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="4">Aucune catégorie.</td>
                        </tr>
                    </ng-template>
                </p-table>

                </p-tabPanel>
                
            </p-tabView>
        </div>
    </div>


</div>