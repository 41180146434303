import { Component, computed, OnDestroy, OnInit, signal } from "@angular/core"; import { Location } from "@angular/common";
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { EmployeEntite } from 'src/app/entites/employe';
import { BaseComponent } from 'src/app/base/base-component';
import { ProfilService } from 'src/app/services/profil-service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AppMainComponent } from 'src/app/app.main.component';
import { CleValeur } from 'src/app/entites/cleValeur';
import { BonTravailService } from 'src/app/services/bon-travail-service';
import { EntretienPreventifEntite } from 'src/app/entites/mecanique/entretien-preventif';
import { BonTravailEntite } from 'src/app/entites/mecanique/bon-travail';
import { EStatuts } from 'src/app/entites/enums/statuts';
import { ERaisonBonTravail } from 'src/app/entites/enums/raison-bon-travail';
import { EtapeBonTravailEntite } from 'src/app/entites/mecanique/etape-bon-travail';
import { RequeteAjouterEtapesBonTravail } from 'src/app/services/requetes/requete-ajouter-etapes-bon-travail';
import { EntretienPreventifService } from 'src/app/services/entretien-preventif-service';
import { ETypeEtapeBonTravail } from 'src/app/entites/enums/type-etape-bon-travail';
import { EntretienCorrectifEntite } from 'src/app/entites/mecanique/entretien-correctif';
import { ItemBonCommande } from "src/app/entites/item-bon-commande";
import { RequeteApprouverBonTravail } from "src/app/services/requetes/requete-approuver-bontravail";
import { PieceBonTravailEntite } from "src/app/entites/mecanique/piece-bon-travail";
import { filter } from "rxjs";

export interface ParametresApprouverBonTravail {
    bonTravail: BonTravailEntite;
    alerteEtapesNonCompletes: boolean;
}

@Component({
    templateUrl: './approuver-bon-travail.component.html',
})
export class ApprouverBonTravailComponent extends BaseComponent implements OnInit, OnDestroy {

    donnees: ParametresApprouverBonTravail;

    bonTravail: BonTravailEntite;

    dateDebut: Date;
    dateFin: Date;

    items: ItemBonCommande[] = [];

    piecesSansCout: PieceBonTravailEntite[] = [];

    heuresOriginal?: number;
    kmOriginal?: number;

    indCreerPartiel: boolean = false;

    submitted: boolean;
    submittedItems: boolean;
    blocked: boolean = false;
    loading: boolean = false;

    constructor(
        profilService: ProfilService,
        route: ActivatedRoute,
        routerService: Router,
        appMain: AppMainComponent, location: Location, 
        public sdb: DonneesBaseService,
        public bonTravailService: BonTravailService,
        public ref: DynamicDialogRef,
        public confirmationService: ConfirmationService,
        public messageService: MessageService,
        public config: DynamicDialogConfig) {
        super(sdb, profilService, route, routerService, appMain);
    }

    ngOnInit() {

        this.donnees = this.config.data;

        this.bonTravail = this.donnees.bonTravail;

        this.heuresOriginal = this.bonTravail.heuresCompteur;
        this.kmOriginal = this.bonTravail.kmCompteur;

        if (this.bonTravail.heuresMecanicien && this.bonTravail.heuresMecanicien > 0) {
            this.bonTravail.heuresCompteur = this.bonTravail.heuresMecanicien;
        }   
        
        if (this.bonTravail.kmMecanicien && this.bonTravail.kmMecanicien > 0) {
            this.bonTravail.kmCompteur = this.bonTravail.kmMecanicien;
        }   

        if (this.bonTravail.dateTravail && !this.bonTravail.dateFin) {
            this.bonTravail.dateFin = new Date(this.bonTravail.dateTravail);
        }

        this.CompleterChargement();

        this.routerService.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe((event: NavigationEnd) => {
            this.ref.close(null);
        });
       
    }

    ngOnDestroy(): void {
        
    }

    approuver() {

        this.submitted = true;
        this.messageService.clear();

        if (!this.valider() || !this.validerChampsRequis()) {
            this.messageService.add({ key: 'assignerEntretien', severity: 'error', summary: 'Erreurs', detail: 'Veuillez corriger les erreurs de saisie.' });
            return;
        }

        this.Bloquer();

        const requete: RequeteApprouverBonTravail = {
            bonTravail: this.bonTravail,
            items: this.items
        }

        this.bonTravailService.Completer(requete).then(resApprobation => {
            if (resApprobation.estUnSucces) {

                if (this.indCreerPartiel) {
                    this.bonTravailService.CreerPartiel({
                        bonTravailId: this.bonTravail.bonTravailId,
                        date: this.bonTravail.dateTravail
                    })
                }
                this.Debloquer();
                this.ref.close(resApprobation.valeur);
               
            } else {
                resApprobation.messages.forEach(x => {
                    this.messageService.add({ key: 'assignerEntretien', severity: 'error', summary: 'Erreur', detail: x.description });
                });
                this.Debloquer();
            }
        });       
    }

    rechercher() {
        this.submittedItems = true;

        if (!this.dateDebut || !this.dateFin) return;

        this.loading = true;
        this.bonTravailService.ObtenirItemBonCommandeAssocie(this.bonTravail.bonTravailId, this.dateDebut, this.dateFin).subscribe({
            next: res => {
                this.items = res.body.map(x => ({ ...x, quantiteUtilisee: 0 }));
                this.loading = false;
                this.piecesSansCout = this.bonTravail.etapes.flatMap(x => x.pieces).filter(x => !this.items.find(y => y.item.itemId == x.item.itemId));
            },
            error: err => {
                this.messageService.add({ severity: 'error', summary: 'Erreur', detail: 'Une erreur est survenue lors de la recherche des items de bon de commande.' });
            }
        });
    }

    valider() {

        if (this.piecesSansCout.length > 0) {
            this.confirmationService.confirm({
                message: 'Des pièces n\'ont pas de coût associé. Etes-vous sûr de vouloir approuver le bon de travail ?',
                reject: () => {
                    return false;
                }
            });
        }

        return this.bonTravail.dateTravail && this.bonTravail.dateFin;
    }

    validerChampsRequis() {
        if((this.bonTravail.heuresCompteur == null || this.bonTravail.heuresCompteur == undefined) && this.bonTravail.item.indSuiviHeures) {
            return false;
        }

        if((this.bonTravail.kmCompteur == null || this.bonTravail.kmCompteur == undefined) && this.bonTravail.item.indSuiviKms) {
            return false;
        }

        return true;
    }

    annuler() {
        this.bonTravail.heuresCompteur = this.heuresOriginal;
        this.bonTravail.kmCompteur = this.kmOriginal;
        this.ref.close(null);
    }

    getQuantiteRequise(itemId: number): number {
        const piece = this.bonTravail.etapes.find(x => x.pieces.find(p => p.item.itemId == itemId));
        if(piece) {
            const quantite = parseInt(piece.pieces.find(p => p.item.itemId == itemId)?.quantite);
            return quantite ? quantite : 0;
        }
        return 0;
    }

    goToAddBonCommande() {
        this.routerService.navigate(['/achat/nouveau']);
    }

    stringToColor(str: string): string {
        return '#' + Math.floor(Math.random() * 16777215).toString(16);
    }
}