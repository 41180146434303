<div class="card">
<div class="grid">
    <div class="col-12">
        

            <h5>{{item.numero}} - {{item.description}}</h5>

            <p-chart type="line" [data]="basicData" [options]="basicOptions"></p-chart>
            
            <br>

    </div>
</div>
<!-- <div class="grid">
    <div class="col-12 text-right ">
        <p-button label="Envoyer" class="" icon="pi pi-send" iconPos="left" (onClick)="Envoyer()"></p-button>     
        <p-button label="Annuler" class="p-button-secondary ml-2" icon="pi pi-check" iconPos="left" (onClick)="Annuler()"></p-button>
    </div>
</div> -->
</div>