<div [class]=" 'card'">
    <div class="fluid formgrid grid">
        <div class="flex flex-column gap-2 my-2 col-12">
            <label for="traitementPropose">Traitement proposé</label>
            <textarea pInputTextarea id="traitementPropose" [(ngModel)]="nonConformite.traitementPropose"
                [readOnly]="modeConsultation()" rows="5" style="resize: vertical;"></textarea>
        </div>
    </div>

    <div class="flex flex-column gap-2 my-2">
        <label for="fichiers">Fichiers associés au traitement de la non-conformité</label>
        <p-toast />
        <p-fileUpload *ngIf="modeModification()" #fu name="myfile[]" multiple="multiple" customUpload="true"
            maxFileSize="209715200" invalidFileSizeMessageSummary="{0}: Fichier trop volumineux, "
            invalidFileSizeMessageDetail="la grosseur maximale est {0}." chooseLabel="Ajouter" showUploadButton="false"
            showCancelButton="false" (uploadHandler)="onUpload($event)" (onSelect)="onSelect($event)">
        </p-fileUpload>

        <p-button *ngIf="modeConsultation()" icon="pi pi-eye" label="Voir les documents"
            (click)="onViewDocuments.emit()"></p-button>
    </div>

    <div class="flex gap-3 flex-wrap">
        <div class="flex flex-column gap-2 my-2">
            <label for="montant" style="width: fit-content;">Montant</label>
            <p-inputNumber [(ngModel)]="nonConformite.monetaire" inputId="locale-user" [minFractionDigits]="2"
                [readonly]="modeConsultation()" suffix="$" min="0" />
        </div>

        <div class="flex flex-column gap-2 my-2">
            <label for="modeEstimation">Type de coût</label>
            <p-selectButton [options]="optionsMode" [(ngModel)]="nonConformite.modeEstimation"
                [disabled]="modeConsultation()" />
        </div>

        <div class="flex flex-column gap-2 my-2 ">
            <label for="echeancier">Echéancier</label>
            <p-calendar *ngIf="!modeConsultation()" [(ngModel)]="nonConformite.echeancier" dateFormat="yy-mm-dd"
                [showIcon]="true" [minDate]="minDate"></p-calendar>
            <input *ngIf="modeConsultation()" id="echeancier" type="text" pInputText
                value="{{nonConformite.echeancier | date:'yyyy-MM-dd'}}" [readOnly]="true">
        </div>

        <div class="flex flex-column gap-2 my-2">
            <label for="dossierSst">Dossier SST</label>
            <input id="dossierSst" pInputText [(ngModel)]="nonConformite.dossierSst" [readonly]="modeConsultation()">
        </div>
    </div>

    <p-divider align="left">
        <b>Approbation</b>
    </p-divider>

    <div class="flex flex-wrap gap-3 my-2">
        <div class="flex align-items-center">
            <p-radioButton name="approbation" value="Approuvé" [(ngModel)]="nonConformite.approbation"
                inputId="approbation1" [disabled]="modeConsultation()" />
            <label for="approbation1" class="ml-2">
                Approuvé
            </label>
        </div>

        <div class="flex align-items-center">
            <p-radioButton name="approbation" value="Rejeté" [(ngModel)]="nonConformite.approbation"
                inputId="approbation2" [disabled]="modeConsultation()" />
            <label for="approbation2" class="ml-2">
                Rejeté
            </label>
        </div>

        <div class="flex align-items-center">
            <p-radioButton name="approbation" value="Approuvé sous conditions" [(ngModel)]="nonConformite.approbation"
                inputId="approbation3" [disabled]="modeConsultation()" />
            <label for="approbation3" class="ml-2">
                Approuvé sous conditions
            </label>
        </div>

        <div class="flex align-items-center">
            <p-radioButton name="approbation" value="Autre" [(ngModel)]="nonConformite.approbation"
                inputId="approbation4" [disabled]="modeConsultation()" />
            <label for="approbation4" class="ml-2">
                Autre: <input *ngIf="nonConformite.approbation === 'Autre'" type="text" class="ml-2" pInputText
                    [(ngModel)]="approbationAutre" [readonly]="modeConsultation()" />
            </label>
        </div>
    </div>
    <div *ngIf="modePopup" class="text-right mt-3">
        <button pButton pRipple label="Confirmer" icon="pi pi-check" class="p-button-primary"
            (click)="fileUpload.upload()"></button>
    </div>
</div>