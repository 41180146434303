<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<p-card *ngIf="!EstCharge()">
    <div class="fluid formgrid grid">
        <div class="flex flex-column gap-2 col-12 text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-card>

<div *ngIf="EstCharge()" class="grid">
    <div class="col-12">
        <div class="card">
            <div class="fluid formgrid grid mb-4">
                <div class="flex flex-column gap-2 col-12 md:col-6">
                    <label for="nonConformite">Non-conformité<span class="requis">*</span></label>
                    <p-dropdown [options]="nonConformites" [(ngModel)]="actionCorrective.nonConformite" optionLabel="numero"
                        appendTo="body"  [showClear]="true" filter="true" filterBy="numero,titre"
                        [disabled]="nonConformiteInputDisabled"
                        placeholder="Sélectionner une non-conformité...">
                        <ng-template pTemplate="selectedItem">
                            <div *ngIf="actionCorrective.nonConformite">
                                <div>{{actionCorrective.nonConformite.numero}} - {{actionCorrective.nonConformite.titre}}</div>
                            </div>
                        </ng-template>
                        <ng-template let-nonConformite pTemplate="item">
                            <div>
                                <div>{{nonConformite.numero}} - {{nonConformite.titre}} 
                                    
                                    <span [class]="'statut ' + nonConformite.statut.nom.toLowerCase()">
                                        {{nonConformite.statut.nom}}
                                    </span>
                                </div>
                            </div>
                        </ng-template>
                    </p-dropdown>
                    <small class="p-error" *ngIf="submitted && !actionCorrective.nonConformite">La non-conformité est obligatoire.</small>
                </div>
                <div class="flex flex-column-reverse">
                    <p-button label="Aperçu" icon="pi pi-eye" iconPos="left" (click)="voirNonConformite()" [disabled]="actionCorrective.nonConformite == null"></p-button>
                </div>
            </div>

            <p-divider>
                <span class="font-bold">Description<span class="requis">*</span></span>
            </p-divider>
            <div style="padding: 1.25rem" class="w-full">
                <p-editor [(ngModel)]="actionCorrective.demande" [placeholder]="'Décrivez l\'action corrective...'" [style]="{ height: '200px' }"/>
                <small class="p-error" *ngIf="submitted && !actionCorrective.demande">La description est obligatoire.</small>
            </div>

            <div class="fluid formgrid grid">
                <div class="flex flex-column gap-2 col-12 md:col-6 lg:col-3">
                    <label for="date">Date cible<span class="requis">*</span></label>
                    <p-calendar 
                    [(ngModel)]="actionCorrective.dateCible" 
                    [showIcon]="true" 
                    [iconDisplay]="'input'"
                    [minDate]="actionCorrective.date" />
                    <small class="p-error" *ngIf="submitted && !actionCorrective.dateCible">La date cible est obligatoire.</small>
                </div>
            </div>
           
            <div class="col-12 text-right">
                <p-button label="Créer" (click)="creerActionCorrective()" icon="pi pi-save" iconPos="left"></p-button>
            </div>
        </div>
    </div>
</div>