import { Component, OnChanges, OnInit, SimpleChanges } from "@angular/core"; import { Location } from "@angular/common";
import { MenuService } from './app.menu.service';
import { PrimeNGConfig, MessageService } from 'primeng/api';
import { AppComponent } from './app.component';

import { AuthService } from '@auth0/auth0-angular';
import { Router } from '@angular/router';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { ItemService } from './services/item-service';
import { ProfilService } from './services/profil-service';

@Component({
    selector: 'app-main',
    templateUrl: './app.main.component.html',
    providers: [MessageService]
})
export class AppMainComponent implements OnInit {

    
    detailItemId: number;
    afficherDetailItem: boolean = false;

    detailBonTravailId: number;
    afficherDetailBonTravail: boolean = false;
    
    mainBlocked: boolean = false;
    overlayMenuActive: boolean;

    showIdleDialog: boolean = false;
    lastPing?: Date = null;
    title = 'angular-idle-timeout';

    menuButtonIcon: string = 'pi pi-chevron-left';

    staticMenuDesktopInactive: boolean;

    staticMenuMobileActive: boolean;

    menuClick: boolean;

    search = false;

    searchClick = false;

    userMenuClick: boolean;

    topbarUserMenuActive: boolean;

    notificationMenuClick: boolean;


    topbarNotificationMenuActive: boolean;

    rapportsMenuClick: boolean;

    topbarRapportsMenuActive: boolean;

    favoriteMenuClick: boolean;

    topbarFavoriteMenuActive: boolean;

    rightMenuActive: boolean;

    configActive: boolean;

    configClick: boolean;

    resetMenu: boolean;

    menuHoverActive = false;

    public recherche: string;

    constructor(private menuService: MenuService,
        private primengConfig: PrimeNGConfig,
        public app: AppComponent,
        public auth: AuthService,
        private router: Router,
        private idle: Idle,
        private keepalive: Keepalive,
        private itemService: ItemService) {

            //300
        idle.setIdle(300);
        idle.setTimeout(false);
        idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

        idle.onIdleStart.subscribe(() => {
            this.itemService.Desactiver();
            this.showIdleDialog = true;
        });

        idle.onIdleEnd.subscribe(() => {
        });

        keepalive.interval(15);
        keepalive.onPing.subscribe(() => this.lastPing = new Date());
        this.reset();

    }

    reset() {
        this.idle.watch();
    }

    surFermetureDialogueIdle() {
        this.itemService.Activer();
        this.showIdleDialog = false;
        this.reset();
    }

    /*  hideChildModal(): void {
       this.showIdleDialog = false;
     } */

    /*  stay() {
       this.showIdleDialog = false;
       this.reset();
     } */

    /*      logout() {
           this.showIdleDialog = false;
           //this.appService.setUserLoggedIn(false);
           this.router.navigate(['/']);
         } */


    ngOnInit() {
    }
    
    public Bloquer() {
        this.mainBlocked = true;
    }

    public Debloquer() {
        this.mainBlocked = false;
    }

    onHome(event) {
        this.router.navigateByUrl('');

    }



    onLayoutClick() {
        if (!this.searchClick) {
            this.search = false;
        }

        if (!this.userMenuClick) {
            this.topbarUserMenuActive = false;
        }

        if (!this.notificationMenuClick) {
            this.topbarNotificationMenuActive = false;
        }

        if (!this.rapportsMenuClick) {
            this.topbarRapportsMenuActive = false;
        }

        if (!this.favoriteMenuClick) {
            this.topbarFavoriteMenuActive = false;
        }

        if (!this.menuClick) {
            if (this.isSlim()) {
                this.menuService.reset();
            }

            if (this.overlayMenuActive || this.staticMenuMobileActive) {
                this.hideOverlayMenu();
            }

            this.menuHoverActive = false;
            this.unblockBodyScroll();
        }

        if (this.configActive && !this.configClick) {
            this.configActive = false;
        }

        this.searchClick = false;
        this.configClick = false;
        this.userMenuClick = false;
        this.notificationMenuClick = false;
        this.rapportsMenuClick = false;
        this.favoriteMenuClick = false;
        this.menuClick = false;
    }

    onMenuButtonClick(event) {
        this.menuClick = true;
        this.topbarUserMenuActive = false;
        this.topbarNotificationMenuActive = false;
        this.topbarRapportsMenuActive = false;
        this.rightMenuActive = false;

        if (this.isDesktop()) {
            if (this.menuButtonIcon === 'pi pi-chevron-left') {
                this.menuButtonIcon = 'pi pi-chevron-right';
            } else {
                this.menuButtonIcon = 'pi pi-chevron-left';
            }
        }

        if (this.isOverlay()) {
            this.overlayMenuActive = !this.overlayMenuActive;
        }

        if (this.isDesktop()) {
            this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
        } else {
            this.staticMenuMobileActive = !this.staticMenuMobileActive;
            if (this.staticMenuMobileActive) {
                this.blockBodyScroll();
            } else {
                this.unblockBodyScroll();
            }
        }

        event.preventDefault();
    }

    onSearchClick(event) {
        this.router.navigateByUrl('/recherche');
        /* this.search = !this.search;
        this.searchClick = !this.searchClick; */
    }

    onSearchHide(event) {
        this.search = false;
        this.searchClick = false;
    }

    onMenuClick($event) {
        this.menuClick = true;
        this.resetMenu = false;
    }

    onTopbarUserMenuButtonClick(event) {
        this.userMenuClick = true;
        this.topbarUserMenuActive = !this.topbarUserMenuActive;

        this.hideOverlayMenu();

        event.preventDefault();
    }

    onTopbarNotificationMenuButtonClick(event) {
        this.notificationMenuClick = true;
        this.topbarNotificationMenuActive = !this.topbarNotificationMenuActive;

        this.hideOverlayMenu();

        event.preventDefault();
    }

    onTopbarRapportsMenuButtonClick(event) {
        this.rapportsMenuClick = true;
        this.topbarRapportsMenuActive = !this.topbarRapportsMenuActive;

        this.hideOverlayMenu();

        event.preventDefault();
    }

    onTopbarFavoriteMenuButtonClick(event) {
        this.favoriteMenuClick = true;
        this.topbarFavoriteMenuActive = !this.topbarFavoriteMenuActive;

        this.hideOverlayMenu();

        event.preventDefault();
    }

    onRippleChange(event) {
        this.app.ripple = event.checked;
        this.primengConfig = event.checked;
    }

    onConfigClick(event) {
        this.configClick = true;
    }

    isSlim() {
        return this.app.menuMode === 'slim';
    }

    isOverlay() {
        return this.app.menuMode === 'overlay';
    }

    isDesktop() {
        return window.innerWidth > 991;
    }

    isMobile() {
        return window.innerWidth <= 991;
    }

    hideOverlayMenu() {
        this.overlayMenuActive = false;
        this.staticMenuMobileActive = false;
    }

    blockBodyScroll(): void {
        if (document.body.classList) {
            document.body.classList.add('blocked-scroll');
        } else {
            document.body.className += ' blocked-scroll';
        }
    }

    unblockBodyScroll(): void {
        if (document.body.classList) {
            document.body.classList.remove('blocked-scroll');
        } else {
            document.body.className = document.body.className.replace(new RegExp('(^|\\b)' +
                'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    }
}
