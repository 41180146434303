<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<p-card *ngIf="!EstCharge()">
	<div class="fluid formgrid grid">
		<div class="flex flex-column gap-2 my-2 col-12 text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-card>

<div *ngIf="EstCharge()" class="grid">
	<div class="col-12">
		<div class="card">
			<h5>Approuver les km
			</h5>
			<p-menubar autoDisplay="false" [model]="menu">
			</p-menubar>
		</div>

	</div>
</div>


<div *ngIf="EstCharge()" class="grid">
	<div class="col-12">
		<div class="card">
	
			<div class="fluid formgrid grid">
				<div class="flex flex-column gap-2 my-2 col-12 md:col-3">
					<label for="noUnite">Emplacement<span class="requis">*</span></label>
					<p-dropdown [options]="emplacements" [(ngModel)]="emplacement" optionLabel="nom"
						appendTo="body" filter="true" filterBy="nom, code"
						placeholder="Sélectionnez un emplacement...">
						<ng-template pTemplate="selectedItem">
							<div *ngIf="emplacement">
								<div>{{emplacement.nom}} ({{emplacement.code}})</div>
							</div>
						</ng-template>
						<ng-template let-emplacement pTemplate="item">
							<div>
								<div>{{emplacement.nom}} ({{emplacement.code}})</div>
							</div>
						</ng-template>
					</p-dropdown>
				</div>
				<div class="flex flex-column gap-2 my-2 col-12 lg:col-3">
					<label for="description">Département</label>
					<p-dropdown [options]="departements" [(ngModel)]="departement" optionLabel="nom"
						appendTo="body" filter="true" filterBy="nom"
						placeholder="Sélectionnez un departement...">
						<ng-template pTemplate="selectedItem">
							<div *ngIf="departement">
								<div>{{departement.nom}}</div>
							</div>
						</ng-template>
						<ng-template let-departement pTemplate="item">
							<div>
								<div>{{departement.nom}}</div>
							</div>
						</ng-template>
					</p-dropdown>
				</div>
				<!-- <div class="col-12 md:col-2 lg:col-1 text-right">
					<label>&nbsp;</label>
					<p-button label="Filtrer" (click)="filtrer()" icon="pi pi-filter" iconPos="left"></p-button>
				</div> -->
				<div class="col-12 md:col-6 lg:col-3">
					<label>&nbsp;</label>
					<button style="width: auto; margin-top: 22px; margin-right: 10px;" pButton pRipple label="Filtrer" icon="pi pi-filter" class="p-button-primary"
						(click)="filtrer()"></button>
					<button style="width: auto; margin-top: 22px;" pButton pRipple label="Annuler" icon="pi pi-filter-slash" class="p-button-secondary"
						(click)="retirerFiltres()"></button>
				</div>



			</div>
			
		
					<p-table #dtKms *ngIf="EstCharge()" [value]="kms" dataKey="itemId"
					[(selection)]="selectionKms"  (onRowSelect)="surChangementSelection()" (onRowUnselect)="surChangementSelection()"
            (onHeaderCheckboxToggle)="surChangementSelection()"
						tableStyleClass="tableMoyen" autoLayout="true"
						[globalFilterFields]="['numero','description']">
						<ng-template pTemplate="caption">
							<div class="flex align-content-center justify-content-between">
								<div>
									<span class="p-input-icon-left">
										<i class="pi pi-search"></i>
										<input pInputText type="text"
											(input)="dtKms.filterGlobal($event.target.value, 'contains')"
											placeholder="Recherche..." />
									</span>
								</div>
							</div>
						</ng-template>

						<ng-template pTemplate="header">
							<tr>
								<th style="min-width: 3rem">
									<p-tableHeaderCheckbox></p-tableHeaderCheckbox>
								</th>
								<th>Unité</th>
								<th>Description</th>
								<th>Département</th>
								<th>Emplacement</th>
								<th>Derniers km</th>
								<th>Date derniers km</th>
								<th>Nouveaux km</th>
								<th>Date nouveaux km</th>
								<th>Entré par</th>
							</tr>
						</ng-template>
						<ng-template pTemplate="body" let-entree>
							<tr>
								<td>
									<p-tableCheckbox [value]="entree"></p-tableCheckbox>
								</td>
								<td>{{entree.numero}}</td>
								<td>{{entree.description}}</td>
								<td>{{entree.departement}}</td>
								<td>{{entree.emplacement}}</td>
								<td>{{entree.kmsActuelles}}</td>
								<td>{{entree.dateKmsActuelles | date:'yyyy-MM-dd'}}</td>
								<td>{{entree.kms}}</td>
								<td>{{entree.dateNouvellesKms | date:'yyyy-MM-dd'}}</td>
								<td>{{entree.entrePar}}</td>
							</tr>
						</ng-template>
					</p-table>


		</div>
	</div>


</div>


