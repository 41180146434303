import { Component, OnInit, ViewChild, Input, ElementRef } from "@angular/core"; import { Location } from "@angular/common";
import { Table } from 'primeng/table';
import { BreadcrumbService } from '../../app.breadcrumb.service';

import { CommandeMin } from '../../entites/commande-min';
import { MenuItem, TreeNode } from 'primeng/api';
import { MessageService, LazyLoadEvent, ConfirmationService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ItemMin } from '../../entites/item-min';
import { ItemService } from '../../services/item-service';
import { Router, ActivatedRoute } from '@angular/router';

import { ToolbarModule } from 'primeng/toolbar';

import { MenubarModule } from 'primeng/menubar';
import { BaseComponent } from '../../base/base-component';
import { Item } from '../../entites/item';
import { Inventaire } from '../../entites/inventaire';
import { NoteEntite } from '../../entites/note';
import { Commande } from '../../entites/commande';
import { CommandeService } from '../../services/commande-service';
import { Emplacement } from '../../entites/emplacement';
import { EmplacementService } from '../../services/emplacement-service';
import { EntreeCommande } from '../../entites/entree-commande';
import { CleValeur } from 'src/app/entites/cleValeur';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { SelectionItemComponent, ETypeSelectionItem, ResultatSelectionItem } from 'src/app/controles/selection-item.component';
import { ItemCommandeEntite } from 'src/app/entites/item-commande';
import { ContextMenu } from 'primeng/contextmenu/public_api';
import { Statut } from 'src/app/entites/statut';
import { EStatuts } from 'src/app/entites/enums/statuts';
import { RequeteCreerItemCommande } from 'src/app/services/requetes/requete-creer-item-commande';
import { SaisieQuantiteComponent } from 'src/app/controles/saisie-quantite.component';
import { ProfilService } from 'src/app/services/profil-service';
import { RequeteModifierItemCommande } from 'src/app/services/requetes/requete-modifier-item-commande';
import { InfoChangementStatut } from 'src/app/entites/info-changement-statut';
import { AppMainComponent } from 'src/app/app.main.component';
import { SelectionDepartementComponent } from 'src/app/controles/selection-departement.component';
import { DepartementEntite } from 'src/app/entites/departement';
import { RapportService } from 'src/app/services/rapport-service';

@Component({
    templateUrl: './detail-approbation-precommande.component.html',
    styleUrls: ['./detail-approbation-precommande.component.scss'],
    providers: [MessageService, ConfirmationService, DialogService]
})
export class DetailApprobationPrecommandeComponent extends BaseComponent implements OnInit {

    emplacementId: number;
    emplacement: Emplacement;

    itemsCommande: ItemCommandeEntite[];
    selectionItemCommande: ItemCommandeEntite;
    selectionMultipleItemCommande: ItemCommandeEntite[] = [];
    menuContextuelEntrees: MenuItem[];

    statuts: Statut[] = this.sdb.StatutsPreCommande();

    ref: DynamicDialogRef;

    menu: MenuItem[];


    @ViewChild('menuContextuelItemCommande') menuContextuelItemCommande: ContextMenu;

    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        private breadcrumbService: BreadcrumbService,
        private CommandeService: CommandeService,
        private EmplacementService: EmplacementService,
        private ItemsService: ItemService,
        private rapportService: RapportService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        public dialogService: DialogService,
        public routerService: Router,
        route: ActivatedRoute,
        appMain: AppMainComponent, location: Location) {

        super(sdb, profilService, route, routerService, appMain);

        this.breadcrumbService.setItems([
            { label: 'pré-commandes', routerLink: '/precommandes' },
            { label: 'Détails' }
        ]);
        this.route.params.subscribe(params => { console.log(params.id); this.emplacementId = params.id as number; });
    }

    ngOnInit() {

        this.verifierAccesPage('approbation_precommande_detail');

        this.CommandeService.ObtenirApprobationPrecommande(this.emplacementId).then(x => {

            this.itemsCommande = x.valeur.itemsCommande;
            this.emplacement = x.valeur.emplacement;

            this.CompleterChargement(`Approbation pré-commande - ${this.emplacement.nom} (${this.emplacement.code})`);
        });

        this.initialiserMenu();

        this.colonnesDisponibles = [
            { champ: 'inventaire', entete: 'Inventaire', afficheParDefaut: false },
            { champ: 'demandeur', entete: 'Demandeur', afficheParDefaut: true },
            { champ: 'saisiPar', entete: 'Saisi par', afficheParDefaut: false },
            { champ: 'dateDemande', entete: 'Date de demande', afficheParDefaut: true },
            { champ: 'dateRequis', entete: 'Date requis', afficheParDefaut: true },
            { champ: 'codeProjet', entete: 'Code projet', afficheParDefaut: true },
            { champ: 'codeActivite', entete: 'Code activité', afficheParDefaut: true },
            { champ: 'departement', entete: 'Département', afficheParDefaut: true },
            { champ: 'emplacement', entete: 'Emplacement', afficheParDefaut: false },
            { champ: 'note', entete: 'Note', afficheParDefaut: true }
        ];
        this.colonnesSelectionnees = this.colonnesDisponibles.filter(x => x.afficheParDefaut);
    }

    initialiserMenu() {
        this.menu = [
            {
                label: 'Retour', icon: 'pi pi-fw pi-angle-double-left', command: () => this.routerService.navigateByUrl('approbationprecommandes')
            },
            {
                label: 'Imprimer', icon: 'pi pi-fw pi-print', command: () => this.imprimer()
            },
            {
                label: 'Commande',
                icon: 'pi pi-fw pi-eye',
                visible: this.estAutorise('commande_detail'),
                command: () => this.routerService.navigateByUrl('commande/' + this.emplacementId)
            },
            {
                label: 'Actions multiples',
                icon: 'pi pi-fw pi-bars',
                visible: this.estAutorise('approbation_precommande_approuver') || this.estAutorise('approbation_precommande_supprimer_item'),
                disabled: this.selectionMultipleItemCommande.length == 0,
                items: [
                    {
                        label: 'Assigner',
                        icon: 'pi pi-fw pi-forward',
                        visible: this.estAutorise('approbation_precommande_assigner'),
                        command: () => this.assignerLot()
                    },
                    {
                        label: 'Approuver',
                        icon: 'pi pi-fw pi-forward',
                        visible: this.estAutorise('approbation_precommande_approuver'),
                        disabled: this.selectionMultipleItemCommande.filter(x => x.departement).length != this.selectionMultipleItemCommande.length,
                        command: () => this.changerStatutLot(27, false)
                    },
                    {
                        separator: true, visible: this.estAutorise('approbation_precommande_supprimer_item')
                    },
                    {
                        label: 'Supprimer',
                        icon: 'pi pi-fw pi-times',
                        visible: this.estAutorise('approbation_precommande_supprimer_item')
                    },
                ]
            },
        ];
    }

    imprimer() {
        this.messageService.add({ severity: 'info', summary: 'Demande de rapport', detail: `Votre demande de rapport a été envoyée.` });

        this.rapportService.ProduireRapportPreCommande(this.emplacementId).then(x => {
            if (x) {
                this.messageService.add({ severity: 'success', summary: 'Succès', detail: `Votre rapport a été généré.` });
            } else {
                this.messageService.add({ severity: 'error', summary: 'Erreur', detail: `Une erreur est survenue pendant la génération de votre rapport.` });
            }
        });
    }

    surChangementSelection() {
        this.initialiserMenu();
    }

    supprimerItemcommnde(event, item: ItemCommandeEntite) {
        console.log(item);
        this.confirmationService.confirm({
            key: "popup",
            target: event.target,
            message: 'Voulez-vous vraiment supprimer l\'item "' + item.item.description + '" ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.CommandeService.SupprimerItemCommande(item.itemCommandeId).then(res => {
                    if (res.estUnSucces) {
                        this.itemsCommande = this.itemsCommande.filter(val => val.itemCommandeId != item.itemCommandeId);
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Item supprimé.' });
                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                });

            }
        });
    }

    convertToItemDivers(itemCommande: ItemCommandeEntite) {
        this.CommandeService.ConvertirItemPreCommandeADivers(itemCommande.itemCommandeId).then(res => {
            if (res.estUnSucces) {
                this.messageService.add({ severity: 'success', summary: 'Succès', detail: `L'item a été converti en item divers.` });

                this.CommandeService.ObtenirApprobationPrecommande(this.emplacementId).then(x => {

                    this.itemsCommande = x.valeur.itemsCommande;
                    this.emplacement = x.valeur.emplacement;

                    this.CompleterChargement(`Approbation pré-commande - ${this.emplacement.nom} (${this.emplacement.code})`);
                });
            } else {
                res.messages.forEach(x => {
                    this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                });
            }
        });
    }

    surMenuContextuelItem(event, itemCommande: ItemCommandeEntite) {
        this.selectionItemCommande = itemCommande;

        this.menuContextuelEntrees = [
            {
                label: 'Convertir en item divers', 
                icon: 'pi pi-fw pi-refresh',  
                visible: this.estAutorise('approbation_precommande_modifier_item') && itemCommande.item.numero !== 'DIVERS',
                command: () => this.convertToItemDivers(itemCommande)
            },
            {
                label: 'Remplacer',
                icon: 'pi pi-fw pi-search',
                visible: this.estAutorise('approbation_precommande_modifier_item'),
                command: () => this.rechercher(itemCommande)
            },
            {
                label: 'Changer la quantité',
                icon: 'pi pi-fw pi-sort',
                visible: this.estAutorise('approbation_precommande_modifier_item'),
                command: () => this.modifierQuantite(itemCommande)
            },
            { separator: true, visible: this.estAutorise('approbation_precommande_approuver') },
            {
                label: 'Assigner',
                icon: 'pi pi-fw pi-forward',
                visible: this.estAutorise('approbation_precommande_assigner') && itemCommande.statut.statutId == EStatuts.EnApprobation,
                command: x => this.assigner(itemCommande.itemCommandeId, itemCommande.quantite, itemCommande.item.description)
            },
            {
                label: 'Réassigner',
                icon: 'pi pi-fw pi-forward',
                visible: this.estAutorise('approbation_precommande_assigner') && itemCommande.statut.statutId == EStatuts.EnTraitement,
                command: x => this.assigner(itemCommande.itemCommandeId, itemCommande.quantite, itemCommande.item.description)
            },
            {
                label: 'Approuver',
                icon: 'pi pi-fw pi-forward',
                visible: this.estAutorise('approbation_precommande_approuver'),
                disabled: itemCommande.statut.statutId != EStatuts.EnTraitement,
                command: x => this.changerStatut(itemCommande.itemCommandeId, itemCommande.quantite, 27, itemCommande.item.description, itemCommande.departement?.departementId)
            },
        ];


        this.menuContextuelItemCommande.toggle(event);
    }

    modifierQuantite(itemCommande: ItemCommandeEntite) {
        const ref = this.dialogService.open(SaisieQuantiteComponent, {
            data: {
                quantite: itemCommande.quantiteDemande,
                min: 1
            },
            header: 'Saisir la quantité',
            width: '50%'
        }).onClose.subscribe((res: number) => {
            if (res) {
                this.CommandeService.ModifierqteItemCommande({
                    employe: this.profilService.ProfilCourant(),
                    emplacement: this.emplacement,
                    itemsCommande: [{
                        itemCommandeId: itemCommande.itemCommandeId,
                        quantite: res
                    }]
                }).then(res => {
                    if (res.estUnSucces) {
            
                        res.valeur.itemCommandeModifies.forEach(x => {
                            var itemCommandeAModifier = this.itemsCommande.find(ic => ic.itemCommandeId == x.itemCommandeId);
                            itemCommandeAModifier.quantiteDemande = x.quantiteDemande;
                            itemCommandeAModifier.quantite = x.quantite;
                        });
        
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: `Quantité modifiée` });
        
                        this.initialiserMenu();
        
                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                }).catch(() => {
                    this.routerService.navigateByUrl('error');
                });
            }

        });
    }

    rechercher(itemCommande: ItemCommandeEntite) {
        const ref = this.dialogService.open(SelectionItemComponent, {
            data: {
                type: ETypeSelectionItem.Recherche,
                description: itemCommande.item.description
            },
            header: 'Sélection d\'un item',
            width: '95%'
        }).onClose.subscribe((res: ResultatSelectionItem) => {

            if (res) {

                this.CommandeService.RemplacerItemCommande({
                    itemCommandeId: itemCommande.itemCommandeId,
                    itemId: res.item.itemId
                }).then(resRemplacement => {
                    if (resRemplacement.estUnSucces) {

                        var itemCommandeAModifier = this.itemsCommande.find(ic => ic.itemCommandeId == itemCommande.itemCommandeId);
                        itemCommandeAModifier.item = res.item;

                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: `L'item a été remplacé.` });

                    } else {
                        resRemplacement.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                });
            }


        });
    }

    changerStatut(itemCommandeId: number, quantite: number, statutId: number, description: string, departementId: number) {

        this.confirmerChangerStatut([{
            itemCommandeId: itemCommandeId,
            quantite: quantite,
            statutId: statutId,
            description: description,
            departementId: departementId,
            indPrecommande: false
        }]);

        /* const ref = this.dialogService.open(SelectionDepartementComponent, {
            header: 'Sélectionner un département',
            width: '50%'
        }).onClose.subscribe((res: DepartementEntite) => {
            if (res) {
                this.confirmerChangerStatut([{
                    itemCommandeId: itemCommandeId,
                    quantite: quantite,
                    statutId: statutId,
                    description: description,
                    departementId: res?.departementId,
                    indPrecommande: false
                }]);
            }
        }); */
    }

    assigner(itemCommandeId: number, quantite: number, description: string) {

        const ref = this.dialogService.open(SelectionDepartementComponent, {
            header: 'Sélectionner un département',
            width: '50%'
        }).onClose.subscribe((res: DepartementEntite) => {
            if (res) {
                this.confirmerAssignation([{
                    itemCommandeId: itemCommandeId,
                    quantite: quantite,
                    statutId: EStatuts.EnTraitement,
                    description: description,
                    departementId: res?.departementId,
                    indPrecommande: true
                }]);
            }
        });
    }

    assignerLot()
    {
        if (this.selectionMultipleItemCommande.length > 0) {
            var infos: InfoChangementStatut[] = [];

            const ref = this.dialogService.open(SelectionDepartementComponent, {
                header: 'Sélectionner un département',
                width: '50%'
            }).onClose.subscribe((res: DepartementEntite) => {
                if (res) {
                    this.selectionMultipleItemCommande.forEach(ic => {
                        infos.push({
                            itemCommandeId: ic.itemCommandeId,
                            quantite: ic.quantite,
                            statutId: EStatuts.EnTraitement,
                            description: ic.item.description,
                            departementId: res?.departementId,
                            expediteurId: this.profilService.ProfilCourant().employeId,
                            indPrecommande: true
                        });
                    });

                    this.confirmerAssignation(infos);
                }
            });
        }
    }

    confirmerAssignation(infos: InfoChangementStatut[]) {

        var requete: RequeteModifierItemCommande = {
            employe: this.profilService.ProfilCourant(),
            emplacement: this.emplacement,
            itemsCommande: []
        };

        infos.forEach(x => {
            requete.itemsCommande.push({
                itemCommandeId: x.itemCommandeId,
                quantite: x.quantite,
                statutId: x.statutId,
                departementId: x.departementId, 
                indPrecommande: x.indPrecommande,
                expediteurId: x.expediteurId
            });
        });

        this.CommandeService.ModifierItemCommande(requete).then(res => {
            if (res.estUnSucces) {

                infos.forEach(i => {

                    var ic = this.itemsCommande.find(x => x.itemCommandeId == i.itemCommandeId);
                    if (ic) {
                        ic.statut = this.sdb.Statut(i.statutId);
                        ic.departement = this.sdb.Departement(i.departementId);
                    }
                });

                this.messageService.add({ severity: 'success', summary: 'Succès', detail: `Assignation complétée.` });

                this.initialiserMenu();
            } else {
                res.messages.forEach(x => {
                    this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                });
            }
        });
    }

    changerStatutLot(statutId: number, indPrecommande: boolean) {
        if (this.selectionMultipleItemCommande.length > 0) {
            var infos: InfoChangementStatut[] = [];

            this.selectionMultipleItemCommande.forEach(ic => {
                infos.push({
                    itemCommandeId: ic.itemCommandeId,
                    quantite: ic.quantite,
                    statutId: statutId,
                    description: ic.item.description,
                    departementId: ic.departement?.departementId,
                    indPrecommande: indPrecommande
                });
            });

            this.confirmerChangerStatut(infos);

            /* const ref = this.dialogService.open(SelectionDepartementComponent, {
                header: 'Sélectionner un département',
                width: '50%'
            }).onClose.subscribe((res: DepartementEntite) => {
                if (res) {
                    this.selectionMultipleItemCommande.forEach(ic => {
                        infos.push({
                            itemCommandeId: ic.itemCommandeId,
                            quantite: ic.quantite,
                            statutId: statutId,
                            description: ic.item.description,
                            departementId: res?.departementId,
                            indPrecommande: false
                        });
                    });

                    this.confirmerChangerStatut(infos);
                }
            }); */

        }
    }

    confirmerChangerStatut(infos: InfoChangementStatut[]) {

        var requete: RequeteModifierItemCommande = {
            employe: this.profilService.ProfilCourant(),
            emplacement: this.emplacement,
            itemsCommande: []
        };

        infos.forEach(x => {
            requete.itemsCommande.push({
                itemCommandeId: x.itemCommandeId,
                quantite: x.quantite,
                statutId: x.statutId,
                departementId: x.departementId,
                indPrecommande: x.indPrecommande,
                expediteurId: this.profilService.ProfilCourant().employeId
            });
        });

        this.CommandeService.ApprouverItemPreCommande(requete).then(res => {
            if (res.estUnSucces) {

                infos.forEach(i => {
                    this.itemsCommande = this.itemsCommande.filter(x => x.itemCommandeId != i.itemCommandeId);
                });

                this.messageService.add({ severity: 'success', summary: 'Succès', detail: `Envoi en commande complété.` });

                this.initialiserMenu();
            } else {
                res.messages.forEach(x => {
                    this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                });
            }
        });

    }

    /* approuver(itemCommandeId: number, quantite: number, statutId: number, description: string) {

        this.confirmationService.confirm({
            message: 'Voulez-vous vraiment envoyer l\'item "' + description + ' en commande" ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.CommandeService.ModifierItemCommande([{
                    itemCommandeId: itemCommandeId,
                    quantite: quantite,
                    statutId: statutId,
                    indPrecommande: false
                }]).then(res => {
                    if (res.estUnSucces) {
        
                        this.itemsCommande = this.itemsCommande.filter(x => x.itemCommandeId != itemCommandeId);
        
                        this.messageService.add({ severity: 'success', summary: 'Succès', detail: `Item envoyé en commande` });
                    } else {
                        res.messages.forEach(x => {
                            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: x.description });
                        });
                    }
                });

            }
        });

        
    } */
}
