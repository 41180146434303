<p-toast key="reparation"></p-toast>

<p-blockUI [blocked]="blocked">
    <div class="fluid formgrid grid">
        <div class="flex flex-column gap-2 col-12 text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-blockUI>


<p-card *ngIf="!EstCharge()">
    <div class="fluid formgrid grid">
        <div class="flex flex-column gap-2 col-12 text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-card>

<div #contenu *ngIf="EstCharge()">

    <div class="fluid formgrid grid">
        <div class="flex flex-column gap-2 col-12 md:col-6 lg:col-3">
            <label for="lastname">Date d'envoi<span class="requis">*</span></label>
            <p-calendar appendTo="body" showIcon=true [(ngModel)]="reparation.dateEnvoi" dateFormat="yy-mm-dd" [yearNavigator]="true" [monthNavigator]="true" yearRange="2000:2040" ></p-calendar>
            <small class="p-error" *ngIf="submitted && !reparation.dateEnvoi">La date d'envoi est obligatoire.</small>
        </div>
        <div class="flex flex-column gap-2 col-12 md:col-6 lg:col-3">
            <label for="lastname">Date de retour prévue<span *ngIf="alerteActive" class="requis">*</span></label>
            <p-calendar appendTo="body" showIcon=true [(ngModel)]="reparation.dateRetourPrevue" dateFormat="yy-mm-dd" [yearNavigator]="true" [monthNavigator]="true" yearRange="2000:2040" ></p-calendar>
            <small class="p-error" *ngIf="submitted && alerteActive && !reparation.dateRetourPrevue">La date de retour prévue est obligatoire.</small>
        </div>

        <div class="flex flex-column gap-2 col-12 p-lg-6">
            <label for="lastname">Fournisseur<span class="requis">*</span></label>
            <p-dropdown [options]="fournisseurs" [(ngModel)]="reparation.fournisseur" optionLabel="nom" appendTo="body"
                [showClear]="true" filter="true" filterBy="numero,nom" placeholder="Sélectionner un fournisseur...">
                <ng-template pTemplate="selectedItem">
                    <div *ngIf="reparation.fournisseur">
                        <div>{{reparation.fournisseur.nom}} ({{reparation.fournisseur.numero}})</div>
                    </div>
                </ng-template>
                <ng-template let-fournisseur pTemplate="item">
                    <div>
                        <div>{{fournisseur.nom}} ({{fournisseur.numero}})</div>
                    </div>
                </ng-template>
            </p-dropdown>
            <small class="p-error" *ngIf="submitted && !reparation.fournisseur">Le fournisseur est obligatoire.</small>
        </div>
        <div class="flex flex-column gap-2 col-12 p-lg-6">
            <label for="lastname">Emplacement de retour </label>
                <p-dropdown [options]="emplacements" [(ngModel)]="reparation.emplacement" optionLabel="nom" [showClear]="true" appendTo="body"
                    filter="true" filterBy="nom"
                    placeholder="Sélectionner un emplacement...">
                    <ng-template pTemplate="selectedItem">
                        <div *ngIf="reparation.emplacement">
                            <div>{{reparation.emplacement.nom}} ({{reparation.emplacement.code}})</div>
                        </div>
                    </ng-template>
                    <ng-template let-emplacement pTemplate="item">
                        <div>
                            <div>{{emplacement.nom}} ({{emplacement.code}})</div>
                        </div>
                    </ng-template>
                </p-dropdown>
        </div>
        <div class="flex flex-column gap-2 col-12">
            <label for="indRemplace">Remplacé</label><br>
            <p-inputSwitch [(ngModel)]="reparation.indRemplace"></p-inputSwitch>
        </div>
        <div class="flex flex-column gap-2 col-12">
            <label for="lastname">Alerte</label><br>
            <p-inputSwitch [(ngModel)]="alerteActive"></p-inputSwitch>
        </div>
        <div class="flex flex-column gap-2 col-12 md:col-6 lg:col-3" *ngIf="alerteActive">
            <label for="lastname">Nombre de jours avant la date de retour prévue</label>
            <p-inputNumber [(ngModel)]="reparation.nbJoursAlerte" suffix=" jour(s)" [min]="0" [max]="100" [showButtons]="true"
                buttonLayout="horizontal" spinnerMode="horizontal" decrementButtonClass="p-button-danger"
                incrementButtonClass="p-button-success" incrementButtonIcon="pi pi-plus"
                decrementButtonIcon="pi pi-minus"></p-inputNumber>
                <small class="p-error" *ngIf="submitted && alerteActive && !reparation.nbJoursAlerte">La date de retour prévue est obligatoire.</small>
        </div>
    </div>

    <div class="text-right mt-3">

        <button pButton pRipple label="Sauvegarder" icon="pi pi-save" class="p-button-primary"
        (click)="sauvegarder()"></button>
        <button pButton pRipple label="Annuler" icon="pi pi-ban" class="p-button-text" (click)="annuler()"></button>
        

    </div>

</div>