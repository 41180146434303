import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from "@angular/core"; import { Location } from "@angular/common";
import { Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { ReponseBase } from './reponse-base';
import { ItemService } from './item-service';
import { EntretienPreventifEntite } from '../entites/mecanique/entretien-preventif';
import { ESeverites } from '../entites/enums/severites';
import { HistoriqueEntretienPreventifEntite } from '../entites/mecanique/historique-entretien-preventif';
import { DonneesBaseService } from './donnees-base-service';
import { EStatuts } from '../entites/enums/statuts';
import { BonTravailEntite } from '../entites/mecanique/bon-travail';
import { RequeteAjouterEtapesBonTravail } from './requetes/requete-ajouter-etapes-bon-travail';
import { ReponseAjouterEtapesBonTravail } from './reponses/reponse-ajouter-etapes-bon-travail';
import { PieceUniteEntite } from '../entites/piece-unite';
import { PieceBonTravailEntite } from '../entites/mecanique/piece-bon-travail';

@Injectable({ providedIn: 'root' })
export class PieceService {

  private obtenirUrl = environment.apiBaseUrl + 'piece';
  private obtenirParUniteUrl = environment.apiBaseUrl + 'piece/unite/{id}';
  private obtenirParPieceUrl = environment.apiBaseUrl + 'piece/piece/{id}';
  private detailUrl = environment.apiBaseUrl + 'piece/{id}';
  private creerUrl = environment.apiBaseUrl + 'piece';
  private creerAssociationEquipementCtrl = environment.apiBaseUrl + 'piece/ajouter/{pieceId}/{codeEquipement}/{categorieId}';
  private modifierUrl = environment.apiBaseUrl + 'piece/modifier';
  private supprimerUrl = environment.apiBaseUrl + 'piece/{id}';
  private historiquePoseCompletUrl = environment.apiBaseUrl + 'piece/{id}/historiquepose';
  private historiquePoseUniteUrl = environment.apiBaseUrl + 'piece/{id}/historiquepose/unite/{uniteId}';
  

  public httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(
    private sdb: DonneesBaseService,
    public itemService: ItemService,
    private http: HttpClient,

    public router: Router) { }

    public Obtenir(): Promise<ReponseBase<PieceUniteEntite[]>> {
      var p = this.http.get<ReponseBase<PieceUniteEntite[]>>(this.obtenirUrl)
        .toPromise();
        p.catch(() => this.router.navigateByUrl('error'));
        return p;
    }

    public ObtenirParUnite(id: number): Promise<ReponseBase<PieceUniteEntite[]>> {
      var p = this.http.get<ReponseBase<PieceUniteEntite[]>>(this.obtenirParUniteUrl.replace('{id}', id.toString()))
        .toPromise();
        p.catch(() => this.router.navigateByUrl('error'));
        return p;
    }

    public ObtenirParPiece(id: number): Promise<ReponseBase<PieceUniteEntite[]>> {
      var p = this.http.get<ReponseBase<PieceUniteEntite[]>>(this.obtenirParPieceUrl.replace('{id}', id.toString()))
        .toPromise();
        p.catch(() => this.router.navigateByUrl('error'));
        return p;
    }

    public ObtenirDetail(id: number): Promise<ReponseBase<PieceUniteEntite>> {
      var p = this.http.get<ReponseBase<PieceUniteEntite>>(this.detailUrl.replace('{id}', id.toString()))
        .toPromise();
        p.catch(() => this.router.navigateByUrl('error'));
        return p;
    }

    public Creer(entretien: PieceUniteEntite[]): Promise<ReponseBase<PieceUniteEntite[]>> {
      var p = this.http.post<ReponseBase<PieceUniteEntite[]>>(this.creerUrl, JSON.stringify(entretien), this.httpOptions)
        .toPromise();
        p.catch(() => this.router.navigateByUrl('error'));
        return p;
    }

    public AssocierEquipementCtrl(pieceId: number, codeEquipement: string, categorieId?: number): Promise<ReponseBase<number>> {
      if (!categorieId) {
        categorieId = 0;
      }
      var p = this.http.post<ReponseBase<number>>(this.creerAssociationEquipementCtrl.replace('{pieceId}', pieceId.toString()).replace('{codeEquipement}', codeEquipement).replace('{categorieId}', categorieId?.toString()), null, this.httpOptions)
        .toPromise();
        p.catch(() => this.router.navigateByUrl('error'));
        return p;
    }

    public Modifier(entretien: PieceUniteEntite): Promise<ReponseBase<PieceUniteEntite>> {
      var p = this.http.post<ReponseBase<PieceUniteEntite>>(this.modifierUrl, JSON.stringify(entretien), this.httpOptions)
        .toPromise();
        p.catch(() => this.router.navigateByUrl('error'));
        return p;
    }
  
    public Supprimer(id: number): Promise<ReponseBase<number>> {
      var p = this.http.delete<ReponseBase<number>>(this.supprimerUrl.replace('{id}', id.toString()))
        .toPromise();
        p.catch(() => this.router.navigateByUrl('error'));
        return p;
    }

    public ObtenirHistoriquePoseComplet(id: number): Promise<ReponseBase<PieceBonTravailEntite[]>> {
      var p = this.http.get<ReponseBase<PieceBonTravailEntite[]>>(this.historiquePoseCompletUrl.replace('{id}', id.toString()))
        .toPromise();
        p.catch(() => this.router.navigateByUrl('error'));
        return p;
    }

    public ObtenirHistoriquePoseUnite(pieceId: number, uniteId: number): Promise<ReponseBase<PieceBonTravailEntite[]>> {
      var p = this.http.get<ReponseBase<PieceBonTravailEntite[]>>(this.historiquePoseUniteUrl.replace('{id}', pieceId.toString()).replace('{uniteId}', uniteId.toString()))
        .toPromise();
        p.catch(() => this.router.navigateByUrl('error'));
        return p;
    }


  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);

      this.log(`${operation} failed: ${error.message}`);

      this.router.navigateByUrl('erreurtechnique');

      return of(result as T);
    };
  }

  private log(message: string) {
      console.log(message);
  }
}
