<p-toast key="debutLocation"></p-toast>


<p-card *ngIf="!EstCharge()">
    <div class="fluid formgrid grid">
        <div class="flex flex-column gap-2 col-12 text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-card>

<div #contenu *ngIf="EstCharge()">

    <div class="fluid formgrid grid">
        <div class="flex flex-column gap-2 col-12 md:col-6 lg:col-3">
            <label for="lastname">Date de début de location<span class="requis">*</span></label>
            <p-calendar appendTo="body" showIcon=true [(ngModel)]="location.dateDebut" dateFormat="yy-mm-dd" [yearNavigator]="true" [monthNavigator]="true" yearRange="2000:2040" ></p-calendar>
            <small class="p-error" *ngIf="submitted && !location.dateDebut">La date de début de location est obligatoire.</small>
        </div>      
        <!-- <div class="flex flex-column gap-2 col-3">
            <label for="description">Cout de location</label>
            <p-inputNumber #champPrix [(ngModel)]="location.coutLocation" mode="currency" currency="USD" locale="en-US"></p-inputNumber>
            <small class="p-error" *ngIf="submitted && !location.coutLocation">Le coût de location est obligatoire.</small>
        </div>
        <div class="flex flex-column gap-2 col-3">
            <label for="description">Valeur de l'équipement</label>
            <p-inputNumber #champPrix [(ngModel)]="location.valeurEquipement" mode="currency" currency="USD" locale="en-US"></p-inputNumber>
            <small class="p-error" *ngIf="submitted && !location.valeurEquipement">La valeur de l'équipement est obligatoire.</small>
        </div> -->
        <div class="flex flex-column gap-2 col-3">
            <label for="description">Heures au début de la location<span class="requis">*</span></label>
            <p-inputNumber [min]="0" [(ngModel)]="location.heuresDebut"></p-inputNumber>
            <small class="p-error" *ngIf="submitted && !location.heuresDebut && location.heuresDebut != 0">Les heures au début de la location sont obligatoires.</small>
        </div>
    </div>

    

    <div class="text-right mt-3">

        <button pButton pRipple label="Sauvegarder" icon="pi pi-save" class="p-button-primary"
        (click)="sauvegarder()"></button>
        <button pButton pRipple label="Annuler" icon="pi pi-ban" class="p-button-text" (click)="annuler()"></button>
        

    </div>

</div>