import { Component, ViewChild } from "@angular/core"; import { Location } from "@angular/common";
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { EmployeEntite } from '../entites/employe';
import { DonneesBaseService } from '../services/donnees-base-service';
import { Fournisseur } from '../entites/fournisseur';
import { BaseComponent } from '../base/base-component';
import { ProfilService } from '../services/profil-service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppMainComponent } from '../app.main.component';
import { FournisseurService } from '../services/fournisseur-service';
import { BoiteOutilEntite } from '../entites/boite-outil';
import { BoiteOutilService } from '../services/boite-outil-service';

export interface ParametresSelectionBoiteOutils {

}

@Component({
    templateUrl: './selection-boite-outils.component.html',
})
export class SelectionBoiteOutilsComponent extends BaseComponent {

    donnees: ParametresSelectionBoiteOutils;

    boites: BoiteOutilEntite[];
    selectionBoite: BoiteOutilEntite;

    submitted: boolean = false;

    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute,
        routerService: Router,
        appMain: AppMainComponent, location: Location, 
        private boiteOutilService: BoiteOutilService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig) {
        super(sdb, profilService, route, routerService, appMain);
    }


    ngOnInit() {

        this.donnees = this.config.data;

        this.boiteOutilService.ObtenirListe().then(res => {
            this.boites = res.valeur;
            this.CompleterChargement();
        });
    }

    confirmer() {

        this.submitted = true;

        if(!this.selectionBoite) {
            return;
        }
        this.ref.close(this.selectionBoite);
    }

    annuler() {
        this.ref.close(null);
    }
}