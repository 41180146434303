import { Component, OnInit, ViewChild, Input } from "@angular/core"; import { Location } from "@angular/common";
import { Table } from 'primeng/table';
import { BreadcrumbService } from '../../app.breadcrumb.service';

import { RequisitionService } from '../../services/requisition-service';
import { RequisitionMin } from '../../entites/requisition-min';
import { MenuItem } from 'primeng/api/menuitem';
import { MessageService, LazyLoadEvent, ConfirmationService } from 'primeng/api';
import {ToastModule} from 'primeng/toast';

import {DialogService} from 'primeng/dynamicdialog';
import { EnvoiRequisitionComponent } from '../../controles/envoi-requisition.component';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseComponent } from 'src/app/base/base-component';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { ProfilService } from 'src/app/services/profil-service';
import { ContextMenu } from 'primeng/contextmenu/public_api';
import { EStatuts } from 'src/app/entites/enums/statuts';
import { AppMainComponent } from 'src/app/app.main.component';
import { SoumissionEntite } from 'src/app/entites/soumission';
import { SoumissionService } from 'src/app/services/soumission-service';
import { SerieSoumission } from 'src/app/entites/serie-soumission';

@Component({
    templateUrl: './creer-serie.component.html',
    styles: [`
        @media screen and (max-width: 960px) {
            :host ::ng-deep .p-datatable.p-datatable-customers.rowexpand-table .p-datatable-tbody > tr > td:nth-child(6) {
                display: flex;
            }
        }

    `],
    providers: [MessageService, ConfirmationService, DialogService]
})
export class CreerSerieSoumissionsComponent extends BaseComponent implements OnInit {
    
    series: SerieSoumission[];
    
    constructor(
        sdb: DonneesBaseService,
        profilService: ProfilService,
        route: ActivatedRoute, 
		appMain: AppMainComponent, location: Location, 
        private breadcrumbService: BreadcrumbService,
        private soumissionService: SoumissionService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        public dialogService: DialogService,
        public routerService: Router) {

            super(sdb, profilService, route, routerService, appMain);
            
        this.breadcrumbService.setItems([
            { label: 'Créer une nouvelle série de soumissions' }
        ]);
    }

    ngOnInit() {

        this.verifierAccesPage('soumission_action_nouvelle_serie');
        
           this.soumissionService.ObtenirSeries().then(x => {
               this.series = x.valeur;

               this.CompleterChargement();
           });
    }


    creerSerie(serie: SerieSoumission){

        serie.dateDebut = new Date(serie.dateDebut);
        serie.dateFin = new Date(serie.dateFin);

        console.log(serie);
        this.routerService.navigateByUrl(`soumission/serie/${serie.dateDebut.toISOString()}/${serie.dateFin.toISOString()}`);
    }
}
