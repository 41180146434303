<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<p-card *ngIf="!EstCharge()">
  <div class="fluid formgrid grid">
    <div class="flex flex-column gap-2 my-2 col-12 text-center">
      <p-progressSpinner></p-progressSpinner>
    </div>
  </div>
</p-card>

<div *ngIf="EstCharge()" class="grid">
  <div class="col-12">
    <div class="card">
      <h5>{{actionCorrective.numero}}
        <!-- <span [class]="'statut ' + actionCorrective.statut.nom.toLowerCase()">
          {{actionCorrective.statut.nom}}
        </span> -->
      </h5>

      <p-menubar [model]="menu" [autoDisplay]="false">
        <p-button *ngIf="modeConsultation()" (click)="Modifier()" icon="pi pi-pencil"
          styleClass="p-button-primary"></p-button>
        <p-button *ngIf="modeModification()" (onClick)="enregistrerModifications()" label icon="pi pi-check"
          styleClass="p-button-success mr-2">
        </p-button>
        <p-button *ngIf="modeModification()" (onClick)="Consulter()" icon="pi pi-times"
          styleClass="p-button-secondary"></p-button>
      </p-menubar>
    </div>
  </div>
</div>

<div *ngIf="EstCharge()" class="grid">
  <div class="col-12">
    <div class="card">
      <p-tabView #tabView [(activeIndex)]="tabIndex" (onChange)="surChangementOnglet($event)">
        <p-tabPanel header="Informations générales" leftIcon="pi pi-info-circle">
          <div class="fluid formgrid grid mb-4">
            <div class="flex flex-column gap-2 col-12 md:col-6">
              <label for="nonConformite">Non-conformité<span class="requis">*</span></label>
              <p-dropdown [options]="nonConformites" [(ngModel)]="actionCorrective.nonConformite" optionLabel="numero"
                appendTo="body" [showClear]="true" [disabled]="modeConsultation()" filter="true" filterBy="numero,titre"
                [disabled]="nonConformiteInputDisabled" placeholder="Erreur">
                <ng-template pTemplate="selectedItem">
                  <div *ngIf="actionCorrective.nonConformite">
                    <div>{{actionCorrective.nonConformite.numero}} - {{actionCorrective.nonConformite.titre}}</div>
                  </div>
                </ng-template>
                <ng-template let-nonConformite pTemplate="item">
                  <div>
                    <div>{{nonConformite.numero}} - {{nonConformite.titre}}
                      <span [class]="'statut ' + nonConformite.statut.nom.toLowerCase()">
                        {{nonConformite.statut.nom}}
                      </span>
                    </div>
                  </div>
                </ng-template>
              </p-dropdown>
              <small class="p-error" *ngIf="submitted && !actionCorrective.nonConformite">La non-conformité est
                obligatoire.</small>
            </div>
          </div>

          <p-divider>
            <span class="font-bold">Description<span class="requis">*</span></span>
          </p-divider>
          <div class="fluid formgrid grid" style="padding: 1.25rem">
            <p-editor [(ngModel)]="actionCorrective.demande" [readonly]="modeConsultation()" [formats]="sdb.formats" [modules]="sdb.modules" [placeholder]="'Décrivez l\'action corrective...'" (onSelectionChange)="surModification($event)" [style]="{ height: '320px' }" />
            <small class="p-error" *ngIf="submitted && !actionCorrective.demande">La description est
              obligatoire.</small>
          </div>

          <div class="fluid formgrid grid">
            <div class="flex flex-column gap-2 col-12 md:col-6 lg:col-3">
              <label for="date">Date cible<span class="requis">*</span></label>
              <p-calendar *ngIf="modeModification()" [(ngModel)]="actionCorrective.dateCible" dateFormat="yy-mm-dd"
                [showIcon]="true"></p-calendar>
              <input *ngIf="modeConsultation()" id="dateCible" type="text" pInputText
                value="{{actionCorrective.dateCible | date:'yyyy-MM-dd'}}" [readOnly]="true">
              <small class="p-error" *ngIf="submitted && !actionCorrective.dateCible">La date cible est
                obligatoire.</small>
            </div>
          </div>
        </p-tabPanel>


        <p-tabPanel header="Suivi" leftIcon="pi pi-comments">
          <ng-template pTemplate="content">
            <app-fil-conversation [conversationId]="actionCorrective.conversationId"></app-fil-conversation>
          </ng-template>
        </p-tabPanel>


        <p-tabPanel header="Documents" leftIcon="pi pi-file">
          <ng-template pTemplate="content">
            <p-messages severity="info">
              <ng-template pTemplate>
                <i class="pi pi-info-circle"></i>
                <div class="ml-2">Documents liés à la non-conformité associée</div>
              </ng-template>
            </p-messages>
            <app-document [typeLienDocument]="ETypeLienDocument.NonConformite"
              [idLien]="actionCorrective.nonConformite.idNonConformite" [selectedTreeNode]="typeDocumentSelectionne"
              [numero]="actionCorrective.nonConformite.numero"></app-document>
          </ng-template>
        </p-tabPanel>
      </p-tabView>
    </div>
  </div>
</div>