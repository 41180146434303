<p-toast key="detailSousCategorie"></p-toast>

<p-card *ngIf="!EstCharge()">
    <div class="fluid formgrid grid">
        <div class="flex flex-column gap-2 col-12 text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-card>

<div class="card" *ngIf="EstCharge()">
  
    <div class="fluid formgrid grid">
        <div class="flex flex-column gap-2 col-6">
            <label for="numero">Nom</label>
            <input #champNom id="nom" type="text" pInputText [(ngModel)]="sousCategorie.nom" autocomplete="off">
            <small class="p-error" *ngIf="submitted && !sousCategorie.nom">Le nom est obligatoire.</small>
        </div>
        <div class="flex flex-column gap-2 col-6">
            <label for="numero">Préfixe</label>
            <input id="prefixe" type="text" pInputText [(ngModel)]="sousCategorie.prefixe" autocomplete="off">
        </div>
    </div>
    <div class="grid">
        <div class="col-12 text-right ">
            <button pButton pRipple label="Confirmer" icon="pi pi-save" class="p-button-primary"
                (click)="confirmer()"></button>
            <button pButton pRipple label="Annuler" icon="pi pi-ban" class="p-button-text" (click)="annuler()"></button>
        </div>
    </div>
</div>