<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-confirmPopup key="popup"></p-confirmPopup>
<p-menu #menuContextuelItemCommande popup="popup" [model]="menuContextuelEntrees"></p-menu>

<p-card *ngIf="!EstCharge()">
	<div class="fluid formgrid grid">
		<div class="flex flex-column gap-2 my-2 col-12 text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-card>

<div *ngIf="EstCharge()" class="grid">
	<div class="col-12">
		<div class="card">
			<h5>Pré-commande - {{emplacement.nom}} ({{emplacement.code}})
				<app-bouton-favori [titrePage]="titrePage"></app-bouton-favori>
			</h5>

			<p-menubar autoDisplay="false" [model]="menu"></p-menubar>

		</div>
	</div>
</div>


<div *ngIf="EstCharge()" class="grid">
	<div class="col-12">
		<div class="card">

			<p-table #dt [value]="itemsCommande" [columns]="selectedColumns" dataKey="itemCommandeId"
				tableStyleClass="tableNormale" [(selection)]="selectionMultipleItemCommande"
				(onRowSelect)="surChangementSelection()" (onRowUnselect)="surChangementSelection()"
				(onHeaderCheckboxToggle)="surChangementSelection()" autoLayout="true"
				styleClass="p-datatable-sm p-datatable-striped" [rowHover]="true" [filterDelay]="0"
				[globalFilterFields]="['item.numero','item.description','demandeur']">
				<ng-template pTemplate="caption">
					<div class="flex align-content-center justify-content-between">
						<div>
							<span class="p-input-icon-left">
								<i class="pi pi-search"></i>
								<input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
									placeholder="Recherche..." />
							</span>
								<button *ngIf="estAutorise('precommande_modifier')" pButton pRipple icon="pi pi-plus" class="p-button-success mb-2 ml-4 boutonGrille"
								(click)="surAjoutItem()"></button>

						</div>
						<h5 class="titreTableau m-0">Items</h5>
						<div>
							<!-- <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
										selectedItemsLabel="{0} colonnes sélectionnés" [style]="{minWidth: '200px'}"
										placeholder="Colonnes..."></p-multiSelect> -->
						</div>
					</div>
				</ng-template>
				<ng-template pTemplate="header" let-columns>
					<tr>

						<th style="min-width: 3rem">
							<p-tableHeaderCheckbox></p-tableHeaderCheckbox>
						</th>
						<th pSortableColumn="quantite">
							<div class="flex justify-content-between align-items-center">
								Qte
								<p-sortIcon field="quantiteDemande"></p-sortIcon>
								<p-columnFilter type="text" field="quantiteDemande" display="menu" class="ml-auto">
								</p-columnFilter>
							</div>
						</th>

						<th pSortableColumn="statut.nom">
							<div class="flex justify-content-between align-items-center">
								Statut
								<p-sortIcon field="statut.nom"></p-sortIcon>
								<p-columnFilter field="statut" matchMode="in" display="menu" [showMatchModes]="false"
									[showOperator]="false" [showAddButton]="false">
									<ng-template pTemplate="filter" let-value let-filter="filterCallback">
										<p-multiSelect [ngModel]="value" [options]="statuts" placeholder="Any"
											(onChange)="filter($event.value)" optionLabel="name">
											<ng-template let-option pTemplate="item">
												<div class="multiselect-representative-option">

													<span class="ml-1">{{option.nom}}</span>
												</div>
											</ng-template>
										</p-multiSelect>
									</ng-template>
								</p-columnFilter>
							</div>
						</th>
						<th pSortableColumn="item.numero">
							<div class="flex justify-content-between align-items-center">
								Numéro
								<p-sortIcon field="item.numero"></p-sortIcon>
								<p-columnFilter type="text" field="item.numero" display="menu" class="ml-auto">
								</p-columnFilter>
							</div>
						</th>
						<th pSortableColumn="item.description">
							<div class="flex justify-content-between align-items-center">
								Description
								<p-sortIcon field="item.description"></p-sortIcon>
								<p-columnFilter type="text" field="item.description" display="menu" class="ml-auto">
								</p-columnFilter>
							</div>
						</th>
						<th pSortableColumn="demandeur.prenom">
							<div class="flex justify-content-between align-items-center">
								Demandeur
								<p-sortIcon field="demandeur.prenom"></p-sortIcon>
								<p-columnFilter type="text" field="demandeur.prenom" display="menu" class="ml-auto">
								</p-columnFilter>
							</div>
						</th>
						<th pSortableColumn="dateDemande">
							<div class="flex justify-content-between align-items-center">
								Date d'ajout
								<p-sortIcon field="dateDemande"></p-sortIcon>
								<p-columnFilter type="text" field="dateDemande" display="menu" class="ml-auto">
								</p-columnFilter>
							</div>
						</th>
						<th pSortableColumn="dateRequis">
							<div class="flex justify-content-between align-items-center">
								Date réquis
								<p-sortIcon field="dateRequis"></p-sortIcon>
								<p-columnFilter type="text" field="dateRequis" display="menu" class="ml-auto">
								</p-columnFilter>
							</div>
						</th>
						<th pSortableColumn="codeProjet">
							<div class="flex justify-content-between align-items-center">
								Code projet
								<p-sortIcon field="codeProjet"></p-sortIcon>
								<p-columnFilter type="text" field="codeProjet" display="menu" class="ml-auto">
								</p-columnFilter>
							</div>
						</th>
						<th pSortableColumn="codeActivite">
							<div class="flex justify-content-between align-items-center">
								Code activité
								<p-sortIcon field="codeActivite"></p-sortIcon>
								<p-columnFilter type="text" field="codeActivite" display="menu" class="ml-auto">
								</p-columnFilter>
							</div>
						</th>
						<th pSortableColumn="note">
							<div class="flex justify-content-between align-items-center">
								Note
								<p-sortIcon field="note"></p-sortIcon>
								<p-columnFilter type="text" field="note" display="menu" class="ml-auto">
								</p-columnFilter>
							</div>
						</th>

						<th style="min-width: 8rem; width: 8rem;"></th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-item>

					<tr [pContextMenuRow]="item">
						<td>
							<p-tableCheckbox *ngIf="item.statut.statutId == 3" [value]="item"></p-tableCheckbox>
						</td>
						<td>{{item.quantiteDemande}}</td>
						<td><span [class]="'statut ' + item.statut.nom.replace(' ','').toLowerCase()">
								{{item.statut.nom}}
							</span>
						</td>

						<td>{{item.item.numero}}</td>
						<td>{{item.item.description}}</td>
						<td>{{item.demandeur | nomEmploye}}</td>
						<td>{{item.dateDemande | date:'yyyy-MM-dd'}}</td>
						<td>{{item.dateRequis | date:'yyyy-MM-dd'}}</td>
						<td>{{item.codeProjet?.code}}</td>
                          <td>{{item.codeActivite?.code}}</td>
                          <td>{{item.note}}</td>
						<td>
							<button *ngIf="item.statut.statutId == 3" pButton pRipple icon="pi pi-trash" class="p-button-danger mr-2 boutonGrille"
								(click)="supprimerItemcommnde($event, item)"></button>
							<button *ngIf="item.statut.statutId == 3" pButton pRipple icon="pi pi-bars" class="p-button-secondary mr-2 boutonGrille"
								(click)="surMenuContextuelItem($event, item)"></button>
						</td>
					</tr>
				</ng-template>
				<ng-template pTemplate="emptymessage">
					<tr>
						<td colspan="7">Aucun item.</td>
					</tr>
				</ng-template>
			</p-table>
		</div>
	</div>


</div>

<div *ngIf="EstCharge()" class="grid">
	<div class="col-12">
		<div class="card">
			<p-menubar autoDisplay="false" [model]="menu"></p-menubar>

		</div>
	</div>
</div>