<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<p-card *ngIf="!EstCharge()">
	<div class="fluid formgrid grid">
		<div class="flex flex-column gap-2 my-2 col-12 text-center">
			<p-progressSpinner></p-progressSpinner>
		</div>
	</div>
</p-card>

<div *ngIf="EstCharge()" class="grid">
	<div class="col-12">
		<div class="card">
			<h5>{{boiteOutil.nom}}</h5>

			<p-menubar autoDisplay="false" [model]="menu">
				<p-button *ngIf="estAutorise('admin_boites_outils') && modeConsultation()" (click)="btnModifier_click()" icon="pi pi-pencil"
					styleClass="p-button-primary"></p-button>
				<p-button *ngIf="estAutorise('admin_boites_outils') && modeAjoutModification()" (onClick)="enregistrerModifications()" label
					icon="pi pi-check" styleClass="p-button-success mr-2">
				</p-button>
				<p-button *ngIf="estAutorise('admin_boites_outils') && modeAjoutModification()" (onClick)="annulerModification($event)" icon="pi pi-times"
					styleClass="p-button-secondary"></p-button>
			</p-menubar>
		</div>
	</div>


</div>


<div *ngIf="EstCharge()" class="grid">
	<div class="col-12">
		<div class="card">
					<!-- <div class="col-12 text-right">
						<p-button label="Modifier" icon="pi pi-pencil" iconPos="left"></p-button>
					</div> -->
					<div class="fluid formgrid grid">
						<div class="flex flex-column gap-2 my-2 col-12 md:col-12 lg:col-4">
							<label for="nom">Nom</label>
							<input id="nom" type="text" pInputText [readonly]="modeConsultation()"
								[(ngModel)]="boiteOutil.nom">
							<small class="p-error" *ngIf="submitted && !boiteOutil.nom">Le nom
								est
								obligatoire.</small>
						</div>
						<div class="flex flex-column gap-2 my-2 col-12 md:col-12 lg:col-8">
							<label for="description">Description</label>
							<input id="description" type="text" pInputText [readonly]="modeConsultation()"
								[(ngModel)]="boiteOutil.description">
						</div>
					</div>

					<p-menu #menuContextuelItem popup="popup" [model]="contenuMenuContextuelItem"></p-menu>
					
					<p-table #dtItems [value]="boiteOutil.itemsBoiteOutil" dataKey="itemBoiteOutilId"
							 tableStyleClass="tableNormale" autoLayout="true"
							 [rowHover]="true" [filterDelay]="0"
							[globalFilterFields]="['item.numero','quantite','item.description']">
							<ng-template pTemplate="caption">
								<div class="flex align-content-center justify-content-between">
									<div>
										<span class="p-input-icon-left">
											<i class="pi pi-search"></i>
											<input pInputText type="text"
												(input)="dtItems.filterGlobal($event.target.value, 'contains')"
												placeholder="Recherche..." />
										</span>
										<button *ngIf="estAutorise('admin_boites_outils')" pButton pRipple icon="pi pi-plus"
											class="p-button-rounded p-button-success p-button-sm mb-2 ml-4"
											(click)="surAjoutItem()"></button>

									</div>
									<h5 class="titreTableau m-0">Items</h5>
									<div>
										<p-multiSelect disabled="true" [style]="{minWidth: '200px'}" placeholder="Colonnes..."></p-multiSelect>
									</div>
								</div>
							</ng-template>
							<ng-template pTemplate="header">
								<tr>
									<th pSortableColumn="item.categorie.nom">
										<div class="flex justify-content-between align-items-center">
											Catégorie
											<p-sortIcon field="item.categorie.nom"></p-sortIcon>
											<p-columnFilter type="text" field="item.categorie.nom" display="menu"
												class="ml-auto">
											</p-columnFilter>
										</div>
									</th>
									<th pSortableColumn="item.numero">
										<div class="flex justify-content-between align-items-center">
											Numéro
											<p-sortIcon field="item.numero"></p-sortIcon>
											<p-columnFilter type="text" field="item.numero" display="menu"
												class="ml-auto">
											</p-columnFilter>
										</div>
									</th>
									<th pSortableColumn="item.description">
										<div class="flex justify-content-between align-items-center">
											Description
											<p-sortIcon field="item.description"></p-sortIcon>
											<p-columnFilter type="text" field="item.description" display="menu"
												class="ml-auto">
											</p-columnFilter>
										</div>
									</th>
									<th pSortableColumn="quantite">
										<div class="flex justify-content-between align-items-center">
											Quantité
											<p-sortIcon field="quantite"></p-sortIcon>
											<p-columnFilter type="text" field="quantite" display="menu"
												class="ml-auto">
											</p-columnFilter>
										</div>
									</th>
									
									<th *ngIf="estAutorise('admin_boites_outils')" style="min-width: 5rem"></th>
								</tr>
							</ng-template>
							<ng-template pTemplate="body" let-item>

								<tr >
									<td>{{item.item.categorie.nom}}</td>
									<td>{{item.item.numero}}</td>
									<td>{{item.item.description}}</td>
									<td>{{item.quantite}}</td>
									<td *ngIf="estAutorise('admin_boites_outils')">
										<button pButton pRipple icon="pi pi-bars"
											class="p-button-secondary mr-2 boutonGrille"
											(click)="surMenuContextuelItem($event, item)"></button>
									</td>
								</tr>
							</ng-template>
							<ng-template pTemplate="emptymessage">
								<tr>
									<td colspan="5">Aucun item.</td>
								</tr>
							</ng-template>
						</p-table>
		</div>
	</div>


</div>
