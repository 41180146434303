import { Component, OnDestroy, Inject, OnInit } from "@angular/core"; import { Location } from "@angular/common";
import { AppComponent } from './app.component';
import { AppMainComponent } from './app.main.component';
import { BreadcrumbService } from './app.breadcrumb.service';
import { Subscription } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { AuthService } from '@auth0/auth0-angular';
import { DOCUMENT } from '@angular/common';
import { FavoriEntite } from './entites/favori';
import { ProfilService } from './services/profil-service';
import { HistoriqueEntite } from './entites/historique';
import { threadId } from 'worker_threads';
import { RouterModule, Router } from '@angular/router';
import { RapportService } from './services/rapport-service';
import { RapportEntite } from './entites/rapport';
import { environment } from 'src/environments/environment';
import { BreadcrumbPipe } from './pipes/breadcrumb.pipe';
 
@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
    styleUrls: ['./app.topbar.component.scss'],
    providers: [BreadcrumbPipe]
})
export class AppTopBarComponent implements OnDestroy, OnInit {

    subscription: Subscription;

    items: MenuItem[];
    home: MenuItem;
    userItems: MenuItem[];
    favorisItems: MenuItem[];
    historiquesItems: MenuItem[];
    rapportsItems: MenuItem[];

    favoris: FavoriEntite[] = [];
    historiques: HistoriqueEntite[] = [];
    rapports: RapportEntite[] = [];

    nomUtilisateur: string;

    afficherAlerteDev: boolean = false;

    url: string;

    constructor(
        public profilService: ProfilService,
        public rapportService: RapportService,
        public routerService: Router,
        public breadcrumbService: BreadcrumbService,
        public app: AppComponent,
        public appMain: AppMainComponent, location: Location, 
        public auth: AuthService,
        @Inject(DOCUMENT) private doc: Document) {
        this.subscription = breadcrumbService.itemsHandler.subscribe(response => {
            this.items = response;
        });
    }

    ngOnInit() {

        this.url = environment.urlBase;

        this.afficherAlerteDev = !environment.production;

        this.nomUtilisateur = `${this.profilService.ProfilCourant().prenom} ${this.profilService.ProfilCourant().nom}`;
        
        this.obtenirFavoris();
        this.obtenirHistorique();
        this.obtenirRapports();

        this.genererMenus();

        this.profilService.changementFavoris.subscribe(x => {
            this.obtenirFavoris();
        });

        this.profilService.changementHistorique.subscribe(x => {
            this.obtenirHistorique();
        });

        this.rapportService.changementRapports.subscribe(x => {
            this.obtenirRapports();
        });
    }

    genererMenus() {
        this.home = { icon: 'pi pi-home', routerLink: '/' };
        this.userItems = [
            /*{ label: 'Profil', icon: 'pi pi-user', command: () => this.naviguer('/profil') },*/
            { label: 'Recharger', icon: 'pi pi-refresh', command: () => this.recharger() },
            { label: 'Paramètres', icon: 'pi pi-cog', /*command: () => this.naviguer('/parametres') */ },
            { label: 'Déconnexion', icon: 'pi pi-sign-out', command: () => this.auth.logout() }
        ];

    }

    recharger() {
        this.routerService.navigateByUrl('/', {skipLocationChange: true}).then(()=>
                this.routerService.navigate(['/']));
    }

    obtenirFavoris() {
        this.profilService.ObtenirFavoris(this.profilService.ProfilCourant().employeId).then(x => {
            this.favoris = x;
        });
    }

    supprimerFavori(favoriId: number) {
        this.profilService.supprimerFavori(favoriId);
    }

    naviguer(url: string) {
        this.routerService.navigateByUrl('/', {skipLocationChange: true}).then(()=>
                this.routerService.navigate([url]));
    }

    naviguerRapport(rapport: RapportEntite) {
        window.open(rapport.url, '_blank');
        this.rapportService.supprimerRapport(rapport);
    }

    obtenirHistorique() {
        this.historiques = this.profilService.obtenirHistorique();
    }

    supprimerHistorique() {
        this.profilService.viderHistorique();
    }

    obtenirRapports() {
        this.rapports = this.rapportService.obtenirRapports();
    }

    viderRapports() {
        this.rapportService.viderRapports();
    }

    supprimerRapport(rapport: RapportEntite) {
        this.rapportService.supprimerRapport(rapport);
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
