<div class="card">
<div class="fluid formgrid grid">
    <div class="flex flex-column gap-2 col-12 md:col-6">
        <label for="noFournisseur">Numéro fournisseur</label>
        <input #champNoFournisseur type="text" pInputText id="noFournisseur" [(ngModel)]="numeroFournisseur"
                autocomplete="off" (keyup.enter)="confirmer()" />
    </div>
    <div class="flex flex-column gap-2 col-12 md:col-6">
        <label for="prix">Prix</label>
            <p-inputNumber #champPrix [(ngModel)]="prix" mode="currency" currency="USD" locale="en-US" (keyup.enter)="confirmer()"></p-inputNumber>
    </div>
</div>
<div class="grid">
    <div class="col-12 text-right ">
        <button pButton pRipple label="Ajouter" icon="pi pi-save" class="p-button-primary"
        (click)="confirmer()"></button>
        <button pButton pRipple label="Annuler" icon="pi pi-ban" class="p-button-text" (click)="annuler()"></button>
    </div>
</div>
</div>