<p-toast key="creationBonCommande"></p-toast>

<p-blockUI [blocked]="blocked">
    <div class="fluid formgrid grid">
        <div class="flex flex-column gap-2 col-12 text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-blockUI>


<p-card *ngIf="!EstCharge()">
    <div class="fluid formgrid grid">
        <div class="flex flex-column gap-2 col-12 text-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-card>

<div #contenu *ngIf="EstCharge()">

    <div class="fluid formgrid grid">
        <div class="flex flex-column gap-2 col-12 md:col-6 p-lg-4">
            <label for="numero">Numéro</label>
            <input id="numero" type="text" pInputText [readonly]="true" [(ngModel)]="donnees.numeroItem">
        </div>
        <div class="flex flex-column gap-2 col-12 p-md-12 p-lg-8">
            <label for="description">Description</label>
            <input id="description" type="text" pInputText [readonly]="true" [(ngModel)]="donnees.descriptionItem">
        </div>
    </div>
    <div class="fluid formgrid grid">
        <div class="flex flex-column gap-2 col-12 p-lg-4">
            <label for="numero">Fournisseur</label>
            <input id="numero" type="text" pInputText [readonly]="true"
                value="{{donnees.nomFournisseur}} ({{donnees.numeroFournisseur}})">
        </div>
        <div class="flex flex-column gap-2 col-12 p-lg-4">
            <label for="numeroFournisseur">Numéro fournisseur</label>
            <input id="numeroFournisseur" type="text" pInputText [readonly]="true"
                value="{{donnees.numeroFournisseur}}">
        </div>
        <div class="flex flex-column gap-2 col-12 p-lg-4">
            <label for="prix">Prix</label>
            <input id="prix" type="text" pInputText [readonly]="true" value="{{donnees.prix}}">
        </div>
    </div>
    <div class="fluid formgrid grid">
        <div class="flex flex-column gap-2 col-12">
            <p-radioButton label="Créer un nouveau bon de commande" name="nouveauBonCommande" value="1"
                [(ngModel)]="selectionNouveauBonCommande" class="mt-4"></p-radioButton>
            <br />
            <p-radioButton label="Utiliser le bon de commande en cours" name="nouveauBonCommande" value="2"
                [disabled]="!bonCommandeOuvert" [(ngModel)]="selectionNouveauBonCommande" class="mt-2">
            </p-radioButton>
        </div>
    </div>


    <div class="fluid formgrid grid">
        <div class="flex flex-column gap-2 col-12 lg:col-3">
            <label for="quantite">Quantité</label><br>
            <p-inputNumber #champQuantite [(ngModel)]="quantite" (keyup.enter)="creerBonDeCommande()" id="quantite">
            </p-inputNumber>
            <small class="p-error" *ngIf="submitted && !quantite">La quantié est obligatoire.</small>
        </div>
        <div class="flex flex-column gap-2 col-12 md:col-6 lg:col-3">
            <label for="codeProjet">Code projet<span class="requis">*</span></label>
            <p-dropdown #champCodeProjet [options]="listeCodeProjet" [(ngModel)]="selectionCodeProjet"
                optionLabel="code" [showClear]="true" (onHide)="surFermetureCodeProjet()" filter="true"
                filterBy="code,nom" (onChange)="surChangementCodeProjet()" appendTo="body"
                placeholder="Sélectionner un code de projet...">
                <ng-template pTemplate="selectedItem">
                    <div *ngIf="selectionCodeProjet">
                        <div>{{selectionCodeProjet.code}}</div>
                    </div>
                </ng-template>
                <ng-template let-code pTemplate="item">
                    <div>
                        <div>{{code.code}} - {{code.nom}}</div>
                    </div>
                </ng-template>
            </p-dropdown>
            <small class="p-error" *ngIf="submitted && !selectionCodeProjet">Le code de projet est obligatoire.</small>
        </div>
        <div class="flex flex-column gap-2 col-12 md:col-6 lg:col-3">
            <label for="codeActivite">Code d'activité<span class="requis">*</span></label>
            <p-dropdown #champCodeActivite [options]="listeCodeActivite" [(ngModel)]="selectionCodeActivite"
                optionLabel="code" [disabled]="!selectionCodeProjet" [showClear]="true"
                (onHide)="surFermetureCodeActivite()" filter="true" filterBy="code,nom" appendTo="body"
                placeholder="Sélectionner un code d'activité...">
                <ng-template pTemplate="selectedItem">
                    <div *ngIf="selectionCodeActivite">
                        <div>{{selectionCodeActivite.code}}</div>
                    </div>
                </ng-template>
                <ng-template let-code pTemplate="item">
                    <div>
                        <div>{{code.code}} - {{code.nom}}</div>
                    </div>
                </ng-template>
            </p-dropdown>
            <small class="p-error" *ngIf="submitted && !selectionCodeActivite">Le code d'activité est
                obligatoire.</small>
        </div>
        <div class="flex flex-column gap-2 col-12 md:col-6 lg:col-3">
            <label for="codeCategorie">Catégorie de coût<span class="requis">*</span></label>
            <p-dropdown #champCodeCategorie [options]="listeCodeCategorie" [(ngModel)]="selectionCodeCategorie"
                optionLabel="code" [disabled]="!selectionCodeProjet" [showClear]="true"
                (onHide)="surFermetureCodeCategorie()" filter="true" filterBy="code,nom" appendTo="body"
                placeholder="Sélectionner une catégorie de coût...">
                <ng-template pTemplate="selectedItem">
                    <div *ngIf="selectionCodeCategorie">
                        <div>{{selectionCodeCategorie.code}}</div>
                    </div>
                </ng-template>
                <ng-template let-code pTemplate="item">
                    <div>
                        <div>{{code.code}} - {{code.nom}}</div>
                    </div>
                </ng-template>
            </p-dropdown>
            <small class="p-error" *ngIf="submitted && !selectionCodeCategorie">La catégorie de coût est
                obligatoire.</small>
        </div>
        <div class="flex flex-column gap-2 col-12 md:col-6 lg:col-3">
            <label for="codeCategorie">Équipement</label>
            <p-dropdown #champCodeEquipement [options]="listeCodeEquipement" [(ngModel)]="selectionCodeEquipement" optionLabel="code"
                [showClear]="true" filter="true" filterBy="code,nom"
                appendTo="body" placeholder="Sélectionner un équipement..">
                <ng-template pTemplate="selectedItem">
                    <div *ngIf="selectionCodeEquipement">
                        <div>{{selectionCodeEquipement.code}}</div>
                    </div>
                </ng-template>
                <ng-template let-code pTemplate="item">
                    <div>
                        <div>{{code.code}} - {{code.nom}}</div>
                    </div>
                </ng-template>
            </p-dropdown>
        </div>
    </div>

    <!------------ ASSOCIATIONS PIÈCES MÉCANIQUES ------------>

    <div *ngIf="selectionCodeEquipement && estAutorise('piece_associer')" class="fluid formgrid grid mt-2">

        <div class="flex flex-column gap-2 col-12 p-md-3 p-xl-2 mb-3">
            <label for="lastname">Associer la pièce à l'unité</label>
            <p-checkbox [binary]="true" [(ngModel)]="indAssocier"></p-checkbox>
        </div>
        <div class="flex flex-column gap-2 col-12 p-md-3">
            <label for="lastname">Code de catégorie</label>
            <p-dropdown [options]="listeCategoriesEntretien" [(ngModel)]="selectionCategorieEntretien"
                [showClear]="true" optionLabel="description" [showClear]="true" filter="true"
                filterBy="code,description" appendTo="body" (onChange)="filtrerPieces()"
                placeholder="Sélectionner une catégorie...">
                <ng-template pTemplate="selectedItem">
                    <div *ngIf="selectionCategorieEntretien">
                        <div>{{selectionCategorieEntretien.code}} -
                            {{selectionCategorieEntretien.description}}
                        </div>
                    </div>
                </ng-template>
                <ng-template let-categorie pTemplate="item">
                    <div>
                        <div>{{categorie.code}} - {{categorie.description}}</div>
                    </div>
                </ng-template>
            </p-dropdown>


        </div>


    </div>





    <div class="text-right mt-3">

        <button pButton pRipple label="Créer" icon="pi pi-save" class="p-button-primary"
            (click)="creerBonDeCommande()"></button>

        <button pButton pRipple label="Annuler" icon="pi pi-ban" class="p-button-text" (click)="annuler()"></button>

    </div>

</div>