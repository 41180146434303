import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from "@angular/core"; import { Location } from "@angular/common";

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { ItemMin } from '../entites/item-min';
import { Router } from '@angular/router';
import { ReponseBase } from './reponse-base';
import { UniteEntite } from '../entites/unite';
import { AuthService } from '@auth0/auth0-angular';
import { ProfilService } from './profil-service';
import { DonneesBaseService } from './donnees-base-service';

@Injectable({ providedIn: 'root' })
export class InitialisationService {

 

  constructor(
    private sdb: DonneesBaseService,
    private profilService: ProfilService,
    private http: HttpClient,
    public router: Router,
    public auth: AuthService,) { }


    public initialiser() {

      console.log('Début Initialisation');

    return new Promise((resolve, reject) => {

     
      const pAuth = new Promise((resolveAuth, rejectAuth) => {
        this.auth.user$.subscribe(
          (profile) => {
          
              if (profile != null) {

                //console.log('Init: Profil Auth0 reçu')

                if (!this.profilService.Profil()) {
                  
                  console.log('Init: Debut Obtenir Profil SIHC ')

                  console.log(profile);
                  this.profilService.ObtenirProfil(profile.sub, profile.email).then(res => {

                    console.log('Init: Obtenir Profil SIHC OK')

                    console.log('Init: Debut init SDB')

                    const pBase = this.sdb.initialiser();
  
                    pBase.then(x => {

                      console.log('Init: Init SDB OK')

                      resolveAuth(null)
                    }).catch(() => {
                      console.log('Init: Init SDB Erreur')
                      rejectAuth()
                    });
  
                    console.log(this.profilService.Profil());
  
                    //resolve();
  
                  }).catch( err => {
                    console.log('Init: Obtenir Profil SIHC Erreur')
                    rejectAuth(false);
                  });
                }                
              } else {

                console.log('Init: Profil Auth0 null, resolve')
                resolveAuth(null);

              }
          }
        );
      });


      Promise.all([pAuth]).then(() => {
        
        console.log('Initialisation terminée');
        console.log('Profil:' + JSON.stringify(this.profilService.Profil()));
        
        resolve(true);
      }).catch(() => {

        console.log('Initialisation en erreur');

        this.router.navigateByUrl('error');

        resolve(false);
      });

    });



  }


  private handleError(operation: string, error: string) {

      this.log(`${operation} failed: ${error}`);

      this.router.navigateByUrl('error');
  }

  private log(message: string) {
    console.log(message);
  }
}
