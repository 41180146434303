import { Component, ViewChild, ElementRef, ɵConsole } from "@angular/core"; import { Location } from "@angular/common";
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService, MenuItem } from 'primeng/api';
import { Table } from 'primeng/table/table';
import { ActivatedRoute, Router } from '@angular/router';
import { EtapeBonTravailEntite } from 'src/app/entites/mecanique/etape-bon-travail';
import { BaseComponent } from 'src/app/base/base-component';
import { ItemMin } from 'src/app/entites/item-min';
import { ProfilService } from 'src/app/services/profil-service';
import { AppMainComponent } from 'src/app/app.main.component';
import { ItemService } from 'src/app/services/item-service';
import { RequisitionService } from 'src/app/services/requisition-service';
import { FournisseurService } from 'src/app/services/fournisseur-service';
import { DonneesBaseService } from 'src/app/services/donnees-base-service';
import { EntretienPreventifEntite } from 'src/app/entites/mecanique/entretien-preventif';
import { EntretienCorrectifEntite } from 'src/app/entites/mecanique/entretien-correctif';
import { ListeVerificationEntite } from 'src/app/entites/mecanique/liste-verification';
import { ListeVerificationService } from 'src/app/services/liste-verification-service';
import { CategorieEntretienEntite } from 'src/app/entites/mecanique/categorie-entretien';
import { ETypeBonTravail } from 'src/app/entites/enums/type-bon-travail';
import { ETypeEtapeBonTravail } from 'src/app/entites/enums/type-etape-bon-travail';
import { BonTravailService } from 'src/app/services/bon-travail-service';
import { RequeteAjouterEtapesBonTravail } from 'src/app/services/requetes/requete-ajouter-etapes-bon-travail';
import { EntretienCorrectifService } from 'src/app/services/entretien-correctif-service';
import { EntretienPreventifService } from 'src/app/services/entretien-preventif-service';
import { HeureService } from 'src/app/services/heure-service';
import { AjustementHeureEntite } from 'src/app/entites/ajustement-heure';
import { AjouterAjustementComponent } from './ajouter-ajustement.component';
import { ContexteService } from 'src/app/services/contexte-service';
import { AjustementKmEntite } from 'src/app/entites/ajustement-km';
import { KmService } from 'src/app/services/km-service';
import { AjouterAjustementKmComponent } from './ajouter-ajustement-km.component';

export interface ParametresAjustements {
    itemId: number;
}


@Component({
    selector: 'app-ajustements-km',
    templateUrl: './ajustements-km.component.html'
})
export class AjustementsKmComponent extends BaseComponent {

    donnees: ParametresAjustements;

    ajustements: AjustementKmEntite[];

    @ViewChild('dt') tableItems: Table;

    constructor(
        profilService: ProfilService,
        route: ActivatedRoute,
        routerService: Router,
        appMain: AppMainComponent, location: Location, 
        public itemService: ItemService,
        public entretienPreventifService: EntretienPreventifService,
        public entretienCorrectifService: EntretienCorrectifService,
        public fournisseurService: FournisseurService,
        public listeVerificationService: ListeVerificationService,
        public bonTravailService: BonTravailService,
        public dialogService: DialogService,
        public kmService: KmService,
        private contexteService: ContexteService,
        public messageService: MessageService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public sdb: DonneesBaseService) {
        super(sdb, profilService, route, routerService, appMain);
    }



    ngOnInit() {

        this.donnees = this.config.data;

        this.kmService.ObtenirListeAjustements(this.donnees.itemId).then(x => {

            this.ajustements = x.valeur;

            this.CompleterChargement();

        });

    }

    fermer() {
        this.ref.close(null);
    }

    ajouter() {


        const subRef = this.dialogService.open(AjouterAjustementKmComponent, {
            data: {
                itemId: this.donnees.itemId
            },
            header: 'Ajouter un ajustement de compteur',
            width: '50%'
        });
        
        subRef.onClose.subscribe((res: AjustementKmEntite) => {
            if (res) {
                this.ajustements.unshift(res);
                this.messageService.add({ severity: 'success', summary: 'Succès', detail: `Ajustement ajouté.` });

                this.ref.close(null);

                this.contexteService.Ajouter("tab", "7");
                this.routerService.navigateByUrl('/', {skipLocationChange: true}).then(()=>
                    this.routerService.navigate(['item/' + this.donnees.itemId]));
            }   

        });

        const dynamicDialogComponent = this.dialogService.dialogComponentRefMap.get(this.ref).instance;
        dynamicDialogComponent.unbindGlobalListeners();
        subRef.onDestroy.subscribe(_ => {
            dynamicDialogComponent.moveOnTop(); 
            dynamicDialogComponent.bindGlobalListeners(); 
            dynamicDialogComponent.focus(); 
        });



    }
}